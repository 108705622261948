import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"

export class Chart {

    static load(id: string, options: IRequestOptions | null = null) : Promise<Models.Chart | null> {
        return ApiUtils.apiGet("/chart/load/"+ ApiUtils.encodeSegment(id), options)
            .then(response => response.object(Models.Chart));
    }

    static save(chart: Models.Chart, options: IRequestOptions | null = null) : Promise<Models.Chart | null> {
        return ApiUtils.apiPostJson("/chart/save/", chart, options)
            .then(response => response.object(Models.Chart));
    }

    static loadForParent(id: string, options: IRequestOptions | null = null) : Promise<Models.Chart[] | null> {
        return ApiUtils.apiGet("/chart/loadforparent/" + ApiUtils.encodeSegment(id), options)
            .then(response => response.objects(Models.Chart));
    }

    static remove(id: string, options: IRequestOptions | null = null) : Promise<boolean | null> {
        return ApiUtils.apiGet("/chart/delete/" + ApiUtils.encodeSegment(id), ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<boolean>());
    }
    
    static loadChartData(id: string, rowIds: string[] | null, options: IRequestOptions | null = null) {
        var url = "/chartData/load/" + ApiUtils.encodeSegment(id);
        var mergedOptions = ApiUtils.createRequestOptions({ clearCache: true }, options)
        return ApiUtils.apiPostJson(url, { rowIds: rowIds }, mergedOptions)
            .then(response => response.json<Models.ChartData>());
    }
    
    static saveOrder(orderedIds: string[], options: IRequestOptions | null = null){
        return ApiUtils.apiPostJson("/chart/saveOrder/", {OrderedIds: orderedIds}, options)
            .then(response => response.json<boolean>());
    }
}