
    import { Component } from 'vue-property-decorator';
    import { FormValidation } from '@/views/components/FormValidation';
    import { UserModule } from '@/store/UserStore';
    import GlobalConfirmationDialog from "@/views/components/GlobalConfirmationDialog.vue"
    import NotificationStack from "@/views/layouts/LayoutParts/NotificationStack.vue"
    import { EventBus } from "@/modules/EventBus";
    import * as API from '@/api/Api';
    import * as Models from '@/models/RowShare';

    @Component ({
        name: 'BackOfficeLayout',
        components: {NotificationStack, GlobalConfirmationDialog}
    })
    export default class BackOfficeLayout extends FormValidation {
        async signout() {
            let returnUrl: string | null = await UserModule.signOut();
            if (returnUrl) {
                if (returnUrl.charAt(0) == "~") returnUrl = returnUrl.substr(1);

                window.location.href = returnUrl;
            }
        }
        
        async goToBoRoot(){
            this.$router.push({name:'bo'})
        }

        async clearCache(){
            var evtArgs = new Models.ConfirmationRequiredEventParams();
            evtArgs.title = "Clear cache";
            evtArgs.description1 = "Confirm clear cache ?";
            evtArgs.cancelButtonText = "Cancel";
            evtArgs.actionButtonText = "Confirm";
            evtArgs.actionButtonIcon = "broom";
            evtArgs.actionButtonColor = 'error';

            evtArgs.onConfirmation = async () => {
                API.BoApi.ClearCache();
            }

            EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, evtArgs);
        }
        async restartServer(){
            var evtArgs = new Models.ConfirmationRequiredEventParams();
            evtArgs.title = "Restart server";
            evtArgs.description1 = "Confirm restart server ?";
            evtArgs.cancelButtonText = "Cancel";
            evtArgs.actionButtonText = "Confirm";
            evtArgs.actionButtonIcon = "recycle";
            evtArgs.actionButtonColor = 'error';

            evtArgs.onConfirmation = async () => {
                API.BoApi.RestartServer();
            }

            EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, evtArgs);
        }
        async clearCacheAndRestartServer(){
            var evtArgs = new Models.ConfirmationRequiredEventParams();
            evtArgs.title = "Clear cache and restart";
            evtArgs.description1 = "Confirm clear cache and restart server ?";
            evtArgs.cancelButtonText = "Cancel";
            evtArgs.actionButtonText = "Confirm";
            evtArgs.actionButtonIcon = "broom";
            evtArgs.actionButtonColor = 'error';

            evtArgs.onConfirmation = async () => {
                API.BoApi.ClearCacheAndRestartServer();
            }

            EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, evtArgs);
        }
    }
