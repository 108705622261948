
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as models from '@/models/RowShare';
import * as api from '@/api/Api';
import * as agGrid from 'ag-grid-community';
import { ApiUtils, RowShareException } from '@/api/ApiUtils';
import GridViewVM from '@/viewModels/Table/gridViewVM';
import RowVM from '@/viewModels/Table/rowVM';
import { EventBus } from '@/modules/EventBus';
import ListDisplayModeVM from '@/viewModels/Table/listDisplayModeVM';
import { RouterUtils } from '@/modules/Router';
import { ApiResult } from '@/models/ApiResult';

@Component({ name: 'CopyMoveRowsDialog'})
export default class CopyMoveRowsDialog extends Vue {
    @Prop() rows!: agGrid.IRowNode[];
    @Prop() showDialog!: boolean;
    @Prop() currentList!: models.List;
    @Prop() displayModeVM!: ListDisplayModeVM;

    dialogOpened: boolean = false;
    loadingSiblings: boolean = true;
    targetLists: models.ListThumbnailInfo[] = [];
    targetListId: string = "";
    comparaisonErrors: models.ApiError[] | null = null;
    processingCopy: boolean = false;
    processingMove: boolean = false;
    processingError: string = "";

    get processingTransfer(): boolean {
        return this.processingCopy || this.processingMove;
    }
    
    get hasErrors(): boolean {
        return (this.hasComparaisonErrors || this.processingError != "");
    }

    get hasComparaisonErrors(): boolean {
        return (this.comparaisonErrors?.length ?? 0) > 0;
    }

    get siblingsLabel(): string {
        return this.$i18n.t('CopyMoveRowsDialog_TargetList').toString();
    }

    get copyDisabled(): boolean {
        return (this.targetListId == "") || this.processingMove;
    }

    get moveDisabled(): boolean {
        return (this.targetListId == "") || this.processingCopy || this.selectionHasLockedRows;
    }

    get rowIds(): string[] {
        let rowIds: string[] = [];
        for(let i = 0; i < this.rows.length; ++i){
            let rowVM = <RowVM>(this.rows[i].data);
            if(rowVM.rsRow.Id) {
                rowIds.push(rowVM.rsRow.Id)
            }
        }
        return rowIds;
    }

    get selectionHasLockedRows(): boolean {
        if(!this.rows || this.rows.length === 0) {
            return false;
        }
        return this.rows.findIndex(r => (<RowVM>r.data)?.isLocked ?? -1) > -1;
    }


    @Watch('showDialog')
    initDialog() {
        this.dialogOpened = this.showDialog;
        this.processingCopy = false;
        this.processingMove = false;
        this.processingError = "";
        this.targetListId = "";
        this.targetLists = [];
        this.comparaisonErrors = [];
        if(this.showDialog) {
            this.loadingSiblings = true;
            api.ListTree.loadSiblings(this.currentList.Id)
               .then((siblings: models.ListThumbnailInfo[] | null) => {
                if(siblings) {
                    this.targetLists = siblings.filter(s => s.Id != this.currentList.Id && !s.IsInRecycleBin);
                }
               })
               .finally(() => {
                    this.loadingSiblings = false;
               });
        }
    }

    onCancel() {
        this.dialogOpened = false;
        this.$emit('cancel');
    }

    copyMoveRows(move: boolean) {
        this.processingCopy = !move;
        this.processingMove = move;
        this.comparaisonErrors = [];

        this.processCopyMoveRows(move).then((result: ApiResult<boolean> | null) => {
            if(result && result.IsValid) {
                if(move) {
                    var rowVMs = this.rows.map(node => node.data);
                    EventBus.$emit(models.Event.ROW_DELETE, rowVMs);
                }
                else {
                    this.displayModeVM.deselectAllRows();
                }
                this.onCancel();
            } else {
                if(!result)
                    this.processingError = this.$i18n.t('CopyMoveRowsDialog_ProcessingError').toString();
                else
                    this.comparaisonErrors = result.Errors;
            }
            this.processingCopy = false;
            this.processingMove = false;
        }).catch((exc: RowShareException) => {
            this.processingError = exc.message;
        }).finally(() => {
            this.processingMove = false;
            this.processingCopy = false;
        });
    }

    async processCopyMoveRows(move: boolean) : Promise<ApiResult<boolean> | null> {
        if(move) {
            const moveBlobInsteadOfCopyStr = RouterUtils.getCaseInsentiveQuery(this.$route, "moveBlobInsteadOfCopy");
            var moveBlobInsteadOfCopy = moveBlobInsteadOfCopyStr != "false";

            return api.Row.move(this.currentList.Id, this.targetListId, this.rowIds, moveBlobInsteadOfCopy);
        }
        else {
            return api.Row.copy(this.currentList.Id, this.targetListId, this.rowIds);
        }
    }
    
    public GetErrorDescription(error: models.ApiError) {
        return ApiUtils.GetApiErrorDescription(error);
    }
}
