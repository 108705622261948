export class Hyperlink {
    url: string = "";
    displayName: string = "";

    constructor(link: string) {
        if (!link){
            return;
        }
        let values = link?.split('|');
        this.url = values[0];
        if(values.length > 1){
            this.displayName = values[1];
        }
    }
}