
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import RemindUsersDialog from '@/views/Table/ListActions/RemindUsersDialog.vue';
    import RowMergeDialog from '@/views/Table/ListActions/RowMergeDialog.vue';
    import CommentEditorDialog from '@/views/Table/CommentEditorDialog.vue';
    import * as RowShare from '@/models/RowShare';
    import { EventBus } from "@/modules/EventBus";
    import { Location as RouterLocation, Route } from "vue-router";
    import PageLoadSpinner from "@/views/layouts/LayoutParts/PageLoadSpinner.vue";
    import ListVM from '@/viewModels/Table/listVM';
    import RowVM from '@/viewModels/Table/rowVM';
    import FormViewVM from '@/viewModels/Table/formViewVM';
    import {ColumnDescriptionVM, ColumnDescriptionCollection} from '@/viewModels/Table/ColumnDescriptionVM';
    import Bigram from "@/views/components/Bigram.vue";
    import CellFormView from '@/views/Table/FormView/CellFormView.vue';
    import { UserModule } from '@/store/UserStore';
    import { ListTreeModule } from '@/store/ListTreeStore';
    import ProxyBasedCardRenderer from '@/views/Table/CardView/ProxyBasedCardRenderer.vue';
    import CommentCardRenderer from '@/views/Table/cellsComponents/Comment/CommentCardRenderer.vue';
    import TwoStatesBooleanCardRenderer from '@/views/Table/cellsComponents/TwoStateBoolean/TwoStatesBooleanCardRenderer.vue'
    import { ColumnStrongType } from '@/models/Column';
    import linkifyStr from 'linkify-string';

    @Component({
        name: 'Survey',
        components: {
            PageLoadSpinner, Bigram, RemindUsersDialog, RowMergeDialog, CommentEditorDialog,
            ProxyBasedCardRenderer, CommentCardRenderer, TwoStatesBooleanCardRenderer, CellFormView
        }
    })
    export default class Survey extends Vue {

        @Prop() listVM!: ListVM;
        @Prop({ default: 'view' }) editorState !: RowShare.EditorState;
        
        formViewVM!: FormViewVM;

        private columnDescriptions: ColumnDescriptionCollection = new ColumnDescriptionCollection();
        private cellStates : ("form-cell-error"|"form-cell-saving"|"form-cell-readonly"|"")[] = [];
        private cellErrors : (string|null)[] = [];

        private rowVM : RowVM | null = null;
        
        async mounted() : Promise<void>{
            this.formViewVM = new FormViewVM(this.listVM);
            window.addEventListener('beforeunload', this.beforeUnload);
            this.onListLoaded();
            this.initCols();
            this.formViewVM.setRowUpdateCallBack(rowVM => this.refreshRowState(rowVM));
        }

        beforeDestroy() {
            EventBus.$off([RowShare.Event.ROW_UPDATED, RowShare.Event.VALIDATE_FORM]);
            window.removeEventListener('beforeunload', this.beforeUnload);
            this.formViewVM.dispose();
        }

        @Watch("listVM.columns")
        initCols(){
            if(!this.listVM.columns)
                return;

            this.columnDescriptions = new ColumnDescriptionCollection();

            for(let i = 0; i < (this.listVM.columns.length); ++i){
                var col = this.listVM.columns[i];

                if(col.IsReadOnly || col.isGenerated || col.HasReadOnlyPermissions || col.HasHiddenPermissions || col.Type == ColumnStrongType.Comment || col.isChildColumn)
                    continue;

                let colDesc = new ColumnDescriptionVM(col);
                this.columnDescriptions.add(colDesc);
            }
        }
        
        @Watch("listVM.list")
        onListLoaded(){
            this.init();

            if(this.listVM.list) {
                document.title = this.listVM.list.DisplayName + " | RowShare";
            } else {
                document.title = "RowShare";
            }
        }

        init(){
            if(!this.listVM.list)
                return;
            this.rowVM = RowVM.createNewRow(this.listVM, this.listVM.list.Id);            
            if(!this.rowVM?.rsRow)
                return 0;
            this.refreshRowState(this.rowVM);
        }

        /**  
         * This method is called by formViewVM everytime a row is refreshed
         * It is used to refresh the UI with error icon or saving spinner
         **/
        refreshRowState(rowVM: RowVM){
            if(!this.listVM.columns || !this.listVM.list)
                return;

            if(rowVM.rowVMId != this.rowVM?.rowVMId)
                return;
            
            var cellStates : ("form-cell-error"|"form-cell-saving"|"form-cell-readonly"|"")[] = [];
            var cellErrors : (string|null)[] = [];
            this.columnDescriptions.columns.forEach(colDesc => {
                var rsCol = colDesc.currentColumn;
                if(rsCol == null) {
                    cellErrors.push("");
                    cellStates.push("");
                    return;
                }
                if(this.rowVM) {
                    cellErrors.push(this.rowVM.getCellError(rsCol));
                }
                else {
                    cellErrors.push("");
                }
                    
                if(this.rowVM?.isSavingCell(rsCol)) {
                    cellStates.push("form-cell-saving");
                } else if(this.rowVM?.hasCellError(rsCol)) {
                    cellStates.push("form-cell-error");
                } else if(!this.rowVM?.rsRow.CanUpdate || this.rowVM.isLocked) {
                    cellStates.push("form-cell-readonly");
                } else {
                    cellStates.push("");
                }
            });
            this.cellStates = cellStates;
            this.cellErrors = cellErrors;
        }
                
        async save(){
            await this.rowVM?.saveSurvey();

            // failed saving the row
            if(this.rowVM?.isNew)
                return;

            // success should navigate to an intermediate success screen
            this.$router.push(<RouterLocation>{ name: 'SurveySuccess', params: { listId: this.listVM?.list?.Id }});
        }

        beforeRouteLeave(to: Route, from: Route, next: any){
            this.handleBeforeRouteLeave(() => next());    
        }

        canLeaveRoute(){
            return !this.rowVM || this.rowVM.removed == true || (!this.rowVM.dirtyValues && !this.rowVM.valuesBeingSaved);
        }

        handleBeforeRouteLeave(callback : () => void) {
            if(!this.canLeaveRoute()){
                let event = new RowShare.ConfirmationRequiredEventParams();
                event.title = this.$i18n.t('NotSavedItemsDialog_Title').toString();
                event.description1 = this.$i18n.t('NotSavedItemsDialog_Description').toString();
                event.cancelButtonText = this.$i18n.t('NotSavedItemsDialog_Cancel').toString();
                event.actionButtonText = this.$i18n.t('NotSavedItemsDialog_Validate').toString();
                event.actionButtonColor = "primary";
                event.onConfirmation = async () => {
                    callback();
                };
                EventBus.$emit(RowShare.Event.CLEAR_ALL_GLOBAL_NOTIFICATIONS);
                EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, event);
            }
            else {
                callback();
            }
        }

        beforeUnload(evt: BeforeUnloadEvent) {
            if(!this.canLeaveRoute()){
                evt.returnValue = this.$i18n.t('NotSavedItemsDialog_Description');
                evt.preventDefault();
            }
        }

        getDescriptionContent(content: string) {
            return linkifyStr(content, {target: '_blank'});
        }

    }
