import { FolderAccessMode } from './Folder';

export class ListTree {
    public Organizations!: ListTreeOrganization[];
}

export class ListTreeOrganization {
    public Id!: string;
    public Name!: string;
    public IsDefault!: boolean;
    public Path!: string;
    public RootFolder!: ListTreeFolder;
    public IconPath!: string;
    public FullSiteLogoPath!: string;
    public SmallSiteLogoPath!: string;
    public Favorites!:ListThumbnailInfo[];
    public Recents!:ListThumbnailInfo[];
    public InTrial!:boolean;
    public CanCurrentUserManage!:boolean;
    public IsEnabled!:boolean;
    public SubscriptionCleanDeletedRowsDelay!: number;
    public HasArchiveRows!: boolean;
    public HasLockRows!: boolean;
    public HasRowMerge!: boolean;
    public HasRowMetadatas!: boolean;
    public IsFree!: boolean;
    public HasRowShareSupportActivated!: boolean;
    public HasAudit!: boolean;
    public HasRtcHistory!: boolean;
}

export class ListTreeFolder {
    public Id!: string;
    public ParentId!: string;
    public Name!: string;
    public Path!: string;
    public CanCurrentUserBrowseContent!: boolean;
    public CanCurrentUserCreateList!: boolean;
    public CanCurrentUserCreateFolder!: boolean;
    public CanCurrentUserManageAccesses!: boolean;
    public AccessMode!: FolderAccessMode;
    public HasRemainingListSlot!: boolean;
    public Folders!: ListTreeFolder[];
    public Lists!: ListThumbnailInfo[];
    public IsRecycleBin!:boolean;
    public IsInRecycleBin!:boolean;
    public CanCurrentUserRenameFolder!:boolean;
    public CanCurrentUserDeleteFolder!:boolean;
    public TotalListCount!:number;
}

export class ListThumbnailInfo {
    public Id!: string;
    public Name!: string;
    public Path!: string;
    public IconPath!: string;
    public OwnersNicknames!:string[];
    public Owned!:boolean;
    public LoadedByReadOnlyMember!:boolean;
    public IsInRecycleBin!:boolean;
    public LastVisited!:Date;
    public HumanReadableLastUpdateDate!: string;
    public LastUpdateDateSortOrder !: number;
    public IsReferenced!: boolean;
}

export class BreadcrumbFolder {
    public Id!: string;
    public Name!: string;
    public IsRootFolder!: boolean;
    public IsTag: boolean = false;
}
