import * as RowShare from '@/models/RowShare';

export class Chart {
    public Id!: string;
    public ListId!: string;
    public Type!: ChartType;
    public Name!: string;
    public Data!: string;
    public DataVersion!: number;
    public Shared!: boolean;
    public Owned!: boolean;
    public HasNotPublicColumn!: boolean;
}

export class ChartParameters {
    public YAggregationFunction!: ChartAggregationFunction | null | undefined;
    public YColumnId!: string;
    public XColumnId!: string;
    public SplitColumnId!: string | null| undefined;
    public Stacked!: ChartStackedType | null | undefined;    
    public DateGroupingUnit!: ChartDateGroupingUnit | null | undefined;
    public DateGroupingQuantity!: number | null | undefined;
}

export enum ChartType {
    Line = 0,
    Bar = 1,
    Area = 2,
    Scatter = 3,
    Pie = 4
}

export enum ChartStackedType {
    NotStacked,
    Normal,
    Full
}

export enum ChartAggregationFunction {
    Count = 0,
    Sum = 1,
    Max = 2,
    Min = 3,
    Avg = 4,
    NotNullCount = 5
}

export enum ChartDateGroupingUnit {
    None = 0,
    Year = 1,
    Month = 2,
    Day = 3,
    // Hour,
    // Minute,
    // Second
}
    
export enum XDataType {
    Other = 0,
    Number = 1,
    DateTime = 2
}

export class chartAggregationFunctionItem {
    name!: string;
    aggregationFunction!: ChartAggregationFunction;
    onlyNumericColumns!: boolean;
    canChooseYColumn!: boolean;
}

export class ChartData {
    public Id!: string;
    public Name!: string;
    public Type!: ChartType;
    public Stacked!: ChartStackedType | null | undefined;    
    public DateGroupingUnit!: ChartDateGroupingUnit | null | undefined;
    public XDataType!: XDataType;
    public XColumnStrongType!: RowShare.ColumnStrongType;
    public Labels!: string[];
    public DataSets!: ChartDataSet[];
}

export class ChartDataSet {
    public Label!: string;
    public Data!: string[];
    public BackgroundColor!: string;
}
export class chartDateGroupingUnitItem {
    name!: string;
    groupingUnit!: ChartDateGroupingUnit;
}