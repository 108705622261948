
import { Utils } from '@/utils/Utilities';
import { ReturnUrlHandler } from '@/views/ReturnUrl';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({name: 'Toolbar'})
export default class Toolbar extends ReturnUrlHandler {
    @Prop() title!: string;
    @Prop() description!: string;

    get hasReturnUrl(): boolean {
        return !Utils.isNullOrWhiteSpace(this.returnUrl);
    }

    returnToPrevious() {
        this.redirectToReturnUrlOrDefault(this.returnUrl);
    }
}
