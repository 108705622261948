
import { Vue, Component, Watch } from 'vue-property-decorator';
import * as Models from '@/models/RowShare';
import { ColumnAccessVM, ColumnAccessVMStatus } from '@/viewModels/Table/ColumnAccessVM';
import { EventBus } from '@/modules/EventBus';
import * as Api from '@/api/Api';
import { DataTableHeader } from 'vuetify';
import SecurityPrincipalDropdown from "@/views/components/SecurityPrincipalDropdown.vue";
import Bigram from '@/views/components/Bigram.vue';
import ColumnSelector from './ColumnSelector.vue';
import { RowShareException } from '@/api/ApiUtils';

@Component({name: 'SidePanelColumnPermissions', components: { SecurityPrincipalDropdown, Bigram, ColumnSelector }})
export default class SidePanelColumnPermissions extends Vue {
    showMe: boolean = false;
    columnId: string = "";
    width: string = "0px";
    selectedGuest: Models.SecurityPrincipal | null = null;
    currentColumnAccesses: ColumnAccessVM[] = [];
    originalColumnAccesses: ColumnAccessVM[] = [];
    currentColumn: Models.Column | null = null;
    currentList: Models.List | null = null;
    currentOrganization: Models.Organization | null = null;
    loadingAccesses: boolean = false;
    columnAccessesHeight: number = 0;
    processing: boolean = false;
    selectedColumnId: string = "";


    accessesListHeaders: DataTableHeader[] = [];

    created() {
        EventBus.$on(Models.Event.SHOW_SIDEPANEL_COLUMN_PERMISSIONS, (params: Models.showSidePanelColumnPermissionsEventParams) => this.onShowSidePanel(params));
        window.addEventListener('resize', this.refreshColumnAccessesHeight);
    }

    beforeDestroy() {
        EventBus.$off([Models.Event.SHOW_SIDEPANEL_COLUMN_PERMISSIONS]);
        window.removeEventListener('resize', this.refreshColumnAccessesHeight);
    }

    async onShowSidePanel(event: Models.showSidePanelColumnPermissionsEventParams) {
        this.columnId = event.columnId;
        this.width = "40%";
        this.initializeColumnAccessesHeader();
        await this.initPanel();
        this.selectedColumnId = this.columnId;
        setTimeout(() => {
            this.showMe = true;
        }, 0);

        if((<any>window).Intercom) {
            (<any>window).Intercom('update', { 'hide_default_launcher': true});
        }
    }

    async initPanel() {
        this.currentColumn = await Api.Column.load(this.columnId);
        if(this.currentColumn != null) {
            this.currentList = await Api.List.load(this.currentColumn.ListId);
        }
        if(this.currentList != null) {
            this.currentOrganization = await Api.Organization.load(this.currentList.OrganizationId);
        }

        this.refreshColumnAccesses();
        this.refreshColumnAccessesHeight();
    }

    initializeColumnAccessesHeader() {
        this.accessesListHeaders.push({text: '', align: 'start', value: 'guestName', width: '50%', cellClass: 'cell-guest'});
        this.accessesListHeaders.push({text: '', align: 'center', value: 'Access', sortable: false, width: '45%', cellClass: 'cell-access'});
        this.accessesListHeaders.push({text: '', align: 'end', value:'removeCol', sortable: false, width: '5%', cellClass: 'cell-removeCol'});
    }

    refreshColumnAccesses() {
        this.loadingAccesses = true;
        this.currentColumnAccesses = [];
        this.originalColumnAccesses = [];
        Api.ColumnAccess.loadForColumn(this.columnId)
                    .then(async (result) => {
                        if(result && result.IsValid) {
                            let accesses = result.Value;
                            this.currentColumnAccesses = accesses.filter(acc => !acc.IsMyOrganization).map(acc => new ColumnAccessVM(acc));
                            this.currentColumnAccesses.sort((a: ColumnAccessVM, b: ColumnAccessVM) => {
                                if(a.IsMyOrganization) {
                                    return -1;
                                }
                                if(b.IsMyOrganization) {
                                    return 1;
                                }
                                let vala = a.guestName.toLowerCase();
                                let valb = b.guestName.toLowerCase();
                                if(vala < valb) {
                                    return -1;
                                }
                                else if(vala > valb) {
                                    return 1;
                                }
                                return 0;
                            });
                            // Add MyOrganization access
                            this.originalColumnAccesses = accesses.filter(acc => !acc.IsMyOrganization).map(acc => new ColumnAccessVM(acc));
                            var myOrgAccess = accesses.find(acc => acc.IsMyOrganization);
                            if(!myOrgAccess) {
                                myOrgAccess = Models.ColumnAccess.getNewDefault(this.columnId)
                                myOrgAccess.Access = Models.ColumnAccessLevel.RowAccess;
                                let principals = await Api.SecurityPrincipal.loadForOrganization(this.currentOrganizationId, false);
                                if(principals && principals.length > 0) {
                                    var orgPrincipal = principals.find(p => p.IsMyOrganization);
                                    if(orgPrincipal) {
                                        myOrgAccess.principal = orgPrincipal;
                                    }
                                }
                                myOrgAccess.IsMyOrganization = true;
                            }
                            this.currentColumnAccesses.unshift(new ColumnAccessVM(myOrgAccess));
                            this.originalColumnAccesses.unshift(new ColumnAccessVM(myOrgAccess));
                            // Add Admin access for information
                            var adminAccess = Models.ColumnAccess.getNewDefault(this.columnId);
                            var adminRow = new ColumnAccessVM(adminAccess);
                            adminRow.Access = Models.ColumnAccessLevel.RowAccess;
                            adminRow.isAdminAccess = true;
                            adminRow.status = ColumnAccessVMStatus.Unchanged;
                            this.currentColumnAccesses.unshift(adminRow);
                            this.originalColumnAccesses.unshift(adminRow);
                        }
                    })
                    .finally(() => {
                        this.loadingAccesses = false;                        
                        // if(this.selectedGuest) {
                        //     this.onGuestSelected();
                        // }
                    });
    }

    onCancel() {
        if(this.hasModifiedAccess) {
            EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, <Models.ConfirmationRequiredEventParams> {
                description1: this.$i18n.t('SidePanelColumnPermissions_ConfirmClosePanel').toString(),
                actionButtonText: this.$i18n.t('SidePanelColumnPermissions_ConfirmCancellation').toString(),
                actionButtonColor: "primary",
                actionButtonIcon: "",
                onConfirmation: async () => {this.closePanel();},
                cancelButtonText: this.$i18n.t('Common_Cancel').toString()
            });
        }
        else {
            this.closePanel();
        }
    }

    closePanel() {
        this.columnId = "";
        this.showMe = false;
        this.width = "0px";
        this.currentList = null;
        this.currentOrganization = null;
        this.currentColumnAccesses = [];
        this.originalColumnAccesses = [];
        this.accessesListHeaders = [];
        this.selectedGuest = null;
        this.selectedColumnId = '';
        if((<any>window).Intercom) {
            (<any>window).Intercom('update', { 'hide_default_launcher': false});
        }
    }

    onClickOutside() {
        if(this.showMe) {
            this.onCancel();
        }
    }

    shouldClosePanel(event: any): boolean {
        if(!event?.target?.classList) {
            return false;
        }
        return event.target.classList.contains('v-overlay__scrim');
    }

    setStatus(access: ColumnAccessVM) {
        let originalAccess = this.originalColumnAccesses.find((acc) => acc.Id == access.Id);
        if(originalAccess) {
            access.status = originalAccess.Access == access.Access ? ColumnAccessVMStatus.Unchanged : ColumnAccessVMStatus.Modified;
        }
    }

    updateStatus(access: ColumnAccessVM) {
        if(access.isNew || access.isDeleted) {
            return;
        }
        this.setStatus(access);
    }

    removeAccess(access: ColumnAccessVM) {  
        if(access.isNew) {
            let accessIndex = this.currentColumnAccesses.findIndex((acc) => acc == access);
            if(accessIndex > -1) {
                this.currentColumnAccesses.splice(accessIndex, 1);
            }
        }
        if(!access.isDeleted) {
            access.status = ColumnAccessVMStatus.Deleted;
        }
        else {
            this.setStatus(access);
        }
    }

    getAccessClass(access: ColumnAccessVM) {
        switch(access.status) {
            case ColumnAccessVMStatus.Deleted:
                return "access-deleted";
            case ColumnAccessVMStatus.Modified:
                return "access-modified";
            case ColumnAccessVMStatus.New:
                return "access-new";
            default:
                return "";
        }
    }

    @Watch('currentColumnAccesses')
    refreshColumnAccessesHeight() {
        let columnaccessesFooter = <HTMLElement>this.$refs.columnaccessesFooter;
        let sidepanelHeader = <HTMLElement>this.$refs.sidepanelHeader;
        let sidepanelFooter = <HTMLElement>this.$refs.sidePanelFooter;
        if(!columnaccessesFooter || !sidepanelHeader || !sidepanelFooter) {
            return;
        }
        let maxHeight = window.innerHeight - (columnaccessesFooter.getBoundingClientRect().height + 
                                            sidepanelHeader.getBoundingClientRect().height +
                                            sidepanelFooter.getBoundingClientRect().height)// + 20);// 284;
        let computedHeight = (this.currentColumnAccesses.length * 32) + 32;
        this.columnAccessesHeight = Math.min(maxHeight, computedHeight);
    }

    onGuestSelected() {
        if(!this.selectedGuest) {
            return;
        }
        
        var newAccesss: ColumnAccessVM;
        let lastAccessCreated =  this.currentColumnAccesses.length > 0 ? this.currentColumnAccesses[this.currentColumnAccesses.length-1]
                                                                     : Models.ColumnAccess.getNewDefault(this.columnId);
        
        newAccesss = new ColumnAccessVM(Models.ColumnAccess.getNew(this.currentColumnAccesses, this.selectedGuest, this.columnId, lastAccessCreated.Access));
        newAccesss.Guest = <Models.Owner> {
            BackgroundColorIndex: this.selectedGuest.BackgroundColorIndex,
            Bigram: this.selectedGuest.Bigram,
            Email: this.selectedGuest.MemberEmail,
            IconPath: this.selectedGuest.IconPath,
            Id: this.selectedGuest.id,
            Type: this.selectedGuest.ownerType,
            DisplayName: this.selectedGuestName
        }
        newAccesss.status = ColumnAccessVMStatus.New;
        this.currentColumnAccesses.push(newAccesss);
        this.$nextTick(() => {
            this.selectedGuest = null;
            (<any>this.$refs.guestsDropdown)?.clearSelection();
            this.refreshColumnAccessesHeight();
        }, 0);
    }

    onSaveClick() {
        let accessesToSave = new Models.ColumnAccessesToSave();
        this.processing = true;
        accessesToSave.columnId = this.columnId;
        this.currentColumnAccesses.forEach((access) => {
            if(access.isAdminAccess) {
                return;
            }
            switch(access.status) {
                case ColumnAccessVMStatus.New:
                    accessesToSave.newAccesses.push(access);
                    break;
                case ColumnAccessVMStatus.Modified:
                    if(access.IsMyOrganization && access.Access == Models.ColumnAccessLevel.RowAccess) {
                        accessesToSave.deletedAccesses.push(access);
                    }
                    else {
                        accessesToSave.updatedAccesses.push(access);
                    }
                    break;
                case ColumnAccessVMStatus.Deleted:
                    accessesToSave.deletedAccesses.push(access);
                    break;
            }
        });
        Api.ColumnAccess.saveMultiple(accessesToSave)
                    .then((result) => {
                        if(result?.IsValid && result?.Value) {
                            this.refreshColumnAccesses();
                            this.refreshColumnAccessesHeight();
                            EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED,
                                            <Models.GlobalNotificationEventParams>{
                                                message: this.$i18n.t('SidePanelColumnPermissions_SaveSuccess').toString(),
                                                type: Models.NotificationType.success,
                                                autoHide: true,
                                                autoHideAfterMs: 3000
                                            });
                            EventBus.$emit(Models.Event.COLUMN_PERMISSIONS_CHANGED, this.columnId);
                        }
                        else {
                            if((result?.Errors?.length ?? 0) > 0) {
                                var error = result?.Errors[0];
                                var errorMessage = this.$i18n.t(error?.ExceptionCode.toString()?? "").toString()
                                EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, 
                                                <Models.GlobalNotificationEventParams>{ 
                                                    message: errorMessage,
                                                    title: this.$i18n.t('SidePanelColumnPermissions_SaveError').toString(),
                                                    type: Models.NotificationType.error,
                                                    autoHide: true,
                                                    autoHideAfterMs: 10000
                                                });
                            }
                        }
                    })
                    .catch((exc: any) => {
                        let rsExc = exc as RowShareException;
                        let exceptionMsg = "";
                        if(rsExc) {
                            exceptionMsg = rsExc.message;
                        }
                        else {
                            exceptionMsg = exc.toString();
                        }
                        EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{ message: exceptionMsg,
                        type: Models.NotificationType.error,autoHide: true, autoHideAfterMs: 10000});
                    })
                    .finally(() => this.processing = false);
    }

    onSelectedColumnIdChanged() {
        this.columnId = this.selectedColumnId;
        this.initPanel();
    }
    
    get selectedGuestName(): string {
        if(!this.selectedGuest) {
            return "";
        }
        if(this.selectedGuest instanceof Models.SecurityPrincipal) {
            switch (this.selectedGuest.ownerType) {
                case Models.OwnerType.Group:
                    return this.selectedGuest.GroupName;
                case Models.OwnerType.MyOrganization:
                    return this.$i18n.t('ListAccess_MyOrganization').toString();
                case Models.OwnerType.Team:
                    return this.selectedGuest.MemberName ?? this.selectedGuest.MemberEmail;
                default:
                    return this.selectedGuest.displayName;
            }
        }
        return this.selectedGuest;
    }

    get hasModifiedAccess(): boolean {
        return this.currentColumnAccesses.findIndex(acc => acc.status !== ColumnAccessVMStatus.Unchanged) > -1;
    }

    get currentSecurityPrincipals(): Models.SecurityPrincipal[] {
        if(!this.currentColumnAccesses) {
            return [];
        }
        return this.currentColumnAccesses.filter(cavm => !cavm.isAdminAccess).map((access) => access.principal);
    }
    
    get currentColumnName(): string {
        return this.currentColumn?.DisplayName ?? "";
    }

    get currentListId(): string {
        return this.currentList?.Id ?? "";
    }

    get adminsGuestName(): string {
        return this.$i18n.t('SidePanelColumnPermissions_AdminAccess').toString();
    }

    get myOrganizationGuestName(): string {
        return this.$i18n.t('SidePanelColumnPermissions_MyOrgAccess').toString();
    }

    get currentOrganizationId(): string {
        return this.currentOrganization?.Id ?? "";
    }

    get hasColumnPermissions(): boolean {
        return this.currentOrganization?.SubscriptionHasColumnPermissions ?? false;
    }

    get canGoToCheckOut(): boolean {
        return this.currentOrganization?.CanCurrentUserManage ?? false;
    }

    get upsellTitle(): string {
        return this.$i18n.t('UpsellCard_ColumnPermissionsTitle').toString();
    }

    get upsellDescription(): string {
        return this.$i18n.t('UpsellCard_ColumnPermissionsDescription').toString();
    }

    get currentUrl(): string {
        return this.$route.fullPath;
    }

}
