
import { Component, Prop } from 'vue-property-decorator';
import { FormValidation } from "@/views/components/FormValidation";
import * as API from '@/api/Api';
import InputText from '@/views/components/FormInputs/InputText.vue';
import InputTextArea from '@/views/components/FormInputs/InputTextArea.vue';
import { List, Column, Event, Culture, CatalogCategory, User, NotificationType, GlobalNotificationEventParams } from '@/models/RowShare';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
import ListToolbar from '@/views/Table/ListActions/ListToolbar.vue';
import { EventBus } from '@/modules/EventBus';
import ListVM from '@/viewModels/Table/listVM';
import { UserModule } from '@/store/UserStore';
import { OnPremModule } from '@/store/OnPremStore';

@Component({
    name: "TableSettings",
    components: { InputText, InputTextArea, ValidationObserver, ValidationProvider, ConfirmationDialog, ListToolbar }
})
export default class TableSettings extends FormValidation  {

    @Prop() private listId!:string;
    private listModel: List = new List();
    private columns: Column [] = [];
    private iconImage: File | null = null;
    private listOfCultures: Culture[] = [];
    private defaultProps = { maxHeight: 200 }
    private deleteIconModal = false;
    // This variable is used to compare with actual values to call or not the API. If this is not done, toaster is shown every time the "onblur" event is thrown,
    // even if the field hasn't changed.
    private olderList!: List;
    private listVM: ListVM = new ListVM();
    private categories: CatalogCategory[] = [];

    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };

    get currentUser() {
        return UserModule.CurrentUser || new User();
    }

    get isNotOnPrem(){
        return !OnPremModule.isOnPrem;
    }

    get canEditCatalogCategory(): boolean {
        return this.isNotOnPrem && this.currentUser.IsAdministrator;
    }

    created(){
        this.loadList(false);
        EventBus.$on(Event.LISTNAME_CHANGED, () => this.loadList(false));
    }

    destroy() {
        EventBus.$off(Event.LISTNAME_CHANGED, () => this.loadList(false));
    }

    loadList(useCache: boolean = true) {
        API.List.load(this.listId,{cache: useCache}).then(listResul => {
            if(listResul){
                this.listModel = listResul;
                this.listVM.list = this.listModel;

                this.olderList = Object.assign({}, listResul);
                if(this.listModel){
                    API.Column.loadForList(this.listId).then(listColumns=>{
                        if(listColumns){
                            // Option by default is added and after that all the table columns
                            let firstColumn = new Column();
                            firstColumn.Id = "00000000000000000000000000000000";
                            firstColumn.DisplayName = this.$t("TableSettings_MainColumn_Option").toString();
                            this.columns = listColumns.filter(c => !c.HasHiddenPermissions);
                            this.columns.unshift(firstColumn);
                        }
                    });
                    API.Culture.loadCultures().then((listOfCultures:any)=>{
                        this.listOfCultures = listOfCultures;
                    });
                    API.Category.loadAll()
                            .then((categories) => {
                                if(categories) {
                                    this.categories = categories;
                                }
                            });
                    
                }
            } 
        });
    }

    async updateTableSettings(){
        if(JSON.stringify(this.listModel) != JSON.stringify(this.olderList)){
            let isValid = await this.$refs.observer.validate();
            if(isValid){
                try{
                    let result = await API.List.save(this.listModel);
                    if(result){
                        this.olderList = Object.assign({}, result);
                        this.listModel = result;
                        this.deleteIconModal = false;
                        EventBus.$emit(Event.LISTINFO_CHANGED);
                        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: this.$i18n.t('TableSettings_SuccessMessage'), 
                                                                                                            type: NotificationType.success, 
                                                                                                            duplicateId: 'ts_'+ this.listId,
                                                                                                            autoHide: true, autoHideAfterMs: 4000});
                    }
                }
                catch(Error) {                        
                    this.listModel = Object.assign({}, this.olderList); 
                    EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: this.$i18n.t('TableSettings_ErrorMessage'), 
                                                                                                        type: NotificationType.error, 
                                                                                                        duplicateId: 'ts_'+ this.listId,
                                                                                                        autoHide: true, autoHideAfterMs: 10000});
                }
            }
        }
    }

    getIcon(){
        if (this.listModel && this.listModel.IconPath)
            return '/' + this.listModel.IconPath + "?_ssb_img=S"+ Math.random();
    }

    async onFileChanged(){
        if(this.iconImage && this.iconImage.size>0) {
            if(!this.iconImage?.type.startsWith('image/')) {
                this.listModel = Object.assign({}, this.olderList);
                this.iconImage = null;
                EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: this.$i18n.t('Common_InvalidFileType'), 
                                                                                                    type: NotificationType.error, 
                                                                                                    duplicateId: 'ts_'+ this.listId,
                                                                                                    autoHide: true, autoHideAfterMs: 5000});
            }
            else {
                try{
                    let result = await API.List.saveWithFile(this.listModel, this.iconImage, "/icon");
                    if(result){
                        this.olderList = Object.assign({}, result);
                        this.listModel = result;
                        this.iconImage = null;
                        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: this.$i18n.t('TableSettings_SuccessMessage'), 
                                                                                                            type: NotificationType.success, 
                                                                                                            duplicateId: 'ts_'+ this.listId,
                                                                                                            autoHide: true, autoHideAfterMs: 4000});
                    }
                }
                catch(Error){
                    this.listModel = Object.assign({}, this.olderList); 
                    this.iconImage = null;
                    EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: this.$i18n.t('TableSettings_ErrorMessage'), 
                                                                                                        type: NotificationType.error, 
                                                                                                        duplicateId: 'ts_'+ this.listId,
                                                                                                        autoHide: true, autoHideAfterMs: 10000});
                }
            }
        }
    }

    deleteImage(){
        if(this.listModel){
            this.listModel.IconPath = null;
            this.listModel.Icon_Remove = true;
        }  
        this.updateTableSettings();
    }

    openModalToDeleteIcon(){
        this.deleteIconModal = true;
    }

    cancelled(){
        this.deleteIconModal = false;
    }

    getLocaleText(key: string) {
        if(this.existsLocalization(key)) {
            return this.$i18n.t(key).toString();
        }
        return '';
    }

    existsLocalization(key: string) {
        return this.$i18n.te(key);
    }
}
