
import {Vue, Prop, Component, Watch } from 'vue-property-decorator';
import Chart, { ChartConfiguration, ChartTypeRegistry } from 'chart.js/auto';
import 'chartjs-adapter-moment';
import * as RowShare from '@/models/RowShare';
import * as API from '@/api/Api';
import { ChartData, ChartStackedType, Event, maximizeChartEventParams } from '@/models/RowShare';
import { EventBus } from '@/modules/EventBus';
import moment from 'moment';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';

@Component({name: 'chartRenderer', components: { PageLoadSpinner }})
export default class charRenderer extends Vue {
    @Prop() chartId!: string;
    @Prop() width!: string;
    @Prop() height!: string;
    @Prop() isThumbnail!: boolean;
    @Prop() rowIds!: string[] | null;
    
    configuration!: ChartConfiguration | null;
    chart: Chart | null = null;
    chartData: ChartData | null = null;
    loadingData: boolean = false;
    loadingErrors: string = '';

    public get domId() { return this.chartId + (this.isThumbnail ? '_thumbnail' : ''); }

    mounted() {
        moment.locale(window.navigator.language);
        this.refreshChartData();
    }

    destroy() {
        if(this.chart) {
            this.chart.destroy();
        }
    }

    @Watch('chartId')
    onChartIdChanged(){
        this.refreshChartData();
    }

    @Watch('rowIds')
    onRowIdsChanged(){
        this.refreshChartData();
    }

    async refreshChartData() {
        if(this.chart) {
            this.chart.destroy();
        }
        if(!this.chartId) {
            return;
        }
        this.loadingData = true;
        API.Chart.loadChartData(this.chartId, this.rowIds, {cache: false})
                 .then(datas => {
                     if(datas) {
                        this.chartData = datas;
                        this.configuration = this.chartDataToChartjsConfiguration(this.chartData);
                        if(this.chart) {
                            this.chart.destroy();
                        }
                        if(this.configuration && this.domId) {
                            this.chart = new Chart(this.domId, this.configuration);                            
                        }
                     }
                 })
                 .catch(err => {
                     let msg = err._message ? err._message : err;
                     this.loadingErrors = this.$i18n.t('Charts_ErrorLoadingData', [msg]).toString();
                 })
                 .finally(() => {this.loadingData = false;});
    }

    private chartDataToChartjsConfiguration(chartData: RowShare.ChartData) : ChartConfiguration {
        var config = <ChartConfiguration>this.getDefaultConfig(chartData);

        config.data = {
            labels: chartData.Labels,
            datasets: chartData.DataSets.map(ds => {
                var chartJsDataset = <any> {
                    label: ds.Label,
                    data: ds.Data.map((value) => value.replaceAll(',','.')),
                    backgroundColor: ds.BackgroundColor,
                    borderColor: ds.BackgroundColor
                };

                if(chartData.Type == RowShare.ChartType.Area) {
                    chartJsDataset.fill = true;
                }

                return chartJsDataset;
            }) 
        };

        return config;
    }

    private getDefaultConfig(chartData: RowShare.ChartData) : Partial<ChartConfiguration> {
        var res : any = { options : {}};
        if(this.isThumbnail) {
            res.options.plugins = {
                title: {
                    display: this.isThumbnail,
                    text: chartData.Name,
                },
                legend: {
                    display: !this.isThumbnail,
                    position: 'right'
                }
            };
            res.options.resizeDelay = 200;


            res.options.onClick = (e: any) => {
                this.maximizeChart();
            };
        }

        switch(chartData.Type) {
            case RowShare.ChartType.Area:
            case RowShare.ChartType.Line: {
                res.type = "line";
                res.options.scales = {
                    x: { ticks:{ display: !this.isThumbnail } },
                    y: { beginAtZero: true, ticks: {display: !this.isThumbnail} },
                };

                if(chartData.Type == RowShare.ChartType.Area)
                    res.options.scales.y.stacked = true;

                if(chartData.XDataType == RowShare.XDataType.DateTime) {
                    res.options.scales.x.type = 'time';
                    res.options.scales.x.beginAtZero = false;
                    res.options.scales.x.time = { tooltipFormat: this.getTooltipFormatForTimeUnit(chartData) }

                    if(chartData.DateGroupingUnit) {
                        res.options.scales.x.time.unit = RowShare.ChartDateGroupingUnit[chartData.DateGroupingUnit].toLowerCase();
                    }
                    /*
                    res.options.scales.x.time.unit = chartData.DateGroupingUnit
                        ? RowShare.ChartDateGroupingUnit[chartData.DateGroupingUnit].toLowerCase()
                        : 'minute';
                    */
                } else if(chartData.XDataType == RowShare.XDataType.Number) {
                    res.options.scales.x.type = 'linear';
                }

                return res;
            }
            
            case RowShare.ChartType.Bar: {
                res.type = "bar";
                
                var stacked = chartData.Stacked == ChartStackedType.Normal;
                res.options.scales = {
                    x: { ticks:{ display: !this.isThumbnail }, stacked: stacked },
                    y: { ticks:{ display: !this.isThumbnail }, stacked: stacked }
                };
                if(chartData.DataSets.length === 1 && !this.isThumbnail) {
                    res.options.plugins = {
                        legend: {
                            display: false
                        }
                    }
                }
                if(chartData.XDataType == RowShare.XDataType.DateTime) {
                    res.options.scales.x.type = 'timeseries';
                    res.options.scales.x.time = { tooltipFormat: this.getTooltipFormatForTimeUnit(chartData) }

                    if(chartData.DateGroupingUnit) {
                        res.options.scales.x.time.unit = RowShare.ChartDateGroupingUnit[chartData.DateGroupingUnit].toLowerCase();
                    }
                }
                
                return res;
            }

            case RowShare.ChartType.Scatter: {
                res.type = "scatter";
                return res;
            }

            case RowShare.ChartType.Pie: {
                res.type = "pie";
                return res;
            }

        }
    }

    public getTooltipFormatForTimeUnit(chartData: RowShare.ChartData){
        switch(chartData.DateGroupingUnit) {
            case RowShare.ChartDateGroupingUnit.Month :
                return "MMMM YYYY";
            case RowShare.ChartDateGroupingUnit.Day:
                return "LL";
            case RowShare.ChartDateGroupingUnit.Year:
                return "YYYY";
            default:
                return chartData.XColumnStrongType == RowShare.ColumnStrongType.Date ? "LL" : "LLLL";
        }
    }

    public getChartImage() {
        if(this.chart) {
            return this.chart.toBase64Image();
        }
    }

    maximizeChart() {
        if(this.isThumbnail) {
            let event = new maximizeChartEventParams();
            event.chartId = this.chartId;
            event.rowIds = this.rowIds;
            EventBus.$emit(Event.MAXIMIZE_CHART, event)
        }
    }
}
