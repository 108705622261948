
import { Component } from 'vue-property-decorator';
import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
import { EventBus } from '@/modules/EventBus';
import { rowEditCommentsEventParams, Event } from '@/models/Event';
import RowVM from '@/viewModels/Table/rowVM';
import RsColumnManager from '@/columnProxies/RsColumnManager';
import CommentColumnProxy from '@/columnProxies/CommentColumnProxy';
import { Row } from '@/api/Api';

@Component({
    name: 'CommentFormEditor'
})
export default class CommentFormEditor extends FormEditor {
    currentValue: number = 0;
    proxy!: CommentColumnProxy;

    init() {
        this.proxy = RsColumnManager.getProxy(this.column.Type);
        this.currentValue = this.rowVM.getValue(this.column, this.proxy);
    };

    openCommentDialog() {
        EventBus.$emit(Event.ROW_EDIT_COMMENTS, <rowEditCommentsEventParams> {
            row: (<RowVM>this.rowVM).rsRow,
            column: this.column,
            list: this.listVM.list,
            refreshParentRow: () => this.refreshParentRow()
        });

    }

    async refreshParentRow() {
        let updatedRow = await Row.load(this.rowVM.rsRow.Id);
        if(updatedRow) {
            this.rowVM.rsRow = updatedRow;
            this.currentValue = this.rowVM.getValue(this.column, this.proxy);
        }
    }

}
