import { Utils } from "@/utils/Utilities"
import { Logger } from "@/utils/Logger"
import { ApiError, PostedFile } from "@/models/RowShare";
import i18n from "@/modules/Localization";
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

function endsWith(originalString : string, suffix: string) {
    return originalString.indexOf(suffix, originalString.length - suffix.length) !== -1;
};

class Cache {
    private dictionary: any = {};

    public set(key: string, value: any) {
        this.dictionary[key] = value;
    }

    public get(key: string): any {
        return this.dictionary[key];
    }

    public containsKey(key: string): boolean {
        return this.dictionary.hasOwnProperty(key);
    }

    public clear() {
        this.dictionary = {};
    }
}

export interface HttpRowShareValidationError {
    Column: string;
    Message: string;
}

export interface HttpRowShareException {
    IsJsonExceptionObject: boolean;
    Exception?: HttpRowShareExceptionDetails;
    Code: number;
    Message: string;
    FullMessage?: string;
    ValidationErrors?: HttpRowShareValidationError[];
}

export interface HttpRowShareExceptionDetails {
    ClassName: string;
    Message: string;
    Data: any;
    InnerException: RowShareException;
    HelpUrl: string;
    StackTraceString: string;
    RemoteStackTraceString: string;
    RemoteStackIndex: number;
    ExceptionMethod: string;
    HResult: number;
    Source: string;
    WatsonBuckets: any;
    Code: number;
    "__type": string;
}

export const enum ExceptionCode {
    Ok = 0,
    GroupCannotBeDeleted = 1,
    FolderCannotBeDeleted = 2,
    UserDoesNotOwnFolder = 3,
    UserDoesNotOwnList = 4,
    FolderOrListAlreadyExists = 5,
    UnhandledImportFileType = 6,
    NothingToImport = 7,
    InvalidImportFile = 8,
    UserDoesNotOwnObject = 9,
    InternalError = 10,
    FolderHasNoList = 11,
    UserDoesNotHaveAccessToObject = 12,
    Unauthorized = 13,
    InvalidParentFolder = 14,
    InvalidSinkType = 15,
    RowHasNoList = 16,
    InvalidRowValuesCount = 17,
    RowConflict = 18,
    RowDoesNotExist = 19,
    InvalidColumnName = 20,
    ColumnAlreadyExists = 21,
    ReportHasAssociatedJobs = 22,
    ReportCodeError = 23,
    UnsupportedReportType = 24,
    TemplateRunError = 25,
    TemplateGenerationError = 26,
    InvalidEmail = 27,
    InvalidRowValues = 28,
    SsbElementDoesNotExist = 29,
    ServiceInstancingError = 30,
    ServiceInterfaceError = 31,
    TemplateGenerationInternalError = 32,
    FailedToEncryptTicket = 33,
    TemplateGenerationInvalidUser = 34,
    TemplateGenerationInvalidList = 35,
    TemplateGenerationInvalidAccessDenied = 36,
    TemplateGenerationInvalidInvalidReport = 37,
    TemplateGenerationInvalidInvalidReportTemplate = 38,
    MaximumRowsExceededForUser = 39,
    ScriptModelFileIsClosed = 40,
    TransmitFileError = 41,
    DeserializationError1 = 42,
    DeserializationError2 = 43,
    DeserializationError3 = 44,
    DeserializationError4 = 45,
    DeserializationError5 = 46,
    DeserializationError6 = 47,
    InvalidPassword = 48,
    LastColumnCannotBeDeleted = 49,
    CircularReferenceOfManager = 50,
    CircularReferenceOfGroup = 51,
    PaymentProviderInterfaceError = 52,
    PaymentProviderInstancingError = 53,
    PaymentProcessError = 54,
    TemplateGenerationCorruptedTemplate = 55,
    TemplateGenerationInvalidDirective = 56,
    MyOrganizationCannotContainMembers = 57,
    MissingOrganizationParameter = 58,
    InvalidQuerySyntax = 59,
    DiscountRuleInterfaceError = 60,
    DiscountRuleInstancingError = 61,
    MaximumMemberExceededForOrganization = 62,
    RowShareQLError = 63,
    InvoiceCreationError = 64,
    PaymentProviderError = 65,
    TargetUserDoesNotHaveAccessToObject = 66,
    NumberOutOfRange = 67,
    FailedImportFile = 68,
    UserAlreadyExists = 69,
    OrganizationNameRequired = 70,
    BlobSizeLimitExceeded = 71,
    InvalidMemberOptions = 72,
    OrganizationOnlyAdministratorCannotBeDisable = 73,
    FreeUserCannotCreateFolder = 74,
    ListCountLimitExceeded = 75,
    FileNameTooLong = 76,
    InvalidFileName = 77,
    MaxRowsForOrganizationListExceeded = 78,
    MaxRowsForUserListExceeded = 79,
    MaxRowsExceededForImport = 80,
    OnlyOneAutonumberColumnAllowed = 83,
    FolderNotFound = 84,
    DisabledOrganizationCannotAddAdmin = 85,
    MergedCells = 86,
    NumberOfColumns = 87,
    ColumnName = 88,
    CellType = 89,
    NumberOfSheets = 90,
    UserNotExists = 95,
    InvalidRecaptcha = 96,
    InvalidGuid = 100,
    ListNotFound = 101,
    NoOneRowPerUserAllowed = 102,
    CultureError = 103,
    ColumnCountError = 104,
    NotTheSameOrganization = 105,
    ColumnDoesNotExists = 106,
    AutonumberColumnError = 107,
    ColumnTypeError = 108,
    MemberAlreadyExists = 109,
    EmptyFile = 110,
    SocialLoginWithUnknownEmail = 111,
    InvalidFileContentType = 112,
    NotAllowedForDemoUser = 113,
    RowAccessDenied = 114,
    InvalidApiParameter = 115,
    InvalidValue = 116,
    XLRowSaveError = 117,
    XLRowAccessDenied = 118,
    XLRowConflict = 119,
    XLRowDoesNotExists = 120,
    CannotCloneRowWithUniqueColumn = 121,
    CannotInsertRowWithDuplicateUniqueColumn = 122,
    CannotDeleteRowsIfListWithReadOnlyOrHiddenColumn = 123,
    OnlyOneRowPerUser = 124

}

export class RowShareException {
    private _message: string = "";
    private _code: ExceptionCode = 0;
    public data: any;
    public httpResponse: HttpResponse | null = null;
    public validationErrors?: HttpRowShareValidationError[];

    private constructor() {}

    public static createFromApiResult(apiResult: HttpResponse): RowShareException {
        const res = new RowShareException();

        res.httpResponse = apiResult;
        res.data = apiResult.data;
        if (Utils.isString(res.data)) {
            try {
                const json = JSON.parse(res.data) as HttpRowShareException;
                if (RowShareException.isJsonException(json)) {
                    res._code = json.Code;
                    this.parseMessage(res, json.Message);

                    if (json.ValidationErrors) {
                        res.validationErrors = json.ValidationErrors;
                    }
                }
            } catch (ex) {
                this.parseMessage(res, res.data);
            }
        }

        return res;
    }

    public static createFromErrorMessage(message: string): RowShareException {
        const res = new RowShareException();
        res._message = message;
        return res;
    }

    private static parseMessage(ex: RowShareException, value: string) {
        const regex = /RS(\d+):\s*(.*)/i;
        const result = regex.exec(value);
        if (result) {
            ex._code = Utils.parseNumber(result[1], 0);
            ex._message = result[2];
        } else {
            ex._message = value;
        }
    }

    public get code(): number {
        return this._code;
    }

    public get message(): string {
        return this._message;
    }

    public toString() {
        return this.message;
    }

    public static isJsonException(value: any): value is HttpRowShareException {
        return Utils.isObject(value) && (<HttpRowShareException>value).IsJsonExceptionObject === true;
    }
}

export class ApiUtils {
    static readonly newRowToken = "(new)";
    static readonly tableUrlPrefix = "/t/";
    static readonly formUrlPrefix = "/tf/";
    static readonly surveyUrlPrefix = "/survey/";
    static readonly columnFormUrlPrefix = "/tcs/";
    static readonly jobsFormUrlPrefix = "/tjf/";
    static readonly tableUrlFormat = ApiUtils.tableUrlPrefix + "{0}";
    static readonly formUrlFormat = ApiUtils.formUrlPrefix + "{0}/{1}";
    static readonly surveyUrlFormat = ApiUtils.surveyUrlPrefix + "{0}";
    static readonly columnFormUrlFormat = ApiUtils.columnFormUrlPrefix + "{0}/{1}";
    static readonly jobsFormUrlFormat = ApiUtils.jobsFormUrlPrefix + "{0}";
    static readonly listIdQueryStringParameterName = "listid";
    static readonly rowQueryStringParameterName = "row";

    static cache = new Cache(); // store an association of url:promise
    static options = {
        rootUrl: process.env.VUE_APP_API_ROOT
    };

    static encodeSegment(value: any) {
        if (Utils.isNullOrUndefined(value)) {
            return "";
        }

        value = String(value);
        while (value.indexOf("/") > 0) {
            value = value.replace("/", "_x002F_");
        }

        while (value.indexOf("\\") > 0) {
            value = value.replace("\\", "_x005C_");
        }

        return encodeURIComponent(value);
    }

    static toJson(obj: any) {
        // Remove keys starting by "$$" as it must be properties used only the UI
        return JSON.stringify(obj, (key, value) => {
            if (Utils.isString(key) && key.indexOf("$$") === 0) {
                return undefined;
            }

            return value;
        });
    }

    static getResponseObject(xhr: XMLHttpRequest) {
        var response = new HttpResponse();
        response.status = xhr.status;
        response.statusText = xhr.statusText;

        if (!xhr.responseText && xhr.status === 0) {
            //response.data = SR.getMessageOrDefault("UnknownError", "An unknown error occurred");
        } else {
            response.data = xhr.responseText;
        }
        if(xhr.responseURL && Utils.endsWith(xhr.responseURL, "/ConfirmEmail"))
        {
            window.location.assign("/ConfirmEmail");
        }
        return response;
    }

    static buildUri(uri: string, setCulture = true) {
        if (uri.length < 4 || !Utils.equalsIgnoreCase(uri.substring(0, 4), "http")) {
            uri = ApiUtils.options.rootUrl + uri;
        }

        // Set lcid parameter
        if (setCulture) {
            let culture = Utils.getCurrentCulture();
            if (culture) {
                uri = ApiUtils.setQueryStringParameter("l", culture, uri);
            }
        }

        return uri;
    }

    static setQueryStringParameter(key: string, value: string | null, uri?: string): string {
        let mustUpdateUrl = false;
        if (!uri) {
            uri = window.location.href;
            mustUpdateUrl = true;
        }

        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";
        let newUri = uri;
        if (uri.match(re)) {
            if (!Utils.isNullOrUndefined(value)) {
                newUri = uri.replace(re, "$1" + key + "=" + encodeURIComponent(value) + "$2");
            } else {
                uri = uri.replace(re, "$1");
                if (Utils.endsWith(uri, "?")) {
                    uri = uri.substr(0, uri.length - 1);
                }
                newUri = uri;
            }
        } else {
            if (!Utils.isNullOrUndefined(value)) {
                newUri = uri + separator + key + "=" + encodeURIComponent(value);
            }
        }

        if (mustUpdateUrl && uri !== newUri) {
            window.location.href = newUri;
        }

        return newUri;
    }

    static setXmlHttpRequestConfiguration(xhr: XMLHttpRequest) {
        try {
            xhr.setRequestHeader("X-RowShare-Version", "2");
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        } catch (e) {
            // do nothing
        }

        try {
            if (typeof xhr.withCredentials === "boolean") {
                xhr.withCredentials = true;
            }
        } catch (e) {
            // do nothing
        }
    }

    static apiGet(uri: string, options: IRequestOptions | null = null): Promise<HttpResponse> {
        uri = ApiUtils.buildUri(uri);

        const useCache = options && options.cache === true;
        const clearCache = options && options.clearCache === true;

        if (useCache && ApiUtils.cache.containsKey(uri)) {
            Logger.log("RowShare.Api", "verbose", "From cache: " + uri);
            return ApiUtils.cache.get(uri);
        }

        const promise = new Promise<HttpResponse>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    const response = ApiUtils.getResponseObject(xhr);
                    if (response.isSuccess) {
                        resolve(response);
                    } else {
                        console.error(uri);
                        reject(RowShareException.createFromApiResult(response));
                    }
                }
            };

            xhr.open("GET", uri, true);
            ApiUtils.setXmlHttpRequestConfiguration(xhr);
            xhr.send(null);
        });

        if (useCache) {
            Logger.log("RowShare.Api", "verbose", "Add to cache: " + uri);
            ApiUtils.cache.set(uri, promise);
        }

        if (clearCache) {
            ApiUtils.cache.clear();
        }

        return promise;
    }

    static apiPostJsonForFileResponse(uri: string, data: any, options: IRequestOptions | null = null): Promise<File> {
        uri = ApiUtils.buildUri(uri);

        const clearCache = options && options.clearCache === true;

        const promise = new Promise<File>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onreadystatechange = function() {
                if(xhr.readyState == 4) {
                    
                    var contentDipositionHeader = xhr.getResponseHeader("content-disposition");
                    var fileName = "";
                    if(contentDipositionHeader) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(contentDipositionHeader);
                        if (matches != null && matches[1]) { 
                            fileName = matches[1].replace(/['"]/g, '');
                        }                        
                    }
                    const file = new File([xhr.response], fileName)
                    if(xhr.status == 200) {
                        resolve(file);
                    }
                    else {
                        reject("Error getting file");
                    }
                }
            }

            xhr.open("POST", uri, true);
            ApiUtils.setXmlHttpRequestConfiguration(xhr);
            if (data instanceof FormData) {
                xhr.send(data);
            } else {
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.send(ApiUtils.toJson(data));
            }            
        });

        if (clearCache) {
            ApiUtils.cache.clear();
        }

        return promise;
    }

    static apiPostJson(uri: string, data: any, options: IRequestOptions | null = null): Promise<HttpResponse> {
        uri = ApiUtils.buildUri(uri);

        const clearCache = options && options.clearCache === true;

        const promise = new Promise<HttpResponse>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    const response = ApiUtils.getResponseObject(xhr);
                    if (response.isSuccess) {
                        resolve(response);
                    } else {
                        reject(RowShareException.createFromApiResult(response));
                    }
                }
            };

            xhr.open("POST", uri, true);
            ApiUtils.setXmlHttpRequestConfiguration(xhr);
            if (data instanceof FormData) {
                xhr.send(data);
            } else {
                xhr.setRequestHeader("Content-Type", "application/json");
                // const body = ApiUtils.toJson(data)
                // body["connectionId"] = RealTimeCollaborationModule.connection.connectionId;
                // xhr.send(body);
                xhr.send(ApiUtils.toJson(data));
            }
            
        });

        if (clearCache) {
            ApiUtils.cache.clear();
        }

        return promise;
    }

    static apiPostFile(uri: string, file: File, options: IRequestOptions | null = null): Promise<HttpResponse> {
        var formData = new FormData();
        ApiUtils.appendFiles(formData, file);

        return ApiUtils.apiPostJson(uri, formData, options);
    }

    static appendFiles(formData: FormData, file: PostedFile | ArrayLike<PostedFile> | FileList | File | Blob | Object) {
        if (Utils.isNullOrUndefined(file)) {
            return;
        }

        if (file instanceof PostedFile) {
            file.appendToFormData(formData);
        } else if (file instanceof File) {
            formData.append("File", file);
        } else if (file instanceof Blob) {
            formData.append("File", file, "unnamed");
        } else if (Utils.isArrayLike(file)) {
            for (let i = 0; i < file.length; i++) {
                const f = file[i];
                ApiUtils.appendFiles(formData, f);
            }
        }
    }

    static appendFilesWithColumnName(formData: FormData, fileColumnName: string, file: FileList | File | Blob | Object) {
        if (Utils.isNullOrUndefined(file)) {
            return;
        }

        if (file instanceof File) {
            formData.append(fileColumnName, file);
        } else if (file instanceof Blob) {
            formData.append(fileColumnName, file, "unnamed");
        } else if (Utils.isArrayLike(file)) {
            for (let i = 0; i < file.length; i++) {
                const f = file[i];
                ApiUtils.appendFilesWithColumnName(formData, fileColumnName, f);
            }
        }
    }

    static createRequestOptions(...options: (IRequestOptions | null | undefined)[]) {
        return Object.assign({}, ...options);
    }

    static apiPostJsonWithFiles(uri: string, data:any, file: FileList | File | Blob | Object | null=null, 
            options: IRequestOptions | null = null):Promise<HttpResponse> {
        var formData = new FormData();

        if(!Utils.isNullOrUndefined(data))
            formData.append('data', ApiUtils.toJson(data));
        if(!Utils.isNullOrUndefined(file)){
            ApiUtils.appendFiles(formData, file);
        }
        return ApiUtils.apiPostJson(uri, formData, options);
    }

    static apiPostJsonWithFilesAndColumnName(uri: string, data:any, fileColumnName: string, file: FileList | File | Blob | Object | null=null, 
            options: IRequestOptions | null = null): Promise<HttpResponse> {
        var formData = new FormData();

        if(!Utils.isNullOrUndefined(data))
            formData.append('data', ApiUtils.toJson(data));
        if(!Utils.isNullOrUndefined(file)){
            ApiUtils.appendFilesWithColumnName(formData,fileColumnName, file);
        }
        return ApiUtils.apiPostJson(uri, formData, options);
    }

    static GetApiErrorDescription(error: ApiError) {
        var patternName = 'ExceptionCode_' + error.ExceptionCode.toString();
        var desc = error.FormatParams ? i18n.t(patternName, error.FormatParams) : i18n.t(patternName);
        return `${error.ExceptionCode.toString()} - ${desc}`;
    }    
}

export interface IRequestOptions {
    cache?: boolean;
    clearCache?: boolean;
}

export class HttpResponse {
    public data!: string;
    public status!: number;
    public statusText!: string;

    public text(): string {
        return this.data;
    }

    public json<T>(): T | null {
        if (Utils.isNullOrEmpty(this.data)) {
            return null;
        }

        return JSON.parse(this.data) as T;
    }

    public populate(instance: any, json: any) {
        for (let key of Object.keys(json)) {
            instance[key] = json[key];
        }
    }

    public object<T>(constructor: { new(): T }): T {
        const json = this.json<T>();
        if (json === null) {
            throw new Error("data is null");
        }

        const instance = new constructor();
        this.populate(instance, json);
        return instance;
    }

    public nullableObject<T>(constructor: { new(): T }): T | null {
        const json = this.json<T>();
        if (json === null) {
            return null;
        }

        const instance = new constructor();
        this.populate(instance, json);
        return instance;
    }

    public objects<T>(constructor: { new(): T }): T[] | null {
        const json = this.json<T[]>();
        if (Utils.isArray(json)) {
            let result: T[] = [];
            for (let o of json) {
                let instance = new constructor();
                this.populate(instance, o);
                result.push(instance);
            }

            return result;
        }

        return null;
    }

    public get isSuccess() {
        return this.status >= 200 && this.status < 300;
    }

    public localizedErrorMessage(): string {
        return this.data; // TODO
    }

    public toString() {
        return `${this.status} ${this.statusText}\r\n${this.data}`;
    }
}
