import { ExceptionCode } from "@/api/ApiUtils";

export class ApiResult<T> {
    public Errors!: ApiError[];
    public IsValid!: boolean;
    public Value!: T;
}

export class ApiError {
    public ExceptionCode!: ExceptionCode;
    public FormatParams!: string[] | undefined | null;
}