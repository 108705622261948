import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import { ColumnStrongType } from '@/models/Column';
import { OwnerIdType } from "@/models/Row";
import * as RowShare from "@/models/RowShare";
import { Utils } from "@/utils/Utilities";
import i18n from '@/modules/Localization';
import { BigramHelper } from '@/utils/BigramHelper';

export default class MemberColumnProxy extends BaseColumnProxy {
    parseInitialTypedValue(rsColumn: RowShare.Column, rowVM: RowVM): any {
        switch(rsColumn.Type) {
            case ColumnStrongType.StatusChangeByUserName:
                return rowVM.rsRow.StatusChangeByUserName ?? null;
        }
        return rowVM.rsRow.Values[rsColumn.Name];
    }

    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        if(rowVM) {
            return this.parseInitialTypedValue(rsColumn, rowVM);
        }
        return val;
    }

    parse(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : {tooltip: string | null, iconClasses: string[] | null} {
        const rsRow = rowVM?.rsRow;
        const res: {tooltip: string | null, iconClasses: string[] | null} = {tooltip: null, iconClasses: null};

        if(!rsRow) {
            return res;
        }
        let value = this.getFormattedText(rsColumn, rowVM, val);
        let isSurveyUser = (value === RowShare.surveyUserEmail || value === '(' + RowShare.surveyUserEmail + ')');
        res.tooltip = null;
        res.iconClasses = [];
        
        if(rsColumn.isOwnerInfo && !isSurveyUser) {
            res.iconClasses.push('color' + rowVM.rsRow.OwnerBackgroundColorIndex + "--text");
            res.iconClasses.push("fa");
        } else {
            res.iconClasses.push('grey--text');
            res.iconClasses.push("far")
        }

        if(rsColumn.isOwnerInfo && rsRow?.OwnerIdType == OwnerIdType.Group) {
            // groups
            res.iconClasses.push("fa-users")
        } else if(Utils.isNullOrEmpty(value)) {
            // unknown
            res.iconClasses.push("fa-question")
            res.tooltip = i18n.t("MemberRenderer_UnknownUserTooltip").toString();
        } else if(isSurveyUser){
            // survey
            res.iconClasses.push("fa-user")
            res.tooltip = i18n.t("MemberRenderer_SurveyUserTooltip").toString();
        } else {
            // user
            res.iconClasses.push("fa-user")
            const regex = /\([^@]+@[^\.]+\.\w+\)/gi;
            if (regex.test(val)) {
                res.tooltip = i18n.t("MemberRenderer_NoAccountUserTooltip").toString();
            }
            else {
                if(rsColumn?.isEmail){
                    res.tooltip = i18n.t("EmailRenderer_Tooltip", {email: value}).toString();
                }
            }
        }

        return res;
    }

    getFormattedHtmlTooltip(rsColumn: RowShare.Column, rowVM: RowVM, val: any){
        var tooltip = this.parse(rsColumn, rowVM, val).tooltip;

        if(!tooltip)
            return val;

        return `${val}<br/><br/>${tooltip}`;
    }

    getCellIconClasses(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        return this.parse(rsColumn, rowVM, val).iconClasses;
    }
    
    getQuickFilterText(rsColumn: RowShare.Column, rowVM: RowVM, val: any): string {
        return this.getFormattedText(rsColumn, rowVM, val);
    }

    getCustomFilterParams(rsCol: RowShare.Column) {
        var res = super.getCustomFilterParams(rsCol);
        if(rsCol.isOwnerInfo) {
            res.keyCreator = (params: any) => this.getFormattedText(rsCol, params.data, params.value);
        }
        res.convertValuesToStrings = true;
        return res
    }

    compareValues(rsColumn: RowShare.Column, valX: any, valY: any, nodeX: any, nodeY: any): number | null {

        if(!(rsColumn?.isOwnerInfo ?? false)) {
            return null;
        }
        let caseSensitiveX = this.getFormattedText(rsColumn, nodeX.data, valX);
        let caseSensitiveY = this.getFormattedText(rsColumn, nodeY.data, valY);

        let x = caseSensitiveX.toLowerCase();
        let y = caseSensitiveY.toLowerCase();

        // if lower case equal, use a case sensitive sort
        if (x === y) {
            if(caseSensitiveX !== caseSensitiveY)
                return caseSensitiveX > caseSensitiveY ? 1 : -1;

            return 0;
        }
        
        return x > y ? 1 : -1;
        
    }
}