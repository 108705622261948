import * as models from '@/models/RowShare';

export class memberVM extends models.Member{
    IsNew!: boolean;
    IsAdding!:boolean;
    hasError!: boolean;
    initialMember: models.Member | null;
    [key: string]: string | boolean | models.MemberOptions | null | models.Group[] | models.Member | Date;

    constructor(member: models.Member | null){
        super();
        this.initialMember = null;
        if(member){
            Object.assign(this, member);
            this.initialMember = member;
        }
    }    
    
    get InvitationState () { return this.IsUser ? 'accepted' : 'pending'}
}