
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
    import { ListThumbnailInfo } from '@/models/ListTree';
    import { ListTreeModule } from '@/store/ListTreeStore';
    import { ListTreeHelper } from '@/utils/ListTreeHelper';
    import { ConfirmationRequiredEventParams, Event, GlobalNotificationEventParams } from '@/models/Event';
    import { List } from '@/api/Api';
    import { EventBus } from '@/modules/EventBus';

    @Component({
        name:'MoveListToRecycleBin',
        components: { ConfirmationDialog }
    })
    export default class MoveListToRecycleBin extends Vue{
        @Prop() listThumbnail!:ListThumbnailInfo;
        @Prop() folderId!:string;
        @Prop() organizationId!:string;

        openModalRecycleBin: boolean = false;

        openModalToRecycleBin(){
            setTimeout(async ()=> {
                if(await List.IsReferenced(this.listThumbnail.Id)) {
                    EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{
                        message: this.$i18n.t('List_CannotDeleteReferenced').toString(),
                        autoHide: true,
                        autoHideAfterMs: 10000,
                        type: 'error',
                        duplicateId: 'CannotDeleteList'
                    });
                }
                else {
                    EventBus.$emit(Event.CONFIRMATION_REQUIRED, <ConfirmationRequiredEventParams> {
                        title: this.$i18n.t('MyTables_RecycleBinModal_Title').toString(),
                        description1: this.$i18n.t('MyTables_RecycleBinModal_Description',[this.listThumbnail.Name]).toString(),
                        description2: this.$i18n.t('MyTables_RecycleBinModal_Warning').toString(),
                        actionButtonText: this.$i18n.t('MyTables_Actions_MoveToRecycleBin').toString(),
                        actionButtonIcon: "trash-alt",
                        actionButtonColor: "error",
                        onConfirmation: () => this.moveToRecycleBinConfirmed(),
                        cancelButtonText: this.$i18n.t('Form_CancelButton').toString()
                        
                    });
                }
            });
        }

        async moveToRecycleBinConfirmed(){
            if(!ListTreeModule.organizationsTree || !ListTreeModule.listTree)
                return;

            let recycleBin = ListTreeHelper.getRecycleBin(ListTreeModule.listTree, this.organizationId);
            let infoToMoveToRecycleBin = {
                listId:this.listThumbnail.Id,
                folderId:this.folderId,
                organizationId:this.organizationId,
                recycleBin: recycleBin? recycleBin.Id:null
            }

            ListTreeModule.moveToRecycleBin(infoToMoveToRecycleBin);
            this.openModalRecycleBin = false;
        }

        cancelled(){
            this.openModalRecycleBin = false;
        }

             
    }
