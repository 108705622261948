import { AccessLevel, ListAccess } from "@/models/RowShare";

export default class ListAccessVM extends ListAccess {
    status: ListAccessVMStatus = ListAccessVMStatus.Unchanged;
        
    constructor(access: ListAccess) {
        super();
        Object.assign(this, access);
    }

    public get isNew(): boolean {
        return this.status === ListAccessVMStatus.New || this.status === ListAccessVMStatus.NewMember;
    }

    public get isModified(): boolean {
        return this.status === ListAccessVMStatus.Modified;
    }

    public get isDeleted(): boolean {
        return this.status === ListAccessVMStatus.Deleted;
    }

    public get isValid(): boolean {
        if(!this.isAdvancedAccess) {
            return true;
        }

        if((this.Create && this.Read == AccessLevel.none) || this.Update > this.Read || this.Suppress > this.Read || this.Assign > this.Read) {
            return false;
        }

        if(this.Create) {
            if(this.Update == AccessLevel.none) {
                return false;
            }
        }

        return true;
    }
}

export enum ListAccessVMStatus {
    Unchanged,
    Modified,
    New,
    Deleted,
    Invalid,
    NewMember
}