import { DateTime } from '@/utils/Date';
import ListVM from './listVM';
import RowVM from './rowVM';
import * as fc from '@fullcalendar/core';

export default class calendarEventVM implements fc.EventInput {
    listVM: ListVM;
    rowVM: RowVM;
    public id?: string | undefined;
    public title?: string | undefined;
    public allDay?: boolean | undefined;
    public start?: fc.DateInput | undefined;
    public className?: (string | string[]) | undefined;
    
    constructor(listVM: ListVM, rowVM: RowVM, startColumnId: string) {
        this.listVM = listVM;
        this.rowVM = rowVM;
        let rsColumn = this.listVM.columns?.find(c => c.Id == startColumnId);
        if(!rsColumn) {
            console.error('column not found : ', startColumnId);
            return;
        }
        this.id = this.rowVM.rsRow.Id;
        this.title = rowVM.rsRow.DescriptorFormattedValue;
        this.className = 'color' + rowVM.rsRow.OwnerBackgroundColorIndex;
        let startDate = DateTime.parseDateTime(rowVM.rsRow.Values[rsColumn.DisplayName], undefined);
        this.start = startDate;
        this.allDay = rsColumn.isDateFormat;
    }

}