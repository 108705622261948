
import { ICellEditorParams } from 'ag-grid-community';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import FileVM from '@/viewModels/Table/FileVM';
import BlobField from '@/views/Table/cellsComponents/Blob/BlobField.vue';

@Component({name: "BlobEditor", components: { BlobField }})
export default class BlobEditor extends Vue{
    params!: ICellEditorParams;
    pCurrentFile: FileVM | null = null;

    openedWithTab = false;

    get currentFile() : FileVM | null{
        return this.pCurrentFile;
    }
    set currentFile(value : FileVM | null){
        this.pCurrentFile = value;
        this.params.api?.stopEditing();
    }

    mounted(){
        this.pCurrentFile = <FileVM>this.params.value;
        Vue.nextTick(() => {
            if(this.pCurrentFile)
                return;

            let tabRef = (<any>this.params.context)['tabbedIntoCellEditor'];
            let thisTabRef = this.params.data.rsRow.Id + '|' + this.params.colDef.colId;
            
            // reset until next navigation, otherwise tabing, closing, and dbl-clicking would detect a tab instead of dblclick
            (<any>this.params.context)['tabbedIntoCellEditor'] = null;

            if(tabRef === thisTabRef) {
                (<any>this.$refs.blobfield).focusButton();
            } else {
                (<any>this.$refs.blobfield).selectFile();
            }
        });
    }

    isPopup(){
        return true;
    }

    getValue(){
        return this.currentFile;
    }
}
