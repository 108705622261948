
    import {Component, Prop } from 'vue-property-decorator';
    import { FormValidation } from '@/views/components/FormValidation';
    import {  ValidationProvider, ValidationObserver, validate} from 'vee-validate';
    import * as API from '@/api/Api';
    import * as Models from '@/models/RowShare';
    import { OnPremModule } from '@/store/OnPremStore';
    import InputText from '@/views/components/FormInputs/InputText.vue';
    import InputTextArea from '@/views/components/FormInputs/InputTextArea.vue';
    import Toaster from '@/views/layouts/LayoutParts/Toaster.vue';
    import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
    import { EventBus } from "@/modules/EventBus";
    import * as RowShare from "@/models/RowShare";
    import { ListTreeModule } from '@/store/ListTreeStore';
   
    @Component({
        name: 'OrganizationSettings',
        components: {ValidationObserver, InputText, InputTextArea, Toaster, ConfirmationDialog}
    })
    export default class OrganizationSettings extends FormValidation {

        @Prop() organizationId!:string;
        private organization!:Models.Organization;
        private oldOrganization!:Models.Organization;
        private iconImage!:File | null;
        private selectOptions = [{text:'Yes', value:0}, {text:'No', value:1}];
        private toasterTextValue:string='';
        private toasterColorValue:string='';
        private displayToaster:boolean = false;
        private deleteLogoModal:boolean=false;

        $refs!: {
            observer: InstanceType<typeof ValidationObserver>;
        };

        constructor(){
            super();
            this.organization = new Models.Organization();
            this.iconImage = null;
        }

        mounted(){
            API.Organization.load(this.organizationId).then(res=>{
                if(res){
                    this.organization = res;
                    this.oldOrganization = Object.assign({}, res);
                }
            })
        }

        async updateOrganizationSettings(){
            if(JSON.stringify(this.organization) != JSON.stringify(this.oldOrganization)){
                let isValid = await this.$refs.observer.validate();
                if(isValid){
                    try{
                        API.Organization.save(this.organization).then(res=>{
                            if(res){
                                EventBus.$emit(RowShare.Event.ORGANIZATION_CHANGED);
                                this.oldOrganization = Object.assign({}, res);
                                this.organization = res;
                                this.toasterTextValue = this.$t("ManageOrganization_SuccessMessage").toString();
                                this.toasterColorValue = "success"
                                this.displayToaster = true;
                                ListTreeModule.refreshOrganizationListTree(res.Id);
                            }
                        })
                    }
                    catch(Error){
                        this.toasterTextValue = this.$t("ManageOrganization_ErrorMessage").toString();
                        this.toasterColorValue="error"
                        this.displayToaster = true;
                    }
                }
            }
        }

        async onFileChanged(){
            if(this.iconImage && this.iconImage.size>0){
                if(!this.iconImage?.type.startsWith('image/')) {
                    this.organization = Object.assign({}, this.oldOrganization); 
                    this.iconImage = null;
                    this.toasterTextValue = this.$t('Common_InvalidFileType').toString();
                    this.toasterColorValue = "error";
                    this.displayToaster = true;
                }
                else {
                    try{
                        let result = await API.Organization.saveWithFile(this.organization, this.iconImage);
                        if(result){
                            this.oldOrganization = Object.assign({}, result);
                            this.organization = result;
                            this.iconImage = null;
                            this.toasterTextValue = this.$t("ManageOrganization_SuccessMessage").toString();
                            this.toasterColorValue="success"
                            this.displayToaster = true;
                            EventBus.$emit(RowShare.Event.ORGANIZATION_CHANGED);
                            ListTreeModule.refreshOrganizationListTree(result.Id);
                        }
                    }
                    catch(Error){
                        this.organization = Object.assign({}, this.oldOrganization); 
                        this.toasterTextValue = this.$t("ManageOrganization_ErrorMessage").toString();
                        this.toasterColorValue = "error";
                        this.displayToaster = true;
                    }
                }
            }
        }

        getIcon(){
            if (this.organization && this.organization.IconPath)
                return '/' + this.organization.IconPath + "?_ssb_img=S"+ Math.random();
        }

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;
        }

        openModalToDeleteLogo(){
            this.deleteLogoModal = true;
        }

        closeToaster(){
            this.displayToaster = false;
        }

        async deleteLogo(){
            this.organization.IconPath = null;
            this.organization.Logo_Remove = true;
            this.cancelled();
            await this.updateOrganizationSettings();
            
        }

        cancelled(){
            this.deleteLogoModal = false;
        }

    }
