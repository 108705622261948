
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import * as Models from "@/models/RowShare";
    import * as API from "@/api/Api";

    import { ListTreeModule } from '@/store/ListTreeStore';
    import Bigram from "@/views/components/Bigram.vue";
    import { FolderAccess } from '@/models/RowShare';
    import SecurityPrincipalDropdown from "@/views/components/SecurityPrincipalDropdown.vue";

    @Component({name:'PermissionDialog',components: {Bigram, SecurityPrincipalDropdown }})

    export default class PermissionDialog extends Vue{
            @Prop() id!: string | null;
            @Prop() title!:string | null;
            @Prop() description!:string;
            @Prop() folderId!: string;
            @Prop() organizationId!: string;
            @Prop() parentFolderId!: string;
            @Prop() accessMode!: Models.FolderAccessMode;
            @Prop({ default: 'md' }) size!: string | null;
            @Prop({ default: true }) displayInherit!: boolean;
            @Prop() dialogState!: boolean;
            @Prop({default: true}) multiple!: boolean;
            @Prop({default: true}) memberCreationEnabled!: boolean;
            inheritAccess: boolean = true;
            displayAddForm: boolean = false;
            isError: boolean = false;
            newFolderAccess= new FolderAccess();
            currentAccesses = new Array<AccessModel>();
            principals = new Array<groupedOptions<string>>();
            savedAccess = new Array<AccessModel>();
            permissions = new Array<selectOptions<Models.FolderAccessMode>>();
            usersListFolderAccesses: Array<Models.FolderAccess>;
            currentOrganization: Models.Organization | null = null;
            selectedPrincipal: Models.SecurityPrincipal | string | null = null;
            constructor(){
                super();
                this.usersListFolderAccesses = [];
            }

            @Watch('dialogState')
            async onShow(){
                this.currentOrganization = await API.Organization.load(this.organizationId);
                this.displayAddForm = false;
                this.inheritAccess = this.accessMode != Models.FolderAccessMode.DontInherit;
                await this.loadAccesses();
                this.permissions = [
                    { 
                        value: Models.FolderAccessMode.None, 
                        text: this.$i18n
                                .t('MyTables_AuthorizationModal_ExplicitPermissions_PermissionNone')
                                .toString()
                    },
                    { 
                        value: Models.FolderAccessMode.Read, 
                        text: this.$i18n
                                .t('MyTables_AuthorizationModal_ExplicitPermissions_PermissionRead')
                                .toString()
                    }, 
                    { 
                        value: Models.FolderAccessMode.Write, 
                        text: this.$i18n
                                .t('MyTables_AuthorizationModal_ExplicitPermissions_PermissionWrite')
                                .toString()
                    }
                ];
            }
            async loadAccesses() {
                this.usersListFolderAccesses = (await API.Folder.loadAccesses(this.folderId)) ?? [];
            }
            
            private async saveNewFolderAccess(){
                if(this.selectedPrincipal instanceof Models.SecurityPrincipal){
                    if(!(this.newFolderAccess.AccessMode >=0  && (this.selectedPrincipal instanceof Models.SecurityPrincipal))){
                    this.isError = true;   
                } else
                {
                    let folderAccessToSave = new Models.FolderAccess();
                    folderAccessToSave.FolderId = this.folderId;
                    folderAccessToSave.AccessMode = this.newFolderAccess.AccessMode;
                    folderAccessToSave.GroupId = this.selectedPrincipal.GroupId;
                    folderAccessToSave.GroupName = this.selectedPrincipal.GroupName;
                    folderAccessToSave.MemberName = this.selectedPrincipal.MemberName;
                    folderAccessToSave.Id = this.selectedPrincipal.id;
                    folderAccessToSave.MemberEmail = this.selectedPrincipal.MemberEmail;
                    folderAccessToSave.MemberOrganizationId = this.selectedPrincipal.MemberOrganizationId;
                    folderAccessToSave.Options = this.selectedPrincipal.Options;
                    let newCreatedFolderAccess = await API.Folder.saveAccess(folderAccessToSave);
                    if(newCreatedFolderAccess){
                        this.usersListFolderAccesses.push(newCreatedFolderAccess);
                    }
                    this.toggleAddForm();
                }
                }
            }

            private toggleAddForm(): void {
                if(!this.displayAddForm) 
                    this.newFolderAccess = new FolderAccess();
                this.displayAddForm = !this.displayAddForm;
                this.isError = false;
                this.selectedPrincipal=null;
            }

            hideModal(): void {
                // this.dialogDisplay = false;
                this.displayAddForm = false;
            }
            
            async toggleInheritMode(): Promise<void> {
                let inheritConfig = {
                    Id: this.folderId,
                    ParentId: this.parentFolderId,
                    AccessMode: this.inheritAccess ? 
                                        Models.FolderAccessMode.None : 
                                        Models.FolderAccessMode.DontInherit,
                    OrganizationId: this.organizationId
                };
                await ListTreeModule.setInheritAccess(inheritConfig);
            }

            onCancel(){
                this.$emit('cancel');
            }

            removeAccess(access: Models.FolderAccess) {
                API.Folder.deleteAccess(access).then((deletedAccess) => {
                    if(deletedAccess){
                        this.loadAccesses();
                    }
                }).catch((error) => {
                console.error('Une erreur s\'est produite lors de la suppression de Folder Access :', error)});
            }
            public onChangeSaveFolderAccess(access: Models.FolderAccess,index: number) {
                API.Folder.saveAccess(access).then((savedAccess) => {
                    if(savedAccess) {
                        this.usersListFolderAccesses[index].Guest.DisplayName = savedAccess.guestName;
                        this.usersListFolderAccesses[index].AccessMode = savedAccess.AccessMode;
                    }
                }).catch((error) => {
                console.error('Une erreur s\'est produite lors de la sauvegarde de Folder Access :', error);
            });
        }
        public get currentDisplayedPrincipals() {
            return this.usersListFolderAccesses.map((currentListOfFolderAccess)=> {
                const principal = new Models.SecurityPrincipal();
                principal.GroupId = currentListOfFolderAccess.GroupId;
                principal.GroupName = currentListOfFolderAccess.GroupName;
                principal.MemberEmail = currentListOfFolderAccess.MemberEmail;
                principal.MemberOrganizationId = currentListOfFolderAccess.MemberOrganizationId;
                principal.MemberName = currentListOfFolderAccess.MemberName;
                principal.Options = currentListOfFolderAccess.Options;
                return principal;
            });
        }
       public get customPlaceHolder(){
         return  this.$i18n.t('MyTables_AuthorizationModal_PrincipalPlaceholder').toString();
       }
    }

    class selectOptions<T> {
        value!: T | null;
        text!: string;
    }

    class groupedOptions<T> {
        options!: Array<selectOptions<T>>;
        label!: string;
    }

    class AccessModel{
        principal!: string | null;
        permission!: Models.FolderAccessMode;
    }

