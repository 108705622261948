import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/modules/Store";
import * as RowShare from "@/models/RowShare";
import * as API from "@/api/Api";
import { Utils } from "@/utils/Utilities";

@Module({ dynamic: true, store: store, name: 'ListStore'})
export class ListStore extends VuexModule {

    /** Getters **/
    currentList: RowShare.List | null = null;
    currentAccesses: Array<RowShare.ListAccess> | null = null;

    /** Mutations **/
    @Mutation
    loadCurrentListIntoStore(list: RowShare.List): void {
        this.currentList = list;
    }

    @Mutation
    loadCurrentListAccessesIntoStore(accesses: Array<RowShare.ListAccess>): void {
        this.currentAccesses = accesses;
    }

    @Mutation
    updateListAccess(access: RowShare.ListAccess): void {
        if(!access || !this.currentAccesses){
            return;
        }
        let accessIndex = this.currentAccesses?.findIndex(a => a.Id == access.Id);
        if(!Utils.isNumber(accessIndex) || accessIndex < 0){
            this.currentAccesses?.push(access);
        }
        else
        {
            this.currentAccesses[accessIndex] = access;
        }
    }

    @Mutation
    removeListAccess(access: RowShare.ListAccess): void {
        if(!access || !this.currentAccesses){
            return;
        }
        let accessIndex = this.currentAccesses.findIndex(a => a.Id == access.Id);
        if(Utils.isNumber(accessIndex) && accessIndex >= 0){
            this.currentAccesses.splice(accessIndex, 1);
        }
    }

    /** Actions **/
    @Action({rawError: true})
    async refreshCurrentList(listId: string) {
        let list = await API.List.load(listId);
        this.context.commit('loadCurrentListIntoStore', list);
    }

    @Action({rawError: true})
    async refreshCurrentAccesses(listId: string) {
        let accesses = await API.ListAccess.loadByList(listId);
        this.context.commit('loadCurrentListAccessesIntoStore', accesses);
    }

    @Action({rawError: true})
    async saveListAccess(payload: {access: RowShare.ListAccess, sendNotification: boolean}) {
        if(!payload || ! payload.access){
            return;
        }
        let updatedAccess = await API.ListAccess.save(payload.access, payload.sendNotification);
        this.context.commit('updateListAccess', updatedAccess);
    }

    @Action({rawError: true})
    async saveList(list: RowShare.List) {
        if(!list){
            return;
        }
        let updatedList = await API.List.save(list);
        this.context.commit('loadCurrentListIntoStore', updatedList);
    }

    @Action({rawError: true})
    async deleteListAccess(access: RowShare.ListAccess) {
        await API.ListAccess.remove(access);
        this.context.commit('removeListAccess', access);
    }
}

export const ListModule = getModule(ListStore);