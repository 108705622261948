
import { Component, Model, Prop, Vue } from "vue-property-decorator";
import * as API from "@/api/Api";
import PageLoadSpinner from "@/views/layouts/LayoutParts/PageLoadSpinner.vue";
import { AgGridVue } from "ag-grid-vue";
import * as RowShare from "@/models/RowShare";
import * as agGrid from "ag-grid-community";
import { EventBus } from "@/modules/EventBus";
import ConfirmationDialog from "@/views/components/ConfirmationDialog.vue";
import memberEditor from "@/views/ManageOrganization/manageOrganizationGrids/memberEditor.vue";
import groupsEditor from "@/views/ManageOrganization/manageOrganizationGrids/groupsEditor.vue";
import booleanRenderer from "@/views/ManageOrganization/manageOrganizationGrids/booleanRenderer.vue";
import memberRenderer from "@/views/ManageOrganization/manageOrganizationGrids/memberRenderer.vue";
import groupsRenderer from "@/views/ManageOrganization/manageOrganizationGrids/groupsRenderer.vue";
import deleteRowRenderer from "@/views/ManageOrganization/manageOrganizationGrids/deleteRowRenderer.vue";
import { RowShareException } from "@/api/ApiUtils";
import Toaster from "@/views/layouts/LayoutParts/Toaster.vue";
import { Location as RouterLocation } from "vue-router";
import { gridUtils } from "@/utils/Grid";
import { memberVM } from "@/viewModels/ManageOrganization/memberVM";
import { RouterUtils } from "@/modules/Router";
import { Utils } from "@/utils/Utilities";
import memberEmailRenderer from "./manageOrganizationGrids/memberEmailRenderer.vue";
import EditInvitationDialog from "./EditInvitationDialog.vue";
import InviteLinkDialog from "./InviteLinkDialog.vue";
import { RecaptchaActions } from "@/views/components/Recaptcha.vue";
import Recaptcha from "@/views/components/Recaptcha.vue";
import { OnPremModule } from "@/store/OnPremStore";
import { random } from "lodash";

@Component({
    name: "Members",
    components: {
        PageLoadSpinner,
        AgGridVue,
        ConfirmationDialog,
        Toaster,
        EditInvitationDialog,
        InviteLinkDialog,
        Recaptcha,
        memberEmailRenderer,
        memberEditor,
        groupsEditor,
        groupsRenderer,
        memberRenderer,
        booleanRenderer,
        deleteRowRenderer,
    },
})
export default class Members extends Vue {
    @Prop() organizationId!: string;
    orgMembers: memberVM[] | null = null;
    memberListColumns: agGrid.ColDef[] = [];
    gridApi!: agGrid.GridApi;
    columnApi!: agGrid.ColumnApi;
    gridOptions!: agGrid.GridOptions;
    organization: RowShare.Organization = new RowShare.Organization();
    showRemoveModal: boolean = false;
    memberRowToDelete: agGrid.IRowNode | null = null;
    toasterText: string = "";
    toasterColor: string = "";
    showToaster: boolean = false;
    defaultColumnSettings: agGrid.ColDef = {};
    toasterTimeOut: number = 2000;
    membersToSave: Promise<RowShare.Member | null>[] = [];
    currentMember: memberVM | null = null;
    currentNode: agGrid.IRowNode | null = null;
    currentIndex: number | null = 0;
    currentColumn: agGrid.Column | null = null;
    recaptchaToken: string | null = null;
    recaptchaTokenIsV3: boolean | null = true;
    isEditing: boolean = false;
    isFiltered: boolean = false;

    gridReady(params: agGrid.GridReadyEvent) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    onRowDataChanged() {
        this.gridApi.sizeColumnsToFit();
    }

    created() {
        EventBus.$on(RowShare.Event.RECAPTCHA_SUCCEEDED, this.onRecaptchaSucceeded);
        EventBus.$on([RowShare.Event.RECAPTCHA_FAILED, RowShare.Event.RECAPTCHA_CANCELEDV2], this.onRecaptchaFailedOrCanceled);
        EventBus.$on(RowShare.Event.ORGANIZATION_CHANGED, this.onOrganizationChanged);
        EventBus.$on(RowShare.Event.GROUP_CHANGED, this.loadMembers);
    }

    destroyed() {
        EventBus.$off([
            RowShare.Event.RECAPTCHA_SUCCEEDED,
            RowShare.Event.RECAPTCHA_FAILED,
            RowShare.Event.RECAPTCHA_CANCELEDV2,
            RowShare.Event.ORGANIZATION_CHANGED,
            RowShare.Event.GROUP_CHANGED
        ]);
    }

    beforeMount() {
        this.defaultColumnSettings = {
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            filterParams: {
                buttons: ["reset", "apply"],
                closeOnApply: true,
                excelMode: "windows",
                refreshValuesOnOpen: true,
                caseSensitive: true,
            },
            headerClass: "colHeader",
        };
        this.memberListColumns = [
            {
                headerName: this.$i18n.t("MemberGrid_ColTitle_Email").toString(),
                field: "Email",
                sortable: true,
                comparator: gridUtils.stringComparator,
                filter: true,
                editable: (params) => this.isEmailEditable(params),
                cellRenderer: "memberEmailRenderer",
                resizable: true,
            },
            {
                headerName: this.$i18n.t("MemberGrid_ColTitle_Name").toString(),
                field: "Name",
                sortable: true,
                comparator: gridUtils.stringComparator,
                filter: true,
                editable: true,
                resizable: true,
            },
            {
                headerName: this.$i18n.t("MemberGrid_ColTitle_Manager").toString(),
                field: "Manager",
                sortable: true,
                resizable: true,
                comparator: gridUtils.memberComparator,
                filter: true,
                filterValueGetter: (params) => this.getManagerName(params),
                editable: true,
                cellRenderer: "memberRenderer",
                cellEditor: "memberEditor",
            },
            {
                headerName: this.$i18n.t("MemberGrid_ColTitle_Groups").toString(),
                field: "Groups",
                editable: true,
                resizable: true,
                cellRenderer: "groupsRenderer",
                cellEditor: "groupsEditor",
                cellEditorParams: { organizationId: this.organizationId },
            },
            {
                headerName: this.$i18n.t("MemberGrid_ColTitle_Enabled").toString(),
                field: "IsEnabled",
                sortable: true,
                filter: true,
                filterValueGetter: (params) => this.boolValueToString(params),
                cellRenderer: "booleanRenderer",
                width: 110,
            },
            {
                headerName: this.$i18n.t("MemberGrid_ColTitle_ROMember").toString(),
                field: "IsReadOnly",
                sortable: true,
                filter: true,
                filterValueGetter: (params) => this.boolValueToString(params),
                cellRenderer: "booleanRenderer",
                width: 115,
            },
            {
                headerName: this.$i18n
                    .t("MemberGrid_ColTitle_Administrator")
                    .toString(),
                field: "IsAdministrator",
                sortable: true,
                filter: true,
                filterValueGetter: (params) => this.boolValueToString(params),
                cellRenderer: "booleanRenderer",
                width: 120,
            },
            {
                headerName: this.$i18n
                    .t("MemberGrid_ColTitle_UniversalOwner")
                    .toString(),
                field: "IsUniversalOwner",
                sortable: true,
                filter: true,
                filterValueGetter: (params) => this.boolValueToString(params),
                cellRenderer: "booleanRenderer",
                flex: 1,
                minWidth: 140,
            },
            {
                cellClass: "noGridCell",
                headerClass: "emptyColHeader",
                width: 30,
                cellRenderer: "deleteRowRenderer",
                sortable: false,
                filter: false,
                pinned: "right",
                lockPosition: true,
                lockVisible: true,
                lockPinned: true,
                suppressMenu: true,
                onCellClicked: (params) => this.confirmRemoveMember(params),
            },
        ];
        this.gridOptions = {
            stopEditingWhenCellsLoseFocus: true,
            rowClassRules: {
                "grid-row-error": (params) => this.getRowClassRules(params),
            },
            getLocaleText: (params) => this.getLocaleText(params),
            getContextMenuItems: this.getContextMenuItems,
            onRowDataChanged: () => this.onRowDataChanged(),
        };
        this.onOrganizationChanged();
        this.loadMembers();
    }

    loadMembers() {
        this.orgMembers = null;
        API.Member.loadForParent(this.organizationId).then((result) => {
            if (result) {
                this.orgMembers = result.map((m) => new memberVM(m));
            }
        });
    }

    getManagerName(params: agGrid.ValueGetterParams) {
        let member = params.data as memberVM;
        if (member && member.Manager) {
            return member.Manager.Name;
        }
        return "";
    }

    boolValueToString(params: agGrid.ValueGetterParams) {
        let member = params.data as memberVM;
        if (member && params.colDef.field) {
            let value = member[params.colDef.field];
            if (value === true) {
                return this.$i18n.t("Common_Yes").toString();
            }
        }
        return this.$i18n.t("Common_No").toString();
    }

    getLocaleText(params: any): string {
        if (params.key && params.key != '') {
            var key = 'Grid_' + params.key;
            if (this.$i18n.te(key)) {
                return this.$i18n.t(key, params.variableValues).toString();
            }
            return params.key
        }
        return '';
    }

    getRowClassRules(params: any): boolean {
        return params.data.hasError === true;
    }

    async cellValueChanged(event: agGrid.CellValueChangedEvent) {
        if (this.membersToSave.length > 0) {
            await Promise.all(this.membersToSave);
            this.gridApi.refreshClientSideRowModel();
        }
        if (event?.node?.id) {
            let member = this.gridApi.getRowNode(event.node.id)?.data; //event.data as memberVM;
            if (
                member &&
                event.node &&
                event.rowIndex != null &&
                event.newValue != event.oldValue
            ) {
                await this.saveMember(member, event.node, event.rowIndex, event.column);
            }
        }
    }

    public getContextMenuItems(
        params: agGrid.GetContextMenuItemsParams
    ): (string | agGrid.MenuItemDef)[] {
        var menuItems: (string | agGrid.MenuItemDef)[] = [];

        if (!params.node?.data.IsUser)
            menuItems.push({
                name: this.$i18n.t("MemberGrid_ResendInvitationMail").toString(),
                action: () => {
                    if (params.node) API.Member.resendInvitation(params.node.data);
                },
            });

        return menuItems;
    }

    async saveMember(
        member: memberVM,
        node: agGrid.IRowNode,
        rowIndex: number,
        column: agGrid.Column
    ) {
        if (this.membersToSave.length > 0) {
            await Promise.all(this.membersToSave);
            this.gridApi.refreshClientSideRowModel();
        }

        if (
            !member ||
            !member.Email ||
            member.Email == "" ||
            !Utils.validateEmail(member.Email)
        ) {
            this.toasterText = this.$i18n.t("MemberGrid_InvalidEmail").toString();
            this.toasterTimeOut = 10000;
            this.toasterColor = "error";
            this.showToaster = true;

            member.hasError = true;
            node.setData(member);
            return;
        }
        if (member.IsNew) {
            member.IsAdding = true;
            node.setData(member);
        }
        if (this.mustShowRecaptcha) {
            this.currentMember = member;
            this.currentNode = node;
            this.currentIndex = rowIndex;
            this.currentColumn = column;
            this.triggerRecaptcha();
        } else {
            this.doSaveMember(member, node, rowIndex, column);
        }
    }

    async doSaveMember(
        member: memberVM,
        node: agGrid.IRowNode,
        rowIndex: number,
        column: agGrid.Column
    ) {
        this.showToaster = false;
        let promise = API.Member.save(member);
        this.membersToSave.push(promise);
        promise
            .then((m) => {
                if (m && this.gridApi) {
                    let mvm = new memberVM(m);
                    mvm.IsAdding = false;
                    node.setData(mvm);
                    this.gridApi.refreshClientSideRowModel();
                }
                EventBus.$emit(RowShare.Event.ORGANIZATION_CHANGED);
            })
            .catch((exc) => {
                let errorMessage = this.$i18n
                    .t("MemberGrid_ErrorSavingMember")
                    .toString();
                let rsExc = exc as RowShareException;
                if (rsExc) {
                    if (rsExc.code == 96 && this.mustShowRecaptcha) {
                        // Recaptcha token not valid
                        this.retryFallbackRecaptcha();
                        return;
                    }
                    errorMessage = errorMessage + ":" + rsExc.message;
                }
                this.displayErrorOnNewMember(errorMessage, member, node);
            })
            .finally(() => {
                this.membersToSave.splice(this.membersToSave.indexOf(promise), 1);
            });
    }

    displayErrorOnNewMember(
        errorMessage: string,
        member: memberVM,
        node: agGrid.IRowNode) {
        this.toasterText = errorMessage;
        this.toasterTimeOut = 10000;
        this.toasterColor = "error";
        this.showToaster = true;
        if (!member.hasError) {
            member.hasError = true;
        }
        member.IsAdding = false;
        if (!member.IsNew) {
            node.setData(new memberVM(member.initialMember));
        } else {
            node.setData(member);
        }
        this.gridApi.refreshClientSideRowModel();
    }

    triggerRecaptcha() {
        this.currentMember!.RecaptchaToken = "";
        EventBus.$emit(
            RowShare.Event.RECAPTCHA_SHOW,
            RecaptchaActions.ManageOrganization
        );
    }

    onRecaptchaSucceeded(successEvent: any) {
        this.currentMember!.RecaptchaToken = successEvent.token;
        this.currentMember!.RecaptchaTokenIsV3 = successEvent.isV3;
        this.currentMember!.IsAdding = false;

        this.doSaveMember(
            this.currentMember!,
            this.currentNode!,
            this.currentIndex!,
            this.currentColumn!
        );
    }

    retryFallbackRecaptcha() {
        this.currentMember!.RecaptchaToken = "";
        EventBus.$emit(RowShare.Event.RECAPTCHA_SHOWV2);
    }

    onRecaptchaFailedOrCanceled(reason: string) {
        this.currentMember!.RecaptchaToken = "";
        this.currentMember!.RecaptchaTokenIsV3 = true;
        this.currentMember!.IsAdding = false;
        let message = this.$i18n.t("Recaptcha_Invalid").toString();
        if (reason)
            message = message + " : " + reason;
        this.displayErrorOnNewMember(message, this.currentMember!,
            this.currentNode!);
        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED,
            <RowShare.GlobalNotificationEventParams>{
                autoHide: true,
                autoHideAfterMs: 3000,
                type: RowShare.NotificationType.error,
                message: message,
            });
    }

    closeToaster() {
        this.showToaster = false;
    }

    isEmailEditable(params: agGrid.ColumnFunctionCallbackParams): boolean {
        let member = params.data as memberVM;
        return member.IsNew;
    }

    addNewMember() {
        if (this.gridApi) {
            let newMember = new memberVM(null);
            newMember.OrganizationId = this.organizationId;
            newMember.IsNew = true;
            var rowNodeTransactions = this.gridApi.applyTransaction({
                add: [newMember],
            });
            if (
                rowNodeTransactions &&
                rowNodeTransactions.add &&
                rowNodeTransactions.add.length > 0
            ) {
                var rowIndex = rowNodeTransactions.add[0].rowIndex;
                this.gridApi.refreshClientSideRowModel();
                if (rowIndex != undefined) {
                    let editCell: agGrid.StartEditingCellParams = {
                        rowIndex: rowIndex,
                        colKey: "Email",
                    };
                    this.gridApi.startEditingCell(editCell);
                }
            }
        }
    }

    onOrganizationChanged() {
        API.Organization.load(this.organizationId, { cache: false }).then((org) => {
            if (org) {
                this.organization = org;
            }
        });
    }

    confirmRemoveMember(params: agGrid.ColumnFunctionCallbackParams) {
        this.memberRowToDelete = params.node as agGrid.IRowNode;
        this.showRemoveModal = true;
    }

    deleteMember() {
        if (this.memberRowToDelete) {
            let memberToDelete = this.memberRowToDelete.data as memberVM;
            // If member is new it as not yet been saved so no need to call API to remove it
            if (memberToDelete.IsNew) {
                this.removeMemberFromGrid(memberToDelete);
            } else {
                API.Member.remove(memberToDelete).then((result) => {
                    if (result === true) {
                        this.removeMemberFromGrid(memberToDelete);
                    }
                });
            }
        }
        this.showRemoveModal = false;
        this.memberRowToDelete = null;
    }

    removeMemberFromGrid(memberToRemove: memberVM) {
        EventBus.$emit(RowShare.Event.ORGANIZATION_CHANGED);
        this.gridApi.applyTransaction({ remove: [memberToRemove] });
    }

    cancelMemberDeletion() {
        this.memberRowToDelete = null;
        this.showRemoveModal = false;
    }

    cellEditingStarted() {
        this.isEditing = true;
    }

    cellEditingStopped(params: agGrid.ColumnFunctionCallbackParams) {
        this.isEditing = false;
    }

    get canAddNewRow(): boolean {
        return !this.isEditing && !this.isFiltered;
    }

    onFilterChanged(event: agGrid.FilterChangedEvent) {
        this.isFiltered = event.api?.isAnyFilterPresent();
    }

    get AddNewRowToolTip(): string {
        if (this.isFiltered) {
            return this.$i18n.t('MemberGrid_CannotAddNewWhenFiltered').toString();
        }
        return "";
    }

    get getButtonLabel(): string {
        if (this.organization.InTrial) {
            return this.$t("ManageOrganization_Checkout").toString();
        } else return this.$t("ManageOrganization_UpdateSubscription").toString();
    }

    get checkoutLink() {
        const queryStringReturnUrl = RouterUtils.getCaseInsentiveQuery(
            this.$route,
            "ReturnUrl"
        );

        return <RouterLocation>{
            name: "SubscriptionCheckOut",
            params: { organizationId: this.organizationId },
            query: {
                ReturnUrl: queryStringReturnUrl
                    ? queryStringReturnUrl
                    : this.$route.fullPath,
            },
        };
    }

    get contactLink() {
        return <RouterLocation>{
            name: "Contact",
            query: {
                ReturnUrl: RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl"),
            },
        };
    }

    get memberToDeleteInfos() {
        if (this.memberRowToDelete) {
            return {
                memberName: this.memberRowToDelete.data.Name,
                memberEmail: this.memberRowToDelete.data.Email,
            };
        }
        return null;
    }

    get isNotOnPrem() {
        return !OnPremModule.isOnPrem;
    }

    get mustShowRecaptcha() {
        return this.isNotOnPrem && process.env.NODE_ENV != "development";
    }

    updateSubscription() {
        if(this.organization.InTrial) {
            this.$router.push(this.checkoutLink);
        }
        else {
            EventBus.$emit(RowShare.Event.UPDATE_SUBSCRIPTION_REQUEST, <RowShare.updateSubscriptionRequestEventParams> { organizationId: this.organizationId});
        }
    }
}
