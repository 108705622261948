import * as agGrid from 'ag-grid-community';
import { SettingNames, Settings } from "@/utils/Settings";
import ListDisplayModeVM from '@/viewModels/Table/listDisplayModeVM';

export class ListConfiguration {
    hiddenColumns!: string[];
    filters!: any;
    sortOrder!: agGrid.ColumnState[];
    viewId: string | null = null;
    sideBar!: SidebarConfiguration;
    collapsedColumnGroups: string[] = [];
    groups: rowGroupStatus[] = [];

    constructor() {
        this.sideBar = new SidebarConfiguration();
    }
}

export class valueColumn {
    colId!: string;
    aggFunction!: string | agGrid.IAggFunc | null | undefined;
}

export class SidebarConfiguration {
    rowGroupColumns!: string[];
    valueColumns!: valueColumn[];
    visibleToolPanelId!: string | undefined;
}

export class rowGroupStatus {
    expanded!: boolean;
    key!: string;
    rowGroupColumnId!: string;
}

export class ListSettings {
    configuration: ListConfiguration | null = null;

    public static loadListSettingsFromLocalStorage(listId: string): ListSettings {
        let settings = new ListSettings();
        settings.configuration = Settings.getJson(listId, SettingNames.configuration);
        return settings;
    }

    public static saveListSettingsToLocalStorage(listId: string, settings: ListSettings) {
        if(!listId) {
            return;
        }

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(listId);
        Object.assign(currentSettings, settings);

        Settings.setJson(listId, SettingNames.configuration, currentSettings?.configuration ?? null);
    }

    public static saveHiddenColumns(displayModeVM: ListDisplayModeVM) {
        const hiddenCols = displayModeVM.getHiddenColumnsIds();
        if(!hiddenCols || !displayModeVM.listVM.list || displayModeVM.listVM.specialRowsDisplayed) {
            return;
        }

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(displayModeVM.listVM.list.Id) ?? new ListSettings();
        if(!currentSettings.configuration) {
            currentSettings.configuration = new ListConfiguration();
        }
        currentSettings.configuration.hiddenColumns = [];

        hiddenCols.forEach(cId => currentSettings.configuration?.hiddenColumns.push(cId));

        ListSettings.saveListSettingsToLocalStorage(displayModeVM.listVM.list.Id, currentSettings);
    }

    public static saveGroups(listId: string, groupStatus: rowGroupStatus) {
        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(listId) ?? new ListSettings();
        if (!currentSettings.configuration) {
            currentSettings.configuration = new ListConfiguration();
        }
        currentSettings.configuration.groups = currentSettings.configuration.groups ?? [];

        let existingGroup = currentSettings.configuration.groups.find(gs => gs.key === groupStatus.key);
        if (existingGroup) {
            existingGroup.rowGroupColumnId = groupStatus.rowGroupColumnId;
            existingGroup.expanded = groupStatus.expanded;
        } else {
            currentSettings.configuration.groups.push(groupStatus);
        }

        ListSettings.saveListSettingsToLocalStorage(listId, currentSettings);
    }

    public static saveCollapsedColumnGroups(displayModeVM: ListDisplayModeVM) {
        const collapsedColumnGroups = displayModeVM.getCollapsedColumnGroups();
        if(!collapsedColumnGroups || !displayModeVM.listVM.list || displayModeVM.listVM.specialRowsDisplayed) {
            return;
        }

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(displayModeVM.listVM.list.Id) ?? new ListSettings();
        if(!currentSettings.configuration) {
            currentSettings.configuration = new ListConfiguration();
        }
        currentSettings.configuration.collapsedColumnGroups = [];
        collapsedColumnGroups.forEach(ccg => currentSettings.configuration?.collapsedColumnGroups.push(ccg));

        ListSettings.saveListSettingsToLocalStorage(displayModeVM.listVM.list.Id, currentSettings);
    }

    public static saveFilters(displayModeVM: ListDisplayModeVM) {
        if(!displayModeVM || !displayModeVM.listVM.list || displayModeVM.printMode || displayModeVM.displayedRowIds != null || displayModeVM.listVM.specialRowsDisplayed) {
            return;
        }

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(displayModeVM.listVM.list.Id);
        if(!currentSettings.configuration)
            currentSettings.configuration = new ListConfiguration();

        currentSettings.configuration.filters = displayModeVM.getCurrentFilters();

        ListSettings.saveListSettingsToLocalStorage(displayModeVM.listVM.list.Id, currentSettings);
    }

    public static saveSortOrder(displayModeVM: ListDisplayModeVM) {
        if(!displayModeVM || !displayModeVM.listVM.list || displayModeVM.printMode || displayModeVM.listVM.specialRowsDisplayed) {
            return;
        }

        const sortOrder = displayModeVM.getCurrentSort();
        if(!sortOrder)
            return;

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(displayModeVM.listVM.list.Id);
        if(!currentSettings.configuration)
            currentSettings.configuration = new ListConfiguration();

        currentSettings.configuration.sortOrder = sortOrder;

        ListSettings.saveListSettingsToLocalStorage(displayModeVM.listVM.list.Id, currentSettings);
    }

    public static saveRowGroupColumns(displayModeVM: ListDisplayModeVM) {
        if(!displayModeVM || !displayModeVM.listVM.list || displayModeVM.printMode || displayModeVM.listVM.specialRowsDisplayed) {
            return;
        }

        const rowGroupColumns = displayModeVM.getRowGroupColumnsIds();
        if(!rowGroupColumns) {
            return;
        }

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(displayModeVM.listVM.list.Id);
        if(!currentSettings.configuration) {
            currentSettings.configuration = new ListConfiguration();
        }
        if(!currentSettings.configuration.sideBar) {
            currentSettings.configuration.sideBar = new SidebarConfiguration();
        }

        currentSettings.configuration.sideBar.rowGroupColumns = rowGroupColumns;
        ListSettings.saveListSettingsToLocalStorage(displayModeVM.listVM.list.Id, currentSettings);
    }

    public static saveValueColumns(displayModeVM: ListDisplayModeVM) {
        if(!displayModeVM || !displayModeVM.listVM.list || displayModeVM.printMode || displayModeVM.listVM.specialRowsDisplayed) {
            return;
        }

        const valueColumns = displayModeVM.getValueColumns();
        if(!valueColumns) {
            return;
        }

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(displayModeVM.listVM.list.Id);
        if(!currentSettings.configuration) {
            currentSettings.configuration = new ListConfiguration();
        }
        if(!currentSettings.configuration.sideBar) {
            currentSettings.configuration.sideBar = new SidebarConfiguration();
        }

        // Utiliser l'api setColumnAggFunc de l'api column d'aggrid
        currentSettings.configuration.sideBar.valueColumns = valueColumns;
        ListSettings.saveListSettingsToLocalStorage(displayModeVM.listVM.list.Id, currentSettings);
    }

    public static saveToolPanelsStatus(displayModeVM: ListDisplayModeVM) {
        if(!displayModeVM || !displayModeVM.listVM.list || displayModeVM.printMode || displayModeVM.listVM.specialRowsDisplayed) {
            return;
        }

        const visibleToolPanel = displayModeVM.getVisibleToolPanelId();

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(displayModeVM.listVM.list.Id);
        if(!currentSettings.configuration) {
            currentSettings.configuration = new ListConfiguration();
        }

        if(!currentSettings.configuration.sideBar) {
            currentSettings.configuration.sideBar = new SidebarConfiguration();
        }

        currentSettings.configuration.sideBar.visibleToolPanelId = visibleToolPanel;
        ListSettings.saveListSettingsToLocalStorage(displayModeVM.listVM.list.Id, currentSettings);
    }
}