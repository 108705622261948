
import { EventBus } from '@/modules/EventBus';
import { Component, Vue, Prop } from 'vue-property-decorator';
import RowFormView from '@/views/Table/FormView/RowFormView.vue'
import ListVM from '@/viewModels/Table/listVM';
import { Event, ShowSidePanelFormViewEventParams } from '@/models/RowShare';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';

@Component({name: 'SidePanelFormView', components: { RowFormView, PageLoadSpinner }})
export default class SidePanelFormView extends Vue {
    showMe: boolean = false;
    listVM: ListVM | null = null;
    rowId: string = "";
    width: string = "0px";

    created() {
        EventBus.$on(Event.SHOW_SIDEPANEL_FORMVIEW, (event: ShowSidePanelFormViewEventParams) => this.onShowSidePanelFormView(event));
    }

    beforeDestroy() {
        EventBus.$off(Event.SHOW_SIDEPANEL_FORMVIEW);
    }

    onShowSidePanelFormView(event: ShowSidePanelFormViewEventParams) {
        this.listVM = event.ListVM;
        this.rowId = event.rowId ?? "";
        this.width = "33%";
        setTimeout(() => { this.showMe = true;}, 0);
    }

    onClickOutside() {
        if(this.showMe) {
            this.onCancel();
        }
    }

    onCancel() {
        this.listVM = null;
        this.rowId = "";
        this.showMe = false;
        this.width = "0px";
    }

    shouldClosePanel(event: any): boolean {
        if(!event?.target?.classList) {
            return false;
        }
        return event.target.classList.contains('v-overlay__scrim');
    }
}
