
import { Component } from 'vue-property-decorator';
import { UserModule } from '@/store/UserStore';
import { OnPremModule } from '@/store/OnPremStore';
import PageTitle from '@/views/layouts/LayoutParts/PageTitle.vue';
import { User } from '@/models/User';
import { Timezone } from '@/models/Timezone';
import * as API from '@/api/Api';
import * as RowShare from '@/models/RowShare';
import InputText from '@/views/components/FormInputs/InputText.vue';
import InputSelect from '@/views/components/FormInputs/InputSelect.vue';
import MyAccountNotificationList from '@/views/MyAccount/MyAccountNotificationList.vue';
import MyAccountSubscriptionList from '@/views/MyAccount/MyAccountSubscriptionList.vue';
import MyAccountDeleteAccountButton from '@/views/MyAccount/MyAccountDeleteAccountButton.vue';
import { ValidationProvider, validate } from 'vee-validate';
import { FormValidation } from "@/views/components/FormValidation";
import { UserNotificationUpdate } from "@/models/UserNotificationLink";
import Bandeau from '@/views/layouts/LayoutParts/Bandeau.vue';

import _ from "lodash";

@Component({
    name: "MyAccount",
    components: { 
        PageTitle,
        InputText, InputSelect, ValidationProvider, MyAccountSubscriptionList,
        MyAccountNotificationList, Bandeau, MyAccountDeleteAccountButton}
})
export default class MyAccount extends FormValidation  {

    private deleteModalElementId = "delete-account";

    private user: User;
    private timezones: Array<Timezone> | null;
    private organizations: Array<RowShare.Organization> | null;
    private relatedLists: Array<RowShare.UserNotificationLink>;

    constructor(){
        super();
        this.user = new User();
        this.timezones = new Array<Timezone>();
        this.organizations = new Array<RowShare.Organization>();
        this.relatedLists = new Array<RowShare.UserNotificationLink>();
    }

    async mounted(){
        this.user = await this.getUserProfile();
        let userLanguage: string = await UserModule.getUserLanguage();
        this.timezones = await API.TimezoneService.loadByCulture(userLanguage);
        this.organizations = await API.Organization.loadall();
        this.relatedLists = await this.getUserRelatedLists();
    }

    get orderedRelatedTables(): Array<RowShare.UserNotificationLink>{
        return _.orderBy(this.relatedLists, 'ListDisplayName');
    }

    get isNotOnPrem(){
        return !OnPremModule.isOnPrem;;
    }

    async getUserProfile() : Promise<User> {
        let profile : User | null = null;
        await API.User
            .load({ cache : false })
            .then(profil => {
                profile = profil;
            });
        return profile ? profile : new User();
    }

    async getUserRelatedLists() : Promise<Array<RowShare.UserNotificationLink>> {
        let userRelatedTable: Array<RowShare.UserNotificationLink> | null;
        userRelatedTable = await API.UserNotificationLink.loadAll();
        return userRelatedTable ? userRelatedTable : new Array<RowShare.UserNotificationLink>();
    }

    async setProfile(): Promise<void>{
        var allValid = true;

        allValid = allValid && (await validate(this.user.FirstName, 'required')).valid;
        allValid = allValid && (await validate(this.user.LastName, 'required')).valid;
        allValid = allValid && (await validate(this.user.NickName, 'required')).valid;
        allValid = allValid && (await validate(this.user.TimeZoneId, 'required')).valid;

        if(allValid){
            this.user = await UserModule.updateUserProfile(this.user);
        } else {
            console.debug("validation error");
        }
    }

    async setUserListNotification(notification: UserNotificationUpdate) : Promise<RowShare.UserNotificationLink> {
        let userNewNotificationList: RowShare.UserNotificationLink | null;
        userNewNotificationList = await API.UserNotificationLink.updateUserTableNotification(notification);
        return userNewNotificationList ? userNewNotificationList : new RowShare.UserNotificationLink();
    }

    // Axeptio cookies
    manageCookies() {
        if((<any>window).openAxeptioCookies) {
            (<any>window).openAxeptioCookies();
        }
    }
    get existsCookiesScript(): boolean {
        return (<any>window).openAxeptioCookies != undefined;
    }
}
