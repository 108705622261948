
    import {Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { CommentViewModel } from './CommentViewModel';

    @Component({
        name: 'CommentCardRenderer'
    })
    export default class CommentCardRenderer extends Vue {
        @Prop({ default : () => {} }) value !: CommentViewModel;
    }
