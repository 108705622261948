
import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { FormValidation } from '@/views/components/FormValidation';

@Component({
    name:'InputText',
    components: { ValidationProvider }
})
export default class InputText extends FormValidation {
//This component allows to create an input text with its properties and validations. It has the following Props:
//inputTextName: the name of the validation provider, which does the validation of the input.
//It is used by vee-validate to show the error message. It's also used to show the placeholder.
//inputTextId: the Id of the input.
//inputTextVid: to show the error messages of the exceptions returned by the server, by setting the error
//on the ValidationObserver.
//inputTextValidationRules: vee-validate rules used to validate the input.
//disabledProp: to disable or not the input
//aria-describeby: allows to associate the input with the feedback message. 
//state: calls the vee-validate function to change the state of the input. This function is in the FormValidation base class.
//mode: it is used to indicate when is thrown the validation

    //Properties
     @Prop() inputTextName!:{
         type:string,
         required:true
     };
     @Prop() placeholder!: 
     {
         type: string,
         required: false
     };
     @Prop() inputTextId!:{
         type:string,
         required:true};
     @Prop() inputTextValidationRules!:string;
     @Prop() disabledProp!:boolean;
     @Prop() value!:string;
     @Prop() inputTextVid!:string;
     @Prop({ default: true}) outlined!: boolean;
     @Prop({ default: false}) filled!: boolean;
     @Prop() focusInput!:boolean;
     @Prop() interactionMode!:string;
     @Prop({ default: ''}) label!:string;
     @Prop( {default: ''}) helpText!: string;
     @Prop({ default: false}) hideDetails!: boolean | string;

    //Methods
    emitTextValueChanged(valueParam:String){
        this.$emit('input', valueParam);
    }

    blured(valueParam:String){
        this.$emit('blured', valueParam);
    }

    onKeydown(event: KeyboardEvent){
        this.$emit('keydown', event);
    }

    mounted() {
        if(this.focusInput) {
            this.$nextTick(() => {
                (<HTMLElement>this.$refs.textInput)?.focus();
            });
        }
    }

}
