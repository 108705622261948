
import {Component, Vue} from 'vue-property-decorator';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import * as models from '@/models/RowShare';
import * as API from '@/api/Api';
import { EventBus } from '@/modules/EventBus';

@Component({name: 'SubscriptionUpdateDialog', components: { PageLoadSpinner }})
export default class SubscriptionUpdateDialog extends Vue {
    organization: models.Organization | null = null;
    showMe: boolean = false;
    currentStep: "update" | "estimate" | "updateConfirmed" = "update";
    loading: boolean = false;
    errorMessage: string = "";
    membersCount: number = 0;
    currentEstimate: models.SubscriptionEstimate | null = null;
    processingUpdate: boolean = false;

    created() {
        EventBus.$on(models.Event.UPDATE_SUBSCRIPTION_REQUEST, this.init);
    }

    beforeDestroy() {
        EventBus.$off([models.Event.UPDATE_SUBSCRIPTION_REQUEST]);
    }

    async init(event: models.updateSubscriptionRequestEventParams) {
        this.loading = true;
        this.showMe = true;
        API.Organization.load(event.organizationId)
                        .then((org) => {
                            this.organization = org;
                            if(!this.organization) {
                                this.errorMessage = this.$i18n.t('SubscriptionUpdateDialog_OrganizationNotFound').toString();                                
                            }
                            else {
                                this.membersCount = this.organization.InTrial ? this.organization.ActiveMemberCount : this.organization.MaximumMemberCount;
                                this.currentStep = "update";
                            }
                        })
                        .finally(() => {this.loading = false;});

    }

    closeMe() {
        this.organization = null;
        this.currentStep = "update";
        this.showMe = false;
    }

    onEstimateClick() {
        if(!this.organization) {
            return;
        }
        this.loading = true;        
        API.Organization.estimateSubscriptionUpdate(this.organization.Id, this.membersCount)
                        .then((result) => {
                            if(result?.IsValid) {
                                this.currentEstimate = result.Value;
                                this.currentStep = "estimate";
                            }
                        })
                        .finally(() => {this.loading = false;});
    }

    backToUpdate() {
        this.currentEstimate = null;
        this.currentStep = "update";
    }

    onUpdateSubscriptionClick() {
        if(!this.organization) {
            return;
        }
        this.processingUpdate = true;
        API.Organization.updateSubscription(this.organization.Id, this.membersCount)
                        .then((result) => {
                            if(result?.IsValid && result?.Value) {
                                this.currentStep = "updateConfirmed";
                            }
                        })
                        .finally(() => {this.processingUpdate = false;});
    }

    getAmountString(amount: number) {
        if(!this.currentEstimate) {
            return "";
        }
        else {
            var amountInDecimal = (amount / 100).toLocaleString(navigator.language)
            if(this.currentEstimate.IsInEuro) {
                return `${amountInDecimal} €`;
            }
            else {
                return `$ ${amountInDecimal}`;
            }
        }
    }

    getDateString(date: Date) {
        return new Date(date).toLocaleDateString(navigator.language, {day: 'numeric', month: 'short', year: 'numeric'})        
    }

    get subscriptionDescription() {
        if(this.organization) {
            return this.$i18n.t('SubscriptionUpdateDialog_Description', 
                                {activeMemberCount: this.organization.ActiveMemberCount, maxMemberCount: this.organization.MaximumMemberCount}).toString();
        }
    }

    get cannotEstimate() {
        if(!this.organization) {
            return true;
        }
        return (this.notEnoughUsers || this.organization.InTrial || this.membersCount == this.organization.MaximumMemberCount);
    }

    get notEnoughUsers() {
        if(!this.organization) {
            return true;
        }
        return (this.membersCount < this.organization.ActiveMemberCount)
    }

    get updateDescription() {
        var values = {
            oldMemberCount: this.organization?.MaximumMemberCount,
            newMemberCount: this.membersCount
        }
        return this.$i18n.t('SubscriptionUpdateDialog_UpdateDescription', values).toString();
    }

    get prorataDescription() {
        if(!this.currentEstimate) {
            return "";
        }
        var values = {
            prorataInvoice: this.currentEstimate.ImmediateAmountDue ? this.$i18n.t('SubscriptionUpdateDialog_Immediately').toString() :
                                                                      this.$i18n.t('SubscriptionUpdateDialog_NextInvoice').toString()
        };

        return this.$i18n.t('SubscriptionUpdateDialog_Prorata', values).toString();
    }

    get updateConfirmationDescription() {
        return this.$i18n.t('SubscriptionUpdateDialog_UpdateConfirmation');
    }

    get cannotEstimateTooltip() {
        if(!this.organization) {
            return "";
        }
        if(this.membersCount == (this.organization?.MaximumMemberCount ?? 0)) {
            return this.$i18n.t('SubscriptionUpdateDialog_NoMemberCountChange').toString();
        }
        else if(this.membersCount < (this.organization?.ActiveMemberCount ?? 0)) {
            return this.$i18n.t('SubscriptionUpdateDialog_NotEnoughUsers',
                                 {activeMemberCount: this.organization.ActiveMemberCount, maxMemberCount: this.organization.MaximumMemberCount}).toString();
        }
        return "";
    }

    get isDowngrade() {
        if(!this.organization) {
            return false;
        }
        return this.membersCount < this.organization.MaximumMemberCount;
    }
}
