
import {Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { FormValidation } from '@/views/components/FormValidation';

@Component({
    name:'InputTextArea',
    components: { ValidationProvider }
    })
export default class InputTextArea extends FormValidation {

    //Properties
    @Prop() inputTextAreaName!:{
        type:string,
        required:true
    };
    @Prop() inputTextAreaId!:{
        type:string,
        required:true
    };
    @Prop() inputTextAreaValidationRules!:string;
    @Prop() disabledProp!:boolean;
    @Prop() value!:string;
    @Prop() inputTextAreaVid!:string;
    @Prop() placeholder!:string;
    @Prop() label!: string;
    @Prop( {default: ''}) helpText!: string;
     
    //Methods
    emitTextAreaValueChanged(valueParam:String){
        this.$emit('input', valueParam );
    }

    blured(valueParam:String){
        this.$emit('blured', valueParam);
    }
     
}
