
    import {Component, Prop, Vue } from 'vue-property-decorator';
    import * as API from '@/api/Api';
    import {AgGridVue} from "ag-grid-vue";
    import * as RowShare from "@/models/RowShare";
    import * as agGrid from 'ag-grid-community';
    import { EventBus } from "@/modules/EventBus";
    import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
    import groupsEditor from '@/views/ManageOrganization/manageOrganizationGrids/groupsEditor.vue';
    import groupsRenderer from '@/views/ManageOrganization/manageOrganizationGrids/groupsRenderer.vue';
    import deleteRowRenderer from '@/views/ManageOrganization/manageOrganizationGrids/deleteRowRenderer.vue';
    import { RowShareException } from '@/api/ApiUtils';
    import Toaster from '@/views/layouts/LayoutParts/Toaster.vue';
    import { groupVM, IGroupEditorParams } from '@/viewModels/ManageOrganization/groupVM';
   
    @Component({
        name: 'Groups',
        components: {AgGridVue, Toaster, ConfirmationDialog, groupsEditor, groupsRenderer, deleteRowRenderer}
    })
    export default class Groups extends Vue {

        @Prop() organizationId!:string;
        orgGroups: groupVM[] | null;
        groupListColumns: agGrid.ColDef[];
        gridApi!: agGrid.GridApi;
        columnApi!: agGrid.ColumnApi;
        gridOptions!: agGrid.GridOptions;
        organization: RowShare.Organization;
        showRemoveModal: boolean;
        groupRowToDelete: agGrid.IRowNode | null;
        toasterText: string;
        toasterColor: string;
        showToaster: boolean;
        defaultColumnSettings: agGrid.ColDef;
        toasterTimeOut: number;

        constructor(){
            super();
            this.orgGroups = null;
            this.groupListColumns =[];
            this.organization = new RowShare.Organization();
            this.showToaster = false;
            this.toasterText = '';
            this.toasterColor = '';
            this.showRemoveModal = false;
            this.groupRowToDelete = null;
            this.defaultColumnSettings = {};
            this.toasterTimeOut = 2000;
        }

        gridReady(params: agGrid.GridReadyEvent){
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
        }

        firstDataRendered(){
            this.gridApi.sizeColumnsToFit();            
        }

        beforeMount(){
            this.defaultColumnSettings ={
                menuTabs: ['filterMenuTab', 'generalMenuTab'],
                filterParams: {
                    buttons: ['reset', 'apply'], 
                    closeOnApply: true,
                    excelMode: 'windows',
                    refreshValuesOnOpen: true,
                    caseSensitive: true,
                },
                headerClass: 'colHeader'
            };
            this.groupListColumns = [
                {headerName: this.$i18n.t('GroupGrid_ColTitle_Name').toString(), field: "Name", lockPosition: true, sortable: true, filter: true, editable: true, resizable: true, pinned: true},
                {headerName: this.$i18n.t('GroupGrid_ColTitle_Description').toString(), field: "Description", lockPosition: true, editable: true, resizable: true},
                {headerName: this.$i18n.t('GroupGrid_ColTitle_SubGroups').toString(), field: "Children", lockPosition: true, sortable: true, editable: true, resizable: true, cellRenderer:'groupsRenderer', cellEditor: 'groupsEditor', cellEditorParams: (params: any) => this.getGroupsEditorParams(params)},
                {headerName: this.$i18n.t('GroupGrid_ColTitle_MemberCount').toString(), field: "MemberCount", lockPosition: true, sortable: true, type: 'numericColumn'},
                {flex: 1, sortable: false, filter: false, lockPosition: true, lockVisible: true, suppressMenu: true},
                {cellClass:'noGridCell', headerClass: 'emptyColHeader', width: 30, cellRenderer: 'deleteRowRenderer', sortable: false, filter: false, lockPosition: true, lockVisible: true,pinned: 'right', lockPinned: true, suppressMenu: true, onCellClicked: params => this.confirmRemoveGroup(params)}
            ];
            this.gridOptions = { stopEditingWhenCellsLoseFocus: true,
                                rowClassRules:{'grid-row-error' : params => this.getRowClassRules(params)},
                                getLocaleText: (params) => this.getLocaleText(params.key, params.defaultValue),
                                suppressContextMenu: true}
            this.orgGroups = null;
            API.Group.loadForParent(this.organizationId)
                .then(result =>{
                    if(result){
                        this.orgGroups = result.filter(g => !g.IsMyOrganization).map(g => new groupVM(g));
                    }
            });
        }

        getGroupsEditorParams(params: any) : IGroupEditorParams{
             let currentGroup = params.data as groupVM;
             if(currentGroup){
                 params.idsToRemove = [];
                 params.idsToRemove.push(currentGroup.Id);
                 params.organizationId = currentGroup.OrganizationId;
             }
             return params;
        }

        getLocaleText(key: string, defaultValue: string): string{
            if(key && key != ''){
                return this.$i18n.t('Grid_'+key).toString();
            }
            return defaultValue ? defaultValue : '';
        }

        getRowClassRules(params: any): boolean{
            return params.data.hasError === true;
        }

        cellValueChanged(event: agGrid.CellValueChangedEvent){
            let group = event.data as groupVM;
            if((group && event.node && event.newValue != event.oldValue)){
                this.saveGroup(group, event.node);
            }
        }

        saveGroup(group: groupVM, node: agGrid.IRowNode) {
            if(group && group.Name && group.Name !== ''){
                API.Group.save(group)
                    .then(g => {
                        if(g && this.gridApi){
                            let gvm = new groupVM(g);
                            node.setData(gvm);
                            EventBus.$emit(RowShare.Event.GROUP_CHANGED);
                        }
                    })
                    .catch(exc => {
                        let rsExc = exc as RowShareException;
                        if(rsExc)
                        {
                            this.toasterText = rsExc.message;
                        }
                        else
                        {
                            this.toasterText = this.$i18n.t("GroupGrid_ErrorSavingGroup").toString();
                        }
                        this.toasterTimeOut = 10000;
                        if(!group.hasError){
                            this.toasterColor = "error";
                            this.showToaster = true;
                            group.hasError = true;
                        }
                        if(!group.IsNew){
                            node.setData(new groupVM(group.initialGroup));
                        }
                        else
                        {
                            node.setData(group);
                        }
                        
                    });
            }
        }

        closeToaster(){
            this.showToaster = false;
        }

        addNewGroup(){
            if(this.gridApi){
                let newGroup = new groupVM(null);
                newGroup.OrganizationId = this.organizationId;
                newGroup.IsNew = true;
                var rowNodeTransactions = this.gridApi.applyTransaction({add: [newGroup]});
                if(rowNodeTransactions && rowNodeTransactions.add && rowNodeTransactions.add.length > 0){
                    var rowIndex = rowNodeTransactions.add[0].rowIndex
                    this.gridApi.refreshClientSideRowModel();
                    if(rowIndex != undefined){
                        let editCell: agGrid.StartEditingCellParams = {
                            rowIndex: rowIndex,
                            colKey: 'Name'
                        };
                        this.gridApi.startEditingCell(editCell);
                    }
                }
            }
        }

        confirmRemoveGroup(params: agGrid.ColumnFunctionCallbackParams){
            this.groupRowToDelete = params.node as agGrid.IRowNode;
            this.showRemoveModal = true;
        }
        
        deleteGroup(){
            if(this.groupRowToDelete){
                let groupToDelete = this.groupRowToDelete.data as RowShare.Group;
                API.Group.remove(groupToDelete).then((result)=>{
                    if(result === true){
                        this.gridApi.applyTransaction({remove: [groupToDelete]});
                    }
                });
            }
            this.showRemoveModal = false;
            this.groupRowToDelete = null;
        }

        cancelGroupDeletion() {
            this.groupRowToDelete = null;
            this.showRemoveModal = false;
        }

        get groupToDeleteName(): string {
            if(this.groupRowToDelete) {
                return this.groupRowToDelete?.data?.Name ?? "";
            }
            return "";
        }
    }
