import * as RowShare from "@/models/RowShare";
import { ColumnStrongType, LookUpItem } from '@/models/Column';
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import { Utils } from '@/utils/Utilities';
import { List } from "@/api/List";
import { SetFilterValuesFuncParams } from "ag-grid-community";

export default class LookupColumnProxy extends BaseColumnProxy {
    parseInitialTypedValue(rsColumn: RowShare.Column, rowVM: RowVM): any {
        const val = rowVM.rsRow.Values[rsColumn.Name];

        if(rsColumn.isLookupMultiValued)
            return this.parseMultivaluedCell(val);
        else if(val && val != "")
            return [val];
        else
            return val;
    }

    getAPIVal(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        return val ? val?.join('\0') : val;
    }

    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        return this.formatValue(val);
    }


    formatValue(val: any) {
        if(!val || !Utils.isArray(val))
            return val;

        var res = val.length > 0 ? val[0] : "";
        for(let i = 1; i < val.length; ++i){
            res += " | " + val[i];
        }
        return res;
    }

    getFormattedHtml(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        const lookupItems = rsColumn.parsedLookupItems;
      
        var res = "";
        if(!val) {
            return res;
        }

        if(val === RowVM.cellNotAccessible) {
            res += `<span>${RowVM.cellNotAccessible}</span>`;
        }
        else {
            const firstTag = val.length > 0 ? val[0] : null;
            const allTagsButFirst = val.length > 1 ? val.slice(1) : [];

            if(firstTag) {
                res += `
                <div class="first-chip-wrapper ${val.length > 1 ? 'not-single-chip' : ''}">
                    <span class="mx-1 v-chip v-chip--label theme--light v-size--default ${this.getItemClasses(lookupItems, firstTag)} ${rsColumn.isLookupMultiValued?'':'single-value'}">
                        <span class="valueItem">${firstTag}</span>
                    </span>
                </div>`;
            }

            for(let i = 0; i < allTagsButFirst.length; ++i){
                res += `
                <span  class="mx-1 v-chip v-chip--label theme--light v-size--default ${this.getItemClasses(lookupItems, allTagsButFirst[i])}">
                    <span class="valueItem">${allTagsButFirst[i]}</span>
                </span>`;
            }
        }
       return res;
    }

    getRendererWrapperClass(){
        return "lookup-renderer";
    }

    areEquals(val1: any, val2: any) {
        if((val1 === null || val1 === undefined || val1 === "" || (Utils.isArray(val1) && val1.length == 0)) && (val2 === null || val2 === undefined || val2 === "" || (Utils.isArray(val2) && val2.length == 0)))
            return true;

        if(!Utils.isArray(val1) || !Utils.isArray(val2)) {
            return false;
        }

        if(val1.length != val2.length) {
            return false;
        }

        for (let index = 0; index < val1.length; index++) {
            if(val1[index] != val2[index]) {
                return false;
            }            
        }
        return true;
    }

    shouldSuppressKeyboardEvent(rsCol: RowShare.Column) { 
        return true;
    }    

    /** Filters **/
    async getFilterValues(params: SetFilterValuesFuncParams<any, string>, rsCol: RowShare.Column) {
        var res: Array<string|null> = [];
        res = await List.loadChoice(rsCol.ListId, rsCol.DisplayName) ?? [];
        if(rsCol?.Type === ColumnStrongType.LookUp) {
            let lookupValues = rsCol.parsedLookupItems.map(l => l.name);
            lookupValues.forEach((val) => {
                if(!res.includes(val)) {
                    res.push(val);
                }
            });
        }
        else if(rsCol?.Type === ColumnStrongType.LookUpList) {
            let lookuplistValues = await List.loadChoice(rsCol.LookupListId, rsCol.LookupListColumnName);
            lookuplistValues?.forEach(val => {
                if(!res.includes(val)) {
                    res.push(val);
                }
            });
        }
        res.sort((x: string | null, y: string | null) => {
            if(x == null) {
                if(y == null) {
                    return 0;
                }
                return -1;
            }
            if(y == null) {
                return 1;
            }
            let valx = x.toLowerCase();
            let valy = y.toLowerCase();
            if(valx < valy) {
                return -1;
            }
            else if(valx > valy) {
                return 1;
            }
            return 0;
        });
        res.unshift(null);
        params.success(res);
    }

    getCustomFilterParams(rsCol: RowShare.Column) : Promise<null | any> {
        var res = super.getCustomFilterParams(rsCol);
        res.suppressSorting = true;
        res.values = (params: SetFilterValuesFuncParams) => this.getFilterValues(params, rsCol);
        return res;
    }

    /* Internal */

    getItemClasses(lookupItems: LookUpItem[], name: string) {
        let color = "";
        if(lookupItems && lookupItems.length > 0){
            let itemIndex = lookupItems.findIndex(item => item.name == name);
            if(itemIndex != undefined && itemIndex > -1) {
                color =  lookupItems[itemIndex].color;
            }
        }
        if(color == ""){
            color = "primary";
        }

        if(color == "primary")
            color += " v-chip--outlined primary--text";

        return color;
    }

    parseMultivaluedCell(value: string[] | string | null | undefined): string[] | null | undefined {
        if (Utils.isNullOrUndefined(value)) {
            return value;
        }

        if (Utils.isArray(value)) {
            return value;
        }

        if (Utils.isString(value) && value != '') {
            return value.split("\0");
        }
        return null;
    }

    /** Sorting **/
    compareValues(rsColumn:RowShare.Column, valX: any, valY: any, nodeX: any, nodeY: any): number | null {
        // Remove accents and uppercase to sort
        let formattedX = this.formatValue(valX).normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
        let formattedY = this.formatValue(valY).normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();

        if(formattedX == formattedY) {
            return 0;
        }
        else {
            return (formattedX > formattedY) ? 1 : -1;
        }
    }
}