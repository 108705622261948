
import {Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as models from '@/models/RowShare';
import * as api from '@/api/Api';
import Toaster from '@/views/layouts/LayoutParts/Toaster.vue';
import { ListTreeModule } from '@/store/ListTreeStore';

@Component({name: 'SurveyModeDialog', components: {Toaster}})
export default class SurveyModeDialog extends Vue{
    @Prop() listId!: string;
    showDialog: boolean = false;
    savingList: boolean = false;
    showToaster: boolean = false;
    toasterColor: string = "";
    toasterText: string = "";
    toasterIcon: string = "";

    get currentList() {
        return ListTreeModule.currentList;
    }

    get allowSurvey() {
        if(this.currentList) {
            return this.currentList.AllowSurvey;
        }
        return false;
    }

    set allowSurvey(value: boolean) {
        if(this.currentList) {
            this.currentList.AllowSurvey = value;
            this.savingList = true;
            api.List.save(this.currentList)
                    .then((list: models.List | null) => {
                        this.savingList = false;
                        if(list) {
                            ListTreeModule.updateCurrentList({ listId: this.listId, allowSurvey: false});
                        }
                    })
        }
    }

    get surveyUrl() {
        if(this.currentList) {
            return `${window.location.protocol}//${window.location.host}/survey/${this.currentList.Id}`;
        }
        return "";
    }

    onCancel() {
        this.showDialog = false;
    }

    openDialog() {
        setTimeout(() => this.showDialog = true);
    }

    copyLink() {
        let url = this.surveyUrl;
        document.addEventListener('copy', this.copyToClipboard);
        document.execCommand('copy');
        this.toasterColor = "success";
        this.toasterText = this.$i18n.t('SurveyDialog_LinkCopied').toString();
        this.toasterIcon = "fal fa-check";
        this.showToaster = true;
    }

    copyToClipboard(event: ClipboardEvent) {
        event.clipboardData?.setData('text/plain', this.surveyUrl);
        event.preventDefault();
        document.removeEventListener('copy', this.copyToClipboard);
    }
}
