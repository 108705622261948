import Vue from 'vue';
import Vuetify from 'vuetify';
import '@fortawesome/fontawesome-pro/css/all.css'
import '@fortawesome/fontawesome-pro/css/sharp-light.css'
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        mobileBreakpoint: 'xs',
    },
    icons: {
        iconfont: 'fa'
    },
    theme:{
        options: {
            customProperties: true
        },
        themes:{
            light:{
                lightest: '#ffffff',
                darkest: '#131523',
                primary: {
                    darken1: '#1742de',
                    base:   '#295ff2',
                    lighten1: '#90c0ff',
                    lighten2: '#eef5ff',
                },
                secondary: {
                    darken1: "#EB9605",
                    base: '#E9A42F',
                    lighten1: '#FFBB39',
                    lighten2: '#FFF4E1',
                    lighten3: '#FDF7ED'
                },
                warning: {
                    darken1: "#EB9605",
                    base: '#E9A42F',
                    lighten1: '#FFBB39',
                    lighten2: '#FFF4E1',
                },
                error: {
                    darken1: '#F44A3E',
                    base: '#F66B62',
                    lighten1: '#FE8D85',
                    lighten2: '#FFECEB',
                },
                success: {
                    darken1: '#168867',
                    base: '#4FB399',
                    lighten1: '#7EC8B4',
                    lighten2: '#E2F3EE',
                },
                grey: {
                    darken1: '#344054',
                    base:'#475467',
                    lighten1:'#98A2B3',
                    lighten2:'#E4E7EC',
                },
                deeppurple: {
                    base: colors.deepPurple.accent1
                },
                teal: {
                    darken4: colors.teal.darken4,
                    darken3: colors.teal.darken3,
                    darken2: colors.teal.darken2,
                    darken1: colors.teal.darken1,
                    base: colors.teal.base,
                    lighten1: colors.teal.lighten1,
                    lighten2: colors.teal.lighten2,
                    lighten3: colors.teal.lighten3,
                    lighten4: colors.teal.lighten4,
                    lighten5: colors.teal.lighten5
                },
                backgrounds:'#f7f8fb',
                icon:'#eef2fc',
                
                facebook: '#4267B2',
                google: '#DB4437',
                excel: '#1d6f42',
                windows: '#01A6F0',
                color0: '#30D98D', 
                color1: '#FAF5C2', 
                color2: '#DBEAE5', 
                color3: '#488B49', 
                color4: '#0B6E4F',
                color5: '#A88261', 
                color6: '#F46B82', 
                color7: '#F2DBEC', 
                color8: '#E81E62', 
                color9: '#A51080', 
                color10: '#5FADAF', 
                color11: '#39116A',
                color12: '#FBE946', 
                color13: '#ECB95B', 
                color14: '#F09942', 
                color15: '#F36416', 
                color16: '#E94F37', 
                color17: '#F0D9DD', 
                color18: '#96031A',
                color19: '#69140E', 
                color20: '#037971', 
                color21: '#E1F1FF', 
                color22: '#00B4CC', 
                color23: '#36A0FC', 
                color24: '#496DDB', 
                color25: '#244F94',
                color26: '#7C8AA2', 
                color27: '#E0E5E7', 
                color28: '#4E8098', 
                color29: '#765941', 
                color30: '#30505F', 
                color31: '#152229'
            }
        }
    }
});


