
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as Models from '@/models/RowShare';
import { ListTreeModule } from '@/store/ListTreeStore';
import GridViewVM from '@/viewModels/Table/gridViewVM';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import {AgGridVue} from "ag-grid-vue";
import RowShareGrid from '@/views/Table/GridView/RowshareGrid.vue';
import ListVM from '@/viewModels/Table/listVM';

@Component({name: 'TablePrint', components: { PageLoadSpinner, AgGridVue, RowShareGrid }})
export default class TablePrint extends Vue {
    @Prop() listVM!: ListVM;
    renderedCellsCount: number = 0;
    
    gridViewVM!: GridViewVM;
    
    created() {
        this.gridViewVM = new GridViewVM(this.listVM);
    }

    destroyed() {
        this.gridViewVM.dispose();
    }

    closeWindow() {
        window.close();
    }

    printTable() {        
        window.print();
    }

    sizeColumnsToFit() {
        let grid = <Vue>this.$refs.gridToPrint;
        if(grid) {
            this.gridViewVM?.columnApi?.sizeColumnsToFit(grid.$el.clientWidth);// window.innerWidth);
            this.gridViewVM?.gridApi?.refreshHeader();
        }
    }

    get listTitle() {
        return this.currentList?.DisplayName;
    }

    get currentList() : Models.List | null {
        return ListTreeModule.currentList;
    }
}
