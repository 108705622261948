
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from "@/modules/EventBus";
import * as RowShare from "@/models/RowShare";
import * as Api from '@/api/Api';
import { ApiUtils } from '@/api/ApiUtils';

@Component({name: "ListAnalysisDialog"})
export default class ListAnalysisDialog extends Vue {

    listId: string = "";

    showDialog: boolean = false;
    userConfirmed: boolean = false;
    errorMessage: string = "";

    created() {
        EventBus.$on(RowShare.Event.LISTANALYSIS_REQUEST, (params: RowShare.listAnalysisRequestEventParams) => this.init(params));
    }

    beforeDestroy() {
        EventBus.$off(RowShare.Event.LISTANALYSIS_REQUEST);
    }

    init(params: RowShare.listAnalysisRequestEventParams) {
        this.listId = params.listId;
        this.showDialog = true;
    }

    async onListAnalysisRequest() {
        let result = await Api.List.requestListAnalysis(this.listId);
        if (result?.IsValid) {
            let job = result.Value;
            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams> {
                message: this.$i18n.t('ListAnalysisDialog_JobCreated').toString(),
                autoHide: true,
                type: RowShare.NotificationType.success
            });
            this.$emit('requestAnalysis', job);
            setTimeout(() => {this.closeDialog()}, 0);
        } else {
            if(result != null && (result?.Errors.length ?? 0) > 0) {
                this.errorMessage = ApiUtils.GetApiErrorDescription(result.Errors[0]);
            }
        }
    }

    closeDialog() {
        this.userConfirmed = false;
        this.showDialog = false;
    }
}
