import { Utils } from './Utilities';
import TimeSpan from './TimeSpan';

export class DateTime {

    static parseDateTime<T>(value: any, defaultValue: T): Date | T {
        if (Utils.isDate(value)) {
            return value;
        }

        if (Utils.isString(value)) {
            // Format /Date(<ticks>+<offset>)/
            // ticks  = ticks Unix Epoch => milliseconds since 1st january 1970
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
            let a = /\/Date\(((?:-|\+)?\d*)((?:-|\+)\d*)?\)\//.exec(value);
            if (a) {
                var date = new Date(+a[1]);
                // if our value didn't include an offset then we bascially want to display the same date for every user whatever his / her timezone is.
                // so with the following line we want to "cancel" the local timezone
                // new Date(ticks) = new Date(Ticks + getTimeZone) => We need to remove the timezone
                date = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
                if (a[2]) {
                    // if serveur has provided an offset, then we want to take this offset in account
                    // this is the case for exemple for row creation date for row last modification date, for reminders, etc.
                    var tz = +a[2]; // +0200, -0130
                    var offset = (tz / 100 * 60) + (tz % 100); // minutes
                    return new Date(date.getTime() + offset * 60000);
                }
                return date;
            }

            // Format 0000-00-00T00:00:00
            // Format 0000-00-00 00:00:00
            // Format 0000/00/00 00:00:00
            let b = /(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})((T| )(\d{1,2}):(\d{1,2})(:(\d{1,2})))?/.exec(value);
            if (b) {
                // ["2012-01-01T01:02:03", "2012", "-", "01", "-", "01", "T01:02:03", "T", "01", "02", ":03", "03"]
                let year = Utils.parseInteger(b[1], 0);
                let month = Utils.parseInteger(b[3], 1) - 1; // 0 based
                let day = Utils.parseInteger(b[5], 0);
                let hours = Utils.parseInteger(b[8], 0);
                let minutes = Utils.parseInteger(b[9], 0);
                let seconds = Utils.parseInteger(b[11], 0);

                return new Date(year, month, day, hours, minutes, seconds, 0);
            }
        }

        return defaultValue;
    }

    static parseTime<T>(value: any, defaultValue: T): TimeSpan | T {
        if (value instanceof TimeSpan) {
            return value;
        }

        if (Utils.isString(value)) {
            const a = /(\d+):(\d+)(?::(\d+))?/.exec(value);
            if (a) {
                const hours = parseInt(a[1], 10);
                const minutes = parseInt(a[2], 10);
                const seconds = parseInt(a[3], 10);
                return TimeSpan.fromTime(hours, minutes, seconds);
            }
        }

        if (typeof value === "number" && isFinite(value)) {
            return TimeSpan.fromTicks(value);
        }

        const ticks = parseInt(value, 10);
        if (isFinite(ticks)) {
            return Utils.parseTime(ticks, defaultValue);
        }

        return defaultValue;
    }
}