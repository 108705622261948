
import { Component, Prop, Vue } from "vue-property-decorator";
import * as RowShare from "@/models/RowShare";
import { EventBus } from "@/modules/EventBus";
import { Utils } from "@/utils/Utilities";
import ListDisplayModeVM from "@/viewModels/Table/listDisplayModeVM";

@Component({
    name: "ViewSelector",
    components: {  }
})
export default class ViewSelector extends Vue {
    @Prop() displayModeVM!: ListDisplayModeVM;
    isOpen = false;

    applyDefaultView(){
        this.displayModeVM.viewManager.applyDefaultView();
    }

    applyView(view: RowShare.View){
        this.displayModeVM.viewManager.applyView(view);
    }

    isSaveViewDialogOpen = false;
    viewBeingReplacedSummary: string | null = null;
    savingViewSummary: string | null = null;
    viewConfigBeingSaved: RowShare.ListConfiguration | undefined = undefined;
    viewBeingSaved: RowShare.View | null = null;
    isSavingView = false;
    saveErrorMessage: string | null = null;

    get canSaveCurrentView(){
        return this.viewBeingSaved && !Utils.isNullOrWhiteSpace(this.viewBeingSaved.Name);
    }

    confirmSaveCurrentView(){
        if(!this.displayModeVM?.listVM?.list?.Id)
            return;

        this.viewBeingSaved = new RowShare.View();
        this.viewBeingSaved.ListId = this.displayModeVM.listVM.list.Id;
        this.baseConfirmSaveView();
    }

    confirmUpdateExistingView(view: RowShare.View){
        this.viewBeingSaved = view;
        this.viewBeingReplacedSummary = this.displayModeVM.viewManager.getViewSummary(view);
        this.baseConfirmSaveView();
    }

    baseConfirmSaveView(){
        this.viewConfigBeingSaved = this.displayModeVM.viewManager.getCurrentViewConfig();
        if(!this.viewConfigBeingSaved)
            return;

        this.savingViewSummary = this.displayModeVM.viewManager.getViewConfigSummary(this.viewConfigBeingSaved);
        this.saveErrorMessage = null;

        this.isSaveViewDialogOpen = true;
    }

    async saveCurrentView(){
        if(!this.viewBeingSaved)
            return;

        this.isSavingView = true;
        
        this.viewBeingSaved.Data = JSON.stringify(this.viewConfigBeingSaved);

        const res = await this.displayModeVM.viewManager.saveView(this.viewBeingSaved);
        this.isSavingView = false;

        if(res == null)
            this.isSaveViewDialogOpen = false;
        else
            this.saveErrorMessage = res;
    }

    canDeleteView(view: RowShare.View){
        return !view.Shared || this.displayModeVM?.listVM?.list?.Owned;
    }

    deleteView(view: RowShare.View){
        let event = new RowShare.ConfirmationRequiredEventParams();
        event.title = view.Shared
            ? this.$i18n.t('DeleteViewDialog_SharedViewTitle').toString()
            : this.$i18n.t('DeleteViewDialog_PersonalViewTitle').toString();
        event.description1 = view.Shared
            ? this.$i18n.t('DeleteViewDialog_SharedViewDescription', [view.Name]).toString()
            : this.$i18n.t('DeleteViewDialog_PersonalViewDescription', [view.Name]).toString();
        event.cancelButtonText = this.$i18n.t('Views_Dialog_Cancel').toString();
        event.actionButtonText = this.$i18n.t('DeleteViewDialog_Confirm').toString();
        event.actionButtonColor = "error";
        event.actionButtonIcon = "trash-alt";
        event.onConfirmation = async () => {
            await this.displayModeVM.viewManager.deleteView(view);
        };
        EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, event);
    }
}
