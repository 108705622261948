import { Utils } from "@/utils/Utilities";
import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";

export default class GroupRowRenderer implements ICellRendererComp {
    cellContainer!: HTMLElement;

    init(params: ICellRendererParams){
        this.cellContainer = document.createElement("div");
        this.cellContainer.innerText = `${params.value}`;
        this.cellContainer.title = params.value;
        this.cellContainer.style.textOverflow = 'ellipsis';
        this.cellContainer.style.overflow = 'hidden';
        if(params.node.data != undefined) {
            this.cellContainer.innerText = "";
        }
    }

    getGui(): HTMLElement {
        return this.cellContainer;
    }

    refresh(params: ICellRendererParams): boolean {
        this.cellContainer.innerText = `${params.value}`;
        if(params.node.data != undefined) {
            this.cellContainer.innerText = "";
        }
        return true;
    }
}