export const enum DataStatus {
    Unspecified = 0,        
    Normal = 1,    
    DeletionRequired = 2,    
    DeletionInProgress = 3,
    Archived = 8,
    Locked = 9,
    EditionInProgress = 10,
}

export const enum DisplayedRows {
    Active,
    Deleted,
    Archived
}

export const enum RowShareServiceFormat {
    Json,
    Xml,
    XlsSync,
    Xls,
    Csv,
    Html,
    DataTable,
    Javascript,
    Yaml
}
