import { User, Member } from "./RowShare";


export class BackOfficeOrganizationSummary {
    public Id!:string;
    public Name!:string;
    public IsEnabled!: boolean;
}

export class BackOfficeListSummary{
    public Name!:string;
    public OrganizationName!:string;
    public OrganizationId!:string;
    public Description!:string;
}

export class BackOfficeUser extends User {
    public Id!: string;
    public LastSeenDate!: Date;
    public Internal!: boolean;
    public IsLockedOut!: boolean;
    public Organizations!: Array<BackOfficeOrganizationSummary>;
    public EmailConfirmationCodes!: string[];
}

export class BackOfficeSearchSummary {
    public Users!: Array<BackOfficeUser>;
    public Organizations!: Array<BackOfficeOrganizationSummary>;
    public Lists!: Array<BackOfficeListSummary>;
}

export class BackOfficeMember extends Member {
    AssociatedUserNickName!: string;
    UserId!: string;
    showExtraInfos!: boolean;
}