import { Utils } from "@/utils/Utilities"
import { ListAccess, CellColor, RowShareServiceFormat } from "./RowShare";

export class List {
    public Id!: string;
    public DisplayName!: string;
    public Description!: string;
    public CanCreate!: boolean;
    public CanRead!: boolean;
    public CanUpdate!: boolean;
    public CanSuppress!: boolean;
    public CanAssign!: boolean;
    public LoadedByReadOnlyMember!: boolean;
    //public UndoRedoInfo: UndoRedoInfo;
    public HasPreviewFeatures!: boolean;
    public Lcid!: number;
    public Language!: string;
    public IconPath!: string | null;
    public Concurrency!: boolean;
    public OneRowMaximumPerUser!: boolean;
    public Accesses!: ListAccess[];
    public Owned!: boolean;
    public CategoryId!: number;
    public RowDescriptorId!: string;
    public ShowTotals!: boolean;
    public ShowOwnerInformations!: boolean;
    //public ShowShareWizard: boolean;
    public ColorOrDefault!: string;
    public FolderId!: string;
    public IsAllowedToCreateSiblings!: boolean;
    public HasRemainingSlotForSiblings!: boolean;
    //public ManagerEquivalence: boolean;
    public OrganizationId!: string;
    public AllowPublic!: boolean;
    public AllowSurvey!: boolean;
    public BlobSizeLimitInKo!: number;
    public RowCount!: number;
    public ReportCount!: number;
    public RemainingRowCount!: number;
    public MaxRowCount!: number;
    public RemainingStorage!: number;
    public MaxSizeInBytes!: number;
    public ListSizeInBytes!: number;
    public OwnersNicknames!: Array<string>;
    public LastUpdateDateUtc!: Date;
    public HumanReadableLastUpdateDate!: string;
    public LastUpdateDateSortOrder!: number;
    public CreationDateUtc!: Date;
    public Icon_Remove!:boolean;
    public IsInRecycleBin!:boolean;
    public CellsColors!:string;
    public CanCurrentUserEnableAudit!:boolean;
    public CanCurrentUserExportAudit!:boolean;
    public HasRelationColumn!: boolean;
    public HasRelatedRows!: boolean;
    public HasUniqueColumn!:boolean;
    public HasUserReadOnlyColumn!:boolean;
    public HasUserHiddenColumn!:boolean;
    public IsReferenced!: boolean;
    public RowsAutoHeight!: boolean;

    public get isOrganization() {
        return !Utils.isEmptyGuid(this.OrganizationId);
    }

    public get isUser() {
        return !this.isOrganization;
    }

    public get hasDescription() {
        return !Utils.isNullOrWhiteSpace(this.Description);
    }

    public get CellsColorsList(): CellColor[] {
        let colors: CellColor[] = [];

        if(!this.CellsColors) {
            return [];
        }

        colors = <CellColor[]>JSON.parse(this.CellsColors);

        if(!colors) {
            colors = [];
        }

        return colors;
    }

}

export class ListOwner {
    public ListId!:string;
    public Options!:ListOwnerOptions;
    public OwnerEmail!:string;
    public OwnerOrganizationId!:string;
    public OwnerName!:string;
}

 enum ListOwnerOptions
{   
    None = 0,   
    Primary = 1,
}

export class CopyListInfo{
    Id!:string;
    FolderId!:string;
    Options!:CopyListOptions;
}

export class CopyListOptions{
    CopyRows?: boolean;
    CopyReports?: boolean;
    CreateNotifications?: boolean;
    RowsAutoHeight?: boolean;
}

export class ListInformations {
    hidePermissionsMessage!: boolean;
    showDescription!: boolean;
}

export class ExportContext {
    rowIds!: string[] | null;
    format!: RowShareServiceFormat;
    formatOptions!: XlsFormatOptions;
}

export class XlsFormatOptions {
    Synchronizable: boolean = false;
    RequiresAuthentication: boolean = false;
    OpenXml: boolean = false;
    OnlyHeader: boolean = false;
    IncludeRowDetails: boolean = false;
    ForAnalysis: boolean = false;
}