import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"

export class Job {

    static load(Id:string, options: IRequestOptions | null = null) : Promise<RowShare.Job | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/job/load/"+ ApiUtils.encodeSegment(Id) + "?v3=true", options)
                .then(response => response.object(RowShare.Job));
    }
    
    static loadAll(options: IRequestOptions | null = null) : Promise<Array<RowShare.Job> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/job/loadall?v3=true", options)
                .then(response => response.objects(RowShare.Job));
    }

    static loadByList(listId:string, type: RowShare.JobType, options: IRequestOptions | null = null) : Promise<Array<RowShare.Job> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/job/loadforparent/" + ApiUtils.encodeSegment(listId) + "/" + ApiUtils.encodeSegment(type) + "?v3=true", options)
                .then(response => response.objects(RowShare.Job));
    }

    static delete(Id:string, options: IRequestOptions | null = null) : Promise<string | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/job/delete/", {'Id':Id}, options)
            .then(response => response.json<string>());
    }

    static loadAnalysisResponseFromJob(Id: string, options: IRequestOptions | null = null)
        : Promise<RowShare.ApiResult<RowShare.AnalysisResponse>| null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/listanalysis/get/" + ApiUtils.encodeSegment(Id), options)
                .then(response => response.json<RowShare.ApiResult<RowShare.AnalysisResponse>>());
    }

    static updateJobStatus(Id: string, status: RowShare.JobStatus, options: IRequestOptions | null = null)
        : Promise<RowShare.ApiResult<RowShare.Job> | null> {
        options = ApiUtils.createRequestOptions({ cache: false}, options);
        var data ={ Id: Id, Status: status};
        return ApiUtils.apiPostJson("/job/setstatus/", data, options)
                .then(response => response.json<RowShare.ApiResult<RowShare.Job>>());
    }

    static save(job: RowShare.Job, options: IRequestOptions | null = null)
        : Promise<RowShare.ApiResult<RowShare.Job> | null> {
        options = ApiUtils.createRequestOptions({clearCache: true}, options);
        return ApiUtils.apiPostJson("/job/save/", job, options)
                .then(response => response.json<RowShare.ApiResult<RowShare.Job>>());
    }

    static loadLastAnalysisJob(listId: string, options: IRequestOptions | null = null)
        : Promise<RowShare.ApiResult<RowShare.Job> | null> {
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiGet("/job/loadlastanalysisjob/" + ApiUtils.encodeSegment(listId), options)
            .then(response => response.json<RowShare.ApiResult<RowShare.Job>>());
    }

}