
import { Component, Prop } from "vue-property-decorator";
import { FormValidation } from "@/views/components/FormValidation";
import { ValidationObserver } from "vee-validate";
import Bandeau from "@/views/layouts/LayoutParts/Bandeau.vue";
import * as API from "@/api/Api";
import * as Models from "@/models/RowShare";
import PageLoadSpinner from "@/views/layouts/LayoutParts/PageLoadSpinner.vue";
import InputPassword from "@/views/components/FormInputs/InputPassword.vue";
import { Location as RouterLocation } from "vue-router";
import { RouterUtils } from "@/modules/Router";
import { ApplicationModule } from "@/store/ApplicationStore";
import { OnPremModule } from "@/store/OnPremStore";

@Component({
  name: "ResetPassword",
  components: { ValidationObserver, Bandeau, PageLoadSpinner, InputPassword },
})
export default class ResetPassword extends FormValidation {
  @Prop() token!: string;
  private resetPasswordModel!: Models.ResetPassword;
  private resetPasswordSuccess: boolean = false;
  private resetPasswordError: boolean = false;
  private tokenError: boolean = false;

  created() {
    this.resetPasswordModel = new Models.ResetPassword();
    this.resetPasswordModel.Password = "";
    this.resetPasswordModel.ConfirmPassword = "";
    this.resetPasswordModel.Token = this.token;
    API.User.validateToken(this.token).then((res) => {
      if (!res) {
        this.tokenError = true;
      }
    });
  }

  onSubmit() {
    API.User.resetPassword(this.resetPasswordModel).then((res) => {
      if (res) {
        this.resetPasswordSuccess = true;
      } else {
        this.resetPasswordError = true;
      }
    });
  }

  //TODO replace getXX() by get XX()

  getLoginUrl() {
    return <RouterLocation>{
      name: "Login",
      query: {
        ReturnUrl: RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl"),
      },
    };
  }

  getResetPasswordUrl() {
    return <RouterLocation>{
      name: "ResetPasswordRequest",
      query: {
        ReturnUrl: RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl"),
      },
    };
  }
  get isNotOnPrem() {
    return !OnPremModule.isOnPrem;
  }

  get passwordRule() {
    return ApplicationModule.passwordRegex;
  }
}
