
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { CatalogModule } from '@/store/CatalogStore'
    import PageTitle from '@/views/layouts/LayoutParts/PageTitle.vue';
    import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
    import Fuse, { IFuseOptions } from 'fuse.js';
    import * as RowShare from '@/models/RowShare';
    import { OnPremModule } from '@/store/OnPremStore';
    import { Utils } from "@/utils/Utilities";
    import { Location as RouterLocation } from 'vue-router';
    import CreateTableActions from '@/views/CreateTable/CreateTableActions.vue';
    import { EventBus } from '@/modules/EventBus';
    import { UserModule } from '@/store/UserStore';

    @Component({
        name: "CreateTable",
        components: { PageTitle, CreateTableActions, PageLoadSpinner }
    })
    export default class CreateTable extends Vue {
        
        // Properties
        @Prop() folderId!: string;
        @Prop() organizationId!: string;
        @Prop() category!: string;

        // Fields
        private embedRoot: string = process.env.VUE_APP_EMBED_ROOT;

        private categories: RowShare.CatalogCategory[] = [];

        private activeCategory: RowShare.CatalogCategory | null = null;

        private indexedCatalog: RowShare.CatalogItem[] | null = null;

        private search: string = "";

        private searchEngine: Fuse<RowShare.CatalogItem> | null = null;

        private filePath: string | null = null;

        private hasChangedLanguage: boolean = false;

        private selectedCategory: number = 0;
        
        private loadingEntry: string | null = null;

        // Computed Properties
        get filteredCatalog() {
            if (this.searchEngine === null || Utils.isNullOrWhiteSpace(this.search))
                return this.indexedCatalog;

            return this.searchEngine.search(this.search).map(result => result.item);
        }

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;
        }


        // Lifecycle Hooks
        async created(){
            EventBus.$on(RowShare.Event.LOCALE_CHANGED, async() => {
                await this.onLocaleChanged();
            });
        }

        async mounted() : Promise<void> {
            if (this.isNotOnPrem){
                await this.initCatalog();
            }
        }

        // Methods
        async initCatalog() : Promise<void> {

            if (!this.isNotOnPrem)
                return;

            // Fetch into store
            if(CatalogModule.isCatalogEmpty || this.hasChangedLanguage){
                await CatalogModule.fetchCatalogData();
                this.hasChangedLanguage = false;
            }

            // Retrieve data from store
            this.indexedCatalog = CatalogModule.catalog;

            // Add all categories filter
            let allCategory = {
                Id: "all",
                Name: this.$i18n.tc('CreateTable_AllCategory'),
                DisplayName: this.$i18n.tc('CreateTable_AllCategory'),
                Color: "primary",
                Description: "",
                IsDefault: true
            };
            this.activeCategory = allCategory;
            this.categories = [];
            this.categories.push(allCategory, ...CatalogModule.categories);
            if(this.category && this.category!=''){
                let cat = this.categories.find(c => c.Id == this.category);
                if(cat){
                    this.filterCategory(cat);
                    this.selectedCategory = this.categories.indexOf(cat);
                }
            }

            this.initSearch();
        }

        async onLocaleChanged() :Promise<void> {
            this.hasChangedLanguage = true;
            if (this.isNotOnPrem){
                await this.initCatalog();
            }
        }

        initSearch() : void {
            if (!this.isNotOnPrem)
                return;

            if (this.indexedCatalog == null)
                return;

            const options: IFuseOptions<RowShare.CatalogItem> = {
                shouldSort: true,
                threshold: 0.1,
                minMatchCharLength: 2,
                distance: 5000,
                keys: ['DisplayName', 'ShortDescription']
            };
            this.searchEngine = new Fuse(this.indexedCatalog, options);
            this.search = "";
        }

        getCreateTableUrl(entry: RowShare.CatalogItem) : string {
            let folderIdStr = this.folderId ? `&folderId=${this.folderId}` : '';
            let listIdStr = "key=" + entry.ListId;
            return `/action/clonelist?${listIdStr}`+
                                      folderIdStr+
                                      '&copyoptions=CopyRows:true;'+
                                                   'CopyReports:true;'+
                                                   'ForceUseUserFolder:true;'+
                                                   'CreateNotifications:true;'+
                                                   'AutoRowsHeight:true;';
        }
        
        useTemplate(entry: RowShare.CatalogItem) {
            this.loadingEntry = entry.ListId;
            window.location.href = this.getCreateTableUrl(entry);
        }

        filterCategory(category: RowShare.CatalogCategory) : void {
            this.activeCategory = category;
            if (category.Id == "all") {
                this.indexedCatalog = CatalogModule.catalog;
            } else if (CatalogModule.catalog) {
                let catContent = CatalogModule.catalog
                                     .filter(i => i.Category.Id == category.Id);
                this.indexedCatalog = catContent ? catContent : null;
            }

            this.initSearch();
        }

        get pageTitle(){
            return this.$route.query.fromSignup
                ? this.$i18n.t('CreateTable_PageTitle_FromSignup', [this.$route.query.fromSignup]).toString() 
                : this.$i18n.t('CreateTable_PageTitle').toString();
        }

        get pageDescription(){

            let user = UserModule.CurrentUser;
            if(user === null || user.IsAnonymous) {
                return this.$i18n.t('CreateTable_PageDescriptionAnonymous').toString();
            }

            return this.$route.query.fromSignup
                ? this.$i18n.t('CreateTable_PageDescription_FromSignup', [this.$route.query.fromSignup]).toString() 
                : this.$i18n.t('CreateTable_PageDescription').toString();
        }

        get stillLookingSubtitle(){
            let user = UserModule.CurrentUser;
            if(user === null || user.IsAnonymous) {
                return this.$i18n.t('CreateTable_StillLookingSubtitleAnonymous').toString();
            }

            return  this.$i18n.t('CreateTable_StillLookingSubtitle').toString();
        }
    }
