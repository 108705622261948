import { LocaleMessages, LocaleMessageObject } from 'vue-i18n';
import { ApiUtils, IRequestOptions } from './ApiUtils';

export class Translation {
    static loadAll(options: IRequestOptions | null = null) : Promise<LocaleMessages | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/translation/loadall", options)
            .then(response => response.json<LocaleMessages>());
    }

    static load(lang: string, options: IRequestOptions | null = null) : Promise<LocaleMessageObject | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/translation/load/" + lang, options)
            .then(response => response.json<LocaleMessageObject>());
    }
}