
    import { Component, Prop, Vue, Watch } from "vue-property-decorator";
    import { EventBus } from "@/modules/EventBus";
    import * as Models from '@/models/RowShare';
    import { RowErrorEventParams, GridErrorEventParams, NotificationType, GlobalNotificationEventParams } from '@/models/RowShare';
    import RowVM from '@/viewModels/Table/rowVM';

    @Component({
        name: "NotificationStack",
        components: { }
    })
    export default class NotificationStack extends Vue  {

        private toasts: ToastVM[] = [];
        showActionButton: boolean = false;
        actionButtonText: string | null = null;
        actionButtonClick!: () => void;

        created(){
            EventBus.$on(Models.Event.GRID_ERROR, (evt: RowErrorEventParams) => this.onGridError(evt));
            EventBus.$on(Models.Event.ROW_ERROR, (evt: RowErrorEventParams) => this.onRowError(evt));
            EventBus.$on(Models.Event.ROW_SAVED, (rowVM: RowVM) => this.clearToastsForRow(rowVM));
            EventBus.$on(Models.Event.ROW_DELETE, (rowVMs: RowVM[]) => this.clearToastsForRows(rowVMs));
            EventBus.$on(Models.Event.ROW_ARCHIVE, (rowVMs: RowVM[]) => this.clearToastsForRows(rowVMs));
            EventBus.$on(Models.Event.GLOBAL_NOTIFICATION_RAISED, (evt: GlobalNotificationEventParams) => this.onGlobalNotification(evt));
            EventBus.$on(Models.Event.CLEAR_ALL_GLOBAL_NOTIFICATIONS, () => this.onClearAllGlobalNotifications());
        }

        beforeDestroy() {
            EventBus.$off(
                [
                    Models.Event.GRID_ERROR,
                    Models.Event.ROW_ERROR,
                    Models.Event.ROW_SAVED,
                    Models.Event.ROW_DELETE, 
                    Models.Event.ROW_ARCHIVE,
                    Models.Event.GLOBAL_NOTIFICATION_RAISED,
                    Models.Event.CLEAR_ALL_GLOBAL_NOTIFICATIONS
                ]);
        }

        onRowError(evt: RowErrorEventParams){
            if(!evt.title)
                evt.title = this.$i18n.t("ErrorNotif_DefaultTitle").toString();

            this.toasts = this.toasts.filter(r => r.isOpen && (!r.key || r.type != NotificationType.error || r.key != evt.rowVM.rowVMId));
            this.toasts.push(new RowErrorToastVM(evt));
        }

        onGridError(evt: GridErrorEventParams){
            if(!evt.title)
                evt.title = this.$i18n.t("ErrorNotif_DefaultTitle").toString();

            this.toasts = this.toasts.filter(r => r.isOpen);
            this.toasts.push(new ToastVM(evt.title, evt.message, NotificationType.error, null));
        }

        onGlobalNotification(evt: GlobalNotificationEventParams){
            this.toasts = this.toasts.filter(r => r.isOpen && (evt.duplicateId == null || r.duplicateId != evt.duplicateId));
            var toast = new ToastVM(evt.title, evt.message, evt.type, evt.duplicateId);
            this.showActionButton = evt.showActionButton;
            if(this.showActionButton) {
                this.actionButtonText = evt.actionButtonText;
                this.actionButtonClick = evt.actionButtonClick;
            }
            this.toasts.push(toast);
            if(evt.autoHide) {
                setTimeout(() => {
                    this.toasts = this.toasts.filter(r => r != toast);
                }, evt.autoHideAfterMs);
            }
        }

        onClearAllGlobalNotifications() {
            this.toasts = [];
        }

        clearToastsForRow(rowVM: RowVM) {
            this.toasts = this.toasts.filter(r => r.isOpen && (!r.key || r.type != NotificationType.error || r.key != rowVM.rowVMId));
        }

        clearToastsForRows(rowVMs: RowVM[]) {
            let rowIds = rowVMs.map((rvm) =>  rvm.rowVMId);
            this.toasts = this.toasts.filter(r => r.isOpen && (!r.key || r.type != NotificationType.error || !rowIds.includes(r.key)));
        }

        onActionButtonClick(toast: ToastVM) {
            this.toasts = this.toasts.filter(t => t != toast);
            this.actionButtonClick();
        }
    }

    class ToastVM{
        static nextId = 0;

        id: number;
        title: string;
        message: string | null;
        type: NotificationType;
        duplicateId: string | null;

        isOpen = true;

        constructor(title: string, message: string | null, type: NotificationType, duplicateId: string | null){
            this.id = ToastVM.nextId;
            ToastVM.nextId++;

            this.title = title;
            this.message = message;
            this.type = type;
            this.duplicateId = duplicateId;
        }

        get key() : any { return undefined; }

        get backgroundColor() {
            switch(this.type){
                case NotificationType.error:
                    return "error lighten-2";
                case NotificationType.warning:
                    return "warning lighten-2";
                default:
                    return "lightest"
            }
        }

        get iconColor() {
            switch(this.type){
                case NotificationType.error:
                    return "error";
                case NotificationType.warning:
                    return "warning";
                case NotificationType.success:
                    return "success";
                default:
                    return "primary"
            }
        }

        get textColor() {
            switch(this.type){
                case NotificationType.error:
                    return "error";
                case NotificationType.warning:
                    return "warning";
                default:
                    return "darkest"
            }
        }

        get icon(){
            switch(this.type){
                case NotificationType.error:
                    return "far fa-times-circle";
                case NotificationType.warning:
                    return "far fa-exclamation-triangle";
                case NotificationType.success:
                    return "far fa-check-circle";
                default:
                    return "far fa-exclamation-circle";
            }
        }
    }

    class RowErrorToastVM extends ToastVM {
        rowVM: RowVM;

        get key() : any { return this.rowVM.rowVMId; }

        constructor(params: RowErrorEventParams){
            super(params.title, params.message, NotificationType.error, null);
            this.rowVM = params.rowVM;
        }
    }
