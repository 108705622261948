
import {Component, Watch } from 'vue-property-decorator';
import ColumnSelector from '@/views/Table/ColumnSettings/ColumnSelector.vue'
import * as models from '@/models/RowShare';
import * as api from '@/api/Api';
import { EventBus } from '@/modules/EventBus';
import { FormValidation } from "@/views/components/FormValidation";
import InputText from '@/views/components/FormInputs/InputText.vue';
import InputTextArea from '@/views/components/FormInputs/InputTextArea.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ColumnTypes } from '@/utils/ColumnTypes';
import { UserModule } from '@/store/UserStore';
import { Utils } from '@/utils/Utilities';
import { RowShareException } from '@/api/ApiUtils';
import ColumnSettingsLookupEditor from '@/views/Table/ColumnSettings/ColumnSettingsLookupEditor.vue';
import ColumnSettingsLookupListEditor from '@/views/Table/ColumnSettings/ColumnSettingsLookupListEditor.vue';
import ColumnSettingsRelatedColumnsEditor from '@/views/Table/ColumnSettings/ColumnSettingsRelatedColumnsEditor.vue';
import ColumnSettingsColorEditor from '@/views/Table/ColumnSettings/ColumnSettingsColorEditor.vue';
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

@Component({name: 'SidePanelColumnSettings', components: { ColumnSelector, ValidationObserver, ValidationProvider, InputText, InputTextArea,
                                                           ColumnSettingsLookupEditor, ColumnSettingsLookupListEditor, ColumnSettingsRelatedColumnsEditor,
                                                           ColumnSettingsColorEditor}})
export default class SidePanelColumnSettings extends FormValidation {
    showMe: boolean = false;
    columnId: string = '';
    currentListId: string = '';
    width: string = '0px';
    currentColumn: models.Column | null = new models.Column();
    originalColumn: models.Column = new models.Column();
    currentList: models.List | null = null;
    currentOrganization: models.Organization | null = null;
    selectedColumnId: string = '';
    settingsHeight: number = 0;
    settingsSaved: boolean = false;
    columnTypeError: string = '';
    disableRelatedColumnsSelection: boolean = false;
    savedColumn: models.Column | null = null;
    validForm: boolean = false;
    defaultProps = { maxHeight: 200 };
    saveInProgress: boolean = false;

    $refs!: {
        observer: InstanceType<typeof ValidationObserver>,
        sidepanelHeader: InstanceType<typeof HTMLElement>,
        form: any
    };    

    created() {
        EventBus.$on(models.Event.SHOW_SIDEPANEL_COLUMN_SETTINGS, (params: models.showSidePanelColumnSettingsEventParams) => this.onShowSidePanel(params));
        window.addEventListener('resize', this.refreshColumnSettingsHeight);
    }

    beforeDestroy() {
        EventBus.$off([models.Event.SHOW_SIDEPANEL_COLUMN_SETTINGS]);
        window.removeEventListener('resize', this.refreshColumnSettingsHeight);
    }

    async onShowSidePanel(event: models.showSidePanelColumnSettingsEventParams) {
        this.columnId = event.columnId;
        this.currentListId = event.listId;
        this.width = "35%";
        await this.initPanel();        
        setTimeout(() => {
            this.showMe = true;
        }, 0);

        if((<any>window).Intercom) {
            (<any>window).Intercom('update', { 'hide_default_launcher': true});
        }
    }

    async initPanel() {
        await this.refreshColumnSettings();
        if(this.currentColumn != null) {
            this.currentList = await api.List.load(this.currentColumn.ListId);
        }
        if(this.currentList != null) {
            this.currentOrganization = await api.Organization.load(this.currentList.OrganizationId);
        }

        this.refreshColumnSettingsHeight();
    }

    onSelectedColumnIdChanged() {
        this.columnId = this.selectedColumnId;
        this.refreshColumnSettings();
    }

    @Watch('currentColumn')
    refreshColumnSettingsHeight() {
        if(!this.$refs.sidepanelHeader) {
            return;
        }
        let maxHeight = window.innerHeight - this.$refs.sidepanelHeader.getBoundingClientRect().height;
        this.settingsHeight = maxHeight;
    }

    onClickOutside() {
        if(this.showMe) {
            this.onCancel();
        }
    }

    shouldClosePanel(event: any): boolean {
        if(!event?.target?.classList || this.saveInProgress) {
            return false;
        }
        return event.target.classList.contains('v-overlay__scrim');
    }

    onCancel() {
        this.closePanel();
    }

    closePanel() {
        this.columnId = "";
        this.showMe = false;
        this.width = "0px";
        this.currentList = null;
        this.currentOrganization = null;
        this.currentColumn = new models.Column();
        if(this.settingsSaved) {
            EventBus.$emit(models.Event.COLUMNSETTINGS_CHANGED);
            this.settingsSaved = false;
        }
        if((<any>window).Intercom) {
            (<any>window).Intercom('update', { 'hide_default_launcher': false});
        }
    }

    async refreshColumnSettings() {
        if(!this.columnId) {
            return;
        }
        this.currentColumn = await api.Column.load(this.columnId, {cache: false});
        this.selectedColumnId = this.columnId;
    }

    onLookupValuesChanged(value: string){
        if(this.currentColumn){
            this.currentColumn.LookupValues = value;
            this.updateColumnSettings();
        }
    }

    onLinkedColumnChanged(link: any) {
        if(link && this.currentColumn) {
            this.currentColumn.LookupListColumnName = link.linkedColumnName;
            this.currentColumn.LookupListId = link.linkedListId;
            this.updateColumnSettings();
        }
    }

    async onRelatedListChanged(event: any) {
        if(event && this.currentColumn) {
            this.currentColumn.RelatedListId = event.RelatedListId;
            this.updateColumnSettings()
        }
    }

    onChildColumnsChanged() {
        this.settingsSaved = true;
    }

    @Watch('currentColumn.Type')
    resetValues() {
        if(this.currentColumn?.Type === models.ColumnStrongType.LookUp) {
            if(Utils.isNullOrWhiteSpace(this.currentColumn.LookupValues)) {
                this.currentColumn.LookupValues = ColumnTypes.defaultLookupValues;
            }
            this.currentColumn.MaxLength = 0;
        }
    }

    isValidNumber(value: any): boolean | string {
        let valStr = value?.toString() ?? "";
        if(Utils.isNullOrWhiteSpace(valStr)) {
            return true;
        }
        const regExp = /^[\-]?\d*$/;

        var number: number | null = null;
        if(this.currentColumn?.Type === models.ColumnStrongType.DecimalNumber || this.currentColumn?.Type === models.ColumnStrongType.Percentage) {
            number = Utils.parseNumber(valStr, null);
        }
        else {
            number = Utils.parseInteger(valStr, null)
        }

        return (number != null) ? true : this.$i18n.t('ColumnSettings_InvalidNumber').toString();
    }

    async validateForm(): Promise<boolean> {
        this.columnTypeError = "";
        let isValid = await (<any>this.$refs.observer).validate();
        isValid = await isValid && (<any>this.$refs.form).validate();
        if(isValid) {
            if(!this.currentColumn?.Type) {
                this.columnTypeError = this.$i18n.t('ColumnSettings_MandatoryProperty', [this.$i18n.t('ColumnSettings_Type').toString()]).toString();
                return false;
            }
        }
        return isValid;
    }


    async updateColumnSettings() {
        this.disableRelatedColumnsSelection = true;
        let isValid = await this.validateForm();        
        if(isValid) {
            if(this.currentColumn && this.columnSettingsHaveChanged) {
                if(this.currentColumn.Type != this.originalColumn?.Type && this.currentColumn.Type == models.ColumnStrongType.LookUp && !this.currentColumn.LookupValues) {
                    this.currentColumn.LookupValues = ColumnTypes.defaultLookupValues;
                }
                this.saveInProgress = true;
                this.currentColumn.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
                api.Column.save(this.currentColumn, {clearCache: true})
                    .then(savedColumn => {
                        Object.assign(this.originalColumn, this.currentColumn);
                        EventBus.$emit(models.Event.COLUMN_INFOS_CHANGED, savedColumn);
                        EventBus.$emit(models.Event.GLOBAL_NOTIFICATION_RAISED, <models.GlobalNotificationEventParams>{ message: this.$i18n.t('ColumnSettings_SaveSuccess'),
                            type: models.NotificationType.success,
                            autoHide: true, autoHideAfterMs: 4000,
                            duplicateId: 'ColumnSaveSuccess'});
                        setTimeout(() => { this.disableRelatedColumnsSelection = false}, 0);
                        this.settingsSaved = true;
                    })
                    .catch(exc => {
                        let rsExc = exc as RowShareException;
                        let exceptionMsg = "";
                        if(rsExc) {
                            exceptionMsg = rsExc.message;
                        }
                        else {
                            exceptionMsg = this.$i18n.t('ColumnSettings_SaveError').toString();
                        }
                        EventBus.$emit(models.Event.GLOBAL_NOTIFICATION_RAISED, <models.GlobalNotificationEventParams>{ message: exceptionMsg,
                            type: models.NotificationType.error,
                            autoHide: true, autoHideAfterMs: 10000});
                        setTimeout(() => { this.disableRelatedColumnsSelection = true}, 0);
                    })
                    .finally(() => {
                        this.saveInProgress = false;
                    });
            }
        }
    }

    getLocaleText(key: string) {
        if(this.existsLocalization(key)) {
            return this.$i18n.t(key).toString();
        }
        return '';
    }

    existsLocalization(key: string) {
        return this.$i18n.te(key);
    }

    copyColumnId() {
        if(!this.currentColumn?.Id) {
            return;
        }
        document.addEventListener('copy', this.copyToClipboard);
        document.execCommand('copy');
        EventBus.$emit(models.Event.GLOBAL_NOTIFICATION_RAISED, <models.GlobalNotificationEventParams> {
            autoHide: true,
            autoHideAfterMs: 2000,
            duplicateId:'CopyColumnId',
            message: this.$i18n.t('SidePanelColumnSettings_IdCopied').toString(),
            type: models.NotificationType.success
        });
    }

    copyToClipboard(event: ClipboardEvent) {
        if(!this.currentColumn?.Id) {
            return;
        }
        event.clipboardData?.setData('text/plain', this.currentColumn?.Id);
        event.preventDefault();
        document.removeEventListener('copy', this.copyToClipboard);
    }

    get columnSettingsHaveChanged() {
        return this.currentColumn && JSON.stringify(this.currentColumn) != JSON.stringify(this.originalColumn);
    }

    get columnTypes(): any[] {
        let types = ColumnTypes.getColumnTypesWithCategoriesSelectorItems(UserModule.connectedUser?.PreviewFeaturesEnabled ?? false);

        const typesToRemoveCriteria = (item : models.ColumnStrongType) =>
            models.ColumnType.metadataTypes().includes(item)
            //|| ColumnType.lookupListTypes().includes(item)
            || models.ColumnType.relatedRowTypes().includes(item);

        if(typesToRemoveCriteria(this.currentColumn!.Type)){
            return types;
        }
        let filteredType = types.filter(item => !typesToRemoveCriteria(item.value));
        filteredType = filteredType.filter(item => !item.category || item.category !== models.ColumnTypeCategoryName.row);
        return filteredType;
    }

    get isNotAllowedColumnType(): boolean {
        if (!this.currentColumn)
            return true;
        if (models.ColumnType.metadataTypes().includes(this.currentColumn.Type))
            return true;
        else if (models.ColumnType.relatedRowTypes().includes(this.currentColumn.Type))
            return true;
        return false;
    }

    get cannotUpdateType(): boolean {
        return ((this.currentColumn?.Type === models.ColumnStrongType.Relation && !Utils.isNullOrWhiteSpace(this.currentColumn.RelatedListId)) || this.isNotAllowedColumnType);
    }

    get numberPreview(): string {
        if(!this.currentColumn) {
            return "";
        }
        let num = Number.parseFloat('12345.67890');
        return (this.currentColumn.Prefix ?? '') + this.formatNum(num) + (this.currentColumn.Suffix ?? '');
    }

    formatNum(num: number){
        if(isNaN(num) || !this.currentColumn)
            return "";

        return num.toLocaleString(navigator.language, {
            useGrouping: this.currentColumn.showThousandsSeparator,
            minimumFractionDigits: this.currentColumn.MaxDecimals,
            maximumFractionDigits: this.currentColumn.MaxDecimals
        })
    }

    get showNumbersOptions(): boolean {
        if(!this.currentColumn) {
            return false;
        }
        return this.currentColumn.canEditMaxDecimals || this.currentColumn.canEditMinMaxValue || this.currentColumn.canEditPrefix
                || this.currentColumn.canEditShowThousandsSeparator || this.currentColumn.canEditSuffix
                || this.currentColumn.canEditUseSmartRenderer;
    }

    get canEditCommonOptions(): boolean {
        if(!this.currentColumn) {
            return false;
        }

        return this.currentColumn.canEditIsUnique || this.currentColumn.canEditColor || this.currentColumn.canEditFormula 
                || this.currentColumn.canEditDefaultValue || this.currentColumn.canEditIsFormula || this.currentColumn.canEditIsMandatory;
    }
}
