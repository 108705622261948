import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"

export class Culture {

    static loadCultures(options: IRequestOptions | null = null): Promise<Array<Models.Culture> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/culture/getCultures/", options)
            .then(response => response.objects(Models.Culture));
    }
}