
import { Component, Vue } from 'vue-property-decorator';
import * as RowShare from '@/models/RowShare';
import * as API from '@/api/Api';
import { IGroupEditorParams } from '@/viewModels/ManageOrganization/groupVM';
import { Utils } from '@/utils/Utilities';
import Multiselect from 'vue-multiselect';

@Component({name: 'groupsEditor', components: { Multiselect }})
export default class groupsEditor extends Vue {
    groups!: RowShare.Group[];
    params!: IGroupEditorParams;
    organizationId!: string;
    selectedGroups!: RowShare.Group[];

    constructor(){
        super();
        this.selectedGroups = [];
        this.groups = [];
    }
    
    beforeMount(){
        this.organizationId = this.params.organizationId;
        this.selectedGroups = [];
        if(this.organizationId){
            API.Group.loadForParent(this.organizationId, {cache: false}).then(groups => {
                if(groups){
                    this.groups = groups.filter(g => !g.IsMyOrganization);
                    if(this.params.idsToRemove && this.params.idsToRemove.length > 0){
                        this.groups = this.groups.filter(g => this.params.idsToRemove.find(id => id == g.Id) == undefined)
                    }
                    this.selectedGroups = this.params.value;
                }
            })
        }
    }

    mounted(){
        (<any>(this.$refs.multiselect)).activate();        
    }

    getValue(){
        return this.selectedGroups;
    }

    isPopup() {
        return true;
    }

    isSelected(item: RowShare.Group) {
        if(Utils.isArray(this.selectedGroups)) {
            let idx = this.selectedGroups.findIndex(i => i.Name == item.Name);
            return idx > -1;
        }
        return false;
    }
}
