import * as agGrid from 'ag-grid-community';
import { i18n } from "@/modules/Localization";
import GridViewVM from '@/viewModels/Table/gridViewVM';
import ColumnVM from '@/viewModels/Table/columnVM';
import * as models from "@/models/RowShare";
import * as api from "@/api/Api";
import router from '@/modules/Router';
import { EventBus } from '@/modules/EventBus';
import vuetify from "@/modules/Vuetify";
import ListDisplayModeVM from './listDisplayModeVM';
import _ from "lodash";
import { GlobalNotificationEventParams } from '@/models/RowShare';
import { Utils } from '@/utils/Utilities';
import { ColumnTypes } from '@/utils/ColumnTypes';
import { ListTreeModule } from '@/store/ListTreeStore';
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

export class columnContextMenu {
    public static getMainMenuItems(params: agGrid.GetMainMenuItemsParams) {
        let columnMenu = [];
        let grid = <GridViewVM>params.context;
        let hasHiddenColumnBeside: boolean = false;
        let colState = params.columnApi?.getColumnState() ?? [];
        let displayedColBefore = params.columnApi?.getDisplayedColBefore(params.column);
        let firstColumn = !displayedColBefore || (displayedColBefore.getColId() == ColumnVM.rowOwnerHeaderId);
        let lastDiplayedColumn = (!params.columnApi?.getDisplayedColAfter(params.column) && firstColumn);
        let isAutoGroupColumn = (params.column.getId() === ColumnVM.autoGroupColumnId);
        let specialRowsDisplayed = grid.listVM?.specialRowsDisplayed;
        let listOwned = grid?.listVM.list?.Owned;

        let colIndex = colState.findIndex(cs => cs.colId == params.column.getColId());
        if(colIndex > -1 && colIndex + 1 < colState.length)
        {
            hasHiddenColumnBeside = colState[colIndex + 1].hide ?? false;
        }
        let rsColumn = (<ColumnVM>params.column.getColDef())?.rsColumn;
        
        if(listOwned && !grid?.listVM.list?.LoadedByReadOnlyMember && !isAutoGroupColumn && !specialRowsDisplayed) {
            columnMenu.push(new columnSettingsMenuEntry(params));
            columnMenu.push(new columnPermissionsMenuEntry(params));
            if(rsColumn?.canEditIsMandatory){
                columnMenu.push(new toggleMandatoryColumnMenuEntry(params));
            }
            columnMenu.push('separator');
        }
        columnMenu.push('autoSizeThis');
        columnMenu.push('autoSizeAll');

        if(listOwned && !grid?.listVM.list?.LoadedByReadOnlyMember && !vuetify.framework.breakpoint.mobile && !isAutoGroupColumn
            && (Utils.isNullOrWhiteSpace(rsColumn.ColumnGroup) || params.column.getPinned()== 'left')) {
            columnMenu.push(new togglePinColumnMenuEntry(params.column, grid));
        }
        if(!firstColumn && !isAutoGroupColumn) {
            columnMenu.push(new hideColumnMenuEntry(params));
        }

        if(hasHiddenColumnBeside) {
            columnMenu.push(new showHiddenColumnsMenuEntry(params.column, grid));
        }

        if(isAutoGroupColumn) {
            columnMenu.push('separator');
            columnMenu.push(new expandAllMenuEntry(params));
            columnMenu.push(new collapseAllMenuEntry(params));
        }

        if((!rsColumn?.isMandatory || hasHiddenColumnBeside || grid?.listVM.list?.Owned) && !isAutoGroupColumn) {
            columnMenu.push('separator');
        }
        
        var columnTypes = ColumnTypes.getColumnTypes(true, ListTreeModule.currentOrganization?.HasRowMetadatas ?? false);
        var columnTypeIndex = columnTypes.findIndex((type) => type.StrongType === rsColumn.Type);
        
        var columnType = columnTypes[columnTypeIndex];

        if(!isAutoGroupColumn && !specialRowsDisplayed && !rsColumn.HasParentColumn && listOwned && !grid?.listVM.list?.LoadedByReadOnlyMember) {
            columnMenu.push(new insertColumnBefore(params));
            if(rsColumn.Type !== models.ColumnStrongType.AutoNumber && (!(rsColumn.isTechnical && !rsColumn.isOwnerInfo) || columnType.HasRequiredSubscription)) {
                columnMenu.push(new cloneColumnAfter(params));
            }
        }

        if(listOwned && !grid?.listVM.list?.LoadedByReadOnlyMember && !isAutoGroupColumn  && !specialRowsDisplayed) {
            if(!params.column?.getColDef()?.lockPosition && !lastDiplayedColumn && !(firstColumn && hasHiddenColumnBeside) && rsColumn?.canBeRemoved) {
                columnMenu.push(new removeColumnMenuEntry(params));
            }
        }
        if(params.column.isAllowRowGroup() && !isAutoGroupColumn) {
            columnMenu.push('separator');
            if(params.column.isRowGroupActive()) {
                columnMenu.push('rowUnGroup');
            }
            else {
                columnMenu.push('rowGroup');
            }
        }
        
        if(isAutoGroupColumn) {
            columnMenu.push('separator');
            columnMenu.push(new clearRowGroupsColumnMenuEntry());
        }

        if(!isAutoGroupColumn && (rsColumn.isDateFormat || rsColumn.isDateTimeFormat)) {
            columnMenu.push(new showCalendarView(params));
        }
        return columnMenu;
    }
}

class clearRowGroupsColumnMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('SharingBar_ClearRowGroups').toString();

    public action = () => {
        EventBus.$emit(models.Event.GRID_CLEAR_ROWGROUPS);        
    }
}

class insertColumnBefore implements agGrid.MenuItemDef {
    public name = i18n.t('Action_InsertColumnBefore').toString();
    public cssClasses = ['insertColumn--menu'];
    private listId: string;
    private columnId: string;

    constructor(params: agGrid.GetMainMenuItemsParams) {
        this.listId = params?.context?.listVM?.list?.Id;
        this.columnId = params.column.getColId();
    }

    public action = () => {
        EventBus.$emit(models.Event.ADD_COLUMN, <models.AddColumnEventParams>{ insertBeforeColumnId: this.columnId });
    }
}

class cloneColumnAfter implements agGrid.MenuItemDef {
    public name = i18n.t('Action_CloneColumnAfter').toString();
    public cssClasses = ['cloneColumnAfter--menu'];
    private rsColumn: models.Column;
    private params: agGrid.GetMainMenuItemsParams;

    constructor(params: agGrid.GetMainMenuItemsParams) {       
        this.params = params; 
        this.rsColumn = (<ColumnVM>params.column.getColDef())?.rsColumn;
    }

    public action = async () => {
        if(!this.rsColumn)
            return;

        var cloneColumnResult = await api.Column.clone(this.rsColumn.Id, RealTimeCollaborationModule.connection.connectionId);
        if(cloneColumnResult?.IsValid) {
            let gridViewVM = <GridViewVM>this.params.context;
            gridViewVM.insertColumnInUI(cloneColumnResult.Value, this.rsColumn.Id, "after");
        }
    }

}

class showCalendarView implements agGrid.MenuItemDef {
    public name = i18n.t('Action_CalendarView').toString();
    public cssClasses = ['calendarView--menu'];

    private listId: string;
    private startColumnId: string;

    constructor(params: agGrid.GetMainMenuItemsParams) {
        this.listId = params?.context?.listVM?.list?.Id;
        this.startColumnId = params.column.getColId();
    }

    public action = () => {
        if(this.listId && this.startColumnId) {
            router.push({ name: 'Calendar', params: {listId: this.listId, startColumnId: this.startColumnId }});
        }
    }
}

class columnPermissionsMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('Action_ColumnPermissions').toString();

    private columnId: string;

    constructor(params: agGrid.GetMainMenuItemsParams) {
        this.columnId = params.column.getColId();
    }

    public action = () => {
        EventBus.$emit(models.Event.SHOW_SIDEPANEL_COLUMN_PERMISSIONS, <models.showSidePanelColumnPermissionsEventParams> { columnId: this.columnId});
    }
}

class expandAllMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('Action_ExpandAll').toString();

    private params: agGrid.GetMainMenuItemsParams;

    constructor(params: agGrid.GetMainMenuItemsParams) {
        this.params = params;
    }

    public action = () => {
        this.params?.api?.expandAll();
    }
}

class collapseAllMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('Action_CollapseAll').toString();

    private params: agGrid.GetMainMenuItemsParams;

    constructor(params: agGrid.GetMainMenuItemsParams) {
        this.params = params;
    }

    public action = () => {
        this.params?.api?.collapseAll();
    }
}

class columnSettingsMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('Action_ColumnSettings').toString();

    private params: agGrid.GetMainMenuItemsParams;
    private rsColumn: models.Column;

    constructor(params: agGrid.GetMainMenuItemsParams) {
        this.params = params;
        this.rsColumn = (<ColumnVM>this.params.column.getColDef())?.rsColumn;
    }

    public action = () => {
        let listId = this.params?.context?.listVM?.list?.Id;
        if(listId) {
            EventBus.$emit(models.Event.SHOW_SIDEPANEL_COLUMN_SETTINGS, 
                <models.showSidePanelColumnSettingsEventParams>{columnId: this.rsColumn.RelationColumnId ?? this.params.column.getColId(), listId: listId});
        }
    }
}

class toggleMandatoryColumnMenuEntry implements agGrid.MenuItemDef {
    private params: agGrid.GetMainMenuItemsParams;
    private rsColumn: models.Column;

    constructor(params: agGrid.GetMainMenuItemsParams) {
        this.params = params;
        this.rsColumn = (<ColumnVM>this.params.column.getColDef())?.rsColumn;
        this.icon = this.rsColumn?.isMandatory ? this.mandatoryIcon : "";        
    }

    private mandatoryIcon = "<i class='fal fa-check'/>";

    public name = i18n.t('Action_ToggleMandatoryColumn').toString();

    public action = () => {
        this.toggleMandatoryColumn(this.params.column);
    };

    async toggleMandatoryColumn(column: agGrid.Column) {
        if(!column){
            return;
        }
        let colVM = <ColumnVM>column.getColDef();
        if(colVM) {
            colVM.rsColumn.isMandatory = !colVM.rsColumn.isMandatory;
            colVM.rsColumn.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
            api.Column.save(colVM.rsColumn).then(savedColumn => {
                if(savedColumn) {
                    colVM.rsColumn = savedColumn;
                    if(this.params?.api) {
                        this.params.api.refreshHeader();
                    }
                    
                    var displayModeVM = <ListDisplayModeVM>this.params.context;
                    if( displayModeVM.listVM.columns) {
                        var idx = displayModeVM.listVM.columns.findIndex(c => c.Id == savedColumn.Id);
                        displayModeVM.listVM.columns[idx] = savedColumn;
                    }
                }
            });
        }
    }
   
    public icon: string = "";
}

class removeColumnMenuEntry implements agGrid.MenuItemDef {
    private params: agGrid.GetMainMenuItemsParams;
    private rsColumn: models.Column;

    constructor(params: agGrid.GetMainMenuItemsParams) {
        this.params = params;
        this.rsColumn = (<ColumnVM>this.params.column.getColDef())?.rsColumn;
    }

    public name = i18n.t('Action_RemoveColumn').toString();

    public action = async () => {
        if(!this.rsColumn)
            return;

        if(await api.Column.IsReferenced(this.rsColumn.Id)) {
            EventBus.$emit(models.Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{
                message: i18n.t('ColumnMenu_CannotDeleteReferenced').toString(),
                autoHide: true,
                autoHideAfterMs: 10000,
                type: 'error',
                duplicateId: 'CannotDeleteColumn'
            });
        }
        else {
            var evtArgs = new models.ConfirmationRequiredEventParams();
            evtArgs.title = i18n.t("ColumnMenu_DeleteColumn_Title", [this.rsColumn?.DisplayName]).toString();
            evtArgs.description1 = i18n.t("ColumnMenu_DeleteColumn_Description", [this.rsColumn?.DisplayName]).toString();
            evtArgs.cancelButtonText = i18n.t("ColumnMenu_DeleteColumn_CancelButton").toString();
            evtArgs.actionButtonText = i18n.t("ColumnMenu_DeleteColumn_ConfirmButton").toString();
            evtArgs.actionButtonIcon = "trash-alt";
            evtArgs.actionButtonColor = "error";

            evtArgs.onConfirmation = async () => {
                if(!this.rsColumn)
                    return;

                this.rsColumn.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
                api.Column.remove(this.rsColumn).then((apiRes: boolean | null) => {
                    let gridViewVM = <GridViewVM>this.params.context;

                    if(apiRes && this.params?.api && gridViewVM.listVM.columns) {

                        _.remove(gridViewVM.listVM.columns, c => c.Id == this.rsColumn.Id);

                        let colDefs = this.params.api.getColumnDefs();
                        if(!colDefs)
                            return;

                        if(this.rsColumn.ColumnGroup) {
                            this.removeFromGroup(colDefs, this.rsColumn.Id);
                        } else {
                            let deletedIndex = colDefs.findIndex(def => <agGrid.ColDef>def && (<agGrid.ColDef>def).colId == this.rsColumn.Id);
                            if(deletedIndex > -1) 
                                colDefs.splice(deletedIndex, 1);
                        }

                        this.params.api.setColumnDefs(colDefs);
                        this.params.api.redrawRows();
                        EventBus.$emit(models.Event.GRID_INFOS_CHANGED, <ListDisplayModeVM>this.params.context);
                        
                    } else {
                        EventBus.$emit(models.Event.GRID_ERROR, { message: i18n.t("ColumnMenu_DeleteColumn_ErrorTemplate", [this.rsColumn?.DisplayName]).toString() });
                    }
                }).catch((err: any) =>  {
                    console.log(err);
                    EventBus.$emit(models.Event.GRID_ERROR, { message: err._message });
                });
            }

            EventBus.$emit(models.Event.CONFIRMATION_REQUIRED, evtArgs);
        }
    }

    public removeFromGroup(colDefs: (agGrid.ColDef | agGrid.ColGroupDef)[], colId: string) : boolean {
        for(let i = 0; i < colDefs.length; ++i) {
            const children = (colDefs[i] as any).children;
            if(children) {
                if(this.removeFromGroup(children, colId))
                    return true;
            } else {
                let colVM = <ColumnVM>colDefs[i];
                if(colVM && colVM.rsColumn?.Id == colId) {
                    colDefs.splice(i, 1);
                    return true;
                }
            }
        }

        return false;
    }
}

class hideColumnMenuEntry implements agGrid.MenuItemDef {
    private params: agGrid.GetMainMenuItemsParams;

    constructor(params: agGrid.GetMainMenuItemsParams) {
        this.params = params;
    }

    public name = i18n.t('Action_HideColumn').toString();

    public action = () => {
        (<GridViewVM>this.params.context).hideColumn(this.params.column);
    }
}

class showHiddenColumnsMenuEntry implements agGrid.MenuItemDef {
    private column: agGrid.Column;
    private gridContext: GridViewVM;

    constructor(column: agGrid.Column, context: GridViewVM) {
        this.column = column;
        this.gridContext = context;
    }

    public name = i18n.t('Action_ShowHiddenColumns').toString();

    public action = () => {
        this.gridContext.showHiddenColumnsBeside(this.column);
    }
}

class togglePinColumnMenuEntry implements agGrid.MenuItemDef {
    private gridContext: GridViewVM;
    private column: agGrid.Column;

    constructor(column: agGrid.Column, context: GridViewVM) {
        this.gridContext = context;
        this.column = column;
        this.name = i18n.t('Grid_pinColumn').toString();

        this.gridContext.execOnceColumnApiIsReady(columnApi => {
            let colState = columnApi.getColumnState().find(cs => cs.colId === this.column.getColId());
            if(colState) {
                if(colState.pinned === true || colState.pinned === 'left') {
                    this.name = i18n.t('Grid_noPin').toString();
                }
            }
        });
    }

    public name: string;

    public action = () => {
        this.gridContext.togglePinColumn(this.column);
    }
}

// To Use if empty column function is re-activated
//
// async emptyColumn(columnId: string) {
//     let rsColumn = await API.Column.load(columnId);
//     if(!rsColumn) {
//         return;
//     }

//     var evtArgs = new RowShare.ConfirmationRequiredEventParams();
//     evtArgs.title = this.$i18n.t('ColumnMenu_ClearColumn_Title', [rsColumn.DisplayName]).toString();
//     evtArgs.description1 = this.$i18n.t('ColumnMenu_ClearColumn_Description', [rsColumn.DisplayName]).toString();
//     evtArgs.cancelButtonText = this.$i18n.t("ColumnMenu_ClearColumn_CancelButton").toString();
//     evtArgs.actionButtonText = this.$i18n.t("ColumnMenu_ClearColumn_ConfirmButton").toString();

//     evtArgs.onConfirmation = () => {
//         if(!rsColumn){
//             return;
//         }
//         API.Column.clear(rsColumn.Id)
//                   .then(result => {
//                       if(result) {
//                           this.gridViewVM.refreshRowsFromServer();
//                       }
//                   })
//                   .catch((err: any) =>  {
//                       EventBus.$emit(RowShare.Event.GRID_ERROR, { message: err._message });
//                   });
//     }
//     EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, evtArgs);
// }
