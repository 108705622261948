
import { ICellEditorParams } from 'ag-grid-community';
import { Component, Prop, Vue } from 'vue-property-decorator';
import RowVM from '@/viewModels/Table/rowVM';
import * as API from '@/api/Api';
import * as RowShare from "@/models/RowShare";
import { ListTreeModule } from '@/store/ListTreeStore';
import SecurityPrincipalDropdown from "@/views/components/SecurityPrincipalDropdown.vue";
import Bigram from "@/views/components/Bigram.vue";
import { BigramHelper } from '@/utils/BigramHelper';
import { MemberHelper } from '@/utils/MemberHelper';

@Component({name: "RowOwnerHeaderEditor", components: { SecurityPrincipalDropdown, Bigram }})
export default class RowOwnerHeaderEditor extends Vue{
    params!: ICellEditorParams;
    orgMembers: API.SecurityPrincipal[] = [];

    selectedPrincipal: RowShare.SecurityPrincipal | string | null = null;

    get rowOwner(){
        var rowVM = <RowVM>this.params.data;
        return rowVM?.rsRow?.Owner;
    }

    getValue(){
        return BigramHelper.getAssignationResultFromSecurityPrincipal(this.selectedPrincipal);
    }

    onDropdownClosed(){
        if(this.selectedPrincipal && this.currentOrganization) {
            MemberHelper.addMemberIfNecessary(this.selectedPrincipal, this.currentOrganization.Id);
        }
        this.params.stopEditing();
    }

    isPopup() {    
        return true;
    }

    get currentOrganization() : RowShare.ListTreeOrganization | null {
            return ListTreeModule.currentOrganization;
    }
}
