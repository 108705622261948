
import {Component, Prop } from 'vue-property-decorator';
import InputText from '@/views/components/FormInputs/InputText.vue';
import { FormValidation } from '@/views/components/FormValidation';
import { ValidationObserver } from 'vee-validate';
import SectionTitle from '@/views/layouts/LayoutParts/SectionTitle.vue';
import { Location as RouterLocation } from "vue-router";
import { OnPremModule } from '@/store/OnPremStore';
import * as API from '@/api/Api';
import { RouterUtils } from '@/modules/Router';
import { Utils } from '@/utils/Utilities';
import SocialLoginList from '@/views/Signin/SocialLoginList.vue'


@Component({
    name:'SignUpEmail',
    components: {InputText, ValidationObserver, SectionTitle, SocialLoginList}

})
export default class SignUpEmail extends FormValidation {

    @Prop() returnUrl!: string;
    @Prop() inviteLinkId!: string;

    private signUpEmail='';

    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };

    constructor() {
        super();
    }

    async mounted(){
        this.setValidationLanguage(this.$i18n.locale);
    }
    
    onSubmit(){
        if(!this.isValidEmail(this.signUpEmail)){      
            this.$refs.observer.setErrors({email :  [this.$i18n.t("SignUp_EmailInvalid").toString()]}) ;
            return;
        }
        API.User.validateUserEmail(this.signUpEmail).then(isValid=>{
                if(isValid){
                    this.$emit("change-email", this.signUpEmail);
                }
                else{
                    this.$router.push({name:'Login', params: {'emailFromSignUp':this.signUpEmail, 'inviteLinkId': this.inviteLinkId}, query: this.$route.query })
                }
            })
            .catch(er=>this.$refs.observer.setErrors({email : [this.$i18n.t("ExceptionCode_" + er.code).toString()]}));        
    }

    isValidEmail(email: string){
        return Utils.validateEmail(email);
    }

    externalSignUp(providerName:string){
        //The login function does the signup when the user is not registered.
        API.SocialProvider.signup(providerName, this.returnUrl, this.inviteLinkId).then(returnUrl=>{
            window.location.href = returnUrl != null ? returnUrl : '';
        }).catch(er=>{
            console.log(er);
        });  
    }

    clearValidations(){
        this.$refs.observer.reset();
    }
    
    get isNotOnPrem(){
        return !OnPremModule.isOnPrem;;
        }

    getLoginUrl() {
        return <RouterLocation>{
            name: "Login",
            query: {
                ReturnUrl: RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl"),
                orgid: RouterUtils.getCaseInsentiveQuery(this.$route, "orgid")
            },
            params: {
                inviteLinkId: this.inviteLinkId
            }
        };
    }
}
