
    import { Prop, Vue, Watch } from "vue-property-decorator";
    import Component from 'vue-class-component';
    import { ListTreeModule } from "@/store/ListTreeStore";
    import RowVM from '@/viewModels/Table/rowVM';
    import * as RowShare from '@/models/RowShare';
    import * as API from '@/api/Api';
    import ListVM from "@/viewModels/Table/listVM";
import { EventBus } from "@/modules/EventBus";

    @Component({
        name: "ListBasedRoot",
        components: { }
    })
    export default class ListBasedRoot extends Vue  {
        private lastListId : string | null = null;   
        listVM: ListVM = new ListVM();
        @Prop() msTeams!: boolean;
        isSurvey: boolean = false;

        get currentList() : RowShare.List | null {
            return ListTreeModule.currentList;
        }

        get displayedRows(): RowShare.DisplayedRows {
            return ListTreeModule.currentListDisplayedRows;
        }

        created(){
            this.isSurvey = this.$route.meta?.isSurvey ?? false;
            this.refreshAllListData();
            EventBus.$on(RowShare.Event.LISTSETTINGS_CHANGED, this.onSettingsChanged);
            EventBus.$on(RowShare.Event.COLUMNSETTINGS_CHANGED, this.onSettingsChanged);
        }

        beforeDestroy() {
            EventBus.$off([RowShare.Event.LISTSETTINGS_CHANGED, RowShare.Event.COLUMNSETTINGS_CHANGED]);
        }

        onSettingsChanged(list: RowShare.List) {
            window.location.reload();
        }
        
        @Watch('currentList')
        onListChanged(){
            this.refreshAllListData();
        }

        @Watch('displayedRows')
        onDisplayedRowsChanged() {
            let status: RowShare.DataStatus;
            switch(this.displayedRows) {
                case RowShare.DisplayedRows.Deleted:
                    status = RowShare.DataStatus.DeletionRequired;
                    break;
                case RowShare.DisplayedRows.Archived:
                    status = RowShare.DataStatus.Archived;
                    break;
                default:
                    status = RowShare.DataStatus.Normal;
                    break;
            }
            this.listVM.displayedRows = this.displayedRows;
            if(this.currentList) {
                this.listVM.notifyRowVMsUpdating();
                API.Row.loadForList(this.currentList.Id, status).then(rows =>  this.listVM.rowVMs = rows?.map(r => new RowVM(this.listVM, r)) ?? null);
            }
        }

        refreshAllListData(){
            if(!this.currentList)
                return;

            // list can change event after the initial load (when a save on the list object occured for instance, etc...)
            if(this.currentList.Id == this.lastListId)
                return;
                
            this.lastListId = this.currentList.Id;

            this.listVM.msTeamsMode = this.msTeams;
            this.listVM.list = this.currentList;
            API.Column.loadForList(this.currentList.Id).then(cols => this.listVM.columns = cols);
            if(!this.isSurvey) {
                API.Row.loadForList(this.currentList.Id).then(rows =>  this.listVM.rowVMs = rows?.map(r => new RowVM(this.listVM, r)) ?? null);
            }
        }
    }
