
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ListTreeOrganization, SubscriptionLimit, SubscriptionLimitType } from "@/models/RowShare";
import { ResLoader } from '@/utils/ResLoader';
import * as API from '@/api/Api';
import { ListTreeModule } from "@/store/ListTreeStore";

declare var Calendly: any;

@Component({
    name: "MyTablesDisabledOrganization"
})
export default class MyTablesDisabledOrganization extends Vue {
    limits: SubscriptionLimit[] | null = null;
    canCheckOutAlone: boolean = false;

    async created() {
        let scriptPromise = this.$loadScript('https://assets.calendly.com/assets/external/widget.js');
        ResLoader.loadCss('https://assets.calendly.com/assets/external/widget.css');

        await Promise.all([scriptPromise]);

    }
    mounted() {
        this.UpdateLimitInfos();
    }

    get organization(): ListTreeOrganization | null {
        return ListTreeModule.currentOrganization;
    }

    @Watch('organization')
    async UpdateLimitInfos() {
        if(!this.organization) {
            return;
        }
        // this.limits = await API.Organization.getLimits(this.organization.Id);
        // if(!this.limits) {
        //     return false;
        // }
        this.canCheckOutAlone = true;// (this.limits.filter(l => l.LimitType != SubscriptionLimitType.User && !l.IsInRecycleBin && l.Exceeded).length == 0);
    }

    openCalendly() {
        Calendly.initPopupWidget({ url: this.$i18n.t('MyTables_DisabledOrganization_CalendlyUrl').toString() });
    }

}
