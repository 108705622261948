import * as Api from '@/api/Api';
import * as RowShare from '@/models/RowShare';
import RowVM from './rowVM';
import ListVMListener from './listVMListener';
import { UserModule } from '@/store/UserStore';
import i18n from '@/modules/Localization';
import { EventBus } from '@/modules/EventBus';
import { Event, GlobalNotificationEventParams } from "@/models/RowShare";

export default class ListVM {

    /** List **/

    private pList: RowShare.List | null = null;

    public get list(): RowShare.List | null {
        return this.pList;
    }
    public set list(val: RowShare.List | null) {
        this.pList = val;
        this.onListUpdated();
    }
    public onListUpdated() {
        for (let i = 0; i < this.listeners.length; ++i)
            this.listeners[i].onListUpdated(this);
    }

    /** Rows **/

    private pRowVMs: RowVM[] | null = null;

    public get rowVMs(): RowVM[] | null {
        return this.pRowVMs;
    }
    public set rowVMs(rowVMs: RowVM[] | null) {
        this.pRowVMs = rowVMs;
        this.onRowVMsUpdated();
    }
    private onRowVMsUpdated() {
        for (let i = 0; i < this.listeners.length; ++i)
            this.listeners[i].onRowVMsUpdated(this);
    }

    public notifyRowVMsUpdating() {
        for (let i = 0; i < this.listeners.length; ++i) {
            this.listeners[i].onBeforeRowVMsUdated(this);
        }
    }

    public notifyRowVMUpdated(rowVM: RowVM) {
        for (let i = 0; i < this.listeners.length; ++i)
            this.listeners[i].onRowVMUpdated(rowVM);
    }

    public refreshCell(rowVM: RowVM): void {
        for (let i = 0; i < this.listeners.length; ++i)
            this.listeners[i].refreshCell(rowVM);
    }

    /** Columns **/

    private pColumns: RowShare.Column[] | null = null;

    public get columns(): RowShare.Column[] | null {
        return this.pColumns;
    }
    public set columns(columns: RowShare.Column[] | null) {
        this.pColumns = columns;
        this.onColumnsUpdated();
    }
    private onColumnsUpdated() {
        for (let i = 0; i < this.listeners.length; ++i)
            this.listeners[i].onColumnsUpdated(this);
    }

    /** Updating data **/

    public async refreshRowsFromServer(onlyIndexAndVersion: boolean = false): Promise<void> {
        if (!this.list || !this.rowVMs)
            return;

        const rows = await Api.Row.loadForList(this.list.Id);
        if (!rows || rows.length <= 0)
            return;

        let currentRows: RowVM[] = [];
        for (let i = 0; i < this.rowVMs.length; ++i) {
            let gridRow = this.rowVMs[i];
            if (gridRow) {
                let serverRow = rows.find(row => row.Id == gridRow.rsRow.Id);
                if (serverRow) {
                    currentRows.push(gridRow);
                    let rowData = rows.splice(rows.indexOf(serverRow), 1)[0];
                    if (onlyIndexAndVersion) {
                        await gridRow.refreshIndexAndVersion(rowData);
                    }
                    else {
                        await gridRow.refreshValues(rowData);
                    }
                }
            }
        }

        // remaining rows are new rows
        if (rows.length > 0) {
            let newRows = rows.map(r => new RowVM(this, r));
            if (newRows && newRows.length > 0) {
                currentRows.push(...newRows);
                currentRows.sort((x, y) => x.rsRow?.Index - y.rsRow?.Index)
                this.rowVMs = currentRows;
            }
        }
    }

    /** Listeners **/

    private listeners: ListVMListener[] = [];

    public registerListener(listener: ListVMListener) {
        this.listeners.push(listener);

        // this is required to handle displayModeVMs created after the initial load 
        listener.onListUpdated(this);
        listener.onRowVMsUpdated(this);
        listener.onColumnsUpdated(this);
    }
    public unregisterListener(listener: ListVMListener) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    /** Capabilities check **/

    public get canAddNewRow(): boolean {
        if (!this.rowVMs)
            return false;

        if (!this.list?.CanCreate)
            return false;

        if (!this.list.Owned && this.list.OneRowMaximumPerUser) {
            if (!this.rowVMs)
                return false;

            if (this.rowAssignedToCurrentUserCount != 0)
                return false;
        }

        return true;
    }

    public get canAddSeveralNewRows() {
        if (!this.canAddNewRow)
            return false;

        if (!this.list?.Owned && this.list?.OneRowMaximumPerUser)
            return false;

        return true;
    }

    public get hasUniqueColumns() {
        return this.list?.HasUniqueColumn;
    }

    public get hasReadOnlyColumns() {
        return this.list?.HasUserReadOnlyColumn;
    }

    public get hasHiddenColumns() {
        return this.list?.HasUserHiddenColumn;
    }

    public msTeamsMode: boolean = false;

    public displayedRows: RowShare.DisplayedRows = RowShare.DisplayedRows.Active;

    public get specialRowsDisplayed(): boolean {
        return this.displayedRows != RowShare.DisplayedRows.Active;
    }

    public get deletedRowsDisplayed(): boolean {
        return this.displayedRows == RowShare.DisplayedRows.Deleted;
    }

    public get archivedRowsDisplayed(): boolean {
        return this.displayedRows == RowShare.DisplayedRows.Archived;
    }

    public get activeRowsDisplayed(): boolean {
        return this.displayedRows == RowShare.DisplayedRows.Active;
    }

    /** Counters **/

    public rowAssignedToCurrentUserCount: number = -1;

    public refreshRowAssignedToCurrentUserCount() {
        if (this.rowVMs === null) {
            this.rowAssignedToCurrentUserCount = -1;
            return;
        }

        let count = 0;
        for (let i = 0; i < this.rowVMs.length; ++i) {
            let vm = this.rowVMs[i];

            let isOwned: boolean;
            if (vm.isNew)
                isOwned = true;
            else
                isOwned = vm.rsRow.Owned;

            if (isOwned)
                count++;
        }

        this.rowAssignedToCurrentUserCount = count;
    }

    /** Check Limits **/
    public checkLimits() {
        if (!this.pList) {
            return;
        }
        let errorRemainingStorage: boolean = false;
        let errorRemainingRows: boolean = false;
        let warnRemainingRows: boolean = false;

        let listSizeInMb = (this.pList.ListSizeInBytes / 1024 / 1024);
        let listMaxSizeInMb = (this.pList.MaxSizeInBytes / 1024 / 1024)
        let storageQuotaUsed = (this.pList.ListSizeInBytes / this.pList.MaxSizeInBytes) * 100;

        if (this.pList.RemainingRowCount <= 0) {
            errorRemainingRows = true;
            warnRemainingRows = false;
        }
        else if (this.pList.RemainingRowCount <= 100) {
            warnRemainingRows = true;
            errorRemainingRows = false;
        }
        if ((listMaxSizeInMb <= 100 && storageQuotaUsed >= 90) || (listMaxSizeInMb - listSizeInMb <= 100 && listMaxSizeInMb > 100)) {
            errorRemainingStorage = true;
        }

        if (errorRemainingRows || warnRemainingRows) {
            let eventRows = <GlobalNotificationEventParams>{
                title: i18n.t('LimitAlertDialog_Title').toString(),
                message: (i18n.t('LimitAlertModal_RemainingRowsPattern', { rowCount: this.pList?.RowCount, maxRowCount: this.pList?.MaxRowCount }).toString()),
                type: errorRemainingRows ? 'error' : 'warning',
                duplicateId: 'remainingRowsNotification',
                autoHideAfterMs: 10000,
                autoHide: true
            }
            EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, eventRows);
        }
        if (errorRemainingStorage) {
            let eventStorage = <GlobalNotificationEventParams>{
                title: i18n.t('LimitAlertDialog_Title').toString(),
                message: (i18n.t('LimitAlertModal_RemainingStoragePattern', { listSize: listSizeInMb.toFixed(2), listMaxSize: listMaxSizeInMb }).toString()),
                type: 'error',
                duplicateId: 'remainingStorageNotification',
                autoHideAfterMs: 10000,
                autoHide: true
            }
            EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, eventStorage);
        }
    }
}