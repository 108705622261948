import { Module, VuexModule, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators'
import * as API from '@/api/Api';
import * as Models from "@/models/RowShare";
import store from '@/modules/Store';
import { Catalog } from '@/api/Api';

@Module({ dynamic: true, store: store, name: 'CatalogStore' })
export class CatalogStore extends VuexModule {
    catalog = new Array<Models.CatalogItem>();
    categories = new Array<Models.CatalogCategory>();

    get isCatalogEmpty(): boolean {
        return this.catalog == null || this.catalog.length == 0;
    }

    get isCategoryListEmpty(): boolean {
        return this.categories == null || this.categories.length == 0;
    }

    @MutationAction({ mutate: ['catalog', 'categories'] })
    async fetchCatalogData(): Promise<any> {
        var catalogItemList = await API.Catalog.load({ clearCache: true });

        const categoryList: Array<Models.CatalogCategory> = [];
        if (catalogItemList != null) {
            for (var i = 0; i < catalogItemList.length; ++i) {
                const list = catalogItemList[i];
                let itemIsNotInTheListYet: boolean =
                    categoryList.find(i => i.Id == list.Category.Id) === undefined;
                if (itemIsNotInTheListYet) {
                    categoryList.push(list.Category);
                }
            }
        }

        return {catalog: catalogItemList, categories: categoryList};
    }
    
    @Action
    async getTableTemplateInfoByListId(listId: string): Promise<Models.TableTemplate> {
        
        let inCatalog = this.isListInCatalog(listId);
        
        if (inCatalog[0]) {
            // retrieve the list from local store
            let storeCatalogTemplate = inCatalog[1];

            //// This situation should never happen
            if (storeCatalogTemplate == null || storeCatalogTemplate === undefined) {
                throw new Error('Could not retrieve list from local Vuex store');
            }

            let result = new Models.TableTemplate();
            result.ListId = listId;
            result.Title = storeCatalogTemplate.DisplayName;
            result.Description = storeCatalogTemplate.LongDescription;
            result.InCatalog = true;

            return result;
        }
        else {
            // Query list from API if is not in the store
            let result = new Models.TableTemplate();
            await API.Catalog
                .getTableTemplateInfoById(listId, { clearCache: true })
                .then(data => {
                    const tableTemplateInfo: Models.TableTemplate | null = data;
                    result = tableTemplateInfo;
                });
            return result;
        }
    }

    // Check if list is in Catalog and return it if it is
    @Action
    isListInCatalog(listId : string) : [boolean, Models.CatalogItem | null | undefined] {
        let findStatus = this.catalog.find(l => l.ListId == listId);
        let inCatalogStatus = !this.isCatalogEmpty && findStatus !== undefined;
        let currentItem = inCatalogStatus ? findStatus : null;

        return [inCatalogStatus, currentItem];
    }
}

export const CatalogModule = getModule(CatalogStore)
