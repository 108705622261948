
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Action, namespace } from 'vuex-class';
    import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
    import { ListThumbnailInfo } from '@/models/ListTree';
    import { ListTreeModule } from '@/store/ListTreeStore'

    @Component({
        name:'DeleteList',
        components: { ConfirmationDialog }
    })
    export default class DeleteList extends Vue{
        @Prop() listThumbnail!:ListThumbnailInfo;
        @Prop() folderId!:string;
        @Prop() organizationId!:string;

        private deleteModalId = 'delete-list';
        private deleteListServerErrorMessage = '';
        private openModalDelete:boolean = false;

        openModalToDelete(){
            setTimeout(()=> this.openModalDelete=true);
        }

        deleteListConfirmed(){
            try{
                ListTreeModule.deleteList({listId: this.listThumbnail.Id, organizationId:  this.organizationId}); 
                this.openModalDelete=false;
            }
            catch(error){
                this.deleteListServerErrorMessage = this.$i18n.t('MyTables_Exception_DeleteList').toString();
            }
        }

        cancelled(){
            this.openModalDelete = false;
        }
     
    }
