import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { BigramHelper } from "@/utils/BigramHelper";
import RowVM from "@/viewModels/Table/rowVM";
import ColumnVM from "@/viewModels/Table/columnVM";

export default class RowOwnerHeaderRenderer implements ICellRendererComp {
    cellContainer!: HTMLElement;
    params!: ICellRendererParams;

    init(params: ICellRendererParams) {
        this.params = params;

        this.cellContainer = document.createElement("div");
        this.cellContainer.classList.add("bigram-renderer");
        
        const bigramElement = this.createBigramElement();
        if(!bigramElement)
            return;

        this.cellContainer.appendChild(bigramElement);
    }

    createBigramElement(){
        const rowVM = <RowVM>this.params.node.data;
        const colVM = <ColumnVM>this.params.colDef;
        const assignation = rowVM?.getAssignation();
        
        if(!colVM || !rowVM || !assignation)
            return null;

        const bigramElement = document.createElement("div");

        // the grid does not show row owners bigrams        
        if(!rowVM.listVM.list?.ShowOwnerInformations || rowVM.isNew)
            return;

        var classes = assignation.rowOwner
            ? BigramHelper.getBigramClassesForRowOwner(assignation.rowOwner, true)
            : BigramHelper.getBigramClassesForSecurityPrincipal(assignation.securityPrincipal, true);

        bigramElement.classList.add(...classes);
        bigramElement.title = assignation.rowOwner
            ? BigramHelper.getBigramTooltipForRowOwner(assignation.rowOwner)
            : BigramHelper.getBigramTooltipForSecurityPrincipal(assignation.securityPrincipal);

        if(classes.includes("rs-bigram-unknown"))
            bigramElement.innerHTML = "<i class='v-icon fal fa-user'></i><i class='v-icon fal fa-question'></i>";
        else if(classes.includes("rs-bigram-survey"))
            bigramElement.innerHTML = "<i class='v-icon notranslate fal fa-user'></i>";
        else
            bigramElement.innerText = assignation.rowOwner ? assignation.rowOwner.Bigram : assignation.securityPrincipal?.Bigram;
        
        return bigramElement;
    }
    
    getGui(): HTMLElement{
        return this.cellContainer;
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        this.cellContainer.innerHTML = '';

        const bigramElement = this.createBigramElement();
        if(bigramElement)
            this.cellContainer.appendChild(bigramElement);
        return true;
    }

    destroy() {
    }
}