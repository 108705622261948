
    import { Component, Prop, Watch } from "vue-property-decorator";
    import { ValidationProvider} from "vee-validate";
    import { ValidationObserver } from 'vee-validate';
    import InputText from "../components/FormInputs/InputText.vue";
    import { FormValidation } from "@/views/components/FormValidation";

    @Component({
        name: 'SingleFormDialog',
        components: { ValidationProvider, InputText, ValidationObserver }
    })
    export default class SingleFormDialog extends FormValidation {

        @Prop() dialogState!:boolean;
        @Prop() title!:string | null;
        @Prop() cancelButtonText!: string | null;
        @Prop() actionButtonText!: string | null;
        @Prop() description!:string;
        @Prop() errorMessage!: string | null;
        @Prop({ default:'md' }) size!: string | null;
        @Prop({ default:'' }) inputPlaceholder!: string | null;
        @Prop({ default:'' }) inputName!: string | null;
        @Prop({ default:'' }) externalError!: string;
        @Prop({ default:'required' }) validationRules!: string;
        @Prop({ default:'' }) formModel!: string;
        @Prop({ default:'check' }) actionButtonIcon!: string;

        private model!: string;

        $refs!: {
            observer: InstanceType<typeof ValidationObserver>; 
        };

        @Watch('dialogState')
        async cleanInput(){
            if(this.dialogState){
                this.model= this.formModel;
                this.$nextTick(() => {
                    //This reset is to avoid validation when opening several consecutive modals 
                    this.$refs.observer.reset();
                });  
            }
        }

        async onSubmit(){
            let formValidState = await this.$refs.observer.validate();
            this.$emit('submit', this.model);  
        }

        //When cancelling, if the values of "model" are different, it means that the user wrote sth.
        //If the user opens the modal again, he/she will see the wrong value, so before closing the modal on cancelling,
        //the model of the modal is returned to its original value
        onCancel(){
            if(this.model!=this.formModel){
                this.model=this.formModel;
            }
            this.$emit('cancel');
        }
    }
