
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as API from '@/api/Api';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import {AgGridVue} from "ag-grid-vue";
import * as agGrid from 'ag-grid-community';
import * as RowShare from "@/models/RowShare";
import { EventBus } from "@/modules/EventBus";
import { Utils } from '@/utils/Utilities';
import { OnPremModule } from '@/store/OnPremStore';
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';
import { GridContextMenu } from '@/viewModels/Table/gridContextMenu';
import { columnContextMenu } from '@/viewModels/Table/columnContextMenu';
import RsColumnManager from '@/columnProxies/RsColumnManager';
import BlobEditor from '@/views/Table/cellsComponents/Blob/BlobEditor.vue';
import CommentEditor from '@/views/Table/cellsComponents/Comment/CommentEditor.vue';
import DateTimeEditor from '@/views/Table/cellsComponents/DateTime/DateTimeEditor.vue';
import HyperlinkEditor from "@/views/Table/cellsComponents/Hyperlink/HyperlinkEditor.vue";
import LookupEditor from '@/views/Table/cellsComponents/Lookup/LookupEditor.vue';
import NumberEditor from '@/views/Table/cellsComponents/Number/NumberEditor.vue';
import RelationEditor from '@/views/Table/cellsComponents/Relation/RelationEditor.vue';
import RichTextEditor from "@/views/Table/cellsComponents/RichText/RichTextEditor.vue";
import RowOwnerHeaderEditor from '@/views/Table/cellsComponents/RowOwner/RowOwnerHeaderEditor.vue';
import TextEditor from '@/views/Table/cellsComponents/Text/TextEditor.vue';
import TimeEditor from '@/views/Table/cellsComponents/DateTime/TimeEditor.vue';
import TwoStatesBooleanEditor from "@/views/Table/cellsComponents/TwoStateBoolean/TwoStatesBooleanEditor.vue";
import columnGroupHeader from '@/views/Table/GridView/columnGroupHeader.vue';
import columnHeader from '@/views/Table/GridView/columnHeader.vue';
import GridViewVM from '@/viewModels/Table/gridViewVM';
import emptyStateOverlay from './emptyStateOverlay.vue';
import ChartsToolPanel from '@/views/Table/ToolPanel/ChartsToolPanel.vue';
import AnalysisToolPanel from '@/views/Table/ToolPanel/AnalysisToolPanel.vue';
import HistoryToolPanel from "@/views/Table/ToolPanel/HistoryToolPanel.vue";
import {RealTimeCollaborationModule, RealTimeCollaborationStore} from "@/store/RealTimeCollaborationStore";
import {RealTimeCollaborationEventType} from "@/models/RowShare";
import {UserModule} from "@/store/UserStore";


@Component({
    name: 'RowshareGrid',
    components: { PageLoadSpinner, AgGridVue,
        ChartsToolPanel, AnalysisToolPanel, columnHeader, columnGroupHeader, emptyStateOverlay, HistoryToolPanel,
        'AutoNumberEditor': TextEditor,
        'CommentEditor': CommentEditor,
        'CreatedByUserEmailEditor': TextEditor,
        'CreatedByUserNameEditor': TextEditor,
        'DateEditor': DateTimeEditor,
        'DateTimeEditor': DateTimeEditor,
        'DecimalNumberEditor': NumberEditor,
        'EmailEditor': TextEditor,
        'FileEditor': BlobEditor,
        'HyperlinkEditor': HyperlinkEditor,
        'ImageEditor': BlobEditor,
        'LastWriteUserEmailEditor': TextEditor,
        'LastWriteUserNameEditor': TextEditor,
        'LookUpEditor': LookupEditor,
        'LookUpListEditor': LookupEditor,
        'MemberManagerEmailEditor': TextEditor,
        'MemberManagerNameEditor': TextEditor,
        'NumberEditor': NumberEditor,
        'OwnerEmailEditor': TextEditor,
        'OwnerNameEditor': TextEditor,
        'PercentageEditor': NumberEditor,
        'ReminderEditor': DateTimeEditor,
        'RichTextEditor': RichTextEditor,
        'TextEditor': TextEditor,
        'TimeEditor': TimeEditor,
        'TwoStatesBooleanEditor': TwoStatesBooleanEditor,
        // technical cells
        'RowOwnerHeaderEditor': RowOwnerHeaderEditor,
        'RelationEditor': RelationEditor
    }
})
export default class RowshareGrid extends Vue{
    @Prop() list!: RowShare.List;
    @Prop() gridViewVM!: GridViewVM;
    @Prop({default: false}) printMode!: boolean;
    @Prop({default: false}) pivotMode!: boolean;
    @Prop() highlightRows!: string | string[];

    autoGroupColumnSettings: agGrid.ColDef = {
        resizable: true, enableRowGroup: false, sortable: true, pinned: 'left', lockPosition: true,
        cellClass: 'technical-left-col', cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            innerRenderer: 'GroupRowRenderer',
            footerValueGetter: (params: any) => this.getGroupFooterValue(params)
        },
        width: 250
    }

    gridOptions: agGrid.GridOptions = {
        stopEditingWhenCellsLoseFocus: true,
        components: Object.assign(RsColumnManager.renderers, RsColumnManager.simpleRenderers),
        onColumnResized: this.onColumnResized,
        getContextMenuItems: GridContextMenu.getContextMenuItems,
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        suppressClipboardPaste: true,
        headerHeight: 60,
        onCellClicked: this.onCellClicked,
        onCellEditingStarted: this.onCellEditingStarted,
        onCellEditingStopped: this.onCellEditingStopped,
        onFilterChanged: this.onFilterChanged,
        onSortChanged: this.onSortChange,
        onColumnVisible: this.onColumnVisibleChanged,
        getMainMenuItems: columnContextMenu.getMainMenuItems,
        rowDragManaged: true,
        onRowDragEnd: this.onRowDragEnd,
        enableRangeSelection: true,
        processCellForClipboard: ColumnVM.processCellForClipboard,
        cacheQuickFilter: true,
        suppressPropertyNamesCheck: true,
        getRowId: (params: agGrid.GetRowIdParams) => params.data.rowVMId, // cannot use rsRow.Id as it would be undefined for new row until the first api result
        tooltipShowDelay: 800,
        showOpenedGroup: true,
        //groupDefaultExpanded: -1, // Expand all row groups
        autoGroupColumnDef: this.autoGroupColumnSettings,
        onColumnRowGroupChanged: this.onColumnRowGroupChanged,
        onColumnValueChanged: this.onColumnValueChanged,
        groupIncludeFooter: true,
        onToolPanelVisibleChanged: this.onToolPanelVisibleChanged,
        suppressModelUpdateAfterUpdateTransaction: true,
        suppressDragLeaveHidesColumns: true,
        onColumnMoved: this.onColumnMoved,
        onColumnPinned: ColumnVM.onColumnPinned,
        skipHeaderOnAutoSize: true,
        onColumnGroupOpened: this.onColumnGroupOpenedChanged,
        onRangeSelectionChanged: this.onRangeSelectionChanged,
        noRowsOverlayComponent: 'emptyStateOverlay',
        loadingOverlayComponent: 'PageLoadSpinner',
        suppressRowGroupHidesColumns: true,
        onFirstDataRendered: this.onFirstDataRendered,
        onRowGroupOpened: this.onRowGroupOpened,
        isGroupOpenByDefault: this.isGroupOpenByDefault
    };

    isGroupOpenByDefault (params : agGrid.IsGroupOpenByDefaultParams) : boolean {
        return this.gridViewVM.isGroupOpenByDefault(params)
    }

    defaultColumnSettings: agGrid.ColDef  = {
        menuTabs: ['filterMenuTab', 'generalMenuTab'],
        headerClass: ['colHeader'],
        headerComponent: 'columnHeader',
        minWidth: 50,
        filterParams: {buttons: ['reset', 'apply'], closeOnApply: true },
        enableRowGroup: true,
        suppressSpanHeaderHeight: true
    };
    sideBarConfig!: agGrid.SideBarDef;

    getGroupFooterValue(params: any) {
        return this.$i18n.t('Grid_GroupFooterLabel', [params.value]).toString();
    }

    getLocaleText(params: any): string{
        if(params.key && params.key != '') {
            var key = 'Grid_' + params.key;
            if(this.$i18n.te(key)) {
                return this.$i18n.t(key, params.variableValues).toString();
            }
        }
        if(params.defaultValue && params.defaultValue != '') {
            return params.defaultValue;
        }
        return '';
    }

    get isNotOnPrem(){
            return !OnPremModule.isOnPrem;
        }

    created() {
        if(!this.printMode) {
            this.sideBarConfig = {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: this.getLocaleText('columns'),
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressColumnSelectAll: true,
                        },
                    },
                    {
                        id: 'charts',
                        labelDefault: this.$i18n.t('ChartsToolPanel_TabLabel').toString(),
                        labelKey: '',
                        iconKey: 'chart',
                        toolPanel: 'ChartsToolPanel'
                    }
                ],
                hiddenByDefault: true,
                position:'right'
            };
        }
        this.gridOptions.rowClassRules = {
            'grid-row-error' : params => params?.data ? params.data.hasError : false,
            'grid-row-total' : params => params?.data ? params.data.isTotalRow : false,
            'grid-row-readonly' : params => params?.data ? params.data.isReadOnly : false,
            'grid-row-group' : params => params?.node ? params.node.group ?? false : false,
            'grid-row-autoheight' : params => (this.list?.RowsAutoHeight ?? false) && !(params?.data?.isTotalRow ?? false),
            'grid-row-locked' : params => params?.data ? params?.data.isLocked : false,
            'grid-row-editing' : params => params?.data ? params?.data.editionInProgress : false,
            'deleting' : params => params?.data ? params?.data.removed : false,
            'highlight-row' : params => {
                if (!params.data) return false;
                const row = RealTimeCollaborationModule
                    .createdRows
                    .find(rowId => rowId === params?.data?.rsRow?.Id);
                return !!row;

            },
        };

        this.gridOptions.doesExternalFilterPass = (node) => GridViewVM.doesExternalFilterPass(this.gridViewVM, node);
        this.gridOptions.isExternalFilterPresent = () => GridViewVM.isExternalFilterPresent(this.gridViewVM);
        this.gridViewVM.gridApi?.setDomLayout(this.printMode ? 'print' : 'normal');
    }

    onColumnMoved(event: agGrid.ColumnMovedEvent) {
        if(!event.finished || !event.columns || event.columns.length == 0) {
            return;
        }
        let toIndex = -1;
        // toIndex event's property is not always correct so get it with getAllGridColumns grid api
        var columns = event.columnApi.getAllGridColumns();
        if(columns) {
            toIndex = columns.findIndex(c => {
                if(event.columns && event.columns.length > 0) {
                    return c.getId() === event.columns[0].getId()
                }
                return false;
            });
        }
        if(toIndex > -1) {
            this.gridViewVM.moveColumns(event.columns ?? [], toIndex);
        }
    }

    onColumnResized(event: agGrid.ColumnResizedEvent) {
        ColumnVM.onColumnResized(event, this.gridViewVM?.printMode)
    }

    gridReady(params: agGrid.GridReadyEvent){
        this.gridViewVM.gridApi = params.api;
        this.gridViewVM.columnApi = params.columnApi;
        this.gridViewVM.printMode = this.printMode;

        EventBus.$on(RowShare.Event.GRID_SEARCH_CHANGED,  (event: RowShare.GridSearchChangeEventParams) => {
            if(this.gridViewVM.gridApi) {
                this.gridViewVM.gridApi.setQuickFilter(event.searchText ?? '');
                if(event.saveValue) {
                    this.gridViewVM.currentQuickFilter = event.searchText;
                }
            }
        });

        let app = document.getElementById('app');
        if(app) {
            this.gridViewVM.gridApi.setPopupParent(app);
        }
        setTimeout(() => {
            this.gridViewVM.tryRefreshTotalRow();

            var toolPanel = this.gridViewVM.gridApi?.getToolPanelInstance('charts');
            if(toolPanel) {
                var chartToolPanel = <ChartsToolPanel>(<any>toolPanel);
                if(chartToolPanel)
                    chartToolPanel.onDataToChartChanged(this.gridViewVM.listVM);
            }
        }, 0);

        if (this.list?.Owned){ //no analysis panel if not owned

        }

        // Show rtc changes history only if owned list (admin)
        if (this.list.Owned) {
            this.sideBarConfig.toolPanels?.push(
                {
                    id: 'history',
                    labelDefault: this.$i18n.t('Rtc_HistorylPanel_TabLabel').toString(),
                    labelKey: 'history',
                    iconKey: 'history',
                    toolPanel: 'HistoryToolPanel',
                    width: 600,
                    toolPanelParams: {
                        a: ""
                    }
                }
            );
        }

        // Don't show analysis on PROD and only if owned list and not OnPrem
        if ( !this.printMode && this.isNotOnPrem &&
            (document.location.hostname.includes('beta') || document.location.hostname.includes('rowsharedev')) &&
            (this.list && this.list.Owned)) {
            this.sideBarConfig.toolPanels?.push({
                id: 'analysis',
                labelDefault: this.$i18n.t('AnalysisToolPanel_TabLabel').toString(),
                labelKey: '',
                iconKey: '',
                toolPanel: 'AnalysisToolPanel',
                width: 600
            });
            EventBus.$on(RowShare.Event.LISTANALYSIS_SHOWRESULTS, (job: RowShare.Job) => this.onShowAnalysisResults(job))
            setTimeout(() => {
                if(this.list?.Id)
                {
                    API.Job.loadLastAnalysisJob(this.list.Id)
                        .then((result) => {
                            if(result && result.IsValid && result.Value) {
                                var job = result.Value;
                                if(job.Status == RowShare.JobStatus.Finished) {
                                    EventBus.$emit(RowShare.Event.LISTANALYSIS_REFRESH, job);
                                }
                            }
                        });
                }
            }, 0);
        }

        params.api.setSideBar(this.sideBarConfig);
        params.api.setSideBarVisible(true);
        this.gridViewVM.restoreVisibleToolPanel();
        this.gridViewVM.viewManager.onViewDataChanged();
    }

    onFirstDataRendered() {
        if(this.highlightRows) {
            var hRows: string[] = [];
            let moreRows = false;
            if(Utils.isArray(this.highlightRows)) {
                this.highlightRows.forEach(hlr => {
                    if(hlr != 'more') {
                        hRows.push(hlr);
                    }
                    else {
                        moreRows = true;
                    }
                });
            }
            else {
                hRows.push(this.highlightRows);
            }
            if(hRows.length > 0) {
                setTimeout(() => {
                    this.gridViewVM.flashRows(hRows, moreRows);
                }, 0);
            }
        }
    }

    onShowAnalysisResults(job: RowShare.Job) {
        EventBus.$emit(RowShare.Event.LISTANALYSIS_REFRESH, job);
        setTimeout(() => {
            if(this.gridViewVM?.gridApi) {
                this.gridViewVM.gridApi.openToolPanel('analysis');
            }
        }, 0);
    }

    postProcessPopup(params: agGrid.PostProcessPopupParams) {
        if(params.type!=="columnMenu") {
            return;
        }
        var ePopup = params.ePopup;
        var oldTopStr = ePopup.style.top;
        oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf('px'));
        var oldTop = parseInt(oldTopStr);
        var newTop = oldTop + 20;
        ePopup.style.top = newTop + 'px';
    }

    async onCellValueChanged(event: agGrid.CellValueChangedEvent){
        let rowVM = <RowVM>event.data;
        if(!rowVM)
            throw new Error("Missing row for save event");

        (<RowVM>rowVM).cellValueChanged((<ColumnVM>event.colDef).rsColumn, event.newValue, event.oldValue);
    }

    handleCellEditing(event: agGrid.CellEditingStartedEvent | agGrid.CellEditingStoppedEvent, lockType: string) {
        if(event.column && event.rowIndex != undefined) {
            const row = this.gridViewVM.gridApi?.getDisplayedRowAtIndex(event.rowIndex);
            const rowId = row?.data.rsRow.Id;
            const colDef = (<agGrid.Column>event.column).getColDef();
            if(rowId && colDef.colId) {
                RealTimeCollaborationModule.broadcast({
                    "type": lockType,
                    "data": {
                        "rowId": rowId,
                        "columnId": colDef.colId,
                        "backgroundColorIndex" : `${UserModule.CurrentUser.Owner?.BackgroundColorIndex}`
                    }
                });
            }
        }
    }

    onCellEditingStarted(event: agGrid.CellEditingStartedEvent) {
        this.handleCellEditing(event, RowShare.RealTimeCollaborationEventType.RowCellLock);
    }

    onCellEditingStopped(event: agGrid.CellEditingStoppedEvent) {
        this.handleCellEditing(event, RowShare.RealTimeCollaborationEventType.RowCellUnlock);
    }

    async onSelectionChanged(event: agGrid.SelectionChangedEvent) {
        EventBus.$emit(RowShare.Event.ROW_SELECTION_CHANGED, event);
    }

    async toggleMandatoryColumn(column: agGrid.Column) {
        if(!column){
            return;
        }
        let colVM = <ColumnVM>column.getColDef();
        if(colVM) {
            colVM.rsColumn.isMandatory = !colVM.rsColumn.isMandatory;
            colVM.rsColumn.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
            API.Column.save(colVM.rsColumn).then(savedColumn => {
                if(savedColumn && this.gridViewVM.gridApi) {
                    colVM.rsColumn = savedColumn;
                    this.gridViewVM.gridApi.refreshHeader();
                }
            });
        }
    }

    onCellClicked(event: agGrid.CellClickedEvent) {
        let rsColumn = (<ColumnVM>event.colDef)?.rsColumn;
        let mouseEvent = <MouseEvent>event.event;

        if(!rsColumn || !mouseEvent) {
            return;
        }
        if(rsColumn.isEmail && (mouseEvent.ctrlKey || mouseEvent.metaKey) && event.value) {
            let email = '';
            if(rsColumn.isOwnerInfo) {
                if(Utils.validateEmail(event.value.ownerId)) {
                    email = event.value.ownerId;
                }
            }
            else if(Utils.validateEmail(event.value)) {
                email = event.value;
            }
            if (email) {
                window.location.href = `mailto:${email}`;
            }
        }
        else if(rsColumn.isHyperlink && (mouseEvent.ctrlKey || mouseEvent.metaKey)) {
            let link = new RowShare.Hyperlink(event.value);
            if(link.url) {
                if(!link.url.startsWith('http'))
                    window.open('http://' + link.url);
                else
                    window.open(link.url);
            }
        }
    }

    onFilterChanged(event: agGrid.FilterChangedEvent){
        if(this.gridViewVM.displayedRowIds &&
            (this.gridViewVM.gridApi?.isColumnFilterPresent() || this.gridViewVM.gridApi?.isQuickFilterPresent())) {
            this.gridViewVM.clearAllSavedFilters();
        }

        RowShare.ListSettings.saveFilters(this.gridViewVM);
        this.gridViewVM.viewManager.onFilterChanged(event);
        this.gridViewVM.gridApi?.deselectAll();

        if(this.list.ShowTotals && this.gridViewVM.gridApi) {
            const totalRow = event.api.getPinnedBottomRow(0);
            if(totalRow)
                this.gridViewVM.gridApi.refreshCells({rowNodes: [totalRow] });
        }

        this.refreshDOM();

        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this.gridViewVM);

        var toolPanel = this.gridViewVM.gridApi?.getToolPanelInstance('charts');
        if(toolPanel) {
            var chartToolPanel = <ChartsToolPanel>(<any>toolPanel);
            if(chartToolPanel)
                chartToolPanel.onDataToChartChanged(this.gridViewVM.listVM);
        }
    }

    onColumnRowGroupChanged(event: agGrid.ColumnRowGroupChangedEvent) {
        this.gridViewVM.onColumnRowGroupChanged(event);
    }

    onColumnValueChanged(event: agGrid.ColumnValueChangedEvent) {
        this.gridViewVM.onColumnValueChanged(event);
    }

    onSortChange(event: agGrid.SortChangedEvent){
        RowShare.ListSettings.saveSortOrder(this.gridViewVM);
        this.gridViewVM.viewManager.onSortChange(event);
        this.refreshDOM();
    }

    onColumnVisibleChanged(event: agGrid.ColumnVisibleEvent){
        this.gridViewVM.onColumnVisibleChanged(event);
    }

    onColumnGroupOpenedChanged(event: agGrid.ColumnGroupOpenedEvent) {
        this.gridViewVM.onColumnGroupOpenedChanged(event);
    }

    onRowGroupOpened(event: agGrid.RowGroupOpenedEvent) {
        if (!event || !event.node || !event.node.group) {
            return;
        }
        console.log(event)
        this.gridViewVM.onRowGroupOpenedChanged(event);
    }

    onRowDragEnd(event: agGrid.RowDragEndEvent){
        if(event?.node?.rowIndex == undefined || event?.node?.rowIndex == null)
            return;

        let nodeToMove : agGrid.IRowNode | undefined = event.node;
        let refNode = event.node.parent?.allLeafChildren[event.node.rowIndex + 1];

        if(!nodeToMove)
            return;

        if(refNode)
            (<RowVM>nodeToMove.data).moveBefore(refNode.data.rsRow.Id);
        else
            (<RowVM>nodeToMove.data).moveLast();
    }

    onRangeSelectionChanged() {
        EventBus.$emit(RowShare.Event.GRID_SELECTION_CHANGED, this.gridViewVM);
    }

    tabToNextCell(params: agGrid.TabToNextCellParams){
        if(params.nextCellPosition?.column.getColDef().cellEditor) {
            let row = this.gridViewVM.gridApi?.getDisplayedRowAtIndex(params.nextCellPosition.rowIndex);
            let rowId = row?.data.rsRow.Id;
            let colDef = params.nextCellPosition.column.getColDef();
            this.gridOptions.context['tabbedIntoCellEditor'] = rowId + '|' + colDef.colId;
        }

        return params.nextCellPosition
    }

    onRowVMsUpdated() {
        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this.gridViewVM);
    }


    onToolPanelVisibleChanged(event: agGrid.ToolPanelVisibleChangedEvent) {
        RowShare.ListSettings.saveToolPanelsStatus(this.gridViewVM);
    }

    refreshDOM() {
        if(this.gridViewVM.gridApi) {
            this.gridViewVM.gridApi.redrawRows();
        }
    }
}
