import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"

export class Group {
    
    static async loadForParent(organizationId: string | null, options: IRequestOptions | null = null) 
    : Promise<Array<Models.Group> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let lists = await ApiUtils.apiGet("/group/loadforparent/" + ApiUtils.encodeSegment(organizationId || ""), options);
        return lists.objects(Models.Group);
    }

    static save(group: Models.Group)
    : Promise<Models.Group | null> {
        return ApiUtils.apiPostJson('/group/save/', group).then(response => response.object(Models.Group));
    }

    static remove(group: Models.Group)
    : Promise<boolean | null> {
        return ApiUtils.apiPostJson('/group/delete/', group).then(response => response.json<boolean>());
    }

    
}