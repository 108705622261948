import { Module, VuexModule, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators'
import { CommandEvent } from '@/models/RowShare';
import { Event } from '@/models/Event';
import { Location as RouterLocation, Route } from "vue-router";
import store from '@/modules/Store';
import { TrackingUtils } from '@/utils/Tracking';

@Module({ dynamic: true, store: store, name: 'ApplicationStore' })
export class ApplicationStore extends VuexModule {
    isInMobileApp = false;
    passwordRegex: string | null = '';
    loginPasswordRegex: string | null = '^.{6,64}$'; //to allow ood passwords
    mobileAppCommands = new Array<CommandEvent>();
    mobileAppPreviousLocation: RouterLocation | string = '';
    isMsTeamsApp: boolean = false;
    appInsightsKey: string = '';

    get getAppInsightsKey(): string {
        return this.appInsightsKey;
    }

    get getIsMobileStatus(): boolean {
        return this.isInMobileApp;
    }

    get getIsMsTeamsApp(): boolean {
        return this.isMsTeamsApp;
    }

    get getPasswordRegex(): string | null {
        return this.passwordRegex;
    }

    @Mutation
    updateAppInsightsKey(appInsightsKey: string) {
        this.appInsightsKey = appInsightsKey;
    }
    @Mutation
    updateDevice(isInMobileApp: boolean) {
        this.isInMobileApp = isInMobileApp;
    }

    @Mutation
    updatePasswordRegex(regex: string | null){
        this.passwordRegex = regex;
    }

    @Mutation
    updateIsMsTeamsApp(isMsTeamsApp: boolean) {
        this.isMsTeamsApp = isMsTeamsApp;
        TrackingUtils.UpdateIsMsTeamsAppDataLayer(true);
    }

    @Mutation
    updateAppCommands(commands: Array<CommandEvent>): void {
        this.mobileAppCommands = commands;
    }

    @Mutation
    updateAppPreviousLocation(location: RouterLocation | string): void {
        this.mobileAppPreviousLocation = location;
    }

    @Mutation
    toggleDescriptionDisplay(): void {
        let descriptionCommand = this.mobileAppCommands.find(c => c.eventName == Event.SHOW_DESCRIPTION_DIALOG);
        if(descriptionCommand){
            let currentState = descriptionCommand.isActive;
            descriptionCommand.isActive = !currentState;
        }
    }

    @Mutation
    toggleRecentDisplay(): void {
        let recentCommand = this.mobileAppCommands.find(c => c.eventName == Event.TOGGLE_RECENT);
        if(recentCommand){
            let currentState = recentCommand.isActive;
            recentCommand.isActive = !currentState;
        }
    }

    @Mutation
    toggleFavouriteDisplay(): void {
        let favouriteCommand = this.mobileAppCommands
                                    .find(c => c.eventName == Event.TOGGLE_FAVOURITE);
        if(favouriteCommand){
            let currentState = favouriteCommand.isActive;
            favouriteCommand.isActive = !currentState;
        }
    }
}

export const ApplicationModule = getModule(ApplicationStore)