
import { Component, Prop, Vue } from "vue-property-decorator";
import * as RowShare from "@/models/RowShare";
import Bigram from "@/views/components/Bigram.vue";
import { ListModule } from "@/store/ListStore";
import { OnPremModule } from '@/store/OnPremStore';
import { EventBus } from "@/modules/EventBus";
import ListDisplayModeVM from "@/viewModels/Table/listDisplayModeVM";
import ListVM from "@/viewModels/Table/listVM";
import RowVM from "@/viewModels/Table/rowVM";
import { UserModule } from "@/store/UserStore";
import ColorPicker from "@/views/components/ColorPicker.vue";
import ListAnalysisDialog from "@/views/Table/ListActions/ListAnalysisDialog.vue";
import { Job } from "@/api/Job";
import { JobStatus } from "@/models/RowShare";

@Component({
    name: "SharingBar",
    components: { Bigram, ColorPicker, ListAnalysisDialog }
})
export default class SharingBar extends Vue {
    @Prop() listVM!: ListVM;
    @Prop() displayModeVM!: ListDisplayModeVM;

    totalRowsCount: number | null = null;
    visibleRowsCount: number | null = null;
    totalColumnsCount: number | null = null;
    visibleColumnsCount: number | null = null;
    rowGroupsCount: number | null = null;
    rowGroupsColNames: string[] = [];
    isFiltered: boolean = false;
    cellSelected: boolean = false;
    selectedColor: string = "";
    groupMenuOpened: boolean = false;
    selectedGroupColumnIds: string[] = [];
    obsoleteFilters: boolean = false;
    currentAnalysis: RowShare.Job | null = null;


    created() {        
        EventBus.$on(RowShare.Event.GRID_INFOS_CHANGED, (displayModeVM: ListDisplayModeVM) => this.onGridInfosChanged(displayModeVM));
        EventBus.$on(RowShare.Event.GRID_SELECTION_CHANGED, (displayModeVM: ListDisplayModeVM) => this.onGridSelectionChanged(displayModeVM));
        EventBus.$on(RowShare.Event.ROW_MODIFIED, (rowVM: RowVM) => this.obsoleteFilters = this.isFiltered);
        EventBus.$on(RowShare.Event.LISTACCESSES_CHANGED, this.onListAccessesChanged);
    }

    beforeMount() {
        this.onListAccessesChanged();
        this.onGridInfosChanged(this.displayModeVM);
        if(this.listVM.list) {
            this.currentAnalysis = null;
            Job.loadLastAnalysisJob(this.listVM.list.Id)
                .then((result) => {
                    if(result && result.IsValid && result.Value) {
                        if(result.Value.Status !== JobStatus.Finished) {
                            this.currentAnalysis = new RowShare.Job();
                            Object.assign(this.currentAnalysis, result.Value);
                            this.currentAnalysis.updateStatusAsync(5000);
                        }
                    }
                });
        }
    }
    
    beforeDestroy(){
        EventBus.$off([
            RowShare.Event.GRID_INFOS_CHANGED, 
            RowShare.Event.ROW_MODIFIED,
            RowShare.Event.LISTACCESSES_CHANGED,
            RowShare.Event.GRID_SELECTION_CHANGED
        ]);
        if(this.currentAnalysis) {
            RowShare.Job.clearAllJobUpdateTimeout();
        }
    }

    onGridInfosChanged(displayModeVM: ListDisplayModeVM) {
        this.refreshIndicators(displayModeVM);
    }

    onGridSelectionChanged(displayModeVM: ListDisplayModeVM) {
        this.cellSelected = displayModeVM.cellSelected;
    }

    onColorSelected() {
        if(this.selectedColor == undefined) {
            return;
        }

        EventBus.$emit(RowShare.Event.GRID_CHOOSE_CELL_COLOR, this.selectedColor);
    }

    closeGroupMenu() {
        if(this.groupMenuOpened) {
            this.groupMenuOpened = false;
        }
    }

    onListAnalysisChipClicked() {
        if(this.currentAnalysis?.Status === RowShare.JobStatus.AnalysisDone) {
            this.currentAnalysis.Status = RowShare.JobStatus.Finished;
            Job.save(this.currentAnalysis)
                .then((result) => {
                    if(result && result.IsValid) {
                        EventBus.$emit(RowShare.Event.LISTANALYSIS_SHOWRESULTS, result.Value);
                    }
                });
        }
        else {
            if(this.listVM?.list && (!this.currentAnalysis || this.currentAnalysis.Status === RowShare.JobStatus.Finished 
                                        || this.currentAnalysis.Status === RowShare.JobStatus.Error)) {
                EventBus.$emit(RowShare.Event.LISTANALYSIS_REQUEST, <RowShare.listAnalysisRequestEventParams>{listId: this.listVM.list.Id});
            }
        }
    }

    onAnalysisRequested(job: RowShare.Job) {
        if(job) {
            RowShare.Job.clearAllJobUpdateTimeout();
            this.currentAnalysis = new RowShare.Job();
            Object.assign(this.currentAnalysis, job);            
            this.currentAnalysis.updateStatusAsync(5000);
        }
    }

    get canRequestAnalysis() {
        // Don't show analysis on PROD or OnPrem
        if(!document.location.hostname.includes('beta') && !document.location.hostname.includes('rowsharedev')) {
            return false;
        }
        if (this.isOnPrem)
            return false;

        return this.listVM.list?.Owned ?? false;
    }

    get isOnPrem(){
        return OnPremModule.isOnPrem;;
    }

    get analysisIcon(): string {
        var res: string = "fal fa-robot";
        if(this.currentAnalysis && this.currentAnalysis.Status !== JobStatus.Finished) {
            res += " fa-fade";
        }
        return res;
    }

    get analysisChipColor(): string {
        if(!this.currentAnalysis) {
            return "primary";
        }
        switch(this.currentAnalysis.Status) {
            case JobStatus.Error: {
                return "error";
            }
            case JobStatus.AnalysisDone: {
                return "success";
            }
            default: {
                return "primary";
            }
        }
    }

    get analysisLabel(): string {
        if(this.currentAnalysis != null && this.currentAnalysis.Status != RowShare.JobStatus.Finished) {
            return RowShare.Job.getStatusString(this.currentAnalysis.Status);
        }
        return this.$i18n.t("SharingBar_RunListAnalysisBtnLabel").toString();
    }

    get canChooseColor() {
        return (this.cellSelected && !this.specialRowsDisplayed && this.isLoggedIn);
    }

    get isLoggedIn() {
        var user = UserModule.CurrentUser;
        return user != null && !user.IsAnonymous;
    }

    get currentListColumnsForRowGrouping() {
        return this.listVM.columns?.filter((c) => c.isRowGroupingEnabled);
    }

    get specialRowsDisplayed(): boolean {
        return this.listVM.specialRowsDisplayed;
    }

    get currentList(): RowShare.List | null {
        return ListModule.currentList;
    }

    get externalAccessAllowed(): boolean {
        if(!ListModule.currentList) {
            return false;
        }
        return ListModule.currentList.AllowPublic;
    }

    get currentAccesses(): RowShare.ListAccess[] | null {
        return ListModule.currentAccesses;
    }

    get listUsersAccesses() {
        if(!this.currentAccesses) {
            return null
        }
        return this.currentAccesses.filter(acc => !acc.IsMyOrganization);
    }

    get firstListUsersAccesses() {
        if(!this.listUsersAccesses) {
            return null;
        }
        if(this.listUsersAccesses.length <= 6) {
            return this.listUsersAccesses;
        }

        return [this.listUsersAccesses[0], this.listUsersAccesses[1], this.listUsersAccesses[2], this.listUsersAccesses[3], this.listUsersAccesses[4]];
    }

    get otherListUsersAccesses() {
        if(!this.listUsersAccesses) {
            return null;
        }

        return this.listUsersAccesses.filter(acc => (this.firstListUsersAccesses?.find(ca => ca.Id == acc.Id) ?? null) == null );
    }

    get otherListUsersAccessesCount() {
        if(!this.otherListUsersAccesses) {
            return 0;
        }
        return this.otherListUsersAccesses.length;
    }

    get hasOtherListUsersAccesses() {
        return this.otherListUsersAccessesCount > 0;
    }

    get hasAdvancedListAccesses() {
        if(!this.currentAccesses) {
            return false;
        }
        return this.currentAccesses.findIndex(acc => acc.isAdvancedAccess) > -1;
    }

    get listMyOrganizationAccess() {
        if(!this.currentAccesses) {
            return null
        }
        return this.currentAccesses.filter(acc => acc.IsMyOrganization);
    }

    get myOrganizationIconPath() {
        if(!this.listMyOrganizationAccess) {
            return "";
        }
        let path = this.listMyOrganizationAccess[0]?.Guest?.IconPath
        if(!path) {
            return "";
        }
        if(!path.startsWith("/")) {
            path = "/" + path + '?_ssb_img=S;w:40;h:40';
        }
        else {
            path = null;
        }
        return path;
    }

    get clearFiltersTooltipText() {
        return this.$i18n.t('SharingBar_ClearFiltersTooltipText').toString()
    }

    get clearHiddenColumnsTooltipText() {
        return this.$i18n.t('SharingBar_ClearHiddenColumnsTooltipText').toString()
    }

    get clearRowGroupsTooltipText() {
        return this.$i18n.t('SharingBar_ClearRowGroups').toString();
    }

    addNewGuests() {
        if(this.listVM.list?.LoadedByReadOnlyMember)
            return;

        EventBus.$emit(RowShare.Event.SHOW_SIDEPANEL_SHARING, <RowShare.showSidePanelSharingEventParams>{listId: this.listVM.list?.Id, addGuest: true,
                                                                                                            advancedMode: this.hasAdvancedListAccesses});
    }

    onListAccessesChanged() {
        if(!this.listVM.list)
            return;

        ListModule.refreshCurrentAccesses(this.listVM.list.Id);
        ListModule.refreshCurrentList(this.listVM.list.Id);
    }

    editSharing() {
        if(this.listVM.list?.LoadedByReadOnlyMember)
            return;
        
        EventBus.$emit(RowShare.Event.SHOW_SIDEPANEL_SHARING, <RowShare.showSidePanelSharingEventParams>{listId: this.listVM.list?.Id, addGuest: false,
                                                                                                            advancedMode: this.hasAdvancedListAccesses});
    }

    get isListShared(): boolean {
        if(this.currentList) {
            return (this.currentList.AllowPublic || ((this.currentAccesses?.length ?? 0) > 0));
        }
        return false;
    }

    get myOrganizationName(): string {
        if(!this.listMyOrganizationAccess){
            return "";
        }
        if(this.listMyOrganizationAccess[0]?.Guest?.Name)
        {
            return this.listMyOrganizationAccess[0].Guest.Name;
        }
        return "";
    }

    get sharedWithMyOrganization(): boolean {
        return (this.listMyOrganizationAccess?.length ?? 0) > 0;
    }

    get rowsCountLabel() {
        if(!this.totalRowsCount) {
            return this.$i18n.t('SharingBar_NoRowsLabel');
        }
        else if(this.totalRowsCount === 1) {
            return this.$i18n.t('SharingBar_OneRowLabel');
        }
        else if(this.totalRowsCount === this.visibleRowsCount) {
            return this.$i18n.t('SharingBar_AllRowsLabel', {totalRowsCount: this.totalRowsCount});
        }
        else {        
            return this.$i18n.t('SharingBar_RowsCountLabel', {visibleRowsCount: this.visibleRowsCount, totalRowsCount: this.totalRowsCount});
        }
    }

    get columnsCountLabel() {
        if(!this.totalColumnsCount) {
            return ""; // Should not occur
        }
        if(this.totalColumnsCount === 1) {
            return this.$i18n.t('SharingBar_OneColumnLabel');
        }
        else if(this.totalColumnsCount === this.visibleColumnsCount) {
            return this.$i18n.t('SharingBar_AllColumnsLabel', {totalColumnsCount: this.totalColumnsCount});
        }
        else {
            return this.$i18n.t('SharingBar_ColumnsCountLabel', {visibleColumnsCount: this.visibleColumnsCount, totalColumnsCount: this.totalColumnsCount});
        }
    }

    get rowGroupsLabel() {
        if(!this.rowGroupsCount || this.rowGroupsCount == 0) {
            return this.$i18n.t('SharingBar_NoRowGroupLabel');
        }
        if(this.rowGroupsCount === 1) {
            return this.$i18n.t('SharingBar_OneRowGroupLabel', this.rowGroupsColNames);
        }
        else {
            return this.$i18n.t('SharingBar_RowGroupsCountLabel', [this.rowGroupsCount]);
        }
    }

    get rowGroupsTooltipText() {
        if(!this.rowGroupsCount) {
            return "";
        }
        return this.$i18n.t('SharingBar_RowGroupsTooltipText', [this.rowGroupsColNames.join(', ')]);
    }

    get canClearRowsFilter() {
        return (this.visibleRowsCount ?? 0) < (this.totalRowsCount ?? 0);
    }

    get canClearHiddenColumns() {
        return (this.visibleColumnsCount ?? 0) < (this.totalColumnsCount ?? 0);        
    }

    get canClearRowGroups() {
        return (this.rowGroupsCount ?? 0) > 0;
    }

    refreshIndicators(displayModeVM: ListDisplayModeVM) {
        this.$nextTick(() => {
            this.totalRowsCount = displayModeVM.totalRowsCount;
            this.visibleRowsCount = displayModeVM.visibleRowsCount;
            this.totalColumnsCount = displayModeVM.totalColumnsCount;
            this.visibleColumnsCount = displayModeVM.visibleColumnsCount;
            this.rowGroupsCount = displayModeVM.rowGroupsCount;
            this.rowGroupsColNames = displayModeVM.rowGroupsColNames;
            this.selectedGroupColumnIds = displayModeVM.getRowGroupColumnsIds() ?? [];
            this.obsoleteFilters = (displayModeVM.displayedRowIds != null);
            this.isFiltered = displayModeVM.isFiltered;
        });
    }

    clearFilters() {
        EventBus.$emit(RowShare.Event.GRID_CLEAR_FILTERS);
    }

    clearRowGroups() {
        EventBus.$emit(RowShare.Event.GRID_CLEAR_ROWGROUPS);
        this.groupMenuOpened = false;
    }

    clearHiddenColumns() {
        EventBus.$emit(RowShare.Event.GRID_CLEAR_HIDDENCOLUMNS);
    }

    restoreFilters() {
        EventBus.$emit(RowShare.Event.RESTORE_FILTERS);
    }

    toggleRowGroupColumnSelected(column: RowShare.Column) {
        if(!column) {
            return;
        }

        if(!this.selectedGroupColumnIds.includes(column.Id)) {
            this.selectedGroupColumnIds.push(column.Id);
            EventBus.$emit(RowShare.Event.GRID_ADD_ROWGROUP, column.Id);
        }
        else {
            var index = this.selectedGroupColumnIds.indexOf(column.Id);
            if(index > -1) {
                this.selectedGroupColumnIds.splice(index, 1);
            }
            EventBus.$emit(RowShare.Event.GRID_REMOVE_ROWGROUP, column.Id);
        }
    }

    isRowGroupColumnSelected(column: RowShare.Column) {
        if(!column) {
            return false;
        }
        return this.selectedGroupColumnIds.includes(column.Id);
    }
}
