
    import { Component, Prop } from "vue-property-decorator";
    import { Location as RouterLocation } from "vue-router";
    import { ListTreeModule } from '@/store/ListTreeStore'
    import * as RowShare from "@/models/RowShare";   
    import { FormValidation } from "@/views/components/FormValidation";
    import CreateFolderAction from '@/views/MyTables/FolderActions/CreateFolder.vue';
    import RenameFolderAction from '@/views/MyTables/FolderActions/RenameFolder.vue';
    import DeleteFolderAction from '@/views/MyTables/FolderActions/DeleteFolder.vue';
    import EditAuthorizationAction from '@/views/MyTables/FolderActions/EditAuthorization.vue';
    import ManageOwnersAction from '@/views/MyTables/FolderActions/ManageOwners.vue';
    import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';

    @Component({ 
        name: 'MyTablesActions',
        components: { 
            CreateFolderAction, 
            RenameFolderAction,
            DeleteFolderAction,
            EditAuthorizationAction,
            ManageOwnersAction,
            ConfirmationDialog }
    })
    export default class MyTablesActions extends FormValidation {

        @Prop() organizationId!: string | null;
        @Prop() folder!: RowShare.ListTreeFolder;
        @Prop() parentFolderId!: string | null;

        private emptyRecycleBinModalId = 'empty-bin';
        private emptyRecycleBinErrorMessage = '';
        private recycleBinModal=false;

        get createTableLocation() : RouterLocation {
            return <RouterLocation>{
                name: "CreateTable",
                query: {
                    organization: this.organizationId,
                    folderId: this.folder ? this.folder.Id : null
                }
            };
        }

        get isRootFolder() : boolean {
            
            let personalOrgRootFolderIds = new Array<string>();
            if (ListTreeModule.listTree){
                ListTreeModule.listTree.Organizations
                    .forEach((organization) => {
                        if(organization.RootFolder)
                            personalOrgRootFolderIds.push(organization.RootFolder.Id);
                });
            }
            if (!this.folder) 
                return false;

            return personalOrgRootFolderIds.includes(this.folder.Id);
        }

        get parentTableLocation() : RouterLocation {
            return <RouterLocation>{
                name: "MyTables",
                params: {
                    organizationId: this.organizationId,
                    folderId: this.parentFolderId ? this.parentFolderId : null
                }
            };
        }

        emptyRecycleBinConfirmed() : void{
            try{
                let infoToEmptyRecycleBin = {
                    recycleBinId:this.folder.Id,
                    organizationId:this.organizationId
                }
                ListTreeModule.emptyRecycleBin(infoToEmptyRecycleBin);
                this.recycleBinModal=false;
                this.$router.push(this.parentTableLocation);
            }
            catch(error: any){
                this.emptyRecycleBinErrorMessage = error.message;
            }
            
        }

        cancelled(){
            this.recycleBinModal = false;
        }
    }
