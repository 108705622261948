
import {Component, Prop, Vue } from 'vue-property-decorator';
import * as API from "@/api/Api";
import * as Models from "@/models/RowShare";
import _ from 'lodash';
import TableOwnerSelector from "@/views/Table/ShareTable/TableOwnerSelector.vue";

@Component({
    name:'TableOwners',
    components: { TableOwnerSelector }
})
export default class TableOwners extends Vue{
    
    @Prop() organizationId!:string
    @Prop() listId!:string;

}
