
import { Vue, Component, Prop } from "vue-property-decorator";
import { UserModule } from '@/store/UserStore';
import { OnPremModule } from '@/store/OnPremStore';
import { FormValidation } from "@/views/components/FormValidation";
import { loadAndSetWithUserLanguageAsync } from "@/modules/Localization";
import { EventBus } from "@/modules/EventBus";
import * as RowShare from "@/models/RowShare";
import { Location as RouterLocation } from "vue-router";
import { ListTreeModule } from "@/store/ListTreeStore";
import { RouterUtils } from "@/modules/Router";
import { ResLoader } from "@/utils/ResLoader";
import { ApplicationModule } from "@/store/ApplicationStore";


@Component
export default class RsErrorHeader extends Vue {

    myAccountListGroupOpened : boolean = false;

    get currentUser () {
        return  UserModule.connectedUser;
    }

    async created() {
        let scriptPromise = this.$loadScript('https://assets.calendly.com/assets/external/widget.js');
        ResLoader.loadCss('https://assets.calendly.com/assets/external/widget.css');
        let res = await Promise.all([scriptPromise]);

    }

    get activeLocale() {
        return this.$i18n.locale;
    }

    get customSmallSiteLogoPath() {
        let url: string = "";
        if(ListTreeModule?.currentSmallSiteLogoPath) {
            url = ListTreeModule.currentSmallSiteLogoPath;
        }
        else if(this.currentOrganization?.SmallSiteLogoPath) {
            url = this.currentOrganization.SmallSiteLogoPath;
        }
        return url;
    }

    get customFullSiteLogoPath() {
        let url: string = "";
        if(ListTreeModule?.currentFullSiteLogoPath) {
            url = ListTreeModule.currentFullSiteLogoPath;
        }
        else if(this.currentOrganization?.FullSiteLogoPath) {
            url = this.currentOrganization.FullSiteLogoPath;
        }
        return url;
    }

    get hasCustomRSLogosForLogin(): boolean {
        return ListTreeModule?.currentFullSiteLogoPath != null && ListTreeModule?.currentSmallSiteLogoPath != null;
    }

    get Languages() {
        return [
            { Name: this.$i18n.t("Footer_English").toString(), Value: "en" },
            { Name: this.$i18n.t("Footer_Spanish").toString(), Value: "es" },
            { Name: this.$i18n.t("Footer_French").toString(), Value: "fr" }
        ];
    }

    get getUser(){
        return UserModule.CurrentUser;
    }

    get isNotOnPrem(){
        return !OnPremModule.isOnPrem;
    }

    get currentOrganization(){
        return ListTreeModule.currentOrganization;
    }

     get organizationsTree(){
        return ListTreeModule.organizationsTree;
    }

    async signout() {
        let returnUrl: string | null = await UserModule.signOut();
        if (returnUrl) {
            if (returnUrl.charAt(0) == "~") returnUrl = returnUrl.substr(1);

            window.location.href = returnUrl;
        }
    }

    get GetHomeUrl() {
        if(UserModule.CurrentUser.IsAnonymous) {
            return this.$route.fullPath;
        }
        return <RouterLocation>{
            name: 'MyTables',
       };
    }

    get signInLink(){
        var returnUrl = RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl");

        if(!returnUrl)
            returnUrl = this.$route.path;

        return <RouterLocation>{
            name: "Login",
            query: {
                ReturnUrl: returnUrl,
            }
        };
    }

    get previousLocation() : RouterLocation | string {
        return ApplicationModule.mobileAppPreviousLocation;
    }

    previous(location: RouterLocation){
        this.$router.push(location);
    }
}
