
    import { Component } from 'vue-property-decorator';
    import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
    import RsColumnManager from '@/columnProxies/RsColumnManager';
    import { CurrencyInputOptions } from 'vue-currency-input';
    import numeral from 'numeral';
    import { Utils } from '@/utils/Utilities';

    @Component({
        name: 'NumberFormEditor'
    })
    export default class NumberFormEditor extends FormEditor {
        currentValue: Number | null = null;
        isPercentage: boolean = false;
        maxValue: number | null = null;
        minValue: number | null = null;

        options : CurrencyInputOptions = {currency: undefined};
        formattedValue: string | null = null;

        onInput(value: any) {
            this.formattedValue = value;
        }

        onChange(value: any) {
            this.formattedValue = value;
        }

        created() {
            if(this.column) {
                this.isPercentage = this.column.isPercentage;
                if(Utils.isNumber(this.column.MinValue)) {
                    this.minValue = this.column.MinValue;
                    if(this.isPercentage) {
                        this.minValue = this.minValue * 100;
                    }
                }
                if(Utils.isNumber(this.column.MaxValue)) {
                    this.maxValue = this.column.MaxValue;
                    if(this.isPercentage) {
                        this.maxValue = this.maxValue * 100;
                    }
                }
            }
            this.options.distractionFree = { hideCurrencySymbol: true, hideGroupingSymbol: true, hideNegligibleDecimalDigits: false}
            if(this.column?.MaxDecimals) {
                this.options.precision = !this.isPercentage ? this.column.MaxDecimals : ((this.column.MaxDecimals - 2) < 0 ? 0 : (this.column?.MaxDecimals - 2));
            }
            else {
                this.options.precision = 0;
            }
            this.$ci.globalOptions = this.options;
        }

        init(){
            const proxy  = RsColumnManager.getProxy(this.column.Type);
            let cellValue = numeral(this.rowVM.getValue(this.column, proxy) ?? null);
            if(cellValue.value() !== null && this.isPercentage) {
                cellValue = cellValue.multiply(100);
            }
            if(cellValue.value() === null)
                this.formattedValue = null;
            this.$ci.setValue(<HTMLInputElement>this.$refs.numberInput, cellValue.value());
        }

        getValue() {
            let value = numeral(this.$ci.getValue(<HTMLInputElement>this.$refs.numberInput));
            if(value.value() === null) {
                return null;
            }
            return this.isPercentage ? value.divide(100).value() : value.value();  
        }
        
        get isReadOnly() {
            return this.column.IsReadOnly || this.column.isGenerated;
        }
    }
