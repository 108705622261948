import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"

export class BoApi {

    static search(searchStr: string, options: IRequestOptions | null = null)
    :Promise<Models.BackOfficeSearchSummary | null> {
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiGet("/admin/search/"+ ApiUtils.encodeSegment(searchStr || "")+ "?v3=true", options)
                       .then(response => response.object(Models.BackOfficeSearchSummary) ?? null);
    }

    static searchLists(searchStr: string, options: IRequestOptions | null = null)
    :Promise<Models.BackOfficeListSummary[] | null> {
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiGet("/admin/list/search/" + ApiUtils.encodeSegment(searchStr || "") + "?v3=true", options)
                       .then(response => response.objects(Models.BackOfficeListSummary) ?? null);
    }

    static searchUsers(searchStr: string, options: IRequestOptions | null = null) : Promise<Models.BackOfficeUser[] | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/admin/user/search/" + ApiUtils.encodeSegment(searchStr || "") + "?v3=true", options)
            .then(response => response.objects(Models.BackOfficeUser));
    }

    static searchOrganizations(searchStr: string, options: IRequestOptions | null = null) : Promise<Models.BackOfficeOrganizationSummary[] | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/admin/organization/search/" + ApiUtils.encodeSegment(searchStr || "") + "?v3=true", options)
            .then(response => response.objects(Models.BackOfficeOrganizationSummary));
    }

    static loadOrganization(id: string, options: IRequestOptions | null = null) : Promise<Models.Organization| null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/admin/organization/load/" + ApiUtils.encodeSegment(id) + "?v3=true", options)
            .then(response => response.object(Models.Organization));
    }    

    static loadMembers(id: string, options: IRequestOptions | null = null) : Promise<Models.BackOfficeMember[]| null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/admin/member/loadforparent/" + ApiUtils.encodeSegment(id) + "?v3=true", options)
            .then(response => response.objects(Models.BackOfficeMember));
    }

    static loadUser(id: string, options: IRequestOptions | null = null) : Promise<Models.BackOfficeUser| null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/admin/user/load/" + ApiUtils.encodeSegment(id) + "?v3=true", options)
            .then(response => response.object(Models.BackOfficeUser));
    }    

    static loadListSummary(searchStr: string, options: IRequestOptions | null = null) : Promise<Models.BackOfficeListSummary | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/admin/list/load/" + ApiUtils.encodeSegment(searchStr || "") + "?v3=true", options)
            .then(response => response.object(Models.BackOfficeListSummary));
    }
    
    static saveUserProperty(email: string, property: string, value: string, options: IRequestOptions | null = null) : Promise<Models.BackOfficeListSummary | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/admin/user/save/" 
                + ApiUtils.encodeSegment(email) + "/" 
                + ApiUtils.encodeSegment(property) + "/" 
                + ApiUtils.encodeSegment(value)
                + "?v3=true", options)
            .then(response => response.object(Models.BackOfficeListSummary));
    }
    
    static saveOrganizationProperty(orgId: string, property: string, value: string, options: IRequestOptions | null = null) : Promise<boolean | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/admin/organization/save/" 
                + ApiUtils.encodeSegment(orgId) + "/" 
                + ApiUtils.encodeSegment(property) + "/" 
                + ApiUtils.encodeSegment(value)
                + "?v3=true", options)
            .then(response => response.json<boolean>());
    }

    static saveOrgLogo(orgId: string, logoType: string, file: Object | FileList | File | Blob | null, options: IRequestOptions | null = null) 
    : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: true}, options);
        return ApiUtils.apiPostJsonWithFiles(`/admin/organization/save/${orgId}`, {logoType},file, options)
            .then(response=> response.json<boolean>())
    }

    static saveMember(member: Models.Member, options: IRequestOptions | null = null) : Promise<boolean | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiPostJson("/admin/member/save/", member, options)
            .then(response => response.json<boolean>());
    }    
    
    static deleteMember(member: Models.Member, options: IRequestOptions | null = null) : Promise<boolean | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiPostJson("/admin/member/delete/", member, options)
            .then(response => response.json<boolean>());
    }
    
    static deleteUser(user: Models.User, options: IRequestOptions | null = null) : Promise<boolean | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiPostJson("/admin/user/delete/", user, options)
            .then(response => response.json<boolean>());
    }

    static deleteOrganization(organization: Models.Organization, options: IRequestOptions | null = null) : Promise<boolean | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiPostJson("/admin/organization/delete/", organization, options)
            .then(response => response.json<boolean>());
    }
    
    static ClearCache(options: IRequestOptions | null = null) : Promise<boolean| null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiGet("/admin/clearcache/", options).then(response => response.json<boolean>());
    }    

    static RestartServer(options: IRequestOptions | null = null) : Promise<boolean| null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiGet("/admin/restartserver/", options).then(response => response.json<boolean>());
    }    

    static ClearCacheAndRestartServer(options: IRequestOptions | null = null) : Promise<boolean| null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiGet("/admin/clearcacheandrestartserver/", options).then(response => response.json<boolean>());
    }    
}