
import {Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name:'Bandeau'
})
export default class Bandeau extends Vue{
    
    @Prop() title!:string;
    @Prop() description!:string;
}
