
import {Vue, Component} from 'vue-property-decorator';
import {IToolPanelParams} from 'ag-grid-community';
import {RtcHistoryItem} from "@/models/RealTimeCollaboration";
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";
import Bigram from "@/views/components/Bigram.vue";
import * as RowShare from "@/models/RowShare";
import {ListTreeModule} from "@/store/ListTreeStore";

@Component({name: 'HistoryToolPanel',
    components: {Bigram}
})
export default class HistoryToolPanel extends Vue {
    params!: IToolPanelParams;

    get rtcHistoryItems(): RtcHistoryItem[] {
        let items = RealTimeCollaborationModule.getHistory
        for (let item of items) {
            if (!item.panelParams)
                item.panelParams = this.params;
        }
        items = items.sort((a, b) => b.date.getTime() - a.date.getTime());
        return items;
    }

    get hasNoHistoryItem(): boolean {
        return this.rtcHistoryItems.length === 0;
    }

    get hasAudit(): boolean {
        return RealTimeCollaborationModule.hasAudit;
    }

    get hasRtcHistory(): boolean {
        return RealTimeCollaborationModule.hasRtcHistory;
    }

    get upsellTitle(): string {
        return this.$i18n.t("Rtc_UpsellTitle").toString();
    }

    get upsellDescription() : string {
        return this.$i18n.t("Rtc_UpsellDescription").toString();
    }

    get currentUrl(): string {
        return this.$route.fullPath;
    }

    get currentOrganizationId(): string {
        return ListTreeModule.currentOrganization?.Id ?? ""
    }

    get currentList(): RowShare.List | null {
        return ListTreeModule.currentList;
    }

    get auditUrl() : string {
        return `/api/audit/export/${this.currentList?.Id}?f=csv`;
    }

    get auditIsEnabled() : boolean {
        return this.currentList?.CanCurrentUserExportAudit ?? false;
    }

    get auditHelpLink() : string {
        return this.$i18n.t('Rtc_AuditHelpLink').toString();
        //return "https://help.rowshare.com/l/fr/article/poyr62nhzy-historique-des-modifications"
    }
}
