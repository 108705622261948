import * as RowShare from "@/models/RowShare";
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';
import { Utils } from "@/utils/Utilities";
import BaseColumnProxy from './BaseColumnProxy';

export default class PercentageColumnProxy extends BaseColumnProxy {
    parseInitialTypedValue(rsColumn: RowShare.Column, rowVM: RowVM): any {
        const val = rowVM.rsRow.Values[rsColumn.Name];
        return val ? Number.parseFloat(val) : val;
    }

    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any): string {
        if(val !== undefined && val !== null && Utils.isNumber(val)) {
            // In javascript 0.14 * 100 = 14.000000000000002 !!!
            return (val * 1000 / 10).toFixed(rsColumn.MaxDecimals >=2 ? rsColumn.MaxDecimals - 2 : 0) + ' %';
        }
        return "";
    }

    getFormattedHtml(rsColumn: RowShare.Column, rowVM: RowVM, val: any): string {
        if(!rsColumn || val === undefined || val === null){
            return "";
        }

        if(val.value !== undefined && val.value !== null) {
            val = val.value;
        }
        
        if (!Utils.isNumber(val)) {
            return "";
        }

        if(!rsColumn.useSmartRenderer) {
            return this.getFormattedText(rsColumn, rowVM, val);
        }

        let minValue = 0;
        let valueRange = 1;

        if (Utils.isNumber(rsColumn.MinValue) && Utils.isNumber(rsColumn.MaxValue)) {
            minValue = rsColumn.MinValue;
            valueRange = rsColumn.MaxValue - rsColumn.MinValue;
        }

        const position = (val - minValue) * 100 / valueRange;

        var cellContent = `
            <div class="percentage-renderer  pb-1 ${position >= 75 ? 'high' : position >= 25 ? 'medium' : 'low'}">
                <div class="percentage-label">
                    <span>${this.getFormattedText(rsColumn, rowVM, val)}</span>
                </div>
                <div class="percentage-progress-bar">
                    <span class="percentage-progress-bar--position" style="width: ${position}%"></span>
                </div>
            </div>
        `;
        return cellContent;
    }

    getEnableAggregate(rsColumn: RowShare.Column): boolean {
        return true;
    }    
}
