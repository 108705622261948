import { ColumnAccess, OwnerType, TeamType } from "@/models/RowShare";
import i18n from "@/modules/Localization";
export class ColumnAccessVM extends ColumnAccess {
    status: ColumnAccessVMStatus = ColumnAccessVMStatus.Unchanged;
    isAdminAccess: boolean;
   
    constructor(access: ColumnAccess) {
        super();
        Object.assign(this, access);
        this.isAdminAccess = false;
    }

    public get isNew(): boolean {
        return this.status === ColumnAccessVMStatus.New;
    }

    public get isModified(): boolean {
        return this.status === ColumnAccessVMStatus.Modified;
    }

    public get isDeleted(): boolean {
        return this.status === ColumnAccessVMStatus.Deleted;
    }

    public get canBeRemoved(): boolean {
        return !this.IsMyOrganization && !this.isAdminAccess;
    }

    public get guestName(): string {
        let guestName = this.Guest?.DisplayName;
        if(!guestName) {
            return "";
        }
        let result = guestName;
        if (this.Guest.Type == OwnerType.Team) {
            if(this.TeamGrant == TeamType.Direct) {
                result = i18n.t("ListAccess_DirectReportsTeamNameFormat", [guestName]).toString();
            }
            else if (this.TeamGrant == TeamType.Extended) {
                result = i18n.t("ListAccess_AllReportsTeamNameFormat", [guestName]).toString();
            }
        }
        return result;
    }

    public get category(): string {
        return (this.isAdminAccess || this.IsMyOrganization) ? "" : i18n.t('SidePanelColumnPermissions_Specific').toString();
    }
}

export enum ColumnAccessVMStatus {
    Unchanged,
    Modified,
    New,
    Deleted,
    Invalid
}