
import {Component, Prop, Vue} from 'vue-property-decorator';
import * as API from '@/api/Api';
import { InviteLink } from '@/models/InviteLink';
import { EventBus } from '@/modules/EventBus';
import { Event, GlobalNotificationEventParams, NotificationType } from '@/models/Event';

@Component({name: 'InviteLinkDialog'})
export default class InviteLinkDialog extends Vue {
    @Prop() organizationId!: string;
    
    showInviteLinkDialog: boolean = false;
    activeInviteLink: InviteLink | null = null;
    creatingInviteLink: boolean = false;
    savingErrorMessage: string = "";
    deletingInviteLink: boolean = false;
    deleteErrorMessage: string = "";
    deletionRequested: boolean = false;

    mounted() {
        this.refresh();
    }

    refresh() {
        API.InviteLink.loadForParent(this.organizationId)
                      .then((links) => {
                          if(links && links.length > 0) {
                              this.activeInviteLink = links[0];
                          }
                      });
    }

    createInviteLink() {
        this.creatingInviteLink = true;
        this.savingErrorMessage = "";
        this.activeInviteLink = new InviteLink();
        this.activeInviteLink.OrganizationId = this.organizationId;
        API.InviteLink.save(this.activeInviteLink)
                      .then((link) => {
                          if(link) {
                              this.activeInviteLink = link;
                          }
                      })
                      .catch(err => {
                          this.savingErrorMessage = err.message;
                      })
                      .finally(() => {
                          this.creatingInviteLink = false;
                          this.refresh();
                      });
    }

    deleteInviteLink() {
        if(this.activeInviteLink) {
            this.deletingInviteLink = true;
            API.InviteLink.delete(this.activeInviteLink.Id)
                          .then(res => {
                              if(res) {
                                  this.activeInviteLink = null;
                                  this.closeInviteLinkDialog();
                              }
                          })
                          .catch(err => {
                              this.deleteErrorMessage = err.message;
                          })
                          .finally(() => {
                              this.deletingInviteLink = false;
                              this.refresh();
                          })

        }
    }

    requestDeletion() {
        this.deletionRequested = true;
    }

    openInviteLinkDialog() {
        this.showInviteLinkDialog = true;
    }

    closeInviteLinkDialog() {
        this.showInviteLinkDialog = false;
        this.creatingInviteLink = false;
        this.deletingInviteLink = false;
        this.savingErrorMessage = "";
        this.deletionRequested = false;
    }

    copyLink() {
        document.addEventListener('copy', this.copyToClipboard);
        document.execCommand('copy');
        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{
            autoHide: true,
            autoHideAfterMs: 3000,
            type: NotificationType.success,
            message: this.$i18n.t('InviteLinkDialog_LinkCopied').toString()
        });
    }

    get canCreateInviteLink(): boolean {
        return true;
    }

    get hasInviteLink(): boolean {
        return this.activeInviteLink != null;
    }

    get inviteLinkUrl(): string {
        if(!this.activeInviteLink || this.creatingInviteLink) {
            return "";
        }
        return `${window.location.protocol}//${window.location.host}/join/${this.activeInviteLink.Id}`;
    }

    copyToClipboard(event: ClipboardEvent) {
        event.clipboardData?.setData('text/plain', this.inviteLinkUrl);
        event.preventDefault();
        document.removeEventListener('copy', this.copyToClipboard);
    }
}
