
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import SingleFormDialog from '@/views/MyTables/SingleFormDialog.vue';
    import { ListTreeModule } from '@/store/ListTreeStore';
    import { ListTreeHelper } from '@/utils/ListTreeHelper';

    @Component({
        name:'CreateFolderAction',
        components: { SingleFormDialog }
    })
    export default class CreateFolderAction extends Vue {

        @Prop({ default: false }) canCreate!: boolean;
        @Prop({ default: '' }) organizationId!: string;
        @Prop({ default: '' }) folderId!: string;

        private createFolderModal=false;
        createFolderDialogName : string = 'create-folder-modal';
        createFolderServerErrorMessage: string = '';

        get dialogAlreadyExistInDOM(): boolean {
            return !!document.getElementById(this.createFolderDialogName);
        }

        async createFolder(folderName: string): Promise<void> {
            this.createFolderServerErrorMessage = "";
           
            let organizationId = this.organizationId ? this.organizationId : "";
            let folderId = this.folderId !== '' ? this.folderId : "";
            let createInfo = {
                    folderId: this.folderId,
                    orgId: this.organizationId,
                    folderName: folderName
                    };

            if(!ListTreeModule.listTree || !ListTreeModule.organizationsTree)
                return;

            let existingFolder = await ListTreeHelper.getFolderByNameInParentFolder(ListTreeModule.listTree, this.organizationId,this.folderId,folderName);
            if(existingFolder==null){
                let existingList = await ListTreeHelper.getListByNameInParentFolder(ListTreeModule.listTree, this.organizationId,this.folderId, folderName);
                if(existingList==null){
                    await ListTreeModule.createFolder(createInfo).then((res:any)=>{
                        this.createFolderModal = false;
                    }).catch((error:any)=>{
                        let errorMessage : string;
                        if (error.Exception != undefined)
                        {
                            errorMessage = error.Exception.Message;
                        }  
                        else
                        {
                            errorMessage = error.message ? error.message : error.Message;
                            if(errorMessage.includes('RS')){
                                errorMessage = this.$t('ExceptionCode_'+ error.Code).toString();
                            }
                        }
                        this.createFolderServerErrorMessage = errorMessage;
                    })
                }
                else{
                    this.createFolderServerErrorMessage = this.$t('MyTables_CreateFolderModal_ExistingList').toString();
                } 
            }
            else{
                this.createFolderServerErrorMessage = this.$t('MyTables_CreateFolderModal_ExistingFolder').toString();
            }
        }

        cancelled(){
            this.createFolderServerErrorMessage = "";
            this.createFolderModal = false;
        }
    }
