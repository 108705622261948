import { Utils } from "./Utilities"

const ticksPerMillisecond = 10000;
const ticksPerSecond = 1000 * ticksPerMillisecond;
const ticksPerMinutes = 60 * ticksPerSecond;
const ticksPerHours = 60 * ticksPerMinutes;
const dateFormatRegExp = /HH|H|hh|h|mm|m|fff|ff|f|ss|s|"[^"]*"|'[^']*'/g;

const zeros = ["", "0", "00", "000", "0000"];
function pad(n: number, digits: number) {
    const str = n + "";
    digits = digits || 2;
    const end = digits - str.length;

    if (end > 0) {
        return zeros[digits].substring(0, end) + n;
    }

    return n;
}

export default class TimeSpan {
    // must match the .NET TimeSpan.Ticks
    // 1 tick = 100 nanoseconds
    private _ticks: number;

    constructor(ticks: number) {
        this._ticks = ticks;
    }

    public static fromTime(hours: number, minutes?: number, seconds?: number, milliseconds?: number) {
        let ticks = hours * ticksPerHours;
        if (Utils.isNumber(minutes)) {
            ticks += minutes * ticksPerMinutes;
        }

        if (Utils.isNumber(seconds)) {
            ticks += seconds * ticksPerSecond;
        }

        if (Utils.isNumber(milliseconds)) {
            ticks += milliseconds * ticksPerMillisecond;
        }

        return new TimeSpan(ticks);
    }

    public static fromTicks(ticks: number) {
        return new TimeSpan(ticks);
    }

    public get ticks() {
        return this._ticks;
    }

    public get hours() {
        return Math.trunc(this.ticks / ticksPerHours);
    }

    public get minutes() {
        return Math.trunc((this.ticks / ticksPerMinutes) % 60);
    }

    public get seconds() {
        return Math.trunc((this.ticks / ticksPerSecond) % 60);
    }

    public get milliseconds() {
        return Math.trunc((this.ticks / ticksPerMillisecond) % 1000);
    }

    public get totalHours() {
        return this.ticks / ticksPerHours;
    }

    public get totalMinutes() {
        return this.ticks / ticksPerMinutes;
    }

    public get totalSeconds() {
        return this.ticks / ticksPerSecond;
    }

    public get totalMilliseconds() {
        return this.ticks / ticksPerMillisecond;
    }

    public get displayValue() {
        return this.toString();
    }

    public toDate() {
        const date = new Date();
        date.setHours(this.hours, this.minutes, this.seconds, this.milliseconds);
        return date;
    }

    public toString(format?: string) {
        if (Utils.isNullOrUndefined(format)) {
            // hh:mm:ss.fff
            return `${pad(this.hours, 2)}:${pad(this.minutes, 2)}:${pad(this.seconds, 2)}.${pad(this.milliseconds, 3)}`;
        }

        return format.replace(dateFormatRegExp, (match) => {
            var result;

            if (match === "h") {
                result = this.hours;
            } else if (match === "hh") {
                result = pad(this.hours, 2);
            } else if (match === "m") {
                result = this.minutes;
            } else if (match === "mm") {
                result = pad(this.minutes, 2);
            } else if (match === "s") {
                result = this.seconds;
            } else if (match === "ss") {
                result = pad(this.seconds, 2);
            } else if (match === "f") {
                result = Math.floor(this.milliseconds / 100);
            } else if (match === "ff") {
                result = this.milliseconds;
                if (result > 99) {
                    result = Math.floor(result / 10);
                }
                result = pad(result, 2);
            } else if (match === "fff") {
                result = pad(this.milliseconds, 3);
            }

            if (result !== undefined) {
                return String(result);
            }

            // quoted value ("sample" or 'sample')
            return match.slice(1, match.length - 1);
        });
    }
}