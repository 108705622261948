
import { ICellEditorParams } from 'ag-grid-community';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
import * as RowShare from '@/models/RowShare';
import { EventBus } from '@/modules/EventBus';
import * as API from '@/api/Api';
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';


@Component({name: "CommentEditor", components: { ConfirmationDialog }})
export default class CommentEditor extends Vue{
    params!: ICellEditorParams;
    openedWithTab = false;

    isPopup() { return false; }

    getValue() { return this.params.value; }

    mounted() {
        Vue.nextTick(() => {
            let tabRef = (<any>this.params.context)['tabbedIntoCellEditor'];
            let thisTabRef = this.params.data.rsRow.Id + '|' + this.params.colDef.colId;
            
            // reset until next navigation, otherwise tabing, closing, and dbl-clicking would detect a tab instead of dblclick
            (<any>this.params.context)['tabbedIntoCellEditor'] = null;

            if(tabRef === thisTabRef) {
                let element = this.$refs.btn as HTMLButtonElement;
                if(element)
                    element.focus();
            } else {
                this.openCommentDialog();
            }
        });
    }

    openCommentDialog(){
        EventBus.$emit(RowShare.Event.ROW_EDIT_COMMENTS, <RowShare.rowEditCommentsEventParams> {
            row: (<RowVM>this.params.data).rsRow,
            column: (<ColumnVM>this.params.colDef).rsColumn,
            list: (<RowVM>this.params.data).listVM.list,
            refreshParentRow: () => this.refreshParentRow()
        });
    }

    async refreshParentRow() {
        let rsRow = <RowVM>this.params.data;
        if(rsRow?.rsRow) {
            let updatedRow = await API.Row.load(rsRow.rsRow.Id);
            if(updatedRow) {
                rsRow.rsRow = updatedRow;
                this.params.node.setData(rsRow);
                this.params.api?.refreshCells({rowNodes: [this.params.node], force: true});
            }
        }
    }

}
