import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"
import {ApiResult} from "@/models/RowShare";

export class Member{
    static load(organizationId: string, email: string, options: IRequestOptions | null = null)
    : Promise<RowShare.Member | null> {
        return ApiUtils.apiGet("/member/load/"+ ApiUtils.encodeSegment(email) +"/"+ ApiUtils.encodeSegment(organizationId), options)
            .then(response => response.json<RowShare.Member>());
    }

    static loadForParent(organizationId: string, options: IRequestOptions | null = null) 
    : Promise<Array<RowShare.Member> | null> {
        options = ApiUtils.createRequestOptions({ cache: false}, options);
        return ApiUtils.apiPostJson("/member/loadforparent/" + organizationId, options)
            .then(response=> response.objects(RowShare.Member))
    }

    static save(member: RowShare.Member)
    : Promise<RowShare.Member | null> {
        return ApiUtils.apiPostJson('/member/save/', member).then(response => response.object(RowShare.Member));
    }

    static resendInvitation(member: RowShare.Member)
    : Promise<boolean | null> {
        return ApiUtils.apiPostJson('/member/resendInvitation/', member).then(response => response.json<boolean>());
    }
    static remove(member: RowShare.Member)
    : Promise<boolean | null> {
        return ApiUtils.apiPostJson('/member/delete/', member).then(response => response.json<boolean>());
    }

    static async addRowShareSupport(organizationId: string)
        : Promise<ApiResult<RowShare.Member> | null> {
        const data = { OrganizationId: organizationId };
        let response = await ApiUtils.apiPostJson('/member/addRowShareSupport/', data);
        return response.object(ApiResult);
    }

    static async removeRowShareSupport(organizationId: string)
        : Promise<ApiResult<boolean> | null> {
        const data = { OrganizationId: organizationId};
        let response = await ApiUtils.apiPostJson('/member/removeRowShareSupport/', data);
        return response.object(ApiResult);
    }
}