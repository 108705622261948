import * as RowShare from '@/models/RowShare';
import i18n from '@/modules/Localization';
import ColumnVM from './columnVM';
import { IColumnDescription } from './IColumnDescription';
import { Utils } from '@/utils/Utilities';

export class ColumnDescriptionVM implements IColumnDescription {
    columnSorted: boolean = false;
    columnSortedAsc: boolean = false;
    columnSortedDesc: boolean = false;
    currentColumn:  RowShare.Column | null = null;
    isRowGroupColumn: boolean = false;
    rowGroupsColumns: ColumnVM[] = [];
    
    constructor(column: RowShare.Column | null) {
        this.currentColumn = column;
    }

    get columnTitle() {
        return this.currentColumn?.DisplayName ?? "";
    }

    get columnHasSpecialProperties() {
        return this.columnIsMandatory || this.columnSorted;
    }

    get columnHasPermissions() {
        return (this.columnIsReadOnly && !this.currentColumn?.isGenerated) || this.columnHasReadOnlyPermissions || this.columnHasHiddenPermissions;
    }

    get columnIsMandatory(): boolean {
        if(this.currentColumn) {
            return this.currentColumn.isMandatory;
        }
        return false;
    }

    get columnIsReadOnly(): boolean {
        if(this.currentColumn) {
            return this.currentColumn.IsReadOnly;
        }
        return false;
    }

    get columnHasHiddenPermissions(): boolean {
        return this.currentColumn?.HasHiddenPermissions ?? false;
    }

    get columnHasReadOnlyPermissions(): boolean {
        return this.currentColumn?.HasReadOnlyPermissions ?? false;
    }

    get columnSubName(): string {
        if(this.currentColumn) {
            return this.currentColumn.SubName;
        }
        return "";
    }
    

    get columnIsFormula(): boolean {
        if(this.currentColumn) {
            return this.currentColumn.isFormula;
        }
        return false;
    }

    get sortDescription(): string {
        if(this.columnSortedAsc) {
            return i18n.t('HeaderDescription_SortedAsc').toString();
        }
        if(this.columnSortedDesc) {
            return i18n.t('HeaderDescription_SortedDesc').toString();
        }
        return "";
    }

    get formulaDescription(): string {
        if(this.currentColumn?.isFormula) {
            return i18n.t('HeaderDescription_Formula', {formula: this.currentColumn.DefaultValue}).toString();
        }
        return "";
    }

    get mandatoryDescription(): string {
        return i18n.t('HeaderDescription_Mandatory').toString();
    }

    get readOnlyDescription(): string {
        return i18n.t('HeaderDescription_ReadOnly').toString();
    }

    get readOnlyPermissionsDescription(): string {
        return i18n.t('HeaderDescription_HasReadOnlyPermissions').toString();
    }

    get hiddenPermissionsDescription(): string {
        return i18n.t('HeaderDescription_HasHiddenPermissions').toString();
    }
    
    hiddenColumnsBesideCount: number = 0;

    get hiddenColumnDescription(): string {
        return i18n.t('HeaderDescription_HiddenColumns', {hiddenColumnsCount: this.hiddenColumnsBesideCount}).toString();
    }

    getSortIcon(hover: boolean): string {
        if(!hover && !this.columnSorted) {
            return "fal fa-fw";
        }
        if(this.columnSortedAsc) {
            return "fal fa-sort-amount-down-alt";
        }
        else if(this.columnSortedDesc) {
            return "fal fa-sort-amount-down";
        }
        else {
            return "fal fa-sort-alt"
        }
    } 

    get specialColumnIcon(): string {
         if(this.columnIsFormula) {
            return "fal fa-function";
        }
        else if(this.currentColumn?.isMember) {
            return "fal fa-user";
        }
        else if(this.currentColumn?.isGenerated) {
            return "fal fa-cogs";
        }
        return "";
    }

    get permissionsIcon(): string {
        if(this.columnHasHiddenPermissions) {
            return "fal fa-eye-slash";
        }
        else if(this.columnHasReadOnlyPermissions) {
            return "fal fa-lock-open";
        }
        else if(this.columnIsReadOnly) {
            return "fal fa-lock";
        }
        return "";
    }

    get columnIsChild(): boolean {
        if(this.currentColumn) {
            return this.currentColumn.isChildColumn;
        }
        return false;
    }

    get columnGroupName(): string {
        if(this.currentColumn) {
            return this.currentColumn.ColumnGroup;
        }
        return "";
    }
}

export class ColumnDescriptionCollection {
    descriptions: ColumnDescriptionVM[];

    constructor() {
        this.descriptions = [];
    }
    
    public add(colDescVM: ColumnDescriptionVM) {
        this.descriptions.push(colDescVM);
    }

    public get columns(): ColumnDescriptionVM[] {
        return this.descriptions;
    }

    isFirstOfGroup(index: number): boolean {
        let currentColumn = this.descriptions[index].currentColumn;
        if(!Utils.isNullOrWhiteSpace(currentColumn?.ColumnGroup)) {
            if(index === 0) {
                return true;
            }
            else {
                let prevColumn = this.descriptions[index - 1].currentColumn;
                return Utils.isNullOrWhiteSpace(prevColumn?.ColumnGroup) || prevColumn?.ColumnGroup != currentColumn?.ColumnGroup;
            }
        }
        return false;
    }

    isMiddleOfGroup(index: number): boolean {
        let currentColumn = this.descriptions[index].currentColumn;
        if(!Utils.isNullOrWhiteSpace(currentColumn?.ColumnGroup)) {
            if(index > 0 && index < this.descriptions.length - 1) {
                let nextColumn = this.descriptions[index + 1].currentColumn;
                let prevColumn = this.descriptions[index - 1].currentColumn;
                return !Utils.isNullOrWhiteSpace(prevColumn?.ColumnGroup) && prevColumn?.ColumnGroup === currentColumn?.ColumnGroup &&
                       !Utils.isNullOrWhiteSpace(nextColumn?.ColumnGroup) && nextColumn?.ColumnGroup === currentColumn?.ColumnGroup;
            }
        }
        return false;
    }

    isLastOfGroup(index: number): boolean {
        let currentColumn = this.descriptions[index].currentColumn;
        if(!Utils.isNullOrWhiteSpace(currentColumn?.ColumnGroup)) {
            if(index === this.descriptions.length-1) {
                return true;
            }
            else {
                let nextColumn = this.descriptions[index + 1].currentColumn;
                return Utils.isNullOrWhiteSpace(nextColumn?.ColumnGroup) || nextColumn?.ColumnGroup != currentColumn?.ColumnGroup;
            }
        }
        return false;
    }

}