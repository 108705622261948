import Vue from 'vue';
import VueI18n, { LocaleMessageObject, LocaleMessages } from 'vue-i18n';
import * as API from '@/api/Api';
import { UserModule } from '@/store/UserStore';

Vue.use(VueI18n);

const supportedCulture = ['en', 'fr', 'es'];

export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    fallbackRoot: true
});

export async function loadAndSetWithUserLanguageAsync(doNotEnforce?: boolean): Promise<void> {
    let userLanguage = UserModule.currentUserLanguage ? UserModule.currentUserLanguage : null;
    if (!userLanguage && doNotEnforce) {
        let activeLocale = await sessionStorage.getItem('activeLocale');
        if (activeLocale) {
            userLanguage = activeLocale;
        }
    }
    let language = userLanguage ? userLanguage : navigator.language.substring(0, 2);
    let userLocale: string =
        (language && supportedCulture.includes(language.toLowerCase()))
            ? language : 'en';

    UserModule.storeUserLanguage(userLocale);
    i18n.locale = userLocale;
    await loadAndStoreTranslationsAsync(userLocale);
    document.documentElement.setAttribute('lang', userLocale);
}

export async function loadAndStoreTranslationsAsync(locale: string): Promise<void> {
    let activeLocale: string | null = await sessionStorage.getItem('activeLocale') as string;
    let activeLocaleUpdate: string | null = await sessionStorage.getItem('activeLocaleUpdateDate') as string;
    let activeLocaleMessage: string | null = await sessionStorage.getItem('activeLocaleMessage') as string;
    if ((activeLocale === locale)
        && (activeLocaleMessage !== null)
        && (activeLocaleUpdate !== null)) {
        let activeLocaleUpdateDate = parseInt(activeLocaleUpdate);
        let now = new Date();
        if (now.getTime() - activeLocaleUpdateDate < 2 * 3600 * 1000) {
            let translationMessage: LocaleMessageObject = JSON.parse(activeLocaleMessage);
            i18n.setLocaleMessage(locale, translationMessage);
            return;
        }
    }

    let translationMessage = await API.Translation.load(locale);
    if (translationMessage) {
        i18n.setLocaleMessage(locale, translationMessage);
        sessionStorage.setItem('activeLocale', locale);
        sessionStorage.setItem('activeLocaleUpdateDate', (new Date()).getTime().toString());
        sessionStorage.setItem('activeLocaleMessage', JSON.stringify(translationMessage));
    }


}


export default i18n;
