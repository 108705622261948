import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"

export class ContactMessage {
    
    static sendMessage(message: RowShare.ContactMessage, file:File|null=null,  options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        //This call to the API allows to send a Json and files, but for the moment it will send
        //only the Json until files are allowed.
        return ApiUtils.apiPostJsonWithFiles("/contactMessage/sendMessage/", message, file, options)
                .then(response => response.json<boolean>());
    }
    
}