import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"

export class SecurityPrincipal {
    static async loadForOrganization(id: string, includeTeams: boolean, options: IRequestOptions | null = null)
    : Promise<Array<RowShare.SecurityPrincipal> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/securityprincipal/loadforparent/" + ApiUtils.encodeSegment(id) + (includeTeams ? "/true" : ""), options)
            .then(response => response.objects(RowShare.SecurityPrincipal));
    }
}