import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"

export class View {
    static save(view: View, options: IRequestOptions | null = null) {
        (<any>view).IsV3 = true;
        return ApiUtils.apiPostJson("/view/save/", view, options)
            .then(response => response.object(Models.View));
    }

    static loadForParent(id: string, options: IRequestOptions | null = null) {
        return ApiUtils.apiGet("/view/loadforparent/" + ApiUtils.encodeSegment(id) + "/v3", options)
            .then(response => response.objects(Models.View));
    }

    static remove(id: string, options: IRequestOptions | null = null) {
        return ApiUtils.apiGet("/view/delete/" + ApiUtils.encodeSegment(id), ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<boolean>());
    }
}