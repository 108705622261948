import * as RowShare from '@/models/RowShare';
import ColumnVM from './columnVM'
import { EventBus } from "@/modules/EventBus";
import * as API from '@/api/Api';
import { ColumnStrongType, RelatedRow, Row, RowErrorEventParams } from '@/models/RowShare';
import FileVM from './FileVM';
import { i18n } from "@/modules/Localization";
import { Utils } from '@/utils/Utilities';
import { BigramHelper } from '@/utils/BigramHelper';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import RsColumnManager from '@/columnProxies/RsColumnManager';
import ListVM from './listVM';
import rowBaseVM from './rowBaseVM';
import { UserModule } from '@/store/UserStore';
import { el } from '@fullcalendar/core/internal-common';
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

export default class RowVM extends rowBaseVM {
    public static readonly rowPositionLast = "_rowPositionLast";
    public static readonly cellNotAccessible = "||NotAccessibleCell||";

    private static nextRowVMId = 0;

    rowVMId: string;
    listVM: ListVM;
    rsRow: RowShare.Row;
    isNew: Boolean = false;
    dirtyValues: any = null;
    valuesBeingSaved: any = null;
    removed = false;

    constructor(listVM: ListVM, row: RowShare.Row) {
        super();
        this.rowVMId = row?.Id ? row.Id : "" + RowVM.nextRowVMId++;
        this.listVM = listVM;
        this.rsRow = row;
    }

    // public get rowVMId(): string {
    //     return this.rsRow?.Id ?? ""+ RowVM.nextRowVMId++;
    // }

    public get isLocked(): boolean {
        return (this.rsRow?.Status ?? RowShare.DataStatus.Normal) == RowShare.DataStatus.Locked && !this.editionInProgress;
    }

    public get editionInProgress(): boolean {
        return (this.rsRow?.Status ?? RowShare.DataStatus.Normal) == RowShare.DataStatus.EditionInProgress;
    }

    public static createNewRow(listVM: ListVM, listId: string): RowVM {
        var newRow = new RowShare.Row();
        newRow.Values = {};
        newRow.ListId = listId;
        newRow.CanAssign = true;
        newRow.CanSuppress = true;
        newRow.CanUpdate = true;

        // setting an estimated index to enable ordering the form view
        // if rowVMs is missing, this is survey, index is not used
        if (listVM.rowVMs) {
            var maxIdx = 0;
            for (let i = 0; i < listVM.rowVMs.length; ++i) {
                var tmpIdx = listVM.rowVMs[i].rsRow.Index;
                if (tmpIdx > maxIdx)
                    maxIdx = tmpIdx;
            }
            newRow.Index = maxIdx + 1;
        }

        var res = new RowVM(listVM, newRow);
        res.rsRow = newRow;
        res.isNew = true;

        if (listVM.columns) {
            for (const col of listVM.columns) {
                if (col.DefaultValue && !col.isFormula) {
                    newRow.Values[col.DisplayName] = col.DefaultValue;
                    const proxy = RsColumnManager.getProxy(col.Type);
                    res.setValue(col, proxy.parseInitialTypedValue(col, res));
                }
            }
        }

        return res;
    }

    public getValue(rsColumn: RowShare.Column, columnProxy: BaseColumnProxy) {
        const colName = rsColumn.DisplayName;

        if (this.dirtyValues) {
            var dirtyVal = this.dirtyValues[colName];
            if (dirtyVal !== undefined)
                return dirtyVal;
        }

        if (this.valuesBeingSaved) {
            var valBeingSaved = this.valuesBeingSaved[colName];
            if (valBeingSaved !== undefined)
                return valBeingSaved;
        }
        // Used for related rows/columns
        if (this.rsRow.Values[colName] === RowVM.cellNotAccessible) {
            return RowVM.cellNotAccessible;
        }
        return columnProxy.parseInitialTypedValue(rsColumn, this);
    }

    public getAssignation() {
        if (this.dirtyValues) {
            var dirtyVal = this.dirtyValues[ColumnVM.rowOwnerHeaderId];
            if (dirtyVal !== undefined)
                return dirtyVal;
        }

        if (this.valuesBeingSaved) {
            var valBeingSaved = this.valuesBeingSaved[ColumnVM.rowOwnerHeaderId];
            if (valBeingSaved !== undefined)
                return valBeingSaved;
        }

        return {
            ownerId: this.rsRow.OwnerId,
            ownerIdType: this.rsRow.OwnerIdType,
            rowOwner: this.rsRow.Owner
        };
    }

    public getOwnerSortValue() {
        const assignation = this.getAssignation();
        if (!assignation.rowOwner && !assignation.SecurityPrincipal) {
            return "";
        }
        return assignation.rowOwner
            ? BigramHelper.getBigramTooltipForRowOwner(assignation.rowOwner)
            : BigramHelper.getBigramTooltipForSecurityPrincipal(assignation.securityPrincipal);
    }

    public setValue(col: RowShare.Column, val: any) {
        // When refreshing a row, the call to rowNode.setDataValue required to refresh formula will
        // ends up calling setValue, but this should be stopped to avoid useless save (which occured in loop for 
        // some customers, resulting in a high load on the server)
        if (col.isFormula)
            return;


        // check is required here since flaging the value as dirty would result in this unchanged value
        // being saved on next cellValueChanged event for any 'actually' changed value
        // For new row, default values will seem unchanged but still need to be saved 
        const proxy = RsColumnManager.getProxy(col.Type);
        var oldValue = this.getValue(col, proxy);
        if (!this.isNew && proxy.areEquals(val, oldValue))
            return;

        if (!this.dirtyValues)
            this.dirtyValues = {};

        this.dirtyValues[col.DisplayName] = val;

        EventBus.$emit(RowShare.Event.ROW_MODIFIED, this);
    }

    public setRelatedRow(col: RowShare.Column, val: string) {
        // Value for child columns is actually the related row id
        if (!col.HasParentColumn) {
            return;
        }

        var related = this.getRelatedRow(col) ?? new RowShare.RelatedRow();
        var modified = (related?.ParentRowId ?? '') != val;
        related.ChildRowId = this.rsRow.Id;
        related.ParentRowId = val;
        related.RelationColumnId = col.RelationColumnId;
        if (!modified) {
            return;
        }
        if (!this.dirtyValues) {
            this.dirtyValues = {};
        }
        this.dirtyValues[col.RelationColumnId] = related;
        this.innerSave();
        EventBus.$emit(RowShare.Event.ROW_MODIFIED, this);
    }

    public getRelatedRow(col: RowShare.Column): RowShare.RelatedRow | null | undefined {
        if (!this.rsRow.HasRelatedRows)
            return null;
        else
            return this.rsRow.ParentRows.find(r => r.ChildRowId === this.rsRow.Id && r.RelationColumnId === col.RelationColumnId) ?? null;
    }

    public async loadRelatedRow(col: RowShare.Column): Promise<RowShare.RelatedRow | null | undefined> {
        if(!this.rsRow.Id) {
            return null;
        }
        if (!this.rsRow.HasRelatedRows) {
            let pr = await this.getParentRowsAsync(col);
            this.rsRow.ParentRows = pr ?? [];
            if (this.rsRow.ParentRows.length > 0)
                return this.rsRow.ParentRows.find(r => r.ChildRowId === this.rsRow.Id && r.RelationColumnId === col.RelationColumnId) ?? null;
            else
                return null;
        }
        else
            return this.rsRow.ParentRows.find(r => r.ChildRowId === this.rsRow.Id && r.RelationColumnId === col.RelationColumnId) ?? null;
    }

    public async getParentRowsAsync(col: RowShare.Column): Promise<RelatedRow[] | null> {
        //mass load by loadforparent does not load related rows
        //direct call when needed ONLY
        let current = await API.Row.load(this.rsRow.Id);
        if (current && current.HasRelatedRows)
            return current.ParentRows;
        else
            return null;
    }

    public setAssignation(val: any) {
        // same as set value
        var oldValue = this.getAssignation();
        if (val == oldValue)
            return;

        if (!this.dirtyValues)
            this.dirtyValues = {};

        this.dirtyValues[ColumnVM.rowOwnerHeaderId] = val;
        EventBus.$emit(RowShare.Event.ROW_MODIFIED, this);
    }

    public setInsertBeforeRowId(insertBeforeRowId: string | undefined) {
        if (!this.dirtyValues)
            this.dirtyValues = {};

        this.dirtyValues[ColumnVM.insertBeforeRowIdId] = insertBeforeRowId;
        EventBus.$emit(RowShare.Event.ROW_MODIFIED, this);
    }

    public async moveBefore(beforeId: string) {
        this.setInsertBeforeRowId(beforeId);
        await this.innerSave();
    }

    public async moveLast() {
        this.setInsertBeforeRowId(RowVM.rowPositionLast);
        await this.innerSave();
    }

    async cellValueChanged(col: RowShare.Column, newValue: any, oldValue: any) {
        // seems redundant with the test in setValue, but even if the value is not flagged as dirty
        // we still need to avoid an empty save 
        const proxy = col?.Type ? RsColumnManager.getProxy(col.Type) : undefined;
        if ((proxy && proxy.areEquals(newValue, oldValue)) || newValue === oldValue || col?.isFormula)
            return;

        // only one save at a time for a given row
        if (this.valuesBeingSaved)
            return;

        this.innerSave();
    }

    async saveSurvey() {
        if (this.valuesBeingSaved)
            return;

        await this.innerSave(true);
    }

    async innerSave(isAnonymousSurveyAnswer: boolean = false) {
        this.valuesBeingSaved = this.dirtyValues;
        this.dirtyValues = null;
        this.resetAllErrors();
        this.listVM.notifyRowVMUpdated(this);

        try {
            // boucle sur les colonnes et construire values/files
            let filesToSave: RowShare.PostedFile[] = [];
            let filesToDelete: RowShare.PostedFile[] = [];
            let relatedRowsToSave: RowShare.RelatedRow[] = [];
            let valuesToSave: any = null;
            if(!this.valuesBeingSaved) {
                this.valuesBeingSaved = {};
            }

            if (!this.listVM.columns)
                throw new Error("Error - No columns loaded");

            for (const col of this.listVM.columns) {
                let colAccessor = col.HasParentColumn ? col.RelationColumnId : col.Name;
                if (this.valuesBeingSaved[colAccessor] === undefined) {
                    continue;
                }
                if (col.HasParentColumn) {
                    let colValue = <RelatedRow>this.valuesBeingSaved[colAccessor];
                    if (colValue) {
                        let index = relatedRowsToSave.findIndex(rrts => (
                            (rrts.ChildRowId == colValue.ChildRowId && rrts.ParentRowId == colValue.ParentRowId && rrts.RelationColumnId == colValue.RelationColumnId)));
                        if (index < 0) {
                            relatedRowsToSave.push(colValue);
                        }
                    }
                }
                else if (col.Type == ColumnStrongType.Image || col.Type == ColumnStrongType.File) {
                    let colValue = <FileVM>this.valuesBeingSaved[colAccessor];
                    if (colValue) {
                        let file = new RowShare.PostedFile(col, colValue.File)
                        if (file.hasData) {
                            filesToSave.push(file);
                        }
                        else {
                            filesToDelete.push(file);
                        }
                    }
                }
                else {
                    if (!valuesToSave)
                        valuesToSave = {};

                    const proxy = RsColumnManager.getProxy(col.Type);
                    valuesToSave[colAccessor] = proxy.getAPIVal(col, this, this.valuesBeingSaved[colAccessor]);
                }
            }

            let shoudRefreshOthers = false;
            if (valuesToSave || filesToSave.length > 0 || relatedRowsToSave.length > 0 || this.valuesBeingSaved[ColumnVM.insertBeforeRowIdId]) {
                if (!valuesToSave)
                    valuesToSave = {};

                let insertBeforeRowId = this.valuesBeingSaved[ColumnVM.insertBeforeRowIdId];
                let moveLast: boolean | undefined = undefined;
                if (insertBeforeRowId == RowVM.rowPositionLast) {
                    insertBeforeRowId = undefined;
                    moveLast = true;
                }

                var apiSavedRow = await API.Row.save({
                    Id: this.rsRow.Id,
                    ListId: this.rsRow.ListId,
                    Version: this.rsRow.Version,
                    Values: valuesToSave,
                    InsertBeforeRowId: insertBeforeRowId,
                    MoveLast: moveLast,
                    IsAnonymousSurveyAnswer: isAnonymousSurveyAnswer,
                    ParentRows: relatedRowsToSave,
                    RtcConnectionId: RealTimeCollaborationModule.connection.connectionId
                }, filesToSave);

                if (apiSavedRow) {
                    this.rsRow = apiSavedRow;
                    this.isNew = false;

                    // if row saved succeed but assign failed, we don't want to save these values again
                    for (const savedValue in valuesToSave) {
                        this.valuesBeingSaved[savedValue] = undefined;
                    }
                    shoudRefreshOthers = !!insertBeforeRowId;
                    this.valuesBeingSaved[ColumnVM.insertBeforeRowIdId] = undefined;
                } else {
                    throw new Error("Missing API response");
                }
            }
            for (const f of filesToDelete) {
                var rowAfterFileDelete = await API.Row.deleteRowFile(this.rsRow.Id, f.column.Index, RealTimeCollaborationModule.connection.connectionId, { clearCache: true });
                if (rowAfterFileDelete) {
                    this.rsRow = rowAfterFileDelete;
                }
            }

            // assignation
            var newOwner = this.valuesBeingSaved[ColumnVM.rowOwnerHeaderId];
            if (newOwner) {
                var apiSavedRow = await API.Row.assignRowOwner({
                    Id: this.rsRow.Id,
                    Version: this.rsRow.Version,
                    OwnerId: newOwner.ownerId,
                    OwnerIdType: newOwner.ownerIdType
                });

                if (apiSavedRow) {
                    this.rsRow = apiSavedRow;
                    this.isNew = false;
                    this.getAssignation()
                    if (!this.rsRow.OwnerHasAccessToList) {
                        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                            autoHideAfterMs: 10000,
                            autoHide: true,
                            duplicateId: 'OwnerHasNoAccessToList',
                            message: this.listVM.list?.Owned ? i18n.t('Common_OwnerHasNoAccessToList_Admin', [this.rsRow.Owner.DisplayName]) : i18n.t('Common_OwnerHasNoAccessToList', [this.rsRow.Owner.DisplayName]),
                            type: RowShare.NotificationType.warning,
                            title: i18n.t('Common_OwnerHasNoAccesToListTitle'),
                            showActionButton: this.listVM.list?.Owned,
                            actionButtonText: i18n.t('Common_AddOwnerToListAccesses'),
                            actionButtonClick: () => {
                                var sp: RowShare.SecurityPrincipal = new RowShare.SecurityPrincipal();
                                if (this.rsRow.OwnerIdType == RowShare.OwnerIdType.Group) {
                                    sp.GroupId = this.rsRow.OwnerId;
                                    sp.GroupName = this.rsRow.Owner.DisplayName;
                                }
                                else if (this.rsRow.OwnerIdType == RowShare.OwnerIdType.User) {
                                    sp.MemberEmail = this.rsRow.OwnerId;
                                    sp.MemberName = this.rsRow.Owner.DisplayName;
                                    sp.MemberOrganizationId = this.listVM.list?.OrganizationId ?? '';
                                }
                                sp.Bigram = this.rsRow.Owner.Bigram;
                                sp.BackgroundColorIndex = this.rsRow.Owner.BackgroundColorIndex;
                                EventBus.$emit(RowShare.Event.SHOW_SIDEPANEL_SHARING, <RowShare.showSidePanelSharingEventParams>{
                                    listId: this.listVM.list?.Id,
                                    inviteParams: <RowShare.inviteRequestEventParams>{
                                        listId: this.listVM.list?.Id,
                                        selectedGuests: [sp]
                                    }
                                });
                            }
                        });
                    }
                } else {
                    throw new Error("Missing API response");
                }
            }

            EventBus.$emit(RowShare.Event.ROW_SAVED, this);

            if (this.dirtyValues)
                this.innerSave();
            else
                this.valuesBeingSaved = null;

            this.listVM.notifyRowVMUpdated(this);

            if (shoudRefreshOthers) {
                this.listVM.refreshRowsFromServer();
            }
        } catch (err: any) {
            console.log("An error occured while saving the row");
            console.log(err);
            this.hasError = true;
            if (err._message)
                this._globalErrorMessage = err._message;
            if (err.validationErrors)
                this._cellsErrorMessages = err.validationErrors;

            const errorTitle = Utils.isNullOrWhiteSpace(this.rsRow.DescriptorFormattedValue)
                ? null
                : i18n.t("ErrorNotif_RowErrorTitleTemplate", [this.rsRow.DescriptorFormattedValue]).toString();

            EventBus.$emit(RowShare.Event.ROW_ERROR, <RowErrorEventParams>{ rowVM: this, title: errorTitle, message: err._message });

            let shouldResave = this.dirtyValues ? true : false;

            // reset all values that couldn't be saved as dirty, except for those that have been re-edited since
            if (!this.dirtyValues) this.dirtyValues = {};
            for (const colName in this.valuesBeingSaved) {
                if (this.dirtyValues[colName] === undefined)
                    this.dirtyValues[colName] = this.valuesBeingSaved[colName];
            }

            if (shouldResave)
                this.innerSave();
            else {
                this.valuesBeingSaved = null;
            }
            this.listVM.notifyRowVMUpdated(this);
        }
        finally {
            this.listVM.refreshCell(this);
        }
    }

    async refreshValues(row: Row) {
        if (!this.dirtyValues && !this.valuesBeingSaved) {
            this.rsRow = row;
            this.listVM.notifyRowVMUpdated(this);
            this.listVM.refreshCell(this);
        }
    }

    async refreshIndexAndVersion(row: Row) {  
            this.rsRow.Index = row.Index;
            this.rsRow.Version = row.Version;
    }

    /** State management **/

    public hasError: boolean = false;
    public _globalErrorMessage: string | null = null;
    public _cellsErrorMessages: { Column: string, Message: string }[] | null = null;
    public hasCellError(rsColumn: RowShare.Column) {
        return this.getCellError(rsColumn) != null;
    }
    public getCellError(rsColumn: RowShare.Column): string | null {
        if (!this._cellsErrorMessages || !rsColumn)
            return null;

        var cellError = this._cellsErrorMessages.find(e => e.Column == rsColumn.DisplayName);
        if (cellError)
            return cellError.Message;

        return null;
    }
    public isSavingCell(rsColumn: RowShare.Column) {
        if (!this.valuesBeingSaved || !rsColumn)
            return false;

        var cell = this.valuesBeingSaved[rsColumn.DisplayName];
        if (cell !== undefined)
            return true;

        return false;
    }
    public resetAllErrors() {
        this.hasError = false;
        this._globalErrorMessage = null;
        this._cellsErrorMessages = null;
    }

    public get isReadOnly() {
        return (!this.rsRow.CanAssign && !this.rsRow.CanSuppress && !this.rsRow.CanUpdate) || this.listVM?.specialRowsDisplayed || this.isLocked;
    }
}