
import { Component, Watch } from 'vue-property-decorator';
import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
import LookupField from '@/views/Table/cellsComponents/Lookup/LookupField.vue'

@Component({ name: 'LookUpFormEditor', components: { LookupField }})
export default class LookUpFormEditor extends FormEditor {

    get lookupfield() : LookupField {
        return <LookupField>this.$refs.lookupfield;
    }

    async init() {
        this.lookupfield.init();
    }

    getValue(){
        return this.lookupfield.getValue();
    }

    onValueChanged() {
        this.saveCellValue();
    }
}
