import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import MyTables from '@/views/MyTables/MyTables.vue';
import CreateTable from '@/views/CreateTable/CreateTable.vue';
import ListDisplayModeSelector from '@/views/Table/ListDisplayModeSelector.vue';
import ListBasedRoot from '@/views/Table/ListBasedRoot.vue';
import Contact from '@/views/Contact/Contact.vue';
import Login from '@/views/Signin/Login.vue';
import LoginFba from '@/views/Signin/LoginFba.vue';
import LoginMS from '@/views/Signin/LoginMS.vue';
import MyAccount from '@/views/MyAccount/MyAccount.vue';
import SignUp from '@/views/Signup/SignUp.vue';
import MyDocuments from '@/views/RowMerge/MyDocuments.vue';
import RowMerge from '@/views/RowMerge/RowMerge.vue';
import PageNotFound from '@/views/ErrorPages/PageNotFound.vue';
import AccessDenied from '@/views/ErrorPages/AccessDenied.vue';
import OrganizationAccessDenied from '@/views/ErrorPages/OrganizationAccessDenied.vue';
import SubscriptionCheckOut from '@/views/ManageOrganization/SubscriptionCheckOut.vue';
import Unsubscription from '@/views/MyAccount/Unsubscription.vue';
import TableOwners from '@/views/Table/ShareTable/TableOwners.vue';
import TableAccessDenied from '@/views/Table/TableAccessDenied.vue';
import ConfirmEmail from '@/views/ConfirmEmail/ConfirmEmail.vue';
import TableSettings from '@/views/Table/TableSettings/TableSettings.vue';
import ResetPasswordRequest from '@/views/ResetPassword/ResetPasswordRequest.vue';
import ResetPassword from '@/views/ResetPassword/ResetPassword.vue';
import { UserModule } from '@/store/UserStore';
import { ListTreeModule } from '@/store/ListTreeStore';
import { ApplicationModule } from '@/store/ApplicationStore';
import { OnPremModule } from '@/store/OnPremStore';
import ManageOrganization from '@/views/ManageOrganization/ManageOrganization.vue';
import ListAccessAudit from '@/views/ManageOrganization/ListAccessAudit.vue';
import BackOffice from '@/views/BackOffice/BackOffice.vue';
import BackOfficeOrganization from '@/views/BackOffice/BackOfficeOrganization.vue';
import BackOfficeOrganizationLimits from '@/views/BackOffice/BackOfficeOrganizationLimits.vue';
import BackOfficeUser from '@/views/BackOffice/BackOfficeUser.vue';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import ColumnSettings from '@/views/Table/ColumnSettings/ColumnSettings.vue';
import { loadAndSetWithUserLanguageAsync } from '@/modules/Localization';
import Cookie from "js-cookie";
import { Utils } from '@/utils/Utilities';
import { RowShareException } from '@/api/ApiUtils';
import TablePrint from '@/views/Table/TablePrint.vue';
import ListFormView from '@/views/Table/FormView/ListFormView.vue';
import Survey from '@/views/Table/FormView/Survey.vue';
import SurveySuccess from '@/views/Table/FormView/SurveySuccess.vue';
import MsTeamsConfiguration from '@/views/msTeams/MsTeamsConfiguration.vue';
import MsTeamsLogin from '@/views/msTeams/MsTeamsLogin.vue';
import ListCalendarView from '@/views/Table/CalendarView/ListCalendarView.vue';
import { List } from '@/models/List';

Vue.use(VueRouter);

// helper methods

export class RouterUtils {

    //see https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
    public static pushRouteTo(component: Vue, route: any) {
        component.$router.push(route)
            .catch(failure => {
                if (failure.name !== 'NavigationDuplicated') {
                    throw failure;
                }
            });
    }

    public static parseMyTablesParams(route: any) {
        var mode: string | null = null;
        var folderId = route.params.folderId ? route.params.folderId : route.query.organizationParentFolderId;
        var organizationId = route.params.organizationId ? route.params.organizationId : route.query.key;
        if (organizationId)
            organizationId = (<string>organizationId).toLowerCase().replace(/-/g, '');
        else //retrieve default one
        {
            organizationId = ListTreeModule.currentOrganizationId;
        }
        if (folderId) {
            if (folderId == 'Recents' || folderId == 'Favorites') {
                mode = folderId;
                folderId = null;
            } else {
                folderId = (<string>folderId).toLowerCase().replace(/-/g, '');
            }
        }

        return {
            folderId: folderId,
            organizationId: organizationId,
            lmCurrent: mode,
            breadcrumbTag: mode
        };
    }

    public static getCaseInsentiveQuery(route: Route, paramName: string) {
        paramName = paramName.toLowerCase();
        for (var prop in route.query) {
            if (route.query.hasOwnProperty(prop) && prop.toLowerCase() == paramName)
                return route.query[prop];
        }
    }
}


// route definitions

const routes: RouteConfig[] =
    [
        { path: '/', redirect: '/MyTables' },
        {
            path: '/Login(.html)?',
            name: 'Login',
            component: Login,
            props: (route: any) => ({
                returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl") ??
                    (RouterUtils.getCaseInsentiveQuery(route, "__url__") ? decodeURIComponent(<string>RouterUtils.getCaseInsentiveQuery(route, "__url__")) : undefined),
                emailFromSignUp: route.params.emailFromSignUp,
                inviteLinkId: route.params.inviteLinkId,
            }),
            meta: {
                layout: "LogInLayout",
                allowsEmptyOrganization: true
            }
        },
        {
            path: '/LoginFba(.html)?',
            name: 'LoginFba',
            component: LoginFba,
            props: (route: any) => ({
                returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl") ??
                    (RouterUtils.getCaseInsentiveQuery(route, "__url__") ?
                        decodeURIComponent(<string>RouterUtils.getCaseInsentiveQuery(route, "__url__")) : null)
            }),
            meta: { layout: "IframeLayout" }
        },
        {
            path: '/LoginFbaSuccess(.html)?',
            name: 'LoginFbaSuccess',
            component: PageLoadSpinner,
            props: (route: any) => ({ returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl") }),
            meta: { layout: "IframeLayout" }
        },
        {
            path: '/LoginMS(.html)?',
            name: 'LoginMS',
            component: LoginMS,
            props: (route: any) => ({ returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl") }),
            meta: { layout: "IframeLayout", notOnPrem: true }
        },
        {
            path: '/MyTables(.html)?/:organizationId?/:folderId?',
            name: "MyTables",
            component: MyTables,
            props: RouterUtils.parseMyTablesParams,
            meta: {
                requiresAuth: true,
                pathProvider: RouterUtils.parseMyTablesParams,
                isBreadcrumbVisible: true,
                isFullBreadcrumb: true,
                breadcrumbTagInUrl: true
            }
        },
        {
            path: '/CreateTable',
            name: 'CreateTable',
            component: CreateTable,
            props: (route: any) => ({
                folderId: route.query.folderId,
                organizationId: route.query.organization,
                category: route.query.category,
            }),
            meta: {
                isBreadcrumbVisible: true,
                isFullBreadcrumb: true,
                allowsEmptyOrganization: false,
                pathProvider: (route: any) => ({
                    folderId: route.query.folderId,
                    organization: route.query.organization
                }),
            }
        },
        { path: '//t/:listId', redirect: '/t/:listId' },
        { path: '/td/:listId', redirect: '/Template/:listId' },
        { path: '/tc/:listId', redirect: '/Template/:listId' },
        { path: '/tf/:listId/:rowId', redirect: '/t/:listId/form/:rowId' },
        {
            path: '/V2/MsTeamsTable(.html)?',
            redirect: (route: any) => {
                // the function receives the target route as the argument
                // we return a redirect path/location here.
                return { path: '/t/' + RouterUtils.getCaseInsentiveQuery(route, "key"), query: { msteams: true } }
            }
        },
        { path: '/V2/MsTeamsConfiguration(.html)?', redirect: { name: 'TeamsConfiguration' } },
        {
            path: '/V2/MsTeamsSilentLogin(.html)?', redirect: (route: any) => {
                return { name: 'TeamsLogin', query: { returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl") } }
            }
        },
        {
            path: '/msteams/configuration',
            name: 'TeamsConfiguration',
            component: MsTeamsConfiguration,
            meta: { requiresAuth: false, layout: 'IframeLayout' }
        },
        {
            path: '/msteams/login',
            name: 'TeamsLogin',
            component: MsTeamsLogin,
            props: (route: any) => ({
                returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl")
            }),
            meta: { requiresAuth: false, layout: 'IframeLayout' }
        },
        {
            path: '/t/:listId',
            component: ListBasedRoot,
            props: (route: any) => ({
                listId: route.params.listId,
            }),
            children: [
                {
                    path: '',
                    name: 'Table',
                    component: ListDisplayModeSelector,
                    props: (route: any) => ({
                        listId: route.params.listId,
                        currentRowId: route.query.currentRowId,
                        highlightRows: RouterUtils.getCaseInsentiveQuery(route, "highlightrow")
                    }),
                    meta: {
                        isBreadcrumbVisible: true,
                        isFullBreadcrumb: true,
                        pathProvider: (route: any) => ({
                            listId: route.params.listId,
                        }),
                    },
                    beforeEnter: (to, from, next) => {
                        // query string param for both GA trackings and preventing infinite loop
                        if (Utils.getCookie("rsVersion") == "2" && !to.query.rsVersionError) {
                            window.location.href = "/t/" + to.params.listId + "?rsVersionError=true";
                        } else {
                            next();
                        }
                        if (RouterUtils.getCaseInsentiveQuery(to, "msteams") && to.meta) {
                            to.meta.layout = 'MsTeamsLayout';
                        }
                    }
                },
                {
                    path: 'tableprint',
                    name: 'TablePrint',
                    component: TablePrint,
                    props: true,
                    meta: {
                        isBreadcrumbVisible: false,
                        layout: "IframeLayout",
                        pathProvider: (route: any) => ({
                            listId: route.params.listId,
                        })
                    }
                },
                {
                    path: 'calendar/:startColumnId',
                    name: 'Calendar',
                    component: ListCalendarView,
                    props: (route: any) => ({
                        listId: route.params.listId,
                        startColumnId: route.params.startColumnId
                    }),
                    meta: {
                        isBreadcrumbVisible: true,
                        isFullBreadcrumb: true,
                        pathProvider: (route: any) => ({
                            listId: route.params.listId
                        })
                    }
                },
                {
                    path: 'form/:rowId',
                    name: 'Form',
                    component: ListFormView,
                    props: (route: any) => ({
                        listId: route.params.listId,
                        rowId: route.params.rowId,
                        showDetails: (route.params.mode === 'details')
                    }),
                    meta: {
                        isBreadcrumbVisible: true,
                        isFullBreadcrumb: false,
                        showListInBreadcrumb: true,
                        pathProvider: (route: any) => ({
                            listId: route.params.listId,
                        }),
                    },
                },
                {
                    path: '/survey/:listId',
                    name: 'Survey',
                    component: Survey,
                    props: (route: any) => ({
                        listId: route.params.listId,
                    }),
                    meta: {
                        allowsEmptyOrganization: true,
                        isBreadcrumbVisible: false,
                        pathProvider: (route: any) => ({
                            listId: route.params.listId,
                            allowSurvey: true
                        }),
                        isSurvey: true // using meta because parent route won't map property from children routes 
                    }
                },

            ]
        },
        {
            path: '/surveysuccess/:listId',
            name: 'SurveySuccess',
            component: SurveySuccess,
            props: (route: any) => ({
                listId: route.params.listId,
            }),
            meta: {
                isBreadcrumbVisible: false,
            }
        },
        {
            path: '/Contact',
            name: 'Contact',
            component: Contact,
            props: true,
            meta: {
                isBreadcrumbVisible: true,
            }
        },
        {
            path: '/MyAccount',
            name: 'MyAccount',
            component: MyAccount,
            meta: {
                requiresAuth: true,
                isBreadcrumbVisible: true,
                allowsEmptyOrganization: true
            }
        },
        { path: '/business-trial', redirect: { name: 'SignUp' } },
        {
            path: '/SignUp',
            name: 'SignUp',
            component: SignUp,
            props: (route: any) => ({
                returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl"),
                freeTrial: RouterUtils.getCaseInsentiveQuery(route, "trial")
            }),
            meta: {
                layout: "SignupLayout"
            }
        },
        {
            path: '/join/:inviteLinkId',
            name: 'Join',
            component: SignUp,
            props: (route: any) => ({
                inviteLinkId: route.params.inviteLinkId ? route.params.inviteLinkId : RouterUtils.getCaseInsentiveQuery(route, "inviteLinkId"),
                returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl")
            }),
            meta: {
                layout: "SignupLayout"
            }
        },
        {
            path: '/newsubscription',
            name: 'NewSubscription',
            component: SignUp,
            props: (route: any) => ({
                returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl"),
            }),
            meta: {
                requiresAuth: true,
                notOnPrem: true
            }
        },
        {
            path: '/MyDocuments',
            name: 'MyDocuments',
            component: MyDocuments,
            meta: {
                requiresAuth: true
            },
            props: (route: any) => ({
                returnUrl: RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl")
            })
        },
        {
            path: '/SubscriptionCheckOut/:organizationId',
            name: 'SubscriptionCheckOut',
            component: SubscriptionCheckOut,
            props: (route: any) => ({
                organizationId: route.params.organizationId,
                returnUrl: route.params.returnUrl ? route.params.returnUrl : RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl")
            }),
            meta: {
                requiresAuth: true,
                pathProvider: (route: any) => ({
                    organizationId: route.params.organizationId,
                }),
                isBreadcrumbVisible: true,
                isFullBreadcrumb: false,
                notOnPrem: true
            }
        },
        {
            path: '/Unsubscription/:userId',
            name: 'Unsubscription',
            component: Unsubscription,
            props: (route: any) => ({
                userId: route.params.userId
            }),
            meta: {
                notOnPrem: true
            }
        },
        {
            path: '/TableOwners/:organizationId/:listId',
            name: 'TableOwners',
            props: true,
            component: TableOwners,
            meta: { layout: "IframeLayout" }
        },
        {
            path: '/TableAccessDenied/:listId?',
            name: 'TableAccessDenied',
            component: TableAccessDenied,
            props: (route: any) => {
                var listId = route.params.listId ? route.params.listId : route.query.key;
                if (listId)
                    listId = (<string>listId).toLowerCase().replace(/-/g, '');
                return { listId: listId };
            },
            meta: { layout: "ErrorLayout" }
        },
        {
            path: '/OrganizationAccessDenied',
            name: 'OrganizationAccessDenied',
            component: OrganizationAccessDenied,
            meta: { layout: "ErrorLayout" }
        },
        {
            path: '/TableSettings/:listId?',
            name: 'TableSettings',
            component: TableSettings,
            props: (route: any) => {
                var listId = route.params.listId ? route.params.listId : route.query.key;
                if (listId)
                    listId = (<string>listId).toLowerCase().replace(/-/g, '');
                return { listId: listId };
            },
            meta: {
                requiresAuth: true,
                isBreadcrumbVisible: true,
                isFullBreadcrumb: true,
                pathProvider: (route: any) => ({
                    listId: route.params.listId,
                }),
            }
        },
        {
            path: '/tjf/:listId',
            name: 'RowMerge',
            component: RowMerge,
            props: (route: any) => {
                var listId = route.params.listId;
                if (listId)
                    listId = (<string>listId).toLowerCase().replace(/-/g, '');
                return { listId: listId };
            },
            meta: {
                requiresAuth: true,
                isBreadcrumbVisible: true,
                isFullBreadcrumb: true,
                pathProvider: (route: any) => ({
                    listId: route.params.listId,
                }),
            }
        },
        {
            path: '/tcs/:listId/:columnId',
            name: 'ColumnSettings',
            component: ColumnSettings,
            props: (route: any) => ({
                listId: route.params.listId,
                columnId: route.params.columnId,
                returnUrl: route.params.returnUrl ? route.params.returnUrl : RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl")
            }),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/ConfirmEmail(.html)?/:token?',
            name: 'ConfirmEmail',
            component: ConfirmEmail,
            props: (route: any) => {
                var token = route.params.token ? route.params.token : route.query.data;
                if (token)
                    token = (<string>token).toLowerCase().replace(/-/g, '');
                return {
                    token: token
                };
            },
            meta: {
                allowsEmptyOrganization: true
            }
        },
        {
            path: '/ResetPasswordRequest/',
            name: 'ResetPasswordRequest',
            component: ResetPasswordRequest,
        },
        {
            path: '/ResetPassword(.html)?/:token?',
            name: 'ResetPassword',
            component: ResetPassword,
            props: (route: any) => {
                var token = route.params.token ? route.params.token : route.query.data;
                if (token)
                    token = (<string>token).toLowerCase();
                return {
                    token: token
                };
            },
        },
        {
            path: '/ManageOrganization(.html)?/:organizationId?',
            name: 'ManageOrganization',
            component: ManageOrganization,
            props: (route: any) => ({
                organizationId: route.params.organizationId ? route.params.organizationId : route.query.key,
                returnUrl: route.params.returnUrl ? route.params.returnUrl : RouterUtils.getCaseInsentiveQuery(route, "ReturnUrl")
            }),
            meta: {
                requiresAuth: true,
                pathProvider: (route: any) => ({ organizationId: route.params.organizationId ? route.params.organizationId : route.query.key }),
                isBreadcrumbVisible: true,
                isFullBreadcrumb: false,
                breadcrumbTag: "Settings"
            }
        },
        {
            path: '/bo/ListAccessAudit/:organizationId',
            name: 'ListAccessAudit',
            component: ListAccessAudit,
            props: true,
            meta: {
                layout: "BackOfficeLayout",
                requiresAuth: true,
                isBO: true,
            }
        },
        {
            path: '/403',
            name: '403',
            component: AccessDenied,
            meta: {
                layout: "ErrorLayout",
            }
        },
        {
            path: '/bo/organization/:organizationId',
            name: 'BackOfficeOrganization',
            component: BackOfficeOrganization,
            props: true,
            meta: {
                layout: "BackOfficeLayout",
                requiresAuth: true,
                isBO: true,
            }
        },
        {
            path: '/bo/organization/limits/:organizationId',
            name: 'BackOfficeOrganizationLimits',
            component: BackOfficeOrganizationLimits,
            props: true,
            meta: {
                layout: "BackOfficeLayout",
                requiresAuth: true,
                isBO: true,
            }
        },
        {
            path: '/bo/user/:userId',
            name: 'BackOfficeUser',
            component: BackOfficeUser,
            props: true,
            meta: {
                layout: "BackOfficeLayout",
                requiresAuth: true,
                isBO: true
            }
        },
        {
            path: '/bo',
            name: 'bo',
            component: BackOffice,
            meta: {
                layout: "BackOfficeLayout",
                requiresAuth: true,
                isBO: true,
            }
        },
        {
            path: '*',
            name: 'PageNotFound',
            component: PageNotFound,
            meta: {
                layout: "ErrorLayout",
            }
        },
    ];

const router = new VueRouter({
    mode: 'history',
    routes: routes
});

router.beforeEach(
    async (to, _from, next) => {

        // mobile app detection
        if (to.query.scope == 'mobileapp') {
            Cookie.set('IsMobile', 'true');
            var isMobileCookie = Cookie.get('IsMobile');
            let isInMobileApp = isMobileCookie ? (isMobileCookie.toLowerCase() == "true") : false;
            ApplicationModule.updateDevice(isInMobileApp);
        }

        await UserModule.refreshUserIfMissing();

        // handling demo user
        if (to.query.demo && UserModule.CurrentUser.IsAnonymous) {
            await UserModule.DemoSignIn(<string>to.query.demo);
            await UserModule.refreshUser();
        }

        // load only needed user language / browser language if no user connected
        await loadAndSetWithUserLanguageAsync();

        ApplicationModule.updateAppPreviousLocation('');

        // load org logo if orgid in querystring on login page
        var orgid = <string>RouterUtils.getCaseInsentiveQuery(to, "orgId");
        if (!to.meta?.requiresAuth && UserModule.CurrentUser.IsAnonymous && orgid) {
            ListTreeModule.updateCurrentSiteLogos(orgid)
        }
        else {
            ListTreeModule.updateCurrentSiteLogos(null);
        }

        // start loading the listtrees and update the current org and folder if provided by the route
        ListTreeModule.refreshOrganizationsTreeIfMissingOrOutdated()
            .then(() => {
                var route = to.matched.length > 1 ? to.matched.filter(r => !!(r.parent))[0] : to.matched[0];
                if (!route || !route.meta || !route.meta.pathProvider || !route.meta.pathProvider(to))
                    return;
                var path = route.meta.pathProvider(to);
                if (path.listId)
                    RouteToList(path, next, to);
                else if (!UserModule.CurrentUser.IsAnonymous) {
                    if (path.organizationId)
                        RouteToOrg(path, next, to);
                }
            });

        if (to.query.errorPage === '404') {
            next({ name: '404' });
        }

        //if we are on the OnPrem variant we do not have access to some routes
        if (to.matched.some(route => route.meta.notOnPrem)) {
            if (OnPremModule.isOnPrem) {
                next({ name: '403' });
            }
        }

        //If the route requires authentication, a query to the store is done to know if there is a connected user or not.
        //If the route doesn't allow users without organizations, the user is redirected to the signup 
        if (to.matched.some(route => route.meta.requiresAuth)) {
            var userDetails = UserModule.CurrentUser;

            if (to.matched.some(route => route.meta.isBO) && !userDetails.IsAdministrator)
                next({ name: 'PageNotFound' });

            let isLoggedIn = !userDetails.IsAnonymous;
            if (isLoggedIn) {
                if (!to.matched.some(route => route.meta.allowsEmptyOrganization)) {
                    if (userDetails && userDetails.OrganizationsCount == 0)
                        next({ name: 'SignUp', query: { ReturnUrl: to.path } });
                    else
                        next();
                }
                else
                    next();
            }
            else {
                next({ name: 'Login', query: { ReturnUrl: to.path } });
            }
        }
        else {
            if (to.matched.some(route => route.meta.allowsEmptyOrganization === false)) {
                var userDetails = UserModule.CurrentUser;
                let isLoggedIn = !userDetails.IsAnonymous;

                if (isLoggedIn && userDetails && userDetails.OrganizationsCount == 0)
                    next({ name: 'SignUp', query: { ReturnUrl: to.path } });
                else
                    next();
            }
            else {
                next();
            }
        }
    }
);


function RouteToOrg(path: any, next: any, to: Route) {
    {
        ListTreeModule.refreshOrganizationListTreeIfMissingOrOutdated(path.organizationId)
            .then(() => {
                ListTreeModule.updateCurrentFolder({ organizationId: path.organizationId, folderId: path.folderId });
            })
            .catch((e: RowShareException) => {
                if (e.httpResponse?.status == 404) {
                    next({ name: 'OrganizationAccessDenied' });
                } else if (e.httpResponse?.status == 401 || e.httpResponse?.status == 403) {
                    if (!UserModule.CurrentUser.IsAnonymous)
                        next({ name: 'OrganizationAccessDenied' });

                    else
                        next({ name: 'Login', query: { ReturnUrl: to.path } });
                };
            });
    }
}

function RouteToList(path: any, next: any, to: Route) {
    {
        ListTreeModule.updateCurrentList({ listId: path.listId, allowSurvey: path.allowSurvey })
            .then((list: List | null) => {
                if (!to.meta?.isSurvey)
                    ListTreeModule.refreshList(list);
                else
                    ListTreeModule.refreshListPathAndStore(list!);
            })
            .catch((e: RowShareException) => {
                if (e.httpResponse?.status == 404) {
                    next({ name: 'PageNotFound' });
                } else if (e.httpResponse?.status == 401 || e.httpResponse?.status == 403) {
                    if (!UserModule.CurrentUser.IsAnonymous)
                        next({ name: 'TableAccessDenied', params: { listId: path.listId } });

                    else
                        next({ name: 'Login', query: { ReturnUrl: to.path } });
                }
            });
    }
}

export default router;