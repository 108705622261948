
    import {Vue, Component, Prop } from 'vue-property-decorator';
    import * as API from '@/api/Api';
    import * as Models from '@/models/RowShare';
    import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
    import OrganizationSettings from "@/views/ManageOrganization/OrganizationSettings.vue";
    import OrganizationInfos from "@/views/ManageOrganization/OrganizationInfos.vue";
    import Groups from "@/views/ManageOrganization/Groups.vue";
    import Members from '@/views/ManageOrganization/Members.vue';
    import { ListAccessAuditAccesLevel, ListAccessAuditList } from '@/models/RowShare';
    import i18n from '@/modules/Localization';
import { Utils } from '@/utils/Utilities';

    @Component({
        name: 'ListAccessAudit',
        components: {PageLoadSpinner, OrganizationSettings, OrganizationInfos, Groups, Members}
    })
    export default class ListAccessAudit extends Vue{
        @Prop() organizationId!: string;

        audit: Models.ListAccessAudit | null = null;
        tree: TreeNodeVM[] | null = null;

        search: string | null = null;

        async created() {
            this.audit = await API.ListAccessAudit.load(this.organizationId);

            var tree = [];

            if(this.audit?.Admins) {
                var adminsNode = new TreeNodeVM();

                adminsNode.id = "admins";
                adminsNode.name = this.$i18n.t("ListAccessAudit_Admins").toString();
                adminsNode.search = adminsNode.name;
                adminsNode.icon = "fa fa-crown";
                adminsNode.children = this.audit.Admins.map(a => TreeNodeVM.fromMember(a, 'admins'));

                tree.push(adminsNode);
            }

            if(this.audit?.UniversalOwners) {
                var universalOwnersNode = new TreeNodeVM();

                universalOwnersNode.id = "universal-owners";
                universalOwnersNode.name = this.$i18n.t("ListAccessAudit_UniversalOwners").toString();
                universalOwnersNode.search = universalOwnersNode.name;
                universalOwnersNode.icon = "fa fa-user-cog";
                universalOwnersNode.children = this.audit.UniversalOwners.map(a => TreeNodeVM.fromMember(a, "universal_owners"));

                tree.push(universalOwnersNode);
            }

            if(this.audit?.RootFolder)
                tree.push(TreeNodeVM.fromFolder(this.audit?.RootFolder));

            this.tree = tree;
        }

        filter (item: TreeNodeVM, search: string, textKey: string) {

            if(item.search.indexOf(search.toLowerCase()) > -1)
                return true;

            return false;
        }

        getAccessModeText(accessMode: ListAccessAuditAccesLevel | boolean) : string {
            if(Utils.isBoolean(accessMode)) {
                if(accessMode === true) {
                    return "Yes";
                }
                else {
                    return "No";
                }
            }
            if(accessMode === ListAccessAuditAccesLevel.All)
                return this.$i18n.t("ListAccessAudit_All").toString();
            else if(accessMode === ListAccessAuditAccesLevel.Owned)
                return this.$i18n.t("ListAccessAudit_Owned").toString();
            else if(accessMode === ListAccessAuditAccesLevel.None)
                return this.$i18n.t("ListAccessAudit_None").toString();
            else
                return this.$i18n.t("ListAccessAudit_Error").toString();
        }

        countReadAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanRead != ListAccessAuditAccesLevel.None).length;
        }
        countFullReadAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanRead == ListAccessAuditAccesLevel.All).length;
        }
        countPartialReadAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanRead == ListAccessAuditAccesLevel.Owned).length;
        }
        countCreateAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanCreate).length;
        }
        countUpdateAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanUpdate != ListAccessAuditAccesLevel.None).length;
        }
        countFullUpdateAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanUpdate == ListAccessAuditAccesLevel.All).length;
        }
        countPartialUpdateAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanUpdate == ListAccessAuditAccesLevel.Owned).length;
        }
        countSuppressAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanSuppress != ListAccessAuditAccesLevel.None).length;
        }
        countFullSuppressAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanSuppress == ListAccessAuditAccesLevel.All).length;
        }
        countPartialSuppressAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanSuppress == ListAccessAuditAccesLevel.Owned).length;
        }
        countAssignAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanAssign != ListAccessAuditAccesLevel.None).length;
        }
        countFullAssignAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanAssign == ListAccessAuditAccesLevel.All).length;
        }
        countPartialAssignAccess(table: ListAccessAuditList) : number {
            return table.Accesses.filter(t => t.CanAssign == ListAccessAuditAccesLevel.Owned).length;
        }

        expandAll() {
            (<any>this.$refs.treeview).updateAll(true); 
        }

        collapseAll() {
            (<any>this.$refs.treeview).updateAll(false); 
        }

        getCsvUrl() {
            return "/api/listaccessaudit/export/" + this.organizationId;
        }

        getJsonUrl() {
            return "/api/listaccessaudit/load/" + this.organizationId;
        }

        getYamlUrl() {
            return "/api/listaccessaudit/load/" + this.organizationId + "?f=yaml";
        }
    }

    class TreeNodeVM {
        id!: string;
        name!: string;
        url: string | null = null;
        icon!: string;
        openedIcon: string | null = null;
        title: string | null = null;
        children!: TreeNodeVM[] | null;
        search!: string;

        table: Models.ListAccessAuditList | null = null;
        member: Models.ListAccessAuditMember | null = null;
        access: Models.ListAccessAuditAccess | null = null;

        static fromFolder(folder: Models.ListAccessAuditFolder) : TreeNodeVM  {
            var res = new TreeNodeVM();
            
            res.id = folder.Id;
            res.name = folder.Name;
            res.search = folder.Name.toLowerCase();
            res.icon = 'fas fa-folder';
            res.openedIcon = 'fas fa-folder-open';

            res.children = [];
            res.children.push(...folder.Folders.map(f => TreeNodeVM.fromFolder(f)));
            res.children.push(...folder.Tables.map(t => TreeNodeVM.fromTable(t)));

            return res;
        }
        
        static fromTable(table: Models.ListAccessAuditList) : TreeNodeVM {
            var res = new TreeNodeVM();
            res.id = table.Id;
            res.name = table.Name;
            res.search = table.Name.toLowerCase() + '|' + table.Id;
            res.url = table.Url;
            res.icon = 'fal fa-table';
            res.table = table;
            res.children = [];

            if(table.ExternalAccess) {
                var ext = new TreeNodeVM();
                ext.id = table.Id + "_ext";
                ext.name = i18n.t("ListAccessAudit_ExternalAccess").toString();
                ext.search = ext.name.toLowerCase();
                ext.icon = "fal fa-globe";
                ext.title = i18n.t("ListAccessAudit_ExternalAccessDescription").toString();
                res.children.push(ext);
            }

            res.children.push(...table.Accesses.map(acc => TreeNodeVM.fromAccess(acc, table.Id)));

            return res;
        }

        static fromMember(member: Models.ListAccessAuditMember, idPrefix: string | null) : TreeNodeVM {
            var res = new TreeNodeVM();

            res.id = (idPrefix ? idPrefix : '') + "_" + member.Email;
            res.name = member.Name;
            res.icon = 'fal fa-user';
            res.children = null;
            res.member = member;

            res.search = (member.Name?.toLowerCase() ?? "") + "|" + member.Email.toLowerCase();

            return res;
        }

        static fromAccess(access: Models.ListAccessAuditAccess, tableId: string) : TreeNodeVM {
            var res = TreeNodeVM.fromMember(access.Member, tableId);
            res.access = access;
            return res;
        }
    }
