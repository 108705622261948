
    import { Component, Prop } from "vue-property-decorator";
    import { OnPremModule } from '@/store/OnPremStore';
    import GoogleLoginButton from '@/views/Signin/GoogleLoginButton.vue';
    import MicrosoftLoginButton from '@/views/Signin/MicrosoftLoginButton.vue';
    import { ReturnUrlHandler } from "@/views/ReturnUrl";

    @Component({
        name: "SocialLoginList",
        components: { 
            GoogleLoginButton, MicrosoftLoginButton
        }
    })
    export default class SocialLoginList extends ReturnUrlHandler {
        @Prop({default: false}) large!:boolean;
        @Prop({default: false}) isSignUp!: boolean;
        @Prop() inviteLinkId!: string;

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;;
        }
    }
