
import {Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Location }  from 'vue-router';
import { ListTreeModule } from '@/store/ListTreeStore'
import { CopyListOptions, CopyListInfo, List, ListTreeFolder, Organization, Folder, AccessMode } from '@/models/RowShare';
import { ListTreeHelper } from '@/utils/ListTreeHelper';
import * as Events from '@/models/Event';
import * as API from '@/api/Api';
import { Utils } from '@/utils/Utilities';
import { RowShareException } from '@/api/ApiUtils';
import { EventBus } from "@/modules/EventBus";
import { RouterUtils} from "@/modules/Router";

@Component({ name:'CopyListDialog' })
export default class CopyListDialog extends Vue {

    @Prop() listId!: string;
    @Prop() organizationId!:string;
    @Prop() folder!:ListTreeFolder;

    //When the modal is open, the destination folder is the current folder. It is changed when a new folder is selected from the list
    private destination = {folderId: '', organizationId: ''};
    processingAction: boolean = false;
    errorMessage: string = "";
    selectedAction: string = "";
    actionCopyWithRows: string = "CopyWithRows";
    actionCopyWithoutRows: string = "CopyWithoutRows";
    actionMove: string = "Move";
    selectedOrganization: Organization | null = null;
    selectedFolder: Folder | null = null;
    organizations: Organization[] = [];
    folders: Folder[] = [];
    loadingOrganizations: boolean = false;
    loadingFolders: boolean = false;
    openModalCopy: boolean = false;
    currentList: List | null = null;

    
    created() {
        EventBus.$on(Events.Event.LIST_COPIEDORMOVED, this.onCopyMoveDone );
    }

    destroyed() {
        EventBus.$off(Events.Event.LIST_COPIEDORMOVED);
    }

    get listName() {
        if(this.currentList) {
            return this.currentList.DisplayName;
        }
        return "";
    }
    get isListOwned() {
        if(this.currentList) {
            return this.currentList.Owned;
        }
        return false;
    }

    get isListInRecycleBin() {
        if(this.currentList) {
            return this.currentList.IsInRecycleBin;
        }
        return false;
    }

    get isCopyInOtherSubscription () : boolean {
        return this.selectedOrganization?.Id !== this.currentList?.OrganizationId;
    }

    openModalToCopyMove(){
        this.refreshCurrentList().then(() => {
            setTimeout(()=> {
                this.selectedAction = this.isListInRecycleBin ? this.actionMove : this.actionCopyWithRows;
                this.openModalCopy = true;
                this.initOrgsAndFolders();
            }, 0);
        });
    }

    @Watch('listId')
    async refreshCurrentList() {
        this.currentList = null;
        if(this.listId) {
            this.currentList = await API.List.load(this.listId);
        }
    }


    get actionButtonText(): string {
        switch(this.selectedAction) {
            case this.actionCopyWithoutRows:
            case this.actionCopyWithRows:
                return this.$i18n.t('CopyListDialog_Copy').toString();
            case this.actionMove:
                if(this.isListInRecycleBin) {
                    return this.$i18n.t('CopyListDialog_RestoreList').toString();
                }
                else {
                    return this.$i18n.t('CopyListDialog_Move').toString();
                }
            default:
                return "";
        }
    }

    get title(): string {
        if(this.isListOwned && !this.isListInRecycleBin) {
            return this.$i18n.t('CopyListDialog_Title').toString();
        }
        else if(this.isListOwned && this.isListInRecycleBin) {
            return this.$i18n.t('MyTables_Actions_RestoreTable').toString();
        }
        else {
            return this.$i18n.t('MyTables_Actions_DuplicateTable').toString();
        }

    }

    get canCopyMoveTable(): boolean {
        return !Utils.isNullOrWhiteSpace(this.selectedAction) && !Utils.isNullOrWhiteSpace(this.destination?.folderId) && !Utils.isNullOrWhiteSpace(this.destination?.organizationId);
    }

    @Watch('selectedOrganization')
    organizationChanged() {
        this.selectedFolder = null;
        this.folders = [];
        if(!this.selectedOrganization) {
            return;
        }
        this.loadFolders();
    }

    @Watch('selectedFolder')
    folderChanged() {
        if(!this.selectedFolder || !this.selectedOrganization) {
            return;
        }
        this.destination = {organizationId: this.selectedOrganization?.Id, folderId: this.selectedFolder.Id};
    }

    async initOrgsAndFolders() {
        this.organizations = [];
        this.folders = [];
        this.selectedOrganization = null;
        this.loadingOrganizations = true;
        API.Organization.loadall({cache: false})
            .then((orgs) => {
                if(orgs) {
                    this.organizations = orgs;
                    if((this.organizations?.length ?? 0) == 1) {
                        this.selectedOrganization = this.organizations[0];
                    }
                    if(ListTreeModule.currentOrganization) {
                        this.selectedOrganization = this.organizations.find(o => o.Id === ListTreeModule.currentOrganization?.Id) ?? null;
                    }
                }
                this.folders = [];
                this.selectedFolder = null;
                if(this.selectedOrganization) {
                    this.loadFolders();
                }
            })
            .finally(() => this.loadingOrganizations = false);
    }

    loadFolders() {
        this.folders = [];
        this.loadingFolders = true;
        if(!this.selectedOrganization) {
            return;
        }
        API.Folder.loadAllForOrganization(AccessMode.ReadOwnedOnly, this.selectedOrganization?.Id, {cache: false})
            .then(folders => {
                if(folders) {
                    this.folders = folders;
                    if(this.folders.length == 1) {
                        this.selectedFolder = this.folders[0];
                    }
                }
            })
            .finally(() => this.loadingFolders = false);
    }

    processAction() {
        if(!this.canCopyMoveTable) {
            return;
        }
        switch(this.selectedAction) {
            case this.actionCopyWithoutRows:
            case this.actionCopyWithRows:
                this.copyList();
                break;
            case this.actionMove:
                this.moveList();
                break;
        }
    }

    onCancel(){
        this.openModalCopy = false;
        this.processingAction = false;
        this.errorMessage = "";
        this.selectedFolder = null;
        this.selectedOrganization = null;
        this.folders = [];
        this.organizations = [];
    }

    onCopyMoveDone(newList: List | null | undefined, refresh: boolean | false, otherOrg: boolean | false) {
        if(newList && refresh) {
                if (otherOrg){
                    ListTreeHelper.resetLocalStoreOrganizationsTree();
                    ListTreeHelper.resetLocalStoreCurrentOrganization();
                }
                var newRoute : Location | string;
                if (newList.FolderId && newList.OrganizationId)
                    newRoute = { name: 'MyTables', params: { organizationId: newList.OrganizationId, folderId: newList.FolderId } };
                else
                    newRoute = '/t/' + newList.Id;
                RouterUtils.pushRouteTo(this, newRoute);
        }
        this.openModalCopy = false;
        this.processingAction = false;
        this.errorMessage = "";
        
    }

    getCopyListInfo(): CopyListInfo {
        let options = new CopyListOptions();
        options.CopyReports = true;
        options.CreateNotifications = true;
        options.RowsAutoHeight = this.currentList?.RowsAutoHeight ?? true;
        options.CopyRows = (this.selectedAction == this.actionCopyWithRows || this.selectedAction == this.actionMove);

        let copyInfo = new CopyListInfo();
        copyInfo.Id = this.listId;
        copyInfo.FolderId = this.destination.folderId;
        copyInfo.Options = options;
        return copyInfo;
    }

    async copyList(){
        this.processingAction = true;
        this.errorMessage = "";
        let other = this.isCopyInOtherSubscription;
        let newList = await ListTreeModule.cloneList({copyInfo: this.getCopyListInfo(), organizationId: this.destination.organizationId})
            .catch((err: any) => {
                this.errorMessage = (<RowShareException>err)?.message;
            })
            .finally(() => this.processingAction = false);
        if(!this.errorMessage) {
            EventBus.$emit(Events.Event.LIST_COPIEDORMOVED, newList, true, other);
        }
    }

    async moveList(){
        this.processingAction = true;
        let listToMoveData = new List();
        listToMoveData.Id = this.listId;
        listToMoveData.FolderId = this.destination.folderId;
        listToMoveData.OrganizationId = this.destination.organizationId;
        let other = this.isCopyInOtherSubscription;
        let deleteInfo = {
            listId:this.listId,
            folderId:this.folder.Id,
            organizationId:this.organizationId
        };
        let allInfoToMove = {
            listToMove: listToMoveData,
            moveFrom:deleteInfo
        }
        this.errorMessage = "";
        let newList = await ListTreeModule.moveList(allInfoToMove)
            .catch((err: any) => {
                this.errorMessage = (<RowShareException>err)?.message;
            })
            .finally(() => this.processingAction = false);
        if(!this.errorMessage) {
            //when a list is moved we need to move away from obsolete one to new one
            EventBus.$emit(Events.Event.LIST_COPIEDORMOVED, newList, true, other);
        }
    }
}
