
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import * as RowShare from '@/models/RowShare';
    import ListVM from '@/viewModels/Table/listVM';
    import RowVM from '@/viewModels/Table/rowVM';
    import FormViewVM from '@/viewModels/Table/formViewVM';
    import { ColumnDescriptionVM } from '@/viewModels/Table/ColumnDescriptionVM';
    import ColumnDescription from "@/views/Table/ColumnDescription.vue"
    import TextFormEditor from '@/views/Table/cellsComponents/Text/TextFormEditor.vue';
    import RichTextFormEditor from '@/views/Table/cellsComponents/RichText/RichTextFormEditor.vue';
    import HyperlinkFormEditor from '@/views/Table/cellsComponents/Hyperlink/HyperlinkFormEditor.vue';
    import CommentFormEditor from '@/views/Table/cellsComponents/Comment/CommentFormEditor.vue';
    import DateTimeFormEditor from '@/views/Table/cellsComponents/DateTime/DateTimeFormEditor.vue';
    import EmailFormEditor from '@/views/Table/cellsComponents/Email/EmailFormEditor.vue';
    import BlobFormEditor from '@/views/Table/cellsComponents/Blob/BlobFormEditor.vue';
    import NumberFormEditor from '@/views/Table/cellsComponents/Number/NumberFormEditor.vue';
    import TimeFormEditor from '@/views/Table/cellsComponents/DateTime/TimeFormEditor.vue';
    import TwoStatesBooleanFormEditor from '@/views/Table/cellsComponents/TwoStateBoolean//TwoStatesBooleanFormEditor.vue';
    import LookUpFormEditor from '@/views/Table/cellsComponents/Lookup/LookUpFormEditor.vue';
    import ProxyBasedCardRenderer from '@/views/Table/CardView/ProxyBasedCardRenderer.vue';
    import CommentCardRenderer from '@/views/Table/cellsComponents/Comment/CommentCardRenderer.vue';
    import TwoStatesBooleanCardRenderer from '@/views/Table/cellsComponents/TwoStateBoolean/TwoStatesBooleanCardRenderer.vue'

    @Component({
        name: 'RowFormView',
        components: {
            ColumnDescription, ProxyBasedCardRenderer, CommentCardRenderer, TwoStatesBooleanCardRenderer, 
            TextFormEditor, RichTextFormEditor, HyperlinkFormEditor, CommentFormEditor, DateTimeFormEditor, EmailFormEditor,
            BlobFormEditor, NumberFormEditor, TimeFormEditor, TwoStatesBooleanFormEditor, LookUpFormEditor
        }
    })
    export default class RowFormView extends Vue {

        @Prop() listVM!: ListVM;
        @Prop() rowVM!: RowVM;
        @Prop() colDesc!: ColumnDescriptionVM;
        @Prop() cellState!: string;
        @Prop() cellError!: string;
        @Prop() focus!: boolean;
        @Prop({default: false}) isInSurveyMode !: boolean;
        
        formViewVM!: FormViewVM;

        private showColumnDescription: boolean = false;

        getComponentName(colDesc: ColumnDescriptionVM) {
            if(!colDesc.currentColumn)
                return "";
                
            let typeName = colDesc.currentColumn.Type.toString();
            if (colDesc.currentColumn.isNumber) {
                typeName = 'Number';
            }
            else if(colDesc.currentColumn.isDateTimeFormat || colDesc.currentColumn.isDateFormat) {
                typeName = "DateTime";
            }
            else if((colDesc.currentColumn.isLookup)) {
                typeName = "LookUp";
            }
            else if(typeName == "File" || typeName == "Image") {
                typeName = "Blob";
            }
            return typeName + 'FormEditor';
        }
        
        columnRendererName(column : RowShare.Column){
            switch(column.Type){
                case RowShare.ColumnStrongType.Comment:
                    return 'CommentCardRenderer';
                case RowShare.ColumnStrongType.TwoStatesBoolean:
                    return 'TwoStatesBooleanCardRenderer';
                default:
                    return 'ProxyBasedCardRenderer';
            }
        }

        isCellAccessible(colDesc: ColumnDescriptionVM): boolean {
            return this.rowVM.rsRow?.Values[colDesc.columnTitle] !== RowVM.cellNotAccessible;
        }
    }
