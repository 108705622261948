
import {ColumnTypes} from '@/utils/ColumnTypes';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import * as Models from '@/models/RowShare';
import * as API from '@/api/Api';
import * as agGrid from 'ag-grid-community';
import ColumnVM from '@/viewModels/Table/columnVM';
import GridViewVM from '@/viewModels/Table/gridViewVM';
import {Location as RouterLocation} from 'vue-router';
import {RowShareException} from '@/api/ApiUtils';
import {EventBus} from '@/modules/EventBus';
import {UserModule} from '@/store/UserStore';
import {Utils} from '@/utils/Utilities';
import {ListTreeModule} from '@/store/ListTreeStore';
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

@Component({name: "AddColumnDialog"})
export default class AddColumnDialog extends Vue{
    @Prop() list: Models.List | undefined;
    @Prop() gridViewVM!: GridViewVM

    columnTypes: Models.ColumnType[] = [];
    columnTypeCategories: Models.ColumnTypeCategory[] = [];
    showMe: boolean = false;
    selectedCategory: string = Models.ColumnTypeCategoryName.frequent;
    selectedType: Models.ColumnType | null = null;
    addingColumn: boolean = false;
    columnTitle: string = "";
    errorMessage: string = "";
    intersectionObserver!: IntersectionObserver;
    autoScrolling: boolean = false;
    insertBeforeColumnId: string | null = null;

    get frequentTypes() {
        return this.columnTypes.filter(type => type.isFrequent);
    }

    get contentClass(): string {
        return this.isInsertColumn ? "insert-column-container" : "add-column-container";
    }

    get isInsertColumn(): boolean {
        return !Utils.isNullOrWhiteSpace(this.insertBeforeColumnId);
    }

    get hasRowMetadataSubscription() {
        return ListTreeModule.currentOrganization?.HasRowMetadatas ?? false;
    }

    getTypesForCategory(category: string) {
        if(category == Models.ColumnTypeCategoryName.frequent) {
            return this.frequentTypes;
        }
        return this.columnTypes.filter(type => type.Category == category);
    }

    created() {
        EventBus.$on(Models.Event.ADD_COLUMN, (params: Models.AddColumnEventParams) => {
            this.insertBeforeColumnId = params.insertBeforeColumnId;
            this.showMe = true;
        });
    }

    destroy() {
        EventBus.$off(Models.Event.ADD_COLUMN);
    }

    @Watch('showMe')
    initModal() {
        this.columnTypeCategories = ColumnTypes.getColumnTypeCategories();
        this.columnTypes = ColumnTypes.getColumnTypes(UserModule.connectedUser?.PreviewFeaturesEnabled ?? false, this.hasRowMetadataSubscription);
        this.selectedType = null;
        this.columnTitle = "";
        this.selectedCategory = Models.ColumnTypeCategoryName.frequent;
        this.errorMessage = "";
        if(this.showMe) {
            this.$nextTick(() => {
                this.$scrollTo('#'+ Models.ColumnTypeCategoryName.frequent, {container: '.types-container', force: true, offset: -100});
                let rootElem = document.getElementById("typesContainer");
                if(rootElem) {
                    if(!this.intersectionObserver){
                        this.intersectionObserver = new IntersectionObserver(
                            this.onIntersect,
                            {
                                root: rootElem,
                                rootMargin: "0px 0px -85% 0px"
                            }
                        );
                    }
                    this.columnTypeCategories.forEach(category => {
                        let elem = document.getElementById(category.Name);
                        if(elem) {
                            this.intersectionObserver.observe(elem);
                        }
                    });
                }
            });
        }
        else {
            if(this.intersectionObserver){
                this.intersectionObserver.disconnect();
            }
        }
    }

    onCancel() {
        this.showMe = false;
    }

    onIntersect(entries: IntersectionObserverEntry[]) {
        if(this.autoScrolling) {
            return;
        }
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                let target = entry.target;
                if(target) {
                    this.selectedCategory = target.id;
                }
            }
        })
    }

    get rootTypesContainer() {
        return document.querySelector('.types-container');
    }

    onSubmit() {
        if(!this.list){
            return;
        }
        if(!this.selectedType) {
            this.errorMessage = this.$i18n.t('AddColumnDialog_ColumnTypeMandatory').toString();
            return;
        }
        if(!this.columnTitle) {
            this.errorMessage = this.$i18n.t('AddColumnDialog_ColumnTitleMandatory').toString();
            (<HTMLElement>this.$refs.txColumnTitle).focus();
            return;
        }
        this.addingColumn = true;
        let newColumn = new Models.Column();
        newColumn.ListId = this.list.Id;
        newColumn.DisplayName = this.columnTitle;
        newColumn.Type = this.selectedType.StrongType;
        newColumn.Options = this.selectedType.Options;
        newColumn.LookupValues = this.selectedType.values;
        newColumn.DefaultValue = this.selectedType.defaultValue;
        if(this.insertBeforeColumnId) {
            var rsColBefore = <ColumnVM>this.gridViewVM.columnApi?.getColumn(this.insertBeforeColumnId)?.getDefinition();
            var agCol = this.gridViewVM.columnApi?.getColumn(this.insertBeforeColumnId);
            if(agCol) {
                var rsColBeforeBefore = <ColumnVM>this.gridViewVM.columnApi?.getDisplayedColBefore(agCol)?.getDefinition();
                if(rsColBeforeBefore.rsColumn?.ColumnGroup === rsColBefore.rsColumn.ColumnGroup) {
                    newColumn.ColumnGroup = rsColBefore.rsColumn.ColumnGroup;
                }
            }
            if(rsColBefore.rsColumn?.LeftPinned) {
                newColumn.LeftPinned = true;
            }
        }
        this.errorMessage = "";
        newColumn.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
        API.Column.save(newColumn).then(addedColumn => {
            if(addedColumn && this.list && this.gridViewVM.gridApi && this.gridViewVM.columnApi) {
                let addColVM = new ColumnVM(this.list, addedColumn);
                let colDefs = this.gridViewVM.gridApi.getColumnDefs();
                if(!colDefs)
                    return;

                if(addColVM.rsColumn.ColumnGroup) {
                    var group = <agGrid.ColGroupDef>colDefs.find(cd => (<any>cd)?.groupId === addColVM.rsColumn.ColumnGroup);
                    if(group) {
                        group.children.push(addColVM);
                    }
                }
                else {
                    colDefs.push(addColVM);
                }
                this.gridViewVM.listVM.columns?.push(addedColumn);
                this.gridViewVM.gridApi.setColumnDefs(colDefs);
                if(this.insertBeforeColumnId) {
                    var agCol = this.gridViewVM.columnApi.getColumn(addColVM.colId);
                    var agColBefore = this.gridViewVM.columnApi.getColumn(this.insertBeforeColumnId);
                    var agColBeforeIndex = this.gridViewVM.columnApi.getAllGridColumns().findIndex(c => c.getColId() == this.insertBeforeColumnId);
                    if(agCol && agColBeforeIndex > -1) {
                        this.gridViewVM.columnApi.moveColumn(agCol, agColBeforeIndex);
                    }
                    if(agCol && agColBefore && agColBefore.isPinned()) {
                        this.gridViewVM.columnApi.setColumnPinned(agCol, 'left');
                    }
                }
                this.addingColumn = false;
                this.showMe = false;
                if(this.selectedType?.mustBeConfigured){
                    setTimeout(() => {
                        if(this.list?.Id) {
                            EventBus.$emit(Models.Event.SHOW_SIDEPANEL_COLUMN_SETTINGS, 
                                <Models.showSidePanelColumnSettingsEventParams>{columnId: addColVM.colId, listId: this.list?.Id});
                        }
                    });
                }
                if(this.selectedType?.mustRefreshValuesFromServer) {
                    this.gridViewVM.listVM.refreshRowsFromServer();
                }
                let column = this.gridViewVM.columnApi.getColumn(addColVM.colId);
                if(column) {
                    this.gridViewVM.gridApi.ensureColumnVisible(addColVM.colId ?? "");
                    this.gridViewVM.gridApi.refreshCells({ columns: [column], force: true});
                    this.gridViewVM.gridApi.redrawRows();
                    EventBus.$emit(Models.Event.GRID_INFOS_CHANGED, this.gridViewVM);
                }
            }
        }).catch(ex => {
            let rsExc = ex as RowShareException;
            this.errorMessage = rsExc.message;
            this.addingColumn = false;
        });
    }

    onTypeSelected() {
        if(this.selectedType){
            this.errorMessage = "";
            this.$nextTick(() => (<HTMLElement>this.$refs.txColumnTitle).focus());
        }
    }

    get columnTitleIcon(): string {
        if(this.selectedType) {
            return this.selectedType.Icon;
        }
        return "fal fa-fw";
    }

    startAutoScrolling(elem: any) {
        this.autoScrolling = true;
    }

    doneAutoScrolling() {
        this.autoScrolling = false;
    }
}
