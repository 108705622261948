
import { Component, Vue, Watch } from 'vue-property-decorator';
import * as microsoftTeams from '@microsoft/teams-js';
import * as API from '@/api/Api';
import { AccessMode, CopyListInfo, CopyListOptions, Folder, List, Organization } from '@/models/RowShare';
import { ListTreeModule } from '@/store/ListTreeStore';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';

@Component({name: 'MsTeamsConfiguration', components: { PageLoadSpinner }})
export default class MsTeamsConfiguration extends Vue {
    userHasList: boolean = false;
    organizations: Organization[] = [];
    folders: Folder[] = [];
    lists: List[] = [];
    selectedOrganization: Organization | null = null;
    selectedFolder: Folder | null = null;
    selectedList: List | null = null;
    isDarkTeams: boolean = false;
    loadingFolders: boolean = false;
    loadingLists: boolean = false;
    creatingTemplateTable: boolean = false;
    templateTableCreated: boolean = false;
    errorMessage: string = "";

    mounted() {
        microsoftTeams.app.initialize();// .initialize();
        this.initOrgs();
        this.init();

        microsoftTeams.pages.config.registerOnSaveHandler(saveEvent => {
            if(!this.selectedList) {
                return;
            }
            var url = `${window.location.protocol}//${window.location.host}/msteams/login?returnUrl=/t/${this.selectedList.Id}`;
            microsoftTeams.pages.config.setConfig({
                websiteUrl: window.location.protocol + '//' + window.location.host,
                contentUrl: url,
                suggestedDisplayName: "RowShare - " + this.selectedList.DisplayName
            });
            saveEvent.notifySuccess();

        });
        this.resetTheme();
        microsoftTeams.app.registerOnThemeChangeHandler(t => this.resetTheme());
    }

    async init() {
        microsoftTeams.app.getContext().then(context => {
            API.User.CheckUserHasListForMsTeams(context.team?.displayName, {cache: false})
                .then(async (hasList) => {
                    this.userHasList = (hasList ?? false);
                    if(!this.userHasList) {
                        this.creatingTemplateTable = true;
                        await this.initOrgs();
                        if(this.selectedOrganization && this.selectedFolder) {
                            let copyOptions = new CopyListOptions();
                                copyOptions.CopyRows = true;
                                copyOptions.CopyReports = true;
                                copyOptions.CreateNotifications = true;
                            let copyInfo = new CopyListInfo();
                                copyInfo.Id = this.$i18n.t('BlankTableId').toString();
                                copyInfo.FolderId = this.selectedFolder.Id;
                                copyInfo.Options = copyOptions;

                            ListTreeModule.cloneList({copyInfo: copyInfo, organizationId: this.selectedOrganization.Id})
                                        .then(newList => {
                                            if(newList) {
                                                this.creatingTemplateTable = false;
                                                this.folderChanged();
                                                this.templateTableCreated = true;
                                            }
                                        })
                                        .catch((err) => this.errorMessage = this.$i18n.t('MsTeamsGeneral_Error').toString());
                        }
                    }
                })
                .catch((err) => this.errorMessage = this.$i18n.t('MsTeamsGeneral_Error').toString());
        });
    }

    async initOrgs() {
        this.organizations = [];
        this.selectedOrganization = null;
        let orgs = await API.Organization.loadall({cache: false});
        if(orgs) {
            this.organizations = orgs;
            if((this.organizations?.length ?? 0) == 1) {
                this.selectedOrganization = this.organizations[0];
            }
        }
    }

    @Watch('selectedOrganization')
    organizationChanged() {
        this.selectedFolder = null;
        this.folders = [];
        this.lists = [];
        this.selectedList = null;
        if(!this.selectedOrganization) {
            return;
        }
        API.Folder.loadAllForOrganization(AccessMode.ReadOwnedOnly, this.selectedOrganization?.Id, {cache: false})
            .then(folders => {
                if(folders) {
                    this.folders = folders;
                    if(this.folders.length == 1) {
                        this.selectedFolder = this.folders[0];
                    }
                }
            })
            .finally(() => this.loadingFolders = false);
    }

    @Watch('selectedFolder')
    folderChanged() {
        this.selectedList = null;
        this.lists = [];
        if(!this.selectedFolder) {
            return;
        }
        this.loadingLists = true;
        API.List.loadForParent(this.selectedFolder.Id, {cache: false})
            .then(lists => {
                if(lists) {
                    this.lists = lists.sort((x, y) => {
                        if(x.DisplayName > y.DisplayName) {
                            return 1;
                        }
                        else if(x.DisplayName < y.DisplayName) {
                            return -1;
                        }
                        else {
                            return 0;
                        }
                    });
                    if((this.lists?.length ?? 0) == 1) {
                        this.selectedList = this.lists[0];
                    }
                }
            })
            .catch((err) => this.errorMessage = this.$i18n.t('MsTeamsGeneral_Error').toString())
            .finally(() => this.loadingLists = false);
    }

    @Watch('selectedList')
    listChanged() {
        if(this.selectedList) {
            microsoftTeams.pages.config.setValidityState(true);
        }
        else {
            microsoftTeams.pages.config.setValidityState(false);
        }
    }

    resetTheme() {
        microsoftTeams.app.getContext().then(context => this.isDarkTeams = (context.app.theme == 'dark'));
    }


}
