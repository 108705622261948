
import {Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name:'ConfirmationDialog' })
export default class ConfirmationDialog extends Vue{

    @Prop() dialogState!:boolean;
    @Prop() title!:string | null;
    @Prop() cancelButtonText!: string | null;
    @Prop() actionButtonText!: string | null;
    @Prop() description!:string;
    @Prop({default: false}) persistent!:boolean;
    @Prop({ default:'md' }) size!: string | null;
    @Prop({ default:'' }) modalConfirmationReally!:string;
    @Prop({ default:'' }) externalError!: string;
    @Prop({ default:'check' }) actionButtonIcon!: string;
    @Prop({ default:false }) submitLoading!: boolean;
    @Prop({ default:false}) attach!: string | boolean;
    @Prop({ default:'error'}) actionButtonColor!: string;

    onSubmit(){
        this.$emit('submit');
    }

    onCancel(){
        if (this.persistent) {
            return;
        }
        this.$emit('cancel');
    }
}

