import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/modules/Store';

@Module({ dynamic: true, store: store, name: 'OnPremStore' })
export class OnPremStore extends VuexModule {
    isOnPrem = false;

    get getIsOnPrem(): boolean {
        return this.isOnPrem;
    }

    @Mutation
    updateIsOnPrem(isOnPrem: boolean) {
        this.isOnPrem = isOnPrem;
    }

}

export const OnPremModule = getModule(OnPremStore)