
import {Component, Prop } from 'vue-property-decorator';
import Bandeau from '@/views/layouts/LayoutParts/Bandeau.vue';
import * as API from '@/api/Api';
import * as Models from '@/models/RowShare';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import OrganizationSettings from "@/views/ManageOrganization/OrganizationSettings.vue";
import ListAccessAudit from "@/views/ManageOrganization/ListAccessAudit.vue";
import OrganizationInfos from "@/views/ManageOrganization/OrganizationInfos.vue";
import Groups from "@/views/ManageOrganization/Groups.vue";
import Members from '@/views/ManageOrganization/Members.vue';
import { ReturnUrlHandler } from '@/views/ReturnUrl';
import SubscriptionUpdateDialog from '@/views/ManageOrganization/SubscriptionUpdateDialog.vue';

@Component({
    name: 'ManageOrganization',
    components: {Bandeau, PageLoadSpinner, OrganizationSettings, OrganizationInfos, Groups, Members, ListAccessAudit, SubscriptionUpdateDialog}
})
export default class ManageOrganization extends ReturnUrlHandler {

    @Prop() organizationId!: string;
    private organization!:Models.Organization;
    private tab:string="parent";
    private arrayTab:{tabName:string, show:boolean}[]=[
        {tabName: 'settings', show: false},
        {tabName: 'members', show: false},
        {tabName: 'groups', show:false},
        {tabName: 'permissionAudit', show:false},
    ]

    created(){
        this.organization = new Models.Organization();
        API.Organization.load(this.organizationId).then(res=>{
            if(res){
                this.organization = res;
                if(this.organization.IsCurrentUserAdministrator){
                    this.arrayTab[0].show = true;
                    this.arrayTab[1].show = true;
                    this.arrayTab[2].show = true;
                    this.arrayTab[3].show = true;
                }
            }
        })
    }

}
