
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { ListThumbnailInfo, ListTreeFolder } from '@/models/RowShare';
    import ListActionsSubmenu from './ListActions/ListActionsSubmenu.vue';

    @Component({
        name:'ListThumbnail',
        components:{ ListActionsSubmenu }
    })
    export default class ListThumbnail extends Vue {
        @Prop() list!: ListThumbnailInfo;
        @Prop() folder!:ListTreeFolder;
        @Prop() organizationId!:string;
        @Prop() showSubmenu!:{
            type:boolean,
            required:true
            };

        private focusedComponent = false;
        
        focusComponent(){
            this.focusedComponent = true;
        }

        focusLost(){
            this.focusedComponent = false;
        }

        getIcon() {
            if (this.list.IconPath)
                return '/' + this.list.IconPath + "?_ssb_img=S;w:48;h:48";
        }

        getTableUrl() {
            if(!this.list.IsInRecycleBin){
                return "/t/" + this.list.Id;
            }
            else return "#";
            
        }
    }
