
import { Component, Prop, Watch } from 'vue-property-decorator';
import * as API from '@/api/Api';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import { HostedPage, PeriodUnitEnum, Plan, SubscriptionLimit, SubscriptionLimitType, Quotation, PricingModelEnum, ItemPrice, Tier } from '@/models/RowShare';
import { ReturnUrlHandler } from '@/views/ReturnUrl';
import { Utils } from '@/utils/Utilities';
import { RouterUtils } from '@/modules/Router';
import { Location } from 'vue-router';

@Component({
    name:'SubscriptionConfigurator',
    components: {PageLoadSpinner}
})
export default class SubscriptionConfigurator extends ReturnUrlHandler{
    // monthlyQuotation: Quotation | null = new Quotation();
    // yearlyQuotation: Quotation | null = new Quotation();
    // lastMembersCountRefresh: number = 0;
    membersCount: number = 5;
    isLoading: boolean = true;
    isUsingEur: boolean = true;
    notEnoughtUsers: boolean = false;
    // timeout!: number;
    orgMembersCount: number = 0
    cbInstance: any;
    // lastCurrencyRefresh: string = "";
    canChooseCurrency: boolean = false;
    cbCheckOutSuceeded: boolean = false;
    cancellationConfirmed: boolean = false;
    quotationLimitExceeded: boolean = false;
    subscriptionLimits!: Array<SubscriptionLimit>;
    orgInTrial: boolean = true;
    // selectedPlan: Plan | null = null;
    // annualSelectedPlan: ItemPrice | null = null;
    // monthSelectedPlan: ItemPrice | null = null;
    // lastPlanRefresh: Plan | null = null;
    configuratorPlans: Array<Plan> | null = null;
    plan: Plan | null = null;
    loadingPlans: boolean = false;        
    selectedPeriod: PeriodUnitEnum = PeriodUnitEnum.Year;
    currentQuotations: Quotation[] = [];
    

    @Prop() organizationId!: string | null;

    public get currentPeriod() {
        switch (this.selectedPeriod) {
            case PeriodUnitEnum.Month:
                return "Month";
            default:
                return "Year";
        }
    }

    public set currentPeriod(value: string) {
        if(value === "Month") {
            this.selectedPeriod = PeriodUnitEnum.Month;
        }
        else {
            this.selectedPeriod = PeriodUnitEnum.Year;
        }
    }

    public get currentCurrency(){
        if(this.isUsingEur){
            return "EUR";
        }
        else
        {
            return "USD";
        }
    }

    public set currentCurrency(value: string){
        if(value==='EUR'){
            this.isUsingEur = true;
        }
        else{
            this.isUsingEur = false;
        }
    }

    public get showFullPriceDetails(): boolean {
        if(!this.membersCount) {
            return false;
        }
        return (this.membersCount > 5 && this.membersCount < 100);
    }

    public get showContactUs(): boolean {
        if(!this.membersCount) {
            return false;
        }
        return (this.membersCount == 100);
    }

    public get priceStringPattern(): string {
        return this.isUsingEur ? 'CheckOut_PriceEurPattern' : 'CheckOut_PriceUsdPattern';
    }

    public get contactLink(){
        return <Location>{
            name: "Contact",
            params: {
                selectedQuestion: 'Contact/Sales'
            }
        };
    }


    async created() {
        this.currentPeriod = "Year";
    }

    async mounted() {
        if(this.organizationId)
        {
            this.loadingPlans = true;
            await this.initChargebeeInstance();
            API.Organization.load(this.organizationId).then(org => {
                if(org){
                    this.membersCount = org.InTrial ? org.ActiveMemberCount : Math.max(org.ActiveMemberCount, org.MaximumMemberCount);
                    this.orgMembersCount = org.ActiveMemberCount;// org.InTrial? org.ActiveMemberCount : org.MaximumMemberCount;
                    this.isUsingEur = (org.CurrencyISOCode == "EUR");
                    this.canChooseCurrency = (org.InTrial || !org.SubscriptionEnabled || org.SubscriptionIsFree);
                    this.orgInTrial = org.InTrial;
                    this.getPlans().then(() => {
                        this.computeQuotations();
                    });
                        // .then(() => {
                        //     if(this.organizationId) {
                        //         API.Organization.getCurrentPlanId(this.organizationId)
                        //             .then(planId => {
                        //                 if(planId && this.configuratorPlans) {
                        //                     let currentPlan = this.configuratorPlans.find(p => p.getItemPriceById(planId) != null);
                        //                     if(currentPlan) {
                        //                             setTimeout(() => {
                        //                                 if(currentPlan) {
                        //                                     this.onPlanSelected(currentPlan);
                        //                                 }
                        //                             },0);
                        //                     }
                        //                 }
                        //         });
                        //     }
                        // });
                }
            });
            // API.Organization.getLimits(this.organizationId).then(limits => {
            //     if(limits){
            //         this.subscriptionLimits = limits;
            //     }
            // });
        }
    }

    async initChargebeeInstance(){
        if(!this.cbInstance)
        {
            let scriptPromise = this.$loadScript('https://js.chargebee.com/v2/chargebee.js');
            let siteNamePromise = API.Configuration.getChargebeeSiteName();
            
            let res = await Promise.all([scriptPromise, siteNamePromise]);
            let cbSiteName = res[1];

            this.cbInstance = (<any>window).Chargebee.init({ site: cbSiteName, enableGATracking: true, enableGTMTracking: true, isItemsModel: true });
        }
    }

    isCorporatePlan(plan: Plan | null | undefined) : boolean {
        return plan?.OfferId == "Corporate";
    }

    isEnterprisePlan(plan: Plan | null | undefined) : boolean {
        return plan?.OfferId == "Enterprise";
    }

    getPlanTitle(plan: Plan): string {
        return plan?.OfferId ?? '';
    }

    getTierDescription(tier: Tier | null | undefined) : string {
        if(!tier) {
            return "";
        }
        return this.$i18n.t('CheckOut_TierDescription_'+ this.currentCurrency, {min: tier.MinUnit, max: tier.MaxUnit, price: (tier.Price / 100).toLocaleString()}).toString();
    }

    getItemPriceTiers(plan: Plan | null | undefined) : Tier[] {
        if(!plan) {
            return [];
        }
        var itemPrice = plan.getItemPrice(1, this.selectedPeriod, this.currentCurrency);
        if(!itemPrice) {
            return [];
        }
        return itemPrice.Tiers ?? [];
    }
    
    get pricingUrl(): string {
        return this.$i18n.t('CheckOut_PricingUrl').toString();
    }

    // async getMonthlyQuotation() {
    //     if(!this.organizationId) {
    //         return null;
    //     }

    //     let currency = this.isUsingEur ? 'EUR' : 'USD';
    //     let quotation = new Quotation();
    //     quotation.PlanId = this.monthSelectedPlan?.Id ?? '';
    //     quotation.CustomerId = this.organizationId;
    //     quotation.RequestedUsers = this.membersCount;
        
    //     if(this.monthSelectedPlan?.PricingModel === PricingModelEnum.PerUnit) {
    //         quotation.PlanQuantity = this.membersCount;
    //         quotation.Price = this.monthSelectedPlan.Price * quotation.PlanQuantity;
    //     }
    //     console.log('Local monthly quotation : ', quotation);

    //     return await API.Organization.getMonthlyQuotation(this.organizationId, this.membersCount, currency, this.monthSelectedPlan?.Id);
    // }

    // async getYearlyQuotation() {
    //     if(!this.organizationId) {
    //         return null;
    //     }

    //     let currency = this.isUsingEur ? 'EUR' : 'USD';
    //     return await API.Organization.getAnnualQuotation(this.organizationId, this.membersCount, currency, this.annualSelectedPlan?.Id);
    // }

    // async getQuotations() {
    //     let currency: string = '';
    //     if(this.isUsingEur) {
    //         currency = "EUR";
    //     }
    //     else {
    //         currency = "USD";
    //     }

    //     if(this.selectedPlan == null) {
    //         return;
    //     }

    //     if (this.membersCount === this.lastMembersCountRefresh && this.lastCurrencyRefresh === currency && this.lastPlanRefresh == this.selectedPlan) {
    //         return;
    //     }
    //     this.isLoading = true;
    //     this.quotationLimitExceeded = false;
    //     if(!this.organizationId) {
    //         return;
    //     }

    //     this.monthlyQuotation = await this.getMonthlyQuotation();
    //     this.yearlyQuotation = await this.getYearlyQuotation();
    //     this.lastMembersCountRefresh = this.membersCount;
    //     this.lastPlanRefresh = this.selectedPlan;
    //     this.lastCurrencyRefresh = currency;
        
    //     this.isLoading = false;
    //     if(this.monthlyQuotation){
    //         this.notEnoughtUsers = this.monthlyQuotation.UsersBilled < this.orgMembersCount;
    //         if(this.subscriptionLimits && this.subscriptionLimits.length > 0) {
    //             this.subscriptionLimits.forEach(limit => {
    //                 if(!this.monthlyQuotation){
    //                     return;
    //                 }
    //                 switch(limit.LimitType)
    //                 {
    //                     case SubscriptionLimitType.Table:
    //                         if(this.monthlyQuotation.PlanTableCount < limit.CurrentValue) {
    //                             this.quotationLimitExceeded = true;
    //                             return;
    //                         }
    //                         break;
    //                     case SubscriptionLimitType.Row:
    //                         if(this.monthlyQuotation.PlanRowCount < limit.CurrentValue) {
    //                             this.quotationLimitExceeded = true;
    //                             return;
    //                         }
    //                         break;
    //                     case SubscriptionLimitType.Storage:
    //                         // Plan storage is in Mo
    //                         if(this.monthlyQuotation.PlanStorage * 1024 * 1024 < limit.CurrentValue) {
    //                             this.quotationLimitExceeded = true;
    //                             return;
    //                         }
    //                         break;
    //                 }
    //             });
    //         }
    //     }
    // }

    async getPlans() {
        this.configuratorPlans = await API.Organization.getConfiguratorPlans() ?? [];
        this.configuratorPlans.sort((planA: Plan, planB: Plan) => {
            var priceA = planA.getBasePrice(1, this.selectedPeriod, this.currentCurrency) ?? 0;
            var priceB = planB.getBasePrice(1, this.selectedPeriod, this.currentCurrency) ?? 0;
            if(priceA > priceB) {
                return 1;
            }
            else if(priceA < priceB) {
                return -1;
            }
            else {
                return 0;
            }
        });
        this.loadingPlans = false;
    }

    // get annualPlans(): Array<Plan> {
    //     if(this.configuratorPlans) {
    //         return this.configuratorPlans.filter(p => {
    //             if(p.Prices.find((pp: ItemPrice) => pp.PeriodUnit == PeriodUnitEnum.Year && pp.Currency === this.currentCurrency)) {
    //                 return true;
    //             }
    //             return false;
    //         });
    //     }
    //     return [];
    // }

    // get monthPlans(): Array<Plan> {
    //     if(this.configuratorPlans) {
    //         return this.configuratorPlans.filter(p => {
    //             if(p.Prices.find((pp: ItemPrice) => pp.PeriodUnit == PeriodUnitEnum.Month && pp.Currency === this.currentCurrency)) {
    //                 return true;
    //             }
    //             return false;
    //         });
    //     }
    //     return [];
    // }
    @Watch('membersCount')
    computeQuotations() {
        setTimeout(() => {
            this.currentQuotations = [];
            if(!this.membersCount || !this.configuratorPlans) {
                return;
            }
            
            this.configuratorPlans.forEach((cp) => {
                let quot = new Quotation();
                quot.PlanId = cp.Id;
                quot.ItemPriceId = cp.getItemPrice(1, this.selectedPeriod, this.currentCurrency)?.Id ?? '';
                quot.Price = cp.getPrice(1, this.selectedPeriod, this.currentCurrency, this.membersCount);
                quot.BasePrice = cp.getPrice(1, this.selectedPeriod, this.currentCurrency, 1);
                quot.RequestedUsers = this.membersCount;
                quot.PlanQuantity = this.membersCount;
                quot.CustomerId = this.organizationId ?? '';

                this.currentQuotations.push(quot);
            });
        }, 0);
    }

    getPlanBasePrice(plan: Plan | null | undefined) : string {
        if(!plan) {
            return "";
        }
        let quotation = this.currentQuotations.find((q) => q.PlanId === plan.Id);
        if(!quotation) {
            return "";
        }
        return this.$i18n.t(this.priceStringPattern, [(Math.round(quotation.BasePrice / 100)).toLocaleString(navigator.language)]).toString();
    }

    getPlanPrice(plan: Plan | null | undefined) : string {
        if(!plan) {
            return "";
        }
        let quotation = this.currentQuotations.find((q) => q.PlanId === plan.Id);
        if(!quotation) {
            return "";
        }
        return this.$i18n.t(this.priceStringPattern, [(Math.round(quotation.Price / 100)).toLocaleString(navigator.language)]).toString()
                + ' ' + this.$i18n.t(this.currentPeriod == 'Year' ? 'CheckOut_PlanByYear' : 'CheckOut_PlanByMonth').toString();
    }

    getPlanUserPrice(plan: Plan | null | undefined) : string {
        if(!plan) {
            return "";
        }
        let quotation = this.currentQuotations.find((q) => q.PlanId === plan.Id);
        if(!quotation) {
            return "";
        }
        return this.$i18n.t(this.priceStringPattern, [(Math.round(quotation.UserPrice / 100)).toLocaleString(navigator.language)]).toString();
    }

    getSetupFeesPrice(plan: Plan | null | undefined) : string {
        if(!plan) {
            return "";
        }
        return this.$i18n.t(this.priceStringPattern, [(plan.getSetupFees(this.currentCurrency) / 100).toLocaleString(navigator.language)]).toString();
    }

    getTablesLimit(plan: Plan | null | undefined) {
        if(!plan) {
            return 0;
        }
        return ((plan.Limits.TablePerQuantity * this.membersCount) + plan.Limits.FixedTableQuantity).toLocaleString(navigator.language);
    }

    getRowsLimit(plan: Plan | null | undefined) {
        if(!plan) {
            return 0;
        }
        return ((plan.Limits.RowPerQuantity * this.membersCount) + plan.Limits.FixedRowQuantity).toLocaleString(navigator.language);
    }

    getSizeLimit(plan: Plan | null | undefined) {
        if(!plan) {
            return '';
        }
        let size = ((plan.Limits.TableSizePerQuantity * this.membersCount) + plan.Limits.FixedTableSize) * 1024 * 1024;
        return Utils.humanFileSize(size, this.$i18n.locale=="fr", 0);
    }

    getReadOnlyUsersLimit(plan: Plan | null | undefined) {
        if(!plan) {
            return 0;
        }
        return ((plan.Limits.ReadOnlyUsersPerQuantity * this.membersCount) + plan.Limits.FixedReadOnlyUsersQuantity).toLocaleString(navigator.language);
    }

    getQuotationPrice(quotation: Quotation | null | undefined) {
        if(!quotation) {
            return "--";
        }
        return this.$i18n.t(this.priceStringPattern, [(quotation.Price / 100).toLocaleString(navigator.language)]).toString();
    }

    choosePlan(plan: Plan | null | undefined) {
        if(!plan) {
            return;
        }
        let quotation = this.currentQuotations.find((q) => q.PlanId === plan.Id);
        if(!quotation) {
            return;
        }
        this.openCheckoutFromQuotation(quotation);
    }

    getPlanExtraUserTooltipTitle(plan: Plan | null | undefined) {
        if(!plan) {
            return;
        }
        return this.$i18n.t('CheckOut_ExtraUserTooltipTitle', [plan.OfferId]).toString();
    }

    // onPlanSelected(plan: Plan) {
    //     this.selectedPlan = plan;
    //     this.annualSelectedPlan = plan.getItemPrice(1, PeriodUnitEnum.Year, this.currentCurrency);
    //     this.monthSelectedPlan = plan.getItemPrice(1, PeriodUnitEnum.Month, this.currentCurrency);
    //     this.getQuotations();
    // }

    // isPlanSelected(plan: Plan) {
    //     return this.selectedPlan === plan;
    // }

    // startTimer() {
    //     this.timeout = setTimeout(() => this.getQuotations(), 500);
    // }

    // clearTimer() {
    //     if (this.timeout) {
    //         clearTimeout(this.timeout);
    //     }
    // }

    // restartTimer() {
    //     this.clearTimer();
    //     this.startTimer();
    // }

    // onMembersCountKeyPress(event: KeyboardEvent){
    //     if (event.keyCode === 13) {
    //         event.preventDefault();
    //         this.clearTimer();
    //         this.getQuotations();
    //     } 
    //     else if ((event.keyCode < 48 || event.keyCode > 57) && event.keyCode !== 8 && event.keyCode !== 46) {
    //         event.returnValue = false;
    //     } 
    //     else {
    //         this.restartTimer();
    //     }
    // }

    // onMembersCountChange(event: Event){
    //     this.clearTimer();
    //     this.getQuotations();
    //     return false;
    // }

    // chooseMembersCount(quantity: number){
    //     this.membersCount = quantity;
    //     this.getQuotations();
    // }

    async openCheckoutFromQuotation(quotation: Quotation | null | undefined) {
        if(!quotation) {
            return;
        }
        if(!this.cbInstance){
            this.initChargebeeInstance();
        }

        this.cbInstance.openCheckout({
            hostedPage: function () : unknown {
                return API.Organization.loadCheckoutFromQuotation(quotation);
            },
            error: function (error: string) {
            },
            close: () => {
                if(this.cbCheckOutSuceeded) {
                    setTimeout(() => {
                        var defaultRedirectUrl = "/manageorganization/"+this.organizationId;
                        this.redirectToReturnUrlOrDefault(defaultRedirectUrl);
                    }, 0);
                }
            },
            success: (hostedPageId : string) => {
                this.cbCheckOutSuceeded = true;
            },
        });
    }
}
