
    import { Component, Prop } from "vue-property-decorator";
    import { UserModule } from '@/store/UserStore'
    import { OnPremModule } from '@/store/OnPremStore';
    import SectionTitle from '@/views/layouts/LayoutParts/SectionTitle.vue';
    import SocialLoginList from '@/views/Signin/SocialLoginList.vue';
    import LoginForm from '@/views/Signin/LoginForm.vue';
    import { ReturnUrlHandler } from "@/views/ReturnUrl";
    import { Location as RouterLocation } from "vue-router";
    import { RouterUtils } from "@/modules/Router";
    import { Utils } from "@/utils/Utilities";
    import { loadAndSetWithUserLanguageAsync } from '@/modules/Localization';

    @Component({
        name: "Login",
        components: { SectionTitle, SocialLoginList, LoginForm }
    })
    export default class Login extends ReturnUrlHandler {

        @Prop() emailFromSignUp!:string;
        @Prop() inviteLinkId!: string;
        @Prop() organizationId!: string;

        private showView=false;

        constructor() {
            super();
        }

        async beforeCreate() {
            await loadAndSetWithUserLanguageAsync();
        }
        
        async created(){
            if(!UserModule.CurrentUser.IsAnonymous) {
                this.redirectToLanding();
            }
            else {
                this.showView = true;
            }
        }

        redirectToLanding(){
            var defaultRedirectRoute = this.$router.resolve({ name: 'MyTables' });
            this.redirectToReturnUrlOrDefault(defaultRedirectRoute.href);
        }

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;;
        }

        get hasUnauthorizedSignupError(){
            var qsVal = RouterUtils.getCaseInsentiveQuery(this.$route, "unauthorizedSignup");
            if(qsVal)
                return (<string>qsVal).toLowerCase() == 'true';

            return false;
        }

        getSignupUrl() {
            if(!Utils.isNullOrWhiteSpace(this.inviteLinkId)) {
                return <RouterLocation> {
                    name: 'Join',
                    params: {
                        inviteLinkId: this.inviteLinkId
                    },
                    query: {
                        ReturnUrl: RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl"),
                    }
                };
            }
            return <RouterLocation>{
                name: "SignUp",
                query: {
                    ReturnUrl: RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl"),
                    orgid: RouterUtils.getCaseInsentiveQuery(this.$route, "orgid")
                }
            };
        }

    }
