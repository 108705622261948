import { User } from './User';
import * as API from '../api/Api';
import i18n from '@/modules/Localization';

export class Job {

    public Id!: string;
    public ReportId!: string;
    public ReportDisplayName!:string;
    public ListId!: string;
    public Status!: JobStatus;
    public Type!: JobType;
    public CreationDateUtc!:Date;
    public StatusChangeDateUtc!: Date;
    public OutputFileLastWriteTimeUtc!: Date;
    public OutputFileSize!:number;
    public OutputFileName!:string;
    public OutputFilePath!: string;
    public OutputError!:string;
    public OutputData!:string;
    public User!: User;
    public RowCount!:number;
    public ListDisplayName!: string;

    public static pollingJobs = new Array<Job>();

    public get hasFinished() {
        switch (this.Status) {
            case JobStatus.Error:
            case JobStatus.Finished:
            case JobStatus.SourceDeleted:
                return true;
        }
        return false;
    }

    private pollingTimeout: number | null = null;
    public updateStatusAsync(interval: number, updateCallback: ((job: Job) => void) | null = null) {
        if (this.hasFinished || Job.pollingJobs.indexOf(this) > -1) {
            return;
        }

        Job.pollingJobs.push(this);

        this.pollingTimeout = setInterval(async() => {
            const job = await API.Job.load(this.Id, { cache: false });
            
            var oldRef = this.pollingTimeout;
            Object.assign(this, job);
            this.pollingTimeout = oldRef;

            if(updateCallback)
                updateCallback(this);

            if (job!= null && job.hasFinished && this.pollingTimeout != null) {
                clearInterval(this.pollingTimeout);
                var index = Job.pollingJobs.indexOf(job);
                if (index > -1) {
                    Job.pollingJobs.splice(index, 1);
                }
            }
        }, interval);
    }

    public static clearAllJobUpdateTimeout(){
        Job.pollingJobs.forEach(job => {
            if(job.pollingTimeout) {
                clearInterval(job.pollingTimeout);
            }
        });
        Job.pollingJobs = [];
    }

    public static getStatusString(status: JobStatus): string {
        return i18n.t(`JobStatus_${status}`).toString();
    }
}

// Must be synchronized with JobStatus enum on backend
export const enum JobStatus{
    Unspecified = 0,       
    Processing = 1,
    NotStarted = 2,
    Finished = 3,
    Error = 4,
    Retrying = 5,
    SourceDeleted = 6,
    ProcessingExport = 7,
    ProcessingIndexation = 8,
    ProcessingAnalysis = 9,
    ExportDone = 10,
    IndexationDone = 11,
    AnalysisDone = 12
}

// Must be synchronized with JobType enum on backend
export const enum JobType {
    Unspecified = 0,
    ListReport = 1,
    ListAnalysis = 2
}

