
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

    @Component({
        name: 'SurveySuccess',
        components: {}
    })
    export default class SurveySuccess extends Vue {
        @Prop() listId!: string;

        async goToRowShare(){

        }
    }
