
import { Component } from 'vue-property-decorator';
import InputText from '@/views/components/FormInputs/InputText.vue';
import { FormValidation } from '@/views/components/FormValidation';
import { ValidationObserver } from 'vee-validate';
import Bandeau from '@/views/layouts/LayoutParts/Bandeau.vue';
import * as API from '@/api/Api';
import * as RowShare from '@/models/RowShare';
import { RowShareException } from '@/api/ApiUtils';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import { Utils } from '@/utils/Utilities';
import { RecaptchaActions } from '@/views/components/Recaptcha.vue';
import { OnPremModule } from '@/store/OnPremStore';
import Recaptcha from '@/views/components/Recaptcha.vue';
import { EventBus } from '@/modules/EventBus';

@Component({
  name: 'ResetPasswordRequest',
  components: { ValidationObserver, InputText, Bandeau, PageLoadSpinner, Recaptcha },
})
export default class ResetPasswordRequest extends FormValidation {
  private resetPasswordRequest:RowShare.ResetPasswordRequest = new RowShare.ResetPasswordRequest();
  private resetPasswordRequestSuccess: boolean = false;
  private working : boolean = false;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  created(){
        EventBus.$on(RowShare.Event.RECAPTCHA_SUCCEEDED,this.onRecaptchaSucceeded);
        EventBus.$on([RowShare.Event.RECAPTCHA_FAILED,RowShare.Event.RECAPTCHA_CANCELEDV2], this.onRecaptchaFailedOrCanceled);
  }

  destroyed() {
        EventBus.$off(RowShare.Event.RECAPTCHA_SUCCEEDED,this.onRecaptchaSucceeded);
        EventBus.$off([RowShare.Event.RECAPTCHA_FAILED,RowShare.Event.RECAPTCHA_CANCELEDV2], this.onRecaptchaFailedOrCanceled);
  }

  onSubmit() {
     this.working = true;
    //Recaptcha in development doesn't work, so the environment variable is evaluated
    if (this.mustShowRecaptcha) {
      this.triggerRecaptcha();
      } 
      else {
        this.doValidate();
      }
  }

  private doValidate()
        {
    //on ne valide que le format
    //on ne dit pas si ça a marché ou pas pour ne pas donner d'indication sur l'existence du user
    if (Utils.validateEmail(this.resetPasswordRequest.Email)) {
      var request = new ResetPasswordRequest();
      API.User.resetPasswordRequest(this.resetPasswordRequest)
        .then((res) => {
          //no indication of what is the issue
          this.resetPasswordRequestSuccess = true;
        })
        .catch(exc => {
                        let rsExc = exc as RowShareException;
                        let exceptionMsg = "";
                        if(rsExc) {
                            if (rsExc.code == 96 && this.mustShowRecaptcha) {
                                // Recaptcha token not valid
                                this.retryFallbackRecaptcha();
                                return;
                                }
                            exceptionMsg = rsExc.message;
                        }
                        else {
                            exceptionMsg = exc.toString();
                        }
                        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{ message: exceptionMsg,
                            type: RowShare.NotificationType.error,autoHide: true, autoHideAfterMs: 10000});
  
            })
          .finally(() => {
            this.working = false;
            });
    }
  }

  triggerRecaptcha() {
    this.resetPasswordRequest.RecaptchaToken = "";
    EventBus.$emit(RowShare.Event.RECAPTCHA_SHOW, RecaptchaActions.ResetPasswordRequest);
  }

  onRecaptchaSucceeded(successEvent: any){   
    this.resetPasswordRequest.RecaptchaToken = successEvent.token;
    this.resetPasswordRequest.RecaptchaTokenIsV3 = successEvent.isV3;
    this.doValidate();
  }

  retryFallbackRecaptcha(){
    this.resetPasswordRequest.RecaptchaToken = "";
    EventBus.$emit(RowShare.Event.RECAPTCHA_SHOWV2);
  }

  onRecaptchaFailedOrCanceled(reason: string = ""){
    this.resetPasswordRequest.RecaptchaToken = "";  
    this.resetPasswordRequest.RecaptchaTokenIsV3 = true;  
    this.working = false;
    EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
              autoHide: true,
              autoHideAfterMs: 3000,
              type: RowShare.NotificationType.error,
              message: (this.$i18n.t('Recaptcha_Invalid').toString()) + reason
              });
  }


  get isNotOnPrem(){
            return !OnPremModule.isOnPrem;
        }

  get mustShowRecaptcha(){
      return this.isNotOnPrem && process.env.NODE_ENV!="development";
  }
}
