
import { Component, Prop, Vue } from "vue-property-decorator";
import * as RowShare from "@/models/RowShare";
import SecurityPrincipalDropdown from "@/views/components/SecurityPrincipalDropdown.vue";
import { EventBus } from "@/modules/EventBus";
import RowVM from "@/viewModels/Table/rowVM";
import { MemberHelper } from "@/utils/MemberHelper";
import { BigramHelper } from "@/utils/BigramHelper";

@Component({ name: "AssignationDialog", components: { SecurityPrincipalDropdown }})
export default class AssignationDialog extends Vue {
    @Prop() currentOrganization!: RowShare.Organization;
    selectedPrincipal: RowShare.SecurityPrincipal | string | null = null;
    selectedRows: RowVM[] = [];
    processing: boolean = false;
    showMe: boolean = false;

    created() {
        EventBus.$on(RowShare.Event.ASSIGNROWS_REQUEST, (selectedRows: RowVM[]) => this.init(selectedRows));
    }

    destroy() {
        EventBus.$off(RowShare.Event.ASSIGNROWS_REQUEST);
    }

    init(selectedRows: RowVM[]) {
        this.selectedRows = selectedRows;
        this.showMe = true;
    }

    closeDialog() {
        this.selectedRows = [];
        this.showMe = false;
        this.$emit('close');
    }

    async assignRows(){
        this.processing = true;
        MemberHelper.addMemberIfNecessary(this.selectedPrincipal, this.currentOrganization?.Id);

        for(let i = 0; i < this.selectedRows.length; ++i){
            let rowVM = this.selectedRows[i];
            rowVM.setAssignation(BigramHelper.getAssignationResultFromSecurityPrincipal(this.selectedPrincipal));
            await rowVM.innerSave();
        }
        this.processing = false;
        this.closeDialog();
    }
}
