import * as RowShare from '@/models/RowShare';
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import { Utils } from '@/utils/Utilities';
import * as agGrid from 'ag-grid-community';
import i18n from '@/modules/Localization';

export default class TwoStatesBooleanColumnProxy extends BaseColumnProxy {
    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        return val;
    }

    getFormattedHtml(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        if(!rsColumn || val === undefined || val === null)
            return "";

        var cellValue = Utils.parseBoolean(val, false);

        return cellValue
            ? `<i class='v-icon notranslate fal fa-check theme--light primary--text' style="font-size: 16px;"></i>`
            : '';
    }

    /** Filters **/
    getCustomFilterParams(rsCol: RowShare.Column) : Promise<null | any> {
        var res = super.getCustomFilterParams(rsCol);
        res.valueFormatter = (params: agGrid.ValueFormatterParams) => {
            if(params.value === true) {
                return i18n.t('Common_True').toString();
            }
        }
        return res;
    }

    /** Aggregation **/

    canShowTotal() : boolean {
        return true;
    }

    addToTotal(currentAggResult: any, rowVM: RowVM, rsColumn: RowShare.Column): any {
        const val = Utils.parseBoolean(rowVM?.getValue(rsColumn, this), false)
        if(val)
            currentAggResult++;
        
        return currentAggResult
    }

    formatTotal(aggResult: any, rowCount: number): any {
        return this.getRowCountFormat("Aggregation_TwoStateBooleanFormat", aggResult, rowCount);
    }
}