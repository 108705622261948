import { render, staticRenderFns } from "./BackOfficeOrganizationLimits.vue?vue&type=template&id=06f90c46&scoped=true&"
import script from "./BackOfficeOrganizationLimits.vue?vue&type=script&lang=ts&"
export * from "./BackOfficeOrganizationLimits.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f90c46",
  null
  
)

export default component.exports