import * as jobs from './Job';
import * as api from '@/api/Api';
import { Row } from './Row';

export class Report {
    public Id!: string;
    public ListId!: string;
    public DisplayName!: string;
    public Options!: ReportOptions;
    public FileContentType!: string;
    public FileName!: string;
    public FileSize!: number;
    public FilePath!: string;
    public OutputFormat!: ReportFormat;
    public FileLastWriteTimeUtc!: Date;
    public CreationDateUtc!: Date;
    public Published!: boolean;
    public ConversionStatus!: jobs.JobStatus;
    public ConversionErrorCode!: number;
    public ConversionErrorMessage!: string;
    public ConversionNeeded!: boolean;
    public OutputDestinationType!: OutputDestinationType;
    public OutputDestinationId: string | null = null;

    public static pollingReports = new Array<Report>();

    public get conversionHasFinished() {
        if (!this.ConversionNeeded) {
            return true;
        }
        switch (this.ConversionStatus) {
            case jobs.JobStatus.Error:
            case jobs.JobStatus.Finished:
            case jobs.JobStatus.SourceDeleted:
                return true;
        }

        return false;
    }

    public get hasConversionError() {
        return this.ConversionNeeded && this.ConversionStatus === jobs.JobStatus.Error;
    }

    public pollingTimeout: number | null = null;
    public updateConversionStatusAsync(interval: number) {
        if (this.conversionHasFinished || Report.pollingReports.indexOf(this) > -1) {
            return;
        }

        Report.pollingReports.push(this);
        this.pollingTimeout = setInterval(async () => {
            const report = await api.Report.load(this.Id, { cache: false });
        
            if(report) {
                // we can't replace everything as this would conflict with in place edit
                this.ConversionStatus = report.ConversionStatus;
                this.ConversionErrorCode = report.ConversionErrorCode;
                this.ConversionErrorMessage = report.ConversionErrorMessage;
                this.ConversionNeeded = report.ConversionNeeded;
            }

            if (report && report.conversionHasFinished && this.pollingTimeout) {
                clearInterval(this.pollingTimeout);
                var index = Report.pollingReports.findIndex(pr => pr.Id === this.Id);
                if (index > -1) {
                    Report.pollingReports.splice(index, 1);
                }
            }
        }, interval);
    }
            
    public static clearAllReportUpdateTimeout(){
        Report.pollingReports.forEach(report => {
            if(report.pollingTimeout)
                clearInterval(report.pollingTimeout);
        });
        Report.pollingReports = [];
    }
}

export class ReportGenerationContext {
    rowIds!: string[] | null;
    totalTableRowCount!: number;
    expirationDate!: Date;
    testMode!: boolean;
    reportId!: string;
    outputFormat!: ReportFormat;
    hasReadOnlyRows!: boolean;
}

export const enum ReportOptions {
    None = 0
}

export const enum ReportFormat {
    Auto = 0,
    Pdf = 1,
    Docx = 2,
}

export const enum OutputDestinationType {
    DownloadFile = 0,
    CopyToColumn = 1,
}