
    import { Component, Vue } from 'vue-property-decorator';
    import { UserModule } from '@/store/UserStore';
    import { OnPremModule } from '@/store/OnPremStore';
    import { ApplicationModule } from '@/store/ApplicationStore';

    @Component({
        name: "OrganizationAccessDenied"
    })
    export default class OrganizationAccessDenied extends Vue {
        private isLoggedIn: Boolean = false;
        private currentUserEmail : string|null = null;
 
        async created(){
            let userModel = UserModule.CurrentUser;
            if(!userModel.IsAnonymous)
                this.currentUserEmail = userModel.Email;
                this.isLoggedIn = true;
        }

        get msTeams(): boolean {
            return ApplicationModule.isMsTeamsApp;
        }

        get isNotOnPrem(){
                return !OnPremModule.isOnPrem;;
            }

        get description1(){
            return this.$i18n.t('OrganizationAccessDenied_Description1', {CurrentUserEmail: this.currentUserEmail}).toString();
        }

        get loginUrl() : string{
            return "/Login";
        }

        async changeAccount(){
            let returnUrl: string | null = await UserModule.signOut();
            if (returnUrl) {
                window.location.href = '/login?ReturnUrl=MyTables';
            }
        }

    }
