
import {Component, Prop} from 'vue-property-decorator';
import InputText from '@/views/components/FormInputs/InputText.vue';
import { FormValidation } from '@/views/components/FormValidation';
import { ValidationObserver } from 'vee-validate';
import Bandeau from '@/views/layouts/LayoutParts/Bandeau.vue';

import * as API from '@/api/Api';
import { UserUnsubscription } from '@/models/RowShare';

@Component({
    name:'Unsubscription',
    components:{ValidationObserver, InputText, Bandeau },
})

export default class Unsubscription extends FormValidation {

    @Prop() private userId!:string;
    private unsubscriptionEmail ='';
    private showMessage:boolean = false;

    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };

    onSubmit(){
        let unsubscriptionInfo = new UserUnsubscription();
        unsubscriptionInfo.userId=this.userId,
        unsubscriptionInfo.Email = this.unsubscriptionEmail;

        API.User.unsubscribeEmail(unsubscriptionInfo).then(res=>{
            this.showMessage = true;
        }).catch(er=> this.$refs.observer.setErrors({
                email : [this.$i18n.t("ExceptionCode_" + er.code).toString()],
            }));
    }

}
