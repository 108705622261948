
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { UserModule } from '@/store/UserStore'
    import { Getter, namespace } from "vuex-class";
    import * as API from '@/api/Api';
    import * as RowShare from '@/models/RowShare';
    import { OnPremModule } from '@/store/OnPremStore';
    import { Utils } from "@/utils/Utilities";
    import { ApiUtils } from '@/api/ApiUtils';
    import { ResLoader } from '@/utils/ResLoader';
    import { Location as RouterLocation } from "vue-router";

    declare var Calendly: any;

    @Component({
        name: "CreateTableActions"
    })
    export default class CreateTableActions extends Vue {

        // Fields
        isFileLoading: boolean = false;
        excelFile: File | null = null;
        importError:boolean = false;
        importErrorMessage="";
        userNotConnected=false;
        hasClickedCreateBlankTable = false;

        // Properties
        @Prop() private headerHtml!: string;
        @Prop() private headerTitle!: string;

        async created(){
            let scriptPromise = this.$loadScript('https://assets.calendly.com/assets/external/widget.js');
            let user = UserModule.CurrentUser;
            ResLoader.loadCss('https://assets.calendly.com/assets/external/widget.css');

            let res = await Promise.all([scriptPromise]);

            if(!user || user.IsAnonymous){
                this.userNotConnected = true;
            }
        }

        // Methods
        onFileChanged(e: Event) {
            this.importError = false;
            this.importErrorMessage = "";
            if(this.excelFile && this.excelFile.size > 0){
                let folderId = this.$route.query.folderId ? this.$route.query.folderId.toString() : "";
                this.isFileLoading = true;
                API.Folder.import(folderId, this.excelFile)
                    .then(res => {
                        if (res === null) {
                            this.isFileLoading = false;
                            this.importErrorMessage=this.$i18n.t('CreateTable_ErrorMessage').toString();
                            this.importError = true;
                            return;
                        } else if (res instanceof RowShare.Folder) {
                            window.location.href = "/MyTables?key=&organizationParentFolderId=" + res.Id;
                        } else if (res.length > 1) {
                            window.location.href = "/MyTables/" + res[0].OrganizationId+"/"+res[0].FolderId;
                        } else if (res.length == 1) {
                            window.location.href = "/t/" + res[0].Id;
                        }
                    })
                    .catch(err => {
                                    this.importErrorMessage = err._message;
                                    this.importError = true;
                                    this.isFileLoading = false});
            }
        }

         getCreateBlankTableUrl() {
             let folderIdStr = this.$route.query.folderId ? "&folderId=" + this.$route.query.folderId : "";
             return this.$i18n.t('BlankTableUrlPattern', { folderId: folderIdStr }).toString();
        }

        openCalendly() {
            Calendly.initPopupWidget({ url: this.$i18n.t('CreateTable_CalendlyUrl').toString() });
        }

        openFile() {    
            var elem = document.getElementById('xlsx-import');
            if (elem != null) {
                elem.click();
            }
        }

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;
        }

        get signUpLink(){
            return <RouterLocation>{
                name: "SignUp",
                query: {
                    ReturnUrl: this.$route.fullPath,
                }
            };
        }

        get signInLink(){
            return <RouterLocation>{
                name: "Login",
                query: {
                    ReturnUrl: this.$route.fullPath,
                }
            };
        }

        gotToDemo() {
            window.location.href = this.$i18n.t("Header_SignupUrl").toString();
        }
        
    }
