
    import {Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { ApplicationModule, ApplicationStore } from '@/store/ApplicationStore'
    import * as Models from '@/models/RowShare';
    import { Location as RouterLocation } from "vue-router";
    import { ArrayUtils } from '@/utils/Array';
    import PageLoadSpinner from "@/views/layouts/LayoutParts/PageLoadSpinner.vue";
    import RowCard from "@/views/Table/CardView/RowCard.vue";
    import Fuse, { IFuseOptions } from 'fuse.js';
    import { Utils } from "@/utils/Utilities";
    import { CommandEvent } from '@/models/RowShare';
    import RowVM from '@/viewModels/Table/rowVM';
    import ListVM from '@/viewModels/Table/listVM';
    //import Fuse from 'fuse.js';

    @Component({name: "CardView", components: { PageLoadSpinner, RowCard }})
    export default class CardView extends Vue {
        @Prop() listVM!: ListVM;
        @Prop({default: false }) reload !: boolean;
        @Prop() scrollToRowId!: string;
        @Prop({default: false}) selectionMode!: boolean;
  
        //private cardViewVM = new CardViewVM(this.listVM);

        private searchTerm : string = "";
        private searchEngine: Fuse<RowVM> | null = null;
        private currentlyRenderedRows: Array<RowVM> = new Array<RowVM>();

        get rowArray(): any[] | null {
            if(!this.listVM.rowVMs)
                return null;

            let rows = [];
            if (this.searchEngine === null || Utils.isNullOrWhiteSpace(this.searchTerm))
                rows = this.listVM.rowVMs;
            else
                rows = this.searchEngine.search(this.searchTerm).map((result) => result.item);

            if(!rows)
                return new Array<RowVM>();

            return ArrayUtils.sort(rows, [(row: RowVM) => row.rsRow.Index], this.sortDirection);
        }

        get canAddRow(): boolean {
            return this.listVM.list != null && this.listVM.list.CanCreate;
        }

        public resetSearchEngine() {
            this.search = "";
            this.initSearch();
        }

        async mounted(){
            this.initListInfo();
            this.initSearch();
            this.$nextTick(() => {
                this.scrollToRow();
            });
        }

        @Watch('listVM.rowVMs')
        onRowVMsChanged() {
            this.initSearch();
            this.$nextTick(() => {
                this.scrollToRow();
            });
        }

        @Watch('scrollToRowId')
        onScrollToRowIdChanged() {
            if(!this.selectionMode) {
                this.$nextTick(() => {this.scrollToRow()});
            }
        }

        scrollToRow() {
            let scrollIndex: number | undefined = undefined;
            if(this.$route.name === 'Form' && this.$route.params.rowId === 'new') {
                if(this.listVM.rowVMs) {
                    scrollIndex = this.listVM.rowVMs.length - 1;
                }
            }
            else if(this.scrollToRowId && this.rowArray && this.listVM.rowVMs){
                scrollIndex = this.rowArray.findIndex(r => r.rsRow.Id === this.scrollToRowId);
            }

            if(scrollIndex !== undefined && scrollIndex > -1) {
                let element = <HTMLElement>((<any>this.$refs.scroller).$el);
                if(element != undefined) {
                    element.scroll({ top: 148 * scrollIndex, behavior:'smooth'}); // 148 * idx
                }
            }
        }

        @Watch("listVM.list")
        onListChanged(){
            this.initListInfo();
            this.initSearch();
        }

        async initListInfo(){
            if(!this.listVM.list)
                return;

            this.description = this.listVM.list.Description ?? '';
            ApplicationModule.updateAppCommands(this.setCommand());
            
            let previousLocation = this.listVM.list.FolderId ?
                    <RouterLocation>{ 
                        name: 'MyTables',
                        params: { 
                            folderId: this.listVM.list.FolderId,
                            organizationId: this.listVM.list.OrganizationId
                        }
                    } : '';
            ApplicationModule.updateAppPreviousLocation(previousLocation);
        }

        createRow() : void {
            if(!this.listVM.list)
                return;

            this.$router.push({ 
                name: 'Form',
                params: { listId: this.listVM.list.Id, rowId: 'new' },
            });
        }

        /** Search **/

        get appStore(): ApplicationStore {
            return ApplicationStore.state;
        }

        pSearch: string | null = null;

        get search(){
            return this.pSearch;
        }
        set search(val){
            this.pSearch = val;

            Utils.debounce(500, () => {
                this.searchTerm = this.pSearch ?? '';
            })();
        }

        initSearch() : void {
            if (this.listVM.rowVMs == null || this.listVM.rowVMs.length == 0)
                return;

            let propertyNames = Object.getOwnPropertyNames(this.listVM.rowVMs[0].rsRow.Values);
            let propertyNamesPrefixed = propertyNames.map((propName) => { return {name: propName, getFn: (row: RowVM) => row.rsRow.Values[propName]} });//`rsRow.Values.${name}`);
            propertyNamesPrefixed.push({name: 'DescriptorFormattedValue', getFn: (row:RowVM) => row.rsRow.DescriptorFormattedValue});
            const options: IFuseOptions<RowVM> = {
                shouldSort: true,
                threshold: 0.1,
                minMatchCharLength: 2,
                distance: 5000,
                keys: propertyNamesPrefixed //[/*'rsRow.DescriptorFormattedValue'*/, ...propertyNamesPrefixed]
            };
            this.searchEngine = new Fuse(this.listVM.rowVMs, options);
            this.searchTerm = "";
        }

        /** Sort **/

        private sortDirection : boolean | 'asc' | 'desc' = false;

        get sorterIcon() : string {
            return this.sortDirection == 'asc' || this.sortDirection === false ? 
                    "sort-alpha-up-alt" : "sort-alpha-up";
        }

        toggleSortDirection(){
            this.sortDirection = 
                this.sortDirection === 'asc' || this.sortDirection === false ? 
                    'desc' : 'asc';
        }

        /** Registering search and description as layout commands **/

        setCommand(): Array<Models.CommandEvent> {
            let commands = new Array<Models.CommandEvent>();

            if(this.description && this.description != ''){
                let dialog = new Models.CommandEvent();
                dialog.order = 1;
                dialog.icon = 'info-circle';
                dialog.eventName = Models.Event.SHOW_DESCRIPTION_DIALOG;
                dialog.color = '#fff';
                dialog.isToggable = false;
                dialog.isActive = false;

                commands.push(dialog);
            } 

            return commands;
        }

        /** Description **/

        private description: string | null = null;

        get dialogDisplay() : boolean {
            let descriptionCommand = ApplicationModule
                                         .mobileAppCommands
                                         .find((c: CommandEvent) => c.eventName == Models.Event.SHOW_DESCRIPTION_DIALOG);
            if(descriptionCommand){
                return descriptionCommand.isActive;
            }
            else{
                return false;
            }
        }

        toggleDescriptionDisplay() : void {
            ApplicationModule.toggleDescriptionDisplay();
        }

        onCardClick(row: RowVM) {
            this.$emit('cardClick', row);
        }

        onCardDblClick(row: RowVM) {
            this.$emit('cardDblClick', row);
        }
    }
