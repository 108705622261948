import * as RowShare from '@/models/RowShare';
import * as agGrid from 'ag-grid-community';
import * as API from '@/api/Api';
import RsColumnManager from '@/columnProxies/RsColumnManager';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import { Settings } from "@/utils/Settings";
import { EventBus } from '@/modules/EventBus';
import i18n from '@/modules/Localization';
import RowVM from './rowVM';
import { ColumnStrongType } from '@/models/RowShare';
import FileVM from '@/viewModels/Table/FileVM';
import rowVM from './rowVM';
import GridViewVM from './gridViewVM';
import { Utils } from '@/utils/Utilities';
import { cl } from '@fullcalendar/core/internal-common';
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

export default class ColumnVM implements agGrid.ColDef{
    public static readonly rowOwnerHeaderId = "_rowOwnerHeader";
    public static readonly addColumnHeaderId= "_addColumnHeader";
    public static readonly insertBeforeRowIdId= "_insertBeforeRowId";
    public static readonly autoGroupColumnId = "ag-Grid-AutoColumn";
    public static readonly statusChangeDateTimeId = "_statusChangedDateTime";
    public static readonly statusChangeByUserId = "_statusChangeByUser";

    headerName?: string;
    colId?: string;
    valueGetter?: ((params: agGrid.ValueGetterParams) => any) | string;
    valueSetter?: ((params: agGrid.ValueSetterParams) => boolean) | string;
    width?: number;
    minWidth?: number;
    cellRenderer?: { new (): agGrid.ICellRendererComp; } | agGrid.ICellRendererFunc | string;
    cellRendererSelector?: agGrid.CellRendererSelectorFunc;    
    cellEditor?: { new (): agGrid.ICellEditorComp; } | string;
    tooltipComponent?: { new (): agGrid.ITooltipComp; } | string;
    tooltipValueGetter?: (params: agGrid.ITooltipParams) => string;
    sortable?: boolean = true;
    comparator?: (valueA: any, valueB: any, nodeA: agGrid.IRowNode, nodeB: agGrid.IRowNode, isInverted: boolean) => number;
    resizable?: boolean = true;
    editable?: boolean | ((params: agGrid.EditableCallbackParams) => boolean);
    filter?: string | { new (): agGrid.IFilterComp; } | boolean = true;
    filterParams?: any;
    getQuickFilterText?: (params: agGrid.GetQuickFilterTextParams) => string;
    cellClassRules?: agGrid.CellClassRules;
    cellClass?: string | string[] | ((cellClassParams: agGrid.CellClassParams) => string | string[]);
    headerClass?: string | string[] | ((params: any) => string | string[]);
    type?: string | string[];
    suppressMenu?: boolean;
    suppressKeyboardEvent?: (params: agGrid.SuppressKeyboardEventParams) => boolean;
    aggFunc?: string | agGrid.IAggFunc | null;
    hide?: boolean;
    pinned?: boolean | 'left' | 'right' | null;
    chartDataType?: 'category' | 'series' | 'time' | 'excluded';
    enableRowGroup?: boolean;
    enablePivot?: boolean;
    enableValue?: boolean;
    toolPanelClass?: string | string[] | ((params: any) => string | string[]);    
    autoHeight?: boolean;
    wrapText?: boolean;
    lockPinned?: boolean;
    keyCreator?: (params: agGrid.KeyCreatorParams<any>) => string;
    columnGroupShow?: agGrid.ColumnGroupShowType;

    cellLocked: boolean = false;

    rsList: RowShare.List;
    rsColumn: RowShare.Column;

    columnProxy: BaseColumnProxy;
    printMode: boolean = false;

    constructor(rsList: RowShare.List, rsColumn: RowShare.Column, listSettings?: RowShare.ListSettings | null, printMode?: boolean){
        this.columnProxy = RsColumnManager.getProxy(rsColumn.Type);
        
        this.colId = rsColumn.Id;
        this.headerName = rsColumn.DisplayName;

        this.valueGetter = (params) => {
            let rsCol = (<ColumnVM>params.colDef).rsColumn;
            if(rsCol?.isOwnerInfo) {
                return params.data?.getAssignation()
            }
            return params.data?.getValue((<ColumnVM>params.colDef).rsColumn, this.columnProxy);
        }
        this.valueSetter = (params) => {
            let rsCol = (<ColumnVM>params.colDef).rsColumn;
            if(rsCol?.isOwnerInfo) {
                return params.data?.setAssignation(params.newValue);
            }
            return params.data?.setValue(rsCol, params.newValue);
        }
        this.tooltipValueGetter = params => params.value ? '-' : ''; // empty string will prevent opening the tooltip, and tooltip renderer won't use this value to display its content
        
        let savedWidth = Settings.getColumnProperty<number>(rsList.Id, rsColumn.Id, 'width') ?? 0;
        this.width = (savedWidth > 0 ? savedWidth : (rsColumn.DefaultWidth > 0 ? rsColumn.DefaultWidth : 150));
        this.editable = this.checkIsEditable.bind(this);
        if(rsColumn.isNumber) {
            this.type = "numericColumn";
            this.chartDataType = 'series';
            if(rsColumn.Type !== ColumnStrongType.RowIndex && rsColumn.Type !== ColumnStrongType.AutoNumber) {
                this.toolPanelClass = 'column-numeric';
            }
        }
        this.rsColumn = new RowShare.Column();
        this.rsList = rsList;
        Object.assign(this.rsColumn, rsColumn);
        this.lockPinned = true;

        if(this.rsList.RowsAutoHeight && (rsColumn.Type ?? "") == ColumnStrongType.RichText) {
            this.autoHeight = true;
        }

        this.cellRendererSelector = (params) => {
            var row = <rowVM>params.data;
            if(row?.isTotalRow) {
                return {component: "TotalRowRenderer"};
            }
            if(row?.getValue(this.rsColumn, this.columnProxy) === RowVM.cellNotAccessible) {
                return { component: "ProxyBasedRenderer" };
            }
            return  { component: this.columnProxy.getRenderer(rsColumn)};
        }
        if(this.rsColumn.HasParentColumn && this.rsColumn.RelatedListId) {
            this.cellEditor = "RelationEditor";
        }
        else if(this.rsColumn.isOwnerInfo) {
            this.cellEditor = "RowOwnerHeaderEditor";
        }
        else {
            this.cellEditor = rsColumn.Type.toString() + "Editor";
        }
        this.tooltipComponent = 'ProxyBasedTooltipRenderer';
        
        let customFilter = this.columnProxy.getCustomFilter(this.rsColumn);
        if(customFilter)
            this.filter = customFilter;

        let customFilterParams = this.columnProxy.getCustomFilterParams(this.rsColumn);
        if(customFilterParams)
            this.filterParams = customFilterParams;

        this.getQuickFilterText = (params: agGrid.GetQuickFilterTextParams) => {
            return this.columnProxy.getQuickFilterText((<ColumnVM>params.colDef).rsColumn, params.data, params.value);
        };

        this.suppressKeyboardEvent = (params) => {
            if(params && params.editing && this.columnProxy.shouldSuppressKeyboardEvent(this.rsColumn)) {
                return true;
            }
            return false;
        };

        this.cellClassRules = ColumnVM.getCellClassRules(rsColumn, rsList);
        this.cellClass = (params: agGrid.CellClassParams) =>  ColumnVM.getCellClasses(params.data?.rsRow, this.rsList, this.rsColumn);// [rsColumn.Type + '-col'];
        this.headerClass = (params: agGrid.HeaderClassParams) => ColumnVM.getHeaderClasses(this.rsList, this.rsColumn)
        if(listSettings){
            this.hide = ColumnVM.isHiddenColumn(rsColumn, listSettings);
        }
        if(rsColumn.isCompact) {
            this.minWidth = 5;
        }
        this.pinned = rsColumn.LeftPinned;
        if(printMode != undefined) {
            this.printMode = printMode;
        }

        if(this.rsColumn.isOwnerInfo || this.rsColumn.isDateFormat || this.rsColumn.isDateTimeFormat) {
            this.keyCreator = (params: any) => this.columnProxy.getFormattedText(this.rsColumn, params.data, params.value);
        }
        
        this.comparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            // we need to check if rows are new first to ensure they will appear at the end
            // checking B first is required when adding multiple rows one after another (the newest row will be B)

            if(nodeB?.data?.isNew)
                return isInverted ? 1 : -1;
            if(nodeA?.data?.isNew)
                return isInverted ? -1 : 1;
        
            // this is used to always sort empty values last
            if (valueA === null || valueA === undefined || valueA === "")
                return isInverted ? -1 : 1;
            if (valueB === null || valueB === undefined || valueB === "")
                return isInverted ? 1 : -1;

            let comparedValues = this.columnProxy.compareValues(this.rsColumn, valueA, valueB, nodeA, nodeB);
            if(comparedValues != null) {
                return comparedValues;
            }

            // text values should be sorted regardless of the case
            let a = valueA, b = valueB;
            switch(this.rsColumn.Type) {
                case ColumnStrongType.Text:
                case ColumnStrongType.RichText:
                case ColumnStrongType.Email:
                case ColumnStrongType.Hyperlink:
                case ColumnStrongType.MemberManagerEmail:
                case ColumnStrongType.MemberManagerName:
                case ColumnStrongType.LastWriteUserEmail:
                case ColumnStrongType.LastWriteUserEmail:
                case ColumnStrongType.CreatedByUserEmail:
                case ColumnStrongType.CreatedByUserName:
                    a = Utils.normalizeString(a) ?? "" ;
                    b = Utils.normalizeString(b) ?? "";
                    break;
            }

            // if lower case equal, use a case sensitive sort
            if (a === b) {
                if(valueA !== valueB)
                    return valueA > valueB ? 1 : -1;

                return 0;
            }
            
            return a > b ? 1 : -1;
        }
        this.enablePivot = this.columnProxy.enablePivot;
        this.enableRowGroup = this.columnProxy.getEnableRowGrouping(this.rsColumn);
        this.enableValue = this.columnProxy.getEnableAggregate(this.rsColumn);
    }

    private checkIsEditable(params: agGrid.EditableCallbackParams): boolean {
        const colVM = <ColumnVM>params.colDef;
        /*if (colVM.cellLocked) {
            return false;
        }*/

        if(params.data?.listVM.specialRowsDisplayed) {
            return false;
        }
        var row = (<RowVM>params.data);
        if(row?.rsRow && row.rsRow.Values[this.rsColumn.DisplayName] === rowVM.cellNotAccessible) {
            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                title: i18n.t("Notif_NotEditableCell").toString(),
                autoHide: true,
                autoHideAfterMs: 2000
            });
            return false;
        }

        if(this.columnProxy.isAlwaysEditable()) {
            return true;
        }

        if(this.rsColumn.isOwnerInfo) {
            if(row.isNew) {
                return false;
            }
            if(!params?.data?.rsRow?.CanAssign && !row.isNew) {
                EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams> {
                    title: i18n.t('Notif_CannotAssignRow').toString(),
                    autoHide: true,
                    autoHideAfterMs: 2000
                });
                return false;
            }
        }

        if(!params?.data?.rsRow?.CanUpdate) {
            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                title: i18n.t("Notif_ReadOnlyRow").toString(),
                autoHide: true,
                autoHideAfterMs: 2000
            });
            return false;
        }

        if(params?.data?.isLocked) {
            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                title: i18n.t("Notif_LockedRow").toString(),
                autoHide: true,
                autoHideAfterMs: 2000
            });
            return false;
        }

        if(params?.data?.editionInProgress) {
            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                title: i18n.t("Rtc_Notif_Editing_Row").toString(),
                autoHide: true,
                autoHideAfterMs: 2000
            });
            return false;
        }

        return !this.rsColumn.IsReadOnly;
    }

    private static isHiddenColumn(rsColumn: RowShare.Column, settings: RowShare.ListSettings): boolean {
        if(settings?.configuration?.hiddenColumns) {
            return settings.configuration.hiddenColumns.findIndex(hc => hc === rsColumn.Id) > -1;
        }
        return false;
    }

    public static getHeaderClasses(rsList: RowShare.List, rsColumn: RowShare.Column) {
        let classes: string[] = [];
        if(!rsList || !rsColumn) {
            return classes;
        }
        classes.push(rsColumn.Type + '-col-header');

        if(rsColumn.ColumnGroup) {
            classes.push('column-grouped');
        }
        
        if(rsColumn.HasParentColumn ?? false) {
            classes.push('column-relation');
        }

        if(rsColumn.isCompact) {
            classes.push('compact-col-header');
        }

        if(rsColumn.Color && (rsColumn.ColorType === RowShare.ColumnColorType.All || rsColumn.ColorType === RowShare.ColumnColorType.Header)) {
            classes.push(rsColumn.Color);
        }

        return classes;
    }

    public static getCellClasses(rsRow: RowShare.Row, rsList: RowShare.List, rsColumn: RowShare.Column) {
        let classes: string[] = [];

        if(!rsRow || !rsList || !rsColumn) {
            return [];
        }

        classes.push(rsColumn.Type + '-col');

        if(rsColumn?.HasParentColumn ?? false) {
            classes.push('cell-relation');
        }
        if(rsColumn.isCompact) {
            classes.push('compact-col');
        }

        let colId: string = "";
        let rowId: string = "";

        colId = rsColumn.Id;
        rowId = rsRow.Id;

        if(colId && rowId) {
            let color = rsList.CellsColorsList.find(cc => cc.ColumnId == colId && cc.RowId == rowId);
            if(color) {
                classes.push(color.Color);
            }
            else {
                color = rsList.CellsColorsList.find(cc => cc.RowId == rowId && !cc.ColumnId);
                if(color) {
                    classes.push(color.Color);
                }
                else {
                    let columnColor = rsColumn.Color;
                    if(columnColor && (rsColumn.ColorType === RowShare.ColumnColorType.Cells || rsColumn.ColorType === RowShare.ColumnColorType.All)) {
                        classes.push(columnColor);
                    }
                }
            }

            const cell = RealTimeCollaborationModule
                .inEditionCells
                .find(x => x.rowId === rowId && x.columnId === colId);
            if (cell) {
                classes.push(...['cell-editing', 'cell-editing-color'+cell.backgroundColorIndex])
            }
        }

        return classes;
    }

    public static getCellClassRules(rsColumn: RowShare.Column | null = null, rsList: RowShare.List | null = null) : agGrid.CellClassRules {
        return {
            'grid-cell-error' : (params: any) => params.data?.hasCellError(rsColumn),
            'grid-cell-saving' : (params: any) => params.data?.isSavingCell(rsColumn),
            'grid-cell-numeric' : (params: any) => params.colDef.type === 'numericColumn',
            'ag-right-aligned-cell' : (params: any) => params.colDef.type === 'numericColumn',
            'grid-cell-datetime' : (params: any) => (params.colDef.rsColumn?.isDateFormat || params.colDef.rsColumn?.isDateTimeFormat),
            'grid-cell-alwayseditable' : (params: any) => rsColumn? (rsColumn.Type === RowShare.ColumnStrongType.Comment || rsColumn.Type === RowShare.ColumnStrongType.Hyperlink) : false,
            'left-data-cell': (params: agGrid.CellClassParams) => {
                let leftCol = params.columnApi.getDisplayedColBefore(params.column);
                return params.column?.getColId() != ColumnVM.rowOwnerHeaderId && (leftCol == null || leftCol.getColId() == ColumnVM.rowOwnerHeaderId);
            },
            'right-data-cell': (params: agGrid.CellClassParams) => {
                let colStates = params.columnApi.getColumnState().filter(cs => cs.colId != ColumnVM.rowOwnerHeaderId && cs.colId != ColumnVM.autoGroupColumnId && !cs.hide);
                if(colStates && colStates.length > 0) {
                    return params.colDef.colId == colStates[colStates.length - 1].colId;
                }
                return false;
            },
            'cell-not-accessible' : (params: any) => { 
                if(params.data?.rsRow && rsColumn) {
                    return params.data?.rsRow.Values[rsColumn.DisplayName] === rowVM.cellNotAccessible;
                }
                return false;
            },
            // 'cell-locked' : (params: any) : boolean => {
            //     const cell = RealTimeCollaborationModule
            //         .inEditionCells
            //         .find(x => x.rowId === params.data.rsRow.Id && x.columnId === params.colDef.colId);
            //     return !!cell;
            // }
        };
    }

    public static onColumnResized(event: agGrid.ColumnResizedEvent, printMode: boolean){
        if(!event.columns || (event.columns.length ?? 0) == 0 || !event.finished)
            return;
        
        event.columns.forEach((gridCol => {
            let colVM = <ColumnVM>gridCol.getColDef();
            if(printMode) {
                event.api.refreshHeader();
                return;
            }
            // Save special columns widths in localstorage
            if(colVM.colId === ColumnVM.autoGroupColumnId ||
                colVM.colId === ColumnVM.statusChangeByUserId ||
                colVM.colId === ColumnVM.statusChangeDateTimeId) {
                var listId = (<GridViewVM>event.context)?.listVM?.list?.Id;
                if(listId) {
                    Settings.setColumnProperty(listId, colVM.colId, "width", gridCol.getActualWidth());
                }
                return;
            }
    
            const rsColumn = colVM.rsColumn;
            if(!rsColumn)
                return;
    
            var newWidth = gridCol.getActualWidth();
            if(!colVM.rsList || rsColumn.DefaultWidth == newWidth)
                return;
    
            rsColumn.DefaultWidth = newWidth;
            Settings.setColumnProperty(colVM.rsList.Id, rsColumn.Id, "width", newWidth);
    
            if(colVM.rsList.Owned) {
                rsColumn.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
                API.Column.saveWidth(rsColumn);
            }
    
        }));
    }

    public static onColumnPinned(event: agGrid.ColumnPinnedEvent) {
        if(!event.columns) {
            return;
        }
        event.columns.forEach(column => {
            var colVM = <ColumnVM>column.getColDef();
            if(colVM) {
                if(colVM.rsList.Owned && colVM.colId) {
                    API.Column.savePinned(colVM.colId, (event.pinned === true || event.pinned === 'left'), RealTimeCollaborationModule.connection.connectionId)
                        .then(res => {
                            if(res) {
                                var index = -1;
                                index = event.columnApi.getColumnState().findIndex(col => event.columnApi.getDisplayedColAfter(column)?.getId() === col.colId);
                                if(!event.pinned) {
                                    index--;
                                }
                                if(index > -1) {
                                    event.columnApi.moveColumn(column, index);
                                }    
                            }
                        });
                }
            }    
        });
        event.api.refreshCells();
        event.api.refreshHeader();
    }

    public static processCellForClipboard(params: agGrid.ProcessCellForExportParams) {
        let colVM = <ColumnVM>params.column.getColDef();
        let rowVM = <RowVM>params.node?.data;
        if(rowVM?.isTotalRow) {
            return params.value;
        }
        if(colVM && colVM.columnProxy && params.node) {
            params.value = colVM.columnProxy.getClipboardValue(colVM.rsColumn, rowVM, params.value);
        }

        if(colVM.colId == ColumnVM.rowOwnerHeaderId && rowVM?.rsRow) {
            params.value = rowVM.rsRow.Owner.Name;
        }
        return params.value;
    }
}
