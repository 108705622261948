
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
    import { Hyperlink } from '@/models/RowShare';
    import RsColumnManager from '@/columnProxies/RsColumnManager';

    @Component({
        name: 'HyperlinkFormEditor',
        components:{
        }
    })
    export default class HyperlinkFormEditor extends FormEditor {     
        
        link: Hyperlink | null = null;

        init(){
            const proxy  = RsColumnManager.getProxy(this.column.Type);
            this.link = new Hyperlink(this.rowVM.getValue(this.column, proxy));
        }

        getValue() {
            if(this.link != null) {
                let cellValue = this.link.url;
                if(this.link.displayName) {
                    cellValue += "|" + this.link.displayName;
                }
                return cellValue;
            }
            return "";
        }

        onUrlTabPress(event: KeyboardEvent) {
            if(!event.shiftKey) {
                (<HTMLElement>(<Vue>this.$refs.displayNameField).$el).focus();
                event.stopPropagation();
            }
        }

        onNameTabPress(event: KeyboardEvent) {
            if(event.shiftKey) {
                (<HTMLElement>(<Vue>this.$refs.urlField).$el).focus();
                event.stopPropagation();
            }
        }


        get linkUrl(): string {
            if(!this.link) {
                return "";
            }
            return this.link.url ?? "";
        }

        set linkUrl(value: string) {
            if(this.link) {
                this.link.url = value;
            }
        }

        get linkName(): string {
            if(!this.link) {
                return "";
            }
            return this.link.displayName ?? "";
        }

        set linkName(value: string) {
            if(this.link) {
                this.link.displayName = value;
            }
        }

        get urlLabel(): string {
            return this.$i18n.t("HyperlinkEditor_urlLabel").toString();
        }

        get nameLabel(): string {
            return this.$i18n.t("HyperlinkEditor_nameLabel").toString();
        }

        get urlPlaceholder() {
            return this.$i18n.t("HyperlinkEditor_urlPlaceholder").toString();
        }

        get namePlaceholder() {
            return this.$i18n.t("HyperlinkEditor_namePlaceholder").toString();
        }

        get formattedLinkUrl(): string {
            if(this.link != null) {
                if(this.link.url == "") {
                    return "";
                }
                return this.link.url.startsWith('http') ? this.link.url : 'http://' + this.link.url;
            }
            return "";
        }
    }
