
import { DisplayedRows } from '@/models/Enums';
import { ListTreeModule } from '@/store/ListTreeStore';
import {Vue, Component} from 'vue-property-decorator';

@Component({name: 'emptyStateOverlay'})
export default class emptyStateOverlay extends Vue {

  get specialRowsDisplayed(): boolean {
      return (ListTreeModule.currentListDisplayedRows != DisplayedRows.Active);
  }
  get canAddRow(): boolean {
      return (ListTreeModule.currentList?.CanCreate ?? false);
  }
}

