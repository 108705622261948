import i18n from "@/modules/Localization";
import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";

export default class TotalRowHeaderRenderer implements ICellRendererComp {
    cellContainer!: HTMLElement;

    init(params: ICellRendererParams){
        this.cellContainer = document.createElement("div");
        this.cellContainer.classList.add("total-row-header-renderer");
        this.cellContainer.innerText = i18n.t("Aggregation_RowHeader").toString();
    }

    getGui(): HTMLElement {
        return this.cellContainer;
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}