
import {Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ColorPicker from '@/views/components/ColorPicker.vue';
import { Column, ColumnColorType } from '@/models/RowShare';

@Component({name: 'ColumnSettingsColorEditor', components: { ColorPicker }})
export default class ColumnSettingsColorEditor extends Vue {
    @Prop() currentColumn!: Column
    @Prop() helpText!: string;
    selectedColorTypes: any[] = [];
    selectedColor: string = '';

    @Watch('currentColumn')
    initCurrentColumnColorType() {
        switch(this.currentColumn?.ColorType) {
            case ColumnColorType.All:
                this.selectedColorTypes.push(...[ColumnColorType.Header, ColumnColorType.Cells]);
                break;
            case ColumnColorType.None:
                this.selectedColorTypes = [];
                break;
            default:
                this.selectedColorTypes.push(this.currentColumn.ColorType);
                break;
        }
        this.selectedColor = this.currentColumn.Color ?? '';
    }

    get headerCheckboxLabel(): string {
        return this.$i18n.t('ColumnSettings_ColorType_Header').toString();
    }

    get cellsCheckboxLabel(): string {
        return this.$i18n.t('ColumnSettings_ColorType_Cells').toString();
    }

    get headerCheckboxValue() {
        return ColumnColorType.Header;
    }

    get cellsCheckboxValue() {
        return ColumnColorType.Cells;
    }

    get colorTypesDisabled(): boolean {
        return this.currentColumn.Color === '';
    }

    get lightColor(): boolean {
        return ['color1','color2','color7','color12','color17','color21','color27'].findIndex(c => c === this.currentColumn?.Color) > -1 || !this.currentColumn?.Color;
    }

    onColorTypeChanged() {
        this.setColumnColorType();
        this.$emit('change');
    }

    onColorSelected() {
        if(this.selectedColor == undefined) {
            return;
        }
        if(this.currentColumn.Color !== this.selectedColor) {
            this.currentColumn.Color = this.selectedColor;
            if(this.selectedColor == "") {
                this.currentColumn.ColorType = ColumnColorType.None;
                this.selectedColorTypes = [];
            }
            else {
                if(this.selectedColorTypes.length == 0) {
                    this.selectedColorTypes.push(ColumnColorType.Header);
                    this.setColumnColorType();
                }
            }
            this.$emit('change');
        }
    }

    setColumnColorType() {
        if(this.selectedColorTypes.length == 0) {
            this.currentColumn.ColorType = ColumnColorType.None;
        }
        else if (this.selectedColorTypes.length == 1) {
            this.currentColumn.ColorType = this.selectedColorTypes[0];
        }
        else {
            this.currentColumn.ColorType = ColumnColorType.All;
        }        
    }

}
