
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as API from "@/api/Api";
import * as Models from "@/models/RowShare";
import _ from 'lodash';
import { EventBus } from "@/modules/EventBus";

@Component({
    name:'TableOwnerSelector'
})
export default class TableOwnerSelector extends Vue{
    
    @Prop() organizationId!:string
    @Prop() listId!:string;

    private displayAddForm: boolean = false;
    private listOwners: Array<Models.ListOwner> =[];
    private organizationMembers:Array<Models.Member> = [];
    private selectedUserEmail: string | null = null;

    private defaultProps = { maxHeight: 150 }
    
    closeInput(){
        let elem = this.$refs.field as HTMLFormElement;
        elem.blur();
    }

    async mounted(){
        let currentOwners = await API.List.loadOwners(this.listId);
        this.listOwners = currentOwners ? currentOwners : [];
        let currentOrganizationMembers = await API.Member.loadForParent(this.organizationId);
        this.organizationMembers = currentOrganizationMembers?.filter(m => m.IsEnabled) ? currentOrganizationMembers?.filter(m => m.IsEnabled) : [];
    }

    
    toggleAddForm(){
        this.displayAddForm = !this.displayAddForm;
    }

    textOption(item:Models.Member){
        return item.nameOrEmail + ' - ' + item.Email
    }

    isUserOwner(user: Models.Member) : boolean{
        let alreadyOwner = _.find(this.listOwners, { OwnerEmail: user.Email})
        return !!alreadyOwner;
    }

    async handleAddTableOwner(){
        if(this.selectedUserEmail){
            let newOwner = new Models.ListOwner();
            newOwner.ListId = this.listId;
            newOwner.OwnerEmail = this.selectedUserEmail;
            newOwner.OwnerOrganizationId = this.organizationId;
            let addedOwner = await API.List.saveOwner(newOwner);
            if (addedOwner){
                this.listOwners.push(addedOwner);
                this.selectedUserEmail = null;
                this.toggleAddForm();
                EventBus.$emit(Models.Event.LISTINFO_CHANGED);
            }
        }    
    }

    async removeOwner(owner: Models.ListOwner){
        if(this.listOwners.length>1){
            let removedOwner = await API.List.deleteOwner(owner);
            if(removedOwner) {
                const index = this.listOwners.indexOf(owner, 0);
                if (index > -1) {
                    this.listOwners.splice(index, 1);
                }
                EventBus.$emit(Models.Event.LISTINFO_CHANGED);
            }
        }   
    }

}
