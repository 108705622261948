import * as RowShare from '@/models/RowShare';
import { BreadcrumbFolder } from '@/models/RowShare';
import { JsonObjectSearch } from '@/utils/Search';

export class ListTreeHelper {

    private static retentionDelay : number = 5000; //5 seconds
    static getFolderInOrganization(listTree: RowShare.ListTree, organizationId: string, folderId: string | null): [Array<RowShare.BreadcrumbFolder>, RowShare.ListTreeFolder] | null {
        if (listTree === null || organizationId === null || organizationId === undefined)
            return null;

        let orgTree = this.searchFullOrganizationTree(listTree, organizationId);
        if (orgTree == null)
            return null;

        if (!folderId || folderId == orgTree.RootFolder.Id)
            return [[{ Id: '', Name: orgTree.Name, IsRootFolder: true, IsTag: false }], orgTree.RootFolder];

        let folderTree = ListTreeHelper.findSubfolder(listTree, orgTree.RootFolder, folderId);
        if (folderTree) {
            folderTree[0].unshift({ Id: '', Name: orgTree.Name, IsRootFolder: true, IsTag: false });
        }
        return folderTree;
    }

    static getOrganizationTreeOrDefault(listTreeOrganizations: RowShare.ListTree, listTree: RowShare.ListTree | null, organizationId: string | null): RowShare.ListTreeOrganization | null | undefined {
        if (listTreeOrganizations === null || listTreeOrganizations.Organizations.length <= 0)
            return null;

        let currentOrg: RowShare.ListTreeOrganization | null | undefined;
        if (!organizationId) {
            currentOrg = listTreeOrganizations.Organizations.find(o => o.IsDefault);
            if (!currentOrg)
                currentOrg = listTreeOrganizations.Organizations[0];
        } else {
            currentOrg = listTreeOrganizations.Organizations.find(o => o.Id == organizationId);
            // switch back to default if an invalid ID is provided
            if (!currentOrg)
                currentOrg = ListTreeHelper.getOrganizationTreeOrDefault(listTreeOrganizations, listTree, null);
        }
        //we have now the current organization and its root folder from the listTreeOrganizations
        if (currentOrg && !listTree) {
            return currentOrg;
        }
        else if (currentOrg && listTree) {
            return ListTreeHelper.searchFullOrganizationTree(listTree, currentOrg.Id);
        }
        return null
    }


    static isLocalStoredAllOrganizationsFresh(): boolean {
        var locaStorageLastUpdate = localStorage.getItem("AllOrganizationsLastUpdate");
        if (!locaStorageLastUpdate)
            return false;

        let lastUpdateTS = parseInt(locaStorageLastUpdate);
        // only using localstorage org if it was saved less than retention delay
        if (lastUpdateTS > ((new Date()).getTime() - (ListTreeHelper.retentionDelay)))
            return true;
        else
            return false;
    }

    static isLocalStoredOrganizationFresh(orgId: string): boolean {

        var lastOrgId = localStorage.getItem("LastOrganizationId");
        if (!lastOrgId || (lastOrgId != orgId))
            return false;

        var locaStorageLastUpdate = localStorage.getItem("CurrentOrganizationLastUpdate");
        if (!locaStorageLastUpdate)
            return false;

        let lastUpdateTS = parseInt(locaStorageLastUpdate);
        // only using localstorage org if it was saved less than retention delay
        if (lastUpdateTS > ((new Date()).getTime() - (ListTreeHelper.retentionDelay)))
            return true;
        else
            return false;
    }

    static setLocalStoreOrganizationsTree(organizationsTree: RowShare.ListTree) {
        if (organizationsTree.Organizations.length > 0) {
            localStorage.setItem("AllOrganizationsLastUpdate", (new Date()).getTime().toString());
            localStorage.setItem("AllOrganizationsTree", JSON.stringify(organizationsTree));
        }
        else
        {
            this.resetLocalStoreOrganizationsTree();
        }
    }

    static resetLocalStoreOrganizationsTree() {
        localStorage.removeItem("AllOrganizationsLastUpdate");
        localStorage.removeItem("AllOrganizationsTree");
    }

    static getLocalStoreListTree(): RowShare.ListTree | null {
        let storedOrg = localStorage.getItem("CurrentOrganization");
        if (!storedOrg)
            return null;
        let org: RowShare.ListTree = JSON.parse(storedOrg);
        return org;
    }

    static resetLocalStoreCurrentOrganization() {
        localStorage.removeItem("CurrentOrganizationLastUpdate");
        localStorage.removeItem("CurrentOrganizationId");
        localStorage.removeItem("CurrentOrganization");
    }

    static setLocalStoreCurrentOrganization(listTree: RowShare.ListTree) {
        localStorage.setItem("CurrentOrganizationLastUpdate", (new Date()).getTime().toString());
        localStorage.setItem("LastOrganizationId", listTree.Organizations[0].Id);
        localStorage.setItem("CurrentOrganizationId", listTree.Organizations[0].Id);
        localStorage.setItem("CurrentOrganization", JSON.stringify(listTree));
    }

    static getLocalStoreOrganizationsTree(): RowShare.ListTree | null {
        if (ListTreeHelper.isLocalStoredAllOrganizationsFresh()) {
            let storedOrgs = localStorage.getItem("AllOrganizationsTree");
            if (!storedOrgs)
                return null;
            let orgs: RowShare.ListTree = JSON.parse(storedOrgs);
            return orgs;
        }
        return null;
    }

    static getPathElementsOrDefault(listTree: RowShare.ListTree, organizationId: string, folderId: string | null): { organization: RowShare.ListTreeOrganization, folder: RowShare.ListTreeFolder | null, breadcrumb: BreadcrumbFolder[] | null } {
        if (!listTree || !listTree.Organizations || listTree.Organizations.length<=0)
            throw new Error("Organization not found");
        var organization = listTree.Organizations[0];
        let { folder, breadcrumb }: { folder: RowShare.ListTreeFolder | null; breadcrumb: RowShare.BreadcrumbFolder[] | null; } = ListTreeHelper.getFolderAndBreadCrumb(listTree, organizationId, folderId);
        return { organization, folder, breadcrumb };
    }

    public static getFolderAndBreadCrumb(listTree: RowShare.ListTree, organizationId: string, folderId: string | null) {
        let folderAndBreadcrumb = ListTreeHelper.getFolderInOrganization(listTree, organizationId, folderId);
        let folder: RowShare.ListTreeFolder | null = null;
        let breadcrumb: RowShare.BreadcrumbFolder[] | null = null;
        if (folderAndBreadcrumb && folderAndBreadcrumb.length >= 2) {
            folder = folderAndBreadcrumb[1];
            breadcrumb = folderAndBreadcrumb[0];
        }
        return { folder, breadcrumb };
    }

    static IsKnownList(listTree: RowShare.ListTree | null, listId: string) : boolean{
        if (listTree == null)
            return false;
            const orgs = listTree.Organizations;
        for (let orgIdx = 0; orgIdx < orgs.length; ++orgIdx) {
                var org = orgs[orgIdx];
                var listInOrg = ListTreeHelper.findListInFolder(listTree, org, org.RootFolder, listId);
                if (listInOrg)
                    return true;
        }
         return false;

    }

    static getPathElementsForList(listTree: RowShare.ListTree | null, listId: string): { organization: RowShare.ListTreeOrganization, folder: RowShare.ListTreeFolder | null, breadcrumb: BreadcrumbFolder[] | null } | null {
        if (listTree == null)
            return null;

        const orgs = listTree.Organizations;
        for (let orgIdx = 0; orgIdx < orgs.length; ++orgIdx) {
            var org = orgs[orgIdx];
            var listInOrg = ListTreeHelper.findListInFolder(listTree, org, org.RootFolder, listId);
            if (listInOrg)
                return { organization: org, folder: listInOrg[1], breadcrumb: listInOrg[0] };
        }
        return null;
    }

    static findListInFolder(listTree: RowShare.ListTree, org: RowShare.ListTreeOrganization, folder: RowShare.ListTreeFolder, listId: string): [Array<RowShare.BreadcrumbFolder>, RowShare.ListTreeFolder] | null {
        let directChild = folder.Lists.find(l => l.Id == listId);
        if (directChild) {
            if (folder.Id == org.RootFolder.Id)
                return [[{ Id: "", Name: org.Name, IsRootFolder: true, IsTag: false }], folder];
            else
                return [[{ Id: folder.Id, Name: folder.Name, IsRootFolder: false, IsTag: false }], folder];
        }

        for (let i = 0; i < folder.Folders.length; ++i) {
            var tmpRes = ListTreeHelper.findListInFolder(listTree, org, folder.Folders[i], listId);
            if (tmpRes) {
                if (folder.Id == org.RootFolder.Id)
                    tmpRes[0].unshift({ Id: "", Name: org.Name, IsRootFolder: true, IsTag: false });
                else
                    tmpRes[0].unshift({ Id: folder.Id, Name: folder.Name, IsRootFolder: false, IsTag: false });

                return tmpRes;
            }
        }

        return null;
    }

    static findSubfolder(listTree: RowShare.ListTree, parentFolder: RowShare.ListTreeFolder, folderId: string): [Array<RowShare.BreadcrumbFolder>, RowShare.ListTreeFolder] | null {
        let directChild = parentFolder.Folders.find(f => f.Id == folderId);
        if (directChild)
            return [[{ Id: directChild.Id, Name: directChild.Name, IsRootFolder: false, IsTag: false }], directChild];

        for (let i = 0; i < parentFolder.Folders.length; ++i) {
            var tmpRes = ListTreeHelper.findSubfolder(listTree, parentFolder.Folders[i], folderId);
            if (tmpRes) {
                tmpRes[0].unshift({ Id: parentFolder.Folders[i].Id, Name: parentFolder.Folders[i].Name, IsRootFolder: false, IsTag: false });
                return tmpRes;
            }
        }

        return null;
    }

    static getAuthorizedPaths(listTree: RowShare.ListTree, folder: RowShare.ListTreeFolder, organizationId: string, res: Array<any>) {
        if (!folder)
            return;

        if (folder.CanCurrentUserCreateList && !folder.IsRecycleBin) {
            res.push({ text: folder.Path, value: { folderId: folder.Id, organizationId: organizationId } });
        }
        if (folder.Folders.length > 0) {
            folder.Folders.forEach(subFolder => {
                ListTreeHelper.getAuthorizedPaths(listTree, subFolder, organizationId, res);
            });
        }

    }

    static getRecycleBin(listTree: RowShare.ListTree, organizationId: string): RowShare.ListTreeFolder | null {
        let organizationTree = ListTreeHelper.searchFullOrganizationTree(listTree, organizationId);
        if (organizationTree) {
            let folderRecycleBin = organizationTree.RootFolder.Folders.find(folder => folder.IsRecycleBin);
            if (folderRecycleBin)
                return folderRecycleBin;
        }
        return null;
    }

    static getFolderByNameInParentFolder(listTree: RowShare.ListTree, organizationId: string, parentFolderId: string | null, name: string): RowShare.ListTreeFolder | null {
        let parentFolder = ListTreeHelper.getFolderInOrganization(listTree, organizationId, parentFolderId);
        if (parentFolder) {
            let folder = parentFolder[1].Folders.find(f => f.Name.toLowerCase() == name.toLowerCase());
            if (folder)
                return folder;
        }
        return null;
    }

    static getListByNameInParentFolder(listTree: RowShare.ListTree, organizationId: string, parentFolderId: string | null, name: string): RowShare.ListThumbnailInfo | null {
        let parentFolder = ListTreeHelper.getFolderInOrganization(listTree, organizationId, parentFolderId);
        if (parentFolder) {
            let list = parentFolder[1].Lists.find(f => f.Name.toLowerCase() == name.toLowerCase());
            if (list)
                return list;
        }
        return null;
    }

    static searchFullOrganizationTree(listTree: RowShare.ListTree, organizationId: string) {
        let searchedOrganization = listTree ?
            listTree.Organizations.find((f) => f.Id == organizationId) :
            null;
        return searchedOrganization;
    }
}
