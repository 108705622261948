import { ICellRendererParams, ITooltipComp, ITooltipParams } from "ag-grid-community";
import RowVM from "@/viewModels/Table/rowVM";
import ColumnVM from "@/viewModels/Table/columnVM";
import RsColumnManager from "@/columnProxies/RsColumnManager";
import i18n from "@/modules/Localization";

/**
 * Generic renderer that uses the methods provided by the ColumnProxy from ColumnVM
 */
export default class ProxyBasedTooltipRenderer implements ITooltipComp {
    cellContainer!: HTMLElement;
    cellValue!: HTMLElement;
    cellIcon!: HTMLElement;

    init(params: ITooltipParams){
        const rowVM = <RowVM>params.data;
        const colVM = <ColumnVM>params.colDef;
        
        const proxy  = RsColumnManager.getProxy(colVM.rsColumn.Type);
        let val = rowVM?.getValue(colVM.rsColumn, proxy); 

        this.cellContainer = document.createElement("div");
        this.cellContainer.classList.add("cell-tooltip-container");
        let wrapperClass = colVM.columnProxy.getRendererWrapperClass(colVM.rsColumn, rowVM, val);
        if(wrapperClass) {
            this.cellContainer.classList.add(wrapperClass);
        }
        
        let html = "";
        if(val === RowVM.cellNotAccessible) {
            html = "<span style='white-space: pre;'>" + i18n.t("Notif_NotAccessibleCell").toString() + "</span>";
        }
        else {
            html = colVM.columnProxy.getFormattedHtmlTooltip(colVM.rsColumn, rowVM, val) ?? "";            
        }

        // optional cell icon
        var cellIconClasses = colVM.columnProxy.getCellIconClasses(colVM.rsColumn, rowVM, val);
        if(cellIconClasses){
            this.cellIcon = document.createElement("i");
            this.cellIcon.classList.add("v-icon", "notranslate", "theme--light", "grid-cell-icon", "mr-2");
            for(let c of cellIconClasses)
                this.cellIcon.classList.add(c);
            
            if(!html) {
                this.cellIcon.style.display = 'none';
            }

            this.cellContainer.appendChild(this.cellIcon);
        }

        this.cellValue = document.createElement("span");
        this.cellValue.classList.add("content");
        this.cellValue.innerHTML = html;
        this.cellContainer.appendChild(this.cellValue);
    }

    getGui(): HTMLElement {
        return this.cellContainer;
    }
}