import * as RowShare from "@/models/RowShare";
import { i18n } from "@/modules/Localization";

export class ListAccess {
    Id!: string;    
    Options!: RowShare.AccessOptions;
    UserEmail!: string;
    GroupId!: string;
    GroupName!: string;
    MemberEmail!: string;
    MemberOrganizationId!: string;
    MemberName!: string;
    ListId!: string;
    Guest!: RowShare.Owner;
    IsMyOrganization!: boolean;
    Create!: boolean;
    Read!: AccessLevel;
    Update!: AccessLevel;
    Suppress!: AccessLevel;
    Assign!: AccessLevel;

    public get AccessMode() : AccessMode {
        switch (this.Read) {
            case AccessLevel.none:
                return AccessMode.None;
            case AccessLevel.ownedOnly:
                if(this.Create && this.Update == AccessLevel.ownedOnly && this.Suppress == AccessLevel.ownedOnly && this.Assign == AccessLevel.ownedOnly) {
                    return AccessMode.WriteOwnedOnly;
                }
                else {
                    return AccessMode.ReadOwnedOnly;
                }
            case AccessLevel.all:
                if(this.Create && this.Update == AccessLevel.all && this.Suppress == AccessLevel.all && this.Assign == AccessLevel.all) {
                        return AccessMode.Write;
                }
                else if(this.Create  && this.Update == AccessLevel.ownedOnly && this.Suppress == AccessLevel.ownedOnly && this.Assign == AccessLevel.ownedOnly) {
                    return AccessMode.ReadWriteOwnedOnly;
                }
                else {
                    return AccessMode.Read;
                }
            default: 
                return AccessMode.None;
            
        }
    }

    public set AccessMode(access: AccessMode) {
        switch(access) {
            case AccessMode.None:
                this.Create = false;
                this.Read = AccessLevel.none;
                this.Update = AccessLevel.none;
                this.Suppress = AccessLevel.none;
                this.Assign = AccessLevel.none;
                break;
            case AccessMode.Read:
                this.Create = false;
                this.Read = AccessLevel.all;
                this.Update = AccessLevel.none;
                this.Suppress = AccessLevel.none;
                this.Assign = AccessLevel.none;
                break;
            case AccessMode.Write:
                this.Create = true;
                this.Read = AccessLevel.all;
                this.Update = AccessLevel.all;
                this.Suppress = AccessLevel.all;
                this.Assign = AccessLevel.all;
                break;
            case AccessMode.WriteOwnedOnly:
                this.Create = true;
                this.Read = AccessLevel.ownedOnly;
                this.Update = AccessLevel.ownedOnly;
                this.Suppress = AccessLevel.ownedOnly;
                this.Assign = AccessLevel.ownedOnly;
                break;
            case AccessMode.ReadOwnedOnly:
                this.Create = false;
                this.Read = AccessLevel.ownedOnly;
                this.Update = AccessLevel.none;
                this.Suppress = AccessLevel.none;
                this.Assign = AccessLevel.none;
                break;
            case AccessMode.ReadWriteOwnedOnly:
                this.Create = false;
                this.Read = AccessLevel.all;
                this.Update = AccessLevel.none;
                this.Suppress = AccessLevel.none;
                this.Assign = AccessLevel.none;
                break;
                
        }
    }
        
    public get principal() {
        const principal = new RowShare.SecurityPrincipal();
        principal.UserEmail = this.UserEmail;
        principal.GroupId = this.GroupId;
        principal.GroupName = this.GroupName;
        principal.MemberEmail = this.MemberEmail;
        principal.MemberOrganizationId = this.MemberOrganizationId;
        principal.MemberName = this.MemberName;
        principal.Options = this.Options;
        return principal;
    }

    public set principal(value: RowShare.SecurityPrincipal) {
        this.UserEmail = value.UserEmail;
        this.GroupId = value.GroupId;
        this.GroupName = value.GroupName;
        this.MemberEmail = value.MemberEmail;
        this.MemberOrganizationId = value.MemberOrganizationId;
        this.MemberName = value.MemberName;
        this.Options = value.Options;
    }

    public static getNewFromAccessMode(listOfAccesses: Array<ListAccess>, securityPrincipal: RowShare.SecurityPrincipal, readAccess: AccessLevel, 
                       writeAccess: AccessLevel, ListId:string) : ListAccess {
        let newListAccess = new ListAccess();
        let accessId = newListAccess.existsAccess(listOfAccesses, securityPrincipal.UserEmail, securityPrincipal.GroupId, securityPrincipal.MemberEmail,
            securityPrincipal.MemberOrganizationId, securityPrincipal.Options);
        if (accessId != null)
            newListAccess.Id = accessId;
        let accessMode = newListAccess.getAccessMode(readAccess, writeAccess);
        newListAccess.AccessMode = accessMode;
        newListAccess.principal = securityPrincipal;
        newListAccess.ListId = ListId;
        return newListAccess;
    }

    public static getNew(listOfAccesses: ListAccess[], securityPrincipal: RowShare.SecurityPrincipal, listId: string, create: boolean, read: AccessLevel, 
                update: AccessLevel, suppress: AccessLevel, assign: AccessLevel) {
        let newListAccess = new ListAccess();
        let accessId = newListAccess.existsAccess(listOfAccesses, securityPrincipal.UserEmail, securityPrincipal.GroupId, securityPrincipal.MemberEmail,
            securityPrincipal.MemberOrganizationId, securityPrincipal.Options);
        if (accessId != null) {
            newListAccess.Id = accessId;
        }
        newListAccess.Create = create;
        newListAccess.Read = read;
        newListAccess.Update = update;
        newListAccess.Suppress = suppress;
        newListAccess.Assign = assign;
        newListAccess.principal = securityPrincipal;
        newListAccess.ListId = listId;
        newListAccess.IsMyOrganization = securityPrincipal.IsMyOrganization;
        return newListAccess;
    }

    public static getNewDefault(listId: string) {
        let newListAccess = new ListAccess();
        newListAccess.simpleAccessLevel = AccessLevel.ownedOnly;
        newListAccess.ListId = listId;
        return newListAccess;
    }

    existsAccess(listOfAccesses: Array<RowShare.ListAccess>, userEmail: string | undefined, groupId: string | undefined, memberEmail: string | undefined, 
        memberOrganizationId: string | undefined, accessOptions: RowShare.AccessOptions) {
        let accessId = null;
        if (userEmail != null) {
            let elem = listOfAccesses.find(ac => ac.UserEmail == userEmail);
            if (elem) {
                accessId = elem.Id;
            }
        }
        if (groupId != null) {
            let elem = listOfAccesses.find(ac => ac.GroupId == groupId);
            if (elem) {
                accessId = elem.Id
            }
        }
        if (memberEmail != null && memberOrganizationId != null) {
            let elem = listOfAccesses.find(ac => ac.MemberEmail == memberEmail && ac.MemberOrganizationId == memberOrganizationId && ac.Options == accessOptions);
            if (elem) {
                accessId = elem.Id;
            }
        }
        return accessId;
    }

    getAccessMode(accessToRead: RowShare.AccessLevel, accessToWrite: RowShare.AccessLevel) : RowShare.AccessMode {
        switch (accessToWrite) {
            case (RowShare.AccessLevel.all): {
                if (accessToRead == RowShare.AccessLevel.all)
                    return RowShare.AccessMode.Write;
                else
                    return RowShare.AccessMode.None;
            }
            case (RowShare.AccessLevel.ownedOnly): {
                if (accessToRead == RowShare.AccessLevel.all)
                    return RowShare.AccessMode.ReadWriteOwnedOnly;
                else if (accessToRead == RowShare.AccessLevel.ownedOnly)
                    return RowShare.AccessMode.WriteOwnedOnly;
                else
                    return RowShare.AccessMode.None;
            }
            case (RowShare.AccessLevel.none): {
                if (accessToRead == RowShare.AccessLevel.all)
                    return RowShare.AccessMode.Read;
                else if (accessToRead == RowShare.AccessLevel.ownedOnly)
                    return RowShare.AccessMode.ReadOwnedOnly;
                else
                    return RowShare.AccessMode.None;
            }
            default:
                return RowShare.AccessMode.None;
        }
    }

    public get readAccessLevel() : AccessLevel {
        return this.Read;
    }

    public set readAccessLevel(value: AccessLevel) {
        this.Read = value;
    }

    public get writeAccessLevel() {
        return this.Update;
    }

    public set writeAccessLevel(value: AccessLevel) {
        this.Create = (value != AccessLevel.none);
        this.Update = value;
        this.Suppress = value;
        this.Assign = value;
    }

    public get guestName(): string {
        let guestName = this.Guest?.DisplayName;
        if(!guestName) {
            return "";
        }
        let result = guestName;
        if (this.Guest.Type == RowShare.OwnerType.Team) {
            if (this.Options & RowShare.AccessOptions.ManagedOfMember) {
                if (this.Options & RowShare.AccessOptions.Recursive) {
                    result = i18n.t("ListAccess_AllReportsTeamNameFormat", [guestName]).toString();
                } else {
                    result = i18n.t("ListAccess_DirectReportsTeamNameFormat", [guestName]).toString();
                }
            }
        }
        return result;
    }

    public get simpleAccessLevel(): AccessLevel | null {
        // In simple mode, all permissions are set to the same value
        if(this.Read == this.Update && this.Update == this.Suppress && this.Suppress == this.Assign) {
            return ((this.Read > 0 && !this.Create) || this.Read == 0) ? null : this.Read;
        }
        return null;
    }

    public set simpleAccessLevel(value: AccessLevel | null) {
        if(!value) {
            return;
        }
        this.Create = (value > 0);
        this.Read = value;
        this.Update = value;
        this.Suppress = value;
        this.Assign = value;
    }

    public get isAdvancedAccess(): boolean {
        return this.simpleAccessLevel == null;
    }
}

export enum AccessLevel {
    none = 0,
    ownedOnly = 1,
    all = 2
}

export const enum AccessMode {
    None = 0,
    Read = 1,
    Write = 2,
    WriteOwnedOnly = 4,
    ReadOwnedOnly = 8,
    ReadWriteOwnedOnly = 16
}


export class NonExistentMember {
    Email: string = "";
    Create: boolean = false;
    Read: AccessLevel = AccessLevel.none;
    Update: AccessLevel = AccessLevel.none;
    Suppress: AccessLevel = AccessLevel.none;
    Assign: AccessLevel = AccessLevel.none;

    public static getNew(email: string, create: boolean, read: AccessLevel, update: AccessLevel, suppress: AccessLevel, assign: AccessLevel) {
        let newMember = new NonExistentMember();
        newMember.Email = email;
        newMember.Create = create;
        newMember.Read = read;
        newMember.Update = update;
        newMember.Suppress = suppress;
        newMember.Assign = assign;

        return newMember;
    }

    public static getNewFromAccessMode(email: string, access: AccessMode) {
        let newMember = new NonExistentMember();
        newMember.Email = email;
        newMember.Access = access;
        return newMember;
    }

    public get Access() : AccessMode {
        switch (this.Read) {
            case AccessLevel.none:
                return AccessMode.None;
            case AccessLevel.ownedOnly:
                if(this.Create && this.Update == AccessLevel.ownedOnly && this.Suppress == AccessLevel.ownedOnly && this.Assign == AccessLevel.ownedOnly) {
                    return AccessMode.WriteOwnedOnly;
                }
                else {
                    return AccessMode.ReadOwnedOnly;
                }
            case AccessLevel.all:
                if(this.Create && this.Update == AccessLevel.all && this.Suppress == AccessLevel.all && this.Assign == AccessLevel.all) {
                        return AccessMode.Write;
                }
                else if(this.Create  && this.Update == AccessLevel.ownedOnly && this.Suppress == AccessLevel.ownedOnly && this.Assign == AccessLevel.ownedOnly) {
                    return AccessMode.ReadWriteOwnedOnly;
                }
                else {
                    return AccessMode.Read;
                }
            default: 
                return AccessMode.None;
            
        }
    }

    public set Access(access: AccessMode) {
        switch(access) {
            case AccessMode.None:
                this.Create = false;
                this.Read = AccessLevel.none;
                this.Update = AccessLevel.none;
                this.Suppress = AccessLevel.none;
                this.Assign = AccessLevel.none;
                break;
            case AccessMode.Read:
                this.Create = false;
                this.Read = AccessLevel.all;
                this.Update = AccessLevel.none;
                this.Suppress = AccessLevel.none;
                this.Assign = AccessLevel.none;
                break;
            case AccessMode.Write:
                this.Create = true;
                this.Read = AccessLevel.all;
                this.Update = AccessLevel.all;
                this.Suppress = AccessLevel.all;
                this.Assign = AccessLevel.all;
                break;
            case AccessMode.WriteOwnedOnly:
                this.Create = true;
                this.Read = AccessLevel.ownedOnly;
                this.Update = AccessLevel.ownedOnly;
                this.Suppress = AccessLevel.ownedOnly;
                this.Assign = AccessLevel.ownedOnly;
                break;
            case AccessMode.ReadOwnedOnly:
                this.Create = false;
                this.Read = AccessLevel.ownedOnly;
                this.Update = AccessLevel.none;
                this.Suppress = AccessLevel.none;
                this.Assign = AccessLevel.none;
                break;
            case AccessMode.ReadWriteOwnedOnly:
                this.Create = false;
                this.Read = AccessLevel.all;
                this.Update = AccessLevel.none;
                this.Suppress = AccessLevel.none;
                this.Assign = AccessLevel.none;
                break;
                
        }
    }

}

export class ListAccessesToSave {
    public listId!: string;
    public organizationId!: string;
    public sendNotification!: boolean;
    public newAccessesForNewMembers!: NonExistentMember[];
    public newAccesses!: ListAccess[];
    public updatedAccesses!: ListAccess[];
    public deletedAccesses!: ListAccess[];
    public recaptchaToken!: string | null;
    public recaptchaTokenIsV3!: boolean;

    constructor() {
        this.newAccessesForNewMembers = [];
        this.newAccesses = [];
        this.updatedAccesses = [];
        this.deletedAccesses = [];
    }
}
