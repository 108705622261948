
import {Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { FormValidation } from '@/views/components/FormValidation';

@Component({
    name:'InputPhone',
    components: { ValidationProvider }
    })
export default class InputPhone extends FormValidation {

    //Properties
    @Prop() inputName!:{
        type:string,
        required:true
    };
    @Prop() inputId!:{
        type:string,
        required:true
    };
    @Prop() inputValidationRules!:string;
    @Prop() disabledProp!:boolean;
    @Prop() value!:string;
    @Prop() inputVid!:string;
    @Prop() placeholder!:string;

    //Fields
    validPhone: boolean = false;
     
    //Methods
    emitValueChanged(valueParam:String){
        this.$emit('input', valueParam );
    }

    blured(valueParam:String){
        this.$emit('blured', valueParam);
    }
}
