
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Utils } from '@/utils/Utilities';
import * as API from '@/api/Api';
import * as RowShare from "@/models/RowShare";
import Multiselect from 'vue-multiselect';
import Bigram from "@/views/components/Bigram.vue";

@Component({name: "SecurityPrincipalDropdown", components: { Multiselect, Bigram }})
export default class SecurityPrincipalDropdown extends Vue{
    @Prop({required: true}) value!: (RowShare.SecurityPrincipal | string) | (RowShare.SecurityPrincipal | string)[];
    @Prop({required: true}) organization!: RowShare.Organization;
    @Prop({default: true}) includeTeams!: boolean;
    @Prop({default: true}) memberCreationEnabled!: boolean;
    @Prop({default: true}) showMyOrganization!: boolean;
    @Prop({default: true}) multiple!: boolean;
    @Prop({default: ""}) openDirection!: string;
    @Prop() filterExistingPrincipals!: RowShare.SecurityPrincipal[];
    @Prop({ default:'' }) customPlaceHolder!:string | null;
    @Prop() dropdownIcon!: string;
    allPrincipals: RowShare.DropdownMember[] = [];
    selection: RowShare.DropdownMember | RowShare.DropdownMember[] = [];

    currentGuestSearch: string = "";
    canCreateTagForCurrentSearch = false;

    async mounted() {
        await this.initListItems();
        if(this.value) {
            if(this.multiple) {
                this.selection = (<RowShare.SecurityPrincipal[]>this.value).map(v => new RowShare.DropdownMember(<RowShare.SecurityPrincipal>v));
            }
            else {
                this.selection = new RowShare.DropdownMember(<RowShare.SecurityPrincipal>this.value);
            }
            this.onValueChanged();
        }
    }

    @Watch('filterExistingPrincipals')
    async initListItems() {
        let principals = await API.SecurityPrincipal.loadForOrganization(this.organization.Id, this.includeTeams);

        if(principals){
            const filterExistingPrincipalsIds = this.filterExistingPrincipals?.map(l => {return l.id}) ?? [];
            principals = principals?.filter( principal => {
                if(!this.showMyOrganization && principal.IsMyOrganization) {
                    return false;                    
                }
                return !filterExistingPrincipalsIds.includes(principal.id);
            })

        }
        this.allPrincipals = principals ? principals.map(s => new RowShare.DropdownMember(s)) : [];
    }

    onValueChanged(){
        let evtArgs: (RowShare.SecurityPrincipal | string) | (RowShare.SecurityPrincipal | string)[];

        if(this.multiple) {
            let vals = <RowShare.DropdownMember[]>this.selection;
            evtArgs = vals.map(vm => vm.isNew ? <string>vm.text : <RowShare.SecurityPrincipal>vm.securityPrincipal);
        } else {
            let val = <RowShare.DropdownMember>this.selection;
            evtArgs = val.isNew ? <string>val.text : <RowShare.SecurityPrincipal>val.securityPrincipal;
        }

        this.$emit('input', evtArgs)
    }

    get createTagPlaceholder(){
        return this.$t("SecurityPrincipalDropdown_TagboxAddNewMemberMessage", [this.currentGuestSearch]).toString();
    }
    
    get placeholder() {
        if(!Utils.isNullOrWhiteSpace(this.customPlaceHolder)){
            return this.customPlaceHolder;
        }
        if(this.organization && this.organization.CanCurrentUserManage){
            return this.$t("SecurityPrincipalDropdown_TagboxPlaceholderAdmin",[this.currentGuestSearch]).toString();
        }else{
            return this.$t("SecurityPrincipalDropdown_TagboxPlaceholderNotAdmin",[this.currentGuestSearch]).toString();
        }
    }

    onNewTag (newTag: string) {
        if(!Utils.validateEmail(newTag))
            return;

        var newItem = new RowShare.DropdownMember({
            value: newTag,
            text: newTag,
            searchBy: newTag,
            isNew: true
        });

        if(this.multiple)
            (<RowShare.DropdownMember[]>this.selection).push(newItem);
        else
            this.selection = newItem;

        this.onValueChanged();
    }

    onTagboxSearchChanged(searchQuery: string){
        this.currentGuestSearch = searchQuery;

        let isValidEmail = Utils.validateEmail(this.currentGuestSearch);
        let isDuplicate = false;

        if(this.multiple)
            isDuplicate = !!(<RowShare.DropdownMember[]>this.selection).find(g => g.value == this.currentGuestSearch);

        let memberAlreadyExists = this.allPrincipals.find(p => p.email?.toLowerCase() == this.currentGuestSearch);

        this.canCreateTagForCurrentSearch = isValidEmail && !isDuplicate && !memberAlreadyExists;
    }
    
    activate(){
        (<any>(this.$refs.multiselect)).activate();
    }

    clearSelection() {
        this.selection = [];        
    }

    getIconPath(principal: RowShare.SecurityPrincipal) {
        if(!principal || !principal.IconPath) {
            return "";
        }
        let path = principal.IconPath
        if(!path) {
            return "";
        }
        if(!path.startsWith("/")) {
            path = "/" + path + '?_ssb_img=S;w:40;h:40';
        }
        else {
            path = "";
        }
        return path;
    }

}
