
import { Component, Vue, Watch } from 'vue-property-decorator';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import { EventBus } from '@/modules/EventBus';
import { AccessLevel, ListAccess, Event, List, Organization, SecurityPrincipal, showSidePanelSharingEventParams, GlobalNotificationEventParams, NotificationType, NonExistentMember, Owner, OwnerType, ListAccessesToSave, ConfirmationRequiredEventParams } from '@/models/RowShare';
import SecurityPrincipalDropdown from "@/views/components/SecurityPrincipalDropdown.vue";
import { RecaptchaActions } from '@/views/components/Recaptcha.vue';
import { OnPremModule } from '@/store/OnPremStore';
import Recaptcha from '@/views/components/Recaptcha.vue';
import * as Api from '@/api/Api';
import Bigram from '@/views/components/Bigram.vue';
import { RowShareException } from '@/api/ApiUtils';
import { DataTableHeader } from 'vuetify';
import AccessLevelSwitch from '@/views/components/AccessLevelSwitch.vue';
import ListAccessVM, { ListAccessVMStatus } from '@/viewModels/Table/ListAccessVM';

@Component({name: 'SidePanelSharing', components: { PageLoadSpinner, Recaptcha, SecurityPrincipalDropdown, Bigram, AccessLevelSwitch } })
export default class SidePanelSharing extends Vue  {
    showMe: boolean = false;
    listId: string = "";
    width: string = "0px";
    selectedGuest: SecurityPrincipal | string = "";
    sendInvitationEmails: boolean = false;
    currentOrganization: Organization | null = null;
    currentList: List | null = null;
    recaptchaToken: string |null = null;
    recaptchaTokenIsV3:boolean = true;
    processing: boolean = false;
    newMemberCount: number = 0;
    currentGuestSearch: string = "";
    canCreateTagForCurrentSearch = false;
    isNoUserOrGroupErrorVisible = false;
    isMaxMemberCountErrorVisible = false;
    selectedSimpleAccessLevel: AccessLevel = AccessLevel.none;
    currentListAccesses: ListAccessVM[] = [];
    originalListAccesses: ListAccessVM[] = [];
    loadingAccesses: boolean = false;
    advancedMode: boolean = false;
    listAccessesHeight: number = 0;
    accessesListHeaders: DataTableHeader[] = [];
    addGuestMode: boolean = false;

    created() {
        EventBus.$on(Event.SHOW_SIDEPANEL_SHARING, (event: showSidePanelSharingEventParams) => this.onShowSidePanelSharing(event));
        window.addEventListener('resize', this.refreshListAccessesHeight);
    }

    beforeDestroy() {
        EventBus.$off([Event.RECAPTCHA_SUCCEEDED,
                       Event.RECAPTCHA_FAILED,
                       Event.RECAPTCHA_CANCELEDV2,
                       Event.SHOW_SIDEPANEL_SHARING]);
        window.removeEventListener('resize', this.refreshListAccessesHeight);
    }

    async onShowSidePanelSharing(event: showSidePanelSharingEventParams) {
        EventBus.$on(Event.RECAPTCHA_SUCCEEDED,this.onRecaptchaSucceeded);
        EventBus.$on([Event.RECAPTCHA_FAILED,Event.RECAPTCHA_CANCELEDV2], this.onRecaptchaFailedOrCanceled);
        this.listId = event.listId;
        this.currentList = await Api.List.load(this.listId);
        if(this.currentList != null) {
            this.currentOrganization = await Api.Organization.load(this.currentList.OrganizationId);
        }
        if((event.inviteParams?.selectedGuests?.length ?? 0) > 0) {
            this.selectedGuest = event.inviteParams.selectedGuests[0];
        }
        this.advancedMode = event.advancedMode;
        this.addGuestMode = event.addGuest;
        this.refreshListAccesses();
        this.refreshListAccessesHeight();
        this.refreshColumns();
        this.width = "60%";

        setTimeout(() => {
            this.showMe = true;
        }, 0);

        if((<any>window).Intercom) {
            (<any>window).Intercom('update', { 'hide_default_launcher': true});
        }
    }

    onSidePanelStatusChanged() {
        if(this.addGuestMode && this.showMe) {
            setTimeout(() =>  {
                (<any>(this.$refs.guestsDropdown)).activate();
            }, 0);
        }
    }

    @Watch('advancedMode')
    refreshColumns() {
        this.accessesListHeaders = [];
        this.accessesListHeaders.push({text: '', align: 'start', value: 'guestName', width: '40%'});
        if(this.advancedMode){
            this.accessesListHeaders.push({text: this.$i18n.t('SidepanelSharing_Read').toString(), align: 'center', value: 'Read', width: '10%' });
            if(this.hasAdvancedPermissions) {
                this.accessesListHeaders.push({text: this.$i18n.t('SidepanelSharing_Create').toString(), align: 'center', value: 'Create', width: '10%'});
            }
            this.accessesListHeaders.push({text: this.$i18n.t('SidepanelSharing_Update').toString(), align: 'center', value: 'Update', width: '10%'});
            this.accessesListHeaders.push({text: this.$i18n.t('SidepanelSharing_Suppress').toString(), align: 'center', value: 'Suppress', width: '10%'});
            this.accessesListHeaders.push({text: this.$i18n.t('SidepanelSharing_Assign').toString(), align: 'center', value: 'Assign', width: '10%'});
        }
        else {
            this.accessesListHeaders.push({text: '', align: 'center', value: 'simpleAccessLevel', sortable: false, width: '50%'});
        }
        this.accessesListHeaders.push({text: '', align: 'center', value: 'resendInvite', sortable: false, width: '5%'})
        this.accessesListHeaders.push({text: '', align: 'end', value: 'removeCol', sortable: false, width: '5%'});
    }

    refreshListAccesses() {
        this.loadingAccesses = true;
        Api.ListAccess.loadByList(this.listId)
                    .then(accesses => {
                        if(accesses) {
                            accesses.sort((a: ListAccess, b: ListAccess) => {
                                if(a.IsMyOrganization) {
                                    return -1;
                                }
                                if(b.IsMyOrganization) {
                                    return 1;
                                }
                                let vala = a.guestName.toLowerCase();
                                let valb = b.guestName.toLowerCase();
                                if(vala < valb) {
                                    return -1;
                                }
                                else if(vala > valb) {
                                    return 1;
                                }
                                return 0;
                            });
                            this.currentListAccesses = accesses.map(acc => new ListAccessVM(acc));
                            this.originalListAccesses = accesses.map(acc => new ListAccessVM(acc));
                        }
                    })
                    .finally(() => {
                        this.loadingAccesses = false;
                        if(this.selectedGuest) {
                            this.onGuestSelected();
                        }
                    });
    }

    onCancel() {
        if(this.hasModifiedAccess) {
            EventBus.$emit(Event.CONFIRMATION_REQUIRED, <ConfirmationRequiredEventParams> {
                description1: this.$i18n.t('SidePanelSharing_ConfirmClosePanel').toString(),
                actionButtonText: this.$i18n.t('SidePanelSharing_ConfirmCancellation').toString(),
                actionButtonColor: "primary",
                actionButtonIcon: "",
                onConfirmation: async () => {this.closePanel();},
                cancelButtonText: this.$i18n.t('Common_Cancel').toString()
            });
        }
        else {
            this.closePanel();
        }
    }

    closePanel() {
        this.listId = "";
        this.showMe = false;
        this.width = "0px";
        this.currentList = null;
        this.currentOrganization = null;
        this.advancedMode = false;
        this.currentListAccesses = [];
        this.sendInvitationEmails = false;
        this.selectedGuest = "";
        if((<any>window).Intercom) {
            (<any>window).Intercom('update', { 'hide_default_launcher': false});
        }
        EventBus.$off(Event.RECAPTCHA_SUCCEEDED,this.onRecaptchaSucceeded);
        EventBus.$off([Event.RECAPTCHA_FAILED,Event.RECAPTCHA_CANCELEDV2], this.onRecaptchaFailedOrCanceled);
    }

    onClickOutside() {
        if(this.showMe) {
            this.onCancel();
        }
    }

    getMyOrganizationIconPath(access: ListAccess) {
        if(!access || !access.IsMyOrganization) {
            return "";
        }
        let path = access?.Guest?.IconPath
        if(!path) {
            return "";
        }
        if(!path.startsWith("/")) {
            path = "/" + path + '?_ssb_img=S;w:40;h:40';
        }
        else {
            path = null;
        }
        return path;
    }

    retryFallbackRecaptcha(){
        this.recaptchaToken = "";
        EventBus.$emit(Event.RECAPTCHA_SHOWV2);
    }

    triggerRecaptcha() {
        this.recaptchaToken = "";
        EventBus.$emit(Event.RECAPTCHA_SHOW, RecaptchaActions.ShareMembers);
    }

    onRecaptchaSucceeded(successEvent: any){
        if(!this.showMe) {
            return;
        }
        this.recaptchaToken = successEvent.token;
        this.recaptchaTokenIsV3 = successEvent.isV3;
        this.saveAccesses();
    }

    onRecaptchaFailedOrCanceled(reason: string = ""){
        if(!this.showMe) {
            return;
        }
        this.recaptchaToken = "";
        this.recaptchaTokenIsV3 = true;
        this.processing = false;
        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{
              autoHide: true,
              autoHideAfterMs: 3000,
              type: NotificationType.error,
              message: (this.$i18n.t('Recaptcha_Invalid').toString()) + reason
              });
    }

    @Watch('currentListAccesses')
    refreshListAccessesHeight() {
        let listaccessesHeader = <HTMLElement>this.$refs.listaccessesHeader;
        let listaccessesFooter = <HTMLElement>this.$refs.listaccessesFooter;
        let sidepanelHeader = <HTMLElement>this.$refs.sidepanelHeader;
        let sidepanelFooter = <HTMLElement>this.$refs.sidePanelFooter;
        if(!listaccessesFooter || !listaccessesHeader || !sidepanelHeader || !sidepanelFooter) {
            return;
        }
        let maxHeight = window.innerHeight - (listaccessesFooter.getBoundingClientRect().height +
                                            listaccessesHeader.getBoundingClientRect().height +
                                            sidepanelHeader.getBoundingClientRect().height +
                                            sidepanelFooter.getBoundingClientRect().height + 20);// 284;
        let computedHeight = (this.currentListAccesses.length * 32) + 32;
        this.listAccessesHeight = Math.min(maxHeight, computedHeight);
    }

    shouldClosePanel(event: any): boolean {
        if(!event?.target?.classList) {
            return false;
        }
        return event.target.classList.contains('v-overlay__scrim');
    }

    onGuestSelected() {
        if(!this.selectedGuest) {
            return;
        }

        var newAccesss: ListAccessVM;
        let lastAccessCreated =  this.currentListAccesses.length > 0 ? this.currentListAccesses[this.currentListAccesses.length-1]
                                                                     : ListAccess.getNewDefault(this.listId);


        if(this.selectedGuest instanceof SecurityPrincipal) {
            newAccesss = new ListAccessVM(ListAccess.getNew(this.currentListAccesses, this.selectedGuest, this.listId,
                                                            lastAccessCreated.Create, lastAccessCreated.Read, lastAccessCreated.Update,
                                                            lastAccessCreated.Suppress, lastAccessCreated.Assign));
            newAccesss.Guest = <Owner> {
                BackgroundColorIndex: this.selectedGuest.BackgroundColorIndex,
                Bigram: this.selectedGuest.Bigram,
                Email: this.selectedGuest.MemberEmail,
                IconPath: this.selectedGuest.IconPath,
                Id: this.selectedGuest.id,
                Type: this.selectedGuest.ownerType,
                DisplayName: this.selectedGuestName
            }
            newAccesss.status = ListAccessVMStatus.New;
        }
        else {
            newAccesss = new ListAccessVM(new ListAccess());
            newAccesss.MemberEmail = this.selectedGuest;
            newAccesss.MemberOrganizationId = this.currentOrganizationId;
            newAccesss.ListId = lastAccessCreated.ListId;
            newAccesss.Create = lastAccessCreated.Create;
            newAccesss.Read = lastAccessCreated.Read;
            newAccesss.Update = lastAccessCreated.Update;
            newAccesss.Suppress = lastAccessCreated.Suppress;
            newAccesss.Assign = lastAccessCreated.Assign;
            newAccesss.Guest = <Owner> {
                DisplayName: this.selectedGuest,
                Email: this.selectedGuest,
                Type: OwnerType.Member
            }
            newAccesss.status = ListAccessVMStatus.NewMember;
        }
        this.currentListAccesses.push(newAccesss);
        this.$nextTick(() => {
            this.selectedGuest = "";
            (<any>this.$refs.guestsDropdown)?.clearSelection();
            this.refreshListAccessesHeight();
        }, 0);
    }

    getAccessClass(access: ListAccessVM) {
        if(!access.isValid) {
            return "access-invalid";
        }
        switch(access.status) {
            case ListAccessVMStatus.Deleted:
                return "access-deleted";
            case ListAccessVMStatus.Invalid:
                return "access-invalid";
            case ListAccessVMStatus.Modified:
                return "access-modified";
            case ListAccessVMStatus.New:
                return "access-new";
            case ListAccessVMStatus.NewMember:
                return "access-new-member";
            default:
                return "";
        }
    }

    updateStatus(access: ListAccessVM) {
        if(!this.hasAdvancedPermissions) {
            access.Suppress = access.Update;
            access.Assign = access.Update;
            access.Create = access.Update > 0;
        }
        if(access.isNew || access.isDeleted) {
            return;
        }
        this.setStatus(access);
    }

    setStatus(access: ListAccessVM) {
        if(!access.isValid) {
            access.status = ListAccessVMStatus.Invalid;
            return;
        }
        let originalAccess = this.originalListAccesses.find((acc) => acc.Id == access.Id);
        if(originalAccess) {
            access.status = (originalAccess.Create == access.Create && originalAccess.Read == access.Read && originalAccess.Update == access.Update
                                && originalAccess.Suppress == access.Suppress && originalAccess.Assign == access.Assign)
                                ? ListAccessVMStatus.Unchanged
                                : ListAccessVMStatus.Modified;
        }
    }

    removeAccess(access: ListAccessVM) {
        if(access.isNew) {
            let accessIndex = this.currentListAccesses.findIndex((acc) => acc == access);
            if(accessIndex > -1) {
                this.currentListAccesses.splice(accessIndex, 1);
            }
        }
        if(!access.isDeleted) {
            access.status = ListAccessVMStatus.Deleted;
        }
        else {
            this.setStatus(access);
        }
    }

    resendInvite(access: ListAccessVM) {
        if(access) {
            Api.ListAccess.resendInvitation(access.Id)
                .then(result => {
                    if(result) {
                        let msg = "";
                        let msgType: NotificationType
                        if(result.IsValid) {
                                msg = this.$i18n.t('SidePanelSharing_ResendInvitation_Success').toString();
                                msgType = NotificationType.success;
                        }
                        else {

                            msg = this.$i18n.t(result?.Errors[0].ExceptionCode.toString() ?? "").toString();
                            msgType = NotificationType.error;
                        }
                        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams> {
                            message: msg,
                            autoHide: true,
                            type: msgType,
                            autoHideAfterMs: result.IsValid ? 2000 : 3000,
                            duplicateId: 'ResendInvite'
                        });
                    }
                });
        }
    }

    onSaveClick() {
        if(this.mustShowRecaptcha) {
            this.triggerRecaptcha();
        }
        else {
            this.saveAccesses();
        }
    }

    saveAccesses() {
        let accessesToSave = new ListAccessesToSave();
        this.processing = true;
        accessesToSave.listId = this.listId;
        accessesToSave.organizationId = this.currentOrganizationId;
        accessesToSave.sendNotification = this.sendInvitationEmails;
        accessesToSave.recaptchaToken = this.recaptchaToken;
        accessesToSave.recaptchaTokenIsV3 = this.recaptchaTokenIsV3;
        this.currentListAccesses.forEach((access) => {
            switch(access.status) {
                case ListAccessVMStatus.NewMember:
                    accessesToSave.newAccessesForNewMembers.push(NonExistentMember.getNew(access.MemberEmail, access.Create, access.Read, access.Update,
                                                                                          access.Suppress, access.Assign));
                    break;
                case ListAccessVMStatus.New:
                    accessesToSave.newAccesses.push(access);
                    break;
                case ListAccessVMStatus.Modified:
                    accessesToSave.updatedAccesses.push(access);
                    break;
                case ListAccessVMStatus.Deleted:
                    accessesToSave.deletedAccesses.push(access);
                    break;
            }
        });
        Api.ListAccess.saveMultiple(accessesToSave)
                    .then((result) => {
                        if(result?.IsValid && result?.Value) {
                            this.refreshListAccesses();
                            this.refreshListAccessesHeight();
                            EventBus.$emit(Event.LISTACCESSES_CHANGED);
                            EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED,
                                            <GlobalNotificationEventParams>{
                                                message: this.$i18n.t('SidePanelSharing_SaveSuccess').toString(),
                                                type: NotificationType.success,
                                                autoHide: true,
                                                autoHideAfterMs: 3000
                                            });
                        }
                        else {
                            if((result?.Errors?.length ?? 0) > 0) {
                                var error = result?.Errors[0];
                                var errorMessage = "";
                                if(error?.ExceptionCode == 96 && this.mustShowRecaptcha) {
                                    // Recaptcha token is not valid
                                    this.retryFallbackRecaptcha();
                                    return;
                                }
                                else if (error?.ExceptionCode == 62) {
                                    // MaximumMemberExceededForOrganization
                                    errorMessage = this.$i18n.t('SidePanelSharing_MaxMemberExceeded').toString();
                                }
                                else {
                                    errorMessage = this.$i18n.t(error?.ExceptionCode.toString()?? "").toString()
                                }

                                EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED,
                                                <GlobalNotificationEventParams>{
                                                    message: errorMessage,
                                                    title: this.$i18n.t('SidePanelSharing_SaveError').toString(),
                                                    type: NotificationType.error,
                                                    autoHide: true,
                                                    autoHideAfterMs: 10000
                                                });
                            }
                        }
                    })
                    .catch((exc: any) => {
                        let rsExc = exc as RowShareException;
                        let exceptionMsg = "";
                        if(rsExc) {
                            if (rsExc.code == 96 && this.mustShowRecaptcha) {
                                // Recaptcha token not valid
                                    this.retryFallbackRecaptcha();
                                    return;
                            }
                            if (rsExc.code == 62) {
                                this.isMaxMemberCountErrorVisible = true;
                                return;
                            }
                            exceptionMsg = rsExc.message;
                        }
                        else {
                            exceptionMsg = exc.toString();
                        }
                        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: exceptionMsg,
                        type: NotificationType.error,autoHide: true, autoHideAfterMs: 10000});
                    })
                    .finally(() => this.processing = false);
    }

    getResendInviteTooltip(access: ListAccessVM) {
        if(this.getCanResendInvite(access)) {
            return this.$i18n.t('SidePanelSharing_ResendInviteTooltip').toString();
        }

        return this.$i18n.t('SidePanelSharing_CannotResendInviteTooltip').toString();
    }

    getCanResendInvite(access: ListAccessVM) {
        return access.status === ListAccessVMStatus.Unchanged;
    }

    get selectedGuestName(): string {
        if(!this.selectedGuest) {
            return "";
        }
        if(this.selectedGuest instanceof SecurityPrincipal) {
            switch (this.selectedGuest.ownerType) {
                case OwnerType.Group:
                    return this.selectedGuest.GroupName;
                case OwnerType.MyOrganization:
                    return this.$i18n.t('ListAccess_MyOrganization').toString();
                case OwnerType.Team:
                    return this.selectedGuest.MemberName ?? this.selectedGuest.MemberEmail;
                default:
                    return this.selectedGuest.displayName;
            }
        }
        return this.selectedGuest;
    }

    get isNotOnPrem() {
        return !OnPremModule.isOnPrem;
    }

    get mustShowRecaptcha() {
      return this.isNotOnPrem
        && process.env.NODE_ENV!="development"
        && this.canShowRecaptcha
        && this.sendInvitationEmails;
    }

    get canShowRecaptcha(): boolean {
        return this.currentListAccesses.findIndex(acc => acc.status == ListAccessVMStatus.NewMember) > -1;
    }

    get hasAdvancedPermissions(): boolean {
        return this.currentOrganization?.SubscriptionHasAdvancedPermissions ?? false;
    }

    get canGoToCheckOut(): boolean {
        return this.currentOrganization?.CanCurrentUserManage ?? false;
    }

    get currentUrl(): string {
        return this.$route.fullPath;
    }

    get currentOrganizationId(): string {
        return this.currentOrganization?.Id ?? "";
    }

    get currentSecurityPrincipals(): SecurityPrincipal[] {
        if(!this.currentListAccesses) {
            return [];
        }
        return this.currentListAccesses.map((access) => access.principal);
    }

    get upsellTitle(): string {
        return this.$i18n.t('UpsellCard_AdvancedPermissionsTitle').toString();
    }

    get upsellDescription(): string {
        return this.$i18n.t('UpsellCard_AdvancedPermissionsDescription').toString();
    }

    get addGuestsButtonText() {
        if(this.sendInvitationEmails) {
            return this.$i18n.t('InviteDialog_Invite').toString();
        }
        else {
            return this.$i18n.t('InviteDialog_ShareTable').toString();
        }
    }

    get canSwitchMode(): boolean {
        if(this.advancedMode) {
            return (this.currentListAccesses.findIndex(access => access.isAdvancedAccess) == -1);
        }
        return true;
    }

    get advancedModeLabel(): string {
        return this.$i18n.t('SidePanelSharing_AdvancedMode').toString();
    }

    get sendInvitationsLabel(): string {
        return this.$i18n.t('InviteDialog_SendButtonDescription').toString();
    }

    get accessesChanged(): boolean {
        return this.currentListAccesses.findIndex(access => access.status !== ListAccessVMStatus.Unchanged) > -1;
    }

    get validationBtnTooltip(): string {
        if(this.hasInvalidAccess) {
            return this.$i18n.t('SidePanelSharing_CannotValidate_InvalidAccesses').toString();
        }
        return this.$i18n.t('SidePanelSharing_NoChangeToValidate').toString();
    }

    get listShared(): boolean {
        return this.currentListAccesses.length > 0;
    }

    get addGuestPlaceHolder(): string {
        return this.$i18n.t('SidePanelSharing_AddGuest').toString();
    }

    get advancedModeSwitchTooltip() {
        if(this.advancedMode) {
            return this.$i18n.t('SidePanelSharing_SimpleModeImpossible').toString();
        }
        else if(!this.hasAdvancedPermissions) {
            return this.$i18n.t('SidePanelSharing_NoAccessToAdvancedMode').toString();
        }
        return "";
    }

    get hasNewGuests(): boolean {
        return this.currentListAccesses.findIndex(access => access.isNew) > -1;
    }

    get hasInvalidAccess(): boolean {
        return this.currentListAccesses.findIndex(access => !access.isValid) > -1;
    }

    get canValidateAccesses(): boolean {
        return this.accessesChanged && !this.hasInvalidAccess;
    }

    get validationBtnTooltipColor(): string {
        if(this.hasInvalidAccess) {
            return "error";
        }
        return "";
    }

    get hasModifiedAccess(): boolean {
        return this.currentListAccesses.findIndex(acc => acc.status !== ListAccessVMStatus.Unchanged) > -1;
    }
}
