
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
    import RsColumnManager from '@/columnProxies/RsColumnManager';
    import { Utils } from '@/utils/Utilities';

    @Component({
        name: 'TwoStatesBooleanFormEditor',
        components: {
        }
    })
    export default class TwoStatesBooleanFormEditor extends FormEditor {
        currentValue: boolean = false;

        init() {
            const proxy = RsColumnManager.getProxy(this.column.Type);
            this.currentValue = Utils.parseBoolean(this.rowVM.getValue(this.column, proxy), false);
        }

        getValue() {
            return this.currentValue;
        }

    }
