
import { Component, Prop } from "vue-property-decorator";
import { UserModule } from '@/store/UserStore';
import { OnPremModule } from '@/store/OnPremStore';
import { FormValidation } from "@/views/components/FormValidation";
import { loadAndSetWithUserLanguageAsync } from "@/modules/Localization";
import { EventBus } from "@/modules/EventBus";
import * as RowShare from "@/models/RowShare";
import { Location as RouterLocation } from "vue-router";
import { ListTreeModule } from "@/store/ListTreeStore";
import { RouterUtils } from "@/modules/Router";
import { ResLoader } from "@/utils/ResLoader";
import { ApplicationModule } from "@/store/ApplicationStore";
import * as API from "@/api/Api";
import {ApiResult, ConfirmationRequiredEventParams, Event} from "@/models/RowShare";

declare var Calendly: any;

@Component
export default class RsHeader extends FormValidation {

    @Prop() isSignUp!: {
        type: boolean;
        default: false;
    };

    @Prop() isLogIn!: {
        type: boolean;
        default: false;
    };

    myAccountListGroupOpened : boolean = false;

    get canAskForSupport() : boolean {
        return !(this.currentOrganization?.HasRowShareSupportActivated ?? false);
    }

    get canSeeSupportButton() : boolean {
        return this.currentUser?.Email !== "support@rowshare.com" && this.isNotOnPrem
    }

    get currentUser () {
        return  UserModule.connectedUser;
    }

    get canDisableSupport() : boolean {
        return !this.canAskForSupport
    }

    onAddRowShareSupport(){
        EventBus.$emit(Event.CONFIRMATION_REQUIRED, <ConfirmationRequiredEventParams> {
            title: this.$i18n.t('Header_AddRowShareSupportAsMember_DialogTitle'),
            description1: this.$i18n.t('Header_AddRowShareSupportAsMember_DialogDescription', [this.currentOrganization?.Name]).toString(),
            actionButtonText: this.$i18n.t('Common_Confirm'),
            actionButtonColor: "primary",
            onConfirmation: () => this.addRowShareSupport(),
            cancelButtonText: this.$i18n.t('Form_CancelButton').toString()
        });
    }

    onRemoveRowShareSupport(){
        this.removeRowShareSupport();
    }

    async removeRowShareSupport(){
        if (!this.currentOrganization) {
            return;
        }
        let apiResult = await API.Member.removeRowShareSupport(this.currentOrganization.Id);
        if(apiResult?.IsValid && apiResult.Value){
            this.currentOrganization.HasRowShareSupportActivated = false;
            let message = this.$i18n.t('Header_RemoveRowSupportMember_SuccessMessage').toString();
            this.notify(message, RowShare.NotificationType.success);
        } else {
            let message = this.$i18n.t('Header_RemoveRowSupportMember_ErrorMessage').toString();
            this.notify(message, RowShare.NotificationType.error);
        }
    }

    async addRowShareSupport() {
        if(!this.currentOrganization) {
            return;
        }
        let apiResult = await API.Member.addRowShareSupport(this.currentOrganization.Id);
        if(apiResult && apiResult?.IsValid){
            /*this.rowShareSupportMember = new RowShare.Member();
            Object.assign(this.rowShareSupportMember, apiResult.Value);*/
            this.currentOrganization.HasRowShareSupportActivated = true;
            let message = this.$i18n.t('Header_AddRowSupportMember_SuccessMessage').toString();
            this.notify(message, RowShare.NotificationType.success);
        } else {
            let message = this.$i18n.t('Header_AddRowSupportMember_ErrorMessage').toString();
            this.notify(message, RowShare.NotificationType.error);
        }
    }

    notify(message: string, notificationType: RowShare.NotificationType) {
        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
            message: message,
            type: notificationType,
            autoHide: true,
            autoHideAfterMs: 4000
        });
    }

    async created() {
        EventBus.$on(RowShare.Event.LOCALE_CHANGED, async() => {
            await this.onLocaleChanged();
        });

        let scriptPromise = this.$loadScript('https://assets.calendly.com/assets/external/widget.js');
        ResLoader.loadCss('https://assets.calendly.com/assets/external/widget.css');
        let res = await Promise.all([scriptPromise]);
    }

    destroy() {
        EventBus.$off([RowShare.Event.LOCALE_CHANGED]);
    }

    onLocaleChanged(){
        this.setValidationLanguage(this.$i18n.locale);
    }

    get activeLocale() {
        return this.$i18n.locale;
    }

    async changeLocale(locale: string) {
        let currentLocale = await sessionStorage.getItem('activeLocale');
        if ( currentLocale === locale)
            return; //do nothing if no real change
        UserModule.changeUserLanguage(locale).then( ul =>
        {
            loadAndSetWithUserLanguageAsync().then( () => {
                EventBus.$emit(RowShare.Event.LOCALE_CHANGED);
            });
        });
    }

    get customSmallSiteLogoPath() {
        let url: string = "";
        if(ListTreeModule?.currentSmallSiteLogoPath) {
            url = ListTreeModule.currentSmallSiteLogoPath;
        }
        else if(this.currentOrganization?.SmallSiteLogoPath) {
            url = this.currentOrganization.SmallSiteLogoPath;
        }
        return url;
    }

    get customFullSiteLogoPath() {
        let url: string = "";
        if(ListTreeModule?.currentFullSiteLogoPath) {
            url = ListTreeModule.currentFullSiteLogoPath;
        }
        else if(this.currentOrganization?.FullSiteLogoPath) {
            url = this.currentOrganization.FullSiteLogoPath;
        }
        return url;
    }

    get hasCustomRSLogosForLogin(): boolean {
        return ListTreeModule?.currentFullSiteLogoPath != null && ListTreeModule?.currentSmallSiteLogoPath != null;
    }

    get demoUser() : boolean {
        return UserModule.connectedUser?.IsDemoUser ?? false;
    }

    get Languages() {
        return [
            { Name: this.$i18n.t("Footer_English").toString(), Value: "en" },
            { Name: this.$i18n.t("Footer_Spanish").toString(), Value: "es" },
            { Name: this.$i18n.t("Footer_French").toString(), Value: "fr" }
        ];
    }

    get getUser(){
        return UserModule.CurrentUser;
    }

    get isNotOnPrem(){
        return !OnPremModule.isOnPrem;
    }

    get organizationsTree(){
        return ListTreeModule.organizationsTree;
    }

    get currentOrganization(){
        return ListTreeModule.currentOrganization;
    }

    get currentList() {
        return ListTreeModule.currentList;
    }

    get currentListFromOtherOrg() {
        return this.currentList != null && this.currentList.OrganizationId !== this.currentOrganization?.Id;
    }

    get currentBreadcrumb() : RowShare.BreadcrumbFolder[] | null {
        let breadcrumb = ListTreeModule.currentBreadcrumb;
        let res: RowShare.BreadcrumbFolder[] | null = null;

        if(this.isFullBreadcrumb)
            res = breadcrumb;
        else if (breadcrumb && breadcrumb.length >= 1)
            res = [breadcrumb[0]];
        else
            res = null;

        if(res && this.breadcrumbTag) {
            let tagPattern = "Header_BreadcrumbTag_" + this.breadcrumbTag;
            let tagName = this.$i18n.t(tagPattern).toString();
            if(tagName == tagPattern)
                tagName = this.breadcrumbTag;

            res = [...res, {Id: this.breadcrumbTag, Name: tagName, IsRootFolder: false, IsTag: true}];
        } else if(res && this.showListInBreadcrumb) {
            const list = ListTreeModule.currentList;
            if(!list)
                return res;

            res = [...res, {Id: list.Id, Name: list.DisplayName, IsRootFolder: false, IsTag: true}];
        }

        if(this.$vuetify.breakpoint.mobile && res && res.length > 2) {
            res = [res[0], {Id: '', Name: '...', IsRootFolder: false, IsTag: false}, res[res.length - 1]];
        } else if(res && res.length > 3) {
            res = [res[0], {Id: '', Name: '...', IsRootFolder: false, IsTag: false},  res[res.length - 2], res[res.length - 1]];

        }

        return res;
    }

    get isBreadcrumbVisible() : boolean {
        return ListTreeModule.currentBreadcrumb && this.$route.meta!.isBreadcrumbVisible && !this.currentListFromOtherOrg;
    }

    get isFullBreadcrumb() : boolean {
        return this.$route.meta!.isFullBreadcrumb;
    }

    get breadcrumbTag() : string | null {
        if(this.$route.meta!.breadcrumbTag)
            return this.$route.meta!.breadcrumbTag;

        var pathProvider = this.$route.meta!.pathProvider;
        if(pathProvider){
            var tag = pathProvider(this.$route)?.breadcrumbTag;
            return tag ?? null
        }

        return null;
    }

    get breadcrumbTagInUrl() : boolean {
        return this.$route.meta!.breadcrumbTagInUrl;
    }

    get showListInBreadcrumb() : boolean {
        return this.$route.meta!.showListInBreadcrumb;
    }

    async signout() {
        let returnUrl: string | null = await UserModule.signOut();
        if (returnUrl) {
            if (returnUrl.charAt(0) == "~") returnUrl = returnUrl.substr(1);

            window.location.href = returnUrl;
        }
    }

    get GetHomeUrl() {
        if(UserModule.CurrentUser.IsAnonymous) {
            return this.$route.fullPath;
        }
        return <RouterLocation>{
            name: 'MyTables',
            params: {
                organizationId: ListTreeModule.currentOrganization?.Id,
                folderId: ListTreeModule.currentOrganization?.IsEnabled ? ListTreeModule.currentOrganization?.RootFolder.Id : undefined
            }
        };
    }

    get signUpLink() {
        return <RouterLocation>{
            name: "SignUp",
            query: {
                ReturnUrl: RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl"),
            }
        };
    }

    get signInLink(){
        var returnUrl = RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl");

        if(!this.isSignUp && !returnUrl)
            returnUrl = this.$route.path;

        return <RouterLocation>{
            name: "Login",
            query: {
                ReturnUrl: returnUrl,
            }
        };
    }

    get isCheckoutBtnVisible(){
        return this.currentOrganization && this.currentOrganization.CanCurrentUserManage && this.currentOrganization.InTrial && this.currentOrganization.IsEnabled;
    }

    get isBreadcrumbRootInLongDisplay(){
        if(this.$vuetify.breakpoint.mobile) {
            return false;
        }
        if(!this.isCheckoutBtnVisible)
            return true;
    }

    getBreadcrumbLinkUrl(organizationId: string, breadcrumbFolder: RowShare.BreadcrumbFolder) {
        if(breadcrumbFolder.IsTag && !this.breadcrumbTagInUrl)
            return this.$route.path;

        if(breadcrumbFolder.Id == '' || breadcrumbFolder.Id == this.currentOrganization?.RootFolder?.Id)
            return { name:'MyTables', params: {organizationId: organizationId, folderId: this.currentOrganization?.RootFolder?.Id}};
        else
            return { name:'MyTables', params: {organizationId: organizationId, folderId: breadcrumbFolder.Id}};
    }

    scheduleDemo() {
        Calendly.initPopupWidget({ url: this.$i18n.t('Header_ScheduleDemoUrl').toString() });
    }

    get previousLocation() : RouterLocation | string {
        return ApplicationModule.mobileAppPreviousLocation;
    }

    previous(location: RouterLocation){
        this.$router.push(location);
    }
}
