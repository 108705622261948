
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import RemindUsersDialog from '@/views/Table/ListActions/RemindUsersDialog.vue';
import { ApplicationModule } from '@/store/ApplicationStore'
import * as RowShare from '@/models/RowShare';
import * as API from "@/api/Api";
import { EventBus } from "@/modules/EventBus";
import { Location as RouterLocation, Route } from "vue-router";
import PageLoadSpinner from "@/views/layouts/LayoutParts/PageLoadSpinner.vue";
import ListVM from '@/viewModels/Table/listVM';
import RowVM from '@/viewModels/Table/rowVM';
import FormViewVM from '@/viewModels/Table/formViewVM';
import { ColumnDescriptionVM, ColumnDescriptionCollection } from '@/viewModels/Table/ColumnDescriptionVM';
import Bigram from "@/views/components/Bigram.vue";
import CellFormView from '@/views/Table/FormView/CellFormView.vue';
import { ListTreeModule } from '@/store/ListTreeStore';
import GridActions from '@/viewModels/Table/GridActions';
import ProxyBasedCardRenderer from '@/views/Table/CardView/ProxyBasedCardRenderer.vue';
import CommentCardRenderer from '@/views/Table/cellsComponents/Comment/CommentCardRenderer.vue';
import TwoStatesBooleanCardRenderer from '@/views/Table/cellsComponents/TwoStateBoolean/TwoStatesBooleanCardRenderer.vue';
import i18n from '@/modules/Localization';
import { Utils } from '@/utils/Utilities';
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

@Component({
    name: 'RowFormView',
    components: {
        PageLoadSpinner, Bigram, RemindUsersDialog,
        ProxyBasedCardRenderer, CommentCardRenderer, TwoStatesBooleanCardRenderer, CellFormView
    }
})
export default class RowFormView extends Vue {

    @Prop() listVM!: ListVM;
    @Prop() rowId !: string;
    @Prop({ default: 'view' }) editorState !: RowShare.EditorState;
    @Prop({ default: true }) showBottomNavigation!: boolean;
    @Prop({ default: false }) sidePanelMode!: boolean;
    
    formViewVM!: FormViewVM;

    navigatingFromNewToRowId = false;
    
    private columnDescriptions: ColumnDescriptionCollection = new ColumnDescriptionCollection();
    private cellStates : ("form-cell-error"|"form-cell-saving"|"form-cell-readonly"|"")[] = [];
    private cellErrors : (string|null)[] = [];

    private rowVM : RowVM | null = null;
    
    created(){
        this.formViewVM = new FormViewVM(this.listVM);

        EventBus.$on(RowShare.Event.VALIDATE_FORM, () => {
            (this.$refs.formEditor as Vue & { validate: () => boolean })?.validate();
        });
        
        ApplicationModule.updateAppCommands(this.setCommand());
        window.addEventListener('beforeunload', this.beforeUnload);

        this.init();
    }

    async mounted() : Promise<void>{
        this.onListLoaded();

        this.initCols();
        this.formViewVM.setRowUpdateCallBack(rowVM => this.refreshRowState(rowVM));
    }

    @Watch("listVM.columns")
    initCols(){
        if(!this.listVM.columns)
            return;

        this.columnDescriptions = new ColumnDescriptionCollection();
        var columns: RowShare.Column[] = [...this.listVM.columns];

        while(columns.length > 0) {
            let col = columns.splice(0, 1);
            let colDesc = new ColumnDescriptionVM(col[0]);
            this.columnDescriptions.add(colDesc);
            if(!Utils.isNullOrWhiteSpace(col[0].ColumnGroup)) {
                let index: number = -1;
                do {
                    index = columns.findIndex(c => c.ColumnGroup === col[0].ColumnGroup);
                    if(index > -1) {
                        col = columns.splice(index, 1);
                        colDesc = new ColumnDescriptionVM(col[0]);
                        this.columnDescriptions.add(colDesc);
                    }
                } while(index > -1);
            }
        }
    }


    beforeDestroy() {
        EventBus.$off(
            [
                RowShare.Event.ROW_UPDATED, 
                RowShare.Event.VALIDATE_FORM
            ]
        );
        window.removeEventListener('beforeunload', this.beforeUnload);
        this.formViewVM.dispose();
    }

    get canAddRow(): boolean {
        return this.listVM?.canAddNewRow;
    }

    get canDeleteRow(){
        if(!this.listVM.list || !this.rowVM) {
            return false;
        }
        return ((this.rowVM.rsRow.CanSuppress)
                    && ((this.listVM.list?.Owned ?? false)
                    || !(this.listVM.hasReadOnlyColumns)
                        && !(this.listVM.hasHiddenColumns))
                    && !this.rowVM.isLocked);
    }

    get canDuplicateRow(){
        return this.listVM.list?.CanCreate
            && !this.listVM.hasUniqueColumns
            && !this.sidePanelMode;
    }

    /**  
     * This method is called by formViewVM everytime a row is refreshed
     * It is used to refresh the UI with error icon or saving spinner
     **/
    refreshRowState(rowVM: RowVM){
        if(!this.listVM.columns || !this.listVM.list)
            return;

        if(rowVM.rowVMId != this.rowVM?.rowVMId)
            return;

        var cellStates : ("form-cell-error"|"form-cell-saving"|"form-cell-readonly"|"")[] = [];
        var cellErrors : (string|null)[] = [];
        for(let i = 0; i < (this.listVM.columns.length); ++i){
            var col = this.listVM.columns[i];

            cellErrors.push(this.rowVM.getCellError(col));
                
            if(this.rowVM?.isSavingCell(col)) {
                cellStates.push("form-cell-saving");
            } else if(this.rowVM?.hasCellError(col)) {
                cellStates.push("form-cell-error");
            } else if(!this.rowVM.rsRow.CanUpdate || this.rowVM.isLocked) {
                cellStates.push("form-cell-readonly");
            } else {
                cellStates.push("");
            }
        }

        // no error, not saving, this may be a succesfull save
        // for new row, first save should refresh the URL to prevent creating another new row on F5 and allow URL sharing
        // We also check if we are in formView to prevent navigating to it when showing calendarView
        if(!this.rowVM.hasError && !this.rowVM.valuesBeingSaved && this.rowId.toLowerCase() == "new" && this.rowVM.rsRow.Id && !this.navigatingFromNewToRowId
            && this.$route.name === 'Form') {
            this.navigatingFromNewToRowId = true; // prevent multiple navigation to the same route, that would crash the router
            this.$router.push({ 
                name: 'Form',
                params: { listId: this.listVM.list.Id, rowId: rowVM.rsRow.Id },
            });
        }
        
        this.cellStates = cellStates;
        this.cellErrors = cellErrors;
    }

    private rowPosition = 0;
    private nextRowId : string | null= null;
    private previousRowId : string | null= null;

    @Watch("rowId")
    onRowIdChanged(){
        this.init();
    }

    @Watch("listVM.rowVMs")
    onRowVMsChanged(){
        this.init();
    }

    init(){
        this.navigatingFromNewToRowId = false;

        if(!this.listVM.list || !this.listVM.rowVMs)
            return;

        if(this.rowId.toLowerCase() == "new") {
            let newRowVM = this.listVM.rowVMs?.find(r => r.isNew) ?? null;
            if(newRowVM == null) {
                newRowVM = RowVM.createNewRow(this.listVM, this.listVM.list.Id);
                this.listVM.rowVMs.push(newRowVM);
            }
            this.rowVM = newRowVM;
        } else {
            this.rowVM = this.listVM.rowVMs?.find(r => r.rsRow.Id == this.rowId) ?? null;
        }
        
        if(!this.rowVM?.rsRow)
            return 0;

        this.refreshRowState(this.rowVM);
        this.setPreviousLocation();

        let pos = 1;
        let prev : RowVM | null = null;
        let next : RowVM | null = null;
        for(let i = 0; i < this.listVM.rowVMs.length; ++i) {
            let loopRowVM = this.listVM.rowVMs[i];
            
            if(loopRowVM.rsRow.Index < this.rowVM.rsRow.Index)
                pos++;

            if(loopRowVM.rsRow.Index < this.rowVM.rsRow.Index && (prev == null || loopRowVM.rsRow.Index > prev.rsRow.Index))
                prev = loopRowVM;

            if(loopRowVM.rsRow.Index > this.rowVM.rsRow.Index && (next == null || loopRowVM.rsRow.Index < next.rsRow.Index))
                next = loopRowVM;
        }
        
        this.rowPosition = pos;
        this.previousRowId = prev?.rsRow.Id ?? null;
        this.nextRowId = next?.rsRow.Id ?? null;
    }

    goBackward(){
        this.handleBeforeRouteLeave(() => {
            if(!this.previousRowId || !this.listVM.list)
                return;

            this.$router.push({ 
                name: 'Form',
                params: { listId: this.listVM.list.Id, rowId: this.previousRowId, mode: 'details' },
            });
        });    
    }

    goForward(){
        this.handleBeforeRouteLeave(() => {
            if(!this.nextRowId || !this.listVM.list)
                return;

            this.$router.push({ 
                name: 'Form',
                params: { listId: this.listVM.list.Id, rowId: this.nextRowId, mode: 'details' },
            });
        });
    }

    addRow(){
        if(!this.listVM.list)
            return;

        this.$router.push({ 
            name: 'Form',
            params: { listId: this.listVM.list.Id, rowId: 'new' },
        });
    }

    setPreviousLocation() {
        let previousLocation = this.listVM.list?.Id ?
                    <RouterLocation>{
                        name: 'Table',
                        params: { listId: this.listVM.list.Id },
                        query: { currentRowId: this.rowVM?.rsRow.Id }
                } : '';
        ApplicationModule.updateAppPreviousLocation(previousLocation);            
    }

    @Watch("listVM.list")
    onListLoaded(){
        this.setPreviousLocation();            
        if(this.listVM.list) {
            document.title = this.listVM.list.DisplayName + " | RowShare";
        } else {
            document.title = "RowShare";
        }
    }

    removeRow(){
        if(!this.rowVM) {
            console.error("Failed deleting the row");
            return;
        }
        
        GridActions.removeRow(this.rowVM, this.formViewVM, false, () => {
            if(!this.sidePanelMode) {
                if(this.$vuetify.breakpoint.mobile || this.listVM.rowVMs?.length === 0) {
                    let previousLocation = this.listVM.list?.Id ?
                        <RouterLocation>{
                            name: 'Table',
                            params: { listId: this.listVM.list.Id }
                        } : '';
                    this.$router.push(previousLocation);
                }
                else {
                    if(this.previousRowId) {
                        this.goBackward();
                    }
                    else if(this.nextRowId) {
                        this.goForward();
                    }
                }
            }
            this.$emit('rowDeleted');
        }); 
    }
    
    async cloneRow(){
        if(!this.rowVM || !this.listVM.rowVMs)
            return;

        let clonedRow = await API.Row.clone(this.rowVM.rsRow.Id, true, RealTimeCollaborationModule.connection.connectionId);
        this.listVM.rowVMs.push(new RowVM(this.listVM, clonedRow))

        let newLocation = this.listVM.list?.Id ?
            <RouterLocation>{
                name: 'Form',
                params: { listId: this.listVM.list.Id, rowId: clonedRow.Id }
            } : '';
        this.$router.push(newLocation);
    }
    
    isRemindUsersModalOpen: boolean = false;
    
    onRemindUsersClicked() {
        this.isRemindUsersModalOpen = true;
    }

    get canRemindUsers(): boolean {
        if(!this.listVM?.list || !this.rowVM) {
            return false;
        }
        return this.listVM.list.Owned && !this.rowVM.isLocked;
    }

    onRowMergeClicked() {
        if(!this.listVM.rowVMs || !this.listVM.list || !this.rowVM)
            return;

        let context = new RowShare.ReportGenerationContext();
        context.rowIds = [this.rowVM.rsRow.Id];
        context.totalTableRowCount = this.listVM.list.RowCount;
        context.hasReadOnlyRows = !this.rowVM.rsRow.CanUpdate || this.rowVM.isLocked;

        EventBus.$emit(RowShare.Event.ROWMERGE_REQUEST, context);
    }
    
    get canRunRowMerge(): boolean {
        if(!this.rowVM) {
            return false;
        }
        return (ListTreeModule.currentListCapabilities?.rowMerge ?? false);
    }

    onCellClicked(colDesc: ColumnDescriptionVM){
        if(!this.rowVM?.rsRow.CanUpdate) {
            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                autoHide: true,
                autoHideAfterMs: 3000,
                type: RowShare.NotificationType.warning,
                title: i18n.t('FormView_ReadonlyRow_Title').toString(),
                message: i18n.t('FormView_ReadonlyRow_Detail').toString(),
                duplicateId: "FormViewReadonlyCellClicked"
            });
        }
        else if(this.rowVM.isLocked) {
            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                title: i18n.t("Notif_LockedRow").toString(),
                autoHide: true,
                autoHideAfterMs: 2000
            });
        }
        else {
            if(colDesc?.columnIsChild ?? false) {
                if(this.rowVM?.rsRow && this.rowVM.rsRow.Values[colDesc.columnTitle] === RowVM.cellNotAccessible) {
                    EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                        title: i18n.t("Notif_NotEditableCell").toString(),
                        autoHide: true,
                        autoHideAfterMs: 3000,
                        duplicateId: "NotEditableCellClicked"
                    });
                }
                else {
                    EventBus.$emit(RowShare.Event.ROW_EDIT_RELATED_ROWS, <RowShare.rowEditRelatedRowsEventParams> {
                        rowVM: this.rowVM,
                        relatedListId: colDesc.currentColumn?.RelatedListId,
                        column: colDesc.currentColumn,
                        refreshParentRow: () => {}
                    });
                }
            }
        }
    }

    setCommand(): Array<RowShare.CommandEvent> {
        let commands = new Array<RowShare.CommandEvent>();

        let commandDelete = new RowShare.CommandEvent();
        commandDelete.order = 2;
        commandDelete.icon = 'trash';
        commandDelete.eventName = RowShare.Event.ROW_DELETE;
        commandDelete.color = '#fff';
        commandDelete.isToggable = false;
        commandDelete.isActive = false;

        commands.push(commandDelete);
        return commands;
    }

    beforeRouteLeave(to: Route, from: Route, next: any){
        this.handleBeforeRouteLeave(() => next());    
    }

    canLeaveRoute(){
        return !this.rowVM || this.rowVM.removed == true || (!this.rowVM.dirtyValues && !this.rowVM.valuesBeingSaved);
    }

    handleBeforeRouteLeave(callback : () => void) {
        if(!this.canLeaveRoute()){
            let event = new RowShare.ConfirmationRequiredEventParams();
            event.title = this.$i18n.t('NotSavedItemsDialog_Title').toString();
            event.description1 = this.$i18n.t('NotSavedItemsDialog_Description').toString();
            event.cancelButtonText = this.$i18n.t('NotSavedItemsDialog_Cancel').toString();
            event.actionButtonText = this.$i18n.t('NotSavedItemsDialog_Validate').toString();
            event.actionButtonColor = "primary";
            event.onConfirmation = async () => {
                callback();
            };
            EventBus.$emit(RowShare.Event.CLEAR_ALL_GLOBAL_NOTIFICATIONS);
            EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, event);
        }
        else {
            callback();
        }
    }

    beforeUnload(evt: BeforeUnloadEvent) {
        if(!this.canLeaveRoute()){
            evt.returnValue = this.$i18n.t('NotSavedItemsDialog_Description');
            evt.preventDefault();
        }
    }
}
