
    import {Component, Prop, Vue} from 'vue-property-decorator';

    @Component({
        name: 'TwoStatesBooleanCardRenderer'
    })
    export default class TwoStatesBooleanCardRenderer extends Vue {
        @Prop({ default : false }) value !: boolean;

        get icon() : string {
            return this.value ? 'fa-check' : 'fa-times';
        }

        get color() {
            return this.value ? 'success' : 'error';
        }
    }
