
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
    import RsColumnManager from '@/columnProxies/RsColumnManager';
    import RichTextField from '@/views/Table/cellsComponents/RichText/RichTextField.vue'

    @Component({name: 'RichTextFormEditor', components: { RichTextField }})
    export default class RichTextFormEditor extends FormEditor {
        currentValue: string | null = null;

        init(){
            const proxy  = RsColumnManager.getProxy(this.column.Type);
            this.currentValue = this.rowVM.getValue(this.column, proxy);
            this.$nextTick(() => {
                (<any>this.$refs.richtextfield).init(); 
            });  
        }

        getValue(){
            return this.currentValue;
        }

        onBlur(){
            this.saveCellValue();
        }
    }
