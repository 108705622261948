import * as models from '@/models/RowShare';
import * as agGrid from 'ag-grid-community';

export class groupVM extends models.Group {
    initialGroup!: models.Group | null;
    
    constructor(group: models.Group | null){
        super();
        this.initialGroup = null;
        if(group){
            Object.assign(this, group);
            this.initialGroup = group;
        }
    }

    public IsNew!: boolean;
    public hasError!: boolean;
}

export interface IGroupEditorParams extends agGrid.ICellEditorParams{
    organizationId: string;
    idsToRemove: string[];
}