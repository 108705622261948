
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ListTreeModule } from '@/store/ListTreeStore';
import { Location as RouterLocation } from "vue-router";
import * as Models from "@/models/RowShare";
import MyTablesDisabledOrganization from "@/views/MyTables/MyTablesDisabledOrganization.vue";
import ListThumbnail from "./ListThumbnail.vue";
import FolderThumbnail from "./FolderThumbnail.vue";
import MyTablesActions from "./MyTablesActions.vue";
import PageLoadSpinner from "@/views/layouts/LayoutParts/PageLoadSpinner.vue";
import { List } from '@/models/List';
import { ListThumbnailInfo, ListTreeFolder } from '@/models/RowShare';
import { ArrayUtils } from '@/utils/Array';
import { RowShareException} from '@/api/ApiUtils';
import SearchResult from './SearchResult.vue';
import { EventBus } from '@/modules/EventBus';
import { UserModule } from '@/store/UserStore';

@Component({
    components: {
        PageLoadSpinner,
        ListBrowser,
        ListThumbnail,
        FolderThumbnail,
        MyTablesActions,
        MyTablesDisabledOrganization,
        SearchResult
    }
})
export default class ListBrowser extends Vue {

    @Prop() organizationId!: string | null;
    @Prop() folderId!: string;
    @Prop({ default: null }) lmCurrent!: string | null;

    private listDragged = new ListThumbnailInfo();
    private folderIdDragged = '';
    private sortDirection: boolean | 'asc' | 'desc' = 'asc';
    private displaySearchResult: boolean = false;

    private lmRecents: string = "Recents";
    private lmFavorites: string = "Favorites";
    private searchText: string = '';

    created() {
        EventBus.$on(Models.Event.TOGGLE_RECENT, this.onToggleRecentOrFavorites);
        EventBus.$on(Models.Event.TOGGLE_FAVOURITE, this.onToggleRecentOrFavorites);
        EventBus.$on(Models.Event.SEARCH_TRIGGERED, this.onSearch);
        EventBus.$on(Models.Event.SEARCH_RESET, this.onSearchReset);
    }

    async beforeMount() {
        this.refreshTrees(false);
    }

    async mounted() {
        this.updateTabTitle();
    }

    beforeDestroy() { 
        EventBus.$off([Models.Event.TOGGLE_RECENT,  
                Models.Event.TOGGLE_FAVOURITE,
                Models.Event.SEARCH_TRIGGERED,
                Models.Event.SEARCH_RESET]);
    }

    @Watch('currentFolder')
    updateTabTitle() {
        if (!this.currentFolder) {
            document.title = "RowShare";
        } else {
            if (this.currentFolder.Id == this.currentOrganization?.RootFolder.Id)
                document.title = this.currentOrganization.Name + " | RowShare";
            else
                document.title = this.currentFolder.Name + " | RowShare";
        }
    }

    viewAll() {
        this.resetSearch();
        this.lmCurrent = "";
    }

    async refreshTrees(enforce: boolean | false) {
        await ListTreeModule.refreshOrganizationsTreeIfMissing(false);
        ListTreeModule.refreshListTreeIfMissing(enforce)
             .catch((e: RowShareException) => {
                if (e.httpResponse?.status == 404
                    || e.httpResponse?.status == 401 
                    || e.httpResponse?.status == 403) {
                    if (!UserModule.CurrentUser.IsAnonymous)
                       window.location.href = '/OrganizationAccessDenied';

                    else
                       window.location.href = '/login?ReturnUrl=MyTables';
                }});           
    }

    get showRecents(): boolean {
        if (this.lmCurrent === this.lmRecents)
        {
            EventBus.$emit(Models.Event.TOGGLE_RECENT);
            EventBus.$off(Models.Event.TOGGLE_RECENT)
            return true;
        }
        else
        {
            EventBus.$on(Models.Event.TOGGLE_RECENT, this.onToggleRecentOrFavorites);
            return false;
        }
    }

    get showFavorites(): boolean {
        if (this.lmCurrent === this.lmFavorites)
        {
            EventBus.$emit(Models.Event.TOGGLE_FAVOURITE);
            EventBus.$off(Models.Event.TOGGLE_FAVOURITE)
            return true;
        }
        else
        {
            EventBus.$on(Models.Event.TOGGLE_FAVOURITE, this.onToggleRecentOrFavorites);
            return false;
        }
    }

    get canAddNewList(): boolean {
        if (this.currentOrganization && this.currentOrganization.IsEnabled && this.currentFolder)
            return this.currentFolder.CanCurrentUserCreateList && this.currentFolder.HasRemainingListSlot;

        return false;
    }

    get orderedFolders(): Array<Models.ListTreeFolder> {
        if (this.currentFolder) {
            let folders = this.currentFolder.Folders;
            return ArrayUtils.sort(
                folders,
                [(folder: { Name: string; }) => folder.Name.toLowerCase()],
                this.sortDirection);
        }
        return new Array<Models.ListTreeFolder>();
    }

    get orderedLists(): Array<Models.ListThumbnailInfo> {
        if (this.currentFolder) {
            let lists = this.currentFolder.Lists;
            return ArrayUtils.sort(
                lists,
                [(list: { Name: string; }) => list.Name.toLowerCase()],
                this.sortDirection);
        }
        return new Array<Models.ListThumbnailInfo>();
    }

    get createTableLocation(): RouterLocation {
        return <RouterLocation>{
            name: "CreateTable",
            query: {
                organization: this.currentOrganization?.Id,
                folderId: this.currentFolder?.Id
            }
        };
    }

    get currentOrganization(): Models.ListTreeOrganization | null {
        return ListTreeModule.currentOrganization;
    }

    get currentFolder(): Models.ListTreeFolder | null {
        return ListTreeModule.currentFolder;
    }

    get otherOrganizations(): Array<Models.ListTreeOrganization> | null {
        if (!ListTreeModule.organizationsTree)
            return null;

        return ListTreeModule.organizationsTree.Organizations.filter(o => o.Id != this.currentOrganization?.Id).sort((o1, o2) => o1.Name.toLowerCase().localeCompare(o2.Name.toLowerCase()));
    }

    get orgIcon(): string | null {
        if (this.currentOrganization?.IconPath) {
            return (
                "/" + this.currentOrganization.IconPath + "?_ssb_img=S;w:64;h:64"
            );
        }
        else {
            return "@/assets/static/images/org.svg"
        }
    }

    get recentLinkUrl() {
        if (this.showRecents) {
            return { name: 'MyTables', params: { organizationId: this.currentOrganization?.Id } };
        }
        else {
            return { name: 'MyTables', params: { organizationId: this.currentOrganization?.Id, folderId: this.lmRecents } };
        }
    }

    get favLinkUrl() {
        if (this.showFavorites) {
            return { name: 'MyTables', params: { organizationId: this.currentOrganization?.Id } };
        }
        else {
            return { name: 'MyTables', params: { organizationId: this.currentOrganization?.Id, folderId: this.lmFavorites } };
        }
    }

    dragStart(list: Models.ListThumbnailInfo, folderId: string, ev: Event) {
        this.orderedFolders.forEach(fol => {
            let elem = document.getElementById(fol.Id);
            if (elem) {
                elem.classList.add('stop-pointer-events');
            }
        });
        this.listDragged = list;
        this.folderIdDragged = folderId;
    }

    clearDragInfo() {
        this.clearStyleClass();
        this.listDragged = new ListThumbnailInfo();
        this.folderIdDragged = '';
    }

    clearStyleClass() {
        this.orderedFolders.forEach(fol => {
            let elem = document.getElementById(fol.Id);
            if (elem) {
                elem.classList.remove('drop-allowed');
                elem.classList.remove('drop-not-allowed');
                elem.classList.remove('stop-pointer-events');
            }
        });
    }

    droppedItem(folder: ListTreeFolder) {
        if (!folder.IsRecycleBin && folder.CanCurrentUserCreateList) {
            if (this.listDragged.Id != null && this.listDragged.Id != undefined && this.listDragged.Owned) {
                let listToMoveData = new List();
                listToMoveData.Id = this.listDragged.Id;
                listToMoveData.FolderId = folder.Id;
                listToMoveData.OrganizationId = this.currentOrganization?.Id ?? '';

                let deleteInfo = {
                    listId: this.listDragged.Id,
                    folderId: this.folderIdDragged,
                    organizationId: this.currentOrganization?.Id
                }

                let allInfoToMove = {
                    listToMove: listToMoveData,
                    moveFrom: deleteInfo,
                    listThumbnail: this.listDragged
                }
                ListTreeModule.moveList(allInfoToMove);
            }
        }
        this.clearDragInfo();
    }

    toggleSortDirection() {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    }

    private timeout!: number;
    searchInsideCurrentFolder(): void {
        if (this.currentOrganization?.RootFolder && (this.searchText.length > 1 || this.searchText.length == 0)) {
            if (!this.timeout) {
                this.timeout = setTimeout(() => {
                    this.timeout = 0;
                    EventBus.$emit(Models.Event.SEARCH_TRIGGERED,
                        this.searchText,
                        this.currentOrganization?.RootFolder,
                        this.currentOrganization?.Id);

                }, 500);
            }
        }
    }

    resetSearch() {
        EventBus.$emit(Models.Event.SEARCH_RESET);
    }

    onSearch(searchText: string, folder: Models.ListTreeFolder, orgId: string) {
        if (searchText) {
            this.displaySearchResult = searchText != '';
        }
        else {
            this.displaySearchResult = false;
        }
    }

    onSearchReset() {
        this.searchText = '';
        this.displaySearchResult = false;
    }

    onToggleRecentOrFavorites(){
        this.refreshTrees(true);
    }
}
