import * as RowShare from '@/models/RowShare';
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import { ICellRendererParams } from 'ag-grid-community';
import i18n from '@/modules/Localization';
import FileVM from '@/viewModels/Table/FileVM';
import * as agGrid from 'ag-grid-community';
import { params } from 'vee-validate/dist/types/rules/alpha';

export default class BlobColumnProxy extends BaseColumnProxy {
    isAlwaysEditable() {
        return true;
    }

    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        return val;
    }
    
    getFormattedHtml(rsColumn: RowShare.Column, rowVM: RowVM, val: any): string {
        return this.innerGetHtml(rsColumn, rowVM, val, true)
    }

    getFormattedHtmlTooltip(rsColumn: RowShare.Column, rowVM: RowVM, val: any): string {
        return this.innerGetHtml(rsColumn, rowVM, val, false)
    }

    innerGetHtml(rsColumn: RowShare.Column, rowVM: RowVM, val: any, isThumbnail: boolean): string {
        if(!rsColumn || val === undefined || val === null || val === "")
            return "";

        var isImage = val?.ContentType?.startsWith('image/') ?? false; 
        
        var downloadUrl = "";
        if(val?.AbsoluteFileUrl){
            let url = new URL(val?.AbsoluteFileUrl);
            if(url) {
                downloadUrl = url.pathname;
            }
        }

        var thumbnailUrl = '';
        var cellContent = "";

        if(isImage) {
            thumbnailUrl =  downloadUrl + (isThumbnail ? "?_ssb_img=S;h:40" : "");
            cellContent = `
                <div class="blob-renderer" >
                    <img src="${thumbnailUrl}" aspect-ratio="1" class="blob-renderer-thumbnail"/>
                </div>
            `;
        }                     
        else {
            cellContent = `
                <div class="blob-renderer">
                    <i class="v-icon notranslate theme--light blob-renderer-thumbnail file-thumbnail fal fa-${val?.Icon?.Name}"
                     ${val?.Icon?.Color ? 'style="color: ' + val?.Icon?.Color + ';"' : ''}></i>
                    <span class="blob-label ml-2">${val.FileName}</span>
                </div>
            `;
        }

        return cellContent;
    }

    areEquals(val1: any, val2: any) {
        if(val1 === null || val1 === undefined || val2 === null || val2 === undefined)
            return val1 === val2;

        return val1.AbsoluteFileUrl === val2.AbsoluteFileUrl
            && val1.ContentType === val2.ContentType
            && val1.FileName === val2.FileName
            && val1.ImageUrl === val2.ImageUrl
            && val1.LastWriteTimeUtc === val2.LastWriteTimeUtc
            && val1.Size == val2.Size;
    }

     /** Filters **/
    
     getCustomFilterParams(rsCol: RowShare.Column) : null | any {
        var res = super.getCustomFilterParams(rsCol);
        
        res.valueFormatter = (params: ICellRendererParams) => {
            if(params.value)
                return i18n.t("Filter_Blob_NotEmpty").toString();
            else
                return i18n.t("Filter_Blob_Empty").toString();
        }

        return res;
    }

    /** Clipboard **/
    getClipboardValue(rsColumn: RowShare.Column, rowVM: RowVM, val: any): any {
        let fileVM = <FileVM>val;
        if(fileVM) {
            return fileVM.AbsoluteFileUrl ? fileVM.AbsoluteFileUrl : fileVM.ImageUrl;
        }
        return null;
    }

    /** Grid context menu **/
    getContextMenuEntries(params: agGrid.GetContextMenuItemsParams) : agGrid.MenuItemDef[] | null {
        if(!params.value)
            return null;

        return [new ShowImageContextMenuEntry(params)];
    }
}


class ShowImageContextMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('Grid_DownloadBlobContextMenuEntry').toString();

    private params: agGrid.GetContextMenuItemsParams;

    constructor(params: agGrid.GetContextMenuItemsParams){
        this.params = params;
    }

    public action = () => {
        let file = <FileVM>this.params.value;
        if(file.AbsoluteFileUrl) {
            let url = new URL(file.AbsoluteFileUrl);
            if(url) {
                window?.open(url.pathname, '_blank')?.focus();
            }
        }
    }
}