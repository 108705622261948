import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"

export class ListAccessAudit {
    static load(orgId: string, options: IRequestOptions | null = null) : Promise<Models.ListAccessAudit | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/ListAccessAudit/load/" + ApiUtils.encodeSegment(orgId || ""), options)
            .then(response => response.object(Models.ListAccessAudit));
    }
}