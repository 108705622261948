var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('bandeau'),_vm._v(" "),_c('v-container',{staticClass:"col-md-6 offsetmd-6",attrs:{"fluid":""}},[_c('div',{staticClass:"pa-3 mt-n12 mb-12 container-box"},[(!_vm.resetPasswordSuccess && !_vm.resetPasswordError && !_vm.tokenError)?_c('div',[_c('h2',{staticClass:"title mb-4 font-weight-medium primary--text"},[_vm._v("\n          "+_vm._s(_vm.$t("ResetPasswordRequest_Title"))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t("ResetPassword_Description")))]),_vm._v(" "),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ passes, invalid }){return [_c('form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('input-password',{attrs:{"inputPasswordId":'input-1',"inputPasswordName":_vm.$t('Form_Password'),"placeholder":_vm.$t('Form_Password') + ' *',"inputPasswordValidationRules":{
                required: true,
                min: 8,
                max: 64, //in addition to passwordrule 
                regex: _vm.passwordRule                 
              },"showTooltip":_vm.isNotOnPrem,"tooltipText":_vm.$t('Common_PasswordRules')},model:{value:(_vm.resetPasswordModel.Password),callback:function ($$v) {_vm.$set(_vm.resetPasswordModel, "Password", $$v)},expression:"resetPasswordModel.Password"}}),_vm._v(" "),_c('input-password',{attrs:{"inputPasswordId":'input-2',"inputTextPasswordVid":"confirmPassword","inputPasswordName":_vm.$t('Form_ConfirmPassword'),"placeholder":_vm.$t('Form_ConfirmPassword') + ' *',"inputPasswordValidationRules":{
                required: true,
                min: 8,
                max: 64, //in addition to regex rule
                confirmed: _vm.$t('Form_Password'),
                regex: _vm.passwordRule             
              }},model:{value:(_vm.resetPasswordModel.ConfirmPassword),callback:function ($$v) {_vm.$set(_vm.resetPasswordModel, "ConfirmPassword", $$v)},expression:"resetPasswordModel.ConfirmPassword"}}),_vm._v(" "),_c('v-btn',{staticClass:"mb-3 submit-button text-none",attrs:{"disabled":invalid,"type":"submit","color":"primary"}},[_vm._v("\n              "+_vm._s(_vm.$t("ResetPasswordRequest_Button"))+"\n            ")])],1)]}}],null,false,985884102)})],1):(_vm.resetPasswordSuccess && !_vm.resetPasswordError && !_vm.tokenError)?_c('div',[_c('h2',{staticClass:"title mb-4 font-weight-medium primary--text"},[_vm._v("\n          "+_vm._s(_vm.$t("ResetPassword_Success_Title"))+"\n        ")]),_vm._v(" "),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("ResetPassword_Success_FirstDescription")))]),_vm._v(" "),_c('router-link',{attrs:{"to":_vm.getLoginUrl()}},[_vm._v(_vm._s(_vm.$t("ResetPassword_Success_DescriptionLinkText")))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("ResetPassword_Success_SecondDescription")))])],1)]):(!_vm.resetPasswordSuccess && _vm.resetPasswordError && !_vm.tokenError)?_c('div',[_c('h2',{staticClass:"title mb-4 font-weight-medium primary--text"},[_vm._v("\n          "+_vm._s(_vm.$t("ResetPassword_Error_Title"))+"\n        ")]),_vm._v(" "),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("ResetPassword_Error_Description")))]),_vm._v(" "),_c('router-link',{attrs:{"to":_vm.getResetPasswordUrl()}},[_vm._v(_vm._s(_vm.$t("ResetPassword_Error_LinkText")))])],1)]):_c('div',[_c('h2',{staticClass:"title mb-4 font-weight-medium primary--text"},[_vm._v("\n          "+_vm._s(_vm.$t("ResetPassword_Error_Title"))+"\n        ")]),_vm._v(" "),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("ResetPassword_TokenError_Description")))]),_vm._v(" "),_c('router-link',{attrs:{"to":_vm.getResetPasswordUrl()}},[_vm._v(_vm._s(_vm.$t("ResetPassword_Error_LinkText")))])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }