import * as RowShare from "@/models/RowShare";
import { DateTime } from "@/utils/Date";
import TimeSpan from "@/utils/TimeSpan";
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';

export default class TimeColumnProxy extends BaseColumnProxy {
    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        let timespan = DateTime.parseTime<TimeSpan | null>(val, null);
        let result: string = "";
        if(timespan) {
            result = timespan.hours.toString().padStart(2, '0') + ":" + timespan.minutes.toString().padStart(2, '0');
            if(timespan.seconds > 0) {
                result += ":" + timespan.seconds.toString().padStart(2, '0');
            }
        }

        return result;
    }
}