import * as RowShare from '@/models/RowShare';
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';

export default class CommentColumnProxy extends BaseColumnProxy {
    parseInitialTypedValue(rsColumn: RowShare.Column, rowVM: RowVM): any {
        const comment = rowVM.rsRow.Values[rsColumn.Name];
        var count = comment?.CommentCount;

        if(count === undefined || count === null)
            return count;

        var res = Number.parseFloat(count);
        return res;
    }

    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        return val;
    }

    getRenderer(rsCol: RowShare.Column) : string{
        return 'CommentRenderer';
    }
    
    isAlwaysEditable() {
        return true;
    }

    /** Filters **/
    getCustomFilter(rsCol: RowShare.Column) : null | string{
        return "agNumberColumnFilter";
    }

    getCustomFilterParams(rsCol: RowShare.Column) : null | any {
        return {
            inRangeInclusive: true,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'lessThanOrEqual',
                'greaterThan',
                'greaterThanOrEqual',
                'inRange'
            ]
        }
    }

    /** Clipboard **/
    getClipboardValue(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        return `${rsColumn.DisplayName} : ${val ?? 0}`;
    }
    
}