
    import { Component } from 'vue-property-decorator';
    import { FormValidation } from '@/views/components/FormValidation';
    import GlobalConfirmationDialog from "@/views/components/GlobalConfirmationDialog.vue"
    import NotificationStack from "@/views/layouts/LayoutParts/NotificationStack.vue"
    import UpsellDialog from '@/views/components/UpsellDialog.vue';
    import SidePanelFormView from '@/views/Table/FormView/SidePanelFormView.vue';
    import SidePanelSharing from '@/views/Table/ShareTable/SidePanelSharing.vue';
    import SidePanelColumnPermissions from '@/views/Table/ColumnSettings/SidePanelColumnPermissions.vue';
    import SidePanelTableSettings from '@/views/Table/TableSettings/SidePanelTableSettings.vue';
    import SidePanelColumnSettings from '@/views/Table/ColumnSettings/SidePanelColumnSettings.vue';

    @Component({components: {NotificationStack, GlobalConfirmationDialog, UpsellDialog, SidePanelColumnPermissions, SidePanelColumnSettings,SidePanelFormView,
                             SidePanelSharing, SidePanelTableSettings}})
    export default class MsTeamsLayout extends FormValidation {
    }
