
import {Component, Prop, Vue} from "vue-property-decorator";
import copyListDialog from "@/views/MyTables/ListActions/CopyListDialog.vue"
import * as API from "@/api/Api";
import * as RowShare from "@/models/RowShare";
import TableOwnerSelector from "@/views/Table/ShareTable/TableOwnerSelector.vue";
import {EventBus} from "@/modules/EventBus";
import {SettingNames, Settings} from "@/utils/Settings";
import {KeyCodes} from "@/utils/KeyCodes";
import {ListTreeModule} from "@/store/ListTreeStore";
import surveyModeDialog from "@/views/Table/ListActions/SurveyModeDialog.vue";
import ViewSelector from "@/views/Table/ViewSelector.vue";
import GridViewVM from "@/viewModels/Table/gridViewVM";
import {UserModule} from "@/store/UserStore";
import ListVM from "@/viewModels/Table/listVM";
import {ApplicationModule} from "@/store/ApplicationStore";
import ImportDialog from "@/views/Table/ListActions/ImportDialog.vue";
import colors from 'vuetify/lib/util/colors';
import linkifyStr from 'linkify-string';
import { RawLocation } from "vue-router";
import Bigram from "@/views/components/Bigram.vue";

@Component({
    name: "ListToolbar",
    components: {Bigram, TableOwnerSelector, copyListDialog, surveyModeDialog, ViewSelector, ImportDialog }
})
export default class ListToolbar extends Vue {
    @Prop() listVM!: ListVM;
    @Prop() gridViewVM!: GridViewVM;
    @Prop() showReturnToTable!: boolean;
    @Prop() hideMenu!: boolean;

    allNotifications: string = "all";
    ownedNotifications: string = "owned";
    noNotifications: string = "none";

    currentList: RowShare.List = new RowShare.List();
    currentOrg: RowShare.Organization | null = null;

    showManageTableOwners: boolean = false;
    userListLinks: RowShare.UserListLink = new RowShare.UserListLink();
    currentListInformations: RowShare.ListInformations = new RowShare.ListInformations();
    displayDescription: boolean = false;
    selectedEmailNotifications: string = "";
    isEditing: boolean = false;
    nameUpdateErrors: string = "";
    updatingName: boolean = false;
    excelMenuOpened: boolean = false;
    exportMenuOpened: boolean = false;
    menuOpened: boolean = false;

    get isMsTeamsApp(): boolean {
        return ApplicationModule.isMsTeamsApp;
    }

    get specialRowsDisplayed(): boolean {
        return this.listVM.specialRowsDisplayed;
    }

    get specialRowsName(): string {
        switch(this.listVM?.displayedRows) {
            case RowShare.DisplayedRows.Deleted:
                return this.$i18n.t('ListToolBar_DeletedRowsDisplayed', [this.organizationCleanDeletedRowsDelay]).toString();
            case RowShare.DisplayedRows.Archived:
                return this.$i18n.t('ListToolBar_ArchivedRowsDisplayed').toString();
            default:
                return "";
        }
    }

    get specialRowsChipColor(): string {
        switch(this.listVM?.displayedRows) {
            case RowShare.DisplayedRows.Deleted:
                return "secondary";
            case RowShare.DisplayedRows.Archived:
                return colors.teal.lighten3;
            default:
                return "";
        }
    }

    get hasArchiveRows(): boolean {
        return ListTreeModule.currentOrganization?.HasArchiveRows ??  false;
    }

    get hasRowMerge(): boolean {
        return ListTreeModule.currentOrganization?.HasRowMerge ?? false;
    }

    get showDescriptionNextTimeLabel(): string {
        return this.$i18n.t('ListToolBar_ShowDescriptionNextTime').toString();
    }

    downloadExcel() {
        let context = this.initExportContext()
        //let url =  `/api/list/export/${this.currentList?.Id}?f=Xls&_xlsoxml=true`;
        context.format = RowShare.RowShareServiceFormat.Xls;
        context.formatOptions = new RowShare.XlsFormatOptions();
        context.formatOptions.OpenXml = true;
        this.downloadFile(context);
    }

    downloadCsv() {
        let context = this.initExportContext()
        context.format = RowShare.RowShareServiceFormat.Csv;
        context.formatOptions = new RowShare.XlsFormatOptions();
        this.downloadFile(context);
    }

    async downloadFile(context: RowShare.ExportContext) {
        let file = await API.List.Export(this.listVM?.list?.Id ?? '', context);
        if(file) {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = file.name;
            link.click();
        }
    }

    onMenuToggle(opened: boolean) {
        if(!opened) {
            this.excelMenuOpened = false;
            this.exportMenuOpened = false;
        }
    }

    created() {
        this.loadListInfo(false);
        this.initUserListLinks();
        EventBus.$on(RowShare.Event.LISTINFO_CHANGED, () => this.loadListInfo(false));
        this.initCurrentListInformations()
        EventBus.$on(RowShare.Event.GRID_CLEAR_FILTERS, () => this.clearSearch())
        EventBus.$on(RowShare.Event.GRID_SEARCH_CHANGED, (event: RowShare.GridSearchChangeEventParams) => this.onExternalSearchChanged(event));
    }

    beforeDestroy(){
        EventBus.$off([
            RowShare.Event.LISTINFO_CHANGED,
            RowShare.Event.GRID_CLEAR_FILTERS,
            RowShare.Event.GRID_SEARCH_CHANGED,
        ])
    }

    initCurrentListInformations() {
        if(!this.listVM.list)
            return;

        this.currentListInformations = Settings.getJson(this.listVM.list.Id, SettingNames.information);
        if(!this.currentListInformations) {
            this.currentListInformations = new RowShare.ListInformations();
            this.currentListInformations.hidePermissionsMessage = true;
            this.currentListInformations.showDescription = false;
            this.showDescription = false;
            this.displayDescription = true;
        }
        else {
            this.displayDescription = this.currentListInformations.showDescription;
        }
    }

    loadListInfo(useCache: boolean = true) {
        if(!this.listVM.list)
            return;

        API.List.load(this.listVM.list.Id, {cache: useCache}).then(list => {
            if(list)
            {
                this.currentList = list;
            }
        });
    }

    returnToTable() {
        if(this.specialRowsDisplayed) {
            ListTreeModule.updateCurrentListDisplayedRows(RowShare.DisplayedRows.Active);
        }
        else {
            this.$router.push(<RawLocation>{name: 'Table', params: {listId: this.listVM.list?.Id}});
        }
    }

    async initUserListLinks() {
        if(!this.isLoggedIn || !this.listVM.list)
            return;
        let links = await API.UserListLink.loadByList(this.listVM.list.Id);
        if (links)
            this.userListLinks = links
    }

    public get isLoggedIn() {
        var user = UserModule.CurrentUser;
        return user != null && !user.IsAnonymous;
    }

    public get listIconUrl() {
        if( this.currentList?.IconPath) {
            return '/' + this.currentList.IconPath + "?_ssb_img=S;w:48;h:48";
        }
    }

    public get ownersNicknames() : string {
        if(!this.currentList?.OwnersNicknames || this.currentList.OwnersNicknames.length == 0) {
            return "";
        }
        var maxOwnersDisplay = 3;
        let owners: string[] = [];
        let othersOwners: string[] = [];
        for (let index = 0; index < this.currentList.OwnersNicknames.length; index++) {
            if(owners.length < maxOwnersDisplay) {
                owners.push(this.currentList.OwnersNicknames[index]);
            }
            else {
                othersOwners.push(this.currentList.OwnersNicknames[index]);
            }
        }

        if(othersOwners.length > 0) {
            if(othersOwners.length == 1) {
                owners.push(...othersOwners);
            }
            else {
                owners.push(this.$i18n.t('ListToolbar_ManyOtherOwners', [othersOwners.length]).toString());
            }
        }

        return owners.join(', ');
    }

    public get notificationsIcon() : string {
        let result : string = "";

        if(this.userListLinks) {
            if(this.userListLinks.isAllRowsEmailNotifications) {
                result = "fa-bell";
                this.selectedEmailNotifications = this.allNotifications;
            }
            if(this.userListLinks.isOwnedRowsEmailNotifications) {
                result = "far fa-bell";
                this.selectedEmailNotifications = this.ownedNotifications;
            }
        }

        if(result.length === 0) {
            result = "fa-bell-slash";
            this.selectedEmailNotifications = this.noNotifications;
        }

        return result;
    }

    public get notificationsIconColor() : string {
        if(!this.userListLinks.isNoEmailNotifications) {
            return "primary";
        }
        return "";
    }

    public get showDescription() : boolean {
        return this.currentListInformations.showDescription;
    }

    public set showDescription(value: boolean) {
        if(!this.listVM.list)
            return;

        this.currentListInformations.showDescription = value;
        Settings.setJson(this.listVM.list.Id, SettingNames.information, this.currentListInformations)
    }

    get folder(): RowShare.ListTreeFolder | null {
        return ListTreeModule.currentFolder;
    }

    get organizationId(): string {
        return ListTreeModule.currentOrganization?.Id ?? "";
    }

    get organizationCleanDeletedRowsDelay(): string {
        return ListTreeModule?.currentOrganization?.SubscriptionCleanDeletedRowsDelay.toString() ?? "";
    }

    getRowMergeGenerationContext(): RowShare.ReportGenerationContext {
        let context = new RowShare.ReportGenerationContext();
        context.totalTableRowCount = this.currentList.RowCount;
        context.hasReadOnlyRows = false;
        if(this.gridViewVM?.gridApi) {
            var tmpRowIds: string[] = [];
            this.gridViewVM.gridApi.forEachNodeAfterFilterAndSort(n => {
                if(!n.group && n.data?.rsRow) {
                    tmpRowIds.push(n.data.rsRow.Id);
                    if(!n.data.rsRow.CanUpdate || n.data.isLocked) {
                        context.hasReadOnlyRows = true;
                    }
                }
            });
            context.rowIds = tmpRowIds;
        } else {
            context.rowIds = null;
        }
        return context;
    }

    initExportContext(): RowShare.ExportContext {
        let context = new RowShare.ExportContext();
        if(this.gridViewVM?.gridApi) {
            let tmpRowIds: string[] = [];
            if(this.gridViewVM.isFiltered) {
                this.gridViewVM.gridApi.forEachNodeAfterFilterAndSort(n => {
                    if(!n.group && n.data?.rsRow) {
                        tmpRowIds.push(n.data.rsRow.Id);
                    }
                });
                context.rowIds = tmpRowIds;
            }
        } else {
            context.rowIds = null;
        }
        return context;
    }

    get canRunRowMerge(): boolean {
        return ListTreeModule.currentListCapabilities?.rowMerge ?? false;
    }

    onRowMergeClicked() {
        if (this.hasRowMerge) {
            //here => the user has the authorization to make row merge, so i execute the same code before my modifications
            // Event $emit updated to be sur that generation context is calculated each time
            let context = this.getRowMergeGenerationContext();
            EventBus.$emit(RowShare.Event.ROWMERGE_REQUEST, context);
        } else {
            //here => the user has not authorization
            EventBus.$emit(RowShare.Event.SHOW_UPSELL_DIALOG, <RowShare.ShowUpsellDialogEventParams>{
                title: this.$i18n.t('UpsellDialog_Title').toString(),
                description: this.$i18n.t('UpsellDialog_Description_RowMerge').toString()
            });
        }
    }

    onExportAuditClicked(){
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.download = "audit";
        a.href = `/api/audit/export/${this.currentList?.Id}?f=csv`;
        a.click();
    }

    manageOwners() {
        if(!this.currentList.Owned || this.currentList.LoadedByReadOnlyMember || this.specialRowsDisplayed) {
            return;
        }
        this.showManageTableOwners = true;
    }

    onCancelManageOwners() {
        this.showManageTableOwners = false;
    }

    addToFavorites() {
        if(!this.listVM.list)
            return;

        API.UserListLink.save(this.listVM.list.Id, RowShare.UserListLinkTypes.Favorite).then(link => {
            if(link) {
                this.userListLinks = link;
            }
        });
    }

    removeFromFavorites() {
        if(!this.listVM.list)
            return;

        API.UserListLink.remove(this.listVM.list.Id, RowShare.UserListLinkTypes.Favorite).then(link => {
            if(link) {
                this.userListLinks = link;
            }
        });
    }

    async unsubscribeFromEmailNotifications() {
        if(!this.listVM.list)
            return;

        this.userListLinks =  await API.UserListLink.remove(this.listVM.list.Id, RowShare.UserListLinkTypes.AllRowsEmailNotifications);
        this.userListLinks =  await API.UserListLink.remove(this.listVM.list.Id, RowShare.UserListLinkTypes.OwnedRowsEmailNotifications);
        //added by soukayna
        this.userListLinks =  await API.UserListLink.save(this.listVM.list.Id, RowShare.UserListLinkTypes.NoEmailNotifications);
    }

    async subscribeToOwnedRowsEmailNotifications() {
        if(!this.listVM.list)
            return;

        this.userListLinks =  await API.UserListLink.remove(this.listVM.list.Id, RowShare.UserListLinkTypes.AllRowsEmailNotifications);
        this.userListLinks =  await API.UserListLink.remove(this.listVM.list.Id, RowShare.UserListLinkTypes.NoEmailNotifications);
        this.userListLinks =  await API.UserListLink.save(this.listVM.list.Id, RowShare.UserListLinkTypes.OwnedRowsEmailNotifications);
    }

    async subscribeToAllEmailNotifications() {
        if(!this.listVM.list)
            return;

        this.userListLinks =  await API.UserListLink.save(this.listVM.list.Id, RowShare.UserListLinkTypes.AllRowsEmailNotifications);
        this.userListLinks =  await API.UserListLink.remove(this.listVM.list.Id, RowShare.UserListLinkTypes.OwnedRowsEmailNotifications);
        this.userListLinks =  await API.UserListLink.remove(this.listVM.list.Id, RowShare.UserListLinkTypes.NoEmailNotifications);
    }

    hideDescription() {
        this.displayDescription = false;
    }

    onListNameKeyDown(event: KeyboardEvent) {
        if(event.keyCode === KeyCodes.DOM_VK_RETURN) {
            this.saveListName();
        }
        else if(event.keyCode === KeyCodes.DOM_VK_ESCAPE) {
            this.loadListInfo();
            this.isEditing = false;
            this.nameUpdateErrors = "";
        }
    }

    saveListName() {
        this.nameUpdateErrors = "";
        this.updatingName = true;
        API.List.save(this.currentList).then(list => {
            if(list){
                this.currentList = list;
                EventBus.$emit(RowShare.Event.LISTNAME_CHANGED);
            }
            this.isEditing = false;
        })
            .catch((exc: Error) =>{
                this.nameUpdateErrors = this.$i18n.t('ListToolBar_ErrorSavingName', [exc.message]).toString();
            })
            .finally(() => {
                this.updatingName = false;
            });
    }

    editListName() {
        if(!this.currentList.Owned || this.currentList.LoadedByReadOnlyMember || this.specialRowsDisplayed) {
            return;
        }
        this.isEditing = true;
        this.nameUpdateErrors = "";
    }

    onDeletedRowsClicked() {
        ListTreeModule.updateCurrentListDisplayedRows(RowShare.DisplayedRows.Deleted);
    }

    onArchivedRowsClicked() {
        if(this.hasArchiveRows) {
            ListTreeModule.updateCurrentListDisplayedRows(RowShare.DisplayedRows.Archived);
        }
        else {
            EventBus.$emit(RowShare.Event.SHOW_UPSELL_DIALOG, <RowShare.ShowUpsellDialogEventParams> {
                title: this.$i18n.t('UpsellDialog_Title').toString(),
                description: this.$i18n.t('UpsellDialog_Description_ArchiveRows').toString()
            });
        }
    }

    get canShowArchivedRows(): boolean {
        return !this.showReturnToTable && (this.listVM.list?.Owned ?? false);
    }

    /** Print **/
    printTable() {
        let location = this.$router.resolve({name: 'TablePrint'});
        if(location?.resolved) {
            window.open(location.href);
        }
    }

    /** Pivot **/
    pivotTable() {
        let location = this.$router.resolve({name: 'PivotTable'});
        if(location?.resolved) {
            window.open(location.href);
        }
    }

    /** search **/

    searchText: string | null = null;
    searchTextChangedTimeout: number = 0;

    onGridSearchChanged(){
        if(this.searchTextChangedTimeout)
            clearTimeout(this.searchTextChangedTimeout);

        this.searchTextChangedTimeout = setTimeout(()=>{
            EventBus.$emit(RowShare.Event.GRID_SEARCH_CHANGED, <RowShare.GridSearchChangeEventParams>{searchText: this.searchText, saveValue: true});
        }, 500);
    }

    clearSearch() {
        this.searchText = null;
        EventBus.$emit(RowShare.Event.GRID_SEARCH_CHANGED, <RowShare.GridSearchChangeEventParams>{searchText: this.searchText, saveValue: true});
    }

    onExternalSearchChanged(event: RowShare.GridSearchChangeEventParams){
        if(event.source === "external")
            this.searchText = event.searchText;
    }

    getDescriptionContent(content: string) {
        return linkifyStr(content, {target: '_blank'});
    }

    openTableSettings() {
        EventBus.$emit(RowShare.Event.SHOW_SIDEPANEL_TABLE_SETTINGS, <RowShare.showSidePanelTableSettingsEventParams> {listId: this.listVM.list?.Id});
    }
}
