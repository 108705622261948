import * as RowShare from '@/models/RowShare';

export class CatalogItem implements RowShare.CatalogItem{

    public ListId!: string;

    public CoverImageUrl!: string;

    public DisplayName!: string;

    public ShortDescription!: string;

    public LongDescription!: string;

    public BackgroundColor!: string;

    public Category!: CatalogCategory;
}

export class CatalogCategory implements RowShare.CatalogCategory{

    public Id!: string;

    public Name!: string;

    public DisplayName!: string;

    public Description!: string;

    public Color!: string;

    public IsDefault!: boolean;
}

export class TableTemplate implements RowShare.TableTemplate{
    
    public ListId!: string;
    
    public Title!: string;

    public Description!: string;

    public InCatalog!: boolean;
}