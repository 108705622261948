import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from '@/models/RowShare';

export class InviteLink {
    static load(id:string, options: IRequestOptions | null = null) : Promise<Models.InviteLink | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/invitelink/load/"+ ApiUtils.encodeSegment(id), options)
                .then(response => response.data ? response.object(Models.InviteLink) : null);
    }

    static loadForParent(id: string, options: IRequestOptions | null = null) {
        return ApiUtils.apiGet("/invitelink/loadforparent/" + ApiUtils.encodeSegment(id), options)
            .then(response => response.objects(Models.InviteLink));
    }

    static save(link: Models.InviteLink, options: IRequestOptions | null = null) {        
        return ApiUtils.apiPostJson("/invitelink/save/", link, options)
            .then(response => response.object(Models.InviteLink));
    }

    static delete(id: string, options: IRequestOptions | null = null) : Promise<boolean | null> {
        return ApiUtils.apiGet("/invitelink/delete/" + ApiUtils.encodeSegment(id), ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<boolean>());
    }

    static isValid(id: string, options: IRequestOptions | null = null) : Promise<boolean | null> {
        return ApiUtils.apiGet("/inviteLink/isvalid/" + ApiUtils.encodeSegment(id), ApiUtils.createRequestOptions({ cache: false }, options))
            .then(response => response.json<boolean>());
    }

    static createMember(id: string, options: IRequestOptions | null = null) : Promise<Models.Organization | null> {
        return ApiUtils.apiGet("/invitelink/create/" + ApiUtils.encodeSegment(id), ApiUtils.createRequestOptions({ cache: false }, options))
            .then(response => response.data ? response.object(Models.Organization) : null );
    }
}