
    import {Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import * as Models from "@/models/RowShare";
    import * as API from "@/api/Api";
    import _ from 'lodash';

    @Component({
        name:'OwnerDialog2'
    })
    export default class OwnerDialog2 extends Vue{
        
        @Prop() id!: string | null;
        @Prop() title!: string | null;
        @Prop() cancelButtonText!: string | null;
        @Prop() actionButtonText!: string | null;
        @Prop() description!: string;
        @Prop({ default: ''}) defaultOptionText!: string;
        @Prop({ default: 'md' }) size!: string | null;
        @Prop({ default: 'times' }) cancelButtonIcon!: string;
        @Prop({ default: '' }) folderId!: string;
        @Prop({ default: '' }) organizationId!: string;
        @Prop({ default: '' }) tableTitle!: string;
        @Prop() dialogState!:boolean;

        private folderOwners: Array<Models.FolderOwner> = [];
        private displayAddForm: boolean = false;
        private organizationUsers: Array<Models.Member> = [];
        private selectedUserEmail: string | null = null;

        
        //The only way to execute a method when the modal is open is by watching the boolean variable 
        @Watch('dialogState')
        async resetModal(){
            this.displayAddForm = false;
            let currentOwners = await API.Folder.loadOwners(this.folderId);
            this.folderOwners = currentOwners ? currentOwners : [];
            let currentOrganizationUsers = await API.Member.loadForParent(this.organizationId);
            this.organizationUsers = currentOrganizationUsers ? currentOrganizationUsers : [];
        }

        toggleAddForm(){
            this.displayAddForm = !this.displayAddForm;
        }

        async handleAddOwner(){
            if(this.selectedUserEmail){
                let newOwner = new Models.FolderOwner();
                newOwner.OwnerEmail = this.selectedUserEmail;
                newOwner.OwnerOrganizationId = this.organizationId;
                newOwner.FolderId = this.folderId;
                let addedOwner = await API.Folder.saveOwner(newOwner);
                if (addedOwner){
                    this.folderOwners.push(addedOwner);
                    this.selectedUserEmail = null;
                    this.toggleAddForm();
                }
            }            
        }

        isUserDisabled(user: Models.Member) : boolean{
            let alreadyOwner = _.find(this.folderOwners, { OwnerEmail: user.Email})
            return !!alreadyOwner;
        }

        async removeOwner(owner: Models.FolderOwner){
            let removedOwner = await API.Folder.deleteOwner(owner);
            if(removedOwner) {
                const index = this.folderOwners.indexOf(owner, 0);
                if (index > -1) {
                    this.folderOwners.splice(index, 1);
                }
            }
        }

        onCancel(){
            this.$emit('cancel');
        }

        textOption(item:Models.Member){
            return item.Name + ' - ' + item.Email
        }
    }

