
import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { FormValidation } from '@/views/components/FormValidation';

@Component({
    name:'InputPassword',
    components:{ ValidationProvider }
})
export default class InputPassword extends FormValidation {

    //Properties
     @Prop() inputPasswordName!:{
         type:string,
         required:true
     };
     @Prop() inputPasswordId!:{
         type:string,
         required:true};
     @Prop() inputPasswordValidationRules!:string;
     @Prop() disabledProp!:boolean;
     @Prop() value!:string;
     @Prop() inputPasswordVid!:string;
     @Prop() placeholder!: 
     {
         type: string,
         required: false
     };
     @Prop({ default: true}) outlined!: boolean;
     @Prop({ default: false}) filled!: boolean;
     @Prop() focusInput!:boolean;
     @Prop() showTooltip!:boolean;
     @Prop() tooltipText!: string;
     
    //Methods
    emitPasswordValueChanged(valueParam:String){
        this.$emit('input', valueParam);
    }

    mounted() {
        if(this.focusInput) {
            this.$nextTick(() => {
                (<HTMLElement>this.$refs.pwdInput)?.focus();
            });            
        }
    }
}
