import { ListConfiguration, Row } from "@/models/RowShare";
import ListDisplayModeVM from "./listDisplayModeVM";
import ListVM from "./listVM";
import RowVM from "./rowVM";
import ViewManager from "./viewManager";
import { CalendarApi } from "@fullcalendar/core";

export default class calendarViewVM implements ListDisplayModeVM {
    viewManager: ViewManager;
    printMode: boolean = false;

    public calendarApi: CalendarApi | undefined = undefined;
    public listVM : ListVM;

    constructor(listVM: ListVM){
        this.listVM = listVM;
        this.listVM.registerListener(this);
        this.viewManager = new ViewManager(this);
    }
    
    dispose() : void {
        this.listVM.unregisterListener(this);
    }
    
    private rowUpdateCallback: ((rowVM: RowVM) => void) | null = null;
    setRowUpdateCallBack(callback: (rowVM: RowVM) => void){
        this.rowUpdateCallback = callback;
    }
    
    onListUpdated() {}

    private rowsUpdateCallback: ((rowVMs: RowVM[]) => void) | null = null;
    setRowsUpdateCallback(callback: (rowVMs: RowVM[]) => void) {
        this.rowsUpdateCallback = callback;
    }

    onBeforeRowVMsUdated() {}

    onRowVMsUpdated() {
        if(this.listVM.rowVMs == null) {
            return;
        }
        if(this.rowsUpdateCallback) {
            this.rowsUpdateCallback(this.listVM.rowVMs);
        }
    }

    onColumnsUpdated() {}
    
    get totalRowsCount(): number  { return this.listVM.rowVMs?.length ?? 0; }
    get visibleRowsCount(): number  { return this.totalRowsCount; }
    get totalColumnsCount(): number  { return this.listVM.columns?.length ?? 0; }
    get visibleColumnsCount(): number { return this.totalColumnsCount; }
    get rowGroupsCount(): number { return 0; }
    get rowGroupsColNames(): string[] { return []; }
    get isFiltered(): boolean { return false; }
    get isSorted(): boolean { return false; }
    get cellSelected(): boolean { return false; }
    displayedRowIds: string[]| null = null;

    /** Sort, filter, hide... **/
    public getCurrentViewConfig() : ListConfiguration | undefined { return undefined; }
    public getHiddenColumnsIds() : string[] | undefined { return undefined; }
    public getCurrentFilters(): any {}
    public getCurrentSort(): any[] | undefined { return undefined; }
 
    public getRowGroupColumnsIds(): string[] | undefined { return undefined; }
    public getValueColumns(): any[] | undefined  { return undefined; }
    public getVisibleToolPanelId(): string | undefined { return undefined; }
    public getCollapsedColumnGroups(): string[] | undefined { return undefined }

    public applyFilters(viewConfig: ListConfiguration) : void {}
    public applySort(viewConfig: ListConfiguration) : void {}
    public applyHiddenColumns(viewConfig: ListConfiguration) : void {}
    public applyRowGroupColumns(viewConfig: ListConfiguration) : void {}
    public applyValueColumns(viewConfig: ListConfiguration) : void {};
    public applyVisibleToolPanel(viewConfig: ListConfiguration) : void {};
    public applyCollapsedColumnGroups(viewConfig: ListConfiguration): void {}
    
    public clearHiddenColumns() : void {}
    public clearSortOrder() : void {}
    public clearFilters() : void {}
    public clearRowGroups() : void {}
    public clearValueColumns(): void {}
    public clearVisibleToolPanel(): void {}
    public clearCollapsedColumnGroups(): void {}

    /** Specific dispaly methods **/

    public onRowVMUpdated(rowVM: RowVM) : void {
        if(this.rowUpdateCallback != null) {
            this.rowUpdateCallback(rowVM);
        }
    }
    
    public refreshCell(rowVM: RowVM) : void {
        if(this.rowUpdateCallback)
            this.rowUpdateCallback(rowVM);
    }
    
    public async insertClonedRowInUI(clonedRow: Row, insertAtIdx: number | undefined, refresh: boolean | undefined) :  Promise<void>{}
    public getRowNodeIndex(id: string): number { return -1;}
    public scrollToIndex(index: number): void {}
    public addRowsInUI(newRows: Row[]): void {};
    public updateRowsInUI(updatedRows: Row[]):void {};
    public removeRowsInUI(rowVMs: RowVM[]) : void {}
    public deselectAllRows() : void {}
}