
import {Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name:'Toaster'
})
export default class Toaster extends Vue{

    @Prop({default:'false'}) showToaster!:boolean;
    @Prop() toasterText!:string;
    @Prop() toasterColor!:string;
    @Prop() toasterTimeOut!: number;
    @Prop() toasterIcon!:string;

    constructor(){
        super();
    }

    closeToaster(){
        this.$emit("closeToasterEvent");
    }

}
