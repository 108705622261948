
import { Utils } from '@/utils/Utilities';
import { ICellEditorParams } from 'ag-grid-community';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({name: "TwoStatesBooleanEditor"})
export default class TwoStatesBooleanEditor extends Vue{
    params!: ICellEditorParams;
    cellValue: boolean = false;

    mounted(){
        var startEditWithKeyboard = !!this.params.eventKey;
        
        let tabRef = (<any>this.params.context)['tabbedIntoCellEditor'];
        let thisTabRef = this.params.data.rsRow.Id + '|' + this.params.colDef.colId;
            
        // reset until next navigation, otherwise tabing, closing, and dbl-clicking would detect a tab instead of dblclick
        (<any>this.params.context)['tabbedIntoCellEditor'] = null;

        if(tabRef === thisTabRef)
            startEditWithKeyboard = true;

        this.refreshDisplay();

        if(startEditWithKeyboard) {
            Vue.nextTick(() => {
                let rootElt = (<any>this.$refs.ckb).$el as HTMLElement;
                var inputElt = rootElt.getElementsByTagName("input");
                if(inputElt && inputElt.length > 0)
                    inputElt[0].focus();
            });
        } else {
            this.cellValue = !this.cellValue;
        }
    }

    refreshDisplay() {
        this.cellValue = Utils.parseBoolean(this.params.value, false);
    }

    getValue(){
        return this.cellValue;
    }
}
