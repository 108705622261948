
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as RowShare from '@/models/RowShare';
import FileVM from '@/viewModels/Table/FileVM';
import { Utils } from '@/utils/Utilities';
import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
import { ColumnStrongType } from '@/models/Column';
import RowVM from '@/viewModels/Table/rowVM';

@Component({name: "BlobField", components: { ConfirmationDialog }})
export default class BlobField extends Vue{
    @Prop() value !: FileVM | null; 
    @Prop() column !: RowShare.Column;
    @Prop() rowVM!: RowVM;

    selectedFile: FileVM | null = null;
    removeImageConfirmation: boolean = false;

    get hasData(){
        return this.value && this.value.FileName;
    }

    get fileName() {
        return this.value?.FileName ?? "";
    }

    get fileSize() {
        if(this.value?.Size){
            return Utils.humanFileSize(this.value.Size);
        }
        return "";
    }

    get downloadUrl() {
        if(this.value?.AbsoluteFileUrl){
            let url = new URL(this.value?.AbsoluteFileUrl);
            if(url) {
                return url.pathname;
            }
        }
        return "";
    }

    get thumbnailUrl() {
        if(this.downloadUrl) {
            return  this.downloadUrl + "?_ssb_img=S" + encodeURIComponent(';w:310');
        }
        return "";
    }

    get canRemoveImage() {
        if(this.column.isMandatory || !this.canUpdateFile || this.column.isChildColumn) {
            return false;
        }
        return true;
    }

    get canUpdateFile(){
        if(!this.rowVM.rsRow.CanUpdate || this.column.IsReadOnly || this.column.isChildColumn || this.rowVM.isLocked)
            return false;
        
        return true;
    }

    selectFile() {
        this.focusButton();
        let imputElt = this.$refs.inputFile as HTMLInputElement;
        if(imputElt) {
            imputElt.click();
        }
    }
    
    focusButton(){
        let element = (<any>this.$refs.openPickerBtn).$el as HTMLButtonElement;
        if(element){
            this.$nextTick(() => {
                element.focus(); 
            });
        }
    }

    onFileUpdated(event: any) {
        let newFile = new FileVM();
        newFile.FileName = event.target.files[0].name;
        newFile.File = event.target.files[0];
        newFile.Size = event.target.files[0].size;
        this.selectedFile = newFile;
        this.$emit('input', this.selectedFile)
    }

    removeImage() {
        this.selectedFile = new FileVM();
        this.$emit('input', this.selectedFile)
        this.removeImageConfirmation = false;
    }

    cancelled() {
        this.removeImageConfirmation = false;
    }

    confirmRemoveImage() {
        this.removeImageConfirmation = true;
    }

    get isImage(): boolean {
        if(this.value?.ContentType) {
            return this.value.ContentType.startsWith('image/');
        }
        return false;
    }

    get authorizedFiles(): string {
        if(this.column.Type == ColumnStrongType.Image){
            return "image/*";
        }
        else{
            return "*/*";
        }
    }

}
