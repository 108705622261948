
    import { Component } from "vue-property-decorator";
    import { UserModule } from '@/store/UserStore'

    import PageLoadSpinner from "@/views/layouts/LayoutParts/PageLoadSpinner.vue";

    import * as API from "@/api/Api";

    import { ReturnUrlHandler } from "@/views/ReturnUrl";
    
    @Component({
        name: "LoginMS",
        components: { PageLoadSpinner }
    })
    export default class LoginMS extends ReturnUrlHandler {
        constructor() {
            super();
        }

        async created(){
            if(!UserModule.CurrentUser.IsAnonymous)
                this.redirectToLanding();
        }

        async mounted(){     
            let returnUrl = await API.SocialProvider.signup("AzureAD", this.returnUrl);
            window.location.href = returnUrl ? returnUrl : '/';
        }

        redirectToLanding(){
            var defaultRedirectRoute = this.$router.resolve({ name: 'MyTables' });
            this.redirectToReturnUrlOrDefault(defaultRedirectRoute.href);
        }
    }
