import * as RowShare from '@/models/RowShare';
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import { ColumnStrongType } from '@/models/Column';
import { DateTime } from '@/utils/Date';
import moment from 'moment';
import { Utils } from '@/utils/Utilities';

export default class DateTimeColumnProxy extends BaseColumnProxy {
    parseInitialTypedValue(rsColumn: RowShare.Column, rowVM: RowVM): any {
        // if(rsColumn.Type == ColumnStrongType.CreationDate) {
        //     return rowVM.rsRow.CreatedDateUtc ?  new Date(rowVM.rsRow.CreatedDateUtc) : undefined;
        // }
        // else if(rsColumn.Type == ColumnStrongType.LastWriteDate) {
        //     return rowVM.rsRow.ModifiedDateUtc ? new Date(rowVM.rsRow.ModifiedDateUtc) : undefined;
        // }
        // else 
        if(rsColumn.Type == ColumnStrongType.StatusChangeDateTime) {
            return rowVM.rsRow.StatusDateUtc ? new Date(rowVM.rsRow.StatusDateUtc) : undefined;
        }
        return DateTime.parseDateTime(rowVM.rsRow.Values[rsColumn.Name], null);
    }

    getAPIVal(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        if(!val)
            return "";

        return moment.utc([
            val.getFullYear(),
            val.getMonth(),
            val.getDate(),
            val.getHours(),
            val.getMinutes(),
            0,
            0
        ]).toDate().toJSON();
    }

    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        if(val) {
            if(this.isDateTimeValue(rsColumn)){
                let options: any = { dateStyle: "short", timeStyle: val.getSeconds() > 0 ? "medium" : "short"};
                return val.toLocaleString(navigator.language, options);
            }
            return val.toLocaleDateString(navigator.language);
        }
        return "";
    }

    getFormattedHtmlTooltip(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        if(val) {
            let options: any = {dateStyle: "full"};
            if(this.isDateTimeValue(rsColumn)) {
                options.timeStyle = val.getSeconds() > 0 ? "medium" : "short";
                return val.toLocaleString(navigator.language, options);
            }
            return val.toLocaleDateString(navigator.language, options);
        }
        return "";
    }

    getCellIconClasses(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        if(!rsColumn || rsColumn.Type != ColumnStrongType.Reminder || val == null)
            return null;
        if (!(val instanceof Date)) {
            return null;
        }
        const reminderDate : Date = moment.utc([
            val.getUTCFullYear(),
            val.getUTCMonth(),
            val.getUTCDate(),
            val.getUTCHours(),
            val.getUTCMinutes(),
            0,
            0
        ]).toDate();

        const currentDate: Date = new Date();

        if (currentDate > reminderDate){
            return ['fa', 'fa-bell-exclamation', 'error--text']
        }

        return ['fa', 'fa-bell'];
    }

    isDateTimeValue(rsColumn: RowShare.Column): boolean {
        if(rsColumn) {
            return rsColumn.isDateTimeFormat;
        }
        return false;
    }

    areEquals(val1: any, val2: any) {
        if(val1 === null || val1 === undefined || val2 === null || val2 === undefined)
            return val1 === val2;

        if(!Utils.isDate(val1) || !Utils.isDate(val2)) {
            return false;
        }


        return val1.getTime() === val2.getTime();
    }

    /** Filters **/

    getCustomFilter(rsCol: RowShare.Column) : null | string{
        return "agDateColumnFilter";
    }

    getCustomFilterParams(rsCol: RowShare.Column) : null | any {
        return {
            inRangeInclusive: true,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'lessThanOrEqual',
                'greaterThan',
                'greaterThanOrEqual',
                'inRange',
                {
                    displayKey: 'blanks',
                    displayName: 'Blanks',
                    predicate: function (filterValue: any, cellValue: any) {
                        return !cellValue || cellValue == "";
                    },
                    numberOfInputs: 0,
                },
                {
                    displayKey: 'notblanks',
                    displayName: 'Not Blanks',
                    predicate: function (filterValue: any, cellValue: any) {
                        return cellValue && cellValue != "";
                    },
                    numberOfInputs: 0,
                },
            ],
            comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
                let dateCellValue = DateTime.parseDateTime(cellValue, null);

                if(dateCellValue == null) {
                    return 0;
                }

                let dateToCompare = new Date(dateCellValue.getFullYear(), dateCellValue.getMonth(), dateCellValue.getDate());
                if(dateToCompare < filterLocalDateAtMidnight) {
                    return -1;
                }
                else if(dateToCompare > filterLocalDateAtMidnight) {
                    return 1;
                }
                return 0;
            }
        }
    }

    getQuickFilterText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        return this.getFormattedText(rsColumn, rowVM, val) + " " + this.getFormattedHtmlTooltip(rsColumn, rowVM, val);
    }
}