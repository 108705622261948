
import { Component, Prop, Vue } from "vue-property-decorator";
import { Location as RouterLocation } from "vue-router";
import * as API from "@/api/Api";
import * as RowShare from "@/models/RowShare";

@Component
export default class FolderThumbnail extends Vue {
    @Prop() folder!: RowShare.ListTreeFolder;
    @Prop() organizationId!: string | null;
    @Prop() listDragged!:RowShare.ListThumbnailInfo;

    get badgeTooltipsContent() {
        return this.$i18n.t('MyTables_TotalCount', [this.folder.TotalListCount]).toString();
    }

    getContentUrl() {
        return <RouterLocation>{
            name: "MyTables",
            params: {
                organizationId: this.organizationId,
                folderId: this.folder.Id
            }
        };
    }

    enteredElement(){
        let elem = document.getElementById(this.folder.Id);
        if(this.listDragged.Owned && this.folder.CanCurrentUserCreateList && !this.folder.IsInRecycleBin && elem){
            elem.classList.add('drop-allowed');
        }
        if((!this.listDragged.Owned || !this.folder.CanCurrentUserCreateList) && !this.folder.IsInRecycleBin && elem){
            elem.classList.add('drop-not-allowed');
        }
    }

    leftElement(){
        let elem = document.getElementById(this.folder.Id);
        if(elem){
            elem.classList.remove('drop-allowed');
            elem.classList.remove('drop-not-allowed');
        }
    }
}
