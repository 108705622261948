export class SubscriptionLimit {
    public LimitType!: SubscriptionLimitType;
    public MaxValue!: number;
    public CurrentValue!: number;
    public Name!: string;
    public ObjectId!: string;
    public IsInRecycleBin!: boolean;
    public Exceeded!: boolean;
}

export enum SubscriptionLimitType
{
    User,
    Table,
    Row,
    Storage,
    ReadOnlyUser
}