
import {Component, Prop, Vue } from 'vue-property-decorator';

import * as API from '@/api/Api';
import * as RowShare from '@/models/RowShare';
import moment from 'moment';
import { EventBus } from '@/modules/EventBus';

@Component({ name:'JobGrid', components:{}})
export default class MyDocuments extends Vue {

    @Prop() private showListLink!: boolean;
    @Prop() private showUser!: boolean;
    @Prop() private jobs!: Array<RowShare.Job>;

    private fields!:any;

    constructor(){
        super();
        this.fields = [];
    }

    created(){
        EventBus.$on(RowShare.Event.LOCALE_CHANGED, async() => {
            await this.onLocaleChanged();
        });
    }

    mounted(){
        this.setTableHeader();
        this.onLocaleChanged();
    }

    beforeDestroy(){
    }

    //Sets the array of values for the table header. The value is the same name as the properties name that is returned
    //from the server. The label is the text to be shown. 
    setTableHeader(){
        this.fields = [];

        this.fields.push({value: 'ReportDisplayName', text: this.$i18n.t('MyDocuments_HeaderTemplate').toString()});

        if(this.showListLink)
            this.fields.push({value: 'ListDisplayName', text: this.$i18n.t('MyDocuments_HeaderTable').toString()});
        
        if(this.showUser)
            this.fields.push({value: 'User', text: this.$i18n.t('MyDocuments_HeaderUser').toString()});
            
        this.fields.push({value: 'CreationDateUtc', text: this.$i18n.t('MyDocuments_HeaderCreatedOn').toString()});
        this.fields.push({value: 'Status', text:this.$i18n.t('MyDocuments_HeaderStatus').toString()});
        this.fields.push({value: 'RowCount', text:this.$i18n.t('MyDocuments_HeaderRowCount').toString()});
        this.fields.push({value: 'Download', text:'', sortable: false});
        this.fields.push({value: 'Delete', text:'', sortable: false});
    }

    onLocaleChanged(){
        this.setTableHeader();
        moment.locale(this.$i18n.locale); //Changes the language for the dates(in this case for the "Created" column).
    }

    confirmDeleteJob(id:string) {
        var evtArgs = new RowShare.ConfirmationRequiredEventParams();
        evtArgs.title = this.$i18n.t("MyDocuments_ConfirmDeleteJob_Title").toString();
        evtArgs.description1 = this.$i18n.t("MyDocuments_ConfirmDeleteJob_Description").toString();
        evtArgs.cancelButtonText = this.$i18n.t("MyDocuments_ConfirmDeleteJob_CancelButton").toString();
        evtArgs.actionButtonText = this.$i18n.t("MyDocuments_ConfirmDeleteJob_ConfirmButton").toString();
        evtArgs.actionButtonIcon = "trash-alt";
        evtArgs.actionButtonColor = "error";
        evtArgs.onConfirmation = async () => { this.deleteJob(id); };
        EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, evtArgs);
    }

    //When an item is deleted from the list, it is removed by splicing the array. This way, it's not necesarry 
    //to call the API again to obtain all the elements.
    deleteJob(Id:string){
        API.Job.delete(Id).then(result=>{
            if(result){
                if(this.jobs!=null) {
                    let element= this.jobs.find(elem => elem.Id == Id);
                    if(element) {
                        const index = this.jobs.indexOf(element);
                        this.jobs.splice(index, 1);
                    }
                };
            }
        });
    }

    //Returns the formatted date on the locale time.
    getFormatedDate(createdDate: string){
        return moment(String(createdDate)).format('llll')
    }

    //Returns the difference of time between today and the parameter.
    getRelativeDate(createdDate:string){       
        return moment().to(moment(String(createdDate)));
    }
    
    getStatusTitle(job: RowShare.Job):string{
        switch(job.Status){
            case RowShare.JobStatus.Error:
            case RowShare.JobStatus.Retrying:
            case RowShare.JobStatus.SourceDeleted:
                return job.OutputError;
            default:
                return moment(job.StatusChangeDateUtc).format('llll');
            
        }
    }

    getStatusIcon(job: RowShare.Job): string{
        switch (job.Status) {
            case RowShare.JobStatus.Unspecified:
            case RowShare.JobStatus.NotStarted:
                return "fa-info-circle";
            case RowShare.JobStatus.Processing:
                return "fa-clock";
            case RowShare.JobStatus.Finished:
                return "far fa-check-circle";
            case RowShare.JobStatus.Error:
            case RowShare.JobStatus.Retrying:
            case RowShare.JobStatus.SourceDeleted:
                return "far fa-times-circle";                
            default:
                return "";
        }
    }

    getStatusColor(job: RowShare.Job): string{
        switch (job.Status) {
            case RowShare.JobStatus.Unspecified:
            case RowShare.JobStatus.Retrying:
            case RowShare.JobStatus.Processing:
                return 'info';
            case RowShare.JobStatus.Finished:
                return 'success';
            case RowShare.JobStatus.NotStarted:
                return 'warning';
            case RowShare.JobStatus.Error:
            case RowShare.JobStatus.SourceDeleted:
                return 'error';
            default:
                return '';
        }
    }

}
