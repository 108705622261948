
import * as RowShare from "@/models/RowShare";
import * as Api from '@/api/Api';
import { ListThumbnailInfo } from '@/models/RowShare';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({name: 'ColumnSettingsLookupListEditor'})
export default class ColumnSettingsLookupListEditor extends Vue {
    @Prop() currentListId!: string;
    @Prop() lookupListId!: string;
    @Prop() lookupListColumnName!: string;
    selectedColumnName: string = "";
    loadingSiblings: boolean = true;
    loadingColumns: boolean = false;
    linkedColumns: RowShare.Column[] | null = [];
    siblings: ListThumbnailInfo[] | null = [];

    mounted() {
        if(!this.currentListId) {
            return;
        }
        this.init();
    }

    @Watch('lookupListId')
    @Watch('lookupListColumnName')
    init() {
        this.loadingSiblings = true;
        Api.ListTree.loadSiblings(this.currentListId).then(lists => {
            this.siblings = lists?.filter(l => !l.IsInRecycleBin) ?? [];
            this.internalSelectedLinkedList = this.lookupListId ?? null;
            this.loadColumnsForLinkedList(() => {
                this.selectedColumnName = this.lookupListColumnName;
            });
            this.loadingSiblings = false;
        });
    }

    internalSelectedLinkedList: string | null = null;

    get selectedLinkedList() : string  | null {
        return this.internalSelectedLinkedList;
    }

    set selectedLinkedList(val: string  | null) {
        this.internalSelectedLinkedList = val;

        if(!val)
            return;

        this.loadColumnsForLinkedList(() => {
            if(this.linkedColumns && this.linkedColumns.length > 0) {
                this.selectedColumnName = this.linkedColumns[0].Name;
                this.onColumnNameChanged();
            }
        });
    }

    private loadColumnsForLinkedList(callback: () => any){
        this.loadingColumns = true;
        this.linkedColumns = [];
        if(this.selectedLinkedList === null)
            return;

        Api.Column.loadForList(this.selectedLinkedList).then(cols => {
            this.linkedColumns = cols ?? [];
            callback();
            this.loadingColumns = false;                  
        });
    }

    onColumnNameChanged() {
        if(this.selectedColumnName) {
            this.$emit('change', {linkedListId: this.selectedLinkedList, linkedColumnName: this.selectedColumnName});
        }
    }
 }
 