import { render, staticRenderFns } from "./Unsubscription.vue?vue&type=template&id=10589891&scoped=true&"
import script from "./Unsubscription.vue?vue&type=script&lang=ts&"
export * from "./Unsubscription.vue?vue&type=script&lang=ts&"
import style0 from "./Unsubscription.vue?vue&type=style&index=0&id=10589891&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10589891",
  null
  
)

export default component.exports