import { SecurityPrincipal } from "./SecurityPrincipal";
import { OwnerType } from "./User";

export class DropdownMember {
    constructor(s: SecurityPrincipal | Partial<DropdownMember>) {
        if (s instanceof SecurityPrincipal) {
            this.securityPrincipal = s;
            this.value = s.id;
            this.text = s.displayName;

            this.searchBy = s.displayName;
            if (s.ownerType == OwnerType.Member)
                this.searchBy += s.MemberEmail;
        } else {
            Object.assign(this, s);
        }

    }

    value!: string;
    text!: string;
    searchBy!: string;
    loading!: boolean;
    securityPrincipal: SecurityPrincipal | null = null;
    isNew: boolean = false;
    editable = false;

    get hasEmail(){
        return this.securityPrincipal?.ownerType  == OwnerType.Member;
    }

    get email(){
        return this.securityPrincipal?.MemberEmail;
    }

    static isNewMember(member: any) {
        return member.isNew;
    }
}
