import { UserListLink } from "./UserListLink";

import { UserListLinkTypes } from "./UserListLink";

export class UserNotificationLink {
    public ListId!: string;
    public ListDisplayName!: string;
    public Types!: UserListLinkTypes;
}

export class UserNotificationUpdate {
    public ListId!: string;
    public Types!: UserListLinkTypes;
}

