
import {Vue, Component, Prop } from 'vue-property-decorator';
import { KeyCodes } from '@/utils/KeyCodes';
import { ICellEditorParams } from 'ag-grid-community';
import RichTextField from '@/views/Table/cellsComponents/RichText/RichTextField.vue'

@Component({name: "RichTextEditor", components: { RichTextField }})
export default class RichTextEditor extends Vue {
    params!: ICellEditorParams;

    editorContent: string | null = null;
    minimumWidth: number = 400;

    get cardWidth() {
        return Math.max(this.minimumWidth, this.params.column.getActualWidth());
    }

    isPopup() {
        return true;
    }

    getPopupPosition() {
        return "over";
    }

    created() {
        var val = '';
        if (this.params.eventKey === "Delete") // suppr 
            val = '';
        else if (this.params.eventKey && this.params.eventKey.length == 1)
            val = this.params.value + this.params.eventKey;
        else
            val = this.params.value;

        this.editorContent = val;        
        this.$nextTick(() => {
            (<any>this.$refs.richtextfield).init(); 
        });  
    }

    getValue() {
        return this.editorContent;
    }

    cancel() {
        this.params.api?.stopEditing(true);
        this.params.api?.setFocusedCell(this.params.rowIndex, this.params.column);
    }

    validate() {
        this.params.api?.stopEditing();
        this.params.api?.setFocusedCell(this.params.rowIndex, this.params.column);
    }

    onKeydown(event: KeyboardEvent) {
        if((event.ctrlKey || event.metaKey) && event.keyCode == KeyCodes.DOM_VK_RETURN) {
            this.validate();
            event.stopImmediatePropagation();
        }
    }

}
