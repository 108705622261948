
import { Vue, Component } from 'vue-property-decorator';
import { ReturnUrlHandler } from '@/views//ReturnUrl';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import * as microsoftTeams from '@microsoft/teams-js';
import * as API from '@/api/Api';
import { Utils } from '@/utils/Utilities';
import { ApplicationModule } from '@/store/ApplicationStore';
import { UserModule } from '@/store/UserStore';

@Component({name: 'MsTeamsLogin', components:{ PageLoadSpinner }})
export default class MsTeamsLogin extends ReturnUrlHandler{
    connecting: boolean = true;
    hasToLogin: boolean = false;
    isDarkTeams: boolean = false;
    errorMessage: string = "";

    constructor() {
        super();
    }

    mounted(){
        ApplicationModule.updateIsMsTeamsApp(true);
        microsoftTeams.app.initialize();
        let _context: microsoftTeams.app.Context;
        microsoftTeams.app.getContext().then(context => {
            _context = context;
        });
        microsoftTeams.authentication.getAuthToken()
            .then((token: string) => {
                API.User.getUserEmailFromToken({IdToken: token, Tid: _context.user?.tenant?.id, TeamsName: _context.team?.displayName})
                    .then((email) => {
                        if(!Utils.isNullOrWhiteSpace(email)) {
                            API.User.load()
                                .then(user => {
                                    if(!user || user.IsAnonymous || user.Email.toLowerCase() != email?.toLowerCase()) {
                                        this.hasToLogin = true;
                                    }
                                    else {
                                        this.redirectToReturnUrlOrDefault('/404');
                                    }
                                })
                                .catch((err) => this.errorMessage = this.$i18n.t('MsTeamsLogin_Error').toString())
                                .finally(() => this.connecting = false);
                        }
                    });
            })
            .catch((reason: any) => { 
                this.errorMessage = this.$i18n.t('MsTeamsLogin_Error',[reason]).toString();
            });
    }

    login() {
        let _context: microsoftTeams.app.Context;
        microsoftTeams.app.getContext().then( (context) => _context = context);
        microsoftTeams.authentication.getAuthToken()
            .then((token: string) => {
                if(!Utils.isNullOrWhiteSpace(token)) {
                    this.hasToLogin = false;
                    this.connecting = true;
                    API.User.connectUserByIdToken({IdToken: token, Tid: _context.user?.tenant?.id, TeamsName: _context.team?.displayName})
                        .then(valid => {
                            if(valid) {
                                UserModule.refreshUser().then(() =>{
                                    this.redirectToReturnUrlOrDefault(this.$route.fullPath);
                                });
                            }
                        })
                        .catch((error: any) => {
                            this.errorMessage = error._message;
                        })
                        .finally(() => this.connecting = false);
                }
            })
            .catch((reason: any) => { 
                this.errorMessage = this.$i18n.t('MsTeamsLogin_Error',[reason]).toString();
            });
    }
}
