
import { Component, Prop } from "vue-property-decorator";
import * as API from "@/api/Api";
import { ReturnUrlHandler } from "@/views/ReturnUrl";

@Component({ name: "MicrosoftLoginButton" })
export default class MicrosoftLoginButton extends ReturnUrlHandler {

    @Prop() inviteLinkId!: string;
    @Prop() isSignUp!: boolean;

    loginOrSignUp() {
        if(this.isSignUp) {
            this.signup();
        }
        else {
            this.login();
        }
    }

    async login() {
        let returnUrl = await API.SocialProvider.login('AzureAD', this.returnUrl);
        window.location.href = returnUrl ? returnUrl : '/';
    }

    async signup() {
        let returnUrl = await API.SocialProvider.signup('AzureAD', this.returnUrl, this.inviteLinkId)
        window.location.href = returnUrl ? returnUrl : '/';
    }
}
