
import { IToolPanelParams } from 'ag-grid-community';
import {Vue, Component, Prop } from 'vue-property-decorator';
import ChartRenderer from '@/views/charts/chartRenderer.vue';
import * as API from '@/api/Api';
import { EventBus } from '@/modules/EventBus';
import { Chart, Event } from '@/models/RowShare';
import { ListModule } from '@/store/ListStore';
import draggable from 'vuedraggable';
import * as agGrid from 'ag-grid-community';
import ListVM from '@/viewModels/Table/listVM';

@Component({name: 'ChartsToolPanel', components: { ChartRenderer, draggable }})
export default class ChartsToolPanel extends Vue {
    params!: IToolPanelParams;

    privateCharts: Chart[] = [];
    sharedCharts: Chart[] = [];
    loadingCharts: boolean = false;
    rowIds: string[] | null = null;
    openedPanels: number[] = [];

    created() {
        EventBus.$on(Event.REFRESH_CHARTS, () => this.refreshCharts());
    }

    destroy() {
        EventBus.$off(Event.REFRESH_CHARTS, () => this.refreshCharts());
    }

    async mounted() {
        this.refreshCharts();
    }

    refreshCharts() {
        this.loadingCharts = true;
        this.privateCharts = [];
        this.sharedCharts = [];
        if(this.currentList) {
            API.Chart.loadForParent(this.currentList.Id, {cache: false})
                     .then((charts) => {
                        if(charts) {
                            this.privateCharts = charts.filter(c => !c.Shared);
                            this.sharedCharts = charts.filter(c => c.Shared);
                        }
                        this.loadingCharts = false;
                        this.initOpenedPanels();
                        this.refreshtoolPanelTabLabel();
                     });
        }
    }

    addNewChart() {
        EventBus.$emit(Event.ADD_NEW_CHART);
    }

    get currentList() {
        return ListModule.currentList;
    }

    public refreshtoolPanelTabLabel() {
        let elements = document.getElementsByClassName('ag-icon-chart');
        if(elements.length == 1) {
            elements[0].classList.remove('has-charts');
        }
        let count = this.privateCharts.length + this.sharedCharts.length;
        if(count > 0 && elements.length == 1) {
            elements[0].classList.add('has-charts');
        }
    }

    public initOpenedPanels() {
        if(this.openedPanels.length > 0) {
            return;
        }
        if((this.sharedCharts?.length ?? 0) > 0) {
            this.openedPanels = [0];
        }
        else if ((this.privateCharts?.length ?? 0) > 0) {
            this.openedPanels = [1];
        }
    }
    
    public onDataToChartChanged(listVM: ListVM){
        var rowIds : string[] = [];
        this.params.api.forEachNodeAfterFilter((node: agGrid.IRowNode) => {
            if(!node.group && node.data?.rsRow)
                rowIds.push(node.data.rsRow.Id);
        });

        if(rowIds.length > 0 && (!listVM.rowVMs || rowIds.length < listVM.rowVMs.length))
            this.rowIds = rowIds;
        else
            this.rowIds = null;
    }

    public onChartsOrderChanged(e: any){
        var newSharedOrder = this.sharedCharts.map(c => c.Id);
        var newPrivateOrder = this.privateCharts.map(c => c.Id);
        API.Chart.saveOrder(newSharedOrder.concat(newPrivateOrder));
    }

    get hasNoCharts(): boolean {
        return this.sharedCharts.length == 0 && this.privateCharts.length == 0;
    }
}
