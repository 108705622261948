
import { ICellEditorParams } from 'ag-grid-community';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ColumnVM from '@/viewModels/Table/columnVM';


@Component({name: "TextEditor"})
export default class TextEditor extends Vue{
    params!: ICellEditorParams;
    cellValue: string = "";
    maxLength: number|null = null;    

    mounted(){
        this.refreshDisplay();
        this.$nextTick(() => {
            (<HTMLElement>this.$refs.textInput)?.focus();
        });
    }
    
    refreshDisplay() {
        let column = <ColumnVM>this.params.colDef;
        if(column && column.rsColumn && column.rsColumn.MaxLength > 0) {
            this.maxLength = column.rsColumn.MaxLength;
        }

        if (this.params.eventKey === "Delete") // suppr 
            this.cellValue = '';
        else if (this.params.eventKey?.length === 1)
            this.cellValue = this.params.value + this.params.eventKey;
        else
            this.cellValue = this.params.value;
    }

    getValue(){
        return this.cellValue;
    }
}
