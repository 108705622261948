
import * as RowShare from "@/models/RowShare";
import * as Api from '@/api/Api';
import { GlobalNotificationEventParams, ListThumbnailInfo } from '@/models/RowShare';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventBus } from "@/modules/EventBus";
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

@Component({name: 'ColumnSettingsRelatedColumnsEditor'})
export default class ColumnSettingsRelatedColumnsEditor extends Vue {
    @Prop() currentListId!: string;
    @Prop() relatedListId!: string;
    @Prop() currentColumnId!: string;
    @Prop() disableColumnSelection!: boolean;
    @Prop() helpText!: string;

    selectedRelatedListColumnIndex: number = -1;
    selectedRelatedColumnIndex: number = -1;
    loadingSiblings: boolean = true;
    loadingColumns: boolean = false;
    relatedListColumns: RowShare.Column[] | null = [];
    relatedColumns: RowShare.Column[] | null = [];
    siblings: ListThumbnailInfo[] | null = [];
    removingRelatedColumn: boolean = false;
    addingRelatedColumn: boolean = false;
    originalRelatedListColumns: RowShare.Column[] | null = [];

    mounted() {
        if(!this.currentListId)
            return;

        this.loadingSiblings = true;
        Api.ListTree.loadSiblings(this.currentListId).then(lists => {
            this.siblings = lists?.filter(l => !l.IsInRecycleBin && l.Id != this.currentListId) ?? [];
            this.internalSelectedRelatedListId = this.relatedListId ?? null;
            this.loadRelatedColumns().then(() => this.loadColumnsForRelatedList());
            this.loadingSiblings = false;
        });
    }

    @Watch('disableColumnSelection')
    initFirstRelatedColumn() {
        if(!this.disableColumnSelection && (this.relatedColumns?.length ?? 0) == 0) {
            setTimeout(() => {
                if(this.relatedListColumns) {
                    let mainColIndex = this.relatedListColumns.findIndex(c => c.IsMainColumn);
                    this.selectedRelatedListColumnIndex = mainColIndex > -1 ? mainColIndex : 0;
                    this.addRelatedColumn();
                }
            }, 0);
        }
    }

    internalSelectedRelatedListId: string | null = null;

    get selectedRelatedListId() : string  | null {
        return this.internalSelectedRelatedListId;
    }

    set selectedRelatedListId(val: string  | null) {
        this.internalSelectedRelatedListId = val;

        if(!val)
            return;
        Api.Column.clearRelated(this.currentColumnId)
            .then(res => {
                this.loadRelatedColumns().then(() => {
                     this.loadColumnsForRelatedList()
                        .then(() => this.onRelatedListChanged());
                });                
            });
    }

    onRelatedListChanged() {
        if(this.selectedRelatedListId) {
            this.$emit('change', {RelatedListId: this.selectedRelatedListId});
        }
    }

    get canRemoveRelatedColumn() {
        return ((this.selectedRelatedColumnIndex ?? -1) >= 0 && (this.relatedColumns?.length ?? 0) > 1);
    }

    get relatedListColumnSelected() {
        return (this.selectedRelatedListColumnIndex ?? -1) >= 0;
    }

    private notifyColumnSaved() {
        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{ message: this.$i18n.t('ColumnSettings_SaveSuccess'), 
                                                                                                            type: RowShare.NotificationType.success, 
                                                                                                            autoHide: true, autoHideAfterMs: 4000,
                                                                                                            duplicateId: 'ColumnSaveSuccess'});
        this.$emit('columnsChanged');
    }

    private async loadColumnsForRelatedList(){
        this.relatedListColumns = [];
        this.selectedRelatedListColumnIndex = -1;
        this.selectedRelatedColumnIndex = -1;
        if(this.selectedRelatedListId === null)
            return;

        this.originalRelatedListColumns = (await Api.Column.loadForList(this.selectedRelatedListId))?.filter(c => !c.isTechnical && c.Type != RowShare.ColumnStrongType.Comment && c.Type != RowShare.ColumnStrongType.Relation) ?? [];
        this.relatedListColumns = this.originalRelatedListColumns.filter(c => this.relatedColumns?.findIndex(rc => rc.ParentColumnId == c.Id) == -1);
    }

    private async loadRelatedColumns() {
        this.relatedColumns = (await Api.Column.loadRelated(this.currentColumnId)) ?? [];
    }

    addRelatedColumn() {
        if(this.selectedRelatedListColumnIndex != null && this.relatedListColumns && this.selectedRelatedListColumnIndex >= 0 
            && this.selectedRelatedListColumnIndex < this.relatedListColumns.length) {
            this.addingRelatedColumn = true;
            var parentCol = this.relatedListColumns[this.selectedRelatedListColumnIndex];
            if(parentCol) {
                var childCol = new RowShare.Column();
                childCol.Type = RowShare.ColumnStrongType.Child;
                childCol.ListId = this.currentListId;
                childCol.DisplayName = parentCol.DisplayName;
                childCol.ParentColumnId = parentCol.Id;
                childCol.RelationColumnId = this.currentColumnId;
                childCol.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
                Api.Column.save(childCol)
                        .then(savedCol => {
                            if(this.selectedRelatedListColumnIndex >= 0 && this.relatedListColumns) {
                                this.relatedListColumns.splice(this.selectedRelatedListColumnIndex, 1);
                            }
                            this.loadRelatedColumns();
                            this.notifyColumnSaved();
                        })
                        .finally(() => this.addingRelatedColumn = false);
            }
        }
    }


    async removeRelatedColumn() {
        if(!this.canRemoveRelatedColumn) {
            return;
        }
        if(this.selectedRelatedColumnIndex != null && this.relatedColumns && this.selectedRelatedColumnIndex >= 0 && this.selectedRelatedColumnIndex < this.relatedColumns.length) {
            if(await Api.Column.IsReferenced(this.relatedColumns[this.selectedRelatedColumnIndex].Id)) {
                EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{
                    message: this.$i18n.t('ColumnMenu_CannotDeleteReferenced').toString(),
                    autoHide: true,
                    autoHideAfterMs: 10000,
                    type: 'error',
                    duplicateId: 'CannotDeleteColumn'
                });
            }
            else {
                this.removingRelatedColumn = true;
                const colToRemove = this.relatedColumns[this.selectedRelatedColumnIndex];
                colToRemove.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
                Api.Column.remove(colToRemove)
                    .then(res => {
                        if(res) {
                            if(this.selectedRelatedColumnIndex >= 0 && this.relatedColumns) {
                                this.relatedColumns.splice(this.selectedRelatedColumnIndex, 1);
                            }
                            this.loadColumnsForRelatedList();
                        }
                        this.notifyColumnSaved();
                    })
                    .finally(() => this.removingRelatedColumn = false);

            }
        }
    }
 }
 