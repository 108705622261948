
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
    import RsColumnManager from '@/columnProxies/RsColumnManager';

    @Component({
        name: 'TimeFormEditor',
        components: {
        }
    })
    export default class TimeFormEditor extends FormEditor {
        currentValue: string = "";
        textFieldVisible = true;

        init() {
            const proxy = RsColumnManager.getProxy(this.column.Type);
            this.currentValue = this.rowVM.getValue(this.column, proxy);

            // force refreshing the input to fix a conflict between v-mask and vuetify
            this.textFieldVisible = false;
            this.$nextTick(() =>  this.textFieldVisible = true );
        }

        getValue(){
            if(this.currentValue.match(/^\d{2}:\d{2}:\d{2}$/) || this.currentValue.match(/^\d{2}:\d{2}$/))
                return this.currentValue;
            if(this.currentValue.match(/^\d{2}:\d{2}:\d{1}$/) || this.currentValue.match(/^\d{2}:\d{1}$/))
                return this.currentValue + '0';
            if(this.currentValue.match(/^\d{2}:\d{2}:$/)) 
                    return this.currentValue.substring(0, 5);
            else {
                return this.currentValue;
            }
        }
    }
