import RowVM from '@/viewModels/Table/rowVM'
import ColumnVM from '@/viewModels/Table/columnVM'
import * as agGrid from 'ag-grid-community';
import * as RowShare from "@/models/RowShare";
import * as Api from '@/api/Api';
import RowTotalVM from './rowTotalVM';
import { EventBus } from '@/modules/EventBus';
import ViewManager from './viewManager';
import { i18n } from '@/modules/Localization';
import ListDisplayModeVM from '@/viewModels/Table/listDisplayModeVM';
import { BigramHelper } from '@/utils/BigramHelper';
import ListVM from './listVM';
import rowBaseVM from './rowBaseVM';
import { Settings } from '@/utils/Settings';
import {ListConfiguration, rowGroupStatus} from "@/models/RowShare";
import { ColumnTypes } from '@/utils/ColumnTypes';
import * as API from '@/api/Api'
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

export default class GridViewVM implements ListDisplayModeVM {
    listColumns: Array<agGrid.ColDef | agGrid.ColGroupDef> = [];
    pGridApi: agGrid.GridApi | null = null;
    pColumnApi: agGrid.ColumnApi | null = null;
    printMode: boolean = false;

    viewManager: ViewManager;

    rowAssignedToCurrentUserCount: number = -1;

    displayedRowIds: string[] | null = null;
    currentQuickFilter: string | null = null;
    preparingAdd: boolean = false;
    previousDisplayedRows: RowShare.DisplayedRows | null = null;
    previousRowNode: agGrid.IRowNode | null = null;

    public listVM: ListVM;

    constructor(listVM: ListVM) {
        this.listVM = listVM;
        this.listVM.registerListener(this);

        this.viewManager = new ViewManager(this);

        EventBus.$on(RowShare.Event.ROW_ADDED, (rowVM: RowVM) => listVM.refreshRowAssignedToCurrentUserCount());
        EventBus.$on(RowShare.Event.ROW_MODIFIED, (rowVM: RowVM) => listVM.refreshRowAssignedToCurrentUserCount());
        EventBus.$on(RowShare.Event.ROW_DELETE, (rowVMs: RowVM[]) => this.removeRowsFromGrid(rowVMs));
        EventBus.$on(RowShare.Event.ROW_RESTORE, (rowVMs: RowVM[]) => this.removeRowsFromGrid(rowVMs));
        EventBus.$on(RowShare.Event.ROW_ARCHIVE, (rowVMs: RowVM[]) => this.removeRowsFromGrid(rowVMs));
        EventBus.$on(RowShare.Event.ROW_UNARCHIVE, (rowVms: RowVM[]) => this.removeRowsFromGrid(rowVms));
        EventBus.$on(RowShare.Event.GRID_CLEAR_HIDDENCOLUMNS, () => this.clearHiddenColumns());
        EventBus.$on(RowShare.Event.GRID_CLEAR_FILTERS, () => this.clearFilters());
        EventBus.$on(RowShare.Event.RESTORE_FILTERS, () => this.restoreFilters());
        EventBus.$on(RowShare.Event.GRID_CLEAR_ROWGROUPS, () => this.onRowGroupsCleared());
        EventBus.$on(RowShare.Event.ROW_SAVED, () => this.tryRefreshTotalRow());
        EventBus.$on(RowShare.Event.GRID_CHOOSE_CELL_COLOR, (color: string) => this.onCellColorSelected(color));
        EventBus.$on(RowShare.Event.GRID_ADD_ROWGROUP, (colId: string) => this.onRowGroupColumnAdded(colId));
        EventBus.$on(RowShare.Event.GRID_REMOVE_ROWGROUP, (colId: string) => this.onRowGroupColumnRemoved(colId));
        EventBus.$on(RowShare.Event.ROW_LOCKED, (updatedRows: RowShare.Row[]) => this.updateRowsInUI(updatedRows));
        EventBus.$on(RowShare.Event.ROW_UNLOCKED, (updatedRows: RowShare.Row[]) => this.updateRowsInUI(updatedRows));
        EventBus.$on(RowShare.Event.GRID_SHOW_ROWS, (rowIds: string[]) => this.flashRows(rowIds, false));
        EventBus.$on(RowShare.Event.COLUMN_PERMISSIONS_CHANGED, (columnId: string) => this.onColumnPermissionsChanged(columnId));

        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowCellUpdate, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowCellUpdateEvent(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowCreate, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowCreateEvent(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowDelete, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowDeleteEvent(entity));

        EventBus.$on(RowShare.RealTimeCollaborationEventType.ColumnDelete, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processColumnDeleteEvent(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.ColumnCreate, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processColumnCreateEvent(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.ColumnSave, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processColumnSaveEvent(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.ColumnClone, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processColumnCloneEvent(entity));

        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowSaveComment, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowSaveComment(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowDeleteComment, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowDeleteComment(entity));

        // EventBus.$on(RowShare.RealTimeCollaborationEventType.RowSave, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowSave(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowSaveBlob, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowSaveBlob(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowDeleteBlob, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowDeleteBlob(entity));

        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowLock, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowLockEvent(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowUnlock, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowUnLockEvent(entity));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowArchive, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processRowArchiveEvent(entity));

        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowCellLock, async(rowId: string, columnId: string) => await this.processRowCellLockEvent(rowId, columnId));
        EventBus.$on(RowShare.RealTimeCollaborationEventType.RowCellUnlock, async(rowId: string, columnId: string) => await this.processRowCellUnLockEvent(rowId, columnId));

        EventBus.$on(RowShare.RealTimeCollaborationEventType.ListDelete, async(entity: RowShare.RealTimeCollaborationEntity) => await this.processListDeleteEvent(entity));
    }

    async processRowSaveComment(entity: RowShare.RealTimeCollaborationEntity) {
        await this.processRowCellUpdateEvent(entity);
    }

    async processRowDeleteComment(entity: RowShare.RealTimeCollaborationEntity) {
        await this.processRowCellUpdateEvent(entity);
    }

    async processRowSave(entity: RowShare.RealTimeCollaborationEntity) {
        await this.processRowCellUpdateEvent(entity);
    }

    async processRowSaveBlob(entity: RowShare.RealTimeCollaborationEntity) {
        await this.processRowCellUpdateEvent(entity);
    }

    async processRowDeleteBlob(entity: RowShare.RealTimeCollaborationEntity) {
        await this.processRowCellUpdateEvent(entity);
    }

    async processRowCellUpdateEvent(entity: RowShare.RealTimeCollaborationEntity) {
        const rowId: string = entity.data["rowId"];
        const columnId: string = entity.data["columnId"];
        const row = await API.Row.load(rowId);
        if (!row) return;
        const rowVM = new RowVM(this.listVM, row);
        this.listVM.notifyRowVMUpdated(rowVM);
        this.flashRow(rowId, columnId);
    }

    async processRowCreateEvent(entity: RowShare.RealTimeCollaborationEntity) {
        const rowId: string = entity.data["rowId"];
        const insertBeforeRowId: string = entity.data["insertBeforeRowId"];

        const row = await API.Row.load(rowId);
        if (!row) return;

        row.InsertBeforeRowId = insertBeforeRowId;
        let idx = this.getRowNodeIndex(insertBeforeRowId) + 1;

        await this.insertClonedRowInUI(row, idx, true);
    }

    async processRowDeleteEvent(entity: RowShare.RealTimeCollaborationEntity) {
        if (!entity || !entity.data) return
        const rowId: string = entity.data["rowId"];
        if (!rowId) return;

        const rowNode = this.gridApi?.getRowNode(rowId);
        if (!rowNode) return;

        rowNode.data.removed = true;
        rowNode.data.Status = RowShare.DataStatus.DeletionInProgress;
        this.listVM.notifyRowVMUpdated(rowNode.data as RowVM);

        setTimeout(() => {
            if (!rowNode) return;
            this.removeRowsFromGrid([rowNode.data])
        }, 3000)
    }

    flashRow(rowId: string, colId: string | null = null) {
        const rowNode = this.gridApi?.getRowNode(rowId);
        if(!rowNode) return;
        this.gridApi?.flashCells({
            rowNodes: [rowNode],
            columns: colId ? [colId] : [],
            flashDelay: 1000,
            fadeDelay: 100,
        });
    }

    async processRowLockEvent(entity: RowShare.RealTimeCollaborationEntity) {
        const rowId: string = entity.data["rowId"];
        const rowNode = this.gridApi?.getRowNode(rowId);
        if (!rowNode) return;
        const rowVM = rowNode.data as RowVM;
        rowVM.rsRow.Status = RowShare.DataStatus.Locked;
        this.listVM.notifyRowVMUpdated(rowVM);
        this.flashRow(rowId);
    }

    async processRowUnLockEvent(entity: RowShare.RealTimeCollaborationEntity) {
        const rowId: string = entity.data["rowId"];
        const rowNode = this.gridApi?.getRowNode(rowId);
        if (!rowNode) return;
        const rowVM = rowNode.data as RowVM;
        rowVM.rsRow.Status = RowShare.DataStatus.Normal;
        this.listVM.notifyRowVMUpdated(rowVM);
        this.flashRow(rowId);
    }

    async processRowArchiveEvent(entity: RowShare.RealTimeCollaborationEntity) {
        const rowId: string = entity.data["rowId"];
        const row = await API.Row.load(rowId);
        if (!row) return;
        const rowVM = new RowVM(this.listVM, row);
        this.flashRow(rowId);
        setTimeout(() =>
                this.removeRowsFromGrid([rowVM])
            ,1500
        )
    }

    async processRowCellLockEvent(rowId: string, columnId: string) {

        const rowNode = this.gridApi?.getRowNode(rowId);
        if (!rowNode) return;

        const rowVM = rowNode.data as RowVM;
        rowVM.rsRow.Status = RowShare.DataStatus.EditionInProgress;
        this.listVM.notifyRowVMUpdated(rowVM);

        const col = this.columnApi?.getColumn(columnId)
        if(!col) return;

        const colDef =  <ColumnVM>col?.getColDef()
        if (!colDef) return;

        // colDef.cellLocked = true;

        this.gridApi?.refreshCells({
            rowNodes: [rowNode],
            columns: [col]
        });
    }

    async processRowCellUnLockEvent(rowId: string, columnId: string) {
        if (rowId === "" && columnId === "") {
            this.gridApi?.refreshCells({force: true});
            return;
        }

        const rowNode = this.gridApi?.getRowNode(rowId);
        if (!rowNode) return;

        const rowVM = rowNode.data as RowVM;
        rowVM.rsRow.Status = RowShare.DataStatus.Normal;
        this.listVM.notifyRowVMUpdated(rowVM);

        const col = this.columnApi?.getColumn(columnId)
        if(!col) return;

        this.gridApi?.refreshCells({
            rowNodes: [rowNode],
            columns: [col],
            force: true
        });
    }

    async processListDeleteEvent(entity: RowShare.RealTimeCollaborationEntity) {
        let event = new RowShare.ConfirmationRequiredEventParams();
        event.title = i18n.t('Rtc_List_Title_Delete').toString();
        event.description1 = i18n.t('Rtc_List_Description_Delete', [entity.authorName]).toString();
        event.actionButtonText = i18n.t('Rtc_List_ActionButton_Delete').toString();
        event.actionButtonColor = "primary";
        event.persistent = true;
        event.onConfirmation = async () => {
            window.location.href = "/";
        };
        EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, event);
    }

    async processColumnDeleteEvent(entity: RowShare.RealTimeCollaborationEntity) {
        const colName: string = entity.data["displayName"];
        const msg = i18n.t('Rtc_Column_Delete', [colName, entity.authorName, entity.authorEmail]).toString();
        this.showStructuralChangePopupMessage(msg);
    }

    async processColumnCreateEvent(entity: RowShare.RealTimeCollaborationEntity) {
        const colName: string = entity.data["displayName"];
        const msg = i18n.t('Rtc_Column_Create', [colName, entity.authorName, entity.authorEmail]).toString();
        this.showStructuralChangePopupMessage(msg);
    }

    async processColumnSaveEvent(entity: RowShare.RealTimeCollaborationEntity) {
        const displayName: string = entity.data["displayName"];
        const oldName: string = entity.data["oldName"];
        const newName: string = entity.data["newName"];
        let msg = "";
        if (oldName && newName){
            msg = i18n.t('Rtc_Column_Name_Change', [oldName, newName, entity.authorName, entity.authorEmail]).toString();
        } else {
            msg = i18n.t('Rtc_Column_Save', [displayName, entity.authorName, entity.authorEmail]).toString();
        }
        this.showStructuralChangePopupMessage(msg);
    }

    async processColumnCloneEvent(entity: RowShare.RealTimeCollaborationEntity) {
        const colName: string = entity.data["displayName"];
        const msg = i18n.t('Rtc_Column_Clone', [colName, entity.authorName, entity.authorEmail]).toString();
        this.showStructuralChangePopupMessage(msg);
    }

    showStructuralChangePopupMessage(message: string) {
        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
            autoHide: false,
            type: RowShare.NotificationType.warning,
            message: message,
            showActionButton: true,
            actionButtonText: i18n.t('Rtc_Column_ActionButton').toString(),
            actionButtonClick: () => {
                window.location.reload()
            }
        });
    }

    dispose(): void {
        this.listVM.unregisterListener(this);

        EventBus.$off(
            [
                RowShare.Event.ROW_ADDED,
                RowShare.Event.ROW_MODIFIED,
                RowShare.Event.ROW_DELETE,
                RowShare.Event.ROW_RESTORE,
                RowShare.Event.ROW_ARCHIVE,
                RowShare.Event.ROW_UNARCHIVE,
                RowShare.Event.GRID_CLEAR_HIDDENCOLUMNS,
                RowShare.Event.GRID_CLEAR_FILTERS,
                RowShare.Event.RESTORE_FILTERS,
                RowShare.Event.GRID_CLEAR_ROWGROUPS,
                RowShare.Event.ROW_SAVED,
                RowShare.Event.GRID_CHOOSE_CELL_COLOR,
                RowShare.Event.GRID_ADD_ROWGROUP,
                RowShare.Event.GRID_REMOVE_ROWGROUP,
                RowShare.Event.ROW_LOCKED,
                RowShare.Event.ROW_UNLOCKED,
                RowShare.Event.GRID_SHOW_ROWS,
                RowShare.Event.COLUMN_PERMISSIONS_CHANGED,
            ]
        );
    }

    onListUpdated() { }

    onBeforeRowVMsUdated(listVM: ListVM): void {
        if (listVM.rowVMs == null) {
            return;
        }
        this.execOnceGridApiIsReady((gridApi) => {
            setTimeout(() => { gridApi?.showLoadingOverlay() }, 0);
        });
    }

    onRowVMsUpdated() {
        if (this.listVM.rowVMs == null)
            return;

        this.execOnceGridApiIsReady((gridApi) => {
            // do not user databinding for rowdata, it is only one way and pushing the new rows to VM.listRows
            // to keep it up to date will refresh the whole grid if using binding (and refreshing will close the active editor)
            if (this.listVM.rowVMs &&
                (gridApi.getDisplayedRowCount() != this.listVM.rowVMs.length || gridApi.getDisplayedRowCount() == 0 || this.previousDisplayedRows != this.listVM.displayedRows)) {
                gridApi.setRowData(this.listVM.rowVMs);
                this.listVM.refreshRowAssignedToCurrentUserCount();
            }
        });
        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
        if (this.listVM.specialRowsDisplayed) {
            this.addStatusColumns();
        }
        else {
            this.removeStatusColumns();
        }
        setTimeout(() => {
            if ((this.listVM.rowVMs?.length ?? 0) > 0) {
                this.gridApi?.hideOverlay();
                this.previousDisplayedRows = this.listVM.displayedRows;
            }
        }, 0);

        if (this.listVM.list?.ShowTotals) {
            this.rowTotalVM = new RowTotalVM(this);
        }
    }

    get statusChangeUserHeader(): string {
        switch (this.listVM?.displayedRows) {
            case RowShare.DisplayedRows.Deleted:
                return i18n.t('DeletedBy_HeaderName').toString();
            case RowShare.DisplayedRows.Archived:
                return i18n.t('ArchivedBy_HeaderName').toString();
            default:
                return "";
        }
    }

    get statusChangeDateHeader(): string {
        switch (this.listVM?.displayedRows) {
            case RowShare.DisplayedRows.Deleted:
                return i18n.t('DeletedDate_HeaderName').toString();
            case RowShare.DisplayedRows.Archived:
                return i18n.t('ArchivedDate_HeaderName').toString();
            default:
                return "";
        }
    }

    get cellSelected(): boolean {
        return (this.gridApi?.getCellRanges()?.length ?? 0) > 0;
    }

    onCellColorSelected(color: string) {
        this.gridApi?.showLoadingOverlay();
        setTimeout(() => this.setCellRangesColor(color), 0);
    }

    async setCellRangesColor(colorClass: string) {
        if (!this.gridApi) {
            return;
        }

        let cellRanges = this.gridApi.getCellRanges();
        let rowsSelected = this.gridApi.getSelectedNodes();
        if ((!cellRanges || cellRanges.length == 0) && (!rowsSelected || rowsSelected.length == 0)) {
            return;
        }
        const list = this.listVM.list;
        if (!list) {
            return;
        }
        let nodesToRefresh: agGrid.IRowNode<any>[] = [];
        let columnsToRefresh: agGrid.Column[] = [];
        let cellsRangesColor: RowShare.CellColor[] = [];

        if (cellRanges) {
            // Getting cells to color from selected range(s)
            cellRanges.forEach((range) => {
                let firstRowIndex = Math.min(range.startRow?.rowIndex ?? 0, range.endRow?.rowIndex ?? 0);
                let lastRowIndex = Math.max(range.startRow?.rowIndex ?? 0, range.endRow?.rowIndex ?? 0);
                for (var rowIndex = firstRowIndex; rowIndex <= lastRowIndex; rowIndex++) {
                    var rowModel = this.gridApi?.getModel();
                    const rowNode = rowModel?.getRow(rowIndex);
                    if (!rowNode) {
                        return;
                    }
                    const rowId = rowNode.data?.rsRow.Id;

                    range.columns.forEach((column) => {
                        if (!this.gridApi) {
                            return;
                        }
                        if (!rowNode.data?.rsRow.CanUpdate) {
                            return;
                        }
                        const colDef = column.getColDef();
                        const rsColumn = (<any>colDef).rsColumn;
                        if (!rsColumn) {
                            return;
                        }
                        cellsRangesColor.push({
                            RowId: rowId,
                            ColumnId: rsColumn.Id,
                            Color: colorClass
                        });
                        columnsToRefresh.push(column);
                    });
                    nodesToRefresh.push(rowNode);
                }
            });
        }

        if (rowsSelected) {
            rowsSelected.forEach((row) => {
                let rowId = row.data?.rsRow.Id;
                cellsRangesColor.push({
                    RowId: rowId,
                    ColumnId: '',
                    Color: colorClass
                });
            });
            nodesToRefresh.push(...rowsSelected);
        }

        //save cells colors
        let colors = await Api.CellColor.saveColors(cellsRangesColor);

        // force refresh
        let colDefs = this.gridApi.getColumnDefs();
        if (colDefs) {
            colDefs.forEach((column) => {
                let colDef = <ColumnVM>column;
                if (colDef?.rsList) {
                    colDef.rsList.CellsColors = colors ?? "";
                }
            });
        }
        this.gridApi.hideOverlay();
        this.gridApi.clearRangeSelection();
        this.gridApi.refreshCells({ rowNodes: nodesToRefresh, force: true });
        this.gridApi.redrawRows({ rowNodes: nodesToRefresh });
        this.deselectAllRows();
    }


    addStatusColumns() {
        if (!this.listColumns || !this.listVM?.list) {
            return;
        }
        let settings = RowShare.ListSettings.loadListSettingsFromLocalStorage(this.listVM.list.Id);

        let statusColumns: agGrid.ColDef[] = [];

        // Create fake RowShare columns to be able to use column proxies
        let fakeRsColumn = new RowShare.Column();
        fakeRsColumn.Id = ColumnVM.statusChangeByUserId;
        fakeRsColumn.DisplayName = this.statusChangeUserHeader;
        fakeRsColumn.Type = RowShare.ColumnStrongType.StatusChangeByUserName;
        fakeRsColumn.LeftPinned = true;
        var colVM = new ColumnVM(this.listVM.list, fakeRsColumn, settings, this.printMode);
        (<agGrid.ColDef>colVM).lockPosition = true;
        (<agGrid.ColDef>colVM).lockVisible = true;
        let headerClass = (this.listVM.displayedRows == RowShare.DisplayedRows.Deleted) ? 'status-col--deleted' : 'status-col--archived';
        colVM.headerClass = headerClass;
        statusColumns.push(colVM);
        fakeRsColumn = new RowShare.Column();
        fakeRsColumn.Id = ColumnVM.statusChangeDateTimeId;
        fakeRsColumn.DisplayName = this.statusChangeDateHeader;
        fakeRsColumn.Type = RowShare.ColumnStrongType.StatusChangeDateTime;
        fakeRsColumn.LeftPinned = true;
        fakeRsColumn.DefaultWidth = 175;
        var colVM = new ColumnVM(this.listVM.list, fakeRsColumn, settings, this.printMode);
        (<agGrid.ColDef>colVM).lockPosition = true;
        (<agGrid.ColDef>colVM).lockVisible = true;
        (<agGrid.ColDef>colVM).sort = "desc";
        colVM.headerClass = headerClass;
        statusColumns.push(colVM);

        this.listColumns.splice(1, 0, ...statusColumns);
        setTimeout(() => {
            let col = this.columnApi?.getColumn((<agGrid.ColDef>this.listColumns[3]).colId) // First column after columns added
            if (col) {
                this.gridApi?.refreshCells({ columns: [col], force: true });
                this.gridApi?.refreshHeader();
            }
        }, 0);
        this.clearFilters();
        this.clearSortOrder();
        this.clearAllSavedFilters();
        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
    }

    removeStatusColumns() {
        if (!this.listColumns) {
            return;
        }
        if (!this.listColumns.find(lc => {
            let colId = (<agGrid.ColDef>lc)?.colId;
            return colId == ColumnVM.statusChangeByUserId || colId == ColumnVM.statusChangeDateTimeId;
        })) {
            return;
        }
        this.clearFilters();
        this.clearSortOrder();
        this.clearAllSavedFilters();
        this.currentQuickFilter
        this.listColumns = this.listColumns.filter(lc => {
            let colId = (<agGrid.ColDef>lc)?.colId;
            return colId != ColumnVM.statusChangeByUserId && colId != ColumnVM.statusChangeDateTimeId;
        });
        setTimeout(() => {
            this.gridApi?.refreshCells();
            this.gridApi?.refreshHeader();
        }, 0);
        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
    }

    onColumnsUpdated() {
        if (!this.listVM.columns || !this.listVM.list)
            return;

        let settings = RowShare.ListSettings.loadListSettingsFromLocalStorage(this.listVM.list.Id);

        var allCols: Array<agGrid.ColDef | agGrid.ColGroupDef> = [];
        this.listVM.columns.forEach(c => {
            let colVM = new ColumnVM(<RowShare.List>this.listVM.list, c, settings, this.printMode);
            if (c.ColumnGroup && c.ColumnGroup !== '') {
                let group = allCols.find(ac => ac.headerName == c.ColumnGroup && (<agGrid.ColGroupDef>ac).children);
                if (group == null || group == undefined) {
                    group = <agGrid.ColGroupDef>{ headerName: c.ColumnGroup, headerClass: ['colGroupHeader'], children: [], marryChildren: true };
                    (<agGrid.ColGroupDef>group).headerGroupComponent = 'columnGroupHeader';
                    (<agGrid.ColGroupDef>group).openByDefault = true;
                    if (c.HasParentColumn) {
                        (<Array<string>>group.headerClass).push('group-relation');
                        (<agGrid.ColGroupDef>group).headerGroupComponentParams = { relationColumnId: c.RelationColumnId };
                    }
                    (<agGrid.ColGroupDef>group).groupId = c.ColumnGroup;
                    allCols.push(group);
                }
                else {
                    colVM.columnGroupShow = 'open';
                }
                (<agGrid.ColGroupDef>group).children.push(colVM);
            }
            else {
                allCols.push(colVM);
            }
        });

        let firstCol = allCols[0];
        if (Object.keys(firstCol).find((key) => key === 'children') != undefined) {
            (<agGrid.ColDef>(<agGrid.ColGroupDef>firstCol).children[0]).pinned = 'left';
        }
        else {
            (<agGrid.ColDef>firstCol).pinned = 'left';
        }

        allCols.unshift({
            colId: ColumnVM.rowOwnerHeaderId,
            valueGetter: params => params.data?.getAssignation(),
            valueSetter: params => params.data?.setAssignation(params.newValue),
            cellClassRules: ColumnVM.getCellClassRules(),
            pinned: 'left',
            cellClass: 'technical-left-col',
            cellRendererSelector: (params) => {
                var row = <rowBaseVM>params.data;
                if (!row) {
                    return undefined;
                }
                if (row.isTotalRow) {
                    return { component: "TotalRowHeaderRenderer" };
                }
                return { component: 'RowOwnerHeader' };
            },
            cellEditor: 'RowOwnerHeaderEditor',
            editable: (params) => {
                if (!params.data?.rsRow?.CanAssign) {
                    EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                        title: i18n.t("Notif_CannotAssignRow").toString(),
                        autoHide: true,
                        autoHideAfterMs: 2000
                    });
                    return false;
                }
                if (params.data?.isLocked) {
                    EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                        title: i18n.t("Notif_LockedRow").toString(),
                        autoHide: true,
                        autoHideAfterMs: 2000
                    });
                    return false;
                }
                return (this.listVM?.list?.ShowOwnerInformations ?? false) && !this.listVM.specialRowsDisplayed && !params.data?.isNew;
            },
            rowDrag: params => params.data?.rsRow.CanUpdate && !params.data?.isNew && !this.listVM.specialRowsDisplayed && !params.data?.isLocked,
            rowDragText: (params, dragItemCount) => {
                return params.rowNode?.data?.rsRow.DescriptorFormattedValue;
            },
            suppressKeyboardEvent: (params) => params?.editing,
            suppressSizeToFit: true,
            resizable: false,
            lockVisible: true,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
            chartDataType: 'excluded',
            checkboxSelection: params => !params.data?.isNew && !params.node.group,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            minWidth: this.listVM.list.ShowOwnerInformations ? 95 : 60,
            maxWidth: this.listVM.list.ShowOwnerInformations ? 95 : 60,
            suppressMovable: true,
            lockPosition: true,
            lockPinned: true,
            sortable: true,
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                const a = nodeA.data?.getOwnerSortValue().toLowerCase();
                const b = nodeB.data?.getOwnerSortValue().toLowerCase();

                if (a == b) {
                    return 0;
                }
                if (a == "") {
                    return 1;
                }
                if (b == "") {
                    return -1;
                }
                return (a > b) ? 1 : -1;
            },
            suppressMenu: true,
            filter: true,
            keyCreator: (params: any) => {
                if (params.value?.rowOwner)
                    return BigramHelper.getBigramTooltipForRowOwner(params.value?.rowOwner);
                else
                    return BigramHelper.getBigramTooltipForSecurityPrincipal(params.value?.securityPrincipal);
            },
            filterParams: {
                excelMode: 'windows',
                comparator: (valueA: any, valueB: any) => {
                    const a = valueA.toLowerCase();
                    const b = valueB.toLowerCase();

                    if (a == b)
                        return 0;
                    return (a > b) ? 1 : -1;
                },
                convertValuesToStrings: true,
            },

        });
        this.listColumns = allCols;
        setTimeout(() => {
            this.execOnceColAndGridApiAreReady(gridApi => {
                if (settings.configuration?.filters) {
                    const filters = settings.configuration.filters;
                    gridApi.setFilterModel(filters);
                    if (Object.keys(filters).length !== 0) {
                        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                            title: i18n.t("Notif_FilterRestored").toString(),
                            autoHide: true,
                            autoHideAfterMs: 10000
                        });
                    }
                }
            });
        }, 0);

        this.execOnceColAndGridApiAreReady((gridApi, columnApi) => {
            if (settings.configuration) {
                if (settings.configuration.sortOrder) {
                    columnApi.applyColumnState({
                        state: settings.configuration.sortOrder,
                        defaultState: { sort: null },
                    });
                }
                if (settings.configuration.sideBar?.valueColumns) {
                    this.applyValueColumns(settings.configuration);
                }
                if (settings.configuration.sideBar?.rowGroupColumns) {
                    this.setRowGroupColumns(settings.configuration.sideBar.rowGroupColumns);
                }
                if ((settings.configuration.collapsedColumnGroups?.length ?? 0) > 0) {
                    this.applyCollapsedColumnGroups(settings.configuration);
                }
            }
            EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
        });
    }

    public get gridApi(): agGrid.GridApi | null {
        return this.pGridApi;
    }
    public set gridApi(val: agGrid.GridApi | null) {
        this.pGridApi = val;
        if (this.pGridApi) {
            for (let i = 0; i < this.funcToExecOnceGridApiIsReady.length; ++i) {
                this.funcToExecOnceGridApiIsReady[i](this.pGridApi);
            }
            this.funcToExecOnceGridApiIsReady = [];

            this.tryRunFuncWaitingForColAndGridApi();
        }
    }

    public get columnApi(): agGrid.ColumnApi | null {
        return this.pColumnApi;
    }
    public set columnApi(val: agGrid.ColumnApi | null) {
        this.pColumnApi = val;
        if (this.pColumnApi) {
            for (let i = 0; i < this.funcToExecOnceColumnApiIsReady.length; ++i) {
                this.funcToExecOnceColumnApiIsReady[i](this.pColumnApi);
            }
            this.funcToExecOnceColumnApiIsReady = [];

            this.tryRunFuncWaitingForColAndGridApi();
        }
    }

    removeRowsFromGrid(rowVMs: RowVM[]) {
        this.listVM.refreshRowAssignedToCurrentUserCount();
        this.removeRowsInUI(rowVMs);
        this.tryRefreshTotalRow();
        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
    }

    private tryRunFuncWaitingForColAndGridApi() {
        if (this.columnApi && this.gridApi) {
            for (let i = 0; i < this.funcToExecOnceColAndGridApiAreReady.length; ++i) {
                this.funcToExecOnceColAndGridApiAreReady[i](this.gridApi, this.columnApi);
            }
            this.funcToExecOnceColAndGridApiAreReady = [];
        }
    }

    bottomPinnedRows: RowTotalVM[] = [];
    set rowTotalVM(val: RowTotalVM | null) {
        if (val)
            this.bottomPinnedRows = [val];
        else
            this.bottomPinnedRows = [];
    }
    get rowTotalVM(): RowTotalVM | null {
        if (this.bottomPinnedRows.length == 1)
            return this.bottomPinnedRows[0];
        else
            return null;
    }

    tryRefreshTotalRow() {
        if (this.listVM.list?.ShowTotals && this.gridApi) {
            const totalRow = this.gridApi.getPinnedBottomRow(0);
            if (totalRow)
                this.gridApi.refreshCells({ rowNodes: [totalRow] });
        }
    }


    public get readyToShowTheGrid(): boolean {
        return this.listColumns.length > 0 && this.listVM.rowVMs != null;
    }

    private funcToExecOnceGridApiIsReady: Array<(gridApi: agGrid.GridApi) => void> = [];

    public execOnceGridApiIsReady(func: (gridApi: agGrid.GridApi) => void) {
        if (this.gridApi) {
            func(this.gridApi);
        } else {
            this.funcToExecOnceGridApiIsReady.push(func);
        }
    }

    private funcToExecOnceColumnApiIsReady: Array<(columnApi: agGrid.ColumnApi) => void> = [];

    public execOnceColumnApiIsReady(func: (columnApi: agGrid.ColumnApi) => void) {
        if (this.columnApi) {
            func(this.columnApi);
        } else {
            this.funcToExecOnceColumnApiIsReady.push(func);
        }
    }

    private funcToExecOnceColAndGridApiAreReady: Array<(gridApi: agGrid.GridApi, columnApi: agGrid.ColumnApi) => void> = [];

    public execOnceColAndGridApiAreReady(func: (gridApi: agGrid.GridApi, columnApi: agGrid.ColumnApi) => void) {
        if (this.gridApi && this.columnApi) {
            func(this.gridApi, this.columnApi);
        } else {
            this.funcToExecOnceColAndGridApiAreReady.push(func);
        }
    }

    deselectAllRows() {
        if (this.gridApi) {
            this.gridApi.deselectAll();
        }
    }

    public addRow(beforeRowNode: agGrid.IRowNode | undefined = undefined) {
        if (!this.gridApi)
            return;
        this.preparingAdd = true;
        setTimeout(() => {
            if (!this.listVM.list)
                return;

            if (this.gridApi?.isColumnFilterPresent() || this.gridApi?.isQuickFilterPresent() || (this.displayedRowIds?.length ?? 0) > 0) {
                if (this.displayedRowIds) {
                    this.displayedRowIds = [];
                }
                this.gridApi?.forEachNodeAfterFilter((node) => {
                    if (node.id) {
                        if (!this.displayedRowIds) {
                            this.displayedRowIds = [];
                        }
                        this.displayedRowIds.push(node.id);
                    }
                });
                this.gridApi?.setFilterModel(null);
                EventBus.$emit(RowShare.Event.GRID_SEARCH_CHANGED, <RowShare.GridSearchChangeEventParams>{ searchText: null, saveValue: false, source: "external" });
                this.gridApi?.onFilterChanged();
            }
            var newRowVM = RowVM.createNewRow(this.listVM, this.listVM.list.Id);
            var agIdx: number | undefined = undefined;

            if (beforeRowNode) {
                let beforeRow = <RowVM>beforeRowNode.data
                agIdx = beforeRowNode.rowIndex ?? undefined;
                newRowVM.rsRow.Index = beforeRow.rsRow.Index;
                newRowVM.setInsertBeforeRowId(beforeRow.rsRow.Id);
                this.gridApi?.forEachNode(n => {
                    let nVM = <RowVM>n.data;
                    if (nVM.rsRow.Index >= beforeRow?.rsRow?.Index)
                        nVM.rsRow.Index++;
                });
            }

            try {
                var rowNodeTransactions = this.gridApi?.applyTransaction({ add: [newRowVM], addIndex: agIdx });
                if (rowNodeTransactions && rowNodeTransactions.add && rowNodeTransactions.add.length > 0) {
                    this.listVM.rowVMs?.push(newRowVM);
                    EventBus.$emit(RowShare.Event.ROW_ADDED, [newRowVM]);
                    var rowIndex = rowNodeTransactions.add[0].rowIndex
                    this.gridApi?.refreshClientSideRowModel();
                    if (rowIndex != undefined) {
                        let flatColumns: agGrid.ColDef[] = [];

                        this.listColumns.forEach(c => {
                            var children = (<agGrid.ColGroupDef>c).children;
                            if (children) {
                                children.forEach(child => {
                                    flatColumns.push(child);
                                });
                            }
                            else {
                                flatColumns.push(c);
                            }
                        })

                        var colToEdit = /*this.listColumns*/flatColumns.find(c => {
                            let rsCol = (<ColumnVM>c).rsColumn;
                            if (!rsCol)
                                return false;

                            return !rsCol.IsReadOnly && !rsCol.isOwnerInfo;
                        }) as agGrid.ColDef;

                        if (!colToEdit) {
                            return;
                        }

                        let editCell: agGrid.StartEditingCellParams = {
                            rowIndex: rowIndex,
                            colKey: colToEdit?.colId ?? ""
                        };
                        this.gridApi?.ensureColumnVisible(colToEdit?.colId ?? "");
                        this.gridApi?.startEditingCell(editCell);
                        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
                    }
                }
            }
            finally {
                this.preparingAdd = false;
            }
        }, 0);
    }

    public onRowVMUpdated(rowVM: RowVM): void {
        if (!this.gridApi)
            return;

        const rowNode = this.gridApi.getRowNode(rowVM.rowVMId);
        if (rowNode) {
            rowNode.setData(rowVM);
            this.gridApi.applyTransaction({ update: [rowVM] });
            this.columnApi?.getAllDisplayedColumns()?.forEach((column) => {
                var colVM = <ColumnVM>column.getColDef();
                if(colVM?.rsColumn && (colVM.rsColumn.isFormula)) {
                    rowNode.setDataValue(column, rowVM.getValue(colVM.rsColumn, colVM.columnProxy));
                }
            });
        }
    }

    public refreshCell(rowVM: RowVM): void {
        if (!this.gridApi)
            return;

        const rowNode = this.gridApi.getRowNode(rowVM.rowVMId);
        if (rowNode) {
            this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
        }
    }

    public async insertClonedRowInUI(clonedRow: RowShare.Row, insertAtIdx: number | undefined, refresh: boolean | undefined): Promise<void> {
        if (!this.gridApi) {
            return;
        }
        var newRowVM = new RowVM(this.listVM, clonedRow);
        if (insertAtIdx) {
            var rowNode = this.gridApi.getDisplayedRowAtIndex(insertAtIdx);
            var parentNode = rowNode?.parent;
            while (parentNode?.parent) {
                parentNode = parentNode.parent;
            }
            if (parentNode) {
                insertAtIdx = parentNode.allLeafChildren.findIndex(node => node.id == rowNode?.id);
            }
        }
        var rowNodeTransaction = this.gridApi.applyTransaction({ add: [newRowVM], addIndex: insertAtIdx });
        if (rowNodeTransaction && ((this.displayedRowIds?.length) ?? 0 > 0)) {
            rowNodeTransaction.add.forEach(rn => {
                if (rn?.id) {
                    this.displayedRowIds?.push(rn.id);
                }
            });
        }
        this.listVM.rowVMs?.push(newRowVM);
        if (refresh) {
            await this.listVM.refreshRowsFromServer(true);
            this.gridApi.onFilterChanged();
        }
    }

    getRowNodeIndex(id: string): number {
        return this.gridApi?.getRowNode(id)?.rowIndex ?? -1;
    }

    public scrollToIndex(index: number): void {
        if (!this.gridApi) {
            return;
        }
        let rowCount = this.gridApi.getDisplayedRowCount();
        this.gridApi.ensureIndexVisible(index > rowCount ? rowCount : index);
    }

    public insertColumnInUI(newColumn: RowShare.Column, insertColumnId: string, insertPosition: "after"|"before"): void {
        if(newColumn && this.listVM.list && this.gridApi && this.columnApi) {
            let addColVM = new ColumnVM(this.listVM.list, newColumn);
            let colDefs = this.gridApi.getColumnDefs();
            if(!colDefs)
                return;

            if(addColVM.rsColumn.ColumnGroup) {
                var group = <agGrid.ColGroupDef>colDefs.find(cd => (<any>cd)?.groupId === addColVM.rsColumn.ColumnGroup);
                if(group) {
                    group.children.push(addColVM);
                }
            }
            else {
                colDefs.push(addColVM);
            }
            this.listVM.columns?.push(newColumn);
            this.gridApi.setColumnDefs(colDefs);
            var newAgCol = this.columnApi.getColumn(addColVM.colId);
            if(insertColumnId) {
                var agColInsert = this.columnApi.getColumn(insertColumnId);
                var agColInsertIndex = this.columnApi.getAllGridColumns().findIndex(c => c.getColId() == insertColumnId);
                if(newAgCol && agColInsertIndex > -1) {
                    if(insertPosition == "after") {
                        agColInsertIndex++;
                    }
                    this.columnApi.moveColumn(newAgCol, agColInsertIndex);
                }
                if(newAgCol && agColInsert && agColInsert.isPinned()) {
                    this.columnApi.setColumnPinned(newAgCol, 'left');
                }
            }

            var columnTypes = ColumnTypes.getColumnTypes(true, true);
            var columnTypeIndex = columnTypes.findIndex((ct) => ct.StrongType == addColVM.rsColumn.Type);
            if(columnTypeIndex >= 0) {
                if(columnTypes[columnTypeIndex].mustRefreshValuesFromServer || addColVM.rsColumn.isFormula ) {
                    this.listVM.refreshRowsFromServer();
                }
            }

            setTimeout(() => {
                if(newAgCol) {
                    this.gridApi?.ensureColumnVisible(addColVM.colId ?? "");
                    this.gridApi?.refreshCells();
                    this.gridApi?.redrawRows();
                    EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
                }
            }, 0);
        }
    }

    public addRowsInUI(newRows: RowShare.Row[]): void {
        if (!this.gridApi) {
            return;
        }
        let rowVMs = newRows.map(nr => new RowVM(this.listVM, nr));
        this.gridApi.applyTransaction({ add: rowVMs });
        this.listVM.rowVMs?.push(...rowVMs);
    };

    public updateRowsInUI(updatedRows: RowShare.Row[]): void {
        if (!this.gridApi) {
            return;
        }
        let rowVMs = updatedRows.map(ur => new RowVM(this.listVM, ur));
        rowVMs.forEach(rowvm => {
            var index = this.listVM.rowVMs?.findIndex(rvm => rvm.rsRow?.Id === rowvm.rsRow?.Id) ?? -1;
            if (index > -1 && this.listVM?.rowVMs) {
                this.listVM.rowVMs[index] = rowvm;
            }
        });
        this.gridApi.applyTransaction({ update: rowVMs });
    };

    public removeRowsInUI(rowVMs: RowVM[]): void {
        if (!this.gridApi)
            return;

        this.gridApi.applyTransaction({ remove: rowVMs });
    }

    public get canAddColumn() {
        return this.gridApi
            && this.columnApi
            && this.listVM.rowVMs
            && this.listColumns
            && this.listVM.list
            && this.listVM.list.Owned
            && !this.listVM.list.LoadedByReadOnlyMember
            && !this.listVM.specialRowsDisplayed;
    }

    public hideColumn(column: agGrid.Column) {
        if (this.listVM.list?.Id && this.columnApi) {
            this.columnApi.setColumnVisible(column, false);
        }
    }

    public onColumnVisibleChanged(event: agGrid.ColumnVisibleEvent) {
        if (!this.printMode && !this.listVM.specialRowsDisplayed) {
            RowShare.ListSettings.saveHiddenColumns(this);
        }
        this.gridApi?.refreshHeader();
        this.viewManager.onColumnVisibleChanged(event);
        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
    }

    public onColumnGroupOpenedChanged(event: agGrid.ColumnGroupOpenedEvent) {
        if (!this.printMode && !this.listVM.specialRowsDisplayed) {
            RowShare.ListSettings.saveCollapsedColumnGroups(this);
        }
        this.viewManager.onColumnGroupOpenedChanged(event);
    }

    public onRowGroupOpenedChanged(event: agGrid.RowGroupOpenedEvent){
        let groupStatus = new rowGroupStatus();
        groupStatus.expanded = event.node.expanded;
        groupStatus.rowGroupColumnId = event.node.rowGroupColumn?.getColId()!;
        groupStatus.key = event.node.key!;
        RowShare.ListSettings.saveGroups(this.listVM.list!.Id!, groupStatus);
    }

    public isGroupOpenByDefault(params: agGrid.IsGroupOpenByDefaultParams): boolean {
        let currentSettings = RowShare.ListSettings.loadListSettingsFromLocalStorage(this.listVM.list!.Id!) ?? new RowShare.ListSettings();
        if (!currentSettings.configuration) {
            currentSettings.configuration = new ListConfiguration();
        }
        currentSettings.configuration.groups = currentSettings.configuration.groups ?? [];
        let key = params.rowNode.key;
        let existingGroup = currentSettings.configuration.groups.find(status => status.key === key && status.rowGroupColumnId === params.rowGroupColumn.getId());
        if (existingGroup) { //at this point, we process an existing group
            return existingGroup.expanded;
        }
        else {
            if (params.rowNode.leafGroup) {
                return false; // return false implies this node will not expand
            }
            return true; //return true implies this node will expand
        }
    }

    public getHiddenColumnsIds(): string[] | undefined {
        if (!this.columnApi)
            return;

        let columnsState = this.columnApi.getColumnState();
        return columnsState.filter(cs => cs.hide && cs.colId).map(cs => <string>cs.colId);
    }

    public getCollapsedColumnGroups(): string[] | undefined {
        if (!this.columnApi) {
            return;
        }
        let colGroups = this.columnApi.getAllDisplayedColumnGroups();
        if (colGroups) {
            let collapsedColumnGroups: string[] = [];
            colGroups.forEach(cg => {
                if (cg) {
                    var colDef = <agGrid.ColGroupDef>cg.getDefinition();
                    if (colDef) {
                        let id = colDef.groupId;
                        if (id) {
                            let group = this.columnApi?.getColumnGroup(id);
                            if (!(group?.isExpanded() ?? true)) {
                                if (!collapsedColumnGroups.includes(id)) {
                                    collapsedColumnGroups.push(id);
                                }
                            }
                        }
                    }
                }
            });
            return collapsedColumnGroups;
        }
    }

    public showHiddenColumnsBeside(column: agGrid.Column) {
        if (!column || !this.columnApi || !this.gridApi) {
            return;
        }

        let colState = this.columnApi.getColumnState();
        let index = colState.findIndex(c => c.colId == column.getColId()) + 1;
        let hiddenCol: boolean = true;
        let hiddenCols: agGrid.Column[] = [];

        do {
            hiddenCol = colState[index]?.hide ?? false;
            if (hiddenCol) {
                let col = this.columnApi.getColumn(colState[index].colId);
                if (col) {
                    hiddenCols.push(col);
                }
            }
            index++;
        } while (index < colState.length && hiddenCol);

        this.columnApi.setColumnsVisible(hiddenCols, true);
        if (!this.printMode) {
            RowShare.ListSettings.saveHiddenColumns(this);
        }
        this.gridApi.refreshHeader();
        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
    }

    public clearHiddenColumns(): void {
        this.execOnceColAndGridApiAreReady((gridApi, columnApi) => {
            let hiddenColumns = columnApi.getColumnState()
                .filter(cs => cs.colId && cs.colId != ColumnVM.rowOwnerHeaderId && cs.hide === true)
                .map(cs => cs.colId ?? "");

            if (hiddenColumns && hiddenColumns.length > 0) {
                columnApi.setColumnsVisible(hiddenColumns, true);
                if (!this.printMode) {
                    RowShare.ListSettings.saveHiddenColumns(this);
                }
                gridApi.refreshHeader();
                EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
            }
        });
    }

    public clearCollapsedColumnGroups(): void {
        this.execOnceColumnApiIsReady((columnApi) => {
            let colGroups = columnApi.getAllDisplayedColumnGroups();
            if (colGroups) {
                colGroups.forEach(cg => {
                    let groupId = (<agGrid.ColGroupDef>cg.getDefinition())?.groupId;
                    if (groupId) {
                        columnApi.setColumnGroupOpened(groupId, true);
                    }
                });
            }
        });
    }

    public clearSortOrder(): void {
        this.execOnceColumnApiIsReady((columnApi) => {
            columnApi.applyColumnState({ defaultState: { sort: null } });
        });
    }

    public clearFilters(): void {
        this.displayedRowIds = null;
        this.execOnceGridApiIsReady((gridApi) => {
            gridApi.setFilterModel(null);
            gridApi.onFilterChanged();
        });
    }

    public clearRowGroups(): void {
        this.execOnceColAndGridApiAreReady((gridApi, columnApi) => {
            columnApi.setRowGroupColumns([]);
            gridApi.refreshHeader();
            EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
        });
    }

    public onRowGroupsCleared(): void {
        this.clearGroupFromLocalStorage()
        this.clearRowGroups();
        this.clearValueColumns();
    }

    public clearGroupFromLocalStorage() {
        let currentSettings = RowShare.ListSettings.loadListSettingsFromLocalStorage(this.listVM.list!.Id!) ?? new RowShare.ListSettings();
        if (!currentSettings.configuration) {
            currentSettings.configuration = new ListConfiguration();
        }
        currentSettings.configuration.groups = [];
        RowShare.ListSettings.saveListSettingsToLocalStorage(this.listVM.list!.Id!, currentSettings);
    }

    public clearValueColumns(): void {
        this.execOnceColAndGridApiAreReady((gridApi, columnApi) => {
            let valCol = this.getValueColumns();
            if (valCol) {
                columnApi.removeValueColumns(valCol.map(vc => vc.colId));
            }
            gridApi.refreshHeader();
        });
    }

    public clearVisibleToolPanel(): void {
        this.execOnceGridApiIsReady((gridApi) => {
            gridApi.closeToolPanel();
        });
    }

    public restoreVisibleToolPanel() {
        if (!this.listVM.list) {
            return;
        }
        let settings = RowShare.ListSettings.loadListSettingsFromLocalStorage(this.listVM.list.Id);
        if (settings?.configuration) {
            this.applyVisibleToolPanel(settings.configuration);
        }
    }

    public restoreFilters() {
        if (!this.listVM.list)
            return;

        let settings = RowShare.ListSettings.loadListSettingsFromLocalStorage(this.listVM.list.Id);
        this.displayedRowIds = null;
        this.gridApi?.onFilterChanged();
        setTimeout(() => {
            if (settings.configuration?.filters) {
                this.gridApi?.setFilterModel(settings.configuration.filters);
            }
            if (this.currentQuickFilter) {
                this.gridApi?.setQuickFilter(this.currentQuickFilter);
                EventBus.$emit(RowShare.Event.GRID_SEARCH_CHANGED, <RowShare.GridSearchChangeEventParams>{ searchText: this.currentQuickFilter, saveValue: true, source: "external" });
            }
            this.gridApi?.onFilterChanged();
        }, 0);
    }

    public clearAllSavedFilters() {
        this.displayedRowIds = null;
        this.gridApi?.onFilterChanged();
        EventBus.$emit(RowShare.Event.GRID_SEARCH_CHANGED, <RowShare.GridSearchChangeEventParams>{ searchText: null, saveValue: true, source: "external" });
    }

    public moveColumns(columns: agGrid.Column[], toIndex: number) {
        if (!this.columnApi || !this.gridApi || !this.listVM.list?.Owned || this.printMode || columns.length == 0 || toIndex == 0
            || this.listVM.specialRowsDisplayed) {
            return;
        }

        Api.Column.moveColumns(columns.map(c => c.getColId()), toIndex, this.listVM.list.Id, this.listVM.columns?.length ?? 0, RealTimeCollaborationModule.connection.connectionId)
            .then(savedColumns => {
                if (savedColumns) {
                    savedColumns.forEach(sc => {
                        var colVM = <ColumnVM>this.gridApi?.getColumnDef(sc.Id);
                        if (colVM) {
                            colVM.rsColumn = sc;
                        }
                        var displayModeVM = <ListDisplayModeVM>this;
                        if (displayModeVM.listVM.columns) {
                            var idx = displayModeVM.listVM.columns.findIndex(c => c.Id == sc.Id);
                            displayModeVM.listVM.columns[idx] = sc;
                        }
                    });
                }
            });
        this.gridApi.refreshCells();
        this.gridApi.refreshHeader();
    }

    public togglePinColumn(column: agGrid.Column) {
        if (!column || !this.columnApi || !this.gridApi) {
            return;
        }
        let colState = this.columnApi.getColumnState().find(cs => cs.colId === column.getColId());
        if (!colState) {
            return;
        }

        let pinned = (colState.pinned === true || colState.pinned === 'left');
        this.columnApi.setColumnPinned(column, pinned ? null : 'left');
        this.gridApi.refreshHeader();
    }

    public get totalRowsCount(): number {
        let count = 0;
        this.gridApi?.forEachNode((node: agGrid.IRowNode) => {
            if (!node.group) {
                count++;
            }
        });
        return count;
    }

    public get visibleRowsCount(): number {
        let count = 0;
        if (this.pGridApi) {
            this.pGridApi.forEachNodeAfterFilter((node: agGrid.IRowNode) => {
                if (!node.group) {
                    count++;
                }
            })
        }
        return count;
    }

    public get totalColumnsCount(): number {
        return (this.listVM.columns?.length ?? 0) + (this.listVM.specialRowsDisplayed ? 2 : 0);
    }

    public get visibleColumnsCount(): number {
        if (!this.pColumnApi) {
            return this.totalColumnsCount;
        }
        if (!this.pColumnApi || !this.pColumnApi.getColumnState()) {
            return 0;
        }
        let visiblecolumns = this.pColumnApi.getColumnState().filter(cs => (<any>this.pColumnApi?.getColumn(cs.colId)).colDef.rsColumn && !cs.hide);
        return visiblecolumns.length;
    }

    public get rowGroupsCount(): number {
        return this.rowGroupsColNames?.length ?? 0;
    }

    public get rowGroupsColNames(): string[] {
        let result: string[] = [];
        if (this.pColumnApi) {
            let colGroups = this.pColumnApi.getRowGroupColumns();
            if (colGroups) {
                result = colGroups.map(rgc => (rgc.getColDef().headerName) ?? '').filter(n => n && n != null && n != '');
            }
        }
        return result;
    }

    public setRowGroupColumns(columns: string[]) {
        this.execOnceColAndGridApiAreReady((gridApi, columnApi) => {
            columnApi.setRowGroupColumns(columns);
            setTimeout(() => {
                if (this.listVM?.list?.Id) {
                    var rowGroupWidth = Settings.getColumnProperty<number>(this.listVM.list?.Id, ColumnVM.autoGroupColumnId, 'width') ?? 0;
                    columnApi.setColumnWidth(ColumnVM.autoGroupColumnId, rowGroupWidth > 0 ? rowGroupWidth : 200);
                }
            }, 0);
        });
    }

    public static isExternalFilterPresent(grid: GridViewVM): boolean {
        return grid && grid.displayedRowIds !== null;
    }

    public static doesExternalFilterPass(grid: GridViewVM, node: agGrid.IRowNode): boolean {
        if (node.id && grid.displayedRowIds != null) {
            return (grid.displayedRowIds.indexOf(node.id) > -1) || node.data.isNew;
        }
        return true;
    }

    /** ViewConfig **/

    public getCurrentViewConfig(): RowShare.ListConfiguration | undefined {
        if (!this.gridApi || !this.columnApi)
            return;

        var viewConfig = new RowShare.ListConfiguration();

        let colState = this.columnApi.getColumnState();
        viewConfig.hiddenColumns = colState.filter(s => s.hide).map(s => <string>s.colId);

        viewConfig.filters = this.gridApi.getFilterModel();
        viewConfig.sortOrder = colState.filter(s => s.sort).map(s => {
            return {
                colId: s.colId,
                sort: s.sort,
                sortIndex: s.sortIndex,
            };
        });
        viewConfig.sideBar.rowGroupColumns = this.columnApi.getRowGroupColumns().map(rgc => rgc.getColId());
        let valCol = this.getValueColumns();
        if (valCol) {
            viewConfig.sideBar.valueColumns = valCol;
        }
        let collapsedGroups = this.getCollapsedColumnGroups();
        if (collapsedGroups && collapsedGroups.length > 0) {
            viewConfig.collapsedColumnGroups.push(...collapsedGroups);
        }

        return viewConfig;
    }

    public applyFilters(viewConfig: RowShare.ListConfiguration): void {
        this.execOnceGridApiIsReady((gridApi) => {
            gridApi.setFilterModel(viewConfig.filters);
        });
    }

    public applySort(viewConfig: RowShare.ListConfiguration): void {
        this.execOnceColumnApiIsReady((columnApi) => {
            columnApi.applyColumnState({
                state: viewConfig.sortOrder,
                defaultState: { sort: null },
            });
        });
    }

    public applyHiddenColumns(viewConfig: RowShare.ListConfiguration): void {
        this.execOnceColumnApiIsReady((columnApi) => {
            this.clearHiddenColumns();
            if (viewConfig.hiddenColumns) {
                for (let i = 0; i < viewConfig.hiddenColumns.length; ++i) {
                    const col = columnApi.getColumn(viewConfig.hiddenColumns[i]);
                    if (col)
                        this.hideColumn(col);
                }
            }
        });
    }

    public applyCollapsedColumnGroups(viewConfig: RowShare.ListConfiguration): void {
        this.execOnceColumnApiIsReady((columnApi) => {
            this.clearCollapsedColumnGroups();
            if (viewConfig.collapsedColumnGroups) {
                viewConfig.collapsedColumnGroups.forEach(ccg => {
                    columnApi.setColumnGroupOpened(ccg, false);
                });
            }
        });
    }

    public applyRowGroupColumns(viewConfig: RowShare.ListConfiguration): void {
        this.setRowGroupColumns(viewConfig.sideBar.rowGroupColumns);
    }

    public applyValueColumns(viewConfig: RowShare.ListConfiguration): void {
        this.execOnceColAndGridApiAreReady((gridApi, columnApi) => {
            this.clearValueColumns();
            viewConfig?.sideBar?.valueColumns?.forEach(vc => {
                let col = columnApi.getColumn(vc.colId);
                if (col) {
                    col.setValueActive(true);
                    col.setAggFunc(vc.aggFunction);
                    columnApi.addValueColumn(col);
                }
            });
        });
    }

    public applyVisibleToolPanel(viewConfig: RowShare.ListConfiguration): void {
        this.execOnceGridApiIsReady((gridApi) => {
            this.clearVisibleToolPanel();
            if (viewConfig?.sideBar?.visibleToolPanelId) {
                gridApi.openToolPanel(viewConfig.sideBar.visibleToolPanelId);
            }
        });
    }

    public getCurrentFilters(): any {
        if (!this.gridApi)
            return;

        return this.gridApi.getFilterModel();
    }

    public get isFiltered(): boolean {
        if (!this.gridApi) {
            return false;
        }
        return this.gridApi.isAnyFilterPresent();
    }

    public get isSorted(): boolean {
        if (!this.gridApi) {
            return false;
        }
        return this.columnApi?.getColumnState()?.find(c => c.sort != null) != undefined;
    }

    public getCurrentSort(): agGrid.ColumnState[] | undefined {
        if (!this.columnApi)
            return;

        return this.columnApi.getColumnState()
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return {
                    colId: s.colId,
                    sort: s.sort,
                    sortIndex: s.sortIndex,
                };
            });
    }

    public onColumnRowGroupChanged(event: agGrid.ColumnRowGroupChangedEvent) {
        if (event.columnApi.getRowGroupColumns().length == 1 && event.columnApi.getValueColumns().length === 0) {
            let summableColumns = event.columnApi.getColumns()?.filter((c: agGrid.Column) => {
                let colVM = <ColumnVM>c.getColDef();
                if (colVM?.columnProxy) {
                    return colVM.columnProxy.getEnableAggregate(colVM.rsColumn);
                }
                return false;
            }).map(c => c.getColId());
            if (summableColumns && summableColumns.length > 0) {
                event.columnApi.setValueColumns(summableColumns);
            }
        }
        RowShare.ListSettings.saveRowGroupColumns(this);
        this.viewManager.onColumnRowGroupChanged(event);
        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
    }

    public onRowGroupColumnAdded(colId: string) {
        if (!colId) {
            return;
        }
        this.execOnceColAndGridApiAreReady((gridApi, columnApi) => {
            columnApi.addRowGroupColumn(colId);
            gridApi.refreshHeader();
        });
    }

    public onRowGroupColumnRemoved(colId: string) {
        if (!colId) {
            return;
        }
        this.execOnceColAndGridApiAreReady((gridApi, columnApi) => {
            columnApi.removeRowGroupColumn(colId);
            gridApi.refreshHeader();
            // clearing value columns if no more groups
            if(columnApi.getRowGroupColumns().length === 0) {
                this.clearValueColumns();
            }
        });
    }

    public onColumnValueChanged(event: agGrid.ColumnValueChangedEvent) {
        RowShare.ListSettings.saveValueColumns(this);
    }

    public getRowGroupColumnsIds(): string[] | undefined {
        if (!this.columnApi) {
            return;
        }
        return this.columnApi.getRowGroupColumns().map(c => c.getColId());
    }

    public getValueColumns(): RowShare.valueColumn[] | undefined {
        if (!this.columnApi) {
            return;
        }
        return this.columnApi.getValueColumns().map((vc: agGrid.Column) => {
            let valCol = new RowShare.valueColumn();
            valCol.colId = vc.getColId();
            valCol.aggFunction = vc.getAggFunc();
            return valCol;
        }).filter(vc => vc != undefined && vc.colId);
    }

    public getVisibleToolPanelId(): string | undefined {
        if (!this.gridApi) {
            return;
        }

        let openedToolPanelId = this.gridApi.getOpenedToolPanel();

        if (openedToolPanelId == null) {
            return;
        }

        return openedToolPanelId;
    }

    public flashRows(rowIds: string[], moreRows: boolean) {
        if((rowIds?.length ?? 0) === 0) {
            return;
        }
        this.execOnceGridApiIsReady((gridApi) => {
            const nodes: agGrid.IRowNode[] = [];

            rowIds.forEach((rowId) => {
                let rowNode = gridApi.getRowNode(rowId);
                if(rowNode) {
                    nodes.push(rowNode);
                }
            });
            if(nodes.length > 0) {
                this.scrollRecursive(gridApi, this.getRowNodeIndex(rowIds[0]), {top: -1, bottom: 0});
                setTimeout(() => {
                    gridApi.flashCells({ rowNodes: nodes, flashDelay: 10000, fadeDelay: 5000});
                }, 800);
                if(moreRows) {
                    EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                        duplicateId: 'moreRows',
                        autoHide: true,
                        autoHideAfterMs: 10000,
                        type: RowShare.NotificationType.info,
                        message: i18n.t('Notification_MoreRowsToHighlight').toString()
                    });
                }
            }
        });
    }

    scrollRecursive(gridApi: agGrid.GridApi, index: number, oldScrollPosition: {top: number, bottom: number}) {
        if(!gridApi) {
            return;
        }
        var currentScrollPosition = gridApi.getVerticalPixelRange();
        //the scrolling has settled - old and current scroll position are identical - exit
        if (Math.round(oldScrollPosition.top) === Math.round(currentScrollPosition.top)) {
            return;
        }

        //more scrolling needed - scroll
        setTimeout(() => {
            gridApi.ensureIndexVisible(index, "top");
            this.scrollRecursive(gridApi, index, currentScrollPosition);
        }, 200);
    }


    onColumnPermissionsChanged(columnId: string) {
        if(!columnId) {
            return;
        }
        this.execOnceGridApiIsReady((gridApi) => {
            Api.Column.load(columnId)
                .then(column => {
                    if(column) {
                        let col = (<ColumnVM>gridApi.getColumnDef(columnId));
                        col.rsColumn = column;
                        gridApi.refreshHeader();
                    }
                });
        })
    }
}
