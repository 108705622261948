import { IRequestOptions, ApiUtils } from './ApiUtils';
import { Timezone } from '@/models/Timezone';


export class TimezoneService {
    static loadByCulture(culture : string, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/timezone/loadByCulture/" + culture, options)
            .then(response => response.json<Array<Timezone>>());
    }
}