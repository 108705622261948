
import {Component, Prop } from 'vue-property-decorator';
import { UserModule } from '@/store/UserStore'
import { ListTreeModule } from '@/store/ListTreeStore'
import * as API from '@/api/Api';
import { FormValidation } from '@/views/components/FormValidation';
import InputText from '@/views/components/FormInputs/InputText.vue';
import InputTextArea from '@/views/components/FormInputs/InputTextArea.vue';
import InputPhone from '@/views/components/FormInputs/InputPhone.vue';
import { ValidationObserver } from 'vee-validate';
import { AddOrganization, Organization } from '@/models/RowShare';
import { namespace } from 'vuex-class';
import { TrackingUtils } from '@/utils/Tracking';

@Component({
    name:'Personalization',
    components:{ InputText, InputTextArea, InputPhone, ValidationObserver }
})
export default class Personalization extends FormValidation{

    private enableTags=false;
    private modelOrganization!: AddOrganization;
    private submitting: boolean = false;

    @Prop() isCreateOrg!:string;

    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };

    constructor(){
        super();
        this.modelOrganization = new AddOrganization();
        this.modelOrganization.Name = '';
        this.modelOrganization.MembersArray=[];
        this.modelOrganization.CompanyName = '';
        this.modelOrganization.PhoneNumber = '';
        this.modelOrganization.UserCount = '';
        this.modelOrganization.WhyRowShareAnswer = '';
    }

    async mounted(){
        this.setValidationLanguage(this.$i18n.locale);
        var userName = UserModule.CurrentUser.NickName;
        this.modelOrganization.Name = this.$i18n.t('SignUp_DefaultTeamName', {'0':userName}).toString();
    }

    onSubmit(){
        this.submitting = true;

        //The API receives the members as strings so it's necessary to convert the array of tags
        this.modelOrganization.Members = this.modelOrganization.MembersArray.toString();
        this.modelOrganization.First = this.collectAdminInfos;
        API.Organization.trialSetup(this.modelOrganization).then((organizationResult: Organization | null)=>{
            if(this.isCreateOrg){
                ListTreeModule.refreshListTree().then((res:any)=>{
                    this.$emit("company-created", organizationResult);
                    ListTreeModule.updateCurrentFolder({organizationId: organizationResult?.Id ?? null , folderId: null});
                });
            }
            else{
                //When the organization is created, the user in the store is updated.
                //This is done only when the user creates his/her first organization.
                UserModule.refreshUser().then((res:any)=>{
                    this.$emit("company-created", organizationResult);
                });
                TrackingUtils.addEventDataLayer('signup_finalized');
            }
        })
        .catch(er=>{this.$refs.observer.setErrors({
                        companyName : [this.$i18n.t("ExceptionCode_" + er.code).toString()],
                    })
        });        
    }

    removeMail(item:string) {
        let element = this.modelOrganization.MembersArray.find(elem => elem == item);
        if(element){
            const index= this.modelOrganization.MembersArray.indexOf(element);
            this.modelOrganization.MembersArray.splice(index, 1);
        }
    }

    //This function is to validate the emails that are written in the tag field
    tagValidator(value:any){
        let isValid = true;
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let i=0;
        while(i<value.length && isValid){
            let elem = value[i];
            if(elem.length>0){
                if(!regex.test(String(elem).toLowerCase())){
                    isValid=false;   
                }
            }
            i++;
        }; 
        if(!isValid)return this.$t('SignUp_TagInvalidMessage');
        else return true;
    }

    //When disabling the tag input, the array of mails is reinitiated.
    deleteTags(){
        if(this.enableTags){
            this.modelOrganization.MembersArray=[];
        }
    }

    disableButton(formInvalid:any, emails:any){
        let disable = formInvalid || (this.tagValidator(emails)!=true)
        return disable;
    }

    get collectAdminInfos(): boolean {
        return (UserModule?.CurrentUser?.ManagedOrganizationsCount ?? 0) == 0;
    }
}
