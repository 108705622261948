
    import { Prop, Vue } from "vue-property-decorator";
    import Component from 'vue-class-component';
    import RsTable from '@/views/Table/GridView/RsTable.vue'
    import ListFormView from '@/views/Table/FormView/ListFormView.vue';
    import ListVM from "@/viewModels/Table/listVM";
    import { Route } from "vue-router";

    @Component({
        name: "ListDisplayModeSelector",
        components: { RsTable, ListFormView }
    })
    export default class ListDisplayModeSelector extends Vue  {
        @Prop() private listVM!: ListVM;
        @Prop({default: ''}) private currentRowId!: string;
        @Prop() highlightRows!: string | string[];

        beforeRouteLeave(to: Route, from: Route, next: any) {
            if(this.$refs.grid) {
                (<any>this.$refs.grid).handleBeforeRouteLeave(to, from, next)
            } else {
                next();
            }
        }
    }
