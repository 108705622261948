import { Utils } from "@/utils/Utilities"

    export type LogLevel = "verbose" | "info" | "warning" | "error";

    // tslint:disable-next-line:max-line-length
    export type Component =
        "Other" |
        "Form" |
        "Grid" | "GridColumnResizer" | "GridContentOverflow" | "GridEdit" | "GridSelection" | "GridStickyHeader" |
        "CheckBox" | "CacheBuster" | "Dialog" | "FileUpload" | "EventHub" | "UnhandledError" |
        "RowShare" | "RowShare.Store" | "RowShare.Grid" | "RowShare.Api" | "RowShare.Editor" |
        "LoginPopup"
        /* | string*/;

    export interface ILoggerOptions {
        minimumLevel: LogLevel;
    }

    export interface ILogger {
        isEnabled(): boolean;
        log(component: Component, level: LogLevel, message: any, ...optionalParams: any[]): void;
        clear(): void;
    }

    export class ConsoleLogger implements ILogger {
        static EnabledKeyName = "SoftFluent.ConsoleLogger.enabled";

        private _enabled: boolean | undefined;

        constructor(enabled?: boolean) {
            if (typeof enabled === "boolean") {
                this._enabled = enabled;
            }
        }

        public static enable(value?: boolean) {
            localStorage.setItem(ConsoleLogger.EnabledKeyName, value !== false ? "true" : "false");
        }

        public enable(value?: boolean) {
            this._enabled = value;
        }

        public isEnabled() {
            if (Utils.isBoolean(this._enabled)) {
                return this._enabled;
            }

            let value = localStorage.getItem(ConsoleLogger.EnabledKeyName);
            if (value && (value.toLowerCase() === "1" || value.toLowerCase() === "true")) {
                return true;
            }

            return false;
        }

        public clear() {
            console.clear();
        }

        public log(component: Component, level: LogLevel, message: any, ...optionalParams: any[]) {
            if (!this.isEnabled()) {
                return;
            }

            message = `[${component}] ${message}`;

            switch (level) {
                case "verbose":
                    console.debug.apply(console, [message, optionalParams]);
                    break;

                case "info":
                    console.info.apply(console, [message, optionalParams]);
                    break;

                case "warning":
                    console.warn.apply(console, [message, optionalParams]);
                    break;

                case "error":
                    console.error.apply(console, [message, optionalParams]);
                    break;
            }
        }
    }

    export class SessionStorageLogger implements ILogger {
        static EnabledKeyName = "SoftFluent.SessionStorageLogger.enabled";
        static DataKeyName = "SoftFluent.SessionStorageLogger.data";

        private _enabled: boolean | undefined;

        public static enable(value?: boolean) {
            localStorage.setItem(SessionStorageLogger.EnabledKeyName, value !== false ? "true" : "false");
        }

        public clear() {
            const storage = this.getStorage();
            storage.removeItem(SessionStorageLogger.DataKeyName);
        }

        public static show(index?: number) {
            const json = localStorage.getItem(SessionStorageLogger.DataKeyName);
            if (json) {
                let array = JSON.parse(json);
                if (Utils.isNumber(index)) {
                    console.log(array[index]);
                } else {
                    console.table(array);
                }
            }
        }

        public enable(value?: boolean) {
            this._enabled = value;
        }

        public isEnabled() {
            if (Utils.isBoolean(this._enabled)) {
                return this._enabled;
            }

            let value = localStorage.getItem(SessionStorageLogger.EnabledKeyName);
            if (value && (value.toLowerCase() === "1" || value.toLowerCase() === "true")) {
                return true;
            }

            return false;
        }

        private getStorage() {
            return localStorage;
        }

        private getStackTrace() {
            try {
                const err = new Error();
                return err.stack;
            } catch (ex) {
                return null;
            }
        }

        public log(component: Component, level: LogLevel, message: any, ...optionalParams: any[]) {
            if (!this.isEnabled()) {
                return;
            }

            if (level === "verbose") {
                return;
            }

            let array: any[] = [];

            const storage = this.getStorage();
            const value = storage.getItem(SessionStorageLogger.DataKeyName);
            if (value !== null) {
                array = JSON.parse(value);
            }

            array.push({
                date: new Date(),
                component: component,
                level: level,
                message: message,
                params: optionalParams.map(value => this.coerceParameter(value)),
                stackTrace: this.getStackTrace()
            });
            storage.setItem(SessionStorageLogger.DataKeyName, JSON.stringify(array));
        }

        private coerceParameter(value: any) {
            try {
                JSON.stringify(value);
                return value;
            } catch (ex) {
                return `${typeof value}`;
            }
        }
    }

    export class Logger {
        private static loggers: ILogger[] = [new ConsoleLogger(true), new SessionStorageLogger()];

        private static readonly options: ILoggerOptions = {
            minimumLevel: "warning"
        };

        static getLoggers() {
            return Logger.loggers;
        }

        static log(component: Component, level: LogLevel, message: any, ...optionalParams: any[]): void {
            for (const logger of Logger.loggers) {
                if (Logger.canLog(logger, component, level, message)) {
                    logger.log.apply(logger, [component, level, message, optionalParams]);
                }
            }
        }

        static clear() {
            for (let logger of Logger.loggers) {
                logger.clear();
            }
        }

        private static canLog(logger: ILogger, component: Component, level: LogLevel, message: any) {
            if (Logger.getLogLevel(Logger.options.minimumLevel) > Logger.getLogLevel(level)) {
                return false;
            }

            return true;
        }

        private static getLogLevel(level: LogLevel) {
            switch (level) {
                case "verbose":
                    return 0;
                case "info":
                    return 1;
                case "warning":
                    return 2;
                case "error":
                    return 3;
            }
        }
    }