
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { State, Action, Getter, namespace } from 'vuex-class';
    import { OnPremModule } from '@/store/OnPremStore'
    import { UserModule } from '@/store/UserStore'
    import InputText from '@/views/components/FormInputs/InputText.vue';
    import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
    import * as API from '@/api/Api';
    import * as RowShare from '@/models/RowShare';
    import { DeleteAccountData } from '@/models/RowShare';

    @Component({
        name: "MyAccountDeleteAccountButton", components: { ConfirmationDialog }
    })
    export default class MyAccountSubscriptionList extends Vue  {
        private readonly FreeOrgWarningStep = 0;
        private readonly PaidOrgWarningStep = 1;
        private readonly OwnOrgWarningStep = 2;
        private readonly LastWarningStep = 3;

        private isDeletingAccount = false;
        private isCannotDeleteAccountModalOpen = false;
        private isDeleteAccountModalOpen = false;
        private userRemovalErrorMessage: string | null = null;

        private hasConfirmedFreeOrgWarning = false;
        private hasConfirmedPaidOrgWarning = false;
        private hasConfirmedOwnOrgWarning = false;

        get currentDeleteAccountModalSate() : number {
            if(!this.deleteAccountData)
                throw new Error("Missing delete account data");

            if(this.deleteAccountData.FreeOrgWithOtherAdmins && this.deleteAccountData.FreeOrgWithOtherAdmins.length > 0 && !this.hasConfirmedFreeOrgWarning)
                return this.FreeOrgWarningStep;

            if(this.deleteAccountData.PaidOrgWithOtherAdmins && this.deleteAccountData.PaidOrgWithOtherAdmins.length > 0 && !this.hasConfirmedPaidOrgWarning)
                return this.PaidOrgWarningStep;

            if(this.deleteAccountData.OwnOrgWithNoMember && !this.hasConfirmedOwnOrgWarning)
                return this.OwnOrgWarningStep;

            return this.LastWarningStep;
        }

        private deleteAccountData: DeleteAccountData | null = null;
        
        async deleteUser(){
            try{
                let redirectUrl = await UserModule.deleteUserProfile();
                if (redirectUrl) {
                    if (redirectUrl.charAt(0) == "~")
                        redirectUrl = redirectUrl.substr(1);

                    window.location.href = redirectUrl;
                }
            }
            catch(error: any){               
                let errorMessage = this.$i18n.t("ExceptionCode_" + error.code).toString();
                this.userRemovalErrorMessage = errorMessage;
            }
        }

        async checkCanDeleteAccount(){
            this.deleteAccountData = await API.User.canCurrentUserdeleteAccount();
            if(this.deleteAccountData?.CanDeleteAccount)
                this.isDeleteAccountModalOpen = true;
            else
                this.isCannotDeleteAccountModalOpen = true;
        }

        protected get getDeleteOwnOrgWithNoMemberWarningText() {
            if(!this.deleteAccountData?.OwnOrgWithNoMember)
                return "";

            return this.$i18n.t('MyAccount_DeleteSubscriptionModal_DeleteOwnOrgWithNoMemberWarning', {
                'TableCount': this.deleteAccountData?.OwnOrgWithNoMember.ListCount,
                'SubscriptionName': this.deleteAccountData?.OwnOrgWithNoMember.Name,
            }).toString();
        }

        protected get getCannotDeleteAccountModalDescription() {
            if(!this.deleteAccountData?.OrgPreventingDeletion)
                return "";

            return this.$i18n.t(this.deleteAccountData.OrgPreventingDeletion.length > 1 ? 'MyAccount_CannotDeleteAccountModal_ManyOrgsDescription' : 'MyAccount_CannotDeleteAccountModal_SingleOrgDescription');
        }

        protected get getDeleteAccountModalFreeOrgAdminWarning() {
            if(!this.deleteAccountData?.FreeOrgWithOtherAdmins)
                return "";

            return this.$i18n.t(this.deleteAccountData.FreeOrgWithOtherAdmins.length > 1 ? 'MyAccount_DeleteAccountModal_ManyFreeOrgAdminWarning' : 'MyAccount_DeleteAccountModal_SingleFreeOrgAdminWarning');
        }

        protected get getDeleteAccountModalPaidOrgAdminWarning() {
            if(!this.deleteAccountData?.PaidOrgWithOtherAdmins)
                return "";

            return this.$i18n.t(this.deleteAccountData.PaidOrgWithOtherAdmins.length > 1 ? 'MyAccount_DeleteAccountModal_ManyPaidOrgAdminWarning' : 'MyAccount_DeleteAccountModal_SinglePaidOrgAdminWarning');
        }

        ConfirmDeleteAccountStep(){
            switch(this.currentDeleteAccountModalSate){
                case this.FreeOrgWarningStep:
                    this.hasConfirmedFreeOrgWarning = true;
                    break;
                case this.PaidOrgWarningStep:
                    this.hasConfirmedPaidOrgWarning = true;
                    break;
                case this.OwnOrgWarningStep:
                    this.hasConfirmedOwnOrgWarning = true;
                    break;
                case this.LastWarningStep:
                    this.isDeletingAccount = true;
                    this.deleteUser();
                    break;
            }
        }

        closeAndResetAllModals(){
            this.isCannotDeleteAccountModalOpen = false;
            this.isDeleteAccountModalOpen = false;
            this.hasConfirmedFreeOrgWarning = false;
            this.hasConfirmedPaidOrgWarning = false;
            this.hasConfirmedOwnOrgWarning = false;
        }

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;;
        }
    }
