
import {Vue, Component, Prop} from 'vue-property-decorator';
import {Editor, EditorContent, EditorOptions } from "@tiptap/vue-2";
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import { KeyCodes } from '@/utils/KeyCodes';

@Component({name: "RichTextField", components: { EditorContent }})
export default class RichTextField extends Vue {
    @Prop({default: null}) value!: string | null;
    @Prop({default: false}) autofocus!: boolean;

    editor: any = null;

    // using an initializer to handle explicit external changes in the value (ex: row reloaded)
    init() {
        if(this.value == null)
            return;

        if(this.editor != null) {
            this.editor.commands.setContent(this.value, false)
            return;
        }

        // using editor new to pass content instead of set content improve the ctrl + z behavior 
        //this.editor.commands.setContent(val, false);
        var editorOptions = <Partial<EditorOptions>>{
            extensions: [
                StarterKit,
                Underline,
            ],
            content: this.value,
            onUpdate: () => this.$emit('input', this.editor.getHTML()),
            onBlur: () => this.$emit('blur'),
            editorProps : {
                handleKeyDown: (view: any, event: KeyboardEvent) => this.onKeydown(view, event)
            }
        };
        if(this.autofocus)
            editorOptions.autofocus = 'end';

        this.editor = new Editor(editorOptions);
    }

    beforeDestroy() {
        if(this.editor)
            this.editor.destroy();
    }

    onKeydown(editorView: any, event: KeyboardEvent) : boolean{
        if((event.ctrlKey || event.metaKey) && event.keyCode == KeyCodes.DOM_VK_RETURN) {
            return true;
        }
        return false;
    }

}
