import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ApplicationModule } from '@/store/ApplicationStore'
import * as Models from '@/models/RowShare';
import * as API from "@/api/Api";
import { EventBus } from "@/modules/EventBus";
import ListVM from '@/viewModels/Table/listVM';
import RowVM from '@/viewModels/Table/rowVM';
import RsColumnManager from '@/columnProxies/RsColumnManager';

type FileOrBlob = File | FileList | Blob | null;

@Component
export class FormEditor extends Vue {

    @Prop() rowVM !: RowVM;
    @Prop() column !: Models.Column;
    @Prop() listVM !: ListVM;
    @Prop() focus !: boolean;
    @Prop({default: false}) isInSurveyMode !: boolean;

    protected isoName : string = 'USD';
    protected showTooltip : boolean = false;
    protected culture : string = 'en';

    get columnDefaultValue() : string {
        if(!this.column.DefaultValue) {
            return "";
        }
        return this.column.DefaultValue;
    }

    async beforeMount(){
        let lang : string = this.listVM?.list ? this.listVM.list.Language : 'en';
        this.culture = lang;
        let currency = await API.Currency.loadByCulture(lang);
        this.isoName = currency ? currency.IsoName : 'USD';
    }

    mounted(){
        (<any>this).init();
    }
    
    @Watch("rowVM")
    onRowVMChanged(){
        (<any>this).init();
    }

    @Watch("rowVM.rsRow")
    onrsRowChanged(){
        (<any>this).init();
    }
    
    /*@Watch('mobileListStore.editorState')
    onStateChanged(val: string, oldVal: string) {
        if((val == 'edit' || val == 'create') && this.focus){
            let editor = <HTMLElement>this.$refs.editor;
            editor.focus();
        }
    }*/

    isMandatoryColumn() : boolean {
        return this.column.isMandatory;
    }
    
    isFormulaColumn() : boolean {
        return this.column.isFormula;
    }

    isReadOnlyColumn(): boolean {
        return this.column.IsReadOnly || this.column.isChildColumn;
    }

    async saveCellValue(){
        if(this.isFormulaColumn())
            return;
            
        const proxy  = RsColumnManager.getProxy(this.column.Type);
        let oldValue = this.rowVM.getValue(this.column, proxy);

        var newValue = (<any>this).getValue();

        this.rowVM.setValue(this.column, newValue);
        if(!this.isInSurveyMode)
            this.rowVM.cellValueChanged(this.column, newValue, oldValue);
    }

    getFileSize(file: FileOrBlob): number {

        let fileAsFile = file as File;
        let fileAsBlob = file as Blob
        let fileAsList = file as FileList;
        let fileSize : number | undefined = 0;

        if (file){
            if (fileAsFile){
                fileSize = fileAsFile.size;
            }
            else if (fileAsBlob){
                fileSize = fileAsBlob.size;
            }
            else if (fileAsList){
                for (let index = 0; index < fileAsList.length; index++) {
                    fileSize = fileAsList.item(index) && fileAsList.item(index)?.size != undefined ? 
                                    fileAsList.item(index)?.size : 0;
                }
            }
        }
        return fileSize != undefined ? fileSize : 0;
    }
}