
    import {Vue, Component, Prop } from 'vue-property-decorator';
    import * as API from '@/api/Api';
    import * as Models from '@/models/RowShare';
    import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
    import BackOfficeUser from './BackOfficeUser.vue';
    import Bigram from "@/views/components/Bigram.vue";
    import moment from 'moment';

    @Component({
        name: 'ListAccessAudit',
        components: {PageLoadSpinner, Bigram, BackOfficeUser}
    })
    export default class ListAccessAudit extends Vue{
        search: string | null = null;
        userSearchResults: Models.BackOfficeUser[] | null = null;
        orgSearchResults: Models.BackOfficeOrganizationSummary[] | null = null;
        listSearchResults: Models.BackOfficeListSummary[] | null = null;
        allSearchResults: Models.BackOfficeSearchSummary | null = null;

        mounted() {
            this.$nextTick(() => {
                (this.$refs.searchField as any).$el.querySelector('input').focus();
        });
  }
        clearAllSearchResults(){
            this.userSearchResults = null;
            this.orgSearchResults = null;
            this.listSearchResults = null;
            this.allSearchResults = null;
        }

        async searchUsers() {
            this.clearAllSearchResults();
            if(this.search == null)
                return;

            this.userSearchResults = await API.BoApi.searchUsers(this.search);
        }

        async searchOrganizations() {
            this.clearAllSearchResults();
            if(this.search == null)
                return;

            this.orgSearchResults = await API.BoApi.searchOrganizations(this.search);
        }

        async searchTables(){
            this.clearAllSearchResults();
            if(this.search == null)
                return;
            
            this.listSearchResults = await API.BoApi.searchLists(this.search);
        }

        async searchAll() {
            this.clearAllSearchResults();
            if(this.search == null) {
                return;
            }
            
            this.allSearchResults = await API.BoApi.search(this.search);
            if(this.allSearchResults) {
                this.userSearchResults = this.allSearchResults.Users;
                this.orgSearchResults = this.allSearchResults.Organizations;
                this.listSearchResults = this.allSearchResults.Lists;
            }
        }
        
        formatUserCreationData(user: Models.User){
            var from = moment(user.CreationDate, undefined, 'en').fromNow();
            var exact = moment(String(user.CreationDate), undefined, 'en').format("YYYY-MM-DD HH:mm");

            return from + " (" + exact + ")";
        }

        onUserUpdated(user: Models.User) {
            if(!this.userSearchResults)
                return;

            var currentIdx = this.userSearchResults.findIndex(u => u.Email == user.Email);
            if(currentIdx >= 0)
                Vue.set(this.userSearchResults, currentIdx, user);
        }

        onUserDeleted(user: Models.User) {
            if(!this.userSearchResults)
                return;

            var currentIdx = this.userSearchResults.findIndex(u => u.Email == user.Email);
            if(currentIdx >= 0)
                this.userSearchResults.splice(currentIdx, 1);
        }
    }
