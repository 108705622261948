
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as API from '@/api/Api';
import { EventBus } from '@/modules/EventBus';
import * as Models from '@/models/RowShare';
import { Utils } from '@/utils/Utilities';
import { UserModule } from '@/store/UserStore';
import { DateTime } from '@/utils/Date';
import linkifyStr from 'linkify-string';
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

@Component({name: "CommentEditorDialog"})
export default class CommentEditorDialog extends Vue {
    commentsList: Array<commentVM> = [];
    rsRow!: Models.Row;
    rsColumn!: Models.Column;
    rsList!: Models.List;
    showMe: boolean = false;
    loadingComments: boolean = false;
    message: string = "";
    confirmDelete: boolean = false;
    currentUser: Models.User | null = null;
    scrollCommentsToBottom: boolean = false;
    refreshParentRow!: () => void;

    created() {
        EventBus.$on(Models.Event.ROW_EDIT_COMMENTS, (params : Models.rowEditCommentsEventParams) => this.init(params))
    }
    
    updated() {
        if(this.scrollCommentsToBottom) {
            let commContainer = this.$refs.commentContainer as HTMLElement;
            if(commContainer){
                commContainer.scrollTop = 9999;
            }
            this.scrollCommentsToBottom = false;            
        }
        if(this.showMe) {
            this.$nextTick(() => {
                (<HTMLElement>this.$refs.commentInput)?.focus();
            });
        }
    }
    getCommentContent(content: string) {
        return linkifyStr(content, {target: '_blank'});
    }

    async init(params: Models.rowEditCommentsEventParams){
        this.currentUser = UserModule.connectedUser;
        this.rsRow = params.row
        this.rsColumn = params.column;
        this.rsList = params.list;
        this.refreshParentRow = params.refreshParentRow;
        
        if(this.rsRow && this.rsColumn) {
            this.loadingComments = true;
            this.showMe = true;
            let list = await API.Row.loadRowComments(this.rsRow.Id, this.rsColumn.Index)
            if(list){
                list.map(comm => this.commentsList.push(new commentVM(comm)));
            }
            this.scrollCommentsToBottom = true;
            this.loadingComments = false;
        }
    }

    closeModal() {
        this.showMe = false;
    }

    @Watch('showMe')
    onToggleVisibility() {
        this.commentsList = [];
        if(this.$vuetify.breakpoint.mobile) {
            let intercomBtn = (<HTMLElement>document.getElementsByClassName('intercom-launcher')?.item(0));
            if(intercomBtn) {
                this.showMe ? intercomBtn.style.display = 'none' : intercomBtn.style.display = 'unset';
            }
        }
    }

    get commentInputLabel() {
        return this.$i18n.t('CommentDialog_CommentInputLabel').toString();
    }

    async addComment() {
        if(!Utils.isNullOrWhiteSpace(this.message)) {
            let newComment = await API.Row.saveRowComment(this.rsRow.Id, this.rsColumn.Index, this.message, RealTimeCollaborationModule.connection.connectionId);
            if(newComment) {
                this.commentsList?.push(new commentVM(newComment));
                this.message = "";
                this.scrollCommentsToBottom = true;
                if(this.refreshParentRow != undefined){
                    this.refreshParentRow();
                }
            }
        }
    }


    onKeydown(event: KeyboardEvent){
        if((event.ctrlKey || event.metaKey) && event.keyCode == 13) {
            this.addComment();
        }
    }

    requireDelete(comm: commentVM) {
        comm.deletionRequired = true;
    }

    cancelDeletion(comm: commentVM){
        comm.deletionRequired = false;
    }

    deleteComment(comm: commentVM) {
        comm.comment.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
        API.Row.deleteRowComment(comm.comment).then(deletedComment => {
            if(deletedComment) {
                let index = this.commentsList.indexOf(comm);
                if(index > -1) {
                    this.commentsList.splice(index, 1);
                    if(this.refreshParentRow != undefined){
                        this.refreshParentRow();
                    }
                }
            }
        })
    }

    get canComment() {
        if(this.currentUser && !this.currentUser.IsAnonymous){
            return this.rsList.Owned || !this.rsColumn.IsReadOnly;
        }
        return false;
    }

    getCreatedDate(date: string) {
        let creationDate = DateTime.parseDateTime(date, null);
        if(creationDate) {
            return creationDate.toLocaleString(navigator.language);
        }
    }
}

class commentVM {
    comment!: Models.CommentDetails;
    deletionRequired: boolean = false;

    constructor(comment: Models.CommentDetails){
        this.comment = comment;
    }
}
