import { Utils } from "@/utils/Utilities";

export class User {
 
    public Email!: string;
    public NickName!: string;
    public FirstName?: string;
    public LastName?: string;
    public HasToConfirmEmail!: boolean;
    public RemainingHoursToConfirmEmail!: number;
    public IsAnonymous!: boolean;
    public IsExcludedFromAnalytics!: boolean;
    public IsAdministrator!: boolean;
    public RootFolderId!: string;
    public TimeZoneId!: string;
    public CreationDate!: Date;
    public LastLoginDate!: Date;
    public LastLoginProvider!: string;
    public Options?: UserOptions;
    public Owner?: Owner;
    public DefaultOrgId!: string;
    public Identity!: string;
    public Lcid!: string;
    public Language!: string | null;
    public PreviewFeaturesEnabled!: boolean;
    public EmailNotifications!: boolean;
    public OrganizationsCount!: number;
    public ManagedOrganizationsCount!: number;
    public Features!: string[];
    public IsDemoUser!: boolean;
    public CreatedFromMsTeams!: boolean;
}

export class UserUnsubscription{
    public userId!:string;
    public Email!:string;
}

export class SignupUser {
    public Email!: string;
    public FirstName!: string;
    public LastName!: string;
    public Password!: string;
    public AutoLogon!: boolean;
    public RecaptchaToken!: string;
    public RecaptchaTokenIsV3: boolean | null = true;
}

export class SigninUser {
    public Email!: string;
    public Password!: string;
    public RecaptchaToken!: string;
    public RecaptchaTokenIsV3: boolean | null = true;
}

export class ResetPasswordRequest{
    public Email!:string;
    public RecaptchaToken!: string;
    public RecaptchaTokenIsV3: boolean | null = true;
}

export class ResetPassword{
    public Password!:string;
    public ConfirmPassword!:string;
    public Token!:string;
}

export class Owner {
    public BackgroundColorIndex!: number | null;
    public Bigram!: string | null;
    public Name!: string | null;
    public Email!: string | null;
    public Type!: OwnerType;
    public Id!: string | null;
    public IconPath!: string | null;
    public DisplayName!: string;

}

export const enum UserOptions {
    None = 0,
    CannotBeContacted = 1,
    NoEmailNotifications = 2,
    NotSubscribedToNewsletter = 4,
    DontSupportHtmlMail = 8,
    SubscribedToPress = 16,
    WebDavEnabled = 32,
    AutoGeneratedNickName = 64,
    Internal = 128,
    PreviewFeaturesEnabled = 256,
}

export const enum OwnerType {
    UserUnknown = 0,
    UserWithNoAccount = 1,
    UserWithNoNickName = 2,
    User = 3,
    Member = 4,
    DisabledMember = 5,
    SurveyUser = 6,
    Group = 7,
    Team = 8,
    MyOrganization = 9,
}