
    import {Vue, Component, Prop } from 'vue-property-decorator';
    import * as Models from '@/models/RowShare';
    import * as API from "@/api/Api";
    import RowVM from '@/viewModels/Table/rowVM';
    import CommentCardRenderer from '@/views/Table/cellsComponents/Comment/CommentCardRenderer.vue';
    import TwoStatesBooleanCardRenderer from '@/views/Table/cellsComponents/TwoStateBoolean/TwoStatesBooleanCardRenderer.vue'
    import ProxyBasedCardRenderer from '@/views/Table/CardView/ProxyBasedCardRenderer.vue';
    import FileVM from '@/viewModels/Table/FileVM';

    @Component({
        name: 'RowCardMobile',
        components: { ProxyBasedCardRenderer, CommentCardRenderer, TwoStatesBooleanCardRenderer }
    })
    export default class RowCardMobile extends Vue {

        @Prop() rowVM!: RowVM;
        @Prop() currentRowId!: string;
        @Prop({default: false}) selectionMode!: boolean;

        private isoName : string = 'USD';

        get currentList() : Models.List | null {
            return this.rowVM.listVM.list;
        }

        get isCurrentRow(): boolean {
            if(this.currentRowId === 'new' && !this.rowVM?.rsRow?.Id) {
                return true;
            }
            if(!this.rowVM?.rsRow.Id || !this.currentRowId)
            {
                return false;
            }
            return this.rowVM.rsRow.Id === this.currentRowId;
        }

        async mounted(){
            let currency = await API.Currency.loadByCulture(this.currentList ? this.currentList.Language : 'en');
            this.isoName = currency ? currency.IsoName : 'USD';
        }

        get imageColumn() : Models.Column | null {
            let columns = this.rowVM.listVM.columns;
            if(!columns){
                return null;
            }
            return columns.find(col => col.Type == Models.ColumnStrongType.Image) ?? null;
        }

        get imageColumnUrl() : string | null {
            var col = this.imageColumn;
            if(!col)
                return null;
            
            var val = this.rowVM.rsRow.Values[col.Name]
            
            return val ? val.AbsoluteFileUrl : null;
        }

        get hasImageColumn() : boolean {
            return this.imageColumn != null;
        }

        get showOwnerInformation(): boolean {
            return this.currentList ? this.currentList.ShowOwnerInformations : false;
        }

        get mainColumn() : Models.Column | null {
            let columns = this.rowVM.listVM.columns;
            if(!columns){
                return new Models.Column();
            }
            let mainColumn = columns.find(col => col.IsMainColumn);
            return mainColumn ? mainColumn : new Models.Column();
        }
        
        get isMainColumnAFile() : boolean {
            var mainColumn = this.mainColumn;
            return mainColumn != null && (mainColumn.Type == Models.ColumnStrongType.Image || mainColumn.Type == Models.ColumnStrongType.File);
        }

        get imageColumnValue(): FileVM | null {
            let imageValue = this.imageColumn ? 
                                <FileVM>this.rowVM.rsRow.Values[this.imageColumn.Name]:
                                null;
            if (this.hasImageColumn && !imageValue) {
                let defaultImage = new FileVM();
                defaultImage.ContentType = 'image/jpeg';
                defaultImage.FileName = 'missing-image.png';
                defaultImage.ImageUrl = require('@/assets/static/images/image-placeholder.jpg');
                defaultImage.AbsoluteFileUrl = require('@/assets/static/images/image-placeholder-big.jpg');
                defaultImage.Size = 634001;
                defaultImage.LastWriteTimeUtc = new Date();
                return defaultImage;
            }
            else if (this.hasImageColumn && imageValue) {
                return imageValue;
            }
            else {
                return null;
            }
        }

        get cols() : Array<Models.Column>{
            let columns = this.rowVM.listVM.columns;
            if(!columns)
                return new Array<Models.Column>();
            
            return this.hasImageColumn
                ? columns.filter(col => !col.IsMainColumn && col != this.imageColumn).slice(0,4)
                : columns.filter(col => !col.IsMainColumn).slice(0,6);
        }
        
        rowDetail(): void {
            if(!this.isCurrentRow || this.$route.name ==='Table'){
                this.$emit('cardClick', this.rowVM);
            }
        }
        
        rowDoubleClick(): void {
            this.$emit('cardDblClick', this.rowVM);
        }

        columnRendererName(column : Models.Column){
            switch(column.Type){
                case Models.ColumnStrongType.Comment:
                    return 'CommentCardRenderer';
                case Models.ColumnStrongType.TwoStatesBoolean:
                    return 'TwoStatesBooleanCardRenderer';
                default:
                    return 'ProxyBasedCardRenderer';
            }
        }

        isCellAccessible(column: Models.Column) {
            return this.rowVM?.rsRow?.Values[column.DisplayName] !== RowVM.cellNotAccessible;
        }
    }
