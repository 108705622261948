import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";

export default class TotalRowRenderer implements ICellRendererComp {
    cellContainer!: HTMLElement;

    init(params: ICellRendererParams){
        this.cellContainer = document.createElement("div");
        this.cellContainer.classList.add("total-row-renderer");
        this.cellContainer.innerText = params.value;
    }

    getGui(): HTMLElement {
        return this.cellContainer;
    }

    refresh(params: ICellRendererParams): boolean {
        this.cellContainer.innerText = params.value;
        return true;
    }
}