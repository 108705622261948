import * as RowShare from '@/models/RowShare';
import BaseColumnProxy from '../../columnProxies/BaseColumnProxy';
import ColumnVM from './columnVM'
import GridViewVM from './gridViewVM'
import rowBaseVM from './rowBaseVM';

export default class RowTotalVM extends rowBaseVM {
    gridViewVM: GridViewVM;

    constructor(gridViewVM: GridViewVM){
        super();
        this.gridViewVM = gridViewVM;
    }

    public getValue(rsColumn: RowShare.Column, columnProxy: BaseColumnProxy) {
        if(!this.gridViewVM?.gridApi)
            return null;
            
        if(!columnProxy.canShowTotal(rsColumn))
            return null;

        let total = 0, rowCount = 0;
        this.gridViewVM.gridApi.forEachNodeAfterFilter(rowNode => {
            total = columnProxy.addToTotal(total, rowNode.data, rsColumn);
            rowCount++;
        });
        return columnProxy.formatTotal(total, rowCount, rsColumn);
    }


    public get isTotalRow() { return true; }

    /** Replicating some rowVM functions to let the renderer works with this custom row **/
    public getAssignation(){ return null; }
    public hasCellError(rsColumn: RowShare.Column) { return false; }
    public getCellError(rsColumn: RowShare.Column) : string | null { return null; }
    public isSavingCell(rsColumn: RowShare.Column) { return false; }
}