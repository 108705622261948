import * as Models from '@/models/RowShare';

export class gridUtils{
    public static stringComparator(s1: string | null | undefined, s2: string | null | undefined) : number{
        let string1 = (s1 ?? '');
        let string2 = (s2 ?? '');

        return string1.localeCompare(string2);
    }

    public static memberComparator(m1: Models.Member, m2: Models.Member): number{
        return gridUtils.stringComparator(m1?.Name, m2?.Name);
    }

}