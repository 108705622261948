
import { Component, Vue } from 'vue-property-decorator';
import { ICellEditorParams } from 'ag-grid-community';
import ColumnVM from '@/viewModels/Table/columnVM';
import LookupField from '@/views/Table/cellsComponents/Lookup/LookupField.vue'

@Component({ name: "LookupEditor", components: { LookupField } })
export default class LookupEditor extends Vue{
    params!: ICellEditorParams;

    get lookupfield() : LookupField {
        return <LookupField>this.$refs.lookupfield;
    }

    mounted(){
        this.refreshDisplay();
    }

    async refreshDisplay() {
        this.lookupfield.init();
        setTimeout(() => {
            this.lookupfield.activate();
        }, 0);
    }

    isPopup() {    
        return true;
    }

    getValue(){
        return this.lookupfield.getValue();
    }

    onDropdownClosed(){
        if(!this.lookupfield.isLookupMultiValued) {
            setTimeout(() => {this.params.stopEditing()}, 0);
        }
    }

    get minWidth(){
        return this.params.column.getActualWidth();
    }
    
    cancel() {
        setTimeout(() => {
            this.params.api?.stopEditing(true);
            this.params.api?.setFocusedCell(this.params.rowIndex, this.params.column);
        }, 0);
    }

    onEnterPressed() {
        // set timeout is required because vue-multiselect need to be able to select the value before the editor is destroyed
        if(!this.lookupfield.isLookupMultiValued) {
            setTimeout(() => {
                this.params.api?.stopEditing();
                this.params.api?.setFocusedCell(this.params.rowIndex, this.params.column);
            }, 0);
        }
    }

    onCtrlEnterPressed(){
        // no set timeout here, strl enter should only close the lookup
        this.params.api?.stopEditing();
        this.params.api?.setFocusedCell(this.params.rowIndex, this.params.column);
    }

    onTabPressed(){
        this.params.api?.tabToNextCell();
    }

    onShiftTabPressed(){
        this.params.api?.tabToPreviousCell();
    }
}
