import * as RowShare from '@/models/RowShare';
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import i18n from '@/modules/Localization';
import { Hyperlink } from "@/models/Hyperlink";

export default class HyperLinkColumnProxy extends BaseColumnProxy {
    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        let link = new Hyperlink(val);
        return link.displayName ? link.displayName : link.url;
    }

    getFormattedHtmlTooltip(rsColumn: RowShare.Column, rowVM: RowVM, val: any){
        let link = new Hyperlink(val);
        if(link.url){
            return `<span>${link.displayName}<br/>${i18n.t("HyperlinkRenderer_Tooltip").toString()}</span>`
        }
        else {
            return link.displayName;
        }
    }

    getFormattedHtml(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        if(!rsColumn || val === undefined || val === null){
            return "";
        }
        let link = new Hyperlink(val);

        var cellContent = `
            <div class="hyperlink-container">
                <div class="hyperlink-value">
                    <span>${link.displayName ? link.displayName : link.url}</span>
                </div>
        `;

        if(link.url) {
            cellContent +=
        `
                <div class="hyperlink-overlay">
                    <a href="${link.url.startsWith('http') ? link.url:'http://' + link.url}" hyperlink-link target="_blank">
                        <i class='v-icon notranslate fa fa-external-link theme--light'></i>
                    </a>
                </div>
        `;
        }

        cellContent += `
            </div>`;
        
        return cellContent;
    }

    getCellIconClasses(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        if(val != null && val != undefined && val != "")
        {
            return ['fal', 'fa-external-link'];
        }        
        return null;
    }

    isAlwaysEditable() {
        return true;
    }

    /** Clipboard **/
    getClipboardValue(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        let link = new Hyperlink(val);
        let result = link?.url ?? "";
        if(link.displayName) {
            result += '|' + link.displayName;
        }
        return result;
    }
}