import { render, staticRenderFns } from "./SelectedRowsBar.vue?vue&type=template&id=32cfc28f&scoped=true&"
import script from "./SelectedRowsBar.vue?vue&type=script&lang=ts&"
export * from "./SelectedRowsBar.vue?vue&type=script&lang=ts&"
import style0 from "./SelectedRowsBar.vue?vue&type=style&index=0&id=32cfc28f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32cfc28f",
  null
  
)

export default component.exports