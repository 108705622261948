
import { Prop, Vue, Watch } from "vue-property-decorator";
import Component from 'vue-class-component';
import { EventBus } from "@/modules/EventBus";
import * as RowShare from '@/models/RowShare';
import { Route } from 'vue-router';
import * as API from '@/api/Api';
// Components
import ListToolbar from '@/views/Table/ListActions/ListToolbar.vue';
import SharingBar from '@/views/Table/ShareTable/SharingBar.vue';
import SelectedRowsBar from '@/views/Table/ListActions/SelectedRowsBar.vue';
import RowshareGrid from '@/views/Table/GridView/RowshareGrid.vue';
import Toaster from '@/views/layouts/LayoutParts/Toaster.vue';
import { ListTreeModule } from '@/store/ListTreeStore';
import CommentEditorDialog from '@/views/Table/CommentEditorDialog.vue';
import * as agGrid from 'ag-grid-community';
import AddColumnDialog from '@/views/Table/AddColumnDialog.vue'
import GridViewVM from "@/viewModels/Table/gridViewVM";
import RowMergeDialog from '@/views/Table/ListActions/RowMergeDialog.vue';
import { UserModule } from "@/store/UserStore";
import ListVM from "@/viewModels/Table/listVM";
import chartConfigurationDialog from '@/views/charts/chartConfigurationDialog.vue';
import chartDialog from '@/views/charts/chartDialog.vue';
import RelationEditorDialog from '@/views/Table/cellsComponents/Relation/RelationEditorDialog.vue';
import AssignationDialog from "@/views/Table/ListActions/AssignationDialog.vue";
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore"
import Bigram from "@/views/components/Bigram.vue";


@Component({
    name: "RsTable",
    components: { ListToolbar, SharingBar, RowshareGrid, Toaster, CommentEditorDialog,
        SelectedRowsBar, AddColumnDialog, RowMergeDialog, AssignationDialog,
        chartConfigurationDialog, chartDialog, RelationEditorDialog, Bigram }
})
export default class RsTable extends Vue  {
    @Prop() listVM!: ListVM;
    @Prop() highlightRows!: string | string[];
    private selectedRows: agGrid.IRowNode[] = [];

    gridViewVM !: GridViewVM;

    created(){
        this.gridViewVM = new GridViewVM(this.listVM)
        EventBus.$on(RowShare.Event.ROW_SELECTION_CHANGED, (params: agGrid.SelectionChangedEvent) => this.OnRowSelectionChanged(params));
        window.addEventListener('beforeunload', this.beforeUnload);
        window.addEventListener('unload', this.unload);
    }

    destroyed() {
        window.removeEventListener('beforeunload', this.beforeUnload);
        window.removeEventListener('unload', this.unload);
        EventBus.$off(RowShare.Event.ROW_SELECTION_CHANGED);
        this.gridViewVM.dispose();
    }

    get rtcUsers(): RowShare.RealTimeCollaborationEntity[] {
        return RealTimeCollaborationModule.ConnectedUsers;
    }

    get hasRtcViewers(): boolean {
        return (this.rtcUsers?.length ?? 0) > 0;
    }

    addColumn() {
        EventBus.$emit(RowShare.Event.ADD_COLUMN, <RowShare.AddColumnEventParams>{insertBeforeColumnId: null});
    }

    onAssignRowsDialogClosed() {
        this.gridViewVM?.deselectAllRows();
    }

    OnRowSelectionChanged(params: agGrid.SelectionChangedEvent){
        var tmpSelectedRows: agGrid.IRowNode[] = [];
        params.api.clearRangeSelection();
        params.api.forEachNodeAfterFilterAndSort(n => {
            if(!n.group && n.isSelected()) {
                tmpSelectedRows.push(n);
            }
        });
        this.selectedRows = tmpSelectedRows;

    }

    get currentList() : RowShare.List | null {
        return ListTreeModule.currentList;
    }

    get currentOrganization() : RowShare.ListTreeOrganization | null {
        return ListTreeModule.currentOrganization;
    }

    get canShowSharingBar() : boolean {
        return this.selectedRows.length == 0
            && this.currentList != undefined
            && this.currentList != null
            && this.listVM.list != null
            && this.listVM.list != undefined;
    }

    get hasSelectedRows(): boolean {
        return this.selectedRows.length > 0;
    }

    @Watch('currentList')
    onCurrentListChanged(){
        if(this.currentList) {
            document.title = this.currentList.DisplayName + " | RowShare";
            this.listVM.list = this.currentList;
            if(UserModule.CurrentUser != null && !UserModule.CurrentUser.IsAnonymous) {
                this.listVM.checkLimits();
                // note: added by Soukayan
                // RealTimeCollaborationModule.gridViewVM = this.gridViewVM; // signalR injection for update grid view after receiving row cell update event
                RealTimeCollaborationModule.startAndSubscribe();
            }
        } else {
            document.title = "RowShare";
        }
    }

    handleBeforeRouteLeave(to: Route, from: Route, next: any) {
        if((this.gridViewVM.listVM.rowVMs?.filter(rvm => rvm.dirtyValues || rvm.valuesBeingSaved).length ?? 0) > 0){
            let event = new RowShare.ConfirmationRequiredEventParams();
            event.title = this.$i18n.t('NotSavedItemsDialog_Title').toString();
            event.description1 = this.$i18n.t('NotSavedItemsDialog_Description').toString();
            event.cancelButtonText = this.$i18n.t('NotSavedItemsDialog_Cancel').toString();
            event.actionButtonText = this.$i18n.t('NotSavedItemsDialog_Validate').toString();
            event.actionButtonColor = "primary";
            event.onConfirmation = async () => {
                await RealTimeCollaborationModule.stopAndUnsubscribe();
                next();
            };
            EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, event);
        }
        else {
            RealTimeCollaborationModule.stopAndUnsubscribe();
            next();
        }
    }

    beforeUnload(evt: BeforeUnloadEvent) {
        if((this.gridViewVM.listVM.rowVMs?.filter(rvm => rvm.dirtyValues || rvm.valuesBeingSaved).length ?? 0) > 0){
            evt.returnValue = this.$i18n.t('NotSavedItemsDialog_Description');
            evt.preventDefault();
            return;
        }
        RealTimeCollaborationModule.stopAndUnsubscribe();
    }

    unload(event: any) {
        RealTimeCollaborationModule.stopAndUnsubscribe();
    }
}
