
    import { Component, Vue } from 'vue-property-decorator';
    import { UserModule } from '@/store/UserStore';
    import { OnPremModule } from '@/store/OnPremStore';

    @Component({
        name: "AccessDenied"
    })
    export default class AccessDenied extends Vue {
        private isLoggedIn: Boolean = false;

        async created(){
            let userModel = UserModule.CurrentUser;
            if(!userModel.IsAnonymous)
                this.isLoggedIn = true;
        }

        get isNotOnPrem(){
                return !OnPremModule.isOnPrem;;
            }

        get loginUrl() : string{
            return "/Login";
        }

    }
