
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { UserModule } from '@/store/UserStore'
    import { User } from '@/models/User';
    import { State, Action, Getter, namespace } from 'vuex-class';
    import * as API from '@/api/Api';
    import * as RowShare from '@/models/RowShare';
import { ApplicationModule } from '@/store/ApplicationStore';

    @Component({ name: "TableAccessDenied" })
    export default class TableAccessDenied extends Vue {
        @Prop() private listId!: string;
        private currentUserEmail : string|null = null;
        private hasRequestedAccess: boolean = false;

        async mounted(){
            this.hasRequestedAccess = false;
            this.currentUserEmail = UserModule.CurrentUser.Email;
        }

        get msTeams(): boolean {
            return ApplicationModule.isMsTeamsApp;
        }

        get retryUrl() : string{
            return "/t/" + this.listId;
        }

        get description1(){
            return this.$i18n.t('TableAccessDenied_Description1', {CurrentUserEmail: this.currentUserEmail}).toString();
        }

        async requestAccess(){
            var res = await API.List.requestAccess(this.listId);
            if(res)
                this.hasRequestedAccess = true;
        }

        async changeAccount(){
            let returnUrl: string | null = await UserModule.signOut();
            if (returnUrl) {
                window.location.href = '/login?ReturnUrl=%2Ft%2F' + this.listId;
            }
        }
    }
