
import { Component, Prop, Vue } from "vue-property-decorator";
import { ReturnUrlHandler } from "@/views/ReturnUrl";
import InputText from "@/views/components/FormInputs/InputText.vue";
import InputPassword from "@/views/components/FormInputs/InputPassword.vue";
import { ValidationObserver } from "vee-validate";
import { RowShareException } from "@/api/ApiUtils";
import * as RowShare from "@/models/RowShare";
import { UserModule } from "@/store/UserStore";
import { OnPremModule } from "@/store/OnPremStore";
import { Utils } from "@/utils/Utilities";
import { Location as RouterLocation } from "vue-router";
import { RecaptchaActions } from "@/views/components/Recaptcha.vue";
import Recaptcha from "@/views/components/Recaptcha.vue";
import { EventBus } from "@/modules/EventBus";
import {
  GlobalNotificationEventParams,
  NotificationType,
} from "@/models/RowShare";
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';

@Component({
  name: "LoginForm",
  components: { InputText, InputPassword, ValidationObserver, Recaptcha, PageLoadSpinner },
})
export default class LoginForm extends ReturnUrlHandler {
  @Prop() presetEmail!: string;
  @Prop() inviteLinkId!: string;

  $refs!: { observer: InstanceType<typeof ValidationObserver> };

  private focusEmail = false;
  private focusPassword = false;
  private signinUser = new RowShare.SigninUser();
  private working = false;

  mounted() {
    if (this.presetEmail != null) {
      this.signinUser.Email = this.presetEmail;
      this.focusPassword = true;
    } else {
      this.focusEmail = true;
    }
    EventBus.$on(RowShare.Event.RECAPTCHA_SUCCEEDED, this.onRecaptchaSucceeded);
    EventBus.$on([RowShare.Event.RECAPTCHA_FAILED,RowShare.Event.RECAPTCHA_CANCELEDV2], this.onRecaptchaFailedOrCanceled);
  }

  destroyed() {
    EventBus.$off(RowShare.Event.RECAPTCHA_SUCCEEDED,this.onRecaptchaSucceeded);
    EventBus.$off([RowShare.Event.RECAPTCHA_FAILED,RowShare.Event.RECAPTCHA_CANCELEDV2], this.onRecaptchaFailedOrCanceled);
  }

  async onSubmit() {
    this.working = true;
    if (this.mustShowRecaptcha) {
      this.triggerRecaptcha();
    } else {
      this.doLogin();
    }
  }

  private async doLogin() {
    let formValidState = await this.$refs.observer.validate();
    if (!formValidState) {
      this.working = false;
      return;
    }
    this.signinUser.Email = this.signinUser.Email.trim();
    if (!this.isValidEmail(this.signinUser.Email)) {
      this.$refs.observer.setErrors({
        email: [this.$i18n.t("SignUp_EmailInvalid").toString()],
      });
      this.working = false;
      return;
    }
    if (!this.isValidLoginPassword(this.signinUser.Password))
    {
      this.$refs.observer.setErrors({
        password: [this.$i18n.t("SignIn_InvalidPassword").toString()],
      });
      this.working = false;
      return;
    }
    UserModule.SignIn(this.signinUser)
      .then((loginStatus: boolean) => {
        this.signinUser.RecaptchaToken = "";
        this.working = false;
        if (!loginStatus) {
          this.$refs.observer.setErrors({
            password: [this.$i18n.t("SignIn_Invalid_Credentials_Error_Message").toString()],
          });
          return;
        } 
        if (!Utils.isNullOrWhiteSpace(this.inviteLinkId)) {
            this.$router.push(<RouterLocation>{
              name: "Join",
              params: {
                ReturnUrl: this.returnUrl,
                inviteLinkId: this.inviteLinkId,
              },
            });
          } else {
            var defaultRedirectRoute = this.$router.resolve({
              name: "MyTables",
            });
            this.redirectToReturnUrlOrDefault(defaultRedirectRoute.href);
          }
      })
      .catch((exc) => {
        let rsExc = exc as RowShareException;
        let exceptionMsg = "";
        if (rsExc) {
          if (rsExc.code == 96 && this.mustShowRecaptcha) {
            // Recaptcha token not valid
              this.retryFallbackRecaptcha();
              return;
            }
          exceptionMsg = rsExc.message;
          } 
        else {
          exceptionMsg = exc.toString();
          }
        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{ message: exceptionMsg,
              type: RowShare.NotificationType.error,autoHide: true, autoHideAfterMs: 10000});
            
        this.$refs.observer.setErrors({
              email: exceptionMsg,
            });
        this.working = false;
      });
  }

  triggerRecaptcha() {
    this.signinUser.RecaptchaToken = "";
    EventBus.$emit(RowShare.Event.RECAPTCHA_SHOW, RecaptchaActions.Login);
  }

  onRecaptchaSucceeded(successEvent: any) {
    this.signinUser.RecaptchaToken = successEvent.token;
    this.signinUser.RecaptchaTokenIsV3 = successEvent.isV3;
    this.doLogin();
  }

  retryFallbackRecaptcha() {
    this.signinUser.RecaptchaToken = "";
    EventBus.$emit(RowShare.Event.RECAPTCHA_SHOWV2);
  }

  onRecaptchaFailedOrCanceled(reason: string = "") {
    this.signinUser.RecaptchaToken = "";
    this.signinUser.RecaptchaTokenIsV3 = true;
    this.working = false;
    EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <
      GlobalNotificationEventParams
    >{
      autoHide: true,
      autoHideAfterMs: 3000,
      type: NotificationType.error,
      message: this.$i18n.t("Recaptcha_Invalid").toString() + reason,
    });
  }

  get isNotOnPrem() {
    return !OnPremModule.isOnPrem;
  }

  get mustShowRecaptcha() {
    return this.isNotOnPrem && process.env.NODE_ENV!="development";
  }

  isValidEmail(email: string) {
    return Utils.validateEmail(email);
  }

  isValidLoginPassword(pwd: string) {
    return Utils.validateLoginPassword(pwd);
  }

  clearValidations() {
    this.$refs.observer.reset();
  }
}
