
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import * as API from "@/api/Api";
    import * as Models from "@/models/RowShare";
    import { Action, Getter, namespace } from "vuex-class";
    import PermissionDialog from '@/views/MyTables/PermissionDialog.vue';

    @Component({
        name:'EditAuthorizationAction',
        components: { PermissionDialog }
    })
    export default class EditAuthorizationAction extends Vue{

        @Prop({ default: false }) canEdit!: boolean;
        @Prop({ default: '' }) organizationId!: string;
        @Prop({ default: '' }) folder!: Models.ListTreeFolder;
        @Prop({ default: '' }) parentFolderId!: string;
        @Prop({ default: true }) displayInheritConfiguration!: boolean;
        private authorizationModal = false;

        editAuthDialogName : string = 'edit-auth-modal';
        editAuthServerErrorMessage: string = '';

        get dialogAlreadyExistInDOM(): boolean {
            return !!document.getElementById(this.editAuthDialogName);
        }

        cancelled(){
            this.authorizationModal = false;
        }
    }
