
    import { ICellRendererParams } from 'ag-grid-community';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component({ name: 'booleanRenderer'})
    export default class booleanRenderer extends Vue{
        params!: ICellRendererParams;

        onDblClick(event: MouseEvent) {            
            var editingCells = this.params.api.getEditingCells();
            if(editingCells.length == 0){
                if(this.params.value != null && this.params.value != undefined){
                    if(this.params.setValue) {
                        this.params.setValue(!this.params.value)
                    }
                }
            }
            event.stopPropagation();
        }
    }
