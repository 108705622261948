
    import { Component, Vue } from 'vue-property-decorator';
    import { UserModule } from '@/store/UserStore';
    import { OnPremModule } from '@/store/OnPremStore';
import { ApplicationModule } from '@/store/ApplicationStore';


    @Component({
        name: "PageNotFound"
    })
    export default class PageNotFound extends Vue {
        private isLoggedIn: Boolean = false;


        async created(){
            this.isLoggedIn = !UserModule.CurrentUser.IsAnonymous;
        }

        get isNotOnPrem(){
                return !OnPremModule.isOnPrem;;
            }

        get loginUrl() : string{
            return "/Login";
        }

        get isMsTeams(): boolean {
            return ApplicationModule.isMsTeamsApp;
        }
    }
