import * as RowShare from '@/models/RowShare';

import BaseColumnProxy from "./BaseColumnProxy";
import NumericColumnProxy from "./NumericColumnProxy";
import TextColumnProxy from "./TextColumnProxy";
import TimeColumnProxy from "./TimeColumnProxy";
import LookupColumnProxy from "./LookupColumnProxy";
import PercentageColumnProxy from "./PercentageColumnProxy";
import MemberColumnProxy from "./MemberColumnProxy";
import DateTimeColumnProxy from "./DateTimeColumnProxy";
import EmailColumnProxy from "./EmailColumnProxy";
import HyperLinkColumnProxy from "./HyperLinkColumnProxy";
import TwoStatesBooleanColumnProxy from "./TwoStatesBooleanColumnProxy";
import BlobColumnProxy from "./BlobColumnProxy";
import CommentColumnProxy from "./CommentColumnProxy";

import ProxyBasedRenderer from "@/views/Table/cellsComponents/ProxyBasedRenderer";
import CommentRenderer from '@/views/Table/cellsComponents/Comment/CommentRenderer';
import RowOwnerHeader from '@/views/Table/cellsComponents/RowOwner/RowOwnerHeaderRenderer';
import TotalRowHeaderRenderer from "@/views/Table/cellsComponents/TotalRowHeaderRenderer";
import TotalRowRenderer from "../views/Table/cellsComponents/TotalRowRenderer";
import ProxyBasedTooltipRenderer from '@/views/Table/cellsComponents/ProxyBasedTooltipRenderer';
import GroupRowRenderer from '@/views/Table/cellsComponents/GroupRowRenderer';

import BlobEditor from '@/views/Table/cellsComponents/Blob/BlobEditor.vue';
import CommentEditor from '@/views/Table/cellsComponents/Comment/CommentEditor.vue';
import DateTimeEditor from '@/views/Table/cellsComponents/DateTime/DateTimeEditor.vue';
import HyperlinkEditor from "@/views/Table/cellsComponents/Hyperlink/HyperlinkEditor.vue";
import LookupEditor from '@/views/Table/cellsComponents/Lookup/LookupEditor.vue';
import NumberEditor from '@/views/Table/cellsComponents/Number/NumberEditor.vue';
import RelationEditor from '@/views/Table/cellsComponents/Relation/RelationEditor.vue';
import RichTextEditor from "@/views/Table/cellsComponents/RichText/RichTextEditor.vue";
import RowOwnerHeaderEditor from '@/views/Table/cellsComponents/RowOwner/RowOwnerHeaderEditor.vue';
import TextEditor from '@/views/Table/cellsComponents/Text/TextEditor.vue';
import TimeEditor from '@/views/Table/cellsComponents/DateTime/TimeEditor.vue';
import TwoStatesBooleanEditor from "@/views/Table/cellsComponents/TwoStateBoolean/TwoStatesBooleanEditor.vue";

import columnGroupHeader from '@/views/Table/GridView/columnGroupHeader.vue';
import columnHeader from '@/views/Table/GridView/columnHeader.vue';

export default class RsColumnManager{

    private static _textProxy = new TextColumnProxy();
    private static _numberProxy = new NumericColumnProxy();
    private static _lookupProxy = new LookupColumnProxy();
    private static _memberProxy = new MemberColumnProxy();
    private static _dateTimeProxy = new DateTimeColumnProxy();
    private static _blobProxy = new BlobColumnProxy();
    private static _percentageProxy = new PercentageColumnProxy();

    private static readonly _proxies: Map<RowShare.ColumnStrongType, BaseColumnProxy> = new Map([
        // text
        [RowShare.ColumnStrongType.Text, RsColumnManager._textProxy],
        [RowShare.ColumnStrongType.RichText, RsColumnManager._textProxy],
        // numbers
        [RowShare.ColumnStrongType.Number, RsColumnManager._numberProxy],
        [RowShare.ColumnStrongType.DecimalNumber, RsColumnManager._numberProxy],
        [RowShare.ColumnStrongType.AutoNumber, RsColumnManager._numberProxy],
        [RowShare.ColumnStrongType.Percentage, RsColumnManager._percentageProxy],
        [RowShare.ColumnStrongType.RowIndex, RsColumnManager._numberProxy],
        // Dates and times
        [RowShare.ColumnStrongType.Time, new TimeColumnProxy()],
        [RowShare.ColumnStrongType.Date, RsColumnManager._dateTimeProxy],
        [RowShare.ColumnStrongType.DateTime, RsColumnManager._dateTimeProxy],
        [RowShare.ColumnStrongType.Reminder, RsColumnManager._dateTimeProxy],
        [RowShare.ColumnStrongType.CreationDate, RsColumnManager._dateTimeProxy],
        [RowShare.ColumnStrongType.LastWriteDate, RsColumnManager._dateTimeProxy],
        // lookups
        [RowShare.ColumnStrongType.LookUp, RsColumnManager._lookupProxy],
        [RowShare.ColumnStrongType.LookUpList, RsColumnManager._lookupProxy],
        // members
        [RowShare.ColumnStrongType.OwnerEmail, RsColumnManager._memberProxy],
        [RowShare.ColumnStrongType.OwnerName, RsColumnManager._memberProxy],
        [RowShare.ColumnStrongType.CreatedByUserEmail, RsColumnManager._memberProxy],
        [RowShare.ColumnStrongType.CreatedByUserName, RsColumnManager._memberProxy],
        [RowShare.ColumnStrongType.LastWriteUserEmail, RsColumnManager._memberProxy],
        [RowShare.ColumnStrongType.LastWriteUserName, RsColumnManager._memberProxy],
        [RowShare.ColumnStrongType.MemberManagerEmail, RsColumnManager._memberProxy],
        [RowShare.ColumnStrongType.MemberManagerName, RsColumnManager._memberProxy],
        // Files
        [RowShare.ColumnStrongType.File, RsColumnManager._blobProxy],
        [RowShare.ColumnStrongType.Image, RsColumnManager._blobProxy],
        // others
        [RowShare.ColumnStrongType.Email, new EmailColumnProxy()],
        [RowShare.ColumnStrongType.Hyperlink, new HyperLinkColumnProxy()],
        [RowShare.ColumnStrongType.TwoStatesBoolean, new TwoStatesBooleanColumnProxy()],
        [RowShare.ColumnStrongType.Comment, new CommentColumnProxy()],

        // Fake columns type to be able to use proxies for deleted rows display
        [RowShare.ColumnStrongType.StatusChangeByUserName, RsColumnManager._memberProxy],
        [RowShare.ColumnStrongType.StatusChangeDateTime, RsColumnManager._dateTimeProxy],
    ]);

    public static getProxy(type: RowShare.ColumnStrongType) : BaseColumnProxy{
        return RsColumnManager._proxies.get(type) ?? RsColumnManager._textProxy;
    }

    public static renderers = {
        // column headers
        columnHeader: columnHeader,
        columnGroupHeader: columnGroupHeader
    };
      
    public static simpleRenderers = {
        // generic
        ProxyBasedRenderer: ProxyBasedRenderer,
        ProxyBasedTooltipRenderer: ProxyBasedTooltipRenderer,
        
        // text
        //// AutoNumberRenderer: TextRenderer,
        // others
        CommentRenderer: CommentRenderer,
        // technical cells
        RowOwnerHeader: RowOwnerHeader,
        TotalRowRenderer: TotalRowRenderer,
        TotalRowHeaderRenderer: TotalRowHeaderRenderer,
        GroupRowRenderer: GroupRowRenderer,
        // to be implemented
        //// RichTextRenderer: TextRenderer,
    }
      
    public static editors = {
        AutoNumberEditor: TextEditor,
        CommentEditor: CommentEditor,
        CreatedByUserEmailEditor: TextEditor,
        CreatedByUserNameEditor: TextEditor,
        DateEditor: DateTimeEditor,
        DateTimeEditor: DateTimeEditor,
        DecimalNumberEditor: NumberEditor,
        EmailEditor: TextEditor,
        FileEditor: BlobEditor,
        HyperlinkEditor: HyperlinkEditor,
        ImageEditor: BlobEditor,
        LastWriteUserEmailEditor: TextEditor,
        LastWriteUserNameEditor: TextEditor,
        LookUpEditor: LookupEditor,
        LookUpListEditor: LookupEditor,
        MemberManagerEmailEditor: TextEditor,
        MemberManagerNameEditor: TextEditor,
        NumberEditor: NumberEditor,
        OwnerEmailEditor: TextEditor,
        OwnerNameEditor: TextEditor,
        PercentageEditor: NumberEditor,
        ReminderEditor: DateTimeEditor,
        RichTextEditor: RichTextEditor,
        TextEditor: TextEditor,
        TimeEditor: TimeEditor,
        TwoStatesBooleanEditor: TwoStatesBooleanEditor,
        // technical cells
        RowOwnerHeaderEditor: RowOwnerHeaderEditor,
        RelationEditor: RelationEditor,
    }
}