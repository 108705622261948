
export const enum UserListLinkTypes {
    Unspecified = 0,
    Favorite = 1,
    Visited = 2,
    AllRowsEmailNotifications = 4,
    OwnedRowsEmailNotifications = 8,
    NoEmailNotifications = 16,
}

export class UserListLink {
    ListId!: string;
    Types!: UserListLinkTypes;

    public get isFavorite() {
        return (this.Types & UserListLinkTypes.Favorite) === UserListLinkTypes.Favorite;
    }

    public get isVisited() {
        return (this.Types & UserListLinkTypes.Visited) === UserListLinkTypes.Visited;
    }

    public get isAllRowsEmailNotifications() {
        return (this.Types & UserListLinkTypes.AllRowsEmailNotifications) === UserListLinkTypes.AllRowsEmailNotifications;
    }

    public get isOwnedRowsEmailNotifications() {
        return (this.Types & UserListLinkTypes.OwnedRowsEmailNotifications) === UserListLinkTypes.OwnedRowsEmailNotifications;
    }

    public get isNoEmailNotifications() {
        return !this.isAllRowsEmailNotifications && !this.isOwnedRowsEmailNotifications;
    }

}