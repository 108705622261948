
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import SingleFormDialog from '@/views/MyTables/SingleFormDialog.vue';
    import * as API from '@/api/Api';
    import * as RowShare from '@/models/RowShare';
    import { Action, Getter, namespace } from 'vuex-class';
    import { ListTreeModule } from '@/store/ListTreeStore';
    import { ListTreeHelper } from '@/utils/ListTreeHelper';

    @Component({
        name:'RenameFolderAction',
        components: { SingleFormDialog }
    })
    export default class RenameFolderAction extends Vue{

        @Prop({ default: false }) canRename!: boolean;
        @Prop({ default: '' }) organizationId!: string;
        @Prop({ default: '' }) folderId!: string;
        @Prop({ default: '' }) parentFolderId!: string;
        @Prop({ default: '' }) newName!: string;

        private renameFolderModal = false;
        renameFolderDialogName : string = 'rename-folder-modal';
        renameFolderServerErrorMessage: string = '';

        get dialogAlreadyExistInDOM(): boolean {
            return !!document.getElementById(this.renameFolderDialogName);
        }

        async renameFolder(newFolderName: string) : Promise<void> {
            this.renameFolderServerErrorMessage = '';
            
            let renameInfo = {
                    parentFolderId: this.parentFolderId,
                    folderId: this.folderId,
                    orgId: this.organizationId,
                    folderName: newFolderName
                };
                
            if(!ListTreeModule.listTree || !ListTreeModule.organizationsTree)
                return;

            let existingFolderInAboveLevel = await ListTreeHelper.getFolderByNameInParentFolder(ListTreeModule.listTree, this.organizationId,this.parentFolderId,newFolderName);
            if(existingFolderInAboveLevel==null){
                let existingListInAboveLevel = await ListTreeHelper.getListByNameInParentFolder(ListTreeModule.listTree, this.organizationId,this.parentFolderId,newFolderName);
                if(existingListInAboveLevel==null){
                    await ListTreeModule.renameFolder(renameInfo).then((res:any)=>{
                        this.renameFolderModal = false;
                    }).catch((error:any)=>{
                        let errorMessage : string;
                        if (error.Exception != undefined)
                        {
                            errorMessage = error.Exception.Message;
                        }
                        else
                        {
                            errorMessage = error.message ? error.message : error.Message;
                        }
                        this.renameFolderServerErrorMessage = errorMessage;
                    })
                }
                else{
                    this.renameFolderServerErrorMessage = this.$t('MyTables_CreateFolderModal_ExistingList').toString();
                } 
            }
            else{
                this.renameFolderServerErrorMessage = this.$t('MyTables_CreateFolderModal_ExistingFolder').toString();
            }            
        }

        cancelled(){
            this.renameFolderServerErrorMessage = "";
            this.renameFolderModal = false;
        }
    }
