import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"

export class Column {

    static loadForList(listId: string, options: IRequestOptions | null = null) : Promise<Array<Models.Column> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/column/loadforparent/" + ApiUtils.encodeSegment(listId || ""), options)
            .then(response => response.objects(Models.Column));
    }

    static loadRelated(columnId: string, options: IRequestOptions | null = null) : Promise<Models.Column[] | null> {
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiGet("/column/loadRelated/" + ApiUtils.encodeSegment(columnId || ""), options)
            .then(response => response.objects(Models.Column));
    }

    static clearRelated(columnId: string, options: IRequestOptions | null = null) : Promise<boolean> {
        options = ApiUtils.createRequestOptions({clearCache: true}, options);
        return ApiUtils.apiGet("/column/clearRelated/"+ ApiUtils.encodeSegment(columnId || ""), options)
            .then(response => response?.json<boolean>() ?? false);
    }

    static load(columnId: string, options: IRequestOptions | null = null) : Promise<Models.Column | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);

        return ApiUtils.apiGet("/column/load/" + ApiUtils.encodeSegment(columnId || ""), options)
            .then(response => response.object(Models.Column));
    }

    static moveColumns(ids: string[], toindex: number, listId: string, displayedColumns: number, rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
        : Promise<Models.Column[] | null> {
            options = ApiUtils.createRequestOptions({cache: false, clearCache: true}, options);
            var data = {
                listId: listId,
                columnIds: ids,
                toIndex: toindex,
                displayedColumns: displayedColumns,
                RtcConnectionId: rtcConnectionId,
            };
            return ApiUtils.apiPostJson('/column/move', data, options)
                .then(response => response.objects(Models.Column));            
        }

    static save(column: Models.Column, options: IRequestOptions | null = null): Promise<Models.Column | null> {
        options = ApiUtils.createRequestOptions({cache: false}, options);
        
        return ApiUtils.apiPostJson('/column/save', column, options).then(response => response.object(Models.Column));
    }

    static saveWidth(column: Models.Column, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({cache: true}, options);

        return ApiUtils.apiPostJson("/column/save/", { Id: column.Id, DefaultWidth: column.DefaultWidth, RtcConnectionId: column.RtcConnectionId }, options)
            .then(response => response.object(Models.Column));
    }

    static savePinned(columnId: string, pinned: boolean, rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
    : Promise<Models.Column | null> {
        return ApiUtils.apiPostJson("/column/save", {Id: columnId, LeftPinned: pinned, RtcConnectionId: rtcConnectionId}, options)
            .then(response => response.object(Models.Column));
    }

    static remove(column: Models.Column, options: IRequestOptions | null = null) {
        return ApiUtils.apiPostJson("/column/delete/", column, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<boolean>());
    }

    static clear(columnId: string, options: IRequestOptions | null = null) {
        return ApiUtils.apiGet("/column/clear/" + ApiUtils.encodeSegment(columnId || ""), ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<boolean>());
    }

    static async IsReferenced(columnId: string, options: IRequestOptions | null = null)
    : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet('/column/IsReferenced/' + ApiUtils.encodeSegment(columnId), options)
            .then(response => response.json<boolean>())
    }

    static async clone(columnId: string, rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
    : Promise<Models.ApiResult<Models.Column> | null> {
        options = ApiUtils.createRequestOptions({clearCache: true}, options);

        return ApiUtils.apiPostJson('/column/clone', {ColumnId: columnId, RtcConnectionId: rtcConnectionId}, options)
                .then(response => response.json<Models.ApiResult<Models.Column>>());
    }


}