
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
    import { DateTime } from '@/utils/Date';
    import { ColumnStrongType } from '@/models/RowShare';
    import RsColumnManager from '@/columnProxies/RsColumnManager';
    import moment from 'moment';

    @Component({
        name: 'DateTimeFormEditor',
        components: {
        }
    })
    export default class DateTimeFormEditor extends FormEditor {
        currentValue: Date | null = null;
        formattedDateTime: string = "";
        showPicker: boolean = false;

        init(){
            const proxy  = RsColumnManager.getProxy(this.column.Type);
            this.currentValue = DateTime.parseDateTime(this.rowVM.getValue(this.column, proxy), null);
            this.pickerValue = this.currentValue;
            this.updateFormattedDate();
        }

        getValue() {
            return this.currentValue;
        }

        get isDateTime():boolean {
            return this.column.isDateTimeFormat;
        }

        get isReminder():boolean {
            return this.column.Type == ColumnStrongType.Reminder;
        }

        get selectionMode() {
            return this.isDateTime ? 'dateTime' : 'date';
        }

        get locale() {
            return window.navigator.language;
        }

        get pickerValue(): Date | null {
            return this.currentValue;
        }

        set pickerValue(value: Date | null) {

            if(value) {
                this.currentValue = value;
            }
            let picker = (<any>this.$refs.datePicker);
            if(picker && this.pickerValue != null){
                picker.move(this.pickerValue);
            }
        }

        validateDate() {
            this.saveCellValue();
        }

        updateFormattedDate() {
            let options: any = {dateStyle: "short", timeStyle: "short"};
            if(this.currentValue) {
                this.formattedDateTime = this.isDateTime ? this.currentValue.toLocaleString(navigator.language, options) : this.currentValue.toLocaleDateString(navigator.language);
            }
            else
            {
                this.formattedDateTime = "";
            }
        }

        pickerChanged() {
            this.updateFormattedDate();
            this.saveCellValue();
        }

        updateDatePicker() {
            moment.locale(this.locale);
            let date = moment(this.formattedDateTime, ["L LT", "L LTS"]);
            if(date.isValid()){
                this.pickerValue = date.toDate();
                (<any>this.$refs.datePicker).move(this.pickerValue);
            }
            else
            {
                this.currentValue = null;
                this.pickerValue = null;
            }
        }

        get nowLabel() {
            if(this.isDateTime) {
                return this.$i18n.t('Action_Now').toString();
            }
            else {
                return this.$i18n.t('Action_Today').toString();
            }
        }

        closeEditor(cancel: boolean) {
            this.showPicker = false;
            if(!cancel) {
                this.pickerChanged();
            }
            else {
                this.updateDatePicker();
            }
        }

        chooseNow() {
            this.pickerValue = moment().toDate();
        }


        get is24hrTimeFormat(): boolean {
            moment.locale(this.locale);
            // Try to parse a time in 24hr format using browser locale.
            // if date is invalid, browser is in 12hr time format
            let date = moment("14:00", "LT", true);
            return date.isValid();
        }

        get pickerIcon(): string {
            if(this.isReminder) {
                return "fa fa-bell";
            }
            else {
                return "fal fa-calendar";
            }
        }
    }
