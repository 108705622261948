
import {Component, Prop, Vue} from "vue-property-decorator";
import * as RowShare from "@/models/RowShare";
import {BigramHelper} from "@/utils/BigramHelper";

@Component({ name: "Bigram" })
export default class Bigram extends Vue {
    @Prop({type: Boolean, default: false}) dense!: boolean;
    @Prop({type: Boolean, default: false}) xsmall!: boolean;
    @Prop() listAccess!: RowShare.ListAccess;
    @Prop() securityPrincipal!: RowShare.SecurityPrincipal;
    @Prop() rowOwner!: RowShare.RowOwner;
    @Prop() columnAccess!: RowShare.ColumnAccess;
    @Prop() rtcUser!: RowShare.RealTimeCollaborationEntity

    get bigram(){
        if(this.listAccess) {
            return this.listAccess.Guest.Bigram;
        }
        else if(this.securityPrincipal) {
            return this.securityPrincipal.Bigram;
        }
        else if(this.rowOwner) {
            return this.rowOwner.Bigram;
        }
        else if(this.columnAccess) {
            return this.columnAccess.Guest?.Bigram;
        } else if(this.rtcUser) {
            return this.rtcUser.bigram;
        }
        else
            return null;
    }

    get classes() {
        if(this.listAccess) {
            return BigramHelper.getBigramClassesForListAccess(this.listAccess, this.dense, this.xsmall);
        }
        else if(this.securityPrincipal) {
            return BigramHelper.getBigramClassesForSecurityPrincipal(this.securityPrincipal, this.dense, this.xsmall);
        }
        else if(this.rowOwner) {
            return BigramHelper.getBigramClassesForRowOwner(this.rowOwner, this.dense, this.xsmall);
        }
        else if(this.columnAccess) {
            return BigramHelper.getBigramClassesForColumnAccess(this.columnAccess, this.dense, this.xsmall);
        } else if (this.rtcUser) {
            return BigramHelper.getBigramClassesForRtcUser(this.rtcUser, this.dense, this.xsmall);
        }
    }

    get tooltip(): string {
        if(this.listAccess) {
            return BigramHelper.getBigramTooltipForListAccess(this.listAccess);
        }
        else if(this.securityPrincipal) {
            return BigramHelper.getBigramTooltipForSecurityPrincipal(this.securityPrincipal);
        }
        else if(this.rowOwner) {
            return BigramHelper.getBigramTooltipForRowOwner(this.rowOwner);
        }
        else if(this.columnAccess) {
            return BigramHelper.getBigramTooltipForColumnAccess(this.columnAccess);
        } else if(this.rtcUser) {
            return BigramHelper.getBigramTooltipForForRtcUser(this.rtcUser);
        }
        else {
            return "";
        }
    }
}
