
import {Component } from 'vue-property-decorator';
import Bandeau from '@/views/layouts/LayoutParts/Bandeau.vue';
import JobGrid from '@/views/RowMerge/JobGrid.vue';
import { ReturnUrlHandler } from '@/views/ReturnUrl';
import { Job } from '@/models/Job';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import * as API from '@/api/Api';

@Component({
    name:'MyDocuments',
    components:{Bandeau, JobGrid, PageLoadSpinner}
})
export default class MyDocuments extends ReturnUrlHandler {

    private titleBandeau='';
    private descriptionBandeau='';
    private listFiles!:Array<Job>;
    private emptyFiles!:boolean;
    private isLoading: boolean;

    constructor(){
        super();
        this.emptyFiles = false;
        this.listFiles = new Array<Job>();
        this.isLoading = false;
    }

    mounted(){
        this.loadFiles();
    }

    beforeDestroy(){
        Job.clearAllJobUpdateTimeout();
    }

    loadFiles(){
        this.isLoading = true;
        Job.clearAllJobUpdateTimeout();
        API.Job.loadAll({cache:false}).then(resultFiles => {
            if(resultFiles!= null && resultFiles.length>0){
                resultFiles.forEach(element => {
                    element.updateStatusAsync(5000);   
                });
                this.emptyFiles=false;
                this.listFiles = resultFiles;
            }
            else{
                this.emptyFiles = true;
            }
        }).finally(()=>{this.isLoading = false;});
    }
}
