import { ApiUtils, IRequestOptions, RowShareException } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"
import { Logger } from "@/utils/Logger"
import { UserUnsubscription, ResetPassword } from '@/models/RowShare';

export class User {
    static load(options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/user", options)
            .then(response => response.object(RowShare.User));
    }

    static loadByEmail(email: string, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiPostJson("/user/email", {email: email}, options)
            .then(response => response.object(RowShare.User));
    }

    static loadLanguage(options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/user/loadlanguage", options)
            .then(response => <string>response.json<string>());
    }

    static changeLanguage(language: string, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/user/changelanguage/" + language, options)
            .then(response => <string>response.json<string>());
    }

    static signup(signup: RowShare.SignupUser, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/user/signup/", signup, options)
            .then(response => response.object(RowShare.User));
    }

    static signout(options: IRequestOptions | null = null) {
        return ApiUtils.apiGet("/user/signout/", options)
            .then(response => response.json<string>());
    }

    static async signin(signin: RowShare.SigninUser, options: IRequestOptions | null = null) : Promise<[boolean, RowShare.User | null]> {
        let returnedStatus = false;
        let returnedUser : RowShare.User | null = new RowShare.User();
        
        try {
            let response = await ApiUtils.apiPostJson("/user/signin/", signin, options);
            if(response === null){
                return [returnedStatus, null];
            }
            returnedUser = response.object(RowShare.User);
            if(returnedUser.IsAnonymous) {
                return [returnedStatus, null];
            }
            returnedStatus = true;
            return [returnedStatus, returnedUser];
        }
        catch (error) {            
            Logger.log("RowShare.Api", "warning", error);
            let rsExc = error as RowShareException;            
            if (rsExc) {
              if (rsExc.code == 96) {    
                throw(error);
              }
            }
            return [returnedStatus, returnedUser];
        }
     }

    static async demoSignin(demoGuid: string, options: IRequestOptions | null = null) : Promise<[boolean, RowShare.User | null]> {
        let returnedStatus = false;
        let returnedUser : RowShare.User | null = new RowShare.User();

        try {
            let response = await ApiUtils.apiGet("/user/demoSignin/" + demoGuid, options);
            if(response === null){
                return [returnedStatus, null];
            }
            returnedUser = response.object(RowShare.User);
            if(returnedUser.IsAnonymous) {
                return [returnedStatus, null];
            }
            returnedStatus = true;
        }
        catch (error) {
            Logger.log("RowShare.Api", "warning", error);
        }
        finally {
             return [returnedStatus, returnedUser];
        }
    }

    static SocialLogin(provider: string, token: string, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/user/sociallogin/", {provider: provider, token: token}, options)
            .then(response => response.object(RowShare.User));
    }

    static async sendConfirmationCode(email: string, options: IRequestOptions | null = null) : Promise<boolean | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiPostJson("/EmailConfirmationCode/create/", {email: email}).then(response => response.json<boolean>());
    }
    
    static async confirmEmail(data: string, options: IRequestOptions | null = null) : Promise<boolean | null>{
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiGet("/user/confirmEmail/" + data, options)
                .then(response => response.json<boolean>());
    }

    static validateUserEmail(emailToValidate: string, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/user/validateUserEmail/" + emailToValidate, options)
            .then(response => response.json<boolean>());
    }

    static Save(user : RowShare.User, options: IRequestOptions | null = null) : Promise<RowShare.User> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiPostJson("/user/save", user, options)
            .then(response => response.object(RowShare.User));
    }

    static delete(options: IRequestOptions | null = null) : Promise<string | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/user/delete", options)
            .then(response => response.json<string>());
    }

    static unsubscribeEmail(unsubscribeInfo:UserUnsubscription, options:IRequestOptions | null = null){
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/user/unsubscribe", unsubscribeInfo, options)
            .then(response => response.json<boolean>());
    }

    static deleteAccount(options: IRequestOptions | null = null) : Promise<string | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/user/deleteaccount", null, options)
            .then(response => response.json<string>());
    }

    static canCurrentUserdeleteAccount(options: IRequestOptions | null = null) : Promise<RowShare.DeleteAccountData | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/user/candeleteaccount", options)
            .then(response => response.object(RowShare.DeleteAccountData));
    }

    static resetPasswordRequest(resetPasswordRequest: RowShare.ResetPasswordRequest, options: IRequestOptions | null = null) : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/user/PrepareUserForPasswordReset/",  resetPasswordRequest, options)
            .then(response => response.json<boolean>());
    }

    static resetPassword(resetPasswordInfo: ResetPassword, options: IRequestOptions | null = null) : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/user/ResetPassword/",  resetPasswordInfo, options)
            .then(response => response.json<boolean>());
    }

    static validateToken(token: string, options: IRequestOptions | null = null) : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/user/ValidateTokenToResetPassword/", { Token: token }, options)
            .then(response => response.json<boolean>());
    }

    static CheckUserHasListForMsTeams(teamName: string | null = null, options: IRequestOptions | null = null) : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/user/CheckUserHasListForMsTeams/" + (teamName ?? ""), options)
            .then(response => response.json<boolean>());
    }

    static connectUserByIdToken(idToken: RowShare.IdTokenUser, options: IRequestOptions | null = null) : Promise<boolean | null> {
        return ApiUtils.apiPostJson("/user/connectUserByIdToken/", idToken, options)
            .then(response => response.json<boolean>());
    }

    static getUserEmailFromToken(token: RowShare.IdTokenUser, options: IRequestOptions | null = null) : Promise<string | null> {
        return ApiUtils.apiPostJson("/user/getUserEmailFromToken/", token, options)
            .then(response => response.json<string>());
    }

}