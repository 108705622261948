import { Utils } from "@/utils/Utilities"
import * as RowShare from "@/models/RowShare";
import { i18n } from "@/modules/Localization";
export class Folder {
    public Id!: string;
    public DisplayName!: string;
    public DisplayPath!: string;
    public CanCurrentUserCreateList!: boolean;
    public CanCurrentUserCreateFolder!: boolean;
    public CanCurrentUserManageAccesses!: boolean;
    public HasRemainingListSlot!: boolean;
    public MetaData!: string | null;
    public ParentId!: string | null;
    public ListsIds!: string[];
    public FoldersIds!: string[];
    public Owned!: boolean;
    public Options!: FolderOptions;
    public AccessMode!: FolderAccessMode;
    //public Accesses!: FolderAccess[];
    public OrganizationId!: string;
    public IsRecycleBin!: boolean;
    public IsInRecycleBin!: boolean;

    public get isOrganization() {
        return !Utils.isEmptyGuid(this.OrganizationId);
    }

    public get isUser() {
        return !this.isOrganization;
    }

    public get isRootFolder() {
        return Utils.isEmptyGuid(this.ParentId);
    }

    public get isOrganizationRootFolder() {
        return this.isRootFolder && this.isOrganization;
    }

    public get isUserRootFolder() {
        return this.isRootFolder && this.isUser;
    }
}

export class FolderOwner {
    public OwnerEmail!: string;
    public OwnerOrganizationId!: string;
    public FolderId!: string;
    public Options!: FolderOwnerOptions;
    public OwnerName!: string;
}

export class FolderAccess {
    public AccessMode!: FolderAccessMode;
    public Options!: AccessOptions;
    public GroupId!: string;
    public GroupName!: string;
    public MemberEmail!: string;
    public MemberOrganizationId!: string;
    public MemberName!: string;
    public Guest!: RowShare.Owner;
    public Id!: string;  
    public FolderId!: string;
    
    public get guestName(): string {
        let guestName = this.Guest?.DisplayName;
        if(!guestName) {
            return "";
        }
        let result = guestName;
        if (this.Guest.Type == RowShare.OwnerType.Team) {
            if (this.Options & RowShare.AccessOptions.ManagedOfMember) {
                if (this.Options & RowShare.AccessOptions.Recursive) {
                    result = i18n.t("ListAccess_AllReportsTeamNameFormat", [guestName]).toString();
                } else {
                    result = i18n.t("ListAccess_DirectReportsTeamNameFormat", [guestName]).toString();
                }
            }
        }
        return result;
    }    
}

// export class FolderAccessEdition {
//     public FolderId!: string;
//     public GroupId!: string;
//     public GroupName!: string;
//     public AccessMode!: FolderAccessMode;
//     public Options!: number | null;
//     public MemberOrganizationId!: string;
//     public MemberEmail!: string;
//     public MemberName!: string;
//     public Bigram!: string;
//     public BackgroundColorIndex!: string;
// }

export enum FolderAccessMode {
    None = 0,
    Read = 1,
    Write = 2,
    Unused1 = 4,
    DontInherit = 8
}

export enum AccessOptions { 
    None = 0,   
    ManagedOfMember = 1,   
    Recursive = 2
}

enum FolderOwnerOptions {
    None = 0,
    Primary = 1
}

enum FolderOptions {
    None = 0,
    RecycleBin = 1,
    Undeletable = 2
}