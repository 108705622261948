import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"

export class Category {
    static loadColors(options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet(`/category/loadColors/`, options)
                        .then(response => response.json())
    }

    static loadAll(options: IRequestOptions | null = null) : Promise<RowShare.CatalogCategory[] | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet(`/category/loadAll/`, options)
                        .then(response => response.objects(RowShare.CatalogCategory))
    }

}