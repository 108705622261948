
    import {Component, Prop } from 'vue-property-decorator';
    import InputText from '@/views/components/FormInputs/InputText.vue';
    import { FormValidation } from '@/views/components/FormValidation';
    import { ValidationObserver } from 'vee-validate';
    import Bandeau from '@/views/layouts/LayoutParts/Bandeau.vue';
    import * as API from '@/api/Api';
    import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';

    @Component({
        name: 'ConfirmEmail',
        components: {ValidationObserver, InputText, Bandeau, PageLoadSpinner}
    })
    export default class ConfirmEmail extends FormValidation {
        @Prop() private token!: string | null;

        private isLoading: boolean;
        private emailConfirmed: boolean;
        private titleBandeau: string;
        private descriptionBandeau: string;
        private sendToEmail: string;
        private confirmationCodeSended: boolean;
        constructor(){
            super();
            this.isLoading = true;
            this.emailConfirmed = false;
            this.titleBandeau = '';
            this.descriptionBandeau = '';
            this.sendToEmail = '';
            this.confirmationCodeSended = false;
        }

        get noToken(): boolean{
            return !this.token;
        }

        created(){
            this.isLoading = true;
            if(this.token){
                API.User.confirmEmail(this.token)
                        .then(response => { if(response){
                                                this.emailConfirmed = response;
                                            }
                                            else{
                                                this.emailConfirmed = false;
                                            }
                                            this.isLoading = false });
            }
            else
            {
                this.isLoading = false;
            }
        }

        onSubmit(){
            API.User.sendConfirmationCode(this.sendToEmail).then(response => {if(response){this.confirmationCodeSended = true}});
        }
    }
