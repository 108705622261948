
import { KeyCodes } from '@/utils/KeyCodes';
import { Utils } from '@/utils/Utilities';
import { ICellEditorParams } from 'ag-grid-community';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ColumnVM from '@/viewModels/Table/columnVM';
import { Column } from '@/models/Column';
import { CurrencyInputOptions } from 'vue-currency-input';
import numeral from 'numeral';

@Component({name: "NumberEditor"})
export default class NumberEditor extends Vue{
    params!: ICellEditorParams;
    isPercentage: boolean = false;
    maxValue: number | null = null;
    minValue: number | null = null;

    options : CurrencyInputOptions = {currency: undefined, locale: navigator.language};

    formattedValue: string | null = null;

    get rsColumnSettings(): Column | null {
        let col = <ColumnVM>this.params?.colDef;
        if(!col) {
            return null;
        }
        return col.rsColumn;
    }

    onInput(value: any) {
        this.formattedValue = value;
    }

    onChange(value: any) {
        this.formattedValue = value;
    }

    mounted(){
        this.refreshDisplay();
        this.$nextTick(() => {
            (<HTMLElement>this.$refs.numberInput)?.focus();
        });
    }

    created() {
        if(this.rsColumnSettings) {
            this.isPercentage = this.rsColumnSettings.isPercentage;
            if(Utils.isNumber(this.rsColumnSettings.MinValue)) {
                this.minValue = this.rsColumnSettings.MinValue;
                if(this.isPercentage) {
                    this.minValue = this.minValue * 100;
                }
            }
            if(Utils.isNumber(this.rsColumnSettings.MaxValue)) {
                this.maxValue = this.rsColumnSettings.MaxValue;
                if(this.isPercentage) {
                    this.maxValue = this.maxValue * 100;
                }
            }
        }
        this.options.distractionFree = { hideCurrencySymbol: true, hideGroupingSymbol: !this.rsColumnSettings?.showThousandsSeparator, hideNegligibleDecimalDigits: true}
        if(this.rsColumnSettings?.MaxDecimals) {
            this.options.precision = !this.isPercentage ? this.rsColumnSettings.MaxDecimals : ((this.rsColumnSettings.MaxDecimals - 2) < 0 ? 0 : (this.rsColumnSettings?.MaxDecimals - 2));
        }
        else {
            this.options.precision = 0;
        }
        this.$ci.globalOptions = this.options;
    }

    refreshDisplay() {
        // undefined would produce a 0, while null is leave as it
        let cellValue = numeral(this.params.value ?? null);
        if(cellValue.value() !== null && this.isPercentage) {
            cellValue = cellValue.multiply(100);
        }
        if (this.params.eventKey === "Delete") { // suppr 
            cellValue.set(null);
        }
        else if (this.params.eventKey && this.params.eventKey.length == 1 && "-0123456789".includes(this.params.eventKey)) {
            if(cellValue.value() !== null) {
                cellValue = numeral(cellValue.value()?.toString() + this.params.eventKey);
            }
            else {
                cellValue = numeral(this.params.eventKey);
            }
        }
        if(this.params.eventKey == '-' && cellValue.value() === null) {
            this.formattedValue = '-';
        }
        else {
            this.$ci.setValue(<HTMLInputElement>this.$refs.numberInput, cellValue.value());
        }
    }

    getValue(){
        let value = numeral(this.$ci.getValue(<HTMLInputElement>this.$refs.numberInput));
        if(value.value() === null) {
            return null;
        }
        return this.isPercentage ? value.divide(100).value() : value.value();
    }

}
