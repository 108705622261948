import { ColumnOptions, ColumnStrongType } from '@/models/Column';
import {ColumnType, ColumnTypeCategory, ColumnTypeCategoryName} from '@/models/ColumnType';
import i18n from '@/modules/Localization';

export class ColumnTypes{
    public static defaultRatingLookupValues: string = '[{"name": "\u2b50", "color": ""},{"name": "\u2b50\u2b50", "color": ""},{"name": "\u2b50\u2b50\u2b50", "color": ""},{"name": "\u2b50\u2b50\u2b50\u2b50", "color": ""},{"name": "\u2b50\u2b50\u2b50\u2b50\u2b50", "color": ""}]';
    public static defaultThreeStateBooleanLookupValues: string = '[{"name":"?", "color": ""}, {"name":"\u274C", "color": ""}, {"name":"\u2705", "color": ""}]';
    public static defaultLookupValues = '[{"name":"Item 1","color":"color0"},{"name":"Item 2","color":""}]';

    static getColumnTypes(betaTypes: boolean = false, hasRowMetadataSubscription: boolean = true) : ColumnType[] {
        let colTypes : ColumnType[] = [];
        this.pushColumnType(colTypes, ColumnStrongType.Text, ColumnTypeCategoryName.text, "fal fa-align-justify",1);
        this.pushColumnType(colTypes, ColumnStrongType.RichText, ColumnTypeCategoryName.text, "fal fa-font",2);
        this.pushColumnType(colTypes, ColumnStrongType.Email, ColumnTypeCategoryName.text, "fal fa-envelope",3);
        this.pushColumnType(colTypes, ColumnStrongType.Hyperlink, ColumnTypeCategoryName.text, "fal fa-external-link",4);
        this.pushColumnType(colTypes, ColumnStrongType.Comment, ColumnTypeCategoryName.text, "fal fa-comment-alt-lines",5);

        this.pushColumnType(colTypes, ColumnStrongType.Number, ColumnTypeCategoryName.number, "fal fa-calculator",10);
        this.pushColumnType(colTypes, ColumnStrongType.DecimalNumber, ColumnTypeCategoryName.number, "fal fa-calculator",11);
        this.pushColumnType(colTypes, ColumnStrongType.Percentage, ColumnTypeCategoryName.number, "fal fa-percent",12);
        this.pushColumnType(colTypes, ColumnStrongType.AutoNumber, ColumnTypeCategoryName.number, "fal fa-calculator", 13);

        this.pushColumnType(colTypes, ColumnStrongType.TwoStatesBoolean, ColumnTypeCategoryName.visual, "fal fa-check-square",20);
        this.pushColumnType(colTypes, ColumnStrongType.LookUp, ColumnTypeCategoryName.visual, "fal fa-times",21, null,
            i18n.t("ColumnType_ThreeStatesBoolean").toString(), 4, ColumnTypes.defaultThreeStateBooleanLookupValues, "?");
        this.pushColumnType(colTypes, ColumnStrongType.LookUp, ColumnTypeCategoryName.visual, "fal fa-star-half-alt",22, null,
            i18n.t("ColumnType_Rating").toString(), 3, ColumnTypes.defaultRatingLookupValues);

        this.pushColumnType(colTypes, ColumnStrongType.LookUp, ColumnTypeCategoryName.lookUp, "far fa-ellipsis-v", 30, null, null, 0, ColumnTypes.defaultLookupValues);
        this.pushColumnType(colTypes, ColumnStrongType.LookUpList, ColumnTypeCategoryName.lookUp, "far fa-ellipsis-v",31);
        this.pushColumnType(colTypes, ColumnStrongType.Relation, ColumnTypeCategoryName.lookUp, "fal fa-link", 32);

        this.pushColumnType(colTypes, ColumnStrongType.Date, ColumnTypeCategoryName.date, "fal fa-calendar",40);
        this.pushColumnType(colTypes, ColumnStrongType.DateTime, ColumnTypeCategoryName.date, "fal fa-calendar",41);
        this.pushColumnType(colTypes, ColumnStrongType.Reminder, ColumnTypeCategoryName.date, "fal fa-bell",42);
        this.pushColumnType(colTypes, ColumnStrongType.Time, ColumnTypeCategoryName.date, "fal fa-clock",43);

        this.pushColumnType(colTypes, ColumnStrongType.File, ColumnTypeCategoryName.media, "fal fa-paperclip",50);
        this.pushColumnType(colTypes, ColumnStrongType.Image, ColumnTypeCategoryName.media, "fal fa-file-image",51);

        this.pushColumnType(colTypes, ColumnStrongType.OwnerName, ColumnTypeCategoryName.row, "fal fa-user", 60);
        this.pushColumnType(colTypes, ColumnStrongType.OwnerEmail, ColumnTypeCategoryName.row, "fal fa-user", 61);
        this.pushColumnType(colTypes, ColumnStrongType.CreationDate, ColumnTypeCategoryName.row, "fal fa-calendar", 62, null, null, 0, null, null, hasRowMetadataSubscription);
        this.pushColumnType(colTypes, ColumnStrongType.CreatedByUserName, ColumnTypeCategoryName.row, "fal fa-user", 63, null, null, 0, null, null, hasRowMetadataSubscription);
        this.pushColumnType(colTypes, ColumnStrongType.LastWriteDate, ColumnTypeCategoryName.row, "fal fa-calendar", 64, null, null, 0, null, null, hasRowMetadataSubscription);
        this.pushColumnType(colTypes, ColumnStrongType.LastWriteUserName, ColumnTypeCategoryName.row, "fal fa-user", 65, null, null, 0, null, null, hasRowMetadataSubscription);
        this.pushColumnType(colTypes, ColumnStrongType.MemberManagerName, ColumnTypeCategoryName.row, "fal fa-user", 66, null, null, 0, null, null, hasRowMetadataSubscription);
        this.pushColumnType(colTypes, ColumnStrongType.RowIndex, ColumnTypeCategoryName.row, "fal fa-list-ol", 66, null, null, 0, null, null, hasRowMetadataSubscription);

        this.pushColumnType(colTypes, ColumnStrongType.Text, ColumnTypeCategoryName.formulas, "fal fa-function", 70,
            ColumnOptions.IsFormula, i18n.t("ColumnType_TextFormula").toString(), 1);
        this.pushColumnType(colTypes, ColumnStrongType.DecimalNumber, ColumnTypeCategoryName.formulas, "fal fa-function", 71,
            ColumnOptions.IsFormula, i18n.t("ColumnType_NumericFormula").toString(), 2);

        return colTypes;
    }

    static pushColumnType(types: ColumnType[], strongType: ColumnStrongType, category: ColumnTypeCategoryName, icon: string, sortOrder: number | null,
                          options: ColumnOptions | null = null, displayName: string | null = null, aliasID: number = 0,
                          values: string | null = null, defaultValue: string | null = null, hasRequiredSubscription: boolean = true) {
        if (!types)
            return;
        let type = new ColumnType();
        type.StrongType = strongType;
        if (displayName == null) {
            type.DisplayName = i18n.t("ColumnType_" + strongType).toString();
        }
        else {
            type.DisplayName = displayName;
        }
        type.Category = category;
        type.SortOrder = sortOrder ?? 0;
        if (options != null) {
            type.Options |= options;
        }
        type.Icon = icon;
        type.aliasID = aliasID;
        if(values != null) {
            type.values = values;
        }
        if(defaultValue != null) {
            type.defaultValue = defaultValue;
        }
        type.HasRequiredSubscription = hasRequiredSubscription;
        types.push(type);
    }

    static getColumnTypeCategories(): ColumnTypeCategory[] {
        let categories: ColumnTypeCategory[] = [];

        this.pushColumnTypeCategory(categories, ColumnTypeCategoryName.frequent, 0)
        this.pushColumnTypeCategory(categories, ColumnTypeCategoryName.text, 1);
        this.pushColumnTypeCategory(categories, ColumnTypeCategoryName.number, 2);
        this.pushColumnTypeCategory(categories, ColumnTypeCategoryName.visual, 3);
        this.pushColumnTypeCategory(categories, ColumnTypeCategoryName.lookUp, 4);
        this.pushColumnTypeCategory(categories, ColumnTypeCategoryName.date, 5);
        this.pushColumnTypeCategory(categories, ColumnTypeCategoryName.media, 6);

        this.pushColumnTypeCategory(categories, ColumnTypeCategoryName.row, 7);
        this.pushColumnTypeCategory(categories, ColumnTypeCategoryName.formulas, 8);

        return categories;
    }
     


    static pushColumnTypeCategory(categories: ColumnTypeCategory[], name: string, sortOrder: number) {
        if (!categories)
            return;
        let cat = new ColumnTypeCategory();
        cat.Name = name;
        cat.DisplayName = i18n.t("ColumnTypeCategory_" + name).toString();
        cat.SortOrder = sortOrder;

        categories.push(cat);
    }

    static getColumnTypesWithCategoriesSelectorItems(betaTypes: boolean = false): any[] {
        let result: any[] = [];

        ColumnTypes.getColumnTypeCategories().forEach(category => {
            if(category.Name != ColumnTypeCategoryName.formulas && category.Name != ColumnTypeCategoryName.frequent) {
                result.push({header: i18n.t("ColumnTypeCategory_"+ category.Name).toString(), category: category.Name});
                ColumnTypes.getTypesForCategory(category.Name, true, betaTypes).forEach(type => {
                    result.push({name: i18n.t("ColumnType_" + type.StrongType).toString(),
                        value: type.StrongType,
                        group: type.Category });
                });
            }
        });

        return result;
    }

    static getTypesForCategory(category: string, trueTypes: boolean = false, betaTypes: boolean = false): ColumnType[] {
        if(category == ColumnTypeCategoryName.frequent) {
            return ColumnTypes.getFrequentTypes(betaTypes);
        }
        return ColumnTypes.getColumnTypes(betaTypes).filter(type => type.Category == category && (trueTypes ? !type.aliasID : true));
    }

    static getFrequentTypes(betaTypes: boolean = false): ColumnType[] {
        return ColumnTypes.getColumnTypes(betaTypes).filter(type => type.isFrequent);
    }


}