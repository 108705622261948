
import { Component, Prop, Vue } from "vue-property-decorator";
import * as agGrid from 'ag-grid-community';
import * as API from "@/api/Api";
import * as RowShare from "@/models/RowShare";
import { EventBus } from "@/modules/EventBus";
import { ApiError, GridErrorEventParams } from "@/models/RowShare";
import { ListTreeModule } from '@/store/ListTreeStore';
import RowVM from '@/viewModels/Table/rowVM';
import CopyMoveRowsDialog from '@/views/Table/ListActions/CopyMoveRowsDialog.vue';
import RemindUsersDialog from '@/views/Table/ListActions/RemindUsersDialog.vue';
import ListDisplayModeVM from "@/viewModels/Table/listDisplayModeVM";
import { ApiUtils } from "@/api/ApiUtils";
import { Utils } from "@/utils/Utilities";
import AssignationDialog from '@/views/Table/ListActions/AssignationDialog.vue';
import { UserModule } from "@/store/UserStore";
import ColorPicker from "@/views/components/ColorPicker.vue";
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

@Component({ name: "SelectedRowsBar", components: { AssignationDialog, CopyMoveRowsDialog, RemindUsersDialog, ColorPicker } })
export default class SelectedRowsBar extends Vue {
    @Prop() selectedRows!: agGrid.IRowNode[]
    @Prop() displayModeVM!: ListDisplayModeVM;
    selectedColor: string = "";


    get cellSelected() {
        return this.displayModeVM?.cellSelected ?? false;
    }

    get selectedRowVMs() {
        return this.selectedRows.map(node => <RowVM>node.data);
    }

    isCloneRowsModalOpen: boolean = false;
    isCopyMoveRowsModalOpen: boolean = false;
    isRemindUsersModalOpen: boolean = false;

    isCurrentModalLoading: boolean = false;
    isCloningAtBottom: boolean = false;
    isCloningAfterEach: boolean = false;

    onColorSelected(value: string) {
        if(this.selectedColor == undefined) {
            return;
        }
        EventBus.$emit(RowShare.Event.GRID_CHOOSE_CELL_COLOR, this.selectedColor);
    }

    get canChooseColor() {
        return (this.cellSelected && !this.specialRowsDisplayed && this.isLoggedIn);
    }

    get isLoggedIn() {
        var user = UserModule.CurrentUser;
        return user != null && !user.IsAnonymous;
    }

    /** Clone rows **/
    cloneErrorMessage: string = '';
    apiErrors: ApiError[] = [];

    get hasApiErrors() {
        return this.apiErrors.length > 0;
    }

    get hasErrors() {
        return this.apiErrors.length > 0 || !Utils.isNullOrWhiteSpace(this.cloneErrorMessage);
    }

    get specialRowsDisplayed(): boolean {
        return this.displayModeVM?.listVM?.specialRowsDisplayed;
    }

    get deletedRowsDisplayed(): boolean {
        return this.displayModeVM?.listVM?.deletedRowsDisplayed;
    }

    get archivedRowsDisplayed(): boolean {
        return this.displayModeVM?.listVM?.archivedRowsDisplayed;
    }

    get hasArchiveRows(): boolean {
        return this.currentOrganization?.HasArchiveRows ?? false;
    }

    get hasLockRows(): boolean {
        return this.currentOrganization?.HasLockRows ?? false;
    }

    get hasRowMerge(): boolean {
        return this.currentOrganization?.HasRowMerge ?? false;
    }

    get canCloneRows() {
        if (this.specialRowsDisplayed) {
            return false;
        }
        if (this.selectedRows.length <= 0)
            return false;

        if (!this.displayModeVM) {
            return false;
        }

        if (this.displayModeVM.listVM.hasUniqueColumns)
            return false;

        if (this.selectedRows.length == 1 && this.displayModeVM.listVM.canAddNewRow)
            return true;
        else
            return this.displayModeVM.listVM.canAddSeveralNewRows;
    }

    get canCloneRowsAfterEach() {
        return !this.displayModeVM.isSorted;
    }

    onCloneRowsClicked() {
        this.isCloneRowsModalOpen = true;
        this.cloneErrorMessage = "";
    }

    async onCloneRowsModalAllAtBottomClicked() {
        await this.cloneSelectedRows(true);
    }

    async onCloneRowsModalAfterEachClicked() {
        await this.cloneSelectedRows(false);
    }

    async onCloseCloneDlg() {
        if (this.isCloningAtBottom || this.isCloningAfterEach) {
            return;  //no close possible during cloning : blocking call for consistency and performance (only one final loadforparent) 
        }
        this.closeAndResetAllModals();
    }

    async cloneSelectedRows(insertAtBottom: boolean) {
        this.cloneErrorMessage = "";
        this.apiErrors = [];
        let lastIndex = this.currentList!.RowCount - 1;
        if (insertAtBottom) {
            this.isCloningAtBottom = true;
        }
        else {
            this.isCloningAfterEach = true;
        }
        try {
            if (insertAtBottom) {
                let result = await API.Row.cloneMultiple(this.selectedRowsIds, insertAtBottom, RealTimeCollaborationModule.connection.connectionId);
                if (result?.IsValid) {
                    let clonedRows = result.Value;
                    var nbCloned = clonedRows.length;
                    for (let currCloned = 0; currCloned < nbCloned; currCloned++) {
                        let refresh = (currCloned == nbCloned - 1)
                        await this.displayModeVM.insertClonedRowInUI(clonedRows[currCloned], lastIndex + 1, refresh);
                        lastIndex++;
                    }
                    this.displayModeVM.scrollToIndex(lastIndex);
                }
                else {
                    this.apiErrors = result?.Errors ?? [];
                }
            }
            else {
                var nbSelectedRows = this.selectedRows.length;
                for (let currCloned = 0; currCloned < nbSelectedRows; currCloned++){
                    let rowVM = <RowVM>(this.selectedRows[currCloned].data);
                    let clonedRow = await API.Row.clone(rowVM.rsRow.Id, insertAtBottom, RealTimeCollaborationModule.connection.connectionId);
                    var index = this.displayModeVM.getRowNodeIndex(this.selectedRows[currCloned].id ?? "");
                    var newAgIdx = index == -1 ? undefined : (index + 1);
                    let refresh = (currCloned == nbSelectedRows - 1)
                    await this.displayModeVM.insertClonedRowInUI(clonedRow, newAgIdx, refresh);
                }
            }
            EventBus.$emit(RowShare.Event.ROW_ADDED, <RowVM>this.selectedRows[0].data);
            EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, this);
        }
        catch (err: any) {
            this.cloneErrorMessage = err.message;
        }
        finally {
            this.isCloningAtBottom = false;
            this.isCloningAfterEach = false;
        }

        if (!this.cloneErrorMessage && !this.hasApiErrors) {
            this.closeAndResetAllModals(true);
        }

    }

    /** Archive rows **/
    get canArchiveRows() {
        return (this.currentList?.Owned ?? false) && !this.specialRowsDisplayed && !this.selectionHasLockedRows;
    }

    onArchiveRowsClicked() {
        if (!this.hasArchiveRows) {
            EventBus.$emit(RowShare.Event.SHOW_UPSELL_DIALOG, <RowShare.ShowUpsellDialogEventParams>{
                title: this.$i18n.t('UpsellDialog_Title').toString(),
                description: this.$i18n.t('UpsellDialog_Description_ArchiveRows').toString()
            });
        }
        else {
            var evtArgs = new RowShare.ConfirmationRequiredEventParams();
            if (this.selectedRows.length == 1) {
                evtArgs.title = this.$i18n.t(`ArchiveConfirmation_SingleRowTitle`, [this.selectedRows[0].data.rsRow.DescriptorFormattedValue]).toString();
                evtArgs.description1 = this.$i18n.t(`ArchiveConfirmation_SingleRowDescription`, [this.selectedRows[0].data.rsRow.DescriptorFormattedValue]).toString();
            } else {
                evtArgs.title = this.$i18n.t(`ArchiveConfirmation_Title`, [this.selectedRows.length]).toString();
                evtArgs.description1 = this.$i18n.t(`ArchiveConfirmation_Description`, [this.selectedRows.length]).toString();
            }
            evtArgs.cancelButtonText = this.$i18n.t("Common_Cancel").toString();
            evtArgs.actionButtonText = this.$i18n.t(`ArchiveConfirmation_ConfirmButton`).toString();
            evtArgs.actionButtonIcon = "archive";
            evtArgs.actionButtonColor = "primary";

            evtArgs.onConfirmation = async () => {
                try {
                    const alreadySavedRows = this.selectedRows.filter(r => !r.data.isNew);
                    let apiRes: RowShare.ApiResult<boolean> | null = null;

                    if (alreadySavedRows.length > 0) {
                        apiRes = await API.Row.archive(alreadySavedRows.map(node => node.data.rsRow.Id), RealTimeCollaborationModule.connection.connectionId);
                    }

                    if (apiRes) {
                        if (apiRes.IsValid && apiRes.Value) {
                            var vms = this.selectedRows.map(node => <RowVM>node.data);
                            EventBus.$emit(RowShare.Event.ROW_ARCHIVE, vms);
                        }
                        else {
                            EventBus.$emit(RowShare.Event.ROW_ERROR, <GridErrorEventParams>{ message: this.$i18n.t(apiRes.Errors[0].ExceptionCode.toString()).toString() });
                        }
                    }
                } catch (err: any) {
                    EventBus.$emit(RowShare.Event.ROW_ERROR, <GridErrorEventParams>{ message: err._message });
                }
            }

            EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, evtArgs);
        }
    }

    get canUnarchiveRows() {
        return (this.currentList?.Owned ?? false) && this.archivedRowsDisplayed;
    }

    async onUnarchiveRowsClicked() {
        let result = await API.Row.unarchive(this.selectedRowsIds, RealTimeCollaborationModule.connection.connectionId);
        if (result) {
            if (result.IsValid) {
                EventBus.$emit(RowShare.Event.ROW_UNARCHIVE, this.selectedRowVMs);
                this.closeAndResetAllModals(true);
            }
            else {
                EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 5000,
                    title: this.$i18n.t('SelectedRows_UnarchiveRowsError').toString(),
                    type: RowShare.NotificationType.error,
                    duplicateId: 'UnarchiveRows',
                    message: this.$i18n.t(result.Errors[0].ExceptionCode.toString()).toString()
                });
            }
        }
    }

    /** Delete rows **/

    get canDeleteRows() {
        return ((!this.selectedRows.find(node => !(<RowVM>(node.data)).rsRow.CanSuppress))
            && ((this.currentList?.Owned ?? false)
                || !(this.currentList?.HasUserReadOnlyColumn ?? false)
                && !(this.currentList?.HasUserHiddenColumn ?? false))
            && !this.selectionHasLockedRows);
    }

    onDeleteRowsClicked() {
        let parentRowsInSelection = false;
        this.selectedRows.forEach(row => {
            if (row?.data.rsRow?.IsParentRow) {
                parentRowsInSelection = true;
                return;
            }
        });

        if (parentRowsInSelection) {
            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                message: this.$i18n.t('SelectedRows_CannotDeleteReferenced').toString(),
                autoHide: true,
                autoHideAfterMs: 10000,
                type: 'error',
                duplicateId: 'CannotDeleteRows'
            });
        }
        else {
            var evtArgs = new RowShare.ConfirmationRequiredEventParams();
            if (this.selectedRows.length == 1) {
                evtArgs.title = this.$i18n.t(`SelectedRows_${this.deletedRowsDisplayed ? 'Hard' : ''}DeleteModal_SingleRowTitle`, [this.selectedRows[0].data.rsRow.DescriptorFormattedValue]).toString();
                evtArgs.description1 = this.$i18n.t(`SelectedRows_${this.deletedRowsDisplayed ? 'Hard' : ''}DeleteModal_SingleRowDescription`, [this.selectedRows[0].data.rsRow.DescriptorFormattedValue]).toString();
            } else {
                evtArgs.title = this.$i18n.t(`SelectedRows_${this.deletedRowsDisplayed ? 'Hard' : ''}DeleteModal_Title`, [this.selectedRows.length]).toString();
                evtArgs.description1 = this.$i18n.t(`SelectedRows_${this.deletedRowsDisplayed ? 'Hard' : ''}DeleteModal_Description`, [this.selectedRows.length]).toString();
            }
            evtArgs.cancelButtonText = this.$i18n.t("SelectedRows_DeleteModal_CancelButton").toString();
            evtArgs.actionButtonText = this.$i18n.t(`SelectedRows_${this.deletedRowsDisplayed ? 'Hard' : ''}DeleteModal_ConfirmButton`).toString();
            evtArgs.actionButtonIcon = "trash-alt";
            evtArgs.actionButtonColor = "error";

            evtArgs.onConfirmation = async () => {
                try {
                    const alreadySavedRows = this.selectedRows.filter(r => !r.data.isNew);
                    let apiRes: boolean | null = true;

                    if (alreadySavedRows.length > 0) {
                        apiRes = await API.Row.deleteBatch(alreadySavedRows.map(node => node.data.rsRow.Id), this.deletedRowsDisplayed, RealTimeCollaborationModule.connection.connectionId);
                    }

                    if (apiRes) {
                        var vms = this.selectedRows.map(node => <RowVM>node.data);
                        EventBus.$emit(RowShare.Event.ROW_DELETE, vms);
                    }
                } catch (err: any) {
                    EventBus.$emit(RowShare.Event.ROW_ERROR, <GridErrorEventParams>{ message: err._message });
                }
            }

            EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, evtArgs);
        }
    }

    get deleteRowsButtonLabel(): string {
        return this.deletedRowsDisplayed ? this.$i18n.t('SelectedRows_HardDelete').toString() : this.$i18n.t('SelectedRows_Delete').toString();
    }
    /** Assignation **/

    get canAssignRows() {
        return !this.selectedRows.find(node => !(<RowVM>(node.data)).rsRow.CanAssign) && !this.specialRowsDisplayed && !this.selectionHasLockedRows;
    }

    onAssignRowsClicked() {
        EventBus.$emit(RowShare.Event.ASSIGNROWS_REQUEST, this.selectedRowVMs);
    }

    /** Copy / move rows **/

    get canCopyMoveRows(): boolean {
        return (this.currentList?.Owned ?? false) && !this.specialRowsDisplayed;
    }

    onCopyMoveRowsClicked() {
        this.isCopyMoveRowsModalOpen = true;
    }

    /** RowMerge **/
    get rowMergeGenerationContext(): RowShare.ReportGenerationContext {
        let context = new RowShare.ReportGenerationContext();
        context.rowIds = this.selectedRowsIds;
        context.totalTableRowCount = this.currentList?.RowCount ?? 0;
        context.hasReadOnlyRows = this.readOnlyRowsInSelection;
        return context;
    }

    get canRunRowMerge(): boolean {
        return (ListTreeModule.currentListCapabilities?.rowMerge ?? false) && !this.specialRowsDisplayed
            && (this.currentList?.ReportCount ?? 0) > 0;
    }

    onRowMergeClicked() {
        if (!this.hasRowMerge) {
            EventBus.$emit(RowShare.Event.SHOW_UPSELL_DIALOG, <RowShare.ShowUpsellDialogEventParams>{
                title: this.$i18n.t('UpsellDialog_Title').toString(),
                description: this.$i18n.t('UpsellDialog_Description_RowMerge').toString()
            });
        }
        else {
            EventBus.$emit(RowShare.Event.ROWMERGE_REQUEST, this.rowMergeGenerationContext);
        }
    }

    /** Remind users **/
    get canRemindUsers(): boolean {
        return (this.currentList?.Owned ?? false) && !this.specialRowsDisplayed && !this.selectionHasLockedRows;
    }

    onRemindUsersClicked() {
        this.isRemindUsersModalOpen = true;
    }

    //** Restore rows **/
    async onRestoreRowsClicked() {
        let result = await API.Row.Restore(this.selectedRowsIds);
        if (result) {
            if (result.IsValid) {
                EventBus.$emit(RowShare.Event.ROW_RESTORE, this.selectedRowVMs);
                this.closeAndResetAllModals(true);
            }
            else {
                EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 5000,
                    title: this.$i18n.t('SelectedRows_RestoreRowsError').toString(),
                    type: RowShare.NotificationType.error,
                    duplicateId: 'RestoreRows',
                    message: this.$i18n.t(result.Errors[0].ExceptionCode.toString()).toString()
                });
            }
        }
    }

    /** Lock/Unlock rows */
    public get selectionHasLockedRows(): boolean {
        if (!this.selectedRows || this.selectedRows.length === 0) {
            return false;
        }
        return this.selectedRows.findIndex(sr => (<RowVM>sr.data)?.isLocked ?? -1) > -1;
    }

    public get selectionHasUnlockedRows(): boolean {
        if (!this.selectedRows || this.selectedRows.length === 0) {
            return false;
        }
        return this.selectedRows.findIndex(sr => !(<RowVM>sr.data)?.isLocked ?? -1) > -1;
    }

    public get canLockRows(): boolean {
        return (this.currentList?.Owned ?? false) && !this.specialRowsDisplayed && this.selectionHasUnlockedRows;
    }

    async onLockRowsClicked() {
        if (!this.hasLockRows) {
            EventBus.$emit(RowShare.Event.SHOW_UPSELL_DIALOG, <RowShare.ShowUpsellDialogEventParams>{
                title: this.$i18n.t('UpsellDialog_Title').toString(),
                description: this.$i18n.t('UpsellDialog_Description_LockRows').toString()
            });
        }
        else {
            let result = await API.Row.lock(this.selectedRowsIds, RealTimeCollaborationModule.connection.connectionId);
            if (result) {
                if (result.IsValid && result.Value) {
                    this.displayModeVM.updateRowsInUI(result.Value);
                    this.closeAndResetAllModals(true);
                }
                else {
                    EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                        autoHide: true,
                        autoHideAfterMs: 5000,
                        title: this.$i18n.t('SelectedRows_LockRowsError').toString(),
                        type: RowShare.NotificationType.error,
                        duplicateId: 'LockRows',
                        message: this.$i18n.t(result.Errors[0].ExceptionCode.toString()).toString()
                    });
                }
            }
        }
    }

    public get canUnlockRows(): boolean {
        return (this.currentList?.Owned ?? false) && !this.specialRowsDisplayed && this.selectionHasLockedRows;
    }

    async onUnlockRowsClicked() {
        let result = await API.Row.unlock(this.selectedRowsIds, RealTimeCollaborationModule.connection.connectionId);
        if (result) {
            if (result.IsValid && result.Value) {
                this.displayModeVM.updateRowsInUI(result.Value);
                this.closeAndResetAllModals(true);
            }
            else {
                EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 5000,
                    title: this.$i18n.t('SelectedRows_UnlockRowsError').toString(),
                    type: RowShare.NotificationType.error,
                    duplicateId: 'UnlockRows',
                    message: this.$i18n.t(result.Errors[0].ExceptionCode.toString()).toString()
                });
            }
        }
    }

    /** Commons **/
    async mounted() {
    }

    closeAndResetAllModals(clearSelection: boolean = false) {
        this.isCloneRowsModalOpen = false;
        this.isCopyMoveRowsModalOpen = false;
        this.isRemindUsersModalOpen = false;

        this.isCurrentModalLoading = false;
        this.isCloningAtBottom = false;
        this.isCloningAfterEach = false;
        if (clearSelection) {
            this.displayModeVM.deselectAllRows();
        }
    }

    get currentOrganization(): RowShare.ListTreeOrganization | null {
        return ListTreeModule.currentOrganization;
    }

    get currentList(): RowShare.List | null {
        return ListTreeModule.currentList;
    }

    get selectedRowsIds(): string[] {
        let rowIds: string[] = [];
        for (let i = 0; i < this.selectedRows.length; ++i) {
            let rowVM = <RowVM>(this.selectedRows[i].data);
            if (rowVM.rsRow.Id) {
                rowIds.push(rowVM.rsRow.Id)
            }
        }
        return rowIds;
    }

    get selectedrsRows(): RowShare.Row[] {
        let rsRows: RowShare.Row[] = [];
        for (let i = 0; i < this.selectedRows.length; ++i) {
            let rowVM = <RowVM>(this.selectedRows[i].data);
            if (rowVM.rsRow.Id) {
                rsRows.push(rowVM.rsRow)
            }
        }
        return rsRows;
    }

    get readOnlyRowsInSelection(): boolean {
        for (let i = 0; i < this.selectedRows.length; ++i) {
            let rowVM = <RowVM>(this.selectedRows[i].data);
            if (!rowVM.rsRow.CanUpdate || rowVM.isLocked) {
                return true;
            }
        }
        return false;
    }

    public GetErrorDescription(error: RowShare.ApiError) {
        return ApiUtils.GetApiErrorDescription(error);
    }
}
