
import {Component, Prop } from 'vue-property-decorator';
import { ReturnUrlHandler } from '@/views/ReturnUrl';
import { Organization } from '@/models/RowShare';

@Component({
    name:'JoinSociety'
})
export default class JoinSociety extends ReturnUrlHandler{

    @Prop() organization!:Organization;

    redirectToCompany(){
        this.$emit("join-company");
    }
    
}
