
    import { Component, Vue} from "vue-property-decorator";
    import { ApplicationModule } from '@/store/ApplicationStore';
    import { OnPremModule } from '@/store/OnPremStore';
    import DefaultLayout from "@/views/layouts/DefaultLayout.vue";
    import IframeLayout from "@/views/layouts/IframeLayout.vue";
    import ErrorLayout from "@/views/layouts/ErrorLayout.vue";
    import SignupLayout from "@/views/layouts/SignupLayout.vue";
    import LogInLayout from "@/views/layouts/LogInLayout.vue";
    import MsTeamsLayout from "@/views/layouts/MsTeamsLayout.vue";
    import BackOfficeLayout from "@/views/layouts/BackOfficeLayout.vue";
    import Cookie from "js-cookie";
    import { UserModule } from '@/store/UserStore';
    import { loadAndSetWithUserLanguageAsync } from '@/modules/Localization';
    import * as API from '@/api/Api';

    @Component({ 
        components: { 
            DefaultLayout, ErrorLayout, SignupLayout, IframeLayout, LogInLayout, MsTeamsLayout, BackOfficeLayout
        } 
    })
    export default class App extends Vue {

        private header: string = "";
        

        async mounted () {
            var isInExcel = location.search.indexOf('excel') > 0;
            var isOnPrem = process.env.VUE_APP_ISONPREM ? (process.env.VUE_APP_ISONPREM.toLowerCase() == "true") : false;
            var isDevEnv = window?.location?.hostname === "www.rowsharedev.com";
            OnPremModule.updateIsOnPrem(isOnPrem);

            if ((!OnPremModule.isOnPrem)&&
                (process.env.VUE_APP_APPINSIGHTS_ID) && (process.env.VUE_APP_APPINSIGHTS_ID !== '')){
                ApplicationModule.updateAppInsightsKey(process.env.VUE_APP_APPINSIGHTS_ID) ;
                }
            
            // loading GTM and recaptcha, but skipping it for Excel Sync, OnPrem and dev
            if(!isInExcel && !isOnPrem && !isDevEnv){
                this.$loadScript('/api/script/load/gtm');
            }

            // Check if on mobile app or not
            let isInMobileApp = true;
            var isMobileCookie = Cookie.get('IsMobile');
            isInMobileApp = isMobileCookie ? (isMobileCookie.toLowerCase() == "true") : false;
            ApplicationModule.updateDevice(isInMobileApp);
            
            //get password complexity rule
            let passwordRegEx = await API.Configuration.getPasswordRegex();
            ApplicationModule.updatePasswordRegex(passwordRegEx);

            setInterval(async () => {
                var isAnonymous = !UserModule.connectedUser || UserModule.connectedUser.IsAnonymous;

                await UserModule.refreshUser();
                if(!isAnonymous && (!UserModule.connectedUser || UserModule.connectedUser.IsAnonymous))
                    window.location.reload();
            }, 900000); 
        }
        
        get pageLayout() {
            if(this.$route.name == null)
                return "IframeLayout";

            if(ApplicationModule.isMsTeamsApp) {
                return "MsTeamsLayout";
            }
            
            return this.$route.meta!.layout || "DefaultLayout";
        }
    }
