
import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { FormValidation } from '@/views/components/FormValidation';

@Component({
    name:'InputSelect',
    components: { ValidationProvider }
})

export default class InputSelect extends FormValidation{

    //Properties
     @Prop() inputSelectName!:{
         type:string,
         required:true
     };
     @Prop() inputSelectId!:{
         type:string,
         required:true};
     @Prop() inputSelectValidationRules!:string;
     @Prop() optionsSelectList!:[];
     @Prop() value!:string;
     @Prop() inputSelectVid!:string;
     @Prop() placeholder!:string;
     @Prop() focusInput!:boolean;

    //Methods
    emitSelectValueChanged(valueParam:string){
        this.$emit('input', valueParam);
    }

    mounted() {
        if(this.focusInput) {
            this.$nextTick(() => {
                (<HTMLElement>this.$refs.inputSelect)?.focus();
            });
        }
    }
}

