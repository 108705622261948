
    import {Vue, Component, Prop } from 'vue-property-decorator';
    import { EventBus } from "@/modules/EventBus";
    import * as API from '@/api/Api';
    import * as Models from '@/models/RowShare';
    import { OnPremModule } from '@/store/OnPremStore';
    import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
    import Bigram from "@/views/components/Bigram.vue";
    import moment from 'moment';

    @Component({
        name: 'BackOfficeUser',
        components: {PageLoadSpinner, Bigram}
    })
    export default class BackOfficeUser extends Vue{
        @Prop() private userId!: string;

        user: Models.BackOfficeUser | null = null;
        
        async created() {
            this.user == null;
            if(this.userId) {
                this.user = await API.BoApi.loadUser(this.userId);
            }
        }

        formatDate(date: string | Date){
            var from = moment(date, undefined, 'en').fromNow();
            var exact = moment(String(date), undefined, 'en').format("YYYY-MM-DD HH:mm");

            return from + " (" + exact + ")";
        }

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;;
        }

        async saveInternal() {
            if(!this.user) {
                return;
            }
            
            var newUser = await API.BoApi.saveUserProperty(this.user.Email, 'internal', (this.user.Internal).toString());                
            this.$emit('userUpdated', newUser);
            this.reportSave(true, "updating user");
        }

        async saveIsLockedOut() {
            if(!this.user) {
                return;
            }
            var newUser = await API.BoApi.saveUserProperty(this.user.Email, 'locked', (this.user.IsLockedOut).toString());                
            this.$emit('userUpdated', newUser);
            this.reportSave(true, "updating user");
        }

        async saveIsRSAdmin() {
            if(!this.user) {
                return;
            }
            var newUser = await API.BoApi.saveUserProperty(this.user.Email, 'rsadmin', (this.user.IsAdministrator).toString());                
            this.$emit('userUpdated', newUser);
            this.reportSave(true, "updating user");
        }

        deleteUser() {
            if(!this.user) {
                return;
            }
            var evtArgs = new Models.ConfirmationRequiredEventParams();
            evtArgs.title = "Delete user";
            evtArgs.description1 = "Delete the RowShare account for " + this.user.Email + " ?";
            evtArgs.cancelButtonText = "Cancel";
            evtArgs.actionButtonText = "Confirm";
            evtArgs.actionButtonIcon = "trash-alt";
            evtArgs.actionButtonColor = 'error';

            evtArgs.onConfirmation = async () => {
                if(!this.user) {
                    return;
                }
                var res = await API.BoApi.deleteUser(this.user);                
                if(res) {
                    this.$emit('userDeleted', this.user);
                    this.$router.push({name: 'bo'});
                }
                
                this.reportSave(res, "deleting user");
            }

            EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, evtArgs);
        }
        
        reportSave(res: boolean | null, operation: string){
            if(res) {
                EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 3000,
                    type: Models.NotificationType.success,
                    message: "Done " + operation
                });
            } else {
                EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 3000,
                    type: Models.NotificationType.error,
                    message: "Failed " + operation
                });
            }
        }

        copyLink() {
        }

        getConfirmationLink(code: string) {
            document.addEventListener('copy',(event) => this.copyToClipboard(event, code));
            document.execCommand('copy');
            EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{
                autoHide: true,
                autoHideAfterMs: 3000,
                type: Models.NotificationType.success,
                message: 'Link copied'
            });            
        }

        copyToClipboard(event: ClipboardEvent, code: string) {
            let url = `${window.location.protocol}//${window.location.host}/ConfirmEmail/${code}`;            
            event.clipboardData?.setData('text/plain', url);
            event.preventDefault();
            document.removeEventListener('copy', (event) => this.copyToClipboard(event, code));
        }

    }
