
import {Component, Prop, Watch} from 'vue-property-decorator';
import * as Models from '@/models/RowShare';
import {
    ColumnStrongType,
    ConfirmationRequiredEventParams,
    Event,
    GlobalNotificationEventParams,
    NotificationType
} from '@/models/RowShare';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import ColumnSettingsLookupEditor from '@/views/Table/ColumnSettings/ColumnSettingsLookupEditor.vue';
import {FormValidation} from '@/views/components/FormValidation';
import {Column, List} from '@/api/Api';
import {ValidationObserver} from 'vee-validate';
import {RawLocation, Route} from 'vue-router';
import InputText from '@/views/components/FormInputs/InputText.vue';
import InputTextArea from '@/views/components/FormInputs/InputTextArea.vue';
import {ColumnTypes} from '@/utils/ColumnTypes';
import ColumnSettingsLookupListEditor from '@/views/Table/ColumnSettings/ColumnSettingsLookupListEditor.vue';
import ColumnSettingsRelatedColumnsEditor from '@/views/Table/ColumnSettings/ColumnSettingsRelatedColumnsEditor.vue';
import ColumnSettingsColorEditor from '@/views/Table/ColumnSettings/ColumnSettingsColorEditor.vue';
import {EventBus} from '@/modules/EventBus';
import {Utils} from '@/utils/Utilities';
import {RowShareException} from '@/api/ApiUtils';
import {UserModule} from '@/store/UserStore';
import ColumnSelector from '@/views/Table/ColumnSettings/ColumnSelector.vue';
import {ColumnType, ColumnTypeCategory, ColumnTypeCategoryName} from "@/models/ColumnType";
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

@Component({
    name: 'ColumnSettings',
    components: { PageLoadSpinner,
        ColumnSettingsLookupEditor,
        ValidationObserver,
        InputText,
        InputTextArea,
        ColumnSettingsLookupListEditor,
        ColumnSettingsRelatedColumnsEditor,
        ColumnSettingsColorEditor,
        ColumnSelector
    }
})
export default class ColumnSettings extends FormValidation {
    @Prop() listId!: string;
    @Prop() columnId!: string;

    private defaultProps = { maxHeight: 200 };
    currentColumn: Models.Column | null = new Models.Column();
    currentList: Models.List | null = new Models.List();
    originalColumn: Models.Column = new Models.Column();
    validForm: boolean = true;
    selectedColumnId: string | null = '';
    columnTypeError: string  = '';
    disableRelatedColumnsSelection: boolean = false;
    previousColumnType: Models.ColumnStrongType | null = null;

    async created(){
        if(this.listId){
            this.currentList = await List.load(this.listId);
        }
        this.currentColumn = await Column.load(this.columnId);
        Object.assign(this.originalColumn, this.currentColumn);
        this.selectedColumnId = this.columnId;
        this.previousColumnType = this.currentColumn!.Type;
        window.addEventListener('beforeunload', this.beforeUnload);
    }

    beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeUnload);
    }

    returnToTable() {
        this.$router.push(<RawLocation>{name: 'Table', params: {listId: this.listId}});
    }

    async validateForm(): Promise<boolean> {
        this.columnTypeError = "";
        let isValid = await (<any>this.$refs.observer).validate();
        isValid = await isValid && (<any>this.$refs.form).validate();
        if(isValid) {
            if(!this.currentColumn?.Type) {
                this.columnTypeError = this.$i18n.t('ColumnSettings_MandatoryProperty', [this.$i18n.t('ColumnSettings_Type').toString()]).toString();
                return false;
            }
        }
        return isValid;
    }

    //hasProhibitedChanges(): boolean {
    //		const prohibitedTypes = [
    //				//adding prohibited items about lookup list
    //				ColumnStrongType.LookUpList,
    //				ColumnStrongType.LookUp,
    //				//adding prohibited items about metadata
    //				ColumnStrongType.OwnerName, ColumnStrongType.OwnerEmail, ColumnStrongType.CreationDate,
    //				ColumnStrongType.CreatedByUserEmail, ColumnStrongType.LastWriteDate,
    //				ColumnStrongType.LastWriteUserName, ColumnStrongType.MemberManagerName, ColumnStrongType.RowIndex,
    //				//adding prohibited items about related row
    //				ColumnStrongType.Relation
    //		];
    //
    //		return this.previousColumnType !== this.currentColumn!.Type
    //			&& prohibitedTypes.includes(this.previousColumnType!) && prohibitedTypes.includes(this.currentColumn!.Type);
    //}

    async updateColumnSettings() {
        this.disableRelatedColumnsSelection = true;
        let isValid = await this.validateForm();
        if(isValid){
            if(this.currentColumn && this.columnSettingsHaveChanged) {
                if(this.currentColumn.Type != this.originalColumn?.Type && this.currentColumn.Type == ColumnStrongType.LookUp && !this.currentColumn.LookupValues) {
                    this.currentColumn.LookupValues = ColumnTypes.defaultLookupValues;
                }
                this.currentColumn.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
                Column.save(this.currentColumn, {clearCache: true})
                    .then(savedColumn => {
                        Object.assign(this.originalColumn, this.currentColumn);
                        EventBus.$emit(Event.COLUMN_INFOS_CHANGED, savedColumn);
                        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: this.$i18n.t('ColumnSettings_SaveSuccess'),
                            type: NotificationType.success,
                            autoHide: true, autoHideAfterMs: 4000,
                            duplicateId: 'ColumnSaveSuccess'});
                        setTimeout(() => { this.disableRelatedColumnsSelection = false}, 0);
                    })
                    .catch(exc => {
                        let rsExc = exc as RowShareException;
                        let exceptionMsg = "";
                        if(rsExc) {
                            exceptionMsg = rsExc.message;
                        }
                        else {
                            exceptionMsg = this.$i18n.t('ColumnSettings_SaveError').toString();
                        }
                        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: exceptionMsg,
                            type: NotificationType.error,
                            autoHide: true, autoHideAfterMs: 10000});
                        setTimeout(() => { this.disableRelatedColumnsSelection = true}, 0);
                    });
            }
        }
    }

    get columnSettingsHaveChanged() {
        return this.currentColumn && JSON.stringify(this.currentColumn) != JSON.stringify(this.originalColumn);
    }

    beforeRouteLeave(to: Route, from: Route, next: any){
        this.handleBeforeRouteLeave(() => next());
    }

    async canLeaveRoute() {
        let isValid = await this.validateForm();
        return (isValid || !this.columnSettingsHaveChanged);
    }

    async beforeUnload(evt: BeforeUnloadEvent) {
        let canLeave = await this.canLeaveRoute();
        if(!canLeave){
            evt.returnValue = this.$i18n.t('NotSavedItemsDialog_Description');
            evt.preventDefault();
        }
    }

    async handleBeforeRouteLeave(callback : () => void) {
        let canLeave = await this.canLeaveRoute();
        if(!canLeave){
            let event = new ConfirmationRequiredEventParams();
            event.title = this.$i18n.t('NotSavedItemsDialog_Title').toString();
            event.description1 = this.$i18n.t('NotSavedItemsDialog_Description').toString();
            event.cancelButtonText = this.$i18n.t('NotSavedItemsDialog_Cancel').toString();
            event.actionButtonText = this.$i18n.t('NotSavedItemsDialog_Validate').toString();
            event.actionButtonColor = "primary";
            event.onConfirmation = async () => {
                callback();
            };
            EventBus.$emit(Event.CLEAR_ALL_GLOBAL_NOTIFICATIONS);
            EventBus.$emit(Event.CONFIRMATION_REQUIRED, event);
        }
        else {
            callback();
        }
    }

    @Watch('columnId')
    async onColumnIdChanged(){
        this.currentColumn = await Column.load(this.columnId);
        this.selectedColumnId = this.columnId;
    }

    onSelectedColumnIdChanged() {
        this.handleBeforeRouteLeave(() => {
            this.$router.push(<RawLocation>{name: 'ColumnSettings', params: {listId: this.listId, columnId: this.selectedColumnId}});
        });
    }

    onLookupValuesChanged(value: string){
        if(this.currentColumn){
            this.currentColumn.LookupValues = value;
            this.updateColumnSettings();
        }
    }

    onLinkedColumnChanged(link: any) {
        if(link && this.currentColumn) {
            this.currentColumn.LookupListColumnName = link.linkedColumnName;
            this.currentColumn.LookupListId = link.linkedListId;
            this.updateColumnSettings();
        }
    }

    async onRelatedListChanged(event: any) {
        if(event && this.currentColumn) {
            this.currentColumn.RelatedListId = event.RelatedListId;
            this.updateColumnSettings()
        }
    }

    get columnTypes(): any[] {
        let types = ColumnTypes.getColumnTypesWithCategoriesSelectorItems(UserModule.connectedUser?.PreviewFeaturesEnabled ?? false);

        const typesToRemoveCriteria = (item : Models.ColumnStrongType) =>
            ColumnType.metadataTypes().includes(item)
            //|| ColumnType.lookupListTypes().includes(item)
            || ColumnType.relatedRowTypes().includes(item);

        if(typesToRemoveCriteria(this.currentColumn!.Type)){
            return types;
        }
        let filteredType = types.filter(item => !typesToRemoveCriteria(item.value));
        //removing header.
        //filteredType = filteredType.filter(item => !item.category || item.category !== ColumnTypeCategoryName.lookUp);
        filteredType = filteredType.filter(item => !item.category || item.category !== ColumnTypeCategoryName.row);
        return filteredType;
    }

    get isNotAllowedColumnType(): boolean {
        if (!this.currentColumn)
            return true;
        if (ColumnType.metadataTypes().includes(this.currentColumn.Type))
            return true;
        /*else if (ColumnType.lookupListTypes().includes(this.currentColumn.Type))
            return true;*/
        else if (ColumnType.relatedRowTypes().includes(this.currentColumn.Type))
            return true;
        return false;
    }

    get cannotUpdateType(): boolean {
        return ((this.currentColumn?.Type === ColumnStrongType.Relation && !Utils.isNullOrWhiteSpace(this.currentColumn.RelatedListId)) || this.isNotAllowedColumnType);
    }

    @Watch('currentColumn.Type')
    resetValues() {
        if(this.currentColumn?.Type === ColumnStrongType.LookUp) {
            if(Utils.isNullOrWhiteSpace(this.currentColumn.LookupValues)) {
                this.currentColumn.LookupValues = ColumnTypes.defaultLookupValues;
            }
            this.currentColumn.MaxLength = 0;
        }
    }

    isValidNumber(value: any): boolean | string {
        let valStr = value?.toString() ?? "";
        if(Utils.isNullOrWhiteSpace(valStr)) {
            return true;
        }
        const regExp = /^[\-]?\d*$/;

        var number: number | null = null;
        if(this.currentColumn?.Type === ColumnStrongType.DecimalNumber || this.currentColumn?.Type === ColumnStrongType.Percentage) {
            number = Utils.parseNumber(valStr, null);
        }
        else {
            number = Utils.parseInteger(valStr, null)
        }

        return (number != null) ? true : this.$i18n.t('ColumnSettings_InvalidNumber').toString();
    }
}
