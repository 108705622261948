
import { Component, Vue } from 'vue-property-decorator';
import { ICellEditorParams } from 'ag-grid-community';
import ColumnVM from '@/viewModels/Table/columnVM';
import { Row } from '@/api/Api';
import RowVM from '@/viewModels/Table/rowVM';
import { EventBus } from '@/modules/EventBus';
import * as Models from '@/models/RowShare';

@Component({ name: "RelationEditor"})
export default class RelationEditor extends Vue{
    params!: ICellEditorParams;

    async mounted(){
        let column = (<ColumnVM>this.params.colDef)?.rsColumn;
        if(column?.IsReadOnly) {
            this.params.api.stopEditing(true);
            return;
        }
        this.openRelationDialog();
    }

    openRelationDialog(){
        EventBus.$emit(Models.Event.ROW_EDIT_RELATED_ROWS, <Models.rowEditRelatedRowsEventParams> {
            rowVM: (<RowVM>this.params.data),
            relatedListId: (<ColumnVM>this.params.colDef)?.rsColumn?.RelatedListId,
            column: (<ColumnVM>this.params.colDef)?.rsColumn,
            refreshParentRow: () => {
                this.refreshParentRow();
            }
        });
    }

    isPopup() { return false; }

    getValue() { return this.params.value; }

    cancel() {
        setTimeout(() => {
            this.params.api?.stopEditing(true);
            this.params.api?.setFocusedCell(this.params.rowIndex, this.params.column);
        }, 0);
    }

    async refreshParentRow() {
        let rsRow = <RowVM>this.params.data;
        this.params.api?.stopEditing(true); //On fait croire à AG-Grid qu'on n'a pas modifié la cellule
    }

}
