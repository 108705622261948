
import { IColumnDescription } from '@/viewModels/Table/IColumnDescription';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {UserModule} from "@/store/UserStore";
import * as API from "@/api/Api";
import {ListTreeModule} from "@/store/ListTreeStore";
import {Member} from "@/models/Member";

@Component({name: "ColumnDescription"})
export default class ColumnDescription extends Vue {
    @Prop() colDesc!: IColumnDescription;

    get isAdmin() {
        return ListTreeModule.currentList?.Owned
    }
}
