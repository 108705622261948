
import {Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/modules/EventBus';
import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
import * as RowShare from '@/models/RowShare';

@Component({
    name:'GlobalConfirmationDialog',
    components: {ConfirmationDialog}
})
export default class GlobalConfirmationDialog extends Vue{

    isVisible: boolean = false;
    isLoading: boolean = false;
    currentParams: RowShare.ConfirmationRequiredEventParams | null = null;

    created(){
        EventBus.$on(
            RowShare.Event.CONFIRMATION_REQUIRED,
            this.onConfirmationRequired
        );
    }

    destroyed(){
        EventBus.$off(RowShare.Event.CONFIRMATION_REQUIRED, this.onConfirmationRequired);
    }

    onSubmit(){
        this.isLoading = true;

        if(this.currentParams) {
            this.currentParams.onConfirmation().then(() => {
                this.isVisible = false;
                this.currentParams = null;
            });
        } else {
            this.isVisible = false;
            this.currentParams = null;
        }
    }

    onCancel(){
        this.isVisible = false;
        this.currentParams = null;
    }

    onConfirmationRequired(params: RowShare.ConfirmationRequiredEventParams){
        if(this.isVisible) {
            console.error("Error: another global confirmation dialog is already open");
            return;
        }

        this.isLoading = false;
        this.currentParams = params;
        this.isVisible = true;
    }
}

