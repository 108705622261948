
    import { Component, Prop, Vue } from "vue-property-decorator";
    import * as Models from "@/models/RowShare";
    import ListThumbnail from "./ListThumbnail.vue";
    import FolderThumbnail from "./FolderThumbnail.vue";
    import { ArrayUtils } from '@/utils/Array';
    import { EventBus } from '@/modules/EventBus';
    import { JsonObjectSearch } from '@/utils/Search';

    @Component({
        components: {
            ListThumbnail,
            FolderThumbnail
        }
    })
    export default class SearchResult extends Vue{
        
        private organizationId: string = '';
        private folder : Models.ListTreeFolder | null = null;
        
        private searchText : string = '';
        private folders: Array<Models.ListTreeFolder> = [];
        private lists: Array<Models.ListThumbnailInfo> = [];
        private deletedFolders: Array<Models.ListTreeFolder> = [];
        private deletedLists: Array<Models.ListThumbnailInfo> = [];
        private sortDirection : boolean | 'asc' | 'desc' = 'asc';

        get orderedFolders(): Array<Models.ListTreeFolder>{
            return ArrayUtils.sort(
                        this.folders, 
                        [(folder: { Name: string; }) => folder.Name.toLowerCase()],
                        this.sortDirection);
        }

        get orderedLists(): Array<Models.ListThumbnailInfo>{
            return ArrayUtils.sort(
                        this.lists, 
                        [(list: { Name: string; }) => list.Name.toLowerCase()], 
                        this.sortDirection);
        }

        get orderedDeletedFolders(): Array<Models.ListTreeFolder>{
            return ArrayUtils.sort(
                        this.deletedFolders, 
                        [(folder: { Name: string; }) => folder.Name.toLowerCase()],
                        this.sortDirection);
        }

        get orderedDeletedLists(): Array<Models.ListThumbnailInfo>{
            return ArrayUtils.sort(
                        this.deletedLists, 
                        [(list: { Name: string; }) => list.Name.toLowerCase()], 
                        this.sortDirection);
        }
        
        get resultNumber(): number {
            return this.orderedFolders.length + this.orderedLists.length;
        }

        get deletedResultNumber(): number {
            return this.orderedDeletedFolders.length + this.orderedDeletedLists.length;
        }

        get allResultNumber(): number {
            return this.resultNumber + this.deletedResultNumber;
        }

        created(){
            EventBus.$on(Models.Event.SEARCH_TRIGGERED, 
                (searchText: string, 
                 folder: Models.ListTreeFolder, 
                 orgId: string) => {
                    this.folder = folder;
                    this.organizationId = orgId;
                    this.onSearchTriggered(searchText);
            });
        }

        onSearchTriggered(searchText: string){
            this.searchText = searchText;

            if(searchText !== '') {
                let currentFolder = this.folder ? this.folder: null;
                let searchResult: Array<any> = JsonObjectSearch.searchAllNodesByName(searchText, currentFolder);
                
                let items = searchResult.filter(result => result.ParentId ?
                                                            (!result.IsRecycleBin && !result.IsInRecycleBin) :
                                                            !result.IsInRecycleBin);
                                                            
                this.folders = items.filter(result => result.ParentId 
                                                      && result.ParentId!='00000000000000000000000000000000');
                this.lists = items.filter(result => !result.ParentId);

                let deletedItems = searchResult.filter(result => result.ParentId ? 
                                                                    (!result.IsRecycleBin && result.IsInRecycleBin) : 
                                                                    result.IsInRecycleBin);
                this.deletedFolders = deletedItems.filter(result => result.ParentId);
                this.deletedLists = deletedItems.filter(result => !result.ParentId);
            }
        }

        resetSearch(){
            EventBus.$emit(Models.Event.SEARCH_RESET);
        }

        toggleSortDirection(){
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        }
    }
