
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import InputText from '@/views/components/FormInputs/InputText.vue';
    import * as API from '@/api/Api';
    import * as RowShare from '@/models/RowShare';
    import { ListTreeModule } from '@/store/ListTreeStore';
    import { OnPremModule } from '@/store/OnPremStore';
import { Utils } from '@/utils/Utilities';

    @Component({
        name: "MyAccountSubscriptionList",
        components: { InputText }
    })
    export default class MyAccountSubscriptionList extends Vue  {

        @Prop() user!: RowShare.User;

        private organizations: Array<RowShare.Organization> | null;
        private orgBeingDeleted: RowShare.Organization | null = null;
        private isDeleteSubscriptionModalOpen = false;
        private subscriptionToDeleteNameConfirmation: string = '';
        private deleteSubscriptionModalWasFirstConfirmed = false;
        private isDeletingSubscription = false;
        private hasSubscriptionDeletionError = false;
        private subscriptionToDeleteErrors: string = '';

        constructor(){
            super();
            this.organizations = new Array<RowShare.Organization>();
        }

        async mounted(){
            this.reloadSubscriptions();
        }

        async reloadSubscriptions(bypassCache: boolean = false){
            this.organizations = await API.Organization.loadall({ cache: !bypassCache });
        }

        openDeleteSubscriptionModal(org: RowShare.Organization){
            if (OnPremModule.isOnPrem)
                return;
                
            this.orgBeingDeleted = org;
            this.subscriptionToDeleteNameConfirmation = '';
            this.isDeleteSubscriptionModalOpen = true;
            this.deleteSubscriptionModalWasFirstConfirmed = false;
            this.isDeletingSubscription = false;
            this.hasSubscriptionDeletionError = false;
            this.subscriptionToDeleteErrors = "";
        }

        firstConfirmDeleteSubscription(){
            if(this.orgBeingDeleted?.Name == this.subscriptionToDeleteNameConfirmation)
                this.deleteSubscriptionModalWasFirstConfirmed = true;
        }

        lastConfirmDeleteSubscription(){
            if (OnPremModule.isOnPrem)
                return;

            if(!this.orgBeingDeleted)
                return;

            this.isDeletingSubscription = true;
            API.Organization.deleteSubscription(this.orgBeingDeleted.Id, this.subscriptionToDeleteNameConfirmation).then(res => {
                if(!res){
                    this.handleDeleteSubscriptionError();
                    return;
                }

                this.isDeletingSubscription = false;
                this.isDeleteSubscriptionModalOpen = false;

                this.reloadSubscriptions(true);
                ListTreeModule.refreshOrganizationsTree();
            }).catch(err => {
                this.handleDeleteSubscriptionError();
            });
        }

        handleDeleteSubscriptionError(){
                this.hasSubscriptionDeletionError = true;
        }

        protected get getDeleteSubscriptionModalTitle() {
            return this.$i18n.t('MyAccount_DeleteSubscriptionModal_Title', {
                'SubscriptionName': this.orgBeingDeleted?.Name,
            }).toString();
        }

        protected get getDeleteSubscriptionWarningText() {
            if(!this.orgBeingDeleted)
                return "error";

            var pattern = this.orgBeingDeleted.MemberCount > 1 ? 'MyAccount_DeleteSubscriptionModal_Description1_HasMembers' : 'MyAccount_DeleteSubscriptionModal_Description1_IsTheOnlyMember';
             
            return this.$i18n.t(pattern, {
                'TableCount': this.orgBeingDeleted.ListCount,
                'MemberCount': this.orgBeingDeleted.MemberCount - 1,
                'SubscriptionName': this.orgBeingDeleted.Name,
            }).toString();
        }

        protected get getDeleteSubscriptionLastWarningText() {
            if(!this.orgBeingDeleted)
                return "error";

            return this.$i18n.t("MyAccount_DeleteSubscriptionModal_LastWarning", {
                'SubscriptionName': this.orgBeingDeleted.Name,
            }).toString();
        }

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;
        }

        @Watch('subscriptionToDeleteNameConfirmation')
        validateSubscriptionName() {
            if(!this.orgBeingDeleted) {
                return false;
            }
            if(Utils.isNullOrWhiteSpace(this.subscriptionToDeleteNameConfirmation)) {
                this.subscriptionToDeleteErrors = this.$i18n.t('Common_MandatoryField', {fieldName: this.$i18n.t('MyAccount_DeleteSubscriptionModal_InputValidationName').toString()}).toString();
                return false;
            }
            if(this.subscriptionToDeleteNameConfirmation !== this.orgBeingDeleted.Name) {
                this.subscriptionToDeleteErrors = this.$i18n.t('Common_InvalidField', {fieldName: this.$i18n.t('MyAccount_DeleteSubscriptionModal_InputValidationName').toString()}).toString();
                return false;
            }
            this.subscriptionToDeleteErrors = "";
            return true;
        }
        
    }
