
import {Component, Prop } from 'vue-property-decorator';
import {Event} from '@/models/RowShare';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import { ReturnUrlHandler } from "@/views/ReturnUrl";
import SubscriptionConfigurator from "@/views/ManageOrganization/SubscriptionConfigurator.vue";
import { EventBus } from '@/modules/EventBus';
import OrganizationInfos from '@/views/ManageOrganization/OrganizationInfos.vue';
import Toolbar from '@/views/layouts/LayoutParts/Toolbar.vue';
import { ResLoader } from '@/utils/ResLoader';
declare var Calendly: any;

@Component({
    name:'SubscriptionCheckOut',
    components:{ PageLoadSpinner, SubscriptionConfigurator, OrganizationInfos, Toolbar},
})
export default class SubscriptionCheckOut extends ReturnUrlHandler {
    @Prop() private organizationId!: string | null;

    toolbarTitle: string = '';
    toolbarDescription: string = '';

    async created() {
        EventBus.$on(Event.LOCALE_CHANGED, async() => {
            this.onLocaleChanged();
        });
        await this.$loadScript('https://assets.calendly.com/assets/external/widget.js');
        ResLoader.loadCss('https://assets.calendly.com/assets/external/widget.css');
    }

    beforeDestroy() {
        EventBus.$off([Event.LOCALE_CHANGED]);
    }

    mounted(){
        this.onLocaleChanged();
    }
    
    onLocaleChanged(){
        this.toolbarTitle = this.$i18n.t('CheckOut_Title').toString();
        this.toolbarDescription = this.$i18n.t('CheckOut_Description').toString();            
    }

    scheduleMeeting() {
        Calendly.initPopupWidget({ url: this.$i18n.t('CheckOut_ScheduleMeetingUrl').toString() });
    }
}
