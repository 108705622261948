import _ from 'lodash';

export class ArrayUtils {
    static sort(
        array: Array<any>, 
        sortField: string | object, 
        sortDirection: boolean | 'asc' | 'desc' = 'asc')
    : Array<any>
    {
        return _.orderBy(array, sortField, sortDirection);
    }
}