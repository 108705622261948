
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as API from "@/api/Api";
import * as Models from '@/models/RowShare';
import { ColumnStrongType, LookUpItem } from '@/models/RowShare';
import { Utils } from '@/utils/Utilities';
import Multiselect from 'vue-multiselect';
import RsColumnManager from '@/columnProxies/RsColumnManager';
import RowVM from '@/viewModels/Table/rowVM';
import ListVM from '@/viewModels/Table/listVM';

@Component({ name: 'LookupField', components:{ Multiselect }})
export default class LookupField extends Vue {
    @Prop() rowVM !: RowVM;
    @Prop() column !: Models.Column;
    @Prop() listVM !: ListVM;

    selectedValues: LookUpItem[] | LookUpItem | null = null;
    //isLookupMultiValued: boolean = false;
    lookupItems: LookUpItem[] = [];
    loadingItems: boolean = false;

    public get isLookupMultiValued(): boolean {
        if(this.column) {
            return this.column.isLookupMultiValued;
        }
        return false;
    }

    async init() {
        if(this.column) {
            this.loadingItems = true;
            this.initListItems()
                .then(() => {                    
                    this.loadingItems = false;
                    this.initSelectedValues();
                });
        }
    }

    initSelectedValues() {
        const proxy  = RsColumnManager.getProxy(this.column.Type);

        if(this.isLookupMultiValued) {
            let stringValues = <string[]>this.rowVM.getValue(this.column, proxy);
            if(stringValues && stringValues.length > 0) {
                this.selectedValues = [];
                for (const strVal of stringValues) {
                    let val = this.lookupItems?.find(item => item.name == strVal) ?? null;
                    if(val)
                        this.selectedValues.push(val);
                    else if (strVal) {
                        let newItem = new LookUpItem(strVal, "");
                        this.selectedValues.push();
                        this.lookupItems.push(newItem);
                    }
                }
                this.selectedValues = this.lookupItems?.filter(item => stringValues.includes(item.name));
            } else {
                this.selectedValues = null;
            }
        } else {
            var value = this.rowVM.getValue(this.column, proxy);
            var strVal = value ? value[0] : null;
            this.selectedValues = this.lookupItems?.find(item => item.name == strVal) ?? null;
            if(this.selectedValues == null && strVal?.length > 0) {
                this.selectedValues = new LookUpItem(strVal, "");
                this.lookupItems.unshift(this.selectedValues);
            } else if(this.selectedValues != null) {
                // we move the item in first position because vuemultiselect won't let us center on it when opening
                this.lookupItems.splice(this.lookupItems.indexOf(this.selectedValues), 1);
                this.lookupItems.unshift(this.selectedValues); 
            }
        }
    }

    async initListItems() {        
        if(this.column) {
            if(this.column.Type == ColumnStrongType.LookUpList) {
                this.lookupItems = (await API.List.loadChoice(this.column.LookupListId, this.column.LookupListColumnName))?.map(item => new LookUpItem(item, "")) ?? [];
            }
            else {
                this.lookupItems = this.column.parsedLookupItems;
            }
        }
    }
    
    activate(){
        (<any>(this.$refs.multiselect)).activate();
    }

    isSelected(item: LookUpItem) {
        if(this.isLookupMultiValued && Utils.isArray(this.selectedValues)) {
            let idx = this.selectedValues.findIndex(i => i.name == item.name);
            return idx > -1;
        } else {
            return this.selectedValues == item;
        }
    }

    getValue(){
        let res: string[] = [];
        if(this.selectedValues) {
            if(this.isLookupMultiValued && Utils.isArray(this.selectedValues)) {
                res = this.selectedValues.map(item => item.name);
            }
            else {
                res = [(<LookUpItem>this.selectedValues)?.name];
            }
        }
        return res;
    }

    onValueChanged() {
        this.$emit('valueChanged', this.getValue())
    }

}
