import { ApiUtils, IRequestOptions } from "./ApiUtils"
import { Utils } from "@/utils/Utilities";

export class SocialProvider {
    static async login(providerName: string, returnUrl: string = "", inviteLinkId: string = "", options: IRequestOptions | null = null)  : Promise<string | null> {
        return SocialProvider.loginOrSignup("login", providerName, returnUrl, inviteLinkId, options)
    }

    static async signup(providerName: string, returnUrl: string = "", inviteLinkId: string = "", options: IRequestOptions | null = null)  : Promise<string | null> {
        return SocialProvider.loginOrSignup("signup", providerName, returnUrl, inviteLinkId, options)
    }

    private static async loginOrSignup(action: string, providerName: string, returnUrl: string = "", inviteLinkId: string = "", options: IRequestOptions | null = null) 
    : Promise<string | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let queryString = returnUrl !== "" ? `?ReturnUrl=${ApiUtils.encodeSegment(returnUrl)}` : "";
        if(!Utils.isNullOrWhiteSpace(inviteLinkId)) {
            queryString += Utils.isNullOrWhiteSpace(queryString) ? "?" : "&";
            queryString += `inviteLinkId=${ApiUtils.encodeSegment(inviteLinkId)}`;
        }
        return await ApiUtils.apiGet(`/socialproviders/${action}/${providerName}${queryString}`, options)
                        .then(response => response.json<string>());
    }
}