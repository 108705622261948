
import { AccessLevel } from '@/models/RowShare';
import {Component, Vue, Prop, Model, Provide, ModelSync} from 'vue-property-decorator';

@Component({name: 'AccessLevelSwitch'})
export default class AccessLevelSwitch extends Vue {
    @Prop({default: "primary"}) noneColor!: string;
    @Prop({default: "primary"}) ownedColor!: string;
    @Prop({default: "primary"}) allColor!: string;
    @Prop() dense!: boolean;
    @Prop({default: false}) twoStateMode!: boolean;
    @Prop({default: false}) disabled!: boolean;
    @ModelSync('value', 'change') selectedValue!: AccessLevel | boolean;

    get noneLevelSelected(): boolean {
        if(this.twoStateMode) {
            return this.selectedValue === false;
        }
        return this.selectedValue === AccessLevel.none;
    }

    get ownedLevelSelected(): boolean {
        if(this.twoStateMode) {
            return false;
        }
        return this.selectedValue === AccessLevel.ownedOnly;
    }

    get allLevelSelected(): boolean {
        if(this.twoStateMode) {
            return this.selectedValue === true;
        }
        return this.selectedValue === AccessLevel.all;
    }

    toggleLevel() {
        if(this.disabled) {
            return;
        }
        if(this.twoStateMode) {
            this.selectedValue = !this.selectedValue;
        }
        else {
            switch(this.selectedValue) {
                case AccessLevel.none:
                    this.selectedValue = AccessLevel.ownedOnly;
                    break;
                case AccessLevel.ownedOnly:
                    this.selectedValue = AccessLevel.all;
                    break;
                case AccessLevel.all:
                    this.selectedValue = AccessLevel.none;
                    break;
            }
        }
    }
}
