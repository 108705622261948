
    import { Component, Prop, Vue, Watch } from "vue-property-decorator";
    import * as Router from "vue-router";
    import * as API from "@/api/Api";
    import * as RowShare from "@/models/RowShare";
    import PageTitle from "@/views/layouts/LayoutParts/PageTitle.vue";
    import ListBrowser from "@/views/MyTables/ListBrowser.vue";
    import { Utils } from "@/utils/Utilities";
    import { TrackingUtils } from "@/utils/Tracking";
    import { ListTreeModule } from '@/store/ListTreeStore';

    @Component({
        name: "MyTables",
        components: { PageTitle, ListBrowser }
    })
    export default class MyTables extends Vue {
        @Prop() organizationId!: string | null;
        @Prop() folderId!: string | null;
        @Prop({default: null}) lmCurrent!: string | null;
    }
