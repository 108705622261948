
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as API from '@/api/Api';
import * as RowShare from '@/models/RowShare';
import JobGrid from '@/views/RowMerge/JobGrid.vue';
import InputText from '@/views/components/FormInputs/InputText.vue';
import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import ListToolbar from '@/views/Table/ListActions/ListToolbar.vue';
import SharingBar from '@/views/Table/ShareTable/SharingBar.vue';
import ListVM from '@/viewModels/Table/listVM';
import { EventBus } from '@/modules/EventBus';
import { Event, GlobalNotificationEventParams } from "@/models/RowShare";
import { RowShareException } from '@/api/ApiUtils';
import RowMergeDialog from '@/views/Table/ListActions/RowMergeDialog.vue';

@Component({
    name: "RowMerge",
    components: { InputText, ConfirmationDialog, ListToolbar, SharingBar, JobGrid, PageLoadSpinner, RowMergeDialog }
})
export default class RowMerge extends Vue  {

    @Prop() private listId!:string;
    private listModel: RowShare.List = new RowShare.List();
    private listVM : ListVM = new ListVM();

    private reports: RowShare.Report[] | null = null;
    private jobs: RowShare.Job[] = [];

    private loadingReports = true;
    private loadingJobs = true;

    columns: RowShare.Column[] | null = [];
    loadingColumns: boolean = false;
    reportTypeIsCopyToColumn: boolean = false;

    private reportFields = [
        //{value: 'FileName', text: this.$i18n.t('RowMerge_ReportTemplate').toString()},
        {value: 'DisplayName', text: this.$i18n.t('RowMerge_ReportName').toString()},
        {value: 'OutputFormat', text: this.$i18n.t('RowMerge_ReportOutputFormat').toString()},
        {value: 'OutputDestination', text: this.$i18n.t('RowMerge_OutputDestination').toString()},
        {value: 'Published', text: this.$i18n.t('RowMerge_ReportPublished').toString()},
        {value: 'Menu', text: '', sortable: false},
    ];

    created(){
        this.loadList(true);
        this.loadReports();
        this.loadJobs();
        this.loadColumns();
    }

    destroy() {
        RowShare.Job.clearAllJobUpdateTimeout();
    }

    loadList(useCache: boolean = true) {
        API.List.load(this.listId,{cache: useCache}).then(listResult => {
            if(listResult){
                this.listModel = listResult;
                this.listVM.list = this.listModel;
            }
        });
    }

    private async loadReports() {
        try {
            this.loadingReports = true;
            var reports = await API.Report.loadByList(this.listId);
            if (!reports || reports?.length == 0) {
                this.reports = null;
                return;
            } else {
                reports.forEach(report => {
                    report.updateConversionStatusAsync(5000);
                });
            }

            this.reports = reports.sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1));
        } finally {
            this.loadingReports = false;
        }
    }

    private async loadJobs() {
        try {
            this.loadingJobs = true;
            var jobs = await API.Job.loadByList(this.listId, RowShare.JobType.ListReport);

            if (!jobs) {
                this.jobs = [];
                return;
            } else {
                jobs.forEach(job => {
                    job.updateStatusAsync(5000);
                });
            }

            this.jobs = jobs;
        } finally {
            this.loadingJobs = false;
        }
    }

    async loadColumns(){
        this.loadingColumns = true;
        await API.Column.loadForList(this.listId).then(cols => this.columns = cols)
        if (this.columns !== null){
            this.columns = this.columns.filter((col: RowShare.Column) => {
                return col.Type === RowShare.ColumnStrongType.File;
            });
        }
        this.loadingColumns = false;
    }

    get allOutputFormat() {
        return [
            {text: this.$i18n.t('RowMerge_Report_Auto').toString(), value: RowShare.ReportFormat.Auto},
            {text: this.$i18n.t('RowMerge_Report_Pdf').toString(), value: RowShare.ReportFormat.Pdf},
            {text: this.$i18n.t('RowMerge_Report_Docx').toString(), value: RowShare.ReportFormat.Docx},
        ];
    }

    get allColumnsByDestinationType(): any[] {
        let result: any[] = [
            {
                text: this.$i18n.t('RowMerge_DownloadAsFile').toString(),
                value: null,
            }
        ];
        if (this.columns !== null) {
            result.push({header: this.$i18n.t('RowMerge_CopyToColumn').toString()});
            this.columns.forEach(col => {
                result.push({
                    text: col.DisplayName,
                    value: col.Id
                })
            });
        }

        return result;
    }

    public async saveReport(report: RowShare.Report) {
        if (report.OutputDestinationId !== null) {
            report.OutputDestinationType = RowShare.OutputDestinationType.CopyToColumn;
        } else {
            report.OutputDestinationType = RowShare.OutputDestinationType.DownloadFile;
        }
        let res = await API.Report.save(report);
        if (res) {
            Object.assign(report, res);
        }
        this.reportTypeIsCopyToColumn = report.OutputDestinationType === RowShare.OutputDestinationType.CopyToColumn;
        // todo: handle file upload:

        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams> {
            message: this.$i18n.t('RowMerge_ReportSaveSuccess'),
            type: RowShare.NotificationType.success,
            autoHide: true,
            autoHideAfterMs: 4000
        });
    }

    pickFile(report: RowShare.Report){
        var input = document.getElementById("input-file-" + report.Id);
        // Clear fileList before adding new file.
        (<HTMLInputElement>input).value = "";
        input?.click();
    }

    async onFileChanged(report: RowShare.Report){
        try {

            var input = document.getElementById("input-file-" + report.Id);
            if(!input)
                return;

            var files = (<HTMLInputElement>input).files;
            if(!files || files.length <= 0)
                return;

            const formData = new FormData();
            formData.append("file", files[0]);
            var updatedReport = await API.Report.saveProperty("file", report.Id, formData);
            if(!updatedReport) {
                this.onFileError();
                return;
            }
            var oldPollingRef = report.pollingTimeout;
            Object.assign(report, updatedReport);
            report.pollingTimeout = oldPollingRef;
            report.updateConversionStatusAsync(5000);

        } catch(e: any) {
            this.onFileError(e);
            return;
        }
    }

    pickNewFile(){
        var input = document.getElementById("input-file-new");
        // Clear fileList before adding new file.
        (<HTMLInputElement>input).value = "";
        input?.click();
    }

    async onNewFileChanged(){
        var input = document.getElementById("input-file-new");
        if(!input)
            return;

        var files = (<HTMLInputElement>input).files;
        if(!files || files.length <= 0)
            return;

        var file = files[0];

        var newReport = new RowShare.Report();
        newReport.DisplayName = file.name;
        newReport.FileSize = file.size;
        newReport.FileName = file.name;
        newReport.OutputFormat = RowShare.ReportFormat.Auto;
        newReport.Published = true;
        newReport.ListId = this.listId;

        var savedReport = null;
        try {
            savedReport = await API.Report.save(newReport);
            if(!savedReport) {
                this.onFileError();
                return;
            }
            const formData = new FormData();
            formData.append("file", file);
            var updatedReport = await API.Report.saveProperty("file", savedReport.Id, formData);
            if(!updatedReport) {
                this.onFileError();
                return;
            }
        }
        catch(e: any) {
            this.onFileError(e);
            if(savedReport)
                await API.Report.remove(savedReport.Id);
            return;
        }

        updatedReport.updateConversionStatusAsync(5000);

        if(!this.reports)
            this.reports = [];

        this.reports.push(updatedReport);
    }

    onFileError(e: any | null = null){
        var message = this.$i18n.t('RowMerge_GenericUploadError').toString();

        let rsExc = e as RowShareException;
        if(rsExc)
            message = rsExc.message;

        let eventStorage = <GlobalNotificationEventParams> {
            title: this.$i18n.t('MyDocuments_FileError').toString(),
            message: message,
            type: 'error',
            autoHideAfterMs: 5000,
            autoHide: true
        }
        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, eventStorage);
    }

    testReport(report: RowShare.Report) {
        if(!report) {
            return;
        }
        EventBus.$emit(Event.ROWMERGE_REQUEST, <RowShare.ReportGenerationContext> {
            totalTableRowCount: this.listModel.RowCount,
            testMode: true,
            outputFormat: report.OutputFormat,
            reportId: report.Id
        });
    }

    newJobStarted(job: RowShare.Job) {
        if(!this.jobs)
            this.jobs = [];

        if(job) {
            this.jobs.unshift(job);
            job.updateStatusAsync(5000)
        }
    }

    confirmDeleteReport(id: string) {
        var evtArgs = new RowShare.ConfirmationRequiredEventParams();
        evtArgs.title = this.$i18n.t("RowMerge_ConfirmDeleteReport_Title").toString();
        evtArgs.description1 = this.$i18n.t("RowMerge_ConfirmDeleteReport_Description").toString();
        evtArgs.cancelButtonText = this.$i18n.t("RowMerge_ConfirmDeleteReport_CancelButton").toString();
        evtArgs.actionButtonText = this.$i18n.t("RowMerge_ConfirmDeleteReport_ConfirmButton").toString();
        evtArgs.actionButtonIcon = "trash-alt";
        evtArgs.actionButtonColor = "error";
        evtArgs.onConfirmation = async () => { this.deleteReport(id); };
        EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, evtArgs);
    }

    //When an item is deleted from the list, it is removed by splicing the array. This way, it's not necesarry
    //to call the API again to obtain all the elements.
    deleteReport(id:string){
        API.Report.remove(id).then(result=>{
            if(result){
                if(this.reports!=null) {
                    let element= this.reports.find(elem => elem.Id == id);
                    if(element) {
                        const index = this.reports.indexOf(element);
                        this.reports.splice(index, 1);
                        if(this.reports.length <= 0)
                            this.reports = null;
                    }
                };
            }
        });
    }

    getStatusColor(report: RowShare.Report): string{
        switch (report.ConversionStatus) {
            case RowShare.JobStatus.Unspecified:
            case RowShare.JobStatus.Retrying:
            case RowShare.JobStatus.Processing:
                return 'info';
            case RowShare.JobStatus.Finished:
                return 'success';
            case RowShare.JobStatus.NotStarted:
                return 'warning';
            case RowShare.JobStatus.Error:
            case RowShare.JobStatus.SourceDeleted:
                return 'error';
            default:
                return '';
        }
    }
}
