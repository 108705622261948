import { localize, extend } from "vee-validate";
import en from 'vee-validate/dist/locale/en.json';
import fr from 'vee-validate/dist/locale/fr.json';
import es from 'vee-validate/dist/locale/es.json';
import { Component, Vue } from 'vue-property-decorator';
import { required, email, min, max, confirmed, is, regex } from 'vee-validate/dist/rules';

//This class is used for form validation. It provides a method to add validation rules,
//to validate each input and to change language of error messages.
@Component({})
export class FormValidation extends Vue{

    created(){
        this.addValidationRules();    
    }

    addValidationRules(){
        extend("required",required);
        extend("email",email);
        extend("min", min);
        extend("max", max);
        extend("confirmed", confirmed);
        extend("is", is);
        extend("regex", regex);
    }

    getValidationState({ dirty = null, validated = null, valid = null }) {
        return dirty || validated ? valid : null;
    }
    
    setValidationLanguage(language:string){      
        //Vee-validate forms message errors
        switch(language){
            case("en"):{
                localize("en",en);
                break;
            }
            case("es"):{
                localize("es",es);
                break;
            }
            case("fr"):{
                localize("fr",fr);
                break;
            }
            default:{
                localize("en",en);
                break;
            }
        }
    }
};