import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"

export class Folder {
    static async load(id: string, options: IRequestOptions | null = null)
    : Promise<Models.Folder | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let response = await ApiUtils.apiGet("/folder/load/" + ApiUtils.encodeSegment(id), options);
        return response.nullableObject(Models.Folder);
    }

    static async loadForParent(id: string, options: IRequestOptions | null = null)
    : Promise<Array<Models.Folder> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let response = await ApiUtils.apiGet("/folder/loadforparent/" + ApiUtils.encodeSegment(id), options);
        return response.objects(Models.Folder);
    }

    static async loadAllForOrganization(accessMode: Models.AccessMode, organizationId: string, options: IRequestOptions | null = null)
    : Promise<Array<Models.Folder> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let url = `/folder/loadall/?d=1&am=${ApiUtils.encodeSegment(accessMode)}`;
        if(organizationId != null) {
            url += `&oid=${ApiUtils.encodeSegment(organizationId)}`;
        }
        let response = await ApiUtils.apiGet(url, options);
        return response.objects(Models.Folder);    
    }
    
    static async loadAll(depth: number, accessMode: Models.AccessMode, options: IRequestOptions | null = null)
    : Promise<Array<Models.Folder> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let response = await ApiUtils.apiGet(`/folder/loadall/?d=${ApiUtils.encodeSegment(depth)}&am=${ApiUtils.encodeSegment(accessMode)}`, options);
        return response.objects(Models.Folder);
    }

    static async import(id: string, file: File, options: IRequestOptions | null = null)
    : Promise<Models.Folder | Models.List[] | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let response = await ApiUtils.apiPostFile("/folder/import/" + ApiUtils.encodeSegment(id) +"/ImportWithMetadata", file, options);
        return response.json<Models.Folder | Models.List[]>();
    }

    static async create(organisationId: string, folderName: string, parentFolderId: string, options: IRequestOptions | null = null)
    : Promise<Models.Folder | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let creatingData = {
            OrganizationId : organisationId,
            FolderName : folderName,
            ParentFolderId : parentFolderId
        };
        let response = await ApiUtils.apiPostJson("/folder/create", creatingData, options);
        return response.object(Models.Folder);
    }

    static async rename(folderName: string, folderId: string, parentId: string, options: IRequestOptions | null = null)
    : Promise<string | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let renamingData = {
            ParentId : parentId,
            Id : folderId,
            DisplayName: folderName
        };
        let response = await ApiUtils.apiPostJson("/folder/rename", renamingData, options);
        return response.json<string>();
    }

    static async moveToRecycleBin(folderId: string, options: IRequestOptions | null = null)
    : Promise<Models.Folder | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let deletingData = {
            FolderId : folderId,
        };
        let response = await ApiUtils.apiPostJson("/folder/movetorecyclebin", deletingData, options);
        return response.object(Models.Folder);
    }

    static async loadOwners(folderId: string, options: IRequestOptions | null = null)
    : Promise<Array<Models.FolderOwner> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiGet("/folderowner/loadforparent/" + folderId, options);
        return response.objects(Models.FolderOwner);
    }

    static async saveOwner(folderOwner: Models.FolderOwner, options: IRequestOptions | null = null)
    : Promise<Models.FolderOwner | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiPostJson("/folderowner/save/", folderOwner, options);
        return response.object(Models.FolderOwner);
    }

    static async deleteOwner(folderOwner: Models.FolderOwner, options: IRequestOptions | null = null)
    : Promise<Models.FolderOwner | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiPostJson("/folderowner/delete/", folderOwner, options);
        return response.object(Models.FolderOwner);
    }

    static async setInheritAccessMode(inheritMode: any, options: IRequestOptions | null = null)
    : Promise<Models.ListTreeFolder | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiPostJson("/folder/save/", inheritMode, options);
        return response.object(Models.ListTreeFolder);
    }

    static async loadAccesses(folderId: string, options: IRequestOptions | null = null)
    : Promise<Array<Models.FolderAccess> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiGet("/folderaccess/loadforparent/"+ ApiUtils.encodeSegment(folderId), options);
        return response.objects(Models.FolderAccess);
    }

    static async saveAccess(access: Models.FolderAccess, options: IRequestOptions | null = null)
    : Promise<Models.FolderAccess | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiPostJson("/folderaccess/save/", access, options);
        return response.object(Models.FolderAccess);
    }

    static async deleteAccess(access: any, options: IRequestOptions | null = null)
    : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiPostJson("/folderaccess/delete/", access, options);
        return response.json<boolean>();
    }

    static emptyRecycleBin(folderId: string, options: IRequestOptions | null = null) : Promise<number | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let data = { FolderId : folderId };
        return ApiUtils.apiPostJson("/folder/emptyrecyclebin", data, options)
            .then(response=>response.json<number>());
    }

    static importRows(listId: string, file: File, options: IRequestOptions | null = null)
    : Promise<Models.ImportResult | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiPostFile(`/folder/ImportRows/${ApiUtils.encodeSegment(listId)}?v3=true`, file, options)
            .then(response => response.object(Models.ImportResult))
    }

}