import { DateTime } from '@/utils/Date';

export class Quotation {
    public UsersBilled!: number;
    public PlanId!: string;
    public PlanQuantity!: number;
    public PlanUserCount!: number;
    public PlanTableCount!: number;
    public PlanRowCount!: number;
    public PlanStorage!: number;
    public AddOnId!: string;
    public AddOnQuantity!: number;
    public AddOnUserCount!: number;
    public Price!: number;
    public CustomerId!: string;
    public Currency!: string;
    public RequestedUsers!: number;
    public HasOtherAddOns!: boolean;
    public FreePlan!: boolean;
    public BasePrice!: number;
    public ItemPriceId!: string;
    public get UserPrice() {
        var price = this.Price - this.BasePrice;
        var users = this.RequestedUsers - 5;
        if(users <= 0) {
            return price;
        }
        return price / users;
    }
}

export class HostedPage{
    public id! : string;
    public type! : string;
    public url! : string;
    public state! : string;
    public embed! : boolean;
    public created_at! : number;
    public expires_at! : number;
}

export class PortalConfig{
    public id!: string;
    public token!: string;
    public access_url!: string;
    public redirect_url!: string;
    public status!: string;
    public created_at!: DateTime;
    public expires_at!: DateTime;
    public customer_id!: string;
}

export enum PortalSections{
    SUBSCRIPTION_DETAILS = "sub_details",
    SUBSCRIPTION_CANCELLATION = "sub_cancel",
    EDIT_SUBSCRIPTION = "edit_subscription",
    VIEW_SCHEDULED_CHANGES = "scheduled_changes",
    ACCOUNT_DETAILS = "account_details",
    EDIT_ACCOUNT_DETAILS = "portal_edit_account",
    ADDRESS = "portal_address",
    EDIT_BILLING_ADDRESS = "portal_edit_billing_address",
    EDIT_SHIPPING_ADDRESS = "portal_edit_shipping_address",
    EDIT_SUBSCRIPTION_CUSTOM_FIELDS = "portal_edit_subscription_cf",
    PAYMENT_SOURCES = "portal_payment_methods",
    ADD_PAYMENT_SOURCE = "portal_add_payment_method",
    EDIT_PAYMENT_SOURCE = "portal_edit_payment_method",
    VIEW_PAYMENT_SOURCE = "portal_view_payment_method",
    CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION = "portal_choose_payment_method",
    BILLING_HISTORY = "portal_billing_history"
}

export class Plan {
    public Id !: string;
    public Name !: string;    
    public Limits !: Limits;
    public OfferId !:string;
    public UnlimitedPlan !: boolean;
    public SetupFees !: SetupFee[];
    public Prices !: ItemPrice[];
    public MandatoryAddOns !: MandatoryAddOn[];


    public getPrice(period: number, periodUnit: PeriodUnitEnum, currency: string, userCount: number): number {
        let price: number = 0;
        let usersToBill = userCount;
        var itemPrice = this.getItemPrice(period, periodUnit, currency);
        if(itemPrice != null) {
            if(itemPrice.PricingModel === PricingModelEnum.Tiered) {
                // Sort tiers
                itemPrice.Tiers.sort((x, y) => {
                    if(x.MinUnit === y.MinUnit) {
                        return 0;
                    }
                    else {
                        return (x.MinUnit < y.MinUnit) ? -1 : 1;
                    }
                });
                itemPrice.Tiers.forEach((tier) => {
                    if(usersToBill > 0) {
                        var userCountInTier = Math.min(usersToBill, tier.MaxUnit - tier.MinUnit + 1);
                        price += userCountInTier * tier.Price;
                        usersToBill -= userCountInTier;
                    }
                    else {
                        return;
                    }
                })
            }
            else {
                price = itemPrice.Price * userCount;
            }
        }
        price += this.getMandatoryAddOnsPrices(period, periodUnit, currency);
        return price;
    }

    public getItemPrice(period: number, periodUnit: PeriodUnitEnum, currency: string): ItemPrice | null {
        return this.Prices.find((pp: ItemPrice) => pp.Period === period && pp.PeriodUnit === periodUnit && pp.Currency === currency) ?? null;
    }
    
    public getUserPrice(period: number, periodUnit: PeriodUnitEnum, currency: string, userCount: number): number {
        var itemPrice = this.getItemPrice(period, periodUnit, currency);
        if(itemPrice != null) {
            if(itemPrice.PricingModel === PricingModelEnum.Tiered) {
                var tier: Tier | null = itemPrice.Tiers.find((t: Tier) => t.MinUnit <= userCount && t.MaxUnit >= userCount) ?? null;
                if(tier != null) {
                    return tier.Price;
                }
            }
            else {
                return itemPrice.Price;
            }
        }
        return 0;
    }

    public getBasePrice(period: number, periodUnit: PeriodUnitEnum, currency: string) : number {
        let price: number = 0;

        // First get the plan price (per unit or tiered)
        var itemPrice = this.Prices.find((ip: ItemPrice) => ip.Period === period && ip.PeriodUnit === periodUnit && ip.Currency === currency) ?? null;
        if(itemPrice != null) {
            // Find first tier price for Tiered plans
            if(itemPrice.PricingModel === PricingModelEnum.Tiered) {
                var tier: Tier | null = itemPrice.Tiers.find((t: Tier) => t.MinUnit === 1) ?? null;
                if(tier != null) {
                    price = tier.Price;
                }
            }
            // Otherwise get price directly from itemPrice
            else {
                price = itemPrice.Price;
            }
        }

        // Then get mandatory addons prices
        price += this.getMandatoryAddOnsPrices(period, periodUnit, currency);
        return price;
    }

    public getMandatoryAddOnsPrices(period: number, periodUnit: PeriodUnitEnum, currency: string) : number {
        let price: number = 0;
        if((this.MandatoryAddOns?.length ?? 0) > 0) {
            //For each mandatory addon get corresponding price (period, period unit and currency)
            this.MandatoryAddOns.forEach((ma: MandatoryAddOn) => {
                var addonPrice: ItemPrice | null = ma.Prices.find((aoip: ItemPrice) => aoip.Period === period && aoip.PeriodUnit === periodUnit && aoip.Currency === currency) ?? null;
                if(addonPrice != null) {
                    price += addonPrice.Price;
                }
            });
        }
        return price;
    }

    public getItemPriceById(priceId: string) : ItemPrice | null {
        return this.Prices.find((pp: ItemPrice) => pp.Id == priceId) ?? null;
    }

    public getSetupFees(currency: string): number {
        let fees = 0;
        this.SetupFees.forEach(sf => {
            if(sf.Currency === currency) {
                fees += sf.Price;
            }
        });
        return fees;
    }
}

export class Limits {
    public UserPerQuantity !:number;
    public FixedUserQuantity !:number;
    public TablePerQuantity !:number;
    public FixedTableQuantity !:number;
    public RowPerQuantity !:number;
    public FixedRowQuantity !:number;
    public TableSizePerQuantity !:number;
    public FixedTableSize !:number;
    public ReadOnlyUsersPerQuantity !:number;
    public FixedReadOnlyUsersQuantity !: number;

}

export class ItemPrice {
    public Id !: string;
    public PricingModel !: PricingModelEnum;
    public Period !:number;
    public PeriodUnit !: PeriodUnitEnum;
    public Price !:number;
    public Currency !:string;
    public Tiers!: Tier[];
}

export class SetupFee {
    public Id !: string;
    public Price !: number;
    public Currency !: string;
}

export class MandatoryAddOn
{
    public Id!: string;
    public PlanId!: string;
    public Prices!: ItemPrice[];
}

export class Tier
{
    public ItemPriceId!: string;
    public MinUnit!: number;
    public MaxUnit!: number;
    public Price!: number;

}



export enum PricingModelEnum {
    UnKnown = 0,
    FlatFee = 1,
    PerUnit = 2,
    Tiered = 3,
    Volume = 4,
    Stairstep = 5
}

export enum PeriodUnitEnum {
    UnKnown = 0,
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4
}
