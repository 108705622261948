import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"
import { OwnerAssign } from '@/viewModels/Table/OwnerViewModel';
import { Utils } from "@/utils/Utilities";
import { ApiResult } from "@/models/ApiResult";

export class Row {

    static load(id: string, options: IRequestOptions | null = null) 
    : Promise<Models.Row | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/row/load/" + ApiUtils.encodeSegment(id) + "?v3=true", options)
            .then(response => response.object(Models.Row));
    }

    static loadForList(listId: string | null, status: Models.DataStatus = Models.DataStatus.Normal, options: IRequestOptions | null = null) 
    : Promise<Array<Models.Row> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let onlySummary = true;
        if (status != Models.DataStatus.Normal)
            onlySummary = false;
        return ApiUtils.apiGet("/row/loadforparent/" + ApiUtils.encodeSegment(listId || "") + "?v3=true&onlySummary=" + onlySummary+ "&status=" + status, options)
            .then(response => response.objects(Models.Row));
    }

    static async loadAssignableContacts(listId: string | null, options: IRequestOptions | null = null) 
    : Promise<Array<Models.Owner> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let contacts = await ApiUtils.apiGet("/contact/loadforparent/" + ApiUtils.encodeSegment(listId || ""), options);
        return contacts.objects(Models.Owner);
    }

    static async loadAssignableGroups(organisationId: string | null, options: IRequestOptions | null = null) 
    : Promise<Array<Models.Group> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let groups = await ApiUtils.apiGet("/group/loadforparent/" + ApiUtils.encodeSegment(organisationId || ""), options);
        return groups.objects(Models.Group);
    }

    static async assignRowOwner(owner: OwnerAssign | null, options: IRequestOptions | null = null) 
    : Promise<Models.Row | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let groups = await ApiUtils.apiPostJson("/row/assign?v3=true", owner, options);
        return groups.object(Models.Row);
    }

    static save(row: Partial<Models.Row>, files: any | null = null, options: IRequestOptions | null = null)
    : Promise<Models.Row | null> {
        options = ApiUtils.createRequestOptions({cache: false}, options);
        if(Utils.isNullOrUndefined(files)) {
            return ApiUtils.apiPostJson("/row/save?v3=true", row, options).then(response => response.object(Models.Row));
        }
        else {
            return ApiUtils.apiPostJsonWithFiles("/row/save?v3=true", row, files, options).then(response => response.object(Models.Row));
        }
    }

    static saveWithoutFile(row: Partial<Models.Row>, options: IRequestOptions | null = null) 
    : Promise<Models.Row | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/row/save?v3=true", row, options)
            .then(response => response.object(Models.Row));
    }

    static saveWithFile(row: Models.Row, fileColumnName: string, file: Object | FileList | File | Blob | null, options: IRequestOptions | null = null) 
    : Promise<Models.Row | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJsonWithFilesAndColumnName("/row/save?v3=true", row, fileColumnName, file, options)
            .then(response => response.object(Models.Row));

    }

    static async loadRowComments(rowId: string, columnIndex: number, options: IRequestOptions | null = null) 
    : Promise<Array<Models.CommentDetails> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let comments = await ApiUtils.apiGet(`/comment/loadforparent/${rowId}/${columnIndex}`, options);
        return comments.objects(Models.CommentDetails);
    }

    static async saveRowComment(rowId: string, columnIndex: number, content: string, rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
    : Promise<Models.CommentDetails | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let data = { RowId: rowId, ColumnIndex: columnIndex, Content: content, RtcConnectionId: rtcConnectionId };
        let savedComment = await ApiUtils.apiPostJson("/comment/save", data, options);
        return savedComment.object(Models.CommentDetails);
    }

    static async deleteRowComment(comment: Models.CommentDetails, options: IRequestOptions | null = null)
    : Promise<Models.CommentDetails | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let removedComment = await ApiUtils.apiPostJson("/comment/delete", comment, options);
        return removedComment.object(Models.CommentDetails);
    }

    static async deleteRowFile(rowId: string, columnIndex: number,  rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
    : Promise<Models.Row | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let removedFile = await ApiUtils.apiPostJson(`/row/delete/${rowId}/${columnIndex}?v3=true`, {RtcConnectionId: rtcConnectionId}, options);
        return removedFile.object(Models.Row);
    }

    static async deleteRow(rowId: string, updateVersion: string, rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
    : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let removeData =  { Id: rowId, Version: updateVersion, RtcConnectionId: rtcConnectionId};
        let removedRow = await ApiUtils.apiPostJson('/row/delete', removeData, options);
        return removedRow.json<boolean>();
    }

    static async deleteBatch(rowIds: string[], hardDelete: boolean, rtcConnectionId: string | null = null, options: IRequestOptions | null = null): Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ clearCache: true }, options);
        let data = {hardDelete: hardDelete, rowIds: rowIds, RtcConnectionId: rtcConnectionId};
        return (await ApiUtils.apiPostJson("/row/deleteBatch/", data, options)).json<boolean>();
    }

    static async clone(rowId: string, insertAtBottom: boolean, rtcConnectionId: string | null = null, options: IRequestOptions | null = null): Promise<Models.Row> {
        options = ApiUtils.createRequestOptions({ clearCache: true }, options);
        let data = {
            Id: rowId,
            RtcConnectionId: rtcConnectionId,
            Options :{
                InsertAtBottom: insertAtBottom,
                CopyCreatedByUser: true
            }
        };
        return ApiUtils.apiPostJson("/row/clone?v3=true", data, options).then(response => response.object(Models.Row));
    }

    static async cloneMultiple(rowIds: string[], insertAtBottom: boolean, rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
    : Promise<ApiResult<Models.Row[]> | null> {
        options = ApiUtils.createRequestOptions({ clearCache: true }, options);
        let data = {
            rowIds: rowIds,
            RtcConnectionId: rtcConnectionId,
            Options :{
                InsertAtBottom: insertAtBottom,
                CopyCreatedByUser: true
            }
        };
        return ApiUtils.apiPostJson("/row/clonemultiple?v3=true", data, options)
            .then(response => response.json<ApiResult<Models.Row[]>>());
    }

    static async copy(sourceListId: string | null, targetListId: string | null, rowIds: string[], options: IRequestOptions | null = null)
    : Promise<ApiResult<boolean> | null> {
        return ApiUtils.apiPostJson(`/row/copy/${ApiUtils.encodeSegment(sourceListId)}/${ApiUtils.encodeSegment(targetListId)}`, { rowIds: rowIds }, options)
            .then(response => response.json<ApiResult<boolean>>());
    }

    static async move(sourceListId: string | null, targetListId: string | null, rowIds: string[], moveBlobInsteadOfCopy: boolean, options: IRequestOptions | null = null)
    : Promise<ApiResult<boolean> | null> {
        return ApiUtils.apiPostJson(`/row/move/${ApiUtils.encodeSegment(sourceListId)}/${ApiUtils.encodeSegment(targetListId)}`, { rowIds: rowIds, moveBlobInsteadOfCopy: moveBlobInsteadOfCopy }, options)
            .then(response => response.json<ApiResult<boolean>>());
    }

    static async getRowOwners(rowIds: string[], options: IRequestOptions | null = null) 
    : Promise<Array<Models.Member> | null> {
        options = ApiUtils.createRequestOptions({cache: false}, options);
        return ApiUtils.apiPostJson("/row/GetRowOwners/", {rowIds: rowIds}, options)
            .then(response => response.objects(Models.Member));
    }

    static async SendOwnerReminders(rowIds: string[], message: string, options: IRequestOptions | null = null)
    : Promise<Array<Models.Member> | null> {
        options = ApiUtils.createRequestOptions({clearCache: true}, options);
        let data = {
            rowIds: rowIds,
            message: message
        };
        return ApiUtils.apiPostJson("/row/SendOwnerReminders/", data, options)
            .then(response => response.objects(Models.Member));
    }

    static async Restore(rowIds: string[], options: IRequestOptions | null = null)
     : Promise<ApiResult<boolean> | null> {
        return ApiUtils.apiPostJson("/row/Restore/", {rowIds: rowIds}, options)
            .then(response => response.json<ApiResult<boolean>>());
     }

    static async archive(rowIds: string[], rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
     : Promise<ApiResult<boolean> | null> {
        return ApiUtils.apiPostJson("/row/Archive", {rowIds: rowIds, RtcConnectionId: rtcConnectionId}, options)
            .then(response => response.json<ApiResult<boolean>>());
    }

    static async unarchive(rowIds: string[], rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
     : Promise<ApiResult<boolean> | null> {
        return ApiUtils.apiPostJson("/row/Unarchive", {rowIds: rowIds, RtcConnectionId: rtcConnectionId}, options)
            .then(response => response.json<ApiResult<boolean>>());
    }

    static async lock(rowIds: string[], rtcConnectionId: string | null = null, options: IRequestOptions | null = null)
     : Promise<ApiResult<Models.Row[]> | null> {
        return ApiUtils.apiPostJson("/row/lock", {rowIds: rowIds, RtcConnectionId: rtcConnectionId}, options)
            .then(response => response.json<ApiResult<Models.Row[]>>());
     }

     static async unlock(rowIds: string[], rtcConnectionId: string | null = null,  options: IRequestOptions | null = null)
     : Promise<ApiResult<Models.Row[]> | null> {
        return ApiUtils.apiPostJson("/row/unlock", {rowIds: rowIds, RtcConnectionId: rtcConnectionId}, options)
            .then(response => response.json<ApiResult<Models.Row[]>>());
     }

     static async loadMultiple(rowIds: string[], options: IRequestOptions | null = null)
     : Promise<ApiResult<Models.Row[]> | null> {
        return ApiUtils.apiPostJson("/row/loadmultiple", {rowIds: rowIds}, options)
            .then(response => response.json<ApiResult<Models.Row[]>>());
     }

}