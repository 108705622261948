
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { ListThumbnailInfo, List, CopyListInfo, CopyListOptions, ListTreeFolder } from '@/models/RowShare';
    import CopyListDialog  from './CopyListDialog.vue';
    import RenameList from './RenameList.vue';
    import MoveListToRecycleBin from './MoveListToRecycleBin.vue';
    import DeleteList from './DeleteList.vue';
    import { Action, namespace } from 'vuex-class';
    import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
    import SingleFormDialog from '@/views/MyTables/SingleFormDialog.vue';

    @Component({
        name:'ListActionsSubmenu',
        components:{ CopyListDialog, RenameList, MoveListToRecycleBin, DeleteList, ConfirmationDialog, SingleFormDialog }
    })
    export default class ListActionSubmenu extends Vue {

        @Prop() list!: ListThumbnailInfo;
        @Prop() folder!:ListTreeFolder;
        @Prop() organizationId!:string;

        //This function is to not loose the style that vuetify adds to the menu's items.
        doNothing(){}

    }

