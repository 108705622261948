
    import {Vue, Component, Prop } from 'vue-property-decorator';
    import * as API from '@/api/Api';
    import * as Models from '@/models/RowShare';
    import { Utils } from '@/utils/Utilities';

    @Component({
        name: 'BackOfficeOrganizationLimits',
        components: {}
    })
    export default class BackOfficeOrganizationLimits extends Vue{
        @Prop() private organizationId!: string;
        private org: Models.Organization | null = null;
        private limits: Models.SubscriptionLimit[] | null = null;

        async created(){
            this.org = await API.BoApi.loadOrganization(this.organizationId);
            this.limits = await API.Organization.getLimits(this.organizationId);
        }
        
        getDisplayType(type: Models.SubscriptionLimitType){
            switch(type) {
                case Models.SubscriptionLimitType.User:
                    return "User";
                case Models.SubscriptionLimitType.Table:
                    return "Table";
                case Models.SubscriptionLimitType.Row:
                    return "Row";
                case Models.SubscriptionLimitType.Storage:
                    return "Storage";
                case Models.SubscriptionLimitType.ReadOnlyUser:
                    return "Read only user";
                default:
                    return "Invalid type";
            }
        }
        
        formatCurrentValue(limit: Models.SubscriptionLimit){
            if(limit.LimitType == Models.SubscriptionLimitType.Storage)
                return Utils.humanFileSize(limit.CurrentValue, this.$i18n.locale=="en", 0);
            else
                return limit.CurrentValue
        }

        formatMaxValue(limit: Models.SubscriptionLimit){
            if(limit.LimitType == Models.SubscriptionLimitType.Storage)
                return Utils.humanFileSize(limit.MaxValue, this.$i18n.locale=="en", 0);
            else
                return limit.MaxValue
        }
    }
