
    import { Component, Watch } from 'vue-property-decorator';
    import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
    import RsColumnManager from '@/columnProxies/RsColumnManager';

    @Component({
        name: 'TextFormEditor',
        components: {
        }
    })
    export default class TextFormEditor extends FormEditor {
        currentValue: string = '';

        init(){
            const proxy  = RsColumnManager.getProxy(this.column.Type);
            this.currentValue = this.rowVM.getValue(this.column, proxy);
        }

        getValue() {
            return this.currentValue;
        }
    }
