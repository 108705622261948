import * as Model from '@/models/RowShare';
import * as API from '@/api/Api';
import { ListTreeModule } from '@/store/ListTreeStore';

declare var dataLayer: any;

export class TrackingUtils {
    static async UpdatePathContext() {
        const currentDataLayer = dataLayer[0];
        if (currentDataLayer && currentDataLayer.user) {
            var dtUser = currentDataLayer.user;
            if(ListTreeModule.currentOrganization) {
                dtUser.OrganizationId = ListTreeModule.currentOrganization?.Id;
                dtUser.OrganizationName = ListTreeModule.currentOrganization?.Name;
                dtUser.OrganizationIsAdmin = ListTreeModule.currentOrganization?.CanCurrentUserManage;
                if(!ListTreeModule.currentOrganization?.IsEnabled) {
                    dtUser.OrganizationBillingStatus = "Disabled";
                }
                else if(ListTreeModule.currentOrganization?.InTrial) {
                    dtUser.OrganizationBillingStatus = "Trial";
                }
                else if (ListTreeModule.currentOrganization?.IsFree) {
                    dtUser.OrganizationBillingStatus = "Free";
                }
                else {
                    dtUser.OrganizationBillingStatus = "Paying";
                }
            }

            if(ListTreeModule.currentList) {
                dtUser.isListOwner = ListTreeModule.currentList.Owned;
            }
            else {
                dtUser.isListOwner = undefined;
            }

            document.dispatchEvent(new CustomEvent("dataLayerCompletion"));
        }
    }
    
    static UpdateUserDataLayer(user: Model.User | null){
        const currentDataLayer = dataLayer[0];
        if (currentDataLayer && currentDataLayer.user) {
            if(!user){
                currentDataLayer.user = null;
                currentDataLayer.connected = false;
                currentDataLayer.userId = null;
                return;
            }

            var dtUser = currentDataLayer.user;
            Object.keys(dtUser).forEach(k =>{
                if(Object.keys(user).includes(k)) {
                    dtUser[k] = (<any>user)[k];
                }
            })
            currentDataLayer.connected = !user.IsAnonymous;
            currentDataLayer.userId = user.Identity;

            document.dispatchEvent(new CustomEvent("dataLayerCompletion"));
        }
    }

    static UpdateIsMsTeamsAppDataLayer(isMsTeamsApp: boolean) {
        const currentDataLayer = dataLayer[0];
        if(currentDataLayer) {
            currentDataLayer.isMsTeamsApp = isMsTeamsApp;
        }
    }

    static addEventDataLayer(eventName: string) {
        dataLayer.push({
            'event': eventName
        });
    }
}