
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { ListThumbnailInfo } from '@/models/ListTree';
    import { List } from '@/models/List';
    import SingleFormDialog from '@/views/MyTables/SingleFormDialog.vue';
    import { ListTreeModule } from '@/store/ListTreeStore';

    @Component({
        name:'RenameList',
        components: { SingleFormDialog }
    })
    export default class RenameList extends Vue{

        @Prop() listThumbnail!:ListThumbnailInfo;
        @Prop() folderId!:string;
        @Prop() organizationId!:string;

        private renameModalId = 'rename-list';
        private renameListServerErrorMessage = '';
        private openModalRename: boolean = false;

        openModalToRename(){
            setTimeout(()=> this.openModalRename=true);
        }

        async renameListConfirmed(nameParam: string){
            this.renameListServerErrorMessage = '';
            try{
                let listInfo = new List();
                listInfo.Id = this.listThumbnail.Id;
                listInfo.DisplayName = nameParam;  
                let renameInfo = {
                        renamedList:listInfo,
                        folderId:this.folderId,
                        organizationId:this.organizationId,
                    }
                await ListTreeModule.renameList(renameInfo);
                this.openModalRename=false;
            }
            catch(error: any){
                if(error.code!=undefined) 
                    this.renameListServerErrorMessage = this.$i18n.t('ExceptionCode_'+error.code).toString();
                else 
                    this.renameListServerErrorMessage = this.$i18n.t('MyTables_Exception_RenameList').toString();
            }
        }
        
        cancelled(){
            this.openModalRename = false;
        }
    }
