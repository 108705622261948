
import { Component } from 'vue-property-decorator';
import { FormValidation } from '@/views/components/FormValidation';
import RsHeader from "@/views/layouts/LayoutParts/Header.vue";
import GlobalConfirmationDialog from "@/views/components/GlobalConfirmationDialog.vue";
import NotificationStack from "@/views/layouts/LayoutParts/NotificationStack.vue";
import UpsellDialog from '@/views/components/UpsellDialog.vue';
import SidePanelFormView from '@/views/Table/FormView/SidePanelFormView.vue';
import SidePanelSharing from '@/views/Table/ShareTable/SidePanelSharing.vue';
import SidePanelColumnPermissions from '@/views/Table/ColumnSettings/SidePanelColumnPermissions.vue';
import SidePanelTableSettings from '@/views/Table/TableSettings/SidePanelTableSettings.vue';
import SidePanelColumnSettings from '@/views/Table/ColumnSettings/SidePanelColumnSettings.vue';
@Component({components: {RsHeader, NotificationStack, GlobalConfirmationDialog, UpsellDialog, SidePanelFormView, SidePanelSharing, 
                            SidePanelColumnPermissions, SidePanelTableSettings, SidePanelColumnSettings}})
export default class DefaultLayout extends FormValidation {
}
