import { Utils } from "@/utils/Utilities";
import { DataStatus } from "@/models/Enums";

export class Column {
    public Id!:           string;
    public ListId!:       string;
    public DisplayName!:  string;
    public Name!:         string;
    public SubName!:      string;
    public ColumnGroup!:      string;
    public DefaultValue!: string | null;
    public Description!:  string;
    public IsReadOnly!:   boolean;
    public Index!:        number;
    public SortOrder!:    number;
    public MaxDecimals!:  number;
    public MaxLength!:    number;
    public MinValue!:     number;
    public MaxValue!:     number;
    public Options!:      ColumnOptions;
    public Icon!:         string;
    public DefaultWidth!: number;
    public Status!:       DataStatus;
    public Type!:         ColumnStrongType;
    public IsMainColumn!: boolean;
    public LookupValues!: string;
    public LookupListId!: string;
    public LookupListColumnName!: string;
    public Format!: string;
    public ListCulture!: string;
    public LeftPinned!: boolean;
    public RelatedListId!: string;
    public ParentColumnId!: string;
    public RelationColumnId!: string;
    public Color!: string;
    public ColorType!: ColumnColorType;
    public NeedsListReload: boolean| null = false;
    public HasReadOnlyPermissions!: boolean;
    public HasHiddenPermissions!: boolean;
    public Prefix!: string;
    public Suffix!: string;
    public RtcConnectionId!:string | null;

    private hasOption(option: ColumnOptions) {
        // tslint:disable-next-line:no-bitwise
        return (this.Options & option) === option;
    }

    private setOption(option: ColumnOptions, value: boolean) {
        if (value) {
            // tslint:disable-next-line:no-bitwise
            this.Options |= option;
        } else {
            // tslint:disable-next-line:no-bitwise
            this.Options &= ~option;
        }
    }

    public get isRowGroupingEnabled() {
        return ![ColumnStrongType.RowIndex, ColumnStrongType.AutoNumber].includes(this.Type);
    }

    public get isMandatory() {
        return this.hasOption(ColumnOptions.IsMandatory);
    }

    public set isMandatory(value: boolean) {
        this.setOption(ColumnOptions.IsMandatory, value);
    }

    public get isUnique() {
        return this.hasOption(ColumnOptions.IsUnique);
    }

    public set isUnique(value: boolean) {
        this.setOption(ColumnOptions.IsUnique, value);
    }

    public get isFormula() {
        return this.hasOption(ColumnOptions.IsFormula);
    }

    public set isFormula(value: boolean) {
        this.setOption(ColumnOptions.IsFormula, value);
    }

    public get isLookupMultiValued() {
        return this.hasOption(ColumnOptions.IsLookupMultiValued);
    }

    public set isLookupMultiValued(value: boolean) {
        this.setOption(ColumnOptions.IsLookupMultiValued, value);
    }

    public get isCompact() {
        return this.hasOption(ColumnOptions.IsCompact);
    }

    public set isCompact(value: boolean) {
        this.setOption(ColumnOptions.IsCompact, value);
    }

    public get isGenerated() {
        return this.Type === ColumnStrongType.AutoNumber ||
            this.isTechnical ||
            this.isFormula;
    }

    public get isStatusColumn() {
        return this.Type === ColumnStrongType.StatusChangeByUserName || this.Type === ColumnStrongType.StatusChangeDateTime;
    }

    public get isTechnical() {
        return this.isMember ||
            this.Type === ColumnStrongType.CreationDate ||
            this.Type === ColumnStrongType.LastWriteDate ||
            this.Type === ColumnStrongType.StatusChangeDateTime ||
            this.Type === ColumnStrongType.RowIndex;
    }

    public get isMember() {
        return this.Type === ColumnStrongType.MemberManagerEmail ||
            this.Type === ColumnStrongType.MemberManagerName ||
            this.Type === ColumnStrongType.LastWriteUserEmail ||
            this.Type === ColumnStrongType.LastWriteUserName ||
            this.Type === ColumnStrongType.OwnerEmail ||
            this.Type === ColumnStrongType.OwnerName ||
            this.Type === ColumnStrongType.CreatedByUserEmail ||
            this.Type === ColumnStrongType.CreatedByUserName ||
            this.Type === ColumnStrongType.StatusChangeByUserName;
    }

    public get isChildColumn() {
        return !Utils.isNullOrWhiteSpace(this.ParentColumnId);
    }

    public get showThousandsSeparator() {
        return !this.hasOption(ColumnOptions.DoNotShowThousandsSeparator);
    }

    public set showThousandsSeparator(value: boolean) {
        this.setOption(ColumnOptions.DoNotShowThousandsSeparator, !value);
    }

    public get isNumber() {
        return this.Type === ColumnStrongType.DecimalNumber ||
            this.Type === ColumnStrongType.Number ||
            this.Type === ColumnStrongType.Percentage ||
            this.Type === ColumnStrongType.AutoNumber ||
            this.Type === ColumnStrongType.RowIndex;
    }

    public get isBlob() {
        return this.Type === ColumnStrongType.File || this.Type === ColumnStrongType.Image;
    }

    public get isPercentage() {
        return this.Type === ColumnStrongType.Percentage;
    }

    public get isEmail() {
        return this.Type === ColumnStrongType.Email || this.Type === ColumnStrongType.CreatedByUserEmail 
                || this.Type === ColumnStrongType.MemberManagerEmail || this.Type === ColumnStrongType.LastWriteUserEmail
                || this.Type === ColumnStrongType.OwnerEmail;
    }

    public get isHyperlink() {
        return this.Type === ColumnStrongType.Hyperlink;
    }

    public get isLookup() {
        return this.Type === ColumnStrongType.LookUp || this.Type === ColumnStrongType.LookUpList;
    }

    get parsedLookupItems() : LookUpItem[] {
        let result: LookUpItem[] =[];
        if (Utils.isNullOrWhiteSpace(this.LookupValues)) {
            return [];
        }
        try {
            const items = JSON.parse(this.LookupValues)
            if (items) {
                result = items.map((v: any) => new LookUpItem(v.name ? v.name : v.value, v.color));                    
            }
        } 
        catch {
            var strvalues: string[];
            if (Utils.isArray(this.LookupValues)) {
                strvalues = this.LookupValues;
            } else {
                strvalues = (<string>this.LookupValues).replace(/\r/g, "").split("\n");
            }
            if (strvalues) {
                
                for (var i = 0; i < strvalues.length; i++) {
                    var val = strvalues[i];
                    var valueVM = new LookUpItem(val, "");
                    result.push(valueVM);
                }
            }
        }
        return result;
    }

    get isDateTimeFormat(): boolean {
        return this.Type == ColumnStrongType.CreationDate || this.Type == ColumnStrongType.DateTime ||
                this.Type == ColumnStrongType.LastWriteDate || this.Type == ColumnStrongType.Reminder || 
                this.Type == ColumnStrongType.StatusChangeDateTime;
    }

    get isOwnerInfo() {
        return this.Type === ColumnStrongType.OwnerEmail || this.Type === ColumnStrongType.OwnerName;
    }

    get canBeCleared(): boolean {
        return !this.isMandatory && !this.isGenerated && this.Type != ColumnStrongType.Comment;
    }

    get canBeRemoved(): boolean {
        return !this.isChildColumn;
    }

    get canEditIsMandatory(): boolean {
        return !this.isGenerated
                && this.Type !== ColumnStrongType.Comment
                && this.Type !== ColumnStrongType.TwoStatesBoolean
                && this.Type !== ColumnStrongType.Relation
                && !this.isChildColumn;
    }

    get useSmartRenderer(): boolean {
        return this.hasOption(ColumnOptions.UseSmartRenderer);
    }

    set useSmartRenderer(value: boolean) {
        this.setOption(ColumnOptions.UseSmartRenderer, value);
    }

    get canEditIsUnique() {
        if (this.isGenerated) {
            return false;
        }
        
        return this.Type !== ColumnStrongType.TwoStatesBoolean 
                && this.Type !== ColumnStrongType.Relation
                && !this.isChildColumn;
    }

    get canEditIsLookupMultiValued() {
        switch (this.Type) {
            case ColumnStrongType.LookUpList:
            case ColumnStrongType.LookUp:
                return true;
        }

        return false;
    }

    get canEditMaxDecimals() {
        if (this.Type === ColumnStrongType.Number) {
            return false;
        }

        return (this.Type === ColumnStrongType.DecimalNumber || this.Type === ColumnStrongType.Percentage);
    }

    get canEditShowThousandsSeparator() {

        return this.isNumber;
    }

    get canEditMinMaxValue() {
        if (this.isGenerated) {
            return false;
        }

        return this.isNumber;
    }

    get canEditMaxLength() {
        if (this.isGenerated) {
            return false;
        }

        return (this.Type === ColumnStrongType.Text);
    }

    get canEditLookupValues() {
        return this.Type === ColumnStrongType.LookUp;
    }

    get canEditIsCompact() {
        return this.Type === ColumnStrongType.LookUp && !this.isLookupMultiValued;
    }

    get canEditLookupList() {
        return this.Type === ColumnStrongType.LookUpList;
    }

    get canEditDefaultValue() {
        if (this.isFormula || this.isGenerated || this.Type === ColumnStrongType.Relation) {
            return false;
        }

        return true;
    }

    get canEditFormula() {
        if (this.isFormula) {
            return true;
        }

        return false;
    }

    get canEditUseSmartRenderer() {
        return this.isPercentage;
    }

    get canEditIsFormula() {
        if(this.isTechnical || this.Type === ColumnStrongType.AutoNumber) {
            return false;
        }
        return this.isBoolean || this.isNumber || this.isDateFormat  || this.isDateTimeFormat || this.Type === ColumnStrongType.Time || this.Type === ColumnStrongType.Text
                || this.isLookup;
    }

    get canEditRelatedColumns() {
        return this.Type === ColumnStrongType.Relation;
    }

    get canEditGroupName() {
        return this.Type !== ColumnStrongType.Relation;
    }

    get canEditSubname() {
        return this.Type !== ColumnStrongType.Relation;
    }

    get canEditColor() {
        return this.Type !== ColumnStrongType.Relation;
    }

    get isDateFormat() {
        return this.Type === ColumnStrongType.Date;
    }

    get isBoolean() {
        return this.Type === ColumnStrongType.TwoStatesBoolean;
    }

    get formula() {
        return this.DefaultValue;
    }

    set formula(value: string | null) {
        this.DefaultValue = value;
    }

    public get HasParentColumn(): boolean {
        return  !Utils.isNullOrWhiteSpace(this.ParentColumnId);
    }

    get canEditPrefix() {
        return this.Type === ColumnStrongType.DecimalNumber || this.Type === ColumnStrongType.Number;
    }

    get canEditSuffix() {
        return this.Type === ColumnStrongType.DecimalNumber || this.Type === ColumnStrongType.Number;
    }    
}

export class LookUpItem {
    public name!: string;
    internalColor!: string;

    constructor(name: string, color: string){
        this.name = name;
        this.internalColor = color;
    }

    public get noColorDefined(): boolean {
        return Utils.isNullOrWhiteSpace(this.internalColor);
    }

    public get color() {
        if(!Utils.isNullOrWhiteSpace(this.internalColor)) {
            return this.internalColor;
        }
        else {
            return "primary";
        }
    }

    public set color(value: string) {
        this.internalColor = value;
    }
}

export enum ColumnStrongType {
    AutoNumber = "AutoNumber",
    Child = "Child",
    Comment = "Comment",
    CreatedByUserEmail = "CreatedByUserEmail",
    CreatedByUserName = "CreatedByUserName",
    CreationDate = "CreationDate",
    Date = "Date",
    DateTime = "DateTime",
    DecimalNumber = "DecimalNumber",
    Email = "Email",
    File = "File",
    Hyperlink = "Hyperlink",
    Image = "Image",
    LastWriteDate = "LastWriteDate",
    LastWriteUserEmail = "LastWriteUserEmail",
    LastWriteUserName = "LastWriteUserName",
    LookUp = "LookUp",
    LookUpList = "LookUpList",
    MemberManagerEmail = "MemberManagerEmail",
    MemberManagerName = "MemberManagerName",
    Number = "Number",
    OwnerName = "OwnerName",
    OwnerEmail = "OwnerEmail",
    Percentage = "Percentage",
    Reminder = "Reminder",
    Relation = "Relation",
    RichText = "RichText",
    RowIndex = "RowIndex",
    Text = "Text",
    ThreeStatesBoolean = "ThreeStatesBoolean",
    Time = "Time",
    TwoStatesBoolean = "TwoStatesBoolean",
    // "Fake" types used only on front side for deleted rows display
    StatusChangeByUserName = "StatusChangeByUserName",
    StatusChangeDateTime = "StatusChangeDateTime"
}

export enum ColumnOptions
{  
    None = 0,   
    IsMandatory = 1,   
    IsComputed = 2,  
    IsLookupMultiValued = 32,   
    IsUnique = 64, 
    IsFrequent = 256,   
    IsSystemHidden = 2048,  
    DoNotShowThousandsSeparator = 4096,  
    IsFormula = 262144,  
    UseSmartRenderer = 524288,
    IsCompact = 4194304,
}

// Must be synchronized with ColumnColorType on back side
export enum ColumnColorType
{   
    None = 0,
    Header = 1,
    Cells = 2,
    All = 3,
}

