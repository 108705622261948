import { i18n } from "@/modules/Localization";
import { ColDef, ColumnApi } from "ag-grid-community";
import ColumnVM from "./columnVM";
import { IColumnDescription } from "./IColumnDescription";

export default class groupRowColumnDescriptionVM implements IColumnDescription {
    columnSorted: boolean = false;
    columnSortedAsc: boolean = false;
    columnSortedDesc: boolean = false;
    columnSettings: ColDef | null = null;
    isRowGroupColumn: boolean = true;
    columnApi: ColumnApi | null = null;
    
    constructor(columnSettings: ColDef, columnApi: ColumnApi) {
        this.columnSettings = columnSettings;
        this.columnApi = columnApi;
    }

    get columnTitle() {
        if(this.rowGroupsColumns) {
            if(this.rowGroupsColumns.length > 0) {
                var rowGroupsColumnsNames = this.rowGroupsColumns.map(rgc => rgc.headerName);
                return i18n.t('SharingBar_OneRowGroupLabel', [rowGroupsColumnsNames.join(', ')]).toString();
            }
        }
        return this.columnSettings?.headerName ?? "";
    }

    get rowGroupsColumns(): ColumnVM[] {
         return this.columnApi?.getRowGroupColumns().map(rgc => <ColumnVM>rgc.getColDef()) ?? [];
    }

    get columnHasSpecialProperties() {
        return true;
    }

    get columnHasPermissions() {
        return false;
    }

    get columnIsMandatory(): boolean {
        return false;
    }

    get columnIsReadOnly(): boolean {
        return false;
    }

    get columnSubName(): string {
        return "";
    }
    
    get columnHasHiddenPermissions(): boolean {
        return false;
    }

    get columnHasReadOnlyPermissions(): boolean {
        return false;
    }

    get columnIsFormula(): boolean {
        return false;
    }

    get sortDescription(): string {
        if(this.columnSortedAsc) {
            return i18n.t('HeaderDescription_SortedAsc').toString();
        }
        if(this.columnSortedDesc) {
            return i18n.t('HeaderDescription_SortedDesc').toString();
        }
        return "";
    }

    get formulaDescription(): string {
        return "";
    }

    get mandatoryDescription(): string {
        return "";
    }

    get readOnlyDescription(): string {
        return "";
    }

    get readOnlyPermissionsDescription(): string {
        return "";
    }

    get hiddenPermissionsDescription(): string {
        return "";
    }
    
    hiddenColumnsBesideCount: number = 0;

    get hiddenColumnDescription(): string {
        return i18n.t('HeaderDescription_HiddenColumns', {hiddenColumnsCount: this.hiddenColumnsBesideCount}).toString();
    }

    getSortIcon(hover: boolean): string {
        if(!hover && !this.columnSorted) {
            return "fal fa-fw";
        }
        if(this.columnSortedAsc) {
            return "fal fa-sort-amount-down-alt";
        }
        else if(this.columnSortedDesc) {
            return "fal fa-sort-amount-down";
        }
        else {
            return "fal fa-sort-alt"
        }
    }

    get specialColumnIcon(): string {
        return "";
    }

    get permissionsIcon(): string {
        return "";
    }

    get columnIsChild(): boolean {
        return false;
    }

}