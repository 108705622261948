
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Group } from '@/models/Group';
    import { ICellRendererParams } from 'ag-grid-community';

    @Component({name: 'groupsRenderer'})
    export default class groupsRenderer extends Vue{
        selectedGroups!: Group[];
        params!: ICellRendererParams;

        beforeMount(){
            this.selectedGroups = this.params.value;
        }
        get groupsDescription(){
            if(this.selectedGroups && this.selectedGroups.length > 0){
                let desc = '';
                let sep = '';
                this.selectedGroups.forEach((value, index) => { 
                        desc += sep + value.Name;
                        sep= ', ';
                        });
                return desc;
            }
            return '';
        }
    }
