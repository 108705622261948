import './modules/ClassComponentHooks';

// https://bootstrap-vue.js.org/docs/#browser-support
import 'core-js/stable';
import 'custom-event-polyfill'
import 'regenerator-runtime/runtime';
import 'intersection-observer';

import Vue from 'vue';
import App from './App.vue';
import DatetimePicker from 'vuetify-datetime-picker';
//import VueAnalytics from 'vue-analytics'

import './scss/Styles.scss';

import vueRouter from './modules/Router';
import vi18n from './modules/Localization';
import vuex from './modules/Store';
import vuetify from './modules/Vuetify';
import Meta from 'vue-meta';
import 'ag-grid-enterprise';
import LoadScript from 'vue-plugin-load-script';
import { LicenseManager } from '../node_modules/ag-grid-enterprise';
import { OnPremModule } from './store/OnPremStore';
import VCalendar from 'v-calendar';
import VueScrollto from 'vue-scrollto';
import VueTelInputVuetify from 'vue-tel-input-vuetify';
import VueMask from 'v-mask'
import VueCurrencyInput from 'vue-currency-input';
import VueAppInsights from 'vue-application-insights';
import { ApplicationModule } from './store/ApplicationStore';
import { loadAndSetWithUserLanguageAsync } from '@/modules/Localization';

Vue.config.productionTip = true;
Vue.use(Meta, { refreshOnceOnNavigation: true });
Vue.use(DatetimePicker);
Vue.use(LoadScript);
Vue.use(VCalendar, { componentPrefix: 'vc' });
Vue.use(VueScrollto);
Vue.use(VueTelInputVuetify, { vuetify });
Vue.use(VueMask);
Vue.use(VueCurrencyInput);

//Vue.use(VueAnalytics, { id: 'UA-80443126-1', router: vueRouter })

// AgGrid Licence
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-065110}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SOFTFLUENT_SOFTWARE}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{RowShare}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{RowShare}_need_to_be_licensed___{RowShare}_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_October_2025}____[v3]_[01]_MTc2MDQ4MjgwMDAwMA==687d0b2c41099c93b4da188ace0afaac");
//Directive to hide elements but keep space occupied (e.g. : style: visibility)
Vue.directive('visible', function (el, binding) {
    el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

(<any>Vue).loadScript('/api/script/load/datalayer').then((s: any) => {
    loadAndSetWithUserLanguageAsync(true).then(() => {
        new Vue({
            router: vueRouter,
            i18n: vi18n,
            store: vuex,
            vuetify,
            render: h => h(App)
        }).$mount('#app');
        if (ApplicationModule.getAppInsightsKey !== '') {
            Vue.use(VueAppInsights, {
                id: ApplicationModule.getAppInsightsKey,
                router: vueRouter,
                options: {
                    disableAjaxTracking: false,
                    disableFetchTracking: false,
                    disableExceptionTracking: false,
                    disableTelemetry: false,
                    enableAutoRouteTracking: true,
                    enableCorsCorrelation: true,
                    enableRequestHeaderTracking: true,
                    enableSessionStorageBuffer: true,
                    enableAutoPageViewTracking: true,
                    maxAjaxCallsPerView: -1,
                    maxBatchInterval: 15000,
                    maxBatchSizeInBytes: 102400,
                    overridePageViewDuration: false,
                    sessionExpirationMs: 1800000,
                    sessionRenewalMs: 300000,
                    startTrackEventBuffer: 0,
                    startTrackPageBuffer: 0,
                    disableFlushOnBeforeUnload: false,
                    enableDebug: false,
                    verboseLogging: false,
                    enableUnhandledPromiseRejectionTracking: true,
                    enableResponseHeaderTracking: true
                }

            });
        }
    });
});

declare global {
    interface Window { grecaptcha: any; }
}