import * as RowShare from '@/models/RowShare';
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';
import { i18n } from "@/modules/Localization";
import * as agGrid from 'ag-grid-community';
import { Utils } from '@/utils/Utilities';

export default abstract class BaseColumnProxy{
    parseInitialTypedValue(rsColumn: RowShare.Column, rowVM: RowVM): any {
        return rowVM.rsRow.Values[rsColumn.Name] ?? ""
    }
    
    getFormulaError(rsColumn: RowShare.Column, rowVM: RowVM) : string | null {
        if(!rsColumn.isFormula)
            return null;

        var val = rowVM?.rsRow.Values[rsColumn.Name] ?? "";
        if(!val || typeof val !== 'string')
            return null;

        const regex = /^#LQL\d*:\s(.*)$/i;
        var match = val.match(regex);
        
        if(match && match.length > 1)
            return "Error: " + match[1];
        else
            return null;
    }

    getAPIVal(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        return val;
    }
    
    areEquals(val1: any, val2: any) {
        return val1 === val2;
    }

    /** Display & rendering **/

    shouldSuppressKeyboardEvent(rsCol: RowShare.Column) { 
        return false;
    }

    isAlwaysEditable() {
        return false;
    }

    abstract getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string;

    getFormattedHtml(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        return this.getFormattedText(rsColumn, rowVM, val);
    }

    getFormattedHtmlTooltip(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string | null {
        return this.getFormattedHtml(rsColumn, rowVM, val);
    }

    getCellIconClasses(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string[] | null {
        return null;
    }

    getRendererWrapperClass(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string | null {
        return null;
    }

    getRenderer(rsCol: RowShare.Column) : string{
        return 'ProxyBasedRenderer';
    }

    /** Filters **/
    getCustomFilter(rsCol: RowShare.Column) : null | string{
        return null;
    }

    getCustomFilterParams(rsCol: RowShare.Column) : null | any {
        return  { 
            excelMode: 'windows',
            comparator: (valueA: any, valueB: any) => {
                const a = Utils.normalizeString(valueA ?? "") ?? "";
                const b = Utils.normalizeString(valueB ?? "") ?? "";

                if (a == b)
                    return 0;
                return (a > b) ? 1 : -1;
            },
            refreshValuesOnOpen: true,
            caseSensitive: true,
            textFormatter: (value: string) => {
                return Utils.normalizeString(value);
            }
        };
    }

    getQuickFilterText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        return val;
    }

    /** Aggregation **/

    canShowTotal(rsColumn: RowShare.Column) : boolean {
        return false;
    }

    addToTotal(currentAggResult: any, rowVM: RowVM, rsColumn: RowShare.Column): any {
        return null;
    }

    formatTotal(aggResult: any, rowCount: number, rsColumn: RowShare.Column): any {
        return null;
    }

    protected getRowCountFormat(basePattern: string, aggResult: number, rowCount: number){
        let patternName = basePattern;
        if(aggResult == 1)
            patternName = basePattern + "_OneValue";
        else if (aggResult > 1)
            patternName = basePattern + "_MultipleValues";
        else
            patternName = basePattern + "_NoValue";

        return i18n.t(patternName, {
            valueCount: aggResult,
            totalRowCount: rowCount,
        }).toString();
    }

    /** Clipboard **/
    getClipboardValue(rsColumn: RowShare.Column, rowVM: RowVM, val: any): any {
        return this.getFormattedText(rsColumn, rowVM, val);
    }
    
    /** Grid context menu **/
    getContextMenuEntries(params: agGrid.GetContextMenuItemsParams) : agGrid.MenuItemDef[] | null {
        return null;
    }

    /** Group/Pivot/Charts Mode **/
    getEnableRowGrouping(rsColumn: RowShare.Column): boolean {
        return true;
    }

    getEnableAggregate(rsColumn: RowShare.Column): boolean {
        return false;
    }

    get enablePivot() {
        return true;
    }

    /** Sorting **/
    compareValues(rsColumn: RowShare.Column, valX: any, valY: any, nodeX: any, nodeY: any) : number | null {
        return null;
    }
}