import * as RowShare from '@/models/RowShare';
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import { isNumber } from 'lodash';
import { ColumnStrongType } from '@/models/Column';
import { Utils } from '@/utils/Utilities';

export default class NumericColumnProxy extends BaseColumnProxy {
    parseInitialTypedValue(rsColumn: RowShare.Column, rowVM: RowVM): any {
        switch(rsColumn.Type) {
            case ColumnStrongType.RowIndex:
                return rowVM.rsRow.Index ?? null;
        }

        const val = rowVM.rsRow.Values[rsColumn.Name];
        if(val) {
            var number = Number.parseFloat(val);
            if(isNaN(number)) {
                return null;
            }
            return number;
        }
        return val;
    }

    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        if(rsColumn) {
            if(Utils.isString(val)) {
                val = (<string>val).replace(/\s/g, "").replace(',','.');
            }
            let num = Number.parseFloat(val);
            let res = this.formatNum(num, rsColumn);
            if(!res) {
                return res;
            }
            return (rsColumn.Prefix ?? '') + res + (rsColumn.Suffix ?? '');
        }

        return "";
    }

    private formatNum(num: number, rsColumn: RowShare.Column){
        if(isNaN(num))
            return "";

        return num.toLocaleString(navigator.language, {
            useGrouping: rsColumn.showThousandsSeparator,
            minimumFractionDigits: rsColumn.MaxDecimals,
            maximumFractionDigits: rsColumn.MaxDecimals
        })
    }

    /** Filters **/
    getCustomFilter(rsCol: RowShare.Column) : null | string{
        return "agNumberColumnFilter";
    }

    getCustomFilterParams(rsCol: RowShare.Column) : null | any {
        return {
            inRangeInclusive: true,
            filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'lessThanOrEqual',
                'greaterThan',
                'greaterThanOrEqual',
                'inRange',
                {
                    displayKey: 'blanks',
                    displayName: 'Blanks',
                    predicate: function (filterValue: any, cellValue: any) {
                        return cellValue === undefined || cellValue === null || cellValue === "";
                    },
                    numberOfInputs: 0,
                },
                {
                    displayKey: 'notblanks',
                    displayName: 'Not Blanks',
                    predicate: function (filterValue: any, cellValue: any) {
                        return cellValue !== undefined && cellValue !== null && cellValue !== "";
                    },
                    numberOfInputs: 0,
                },
            ]
        }
    }

    /** Aggregation **/

    canShowTotal(rsColumn: RowShare.Column) : boolean {
        return rsColumn.Type != ColumnStrongType.AutoNumber && rsColumn.Type != ColumnStrongType.RowIndex;
    }

    addToTotal(currentAggResult: any, rowVM: RowVM, rsColumn: RowShare.Column): any {
        const val = rowVM?.getValue(rsColumn, this) ?? 0;
        if(val && isNumber(val))
            currentAggResult += val;
        
        return currentAggResult
    }

    formatTotal(aggResult: any, rowCount: number, rsColumn: RowShare.Column): any {
        return this.formatNum(aggResult, rsColumn);
    }

    /** Clipboard **/
    getClipboardValue(rsColumn: RowShare.Column, rowVM: RowVM, val: any): any {
        if(isNumber(val)) {
            return val.toLocaleString(navigator.language, {useGrouping: false});
        }
        return "";
    }

    /** Pivot/Charts Mode **/
    getEnableRowGrouping(rsColumn: RowShare.Column): boolean {
        return ![ColumnStrongType.RowIndex, ColumnStrongType.AutoNumber].includes(rsColumn.Type);
    }

    getEnableAggregate(rsColumn: RowShare.Column): boolean {
        return ![ColumnStrongType.RowIndex, ColumnStrongType.AutoNumber].includes(rsColumn.Type);
    }
}