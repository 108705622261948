import { ApiUtils, IRequestOptions } from "./ApiUtils"

export class Configuration{
    static getChargebeeSiteName(options: IRequestOptions | null = null){
        if(!options)
            options = ApiUtils.createRequestOptions({cache : true});
        return ApiUtils.apiGet("/configuration/ChargeBeeSiteName")
            .then(response => response.json<String>());
    }

    static checkMobileDevice(options: IRequestOptions | null = null){
        if(!options)
            options = ApiUtils.createRequestOptions({cache : true});
        return ApiUtils.apiGet("/configuration/checkmobiledevice")
            .then(response => response.json<boolean>());
    }

    static getPasswordRegex(options: IRequestOptions | null = null){
        if(!options)
            options = ApiUtils.createRequestOptions({cache : true});
        return ApiUtils.apiGet("/configuration/getpasswordregex")
            .then(response => response.json<string>());
    }
}