

import { EventBus } from '@/modules/EventBus';
import * as Models from '@/models/RowShare';
import RowVM from '@/viewModels/Table/rowVM';
import CardView from '@/views/Table/CardView/CardView.vue'
import RowCard from '@/views/Table/CardView/RowCard.vue';
import { Component, Vue } from 'vue-property-decorator';
import ListVM from '@/viewModels/Table/listVM';
import { Column, List, Row } from '@/api/Api';
@Component({name: 'RelationEditorDialog', components: {CardView, RowCard}})
export default class RelationEditorDialog extends Vue {
    rowVM: RowVM | null = null;
    relatedListVM: ListVM = new ListVM();
    relatedListId: string = "";
    selectedRowId: string = "";
    column: Models.Column | null = null;

    showMe: boolean = false;
    searchValue: string = "";
    refreshParentRow!: () => void;

    created() {
        EventBus.$on(Models.Event.ROW_EDIT_RELATED_ROWS, this.init);
    }

    destroy() {
        EventBus.$off([Models.Event.ROW_EDIT_RELATED_ROWS]);
    }

    async init(event: Models.rowEditRelatedRowsEventParams) {
        this.rowVM = event.rowVM;
        this.column = event.column;
        this.relatedListId = event.relatedListId;
        this.refreshParentRow = event.refreshParentRow;
        this.rowVM.loadRelatedRow(this.column).then ( rr => {
            this.selectedRowId = rr?.ParentRowId ?? '';
            (<CardView>this.$refs.cardViewer)?.resetSearchEngine();
            this.showMe = true;
            if(this.relatedListId) {
                this.relatedListVM = new ListVM();
                List.load(this.relatedListId)
                    .then(list => {
                        this.relatedListVM.list = list;
                        Column.loadForList(this.relatedListId, { cache: true })
                            .then(cols => {
                                this.relatedListVM.columns = cols;
                                Row.loadForList(this.relatedListId, Models.DataStatus.Normal, { cache: true })
                                    .then(rows => {
                                        this.relatedListVM.rowVMs = rows?.map(r => new RowVM(this.relatedListVM, r)) ?? [];
                                    });
                            });
                    });
            }
        });
    }

    closeModal() {
        this.showMe = false;
        this.rowVM = null;
        this.column = null;
        this.refreshParentRow = () => {};
        this.selectedRowId = "";
    }

    onCardClick(row: RowVM) {
        if(row) {
            this.selectedRowId = row.rsRow.Id;
        }
    }

    onCardDblClick(row: RowVM) {
        if(row) {
            this.selectedRowId = row.rsRow.Id;
            this.linkRow();
        }
    }

    linkRow() {
        if(this.selectedRowId && this.column && this.rowVM) {
            this.rowVM.setRelatedRow(this.column, this.selectedRowId);
            if(this.refreshParentRow !== undefined) {
                this.refreshParentRow();
            }
            this.closeModal();
        }
    }

    unlinkRow() {
        if(this.column && this.rowVM) {
            this.rowVM.setRelatedRow(this.column, '');
            if(this.refreshParentRow !== undefined) {
                this.refreshParentRow();
            }
            this.closeModal();
        }
    }

    get title(): string {
        if(this.column) {
            return this.column.ColumnGroup;
        }
        return "";
    }

    get subtitle(): string {
        if(this.relatedListVM?.list) {
            return this.$i18n.t('RelationRowsDialog_SubTitle', [this.relatedListVM.list.DisplayName]).toString();
        }
        return "";
    }

    get canUnlink(): boolean {
        if(this.column == null || this.rowVM == null) {
            return false;
        }
        return this.rowVM.getRelatedRow(this.column) != null;
    }
}
