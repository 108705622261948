import { OwnerType } from '@/models/User';
import { Utils } from './Utilities';
import * as RowShare from "@/models/RowShare";
import i18n from '@/modules/Localization';

export class BigramHelper {

    public static getBigramCssClass(backgroundColorIndex: number | null, type: OwnerType): string {
        let result = "rs-bigram";

        if (Utils.isNumber(backgroundColorIndex)) {
            result += " color" + backgroundColorIndex;
        }

        if (type == OwnerType.Group) {
            result += " guest-group";
        }
        else if (type == OwnerType.Team) {
            result += " guest-team";
        }
        else if (type == OwnerType.MyOrganization) {
            result = "rs-bigram header-guestsBar-external header-guestsBar-building-org";
        }

        return result;
    }

    public static getAssignationResultFromSecurityPrincipal(securityPrincipal: RowShare.SecurityPrincipal | string | null){
        if(!securityPrincipal)
            return undefined;

        // got a security principal
        if(securityPrincipal instanceof RowShare.SecurityPrincipal) {
            const ownerType = securityPrincipal.type == RowShare.SecurityPrincipalType.Group
                ? RowShare.OwnerIdType.Group
                : RowShare.OwnerIdType.User;

            return {
                ownerId: ownerType == RowShare.OwnerIdType.Group ? securityPrincipal.GroupId : securityPrincipal.MemberEmail,
                ownerIdType: ownerType,
                securityPrincipal: securityPrincipal
            };
        }

        // got a new member email
        return {
            ownerId: securityPrincipal,
            ownerIdType: RowShare.OwnerIdType.User,
        };
    }

    public static getBigramClassesForSecurityPrincipal(securityPrincipal: RowShare.SecurityPrincipal, dense: boolean, xsmall: boolean = false) {
        return this.getBigramClasses(securityPrincipal, null, null, null, null, dense, xsmall);
    }

    public static getBigramClassesForRowOwner(rowOwner: RowShare.RowOwner, dense: boolean, xsmall: boolean = false) {
        return this.getBigramClasses(null, rowOwner, null, null, null, dense, xsmall);
    }

    public static getBigramClassesForListAccess(listAccess: RowShare.ListAccess, dense: boolean, xsmall: boolean = false) {
        return this.getBigramClasses(null, null, listAccess, null, null, dense, xsmall);
    }

    public static getBigramClassesForColumnAccess(columnAccess: RowShare.ColumnAccess, dense: boolean, xsmall: boolean = false) {
        return this.getBigramClasses(null, null, null, columnAccess, null, dense, xsmall);
    }

    public static getBigramClassesForRtcUser(rtcUser: RowShare.RealTimeCollaborationEntity, dense: boolean, xsmall: boolean = false) {
        return this.getBigramClasses(null, null, null, null, rtcUser, dense, xsmall);
    }

    private static getBigramClasses(securityPrincipal: RowShare.SecurityPrincipal | null, rowOwner: RowShare.RowOwner | null,
                                    listAccess: RowShare.ListAccess | null, columnAccess: RowShare.ColumnAccess | null, rtcUser: RowShare.RealTimeCollaborationEntity | null,  dense: boolean,
                                    xsmall: boolean) {
        let res = ["rs-bigram"];

        let bgIndex: number | null = null;
        if(listAccess) {
            bgIndex = listAccess.Guest.BackgroundColorIndex;
        }
        else if(securityPrincipal) {
            bgIndex = securityPrincipal.BackgroundColorIndex;
        }
        else if(rowOwner) {
            bgIndex = rowOwner.BackgroundColorIndex;
        }
        else if(columnAccess) {
            bgIndex = columnAccess.Guest.BackgroundColorIndex;
        }
        else if(rtcUser) {
            bgIndex = rtcUser.backgroundColorIndex;
        }

        if(Utils.isNumber(bgIndex))
            res.push("color" + bgIndex.toString());


        if(dense)
            res.push("rs-bigram-dense");

        if(xsmall) {
            res.push("rs-bigram-xsmall");
        }

        const type = BigramHelper.getNormalizedSecurityPrincipalType(securityPrincipal, rowOwner, listAccess, columnAccess);
        switch(type){
            case (RowShare.SecurityPrincipalType.Group):
                res.push("rs-bigram-group");
                break;
            case (RowShare.SecurityPrincipalType.DirectReports):
            case (RowShare.SecurityPrincipalType.AllReports):
                res.push("rs-bigram-team");
                break;
        }

        let ownerName: string | null = null;
        if(listAccess)
            ownerName = listAccess.Guest.DisplayName;
        else if(securityPrincipal)
            ownerName = securityPrincipal.displayName;
        else if(rowOwner) {
            ownerName = rowOwner.DisplayName;
        }

        if(Utils.isNullOrEmpty(ownerName))
            res.push("rs-bigram-unknown");
        else if(ownerName === RowShare.surveyUserEmail || ownerName === '(' + RowShare.surveyUserEmail + ')')
            res.push("rs-bigram-survey");
        // else if ((/\([^@]+@[^\.]+\.\w+\)/gi).test(ownerName))
        else if (Utils.validateEmail(ownerName))
            res.push("rs-bigram-noaccount");

        return res;
    }

    public static getBigramTooltipForSecurityPrincipal(securityPrincipal: RowShare.SecurityPrincipal) {
        return this.getBigramTooltip(securityPrincipal, null, null, null, null);
    }

    public static getBigramTooltipForRowOwner(rowOwner: RowShare.RowOwner) {
        return this.getBigramTooltip(null, rowOwner, null, null, null);
    }

    public static getBigramTooltipForListAccess(listAccess: RowShare.ListAccess) {
        return this.getBigramTooltip(null, null, listAccess, null, null);
    }

    public static getBigramTooltipForColumnAccess(columnAccess: RowShare.ColumnAccess) {
        return this.getBigramTooltip(null, null, null, columnAccess, null);
    }

    public static getBigramTooltipForForRtcUser(user: RowShare.RealTimeCollaborationEntity) {
        return this.getBigramTooltip(null, null, null, null, user);
    }

    private static getBigramTooltip(securityPrincipal: RowShare.SecurityPrincipal | null, rowOwner: RowShare.RowOwner | null,
                                    listAccess: RowShare.ListAccess | null, columnAccess: RowShare.ColumnAccess | null, user: RowShare.RealTimeCollaborationEntity | null) {
        const type = BigramHelper.getNormalizedSecurityPrincipalType(securityPrincipal, rowOwner, listAccess, columnAccess);

        let ownerName: string | null = null;
        if(listAccess) {
            ownerName = listAccess.Guest.DisplayName;
        }
        else if(securityPrincipal) {
            ownerName = securityPrincipal.displayName;
        }
        else if(rowOwner) {
            ownerName = rowOwner.DisplayName;
        }
        else if(columnAccess) {
            ownerName = columnAccess.Guest.DisplayName;
        }

        let result = ownerName;
        if (type == RowShare.SecurityPrincipalType.DirectReports)
            result = i18n.t("ListAccess_DirectReportsTeamNameFormat", [ownerName]).toString();
        else if(type == RowShare.SecurityPrincipalType.AllReports)
            result = i18n.t("ListAccess_AllReportsTeamNameFormat", [ownerName]).toString();

        if(Utils.isNullOrEmpty(ownerName))
            result = i18n.t("MemberRenderer_UnknownUserTooltip", [ownerName]).toString();
        else if(ownerName === RowShare.surveyUserEmail || ownerName === '(' + RowShare.surveyUserEmail + ')')
            result = i18n.t("MemberRenderer_SurveyUserTooltip", [ownerName]).toString();
        else if ((/\([^@]+@[^\.]+\.\w+\)/gi).test(ownerName))
            result = i18n.t("MemberRenderer_NoAccountUserTooltip", [ownerName]).toString();

        if (user) {
            result = user.authorName;
        }

        return result ?? "";
    }

    private static getNormalizedSecurityPrincipalType(securityPrincipal: RowShare.SecurityPrincipal | null, rowOwner: RowShare.RowOwner | null,
                                                      listAccess: RowShare.ListAccess | null, columnAccess: RowShare.ColumnAccess | null) : RowShare.SecurityPrincipalType | null {
        if(listAccess || rowOwner || columnAccess) {
            let type = listAccess ? listAccess.Guest.Type : (columnAccess ? columnAccess.Guest?.Type : rowOwner?.Type)

            switch(type){
                case (RowShare.OwnerType.User):
                    return RowShare.SecurityPrincipalType.User;
                case (RowShare.OwnerType.Member):
                    return RowShare.SecurityPrincipalType.Member;
                case (RowShare.OwnerType.Group):
                    return RowShare.SecurityPrincipalType.Group;
                case (RowShare.OwnerType.Team):
                    if(!listAccess && !columnAccess)
                        return null;

                    if(listAccess) {
                        if (listAccess.Options & RowShare.AccessOptions.ManagedOfMember) {
                            if (listAccess.Options & RowShare.AccessOptions.Recursive) {
                                return RowShare.SecurityPrincipalType.AllReports;
                            } else {
                                return RowShare.SecurityPrincipalType.DirectReports;
                            }
                        }
                    }
                    else if (columnAccess) {
                        if(columnAccess.TeamGrant == RowShare.TeamType.Direct) {
                            return RowShare.SecurityPrincipalType.DirectReports;
                        }
                        else if (columnAccess.TeamGrant == RowShare.TeamType.Extended) {
                            return RowShare.SecurityPrincipalType.AllReports;
                        }
                    }
                default:
                    return null;
            }
        } else if(securityPrincipal) {
            return securityPrincipal.type;
        } else {
            return null;
        }
    }
}
