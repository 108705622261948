
    import { Component } from "vue-property-decorator";
    import { UserModule } from '@/store/UserStore'
    import { OnPremModule } from '@/store/OnPremStore';
    import SectionTitle from '@/views/layouts/LayoutParts/SectionTitle.vue';
    import MicrosoftLoginButton from '@/views/Signin/MicrosoftLoginButton.vue'
    import LoginForm from '@/views/Signin/LoginForm.vue';
    import { ReturnUrlHandler } from "@/views/ReturnUrl";
    
    @Component({
        name: "LoginFba",
        components: { SectionTitle, MicrosoftLoginButton, LoginForm }
    })
    export default class LoginFba extends ReturnUrlHandler {

        private showView=false;

        constructor() {
            super();
        }

        async created(){
            if(!UserModule.CurrentUser.IsAnonymous)
                this.redirectToLanding();
            else
                this.showView = true;
        }

        async mounted(){           
            let browserLanguage = navigator.language;
            this.$i18n.locale = browserLanguage.substring(0,2);
        }

        redirectToLanding(){
            var defaultRedirectRoute = this.$router.resolve({ name: 'MyTables' });
            this.redirectToReturnUrlOrDefault(defaultRedirectRoute.href);
        }

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;;
        }
    }
