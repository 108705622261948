import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "../models/RowShare"

export class Catalog {
    static load(options: IRequestOptions | null = null) : Promise<RowShare.CatalogItem[] | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet(`/catalog/load/`, options)
                        .then(response => response.objects(RowShare.CatalogItem))
    }
    static getTableTemplateInfoById(listId: string, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet(`/tableTemplate/load/${listId}`, options)
                        .then(response => response.object(RowShare.TableTemplate))
    }
}