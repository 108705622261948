import * as RowShare from "@/models/RowShare";
import * as Api from '@/api/Api';
import { Utils } from '@/utils/Utilities';
import { EventBus } from '@/modules/EventBus';
import { i18n } from '@/modules/Localization';

export class MemberHelper {

    public static addMemberIfNecessary(securityPrincipal: string | RowShare.SecurityPrincipal | null | undefined, organizationId: string | null | undefined) {
        // If securityPrincipal is a string, it means that a member needs to be created
        if(!organizationId || !securityPrincipal || !Utils.isString(securityPrincipal))
            return;
        
        let newMember = new RowShare.Member();
        newMember.Email = securityPrincipal;
        let mail = securityPrincipal.split('@');            
        newMember.Name = mail[0];
        newMember.OrganizationId = organizationId;
        
        Api.Member.save(newMember)
                    .then((member) => {
                        if(member) {
                            EventBus.$emit(RowShare.Event.ORGANIZATION_CHANGED);
                            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                                autoHide: true,
                                autoHideAfterMs: 3000,
                                type: RowShare.NotificationType.success,
                                message: i18n.t('Assign_AddMemberSuccess', {email: member.Email, name: member.Name}).toString()
                            });
                        }
                    })
                    .catch((err) => {
                        EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                        autoHide : false,
                        type : RowShare.NotificationType.error,
                        message : i18n.t('Assign_CannotAddMember').toString()});
                    });
    }

}