
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class PageTitle extends Vue {
        @Prop() private title!: string;
        @Prop() private mainActionName!: string;
        @Prop() private mainActionUrl!: string;
        @Prop({default: true}) private compact!: boolean;
        
        get hasMainAction() {
            return this.mainActionName !== undefined && this.mainActionName !== null;
        }

    }
