import {IToolPanelParams} from "ag-grid-community";
import i18n from "@/modules/Localization";

export interface RealTimeCollaborationEntity {
    connectionId: string;
    connectionDate: string;
    eventType: string;
    methodToInvoke: string;
    groupId: string;
    authorName: string;
    authorEmail: string;
    bigram: string;
    backgroundColorIndex: number;
    data: Record<string, string>;
    // coWorkers: RealTimeCollaborationEntity[];
    // editingCells: EditingCell[];
}

export function computeConcernedEventsForUer(exceptions: RealTimeCollaborationEventType[]): RealTimeCollaborationEventType[] {
    let events: RealTimeCollaborationEventType[] = [
        RealTimeCollaborationEventType.Subscribe,
        RealTimeCollaborationEventType.UnSubscribe,
        RealTimeCollaborationEventType.RowCreate,
        RealTimeCollaborationEventType.RowDelete,
        RealTimeCollaborationEventType.RowCellUpdate,
        RealTimeCollaborationEventType.RowLock,
        RealTimeCollaborationEventType.RowUnlock,
        RealTimeCollaborationEventType.RowCellLock,
        RealTimeCollaborationEventType.RowCellUnlock,
        RealTimeCollaborationEventType.RowArchive,
        RealTimeCollaborationEventType.RowUnarchive,
        RealTimeCollaborationEventType.RowSave,
        RealTimeCollaborationEventType.RowSaveComment,
        RealTimeCollaborationEventType.RowDeleteComment,
        RealTimeCollaborationEventType.RowSaveBlob,
        RealTimeCollaborationEventType.RowDeleteBlob,
        RealTimeCollaborationEventType.ColumnSave,
        RealTimeCollaborationEventType.ColumnCreate,
        RealTimeCollaborationEventType.ColumnClone,
        RealTimeCollaborationEventType.ColumnDelete,
        RealTimeCollaborationEventType.ListDelete,
    ];

    for (const exception of exceptions) {
        events = events.filter(e => e !== exception) ?? [];
    }

    return events;
}

export interface RTCHistory {
    connectionId: string;
    connectionDate: string;
    eventType: string;
    methodToInvoke: string;
    groupId: string;
    authorName: string;
    bigram: string;
    backgroundColorIndex: number;
    data: Record<string, string>;
    // coWorkers: RealTimeCollaborationEntity[];
    // editingCells: EditingCell[];
}

export interface RealTimeCollaborationEntity {
    connectionId: string;
    connectionDate: string;
    eventType: string;
    methodToInvoke: string;
    groupId: string;
    authorName: string;
    authorEmail: string;
    bigram: string;
    backgroundColorIndex: number;
    data: Record<string, string>;
    // coWorkers: RealTimeCollaborationEntity[];
    // editingCells: EditingCell[];
}

export enum RealTimeCollaborationEventType {
    Subscribe = "Subscribe",
    UnSubscribe = "UnSubscribe",

    RowCreate = "RowCreate",
    RowDelete = "RowDelete",
    RowCellUpdate = "RowCellUpdate",
    RowLock = "RowLock",
    RowCellLock = "RowCellLock",
    RowUnlock = "RowUnlock",
    RowCellUnlock = "RowCellUnlock",
    RowArchive = "RowArchive",
    RowUnarchive = "RowUnarchive",

    RowSave = "RowSave",
    RowSaveBlob = "RowSaveBlob",
    RowDeleteBlob = "RowDeleteBlob",

    RowSaveComment = "RowSaveComment",
    RowDeleteComment = "RowDeleteComment",

    ColumnDelete = "ColumnDelete",
    ColumnCreate = "ColumnCreate",
    ColumnSave = "ColumnSave",
    ColumnClone = "ColumnClone",

    ListDelete = "ListDelete"
}

export class EditingCell {
    connectionId!: string;
    rowId!: string;
    columnId!: string;
    backgroundColorIndex!: string;
}

export class RtcHistoryItem {
    entity!: RealTimeCollaborationEntity;
    status!: string;
    formattedDate!: string;
    date!: Date;
    rowId!: string;
    panelParams!: IToolPanelParams
    columnId!: string;

    constructor(entity: RealTimeCollaborationEntity) {
        this.entity = entity; // rtcUser
        this.status = this.formatStatus();
        this.rowId = this.getFromRecord('rowId', entity.data);
        this.columnId = this.getFromRecord('columnId',  entity.data);
        const dateObject = new Date(entity.data['date']);
        const hours = dateObject.getHours().toString().padStart(2, "0");
        const minutes = dateObject.getMinutes().toString().padStart(2, "0");
        this.formattedDate = `${hours}:${minutes}`
        this.date = dateObject;
    }

    formatStatus(): string {
        let colName : string = this.getFromRecord("displayName", this.entity.data);
        let rowName : string = this.getFromRecord("displayName", this.entity.data);
        switch (this.entity.eventType) {
            case RealTimeCollaborationEventType.RowCreate:
                return i18n.t("RtcHistory_Row_Created_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowDelete:
                return i18n.t("RtcHistory_Row_Deleted_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowCellUpdate:
                return i18n.t("RtcHistory_Row_Updated_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowSaveBlob:
                return i18n.t("RtcHistory_Row_SaveBlob_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowDeleteBlob:
                return i18n.t("RtcHistory_Row_DeleteBlob_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowSaveComment:
                return i18n.t("RtcHistory_Row_SaveComment_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowDeleteComment:
                return i18n.t("RtcHistory_Row_DeleteComment_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowLock:
                return i18n.t("RtcHistory_Row_Locked_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowUnlock:
                return i18n.t("RtcHistory_Row_Unlocked_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowArchive:
                return i18n.t("RtcHistory_Row_Archive_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.RowUnarchive:
                return i18n.t("RtcHistory_Row_Unarchive_Status", [this.descriptor()]).toString();
            case RealTimeCollaborationEventType.ColumnDelete:
                return i18n.t('RtcHistory_Column_Deleted_Status', [colName]).toString();
            case RealTimeCollaborationEventType.ColumnCreate:
                return i18n.t('RtcHistory_Column_Created_Status', [colName]).toString();
            case RealTimeCollaborationEventType.ColumnSave:
                return i18n.t('RtcHistory_Column_Updated_Status', [colName]).toString();
            case RealTimeCollaborationEventType.ColumnClone:
                return i18n.t('RtcHistory_Column_Cloned_Status', [colName]).toString();
            case RealTimeCollaborationEventType.ListDelete:
                return 'List Deleted';
            case RealTimeCollaborationEventType.Subscribe:
                return 'Join edition';
            case RealTimeCollaborationEventType.UnSubscribe:
                return 'Leave edition';
            default:
                console.log(this.entity);
                return 'Unknown Event';
        }
    }

    getFromRecord(key: string, data: Record<string, string>): string {
        return key in data ? data[key] : '';
    }

    allowGoToLink(): boolean {
        return this.entity.eventType === RealTimeCollaborationEventType.RowSave
            //||this.entity.eventType === RealTimeCollaborationEventType.RowCreate
            || this.entity.eventType === RealTimeCollaborationEventType.RowSaveBlob
            || this.entity.eventType === RealTimeCollaborationEventType.RowDeleteBlob
            || this.entity.eventType === RealTimeCollaborationEventType.RowSaveComment
            || this.entity.eventType === RealTimeCollaborationEventType.RowDeleteComment
            || this.entity.eventType === RealTimeCollaborationEventType.RowCellUpdate
            || this.entity.eventType === RealTimeCollaborationEventType.RowUnarchive
            || this.entity.eventType === RealTimeCollaborationEventType.RowLock
            || this.entity.eventType === RealTimeCollaborationEventType.RowUnlock
    }

    scrollTo() {
        if (this.entity.eventType === RealTimeCollaborationEventType.RowLock
            || this.entity.eventType === RealTimeCollaborationEventType.RowUnlock
            || this.entity.eventType === RealTimeCollaborationEventType.RowUnarchive
        ) {
            this.scrollAndFlashRow();
            return;
        }
        this.scrollAndFlashCell();
    }


    scrollAndFlashRow() {
        if (!this.rowId) {
            return;
        }
        const rowNode = this.panelParams.api.getRowNode(this.rowId);
        const rowIndex = rowNode?.rowIndex ?? -1;
        console.log(rowIndex, "##", rowNode);
        if (rowIndex < 0 || !rowNode) {
            return;
        }
        console.log(rowIndex, "**", rowNode);
        this.panelParams.api.ensureIndexVisible(rowIndex, "middle");
        this.panelParams.api?.flashCells({
            rowNodes: [rowNode],
            flashDelay: 1000,
            fadeDelay: 100,
        });
    }

    scrollAndFlashCell(){
        if (!this.columnId && !this.rowId) {
            return
        }
        const rowNode = this.panelParams.api.getRowNode(this.rowId);
        const rowIndex = rowNode?.rowIndex ?? -1;
        if (rowIndex < 0 || !rowNode) {
            return;
        }
        this.panelParams.api.ensureIndexVisible(rowIndex, "middle");
        this.panelParams.api.ensureColumnVisible(this.columnId, "middle");
        this.panelParams.api?.flashCells({
            rowNodes: [rowNode],
            columns: [this.columnId],
            flashDelay: 1000,
            fadeDelay: 100,
        });
    }

    descriptor(): string {
        return this.getFromRecord("descriptorFormattedValue", this.entity.data);
    }

    static isSupportedEvent(entity: RealTimeCollaborationEntity): boolean {
        switch (entity.eventType) {
            case RealTimeCollaborationEventType.RowCellLock:
            case RealTimeCollaborationEventType.RowCellUnlock:
            case RealTimeCollaborationEventType.RowSave:
                return false;
        }
        return true;
    }
}