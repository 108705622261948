export class ResLoader {
    public static async loadCss(href: string){
      let existingElement = document.querySelector('link[href="' + href + '"]'); 
      if(existingElement)
        return existingElement;

      var elt = document.createElement("link");
      elt.href = href;
      elt.type = 'text/css';
      elt.rel = 'stylesheet';

      document.head.appendChild(elt);
      return elt;
    } 
}