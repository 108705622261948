import { CellDoubleClickedEvent, ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';

export default class CommentRenderer implements ICellRendererComp {
    rsRow!: RowVM | null;
    rsColumn!: ColumnVM | null;
    commentCount!: number;
    cellContainer!: HTMLElement;
    cellValue!: HTMLElement;
    params!: ICellRendererParams;

    init(params: ICellRendererParams) {
        this.params = params;
        this.rsRow = <RowVM>params.node.data;
        this.rsColumn = <ColumnVM>params.colDef;

        if(!this.rsColumn || !this.rsRow)
            return;
        
        this.commentCount = params.value ?? 0;

        this.cellContainer = document.createElement("span");
        this.cellContainer.classList.add("comment-renderer");
        this.cellContainer.setAttribute("data-comment-count", this.commentCount.toString());
        this.cellContainer.innerHTML = "<i class='v-icon notranslate fal fa-comment-alt-lines theme--light'></i>";
        if(this.commentCount == 0)
            this.cellContainer.classList.add("comment-renderer-empty");
    }

    getGui(): HTMLElement{
        return this.cellContainer;
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        this.commentCount = params.value ?? 0;

        if(this.cellContainer) {
            if(this.commentCount == 0) {
                this.cellContainer.classList.add("comment-renderer-empty");
            }
            else {
                this.cellContainer.classList.remove("comment-renderer-empty");
            }
        }
        
        return true;
    }
}