import { ApiUtils, IRequestOptions } from "./ApiUtils";
import * as RowShare from "@/models/RowShare";
import { ApiResult } from "@/models/ApiResult";

export class Report {
    static load(id: string, options: IRequestOptions | null = null)
    : Promise<RowShare.Report | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/report/load/" + ApiUtils.encodeSegment(id), options)
            .then(response => response.object(RowShare.Report));
    }

    static loadByList(listId: string, options: IRequestOptions | null = null)
    : Promise<RowShare.Report[] | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/report/loadforparent/" + ApiUtils.encodeSegment(listId), options)
            .then(response => response.objects(RowShare.Report));
    }

    static remove(id: string, options: IRequestOptions | null = null)
    : Promise<boolean | null> {
        return ApiUtils.apiPostJson("/report/delete/", { Id: id }, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<boolean>());
    }


    static save(report: Report, options: IRequestOptions | null = null)
    : Promise<RowShare.Report | null> {
        return ApiUtils.apiPostJson("/report/save/", report, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.object(RowShare.Report));
    }

    static saveProperty(propertyName: "file", reportId: string, data: any, options: IRequestOptions | null = null)
    : Promise<RowShare.Report | null> {
        return ApiUtils.apiPostJson("/report/save/" + ApiUtils.encodeSegment(reportId) + "/" + ApiUtils.encodeSegment(propertyName), 
                                    data, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.object(RowShare.Report));
    }

    static createJob(reportId: string, format: RowShare.ReportFormat, rowIds: string[] | null, options: IRequestOptions | null = null)
        : Promise<ApiResult<RowShare.Job> | null> {
        return ApiUtils.apiPostJson("/report/export/" + ApiUtils.encodeSegment(reportId) + "/" + ApiUtils.encodeSegment(format),
            { rowIds: rowIds }, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<ApiResult<RowShare.Job>>());
    }

    static test(reportId: string, format: RowShare.ReportFormat, options: IRequestOptions | null = null) : Promise<RowShare.Job | null> {
        var url = "/report/test/" + ApiUtils.encodeSegment(reportId) + "/" + ApiUtils.encodeSegment(format); 
        return ApiUtils.apiPostJson(url, ApiUtils.createRequestOptions({ clearCache: true }, options))
                       .then(response => response.object(RowShare.Job));
    }
}