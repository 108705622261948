
import { Component, Vue } from 'vue-property-decorator';
import { ICellEditorParams } from 'ag-grid-community';
import * as RowShare from '@/models/RowShare';
import * as API from '@/api/Api';
import { memberVM } from '@/viewModels/ManageOrganization/memberVM';
import { Utils } from '@/utils/Utilities';

@Component({name: 'memberEditor'})
export default class memberEditor extends Vue{
    members: RowShare.Member[] = [];
    params!: ICellEditorParams;
    currentMember!: memberVM;
    selectedManager: RowShare.Member | null = null;

    get selectedManagerEmail(): string {
        return this.selectedManager?.Email ?? "";
    }

    set selectedManagerEmail(value: string) {
        if(Utils.isNullOrWhiteSpace(value)) {
            this.selectedManager = null;
        }
        else {
            var member = this.members.find(m => m.Email == value);
            if(member) {
                this.selectedManager = member;
            }
        }
    }

    beforeMount(){
        this.currentMember = this.params.data as memberVM;
        this.selectedManager = this.currentMember.Manager;
        if(this.currentMember){
            API.Member.loadForParent(this.currentMember.OrganizationId)
                .then(members =>{
                    if(members) {
                        this.members = members.filter(m => m.Email !== this.currentMember.Email && m.IsEnabled);
                        this.selectedManagerEmail = this.currentMember.Manager?.Email ?? "";
                    }
                });
        }
    }

    selectManager(){
        if(this.selectedManager){
            this.currentMember.ManagerEmail = this.selectedManager.Email;
            this.currentMember.ManagerName = this.selectedManager.Name;
        }
        else{
            this.currentMember.ManagerEmail = '';
            this.currentMember.ManagerName = '';
        }
        this.params.stopEditing();
    }

    mounted(){
        (<any>this.$refs.selector).activateMenu();
    }

    getValue(){            
        return this.selectedManager;
    }
}
