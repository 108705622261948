
import {Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CalendarApi, CalendarOptions, EventClickArg, EventDropArg, ViewMountArg } from '@fullcalendar/core';
import frLocale from '@fullcalendar/core/locales/fr';
import esLocale from '@fullcalendar/core/locales/es';
import ListToolbar from '@/views/Table/ListActions/ListToolbar.vue'
import ListVM from '@/viewModels/Table/listVM';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import { Column, Event, ShowSidePanelFormViewEventParams } from '@/models/RowShare';
import calendarEventVM from '@/viewModels/Table/calendarEventVM';
import calendarViewVM from '@/viewModels/Table/calendarViewVM';
import { UserModule } from '@/store/UserStore';
import { EventBus } from '@/modules/EventBus';
import { ListTreeModule } from '@/store/ListTreeStore';
import RowVM from '@/viewModels/Table/rowVM';
import RowMergeDialog from '@/views/Table/ListActions/RowMergeDialog.vue';
import CommentEditorDialog from '@/views/Table/CommentEditorDialog.vue';
import RelationEditorDialog from '@/views/Table/cellsComponents/Relation/RelationEditorDialog.vue';
import _ from 'lodash';

@Component({name: 'ListCalendarView', components: { FullCalendar, ListToolbar, PageLoadSpinner, RowMergeDialog, CommentEditorDialog, RelationEditorDialog }})
export default class ListCalendarView extends Vue {
    @Prop() listVM!: ListVM;
    @Prop() startColumnId!: string;
    
    loadingEvents: boolean = true;
    userLocale: string | undefined = undefined;
    calendarApi: CalendarApi | undefined = undefined;
    selectedView: "dayGridMonth" | "timeGridWeek" | "timeGridDay" | "listMonth" = "dayGridMonth";
    title: string = "";
    calendarViewVM!: calendarViewVM;
    
    
    calendarEventVMs: calendarEventVM[] = [];

    public calendarOptions: CalendarOptions | null = null;

    get canShowDayAndWeekViews(): boolean {
        if(this.currentStartColumn) {
            return this.currentStartColumn.isDateTimeFormat;
        }
        return false;
    }

    get currentStartColumn() {
        return this.listVM?.columns?.find(c => c.Id === this.startColumnId);
    }

    get canAddNewRow() {
        return this.currentStartColumn && !this.currentStartColumn.isTechnical && this.listVM.canAddNewRow
                && this.selectedView == 'dayGridMonth';
    }

    get canEditEvent(): boolean {
        return !this.currentStartColumn?.isTechnical;
    }

    onCalendarViewMounted(arg: ViewMountArg) {        
        this.calendarApi = arg.view.calendar;
        this.refreshTitle();
    }

    @Watch("listVM.list")
    onListLoaded(){
        if(this.listVM.list) {
            document.title = this.listVM.list.DisplayName + " | RowShare";
        } else {
            document.title = "RowShare";
        }
    }    

    created() {
        this.calendarViewVM = new calendarViewVM(this.listVM);
        this.calendarViewVM.setRowsUpdateCallback((rows) => this.refreshEvents());
        this.calendarViewVM.setRowUpdateCallBack((row) => this.onRowUpdated(row));
        this.$nextTick(() => {
            EventBus.$on(Event.LOCALE_CHANGED, this.onLocaleChanged);
            EventBus.$on(Event.ROW_DELETE, this.onRowsDeleted);
        });
        this.calendarOptions = {
            themeSystem: 'standard',
            headerToolbar: false,
            initialView: 'dayGridMonth',
            plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
            dayMaxEvents: true,
            firstDay: 1,
            locales: [frLocale, esLocale],
            locale: this.userLocale,
            //showNonCurrentDates: false, Option updated to fix a bug in FullCalendar
            navLinks: false,
            events: this.calendarEventVMs,
            viewDidMount: this.onCalendarViewMounted,
            eventClick: this.onEventClicked,
            dateClick: this.onDateClicked,
            dayCellClassNames: ['daycell-header'],
            allDaySlot: false,
            eventStartEditable: this.canEditEvent,
            eventDrop: this.onEventDropped,
        };
    }

    beforeDestroy() {
        EventBus.$off([Event.LOCALE_CHANGED, Event.ROW_DELETE]);
    }

    mounted() {
        this.onLocaleChanged();
        this.refreshEvents();
    }

    refreshTitle() {
        if(!this.calendarApi) {
            return;
        }
        this.title = this.calendarApi.view.title.toUpperCase();
    }

    goToPreviousMonth() {
        if(!this.calendarApi) {
            return;
        }
        this.calendarApi.prev();
        this.refreshTitle();
    }

    goToNextMonth() {
        if(!this.calendarApi) {
            return;
        }
        this.calendarApi.next();
        this.refreshTitle();
    }
    
    goToToday() {
        if(!this.calendarApi) {
            return;
        }
        this.calendarApi.today();
        this.refreshTitle();
    }

    @Watch('selectedView')
    changeCurrentView() {
        if(!this.selectedView || !this.calendarApi) {
            return;
        }
        this.calendarApi.changeView(this.selectedView);
        this.refreshTitle();
    }

    onNavLinkDayClick(dateClicked: Date) {
        if(!dateClicked || !this.canShowDayAndWeekViews) {
            return;
        }
        if(this.calendarApi) {
            this.calendarApi.gotoDate(dateClicked);
        }
        this.selectedView = 'timeGridDay';
        
    }

    @Watch('startColumnId')
    onStartColumnIdChanged() {
        this.refreshEvents();
    }

    refreshEvents() {
        if(this.listVM?.list?.Id && this.startColumnId) {
            this.loadingEvents = true;
            this.$nextTick(() => {
                if(!this.calendarOptions) {
                    return;
                }
                let events = this.listVM.rowVMs?.map((row) =>
                {
                    var event = new calendarEventVM(this.listVM, row, this.startColumnId);
                    return event;
                });
                if(events) {
                    this.calendarEventVMs.push(...events);
                }
                this.loadingEvents = false;
            });
        }
    }

    onRowUpdated(row: RowVM) {
        if(!row || !this.calendarApi) {
            return;
        }
        var eventVM = new calendarEventVM(this.listVM, row, this.startColumnId);        
        var eventIndex = this.calendarEventVMs.findIndex((e) => { return e.id === row.rsRow.Id});
        if(eventIndex < 0) {
            this.calendarEventVMs.push(eventVM);
        }
        else {
            this.calendarEventVMs[eventIndex] = eventVM;
        }
        if(eventVM.start) {
            this.calendarApi?.gotoDate(eventVM.start);
        }
    }

    onRowsDeleted(rows: RowVM[]) {
        if(!rows || rows.length == 0 || !this.calendarApi) {
            return;
        }
        rows.forEach((row) => {
            var eventIndex = this.calendarEventVMs.findIndex((e) => { return e.id === row.rsRow.Id});
            if(eventIndex > -1) {
                this.calendarEventVMs.splice(eventIndex, 1);
            }
        });
    }

    onLocaleChanged() {
        if(!this.calendarOptions) {
            return;
        }
        this.calendarOptions.locale = UserModule.currentUserLanguage ?? '';
        this.$nextTick(() => this.refreshTitle());
    }

    onEventClicked(event: EventClickArg) {
        if(!event) {
            return;
        }
        EventBus.$emit(Event.SHOW_SIDEPANEL_FORMVIEW, <ShowSidePanelFormViewEventParams>{
            ListVM: this.listVM,
            rowId: event.event.id
        });
    }

    onDateClicked(event: DateClickArg) {
        if(!event || event.allDay) {
            return;
        }
        this.addEvent(event.date);
    }

    addEvent(eventDate: Date) {
        if(!this.listVM.list || !this.currentStartColumn) {
            return;
        }
        // First remove previously created row but not saved (closing side panel before saving)
        _.remove(this.listVM.rowVMs ?? [], (r) => {return r.isNew});

        var newRowVM = RowVM.createNewRow(this.listVM, this.listVM.list.Id);
        if(newRowVM) {
            newRowVM.setValue(this.currentStartColumn, eventDate);
            this.listVM.rowVMs?.push(newRowVM);
            EventBus.$emit(Event.SHOW_SIDEPANEL_FORMVIEW, <ShowSidePanelFormViewEventParams>{
                ListVM: this.listVM,
                rowId: 'new'
            });
        }
    }

    onEventDropped(infos: EventDropArg) {
        if(!infos || !infos.event.extendedProps.rowVM) {
            return;
        }
        var rowVM = <RowVM>infos.event.extendedProps.rowVM;
        if(!rowVM) {
            return;
        }
        if(!this.currentStartColumn) {
            return;
        }
        rowVM.setValue(this.currentStartColumn, infos.event.start);
        rowVM.innerSave();
    }
}
