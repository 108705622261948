import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"

export class ListTree {
    static async load(orgId: string | null = null, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let url = "/listtree/load/";
        if(orgId){
            url += ApiUtils.encodeSegment(orgId);
        }
        return ApiUtils.apiGet(url, options)
            .then(response => response.object(RowShare.ListTree));
            
    }

    static loadSiblings(id: string, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/listtree/loadsiblings/" + ApiUtils.encodeSegment(id), options)
            .then(response => response.objects(RowShare.ListThumbnailInfo));
    }
}

export class ListTreeFolder{
    static async load(folderId:string, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/listtreefolder/load/"+ ApiUtils.encodeSegment(folderId), options)
            .then(response => response.object(RowShare.ListTreeFolder));
    }
}