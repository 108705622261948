import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"

export class ListAccess {
    static async loadByList(listId: string, options: IRequestOptions | null = null)
     : Promise<Array<RowShare.ListAccess> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/listaccess/loadforparent/" + ApiUtils.encodeSegment(listId), options)
            .then(response => response.objects(RowShare.ListAccess));
    }

    static async load(id: string, options: IRequestOptions | null = null) 
    : Promise<RowShare.ListAccess | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/listaccess/load/" + ApiUtils.encodeSegment(id), options)
            .then(response => response.object(RowShare.ListAccess));
    }

    static async save(listAccess: RowShare.ListAccess, sendNotification: boolean, recaptchaToken: string | null = null, options: IRequestOptions | null = null)
    : Promise<RowShare.ListAccess | null> {
        var accessToSend = <any>listAccess;
        accessToSend.sendNotification = sendNotification;
        accessToSend.recaptchaToken = recaptchaToken;
        return ApiUtils.apiPostJson("/listaccess/save/", accessToSend, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.object(RowShare.ListAccess));
    }

    static async remove(listAccess: RowShare.ListAccess, options: IRequestOptions | null = null)
    : Promise<boolean | null> {
        return ApiUtils.apiPostJson("/listaccess/delete/", listAccess, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<boolean>());
    }

    static async addBatchOfAccess(listAccess: any, options: IRequestOptions | null = null)
    : Promise<RowShare.ListAccess[] | null> {
        return ApiUtils.apiPostJson("/listaccess/addBatchOfAccess/", listAccess, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.objects(RowShare.ListAccess));
    }

    static async saveMultiple(accessesToSave: RowShare.ListAccessesToSave, options: IRequestOptions | null = null)
    : Promise<RowShare.ApiResult<boolean> | null> {
        return ApiUtils.apiPostJson("/listaccess/savemultiple/", accessesToSave, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<RowShare.ApiResult<boolean>>());
    }

    static async resendInvitation(listAccessId: string, options: IRequestOptions | null = null)
    : Promise<RowShare.ApiResult<boolean> | null> {
        return ApiUtils.apiGet("/listaccess/ResendInvitation/" + ApiUtils.encodeSegment(listAccessId))
                .then(response => response.json<RowShare.ApiResult<boolean>>());
    }

}