

//This component is in charge of showing the components of the differents steps of the signUp 
//and redirecting towards the appropriate URLs.

import {Component, Prop } from 'vue-property-decorator';
import { UserModule } from '@/store/UserStore'
import * as API from '@/api/Api';

import SignUpEmail from '@/views/Signup/SignUpEmail.vue';
import PersonalData from '@/views/Signup/PersonalData.vue';
import Personalization from '@/views/Signup/Personalization.vue';
import JoinSociety from '@/views/Signup/JoinSociety.vue';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue'

import { namespace } from 'vuex-class';
import { User, SignupUser } from '@/models/User';
import { AddOrganization, InviteLink, Organization } from '@/models/RowShare';
import { ReturnUrlHandler } from '@/views/ReturnUrl';
import { Location as RouterLocation } from "vue-router";
import { Utils } from '@/utils/Utilities';
import { ListTreeModule } from '@/store/ListTreeStore';


@Component({
    name:'SignUp',
    components:{SignUpEmail, PersonalData, Personalization, JoinSociety, PageLoadSpinner}
})
export default class SignUp extends ReturnUrlHandler {

    @Prop() inviteLinkId!: string;
    @Prop() freeTrial!: boolean;

    private showView = false;
    private showJoinOrganization = false;
    private showPersonalization = false;
    private organization!:Organization;
    private email = '';
    private isCreateOrganization=false;
    checkingInviteLink: boolean = false;
    inviteLink: InviteLink | null = null;
    invalidLink: boolean = false;

    async mounted(){
        this.organization = new Organization();
        this.checkInviteLink(this.inviteLinkId);
        let userModel = UserModule.CurrentUser;
        if(userModel.IsAnonymous)
            this.showView = true;
        else{
            if(this.inviteLinkId) {
                await this.createMember();
            }
            else if(userModel.OrganizationsCount==0){
                this.showView = true;
                this.showPersonalization = true;
            }
            else if(this.$route.name=='NewSubscription'){
                this.isCreateOrganization=true;
                this.showView = true;
                this.showPersonalization = true;
            }
            else {
                var defaultRedirectRoute = this.$router.resolve({ name: 'MyTables' });
                this.redirectToReturnUrlOrDefault(defaultRedirectRoute.href);
            }
        }
    }

    get showFreeTrialMessage(): boolean {
        return this.freeTrial || this.returnUrl?.startsWith('/action/clonelist');
    }
    
    //If an inviteLinkId is provided, checks if inviteLink is valid and if organization as user slots remaining
    checkInviteLink(inviteLinkId: string) {
        if(Utils.isNullOrWhiteSpace(inviteLinkId)) {
            return;
        }
        this.checkingInviteLink = true;
        API.InviteLink.isValid(inviteLinkId)
                      .then(valid => {
                          if(!valid) {
                              this.invalidLink = true;
                          }
                      })
                      .finally(() => this.checkingInviteLink = false);
    }

    //Function that is called when the email is submitted in the component "signUpEmail"
    updateModelEmail(emailValue:string){
        this.email = emailValue;
    }

    async createMember() {
        if(!Utils.isNullOrWhiteSpace(this.inviteLinkId)) {
            let org = await API.InviteLink.createMember(this.inviteLinkId);
            if(org) {
                this.organization = org;
                await UserModule.refreshUser();
                await ListTreeModule.refreshOrganizationsTreeIfMissing(false);
                await ListTreeModule.refreshListTree();
                this.showView = true;
                this.showJoinOrganization = true;
            }
        }
    }

    //Function that is called when the user fills all the inputs in the component "personalData"
    //It shows the JoinOrganization or showPersonalization component, based on the existance
    //or not of a user for the email submitted.
    async dataUpdated(organizationParam:Organization){
        if(!Utils.isNullOrWhiteSpace(this.inviteLinkId)) {
            await this.createMember();
        }
        else {
            if(organizationParam!=null){         
                this.organization = organizationParam;   
                this.showJoinOrganization = true;
            }
            else{
                this.showPersonalization = true;
            }
        }
    }

    //Function that is called when an Organization is created in the component "Personalization"
    companyCreated(organizationParam:Organization){
       
        if(this.returnUrl && this.returnUrl.toLowerCase().trim() != "/mytables") {
            this.pushOrReloadRoute(this.returnUrl);
        } else {
            let defaultRedirectRoute = <RouterLocation>{
                name: 'CreateTable',
                query: {
                    organization: organizationParam.Id,
                    folderId: organizationParam.RootFolderId,
                    fromSignup: organizationParam.Name
                }
            };
            this.pushOrReloadRoute(defaultRedirectRoute);
        }
    }

    //Function that is called when the user joins the company in the component "JoinSociety"
    joinCompany(){
        var defaultRedirectRoute = this.$router.resolve({ name: 'MyTables' });
        defaultRedirectRoute.href = defaultRedirectRoute.href + '/' + this.organization.Id;
        this.redirectToReturnUrlOrDefault(defaultRedirectRoute.href);
    }

}
