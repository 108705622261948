import { ApiUtils, IRequestOptions, RowShareException } from "./ApiUtils"
import * as Models from "@/models/RowShare"
import { ApiResult } from "@/models/ApiResult";

export class List {

    static async load(id: string, options: IRequestOptions | null = null) 
    : Promise<Models.List | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let list = await ApiUtils.apiGet("/list/load/" + ApiUtils.encodeSegment(id) + "?allowSurveyMode=false", options);
        return list && list.data ? list.object(Models.List) : null;
    }

    static async loadForSurvey(id: string, options: IRequestOptions | null = null) 
    : Promise<Models.List | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let list = await ApiUtils.apiGet("/list/load/" + ApiUtils.encodeSegment(id) + "?allowSurveyMode=true", options);
        return list && list.data ? list.object(Models.List) : null;
    }

    static async loadForParent(folderId: string | null, options: IRequestOptions | null = null) 
    : Promise<Array<Models.List> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let lists = await ApiUtils.apiGet("/list/loadforparent/" + ApiUtils.encodeSegment(folderId || ""), options);
        return lists.objects(Models.List);
    }

    static async loadAllForParent(folderId: string | null, options: IRequestOptions | null = null) 
    : Promise<Array<Models.List> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let lists = await ApiUtils.apiGet("/list/loadallforparent/" + ApiUtils.encodeSegment(folderId || ""), options);
        return lists.objects(Models.List);
    }

    static async loadRecents(organizationId: string| null | undefined = null, options: IRequestOptions | null = null) 
    : Promise<Array<Models.List> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let recents = await ApiUtils.apiGet(organizationId ? `/list/loadrecents/${organizationId}` : '/list/loadrecents', options);
        return recents.objects(Models.List);
    }

    static async loadFavorites(organizationId: string, options: IRequestOptions | null = null) 
    : Promise<Array<Models.List> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let favs = await ApiUtils.apiGet(`/list/loadfavorites/${organizationId}`, options);
        return favs.objects(Models.List);
    }

    static async loadFolderHierarchy(id: string, options: IRequestOptions | null = null) 
    : Promise<Array<Models.Folder> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let folders = await ApiUtils.apiGet("/list/loadfolderhierarchy/" + ApiUtils.encodeSegment(id), options);
        return folders.objects(Models.Folder);
    }

    static async delete(IdList: string, options: IRequestOptions | null = null) 
    : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let data = { 'Id': IdList };
        let response = await ApiUtils.apiPostJson("/list/delete/", data, options);
        return response.json<boolean>();
    }
    
    static async clone(copyOptions: Models.CopyListInfo, options: IRequestOptions | null = null) 
    : Promise<Models.List | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let list = await ApiUtils.apiPostJson("/list/clone/", copyOptions, options);
        return list.object(Models.List);
    }

    static async loadOwners(listId: string, options: IRequestOptions | null = null) : Promise<Array<Models.ListOwner>|null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        let response = await ApiUtils.apiGet("/listowner/loadforparent/" + ApiUtils.encodeSegment(listId), options);
        return response.objects(Models.ListOwner);
    }

    static async saveOwner(listOwner: Models.ListOwner, options: IRequestOptions | null = null) : Promise<Models.ListOwner | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiPostJson("/listowner/save/", listOwner, options);
        return response.object(Models.ListOwner);
    }

    static async deleteOwner(listOwner: Models.ListOwner, options: IRequestOptions | null = null) : Promise<boolean|null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiPostJson("/listowner/delete/", listOwner, options);
        return response.json<boolean>();
    }

    static async save(list: Models.List, options: IRequestOptions | null = null) 
    : Promise<Models.List | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let listResponse = await ApiUtils.apiPostJson("/list/save/", list, options);
        return listResponse.object(Models.List);
    }

    static async saveWithFile(list: Models.List, file: Object | FileList | File | Blob | null, typeOfFile: string, options: IRequestOptions | null = null) 
    : Promise<Models.List | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiPostJsonWithFiles(`/list/save/${list.Id}${typeOfFile}`, list, file, options);
        return response.object(Models.List);
    }

    static async loadRemainingRowCount(listId: string, options: IRequestOptions | null = null) 
    : Promise<number | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiGet(`/list/remainingRowCount/${listId}`, options);
        return response.json<number>();
    }

    static async loadRemainingStorage(listId: string, options: IRequestOptions | null = null) 
    : Promise<number | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let response = await ApiUtils.apiGet(`/list/remainingStorage/${listId}`, options);
        return response.json<number>();
    }

    static async move(list: List, options: IRequestOptions | null = null) 
    : Promise<List | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let listResponse = await ApiUtils.apiPostJson("/list/move/", list, options);
        return listResponse.object(Models.List);
    }

    static async moveToRecycleBin(listId: string, options: IRequestOptions | null = null) 
    : Promise<Models.Folder | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let data = { ListId : listId };
        let folder = await ApiUtils.apiPostJson("/list/movetorecyclebin", data, options);
        return folder.object(Models.Folder);
    }
    
    static async loadChoice(listId: string, columnName: string, options: IRequestOptions | null = null)
    : Promise<Array<string> | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);        
        let choiceArray = await ApiUtils.apiGet(`/list/choice/${ApiUtils.encodeSegment(listId)}/${ApiUtils.encodeSegment(columnName)}`, options);
        return choiceArray.json<string[]>();
    }

    static async requestAccess(listId: string, options: IRequestOptions | null = null) 
    : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        let data = { ListId : listId };
        let response = await ApiUtils.apiGet("/list/requestaccess/" + ApiUtils.encodeSegment(listId), options);
        return response.json<boolean>();
    }

    static async IsReferenced(listId: string, options: IRequestOptions | null = null)
    : Promise<boolean | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet('/list/IsReferenced/' + ApiUtils.encodeSegment(listId), options)
            .then(response => response.json<boolean>())
    }

    //Added by Soukayna
    static async requestListAnalysis(listId: string)
    : Promise<ApiResult<Models.Job> | null> {
        var response = await ApiUtils.apiGet(`/ListAnalysis/Request/` + ApiUtils.encodeSegment(listId), null);
        return response.json<ApiResult<Models.Job>>();
    }

    static async Export(listId: string, exportContext: Models.ExportContext, options: IRequestOptions | null = null)
    : Promise<File | null> {
        return await ApiUtils.apiPostJsonForFileResponse(`/list/export/${ApiUtils.encodeSegment(listId)}`, exportContext);
    }
    
}
