
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
    import BlobField from '@/views/Table/cellsComponents/Blob/BlobField.vue';
    import RsColumnManager from '@/columnProxies/RsColumnManager';
    import FileVM from '@/viewModels/Table/FileVM';

    @Component({ name: 'BlobFormEditor', components:{ BlobField } })
    export default class BlobFormEditor extends FormEditor {
        pCurrentFile: FileVM | null = null;

        get currentFile() : FileVM | null{
            return this.pCurrentFile;
        }
        set currentFile(value : FileVM | null){
            this.pCurrentFile = value;
            this.saveCellValue();
        }

        init() : void {
            const proxy  = RsColumnManager.getProxy(this.column.Type);
            this.currentFile = this.rowVM.getValue(this.column, proxy);
        }     
        
        getValue() {
            return this.currentFile;
        }
    }
