import * as agGrid from 'ag-grid-community';
import { i18n } from "@/modules/Localization";
import { EventBus } from "@/modules/EventBus";
import * as API from "@/api/Api";
import * as RowShare from "@/models/RowShare";
import ColumnVM from './columnVM'
import RowVM from './rowVM'
import GridViewVM from './gridViewVM'
import { UserModule } from '@/store/UserStore';
import GridActions from './GridActions';
import { ApplicationModule } from '@/store/ApplicationStore';
import { ListTreeModule } from '@/store/ListTreeStore';
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

export class GridContextMenu {
    public static getContextMenuItems(params: agGrid.GetContextMenuItemsParams) : (string | agGrid.MenuItemDef)[]{
        var menuItems : (string | agGrid.MenuItemDef)[] = [];

        menuItems.push('copy');
        menuItems.push('copyWithHeaders');

        if(!params?.node?.data || params.node.data.isTotalRow || params.api.getEditingCells().length > 0)
            return menuItems;

        var rowVM = <RowVM>params.node.data;
        var colVM = <ColumnVM>params.column?.getColDef();

        if(colVM) {
            var colActions = colVM.columnProxy?.getContextMenuEntries(params);
            if(colActions) {
                menuItems.push('separator');
                for(let i = 0; i <= colActions.length; ++i)
                    menuItems.push(colActions[i]);
            }
        }


        var gridViewVM = <GridViewVM>params.context;
        let specialRowsDisplayed = gridViewVM.listVM?.specialRowsDisplayed;
        if(!rowVM.isNew && !specialRowsDisplayed) {
            menuItems.push('separator');
            if(!ApplicationModule.isMsTeamsApp) {
                menuItems.push(new RowFormViewContextMenuEntry(params));
            }
            menuItems.push(new SidePanelFormViewContextMenuEntry(params));
            if (!rowVM.listVM.hasUniqueColumns && !gridViewVM.isSorted && gridViewVM.listVM.canAddNewRow)
                menuItems.push(new CloneRowContextMenuEntry(params));
            if(rowVM.listVM.canAddNewRow && ((gridViewVM?.rowGroupsCount ?? 0) <= 0) && !gridViewVM.isSorted)
                menuItems.push(new InsertRowContextMenuEntry(params));
        }

        if(gridViewVM.listVM?.displayedRows === RowShare.DisplayedRows.Deleted) {
            menuItems.push('separator');
            menuItems.push(new RestoreRowContextMenuEntry(params));
        }
        if(gridViewVM.listVM?.displayedRows === RowShare.DisplayedRows.Archived) {
            menuItems.push('separator');
            menuItems.push(new UnarchiveRowContextMenuEntry(params));
        }

        if((rowVM.rsRow.CanSuppress)
            && ((rowVM.listVM.list?.Owned ?? false)
               || !(rowVM.listVM.hasReadOnlyColumns)
                  && !(rowVM.listVM.hasHiddenColumns))
            && !rowVM.isLocked) {
            menuItems.push(new DeleteRowContextMenuEntry(params, gridViewVM.listVM.deletedRowsDisplayed));
        }

        if((rowVM.listVM.list?.Owned ?? false) && gridViewVM.listVM.activeRowsDisplayed) {
            if(rowVM.isLocked) {
                menuItems.push(new UnlockRowContextMenuEntry(params));
            }
            else {
                menuItems.push(new ArchiveRowContextMenuEntry(params));
                menuItems.push(new LockRowContextMenuEntry(params));
            }
        }

        if(UserModule.CurrentUser.IsAdministrator) {
            menuItems.push('separator');    
            menuItems.push(new RowIdContextMenuEntry(params));
        }

        return menuItems;
    }
}

class SidePanelFormViewContextMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('Grid_SidePanelFormView').toString();
    private params: agGrid.GetContextMenuItemsParams;

    constructor(params: agGrid.GetContextMenuItemsParams) {
        this.params = params;
    }

    public action = () => {
        if(!this.params.node) {
            return;
        }
        const rowVM = <RowVM>this.params.node.data;
        const gridViewVM = <GridViewVM>this.params.context;
        EventBus.$emit(RowShare.Event.SHOW_SIDEPANEL_FORMVIEW, <RowShare.ShowSidePanelFormViewEventParams>{ListVM: gridViewVM?.listVM, rowId: rowVM.rsRow?.Id})
    }
}

class RowFormViewContextMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('Grid_RowFormView').toString();

    private params: agGrid.GetContextMenuItemsParams;

    constructor(params: agGrid.GetContextMenuItemsParams){
        this.params = params;
    }

    public action = () => {
        if(!this.params.node)
            return;

        var listId = this.params.node.data.rsRow.ListId;
        var rowId = this.params.node.data.rsRow.Id;
        window.location.href = '/tf/' + listId + '/' + rowId;
    }
}

class CloneRowContextMenuEntry implements agGrid.MenuItemDef {

    public name = i18n.t('ContextMenu_CloneRow').toString();

    private params: agGrid.GetContextMenuItemsParams;

    constructor(params: agGrid.GetContextMenuItemsParams){
        this.params = params;
    }

    public action = async () =>{
        if(!this.params.node)
            return;

        const gridViewVM = <GridViewVM>this.params.context;
        const rowVM = <RowVM>this.params.node.data;
        var newAgIdx = 0 ;
        try {
            let clonedRow = await API.Row.clone(rowVM.rsRow.Id, false, RealTimeCollaborationModule.connection.connectionId);
            newAgIdx = (this.params.node.rowIndex ?? 0) + 1;
            await gridViewVM.insertClonedRowInUI(clonedRow, newAgIdx, true);
            EventBus.$emit(RowShare.Event.ROW_ADDED, rowVM);
            EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, gridViewVM);
        }
        catch(err: any) 
        {
            EventBus.$emit(RowShare.Event.GRID_ERROR, { message: err.message });
        }       
    }
}
class InsertRowContextMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('ContextMenu_InsertRow').toString();

    private params: agGrid.GetContextMenuItemsParams;

    constructor(params: agGrid.GetContextMenuItemsParams){
        this.params = params;
    }

    public action = () => {
        if(!this.params.node)
            return;

        const gridViewVM = <GridViewVM>this.params.context;
        gridViewVM.addRow(this.params.node);
    }
}

class DeleteRowContextMenuEntry implements agGrid.MenuItemDef {
    private defaultLabel = i18n.t('ContextMenu_DeleteRow').toString();

    public name = this.defaultLabel;

    private params: agGrid.GetContextMenuItemsParams;

    constructor(params: agGrid.GetContextMenuItemsParams, deletedRowsDisplayed: boolean) {
        if(deletedRowsDisplayed) {
            this.name = i18n.t('ContextMenu_HardDeleteRow').toString();
        }
        this.params = params;
    }

    public action = () => {
        if(!this.params.node)
            return;

        const rowVM = <RowVM>this.params.node.data;
        const gridApi = this.params.api;
        const gridViewVM = <GridViewVM>this.params.context;
        if(!rowVM || ! gridApi) {
            
            console.error("Failed deleting the row");
            return;
        }
        
        GridActions.removeRow(rowVM, gridViewVM, gridViewVM.listVM.deletedRowsDisplayed, () => {});
    }
}

class ArchiveRowContextMenuEntry implements agGrid.MenuItemDef {
    private defaultLabel = i18n.t('ContextMenu_ArchiveRow').toString();
    public name = this.defaultLabel;
    private params: agGrid.GetContextMenuItemsParams;
    private archiveIcon = "<div class='menu-icon'><i class='fal fa-archive'/></div>";
    public icon: string;
    public cssClasses: string[] | undefined = [];

    constructor(params: agGrid.GetContextMenuItemsParams) {
        this.params = params;
        this.icon = this.archiveIcon;
        if(!(ListTreeModule.currentOrganization?.HasArchiveRows ?? false)) {
            this.cssClasses?.push('premiumfunction-contextMenu');
        }
    }

    public action = () => {
        if(!this.params.node) {
            return;
        }

        const rowVM = <RowVM>this.params.node.data;
        const gridApi = this.params.api;
        const gridViewVM = <GridViewVM>this.params.context;
        if(!rowVM || !gridApi) {
            console.error("Failed archiving the row (rowVM or gridAPI undefined)");
            return;
        }
        GridActions.archiveRow(rowVM, gridViewVM, () => {
            gridApi.applyTransaction({remove: [rowVM]});
            EventBus.$emit(RowShare.Event.ROW_ARCHIVE, [rowVM]);
        });
    }
}

class UnarchiveRowContextMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('ContextMenu_UnarchiveRow').toString();
    private params: agGrid.GetContextMenuItemsParams;
    constructor(params: agGrid.GetContextMenuItemsParams) {
        this.params = params;
    }

    public action = async () => {
        if(!this.params.node) {
            return;
        }
        const rowVM = <RowVM>this.params.node.data;
        const gridViewVM = <GridViewVM>this.params.context;
        if(!rowVM?.rsRow?.Id || !gridViewVM) {
            return;
        }
        let result = await API.Row.unarchive([rowVM.rsRow.Id], RealTimeCollaborationModule.connection.connectionId);
        if(result) {
            if(result.IsValid && result.Value) {
                EventBus.$emit(RowShare.Event.ROW_UNARCHIVE, [rowVM]);
            }
            else {
                EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 5000,
                    title: i18n.t('ContextMenu_UnarchiveRowError').toString(),
                    type: RowShare.NotificationType.error,
                    duplicateId: 'UnarchiveRows',
                    message: i18n.t(result.Errors[0].ExceptionCode.toString()).toString()
                });
            }
        }
    }
}

class RestoreRowContextMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('ContextMenu_RestoreRow').toString();
    private params: agGrid.GetContextMenuItemsParams;

    constructor(params: agGrid.GetContextMenuItemsParams) {
        this.params = params;
    }

    public action = async () => {
        if(!this.params.node) {
            return;
        }
        const rowVM = <RowVM>this.params.node.data;
        const gridViewVM = <GridViewVM>this.params.context;
        if(!rowVM?.rsRow?.Id || !gridViewVM) {
            return;
        }
        let result = await API.Row.Restore([rowVM.rsRow.Id]);
        if(result) {
            if(result.IsValid && result.Value) {
                EventBus.$emit(RowShare.Event.ROW_RESTORE, [rowVM]);
            }
            else {
                EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 5000,
                    title: i18n.t('ContextMenu_RestoreRowsError').toString(),
                    type: RowShare.NotificationType.error,
                    duplicateId: 'RestoreRows',
                    message: i18n.t(result.Errors[0].ExceptionCode.toString()).toString()
                });
            }
        }
    }
}

class RowIdContextMenuEntry implements agGrid.MenuItemDef {
    public name = "Row ID";

    private params: agGrid.GetContextMenuItemsParams;

    constructor(params: agGrid.GetContextMenuItemsParams){
        this.params = params;
        const rowVM = <RowVM>this.params?.node?.data;
        if(rowVM?.rsRow?.Id)
            this.name = rowVM?.rsRow?.Id;
    }

    public action = () => {
        document.addEventListener('copy', (event: ClipboardEvent) => this.copyToClipboard(event));
        document.execCommand('copy');
    }
    
    copyToClipboard(event: ClipboardEvent) {
        if(!this.params.node)
            return;

        const rowVM = <RowVM>this.params.node.data;
        event.clipboardData?.setData('text/plain', rowVM?.rsRow?.Id);
        event.preventDefault();
        document.removeEventListener('copy', this.copyToClipboard);
    }
}

class LockRowContextMenuEntry implements agGrid.MenuItemDef {
    private defaultLabel = i18n.t('ContextMenu_LockRow').toString();
    public name = this.defaultLabel;
    private params: agGrid.GetContextMenuItemsParams;
    private lockIcon = "<div class='menu-icon'><i class='fal fa-lock-keyhole'/></div>";
    public icon: string;
    public cssClasses: string[] | undefined = [];

    constructor(params: agGrid.GetContextMenuItemsParams) {
        this.params = params;
        this.icon = this.lockIcon;
        if(!(ListTreeModule.currentOrganization?.HasLockRows ?? false)) {
            this.cssClasses?.push('premiumfunction-contextMenu');
        }
    }

    public action = async () => {
        if(!this.params.node) {
            return;
        }

        const rowVM = <RowVM>this.params.node.data;
        const gridApi = this.params.api;
        const gridViewVM = <GridViewVM>this.params.context;
        if(!rowVM || !gridApi) {
            console.error("Failed locking the row (rowVM or gridAPI undefined)");
            return;
        }

        if(!(ListTreeModule.currentOrganization?.HasLockRows ?? false)) {
            EventBus.$emit(RowShare.Event.SHOW_UPSELL_DIALOG, <RowShare.ShowUpsellDialogEventParams> {
                title: i18n.t('UpsellDialog_Title').toString(),
                description: i18n.t('UpsellDialog_Description_LockRows').toString()
            });
            return;
        }

        let apiRes: RowShare.ApiResult<RowShare.Row[]> | null = null;
        apiRes = await API.Row.lock([rowVM.rsRow.Id], RealTimeCollaborationModule.connection.connectionId);
        if(apiRes) {
            if(apiRes.IsValid && apiRes.Value) {
                EventBus.$emit(RowShare.Event.ROW_LOCKED, apiRes.Value);
            }
            else {
                EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 5000,
                    title: i18n.t('ContextMenu_LockRowError').toString(),
                    type: RowShare.NotificationType.error,
                    duplicateId: 'LockRows',
                    message: i18n.t(apiRes.Errors[0].ExceptionCode.toString()).toString()
                });
            }
        }

    }
}

class UnlockRowContextMenuEntry implements agGrid.MenuItemDef {
    public name = i18n.t('ContextMenu_UnlockRow').toString();
    private params: agGrid.GetContextMenuItemsParams;
    private unlockIcon = "<div class='menu-icon'><i class='fal fa-lock-keyhole-open'/></div>";
    public icon: string;

    constructor(params: agGrid.GetContextMenuItemsParams) {
        this.params = params;
        this.icon = this.unlockIcon;
    }

    public action = async () => {
        if(!this.params.node) {
            return;
        }
        const rowVM = <RowVM>this.params.node.data;
        const gridViewVM = <GridViewVM>this.params.context;
        if(!rowVM?.rsRow?.Id || !gridViewVM) {
            return;
        }
        let result = await API.Row.unlock([rowVM.rsRow.Id], RealTimeCollaborationModule.connection.connectionId);
        if(result) {
            if(result.IsValid && result.Value) {
                EventBus.$emit(RowShare.Event.ROW_UNLOCKED, result.Value);
            }
            else {
                EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 5000,
                    title: i18n.t('ContextMenu_UnlockRowError').toString(),
                    type: RowShare.NotificationType.error,
                    duplicateId: 'UnlockRows',
                    message: i18n.t(result.Errors[0].ExceptionCode.toString()).toString()
                });
            }
        }
    }
}
