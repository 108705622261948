export class Organization {
    public Id!: string;
    public RootFolderId!: string;
    public Name!: string;
    public IconPath!: string | null;
    public FullSiteLogoPath!: string | null;
    public SmallSiteLogoPath!: string | null;
    public GroupCount?: number;
    public MemberCount!: number;
    public ActiveMemberCount!: number;
    public ReadonlyMemberCount!: number;
    public SupportMemberCount!: number;
    public DisabledRegularMemberCount!: number;
    public DisabledReadonlyMemberCount!: number;
    public MaximumMemberCount!: number;
    public ListCount!: number;
    public MaximumListCount!: number;
    public MetaData!: string;
    public AllowPublic!: boolean;
    //public AllowEmailAccess: AllowEmailAccessOptions;
    public IsDefault?: boolean;
    public CanCurrentUserManage!: boolean;
    public InTrial!: boolean;
    public CurrencyISOCode!: string;
    public SubscriptionIsFree!: boolean;
    public SubscriptionEnabled!: boolean;
    public CreationDateTimeUtc!: Date;
    public Logo_Remove!:boolean;
    public ManagerEquivalence!:boolean;
    public CanShowSubscriptionInfos!:boolean;
    public CanShowFreePlanInfos!:boolean;
    public IsCurrentUserAdministrator!:boolean;    
    public SubscriptionCurrentTermEndDateUtc!:Date;
    public CanShowChargebeePortal!:boolean;
    public CannotChangePlanAlone!:boolean;
    public CanAddMember!:boolean;
    public SubscriptionCleanDeletedRowsDelay!: number;
    public SubscriptionHasArchiveRows!: boolean;
    public SubscriptionLastUpdateDateTime!: Date;
    public AuditConservationInDays!: number;
    public SubscriptionHasAdvancedPermissions!: boolean;
    public SubscriptionHasLockRows!: boolean;
    public SubscriptionHasRowMetadatas!: boolean;
    public SubscriptionHasRowMerge!: boolean;
    public SubscriptionHasPermissionsAudit!: boolean;
    public SubscriptionFixedReadOnlyUsersQuantity!: number;
    public SubscriptionHasColumnPermissions!: boolean;
    public SubscriptionHasRTCHistory!: boolean;
}

export class AddOrganization {
    public Name!:string;
    public Members!:string;
    public MembersArray!:[];
    public PhoneNumber!: string;
    public CompanyName!: string;
    public WhyRowShareAnswer!: string;
    public UserCount!: string;
    public First!: boolean;
}
