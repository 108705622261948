
import { Event, ShowUpsellDialogEventParams } from "@/models/Event";
import { EventBus } from "@/modules/EventBus";
import { Vue, Component} from "vue-property-decorator";
import UpsellCard from '@/views/components/UpsellCard.vue';
import { ListTreeModule } from "@/store/ListTreeStore";

@Component({name: 'UpsellDialog', components: { UpsellCard }})
export default class UpsellDialog extends Vue {
    showMe: boolean = false;
    title: string = "";
    description: string = "";
    
    created() {
        EventBus.$on(Event.SHOW_UPSELL_DIALOG, (event: ShowUpsellDialogEventParams) => this.onShowUpsellDialogEvent(event))
    }

    beforeDestroy() {
        EventBus.$off(Event.SHOW_UPSELL_DIALOG);
    }

    onShowUpsellDialogEvent(event: ShowUpsellDialogEventParams) {
        this.title = event.title ?? "";
        this.description = event.description ?? "";
        this.showMe = true;
    }

    onCancel() {
        this.showMe = false;
    }

    get returnUrl(): string {
        return this.$route.fullPath;
    }

    get organizationId(): string {
        return ListTreeModule.currentOrganization?.Id ?? "";
    }

    get canGoToCheckOut(): boolean {
        return ListTreeModule.currentOrganization?.CanCurrentUserManage ?? false;
    }

}
