
import { Component, Vue, Prop, ModelSync, Watch } from 'vue-property-decorator';
import * as Models from '@/models/RowShare';
import * as Api from '@/api/Api';
import { EventBus } from '@/modules/EventBus';

@Component({name: 'ColumnSelector'})
export default class ColumnSelector extends Vue {
    @Prop() listId!: string;
    @Prop({default: true}) manageChildColumns!: boolean;
    @Prop({default: false}) disableNavigation!: boolean;
    @ModelSync('value', 'change') selectedColumnId!: string | null;
    allColumns: Models.Column[] | null = [];

    created() {
        this.initColumns();
        EventBus.$on(Models.Event.COLUMN_INFOS_CHANGED, (column: Models.Column) => this.updateColumnInfos(column));
    }

    beforeDestroy() {
        EventBus.$off([Models.Event.COLUMN_INFOS_CHANGED]);
    }

    updateColumnInfos(column: Models.Column) {
        if(this.allColumns && column) {
            let index = this.allColumns.findIndex((c) => c.Id == column.Id);
            if(index > -1) {
                this.allColumns[index].DisplayName = column.DisplayName;
            }
        }
    }

    @Watch('listId')
    onListIdChanged() {
        this.initColumns();
    }

    async initColumns() {
        if(this.listId && (this.allColumns?.length ?? 0) == 0) {
            var listColumns = await Api.Column.loadForList(this.listId, {cache: false});
            listColumns?.forEach(lc => {
                if(lc.HasParentColumn && this.manageChildColumns) {
                    if(this.allColumns?.findIndex(ac => ac.ColumnGroup === lc.ColumnGroup) == -1) {                        
                        lc.DisplayName = lc.ColumnGroup;
                        lc.Id = lc.RelationColumnId;
                        this.allColumns.push(lc);
                    }
                }
                else {
                    this.allColumns?.push(lc);
                }
            });
        }
    }

    get canGoToPrevious(): boolean {
        if(this.disableNavigation) {
            return false;
        }
        if(this.allColumns) {
            let index = this.allColumns.findIndex((c) => c.Id == this.selectedColumnId);
            return index > 0;
        }
        return true;
    }

    get canGoToNext(): boolean {
        if(this.disableNavigation) {
            return false;
        }
        if(this.allColumns) {
            let index = this.allColumns.findIndex((c) => c.Id == this.selectedColumnId);
            return index < this.allColumns.length - 1;
        }
        return true;
    }

    previousColumn() {
        if(this.allColumns) {
            let index = this.allColumns.findIndex((c) => c.Id == this.selectedColumnId);
            if(index > 0) {
                this.selectedColumnId = this.allColumns[index - 1].Id;
            }
        }
    }
    
    nextColumn() {
        if(this.allColumns) {
            let index = this.allColumns.findIndex((c) => c.Id == this.selectedColumnId);
            if(index < this.allColumns.length - 1) {
                this.selectedColumnId = this.allColumns[index + 1].Id;
            }
        }
    }
}
