
import { ListTreeModule } from '@/store/ListTreeStore';
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({name: 'UpsellCard'})
export default class UpsellCard extends Vue {
    @Prop() title!: string;
    @Prop() description!: string;
    @Prop({default: false}) showCancelButton!: boolean;
    @Prop() returnUrl!: string;
    @Prop() organizationId!: string;
    @Prop({default: false}) canGoToCheckOut!: boolean;


    onCancel() {
        this.$emit('cancel');
    }
}
