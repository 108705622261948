import i18n from "@/modules/Localization";
import * as Models from "@/models/RowShare";

export class ColumnAccess {
    Id!: string;
    TeamGrant!: TeamType;
    GroupId!: string;
    GroupName!: string;
    MemberEmail!: string;
    MemberOrganizationId!: string;
    MemberName!: string;
    ColumnId!: string;
    Guest!: Models.Owner;
    IsMyOrganization!: boolean;
    Access!: ColumnAccessLevel;
    
    public get principal() {
        const principal = new Models.SecurityPrincipal();
        principal.GroupId = this.GroupId;
        principal.GroupName = this.GroupName;
        principal.MemberEmail = this.MemberEmail;
        principal.MemberOrganizationId = this.MemberOrganizationId;
        principal.MemberName = this.MemberName;
        if(this.TeamGrant == Models.TeamType.Direct) {
            principal.Options = Models.AccessOptions.ManagedOfMember;
        }
        else if(this.TeamGrant == Models.TeamType.Extended) {
            principal.Options = principal.Options | Models.AccessOptions.Recursive | Models.AccessOptions.ManagedOfMember;
        }
        return principal;
    }

    public set principal(value: Models.SecurityPrincipal) {
        this.GroupId = value.GroupId;
        this.GroupName = value.GroupName;
        this.MemberEmail = value.MemberEmail;
        this.MemberOrganizationId = value.MemberOrganizationId;
        this.MemberName = value.MemberName;
        if((value.Options & Models.AccessOptions.ManagedOfMember) === Models.AccessOptions.ManagedOfMember) {
            
            this.TeamGrant = Models.TeamType.Direct;
            if((value.Options & Models.AccessOptions.Recursive) === Models.AccessOptions.Recursive) {
                this.TeamGrant = Models.TeamType.Extended;
            }
        }
    }

    public get Options(): Models.AccessOptions {
        if(this.TeamGrant == Models.TeamType.Direct) {
            return Models.AccessOptions.ManagedOfMember;
        }
        else if(this.TeamGrant == Models.TeamType.Extended) {
            return Models.AccessOptions.Recursive;
        }
        return Models.AccessOptions.None;
    }

    public static getNew(listOfAccesses: ColumnAccess[], securityPrincipal: Models.SecurityPrincipal, columnId: string, access: ColumnAccessLevel) {
        let newListAccess = new ColumnAccess();
        let accessId = newListAccess.existsAccess(listOfAccesses, securityPrincipal.GroupId, securityPrincipal.MemberEmail,
            securityPrincipal.MemberOrganizationId, securityPrincipal.Options);
        if (accessId != null) {
            newListAccess.Id = accessId;
        }
        newListAccess.principal = securityPrincipal;
        newListAccess.ColumnId = columnId;
        newListAccess.Access = access;
        newListAccess.IsMyOrganization = securityPrincipal.IsMyOrganization;
        return newListAccess;
    }

    public static getNewDefault(columnId: string) {
        let newListAccess = new ColumnAccess();
        newListAccess.Access = ColumnAccessLevel.RowAccess;
        newListAccess.ColumnId = columnId;
        return newListAccess;
    }

    existsAccess(listOfAccesses: Models.ColumnAccess[], groupId: string | undefined, memberEmail: string | undefined, 
                    memberOrganizationId: string | undefined, accessOptions: Models.AccessOptions) {
        let accessId = null;
        if (groupId != null) {
            let elem = listOfAccesses.find(ac => ac.GroupId == groupId);
            if (elem) {
                accessId = elem.Id
            }
        }
        if (memberEmail != null && memberOrganizationId != null) {
            let elem = listOfAccesses.find(ac => ac.MemberEmail == memberEmail && ac.MemberOrganizationId == memberOrganizationId && ac.Options == accessOptions);
            if (elem) {
                accessId = elem.Id;
            }
        }
        return accessId;
    }
}

// To keep synchronized with BackEnd
export enum ColumnAccessLevel {
    Hidden = 0,
    ReadOnly = 1,
    RowAccess = 2,
}

// To keep synchronized with BackEnd
export enum TeamType
{
    None = 0,
    Direct = 1,
    Extended = 2,
}

export class ColumnAccessesToSave {
    public columnId!: string;
    public newAccesses!: ColumnAccess[];
    public updatedAccesses!: ColumnAccess[];
    public deletedAccesses!: ColumnAccess[];

    constructor() {
        this.newAccesses = [];
        this.updatedAccesses = [];
        this.deletedAccesses = [];
    }
}
