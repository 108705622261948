import { ApiUtils, IRequestOptions } from "./ApiUtils";
import * as RowShare from "@/models/RowShare";


export class UserListLink {
    static loadByList(listId: string, options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/userlistlink/loadforparent/" + listId, options)
            .then(response => {
                if (response && response.data && response.data.length > 0) {
                    return response.object(RowShare.UserListLink);
                }
                else {
                    return null;
                }
            });
    }

    static save(listId: string, types: RowShare.UserListLinkTypes, options: IRequestOptions | null = null) {
        return ApiUtils.apiPostJson("/userlistlink/save/", { ListId: listId, Types: types }, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.object(RowShare.UserListLink));
    }

    static remove(listId: string, types: RowShare.UserListLinkTypes, options: IRequestOptions | null = null) {
        return ApiUtils.apiPostJson("/userlistlink/delete/", { ListId: listId, Types: types }, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.object(RowShare.UserListLink));
    }

}