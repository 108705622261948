import objectScan from 'object-scan';

export class JsonObjectSearch{

    static findFirstNodeById(id: any, searchtarget: any, searchField: string = 'Id'): any{
        let result : object | null = null;

        objectScan([`**.${searchField}`], {
          filterFn: (params: any) => 
          {
            if (params.value === id) {
              result = params.parents[0];
            }
          },
          breakFn: () => result !== null
        })(searchtarget);

        return result;
    }

    static searchAllNodesByName(name: string, searchtarget: any, searchField: string = 'Name'): Array<any>{
        let result : Array<object> = new Array<object>();
        
        if(name && name !== ''){
          objectScan([`**.${searchField}`], {
            filterFn: (params: any) => 
            {
              let lowercasedValue = params.value.toLowerCase();
              let lowercasedName = name.toLowerCase();
              if (lowercasedValue.includes(lowercasedName)) {
                result.push(params.parents[0]);
              }
            }
          })(searchtarget);
        }

        return result;
    }
}
