import { Module, VuexModule, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators'
import * as RowShare from "@/models/RowShare";
import * as API from '@/api/Api';
import { TrackingUtils } from "@/utils/Tracking";
import store from '@/modules/Store';

@Module({ dynamic: true, store: store, name: 'UserStore' })
export class UserStore extends VuexModule {
    public connectedUser: RowShare.User | null = null;
    public currentUserLanguage: string | null = null;

    /** getters **/

    // User data should be loaded by the router beforeEach, using this getter sooner will throw an error
    get CurrentUser(): RowShare.User {
        if (!this.connectedUser)
            throw new Error("User store CurrentUser was used before being loaded");

        return this.connectedUser
    }

    /** mutations **/

    @Mutation
    storeUser(user: RowShare.User | null) {
        this.connectedUser = user;
        if (user && !user.IsAnonymous)
            this.currentUserLanguage = user.Language;
        TrackingUtils.UpdateUserDataLayer(user);
    }

    @Mutation
    storeUserLanguage(language: string | null) {
        this.currentUserLanguage = language;
        if (this.connectedUser && !this.connectedUser.IsAnonymous)
            this.connectedUser.Language = language;
    }

    @Mutation
    disconnectUser() {
        if (this.connectedUser) {
            this.connectedUser = null;
        }
        if (this.currentUserLanguage) {
            this.currentUserLanguage = null;
        }
        localStorage.clear();

        TrackingUtils.UpdateUserDataLayer(null);
    }

    /** actions **/

    @Action({ commit: 'storeUser', rawError: true })
    async refreshUser(): Promise<RowShare.User> {
        let user: RowShare.User | null = null;
        user = await API.User.load({ cache: false });
        return user;
    }

    @Action({ rawError: true })
    async refreshUserIfMissing(): Promise<any> {
        if (!this.connectedUser)
            await this.refreshUser();
    }

    @Action({ rawError: true })
    async getUserLanguage(): Promise<string> {
        if (this.currentUserLanguage != null) {
            return this.currentUserLanguage;
        }
        else {
            if (this.CurrentUser!= null 
                && !this.CurrentUser.IsAnonymous
                && this.CurrentUser.Language != null)
            {   
                this.currentUserLanguage = this.CurrentUser.Language;
                return this.CurrentUser.Language;
            }
            return await this.refreshUserLanguage();
        }
    }

    @Action({ rawError: true })
    async refreshUserLanguage(): Promise<string> {
        let language: string | null = null;
        language = await API.User.loadLanguage({ cache: false });
        this.context.commit('storeUserLanguage', language);
        return language;
    }

    @Action({ rawError: true })
    async changeUserLanguage(newLanguage: string): Promise<string> {
        let userLang = await API.User.changeLanguage(newLanguage);
        this.context.commit('storeUserLanguage', userLang);
        document.documentElement.setAttribute('lang', newLanguage);
        return userLang;
    }

    @Action({ rawError: true })
    async signUpUser(signUpInfo: RowShare.SignupUser): Promise<boolean> {
        let userLoggedUp = await API.User.signup(signUpInfo);
        if (userLoggedUp != null) {
             this.context.commit('storeUser', userLoggedUp);
            return true;
        }
        else return false;
    }

    @Action({ rawError: true })
    async DemoSignIn(demoGuid: string): Promise<boolean> {
        let loginStatus = await API.User.demoSignin(demoGuid);
        if (loginStatus[0]) {
            let returnedUser: RowShare.User | null = loginStatus[1];
            if (returnedUser !== null) {
                this.context.commit('storeUser', returnedUser);
            }
            return true;
        }
        else {
            return false;
        }
    }

    @Action({ rawError: true })
    async SignIn(credentials: RowShare.SigninUser): Promise<boolean> {
        let loginStatus = await API.User.signin(credentials);
        if (loginStatus[0]) {
            let returnedUser: RowShare.User | null = loginStatus[1];
            if (returnedUser !== null) {
                this.context.commit('storeUser', returnedUser);
            }
            return true;
        }
        else {
            return false;
        }
    }

    @Action({ rawError: true })
    async signOut(): Promise<string | null> {
        var returnUrl = await API.User.signout();
        this.context.commit('disconnectUser');
        return returnUrl;
    }

    @Action({ rawError: true })
    async updateUserProfile(user: RowShare.User): Promise<RowShare.User> {
        let userProfile: RowShare.User | null = new RowShare.User();
        userProfile = await API.User.Save(user);

        // commit mutation to update user data in store
        let storeUser = this.CurrentUser;
        if (storeUser == null)
            storeUser = userProfile;

        storeUser.FirstName = user.FirstName;
        storeUser.LastName = user.LastName;
        storeUser.NickName = user.NickName;
        storeUser.TimeZoneId = user.TimeZoneId;
        storeUser.DefaultOrgId = user.DefaultOrgId;
        storeUser.PreviewFeaturesEnabled = user.PreviewFeaturesEnabled;
        storeUser.EmailNotifications = user.EmailNotifications;
        if (!user.IsAnonymous)
            storeUser.Language = user.Language;
        this.context.commit('storeUser', storeUser);

        return userProfile;
    }

    @Action({ rawError: true })
    async deleteUserProfile(): Promise<string | null> {
        let deleteRedirectUrl = await API.User.delete();
        if (this.CurrentUser != null)
            this.context.commit('disconnectUser');

        return deleteRedirectUrl;
    }

    @Action({ rawError: true })
    async deleteAccount(): Promise<string | null> {
        let result = await API.User.deleteAccount();
        return result;
    }
}

export const UserModule = getModule(UserStore);