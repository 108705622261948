
    import {Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import InputText from '@/views/components/FormInputs/InputText.vue';
    import ColorPicker from '@/views/components/ColorPicker.vue';
    import { Utils } from "@/utils/Utilities"
    import draggable from 'vuedraggable';
    import { KeyCodes } from '@/utils/KeyCodes';

    @Component({
        name: 'ColumnSettingsLookupEditor',
        components: {InputText, draggable, ColorPicker}
    })
    export default class ColumnSettingsLookupEditor extends Vue{
        @Prop({default: ''}) columnLookUpValues!: string;
        @Prop({default: ''}) helpText!: string;

        lookUpItems: ColumnSettingsLookupEditorItemVM[];
        itemsToAdd: string;

        constructor(){
            super();
            this.lookUpItems = [];
            this.itemsToAdd = "";
        }

        mounted(){
            this.parseItems(this.columnLookUpValues);
        }

        @Watch('columnLookUpValues')
        onValuesChanged(){
            this.parseItems(this.columnLookUpValues);
        }

        parseItems(values: string) {
            this.lookUpItems = [];
            if (!values) {
                return;
            }
            try {
                const items = JSON.parse(values)
                if (items) {
                    this.lookUpItems = items.map((v: any) => new ColumnSettingsLookupEditorItemVM(v.name ? v.name : v.value, v.color));                    
                }
            } 
            catch {
                var strvalues: string[];
                if (Utils.isArray(values)) {
                    strvalues = values;
                } else {
                    strvalues = (<string>values).replace(/\r/g, "").split("\n");
                }
                if (strvalues) {
                    
                    for (var i = 0; i < strvalues.length; i++) {
                        var val = strvalues[i];
                        var valueVM = new ColumnSettingsLookupEditorItemVM(val, "");
                        this.lookUpItems.push(valueVM);
                    }
                }
            }
        }

        @Watch('lookUpItems')
        onLookUpItemsChanged(){
            let newItemsValues = JSON.stringify(this.lookUpItems);
            if(newItemsValues !== this.columnLookUpValues){
                this.$emit('changed', newItemsValues);
            }
        }

        addItems(){
            if(this.itemsToAdd?.length > 0){
                var items = this.itemsToAdd.split(";");
                if (items) {
                    for (var i = 0; i < items.length; i++) {
                        if (items[i].trim().length > 0) {
                            if(!this.lookUpItems.find(li => li.name.toLowerCase() === items[i].toLowerCase())) {
                                this.lookUpItems.push(new ColumnSettingsLookupEditorItemVM(items[i].trim(), ""));
                            }
                        }
                    }
                    this.itemsToAdd = "";
                }
            }
        }

        onAddItemKeyDown(event: KeyboardEvent){
            if (event.keyCode ===  KeyCodes.DOM_VK_RETURN) {
                this.addItems();
            }
        }

        deleteItem(index: number){
            if(index > -1){
                this.lookUpItems.splice(index, 1);
            }
        }

        getColorrgb(item: ColumnSettingsLookupEditorItemVM) {
            var color = this.$vuetify.theme.currentTheme[item.color];
            if (color) {
                return color;
            } else {
                return "#FFFFFF";
            }
        }

        onClearColor(item: ColumnSettingsLookupEditorItemVM) {
            if(!item) {
                return;
            }
            item.color = '';
            this.onLookUpItemsChanged();
        }

        onColorSelected(item: ColumnSettingsLookupEditorItemVM) {
            if(item?.color == undefined) {
                return;
            }
            this.onLookUpItemsChanged();
        }

    }
    
    class ColumnSettingsLookupEditorItemVM{
    public name: string;
    public color: string;

    constructor(name: string, color: string){
        this.name = name;
        this.color = color;
    }
}
