import { ColumnTypes } from "@/utils/ColumnTypes";
import { ColumnOptions, ColumnStrongType } from "./Column";

export class ColumnType{
    public DisplayName!: string;
    public SortOrder!: number;
    public Options!: ColumnOptions;
    public Icon!: string;
    public StrongType!: ColumnStrongType;
    public Category!: ColumnTypeCategoryName;
    public aliasID!: number;
    public values!: string;
    public defaultValue!: string;

    //the below field is (for now) fully calculated, i.e don't come from back-end
    public HasRequiredSubscription: boolean = true;

    public get mustBeConfigured() {
        const strongtypes = [ColumnStrongType.LookUp, ColumnStrongType.LookUpList, ColumnStrongType.Relation];
        return ((strongtypes.includes(this.StrongType) && this.values !== ColumnTypes.defaultRatingLookupValues
                && this.values !== ColumnTypes.defaultThreeStateBooleanLookupValues)
            || this.hasOption(ColumnOptions.IsFormula));
    }

    public get isFrequent() {
        const strongtypes = [ColumnStrongType.Text, ColumnStrongType.Number, ColumnStrongType.OwnerName, ColumnStrongType.File, ColumnStrongType.Date,
            ColumnStrongType.DecimalNumber, ColumnStrongType.Image, ColumnStrongType.Comment];
        return strongtypes.includes(this.StrongType) && (!this.aliasID || this.aliasID === 0);
    }

    public get mustRefreshValuesFromServer() {
        const strongtypes = [ColumnStrongType.AutoNumber, ColumnStrongType.RowIndex, ColumnStrongType.CreatedByUserEmail,
            ColumnStrongType.CreatedByUserName, ColumnStrongType.CreationDate, ColumnStrongType.LastWriteDate,
            ColumnStrongType.LastWriteUserEmail, ColumnStrongType.LastWriteUserName, ColumnStrongType.OwnerEmail,
            ColumnStrongType.OwnerName, ColumnStrongType.MemberManagerEmail, ColumnStrongType.MemberManagerName];
        return strongtypes.includes(this.StrongType);
    }

    private hasOption(option: ColumnOptions) {
        return (this.Options & option) === option;
    }


    public static metadataTypes(): ColumnStrongType[] {
        return [
            ColumnStrongType.OwnerName, ColumnStrongType.OwnerEmail, ColumnStrongType.CreationDate,
            ColumnStrongType.CreatedByUserEmail, ColumnStrongType.LastWriteDate,
            ColumnStrongType.LastWriteUserName, ColumnStrongType.MemberManagerName, ColumnStrongType.RowIndex,
            ColumnStrongType.CreatedByUserName
        ];
    }

    public static lookupListTypes(): ColumnStrongType[] {
        return [ColumnStrongType.LookUpList, ColumnStrongType.LookUp]
    }

    public static relatedRowTypes(): ColumnStrongType[] {
        return [ColumnStrongType.Relation];
    }
}

export enum ColumnTypeCategoryName{
    text = "text",
    number = "number",
    visual = "visual",
    lookUp = "lookup",
    date = "date",
    media = "media",
    row = "row",
    formulas = "formulas",
    frequent = "frequent"
}

export class ColumnTypeCategory {
    public Name!: string;
    public DisplayName!: string;
    public SortOrder!: number;
}
