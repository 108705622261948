
import {Vue, Component, Prop } from 'vue-property-decorator';
import { EventBus } from "@/modules/EventBus";
import { UserModule } from '@/store/UserStore';
import { OnPremModule } from '@/store/OnPremStore';
import * as API from '@/api/Api';
import * as Models from '@/models/RowShare';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import Bigram from "@/views/components/Bigram.vue";
import moment from 'moment';

@Component({
    name: 'BackOfficeOrganization',
    components: {PageLoadSpinner, Bigram}
})
export default class BackOfficeOrganization extends Vue{
    @Prop() private organizationId!: string;
    private org: Models.Organization | null = null;
    private members: Models.BackOfficeMember[] | null = null;
    private search: string | null = null;
    savingChangePlanAlone: boolean = false;
    currentPlanId: string = "";
    refreshingBillingInfos: boolean = false;
    async created(){
        this.refreshData();
    }

    async refreshData(){
        this.org = await API.BoApi.loadOrganization(this.organizationId, {cache: false});
        this.members =  await API.BoApi.loadMembers(this.organizationId, {cache: false});
        this.currentPlanId = await API.Organization.getCurrentPlanId(this.organizationId, {cache: false}) ?? "No Active Plan";
    }

    get chargeBeeUrl() : string {
        if(!this.org) {
            return "";
        }
        let cbHost = "rowshare.chargebee.com";
        if(process.env.NODE_ENV == "development") {
            cbHost = "rowshare-test.chargebee.com";
        }
        return `https://${cbHost}/d/customers/${this.org.Id}`;
    }

    get isNotOnPrem(){
        return !OnPremModule.isOnPrem;;
    }

    get filteredMembers(){
        if(!this.members)
            return null;

        var res : Models.BackOfficeMember[];
        if(!this.search) {
            var res = this.members;
        } else {
            res = this.members.filter(m => {
                if(!this.search) {
                    return true;
                }

                if(m.Email.toLowerCase().includes(this.search.toLowerCase())) {
                    return true;
                }

                if(m.Name && m.Name.toLowerCase().includes(this.search.toLowerCase())) {
                    return true;
                }

                if(m.AssociatedUserNickName && m.AssociatedUserNickName.toLowerCase().includes(this.search.toLowerCase())) {
                    return true;
                }

                if(m.Groups) {
                    var gr = m.Groups.find(g => g.Name.toLowerCase().includes(this.search?.toLowerCase() ?? ""));
                    if(gr) {
                        return true;
                    }
                }

                if(m.ManagerEmail && m.ManagerEmail.toLowerCase().includes(this.search.toLowerCase())) {
                    return true;
                }

                if(m.ManagerName && m.ManagerName.toLowerCase().includes(this.search.toLowerCase())) {
                    return true;
                }

                if("administrator".includes(this.search.toLowerCase()) && m.IsAdministrator) {
                    return true;
                }

                if("rowshare support".includes(this.search.toLowerCase()) && m.IsRowShareSupport) {
                    return true;
                }

                if("enabled".includes(this.search.toLowerCase()) && !m.IsDisabled) {
                    return true;
                }

                return false;
            });
        }

        return res.sort(function(a, b) {
            if(a.IsRowShareSupport && !b.IsRowShareSupport)
                return -1;
            if(b.IsRowShareSupport && !a.IsRowShareSupport)
                return 1;

            if(a.IsAdministrator && !b.IsAdministrator)
                return -1;
            if(b.IsAdministrator && !a.IsAdministrator)
                return 1;

            if(a.IsUniversalOwner && !b.IsUniversalOwner)
                return -1;
            if(b.IsUniversalOwner && !a.IsUniversalOwner)
                return 1;

            if(a.IsDisabled && !b.IsDisabled)
                return 1;
            if(b.IsDisabled && !a.IsDisabled)
                return -1;

            if(a.IsReadOnly && !b.IsReadOnly)
                return 1;
            if(b.IsReadOnly && !a.IsReadOnly)
                return -1;

            return a.Email.toLowerCase().localeCompare(b.Email.toLowerCase());
        });
    }

    pickLogo() {
        (<HTMLElement>this.$refs.logoInput).click();
    }

    pickFullSiteLogo() {
        (<HTMLElement>this.$refs.fullSiteLogoInput).click();
    }

    pickSmallSiteLogo() {
        (<HTMLElement>this.$refs.smallSiteLogoInput).click();
    }

    refreshBillingInfos() {
        this.refreshingBillingInfos = true;
        API.Organization.refreshBillingInfos(this.organizationId)
            .then(res => {
                if(res) {
                    this.refreshData();
                }
            })
            .finally(() => { this.refreshingBillingInfos = false });
    }

    async onLogoSelected(){
        let files = (<HTMLInputElement>this.$refs.logoInput).files;
        if(!files || files.length != 1)
            return;

        var res = await API.BoApi.saveOrgLogo(this.organizationId, "logo",files[0]);
        this.reportSave(res, "updating logo");
        this.refreshData();
    }

    async onFullSiteLogoSelected(){
        let files = (<HTMLInputElement>this.$refs.fullSiteLogoInput).files;
        if(!files || files.length != 1)
            return;

        var res = await API.BoApi.saveOrgLogo(this.organizationId, "fullSiteLogo",files[0]);
        this.reportSave(res, "updating full site logo");
        this.refreshData();
    }

    async onSmallSiteLogoSelected(){
        let files = (<HTMLInputElement>this.$refs.smallSiteLogoInput).files;
        if(!files || files.length != 1)
            return;

        var res = await API.BoApi.saveOrgLogo(this.organizationId, "smallSiteLogo",files[0]);
        this.reportSave(res, "updating small site logo");
        this.refreshData();
    }

    async removeLogo(logoType: ('logo' | 'fullSiteLogo' | 'smallSiteLogo')){
        var evtArgs = new Models.ConfirmationRequiredEventParams();
        evtArgs.title = "Remove logo";
        evtArgs.description1 = "Remove the organization logo (" + logoType + ") ?";
        evtArgs.cancelButtonText = "Cancel";
        evtArgs.actionButtonText = "Confirm";
        evtArgs.actionButtonIcon = "trash-alt";
        evtArgs.actionButtonColor = 'error';

        evtArgs.onConfirmation = async () => {
            var res = await API.BoApi.saveOrgLogo(this.organizationId, logoType, null);
            this.reportSave(res, "removing logo");
            this.refreshData();
        }

        EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, evtArgs);
    }

    async saveCannotChangePlanAlone() {
        var res: boolean | null = false;
        if(this.org) {
            this.savingChangePlanAlone = true;
            API.BoApi.saveOrganizationProperty(this.organizationId, "CannotChangePlanAlone", this.org.CannotChangePlanAlone + '')
                .then(res => {
                    this.reportSave(res, "update CannotChangePlanAlone");
                    this.refreshData();
                })
                .finally(() => { this.savingChangePlanAlone = false; });
        }
    }

    formatDate(date: string | Date) {

        var d = moment(date, undefined, 'en');
        if(! d.isValid() || d.year() == 1) {
            return "";
        }

        var from = d.fromNow();
        var exact = d.format("YYYY-MM-DD HH:mm");

        return from + " (" + exact + ")";
    }

    getMemberIcon(member: Models.Member) {
        if(member.IsDisabled)
            return "fal fa-user-slash";

        if(member.IsRowShareSupport)
            return "fas fa-user-astronaut";

        if(member.IsAdministrator)
            return "fas fa-user-crown";

        if(member.IsUniversalOwner)
            return "fas fa-user-cowboy";

        if(member.IsReadOnly)
            return "fas fa-user-ninja";

        return "fas fa-user";
    }

    private readonly adminColor = "primary";
    private readonly rsSupportColor = "#CCA43D";
    private readonly universalOwnerColor = "primary lighten-1";

    getMemberColor(member: Models.Member) {
        if(member.IsRowShareSupport)
            return this.rsSupportColor;

        if(member.IsAdministrator)
            return this.adminColor;

        if(member.IsUniversalOwner)
            return this.universalOwnerColor;

        return "#C4C4C4";
    }

    get currentUserEmail(){
        return UserModule.CurrentUser.Email;
    }

    async addCurrentUserForSupport(){
        this.addSupportUser(this.currentUserEmail);
    }

    readonly genericSupportEmail = "support@rowshare.com";

    async addGenericSupportUser(){
        this.addSupportUser(this.genericSupportEmail);
    }

    async addSupportUser(email: string){
        var member = new Models.Member();
        member.Email = email;
        member.OrganizationId = this.organizationId;
        member.IsAdministrator = true;
        member.IsRowShareSupport = true;

        var res = await API.BoApi.saveMember(member);
        this.reportSave(res, "adding support member");
        this.refreshData();
    }

    async toggleAdmin(member: Models.Member) {
        var evtArgs = new Models.ConfirmationRequiredEventParams();
        evtArgs.title = "Edit member";
        evtArgs.description1 = (member.IsAdministrator ? "Remove" : "Add") + " the administrator status to " + member.Email + " ?";
        evtArgs.cancelButtonText = "Cancel";
        evtArgs.actionButtonText = "Confirm";
        evtArgs.actionButtonIcon = "crown";
        evtArgs.actionButtonColor = 'primary';

        evtArgs.onConfirmation = async () => {
            member.IsAdministrator = !member.IsAdministrator;
            var res = await API.BoApi.saveMember(member);
            this.reportSave(res, "changing admin status");
            this.refreshData();
        }

        EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, evtArgs);
    }

    async toggleSupport(member: Models.Member) {
        var evtArgs = new Models.ConfirmationRequiredEventParams();
        evtArgs.title = "Edit member";
        evtArgs.description1 = (member.IsRowShareSupport ? "Remove" : "Add") + " the RowShare support status to " + member.Email + " ?";
        evtArgs.cancelButtonText = "Cancel";
        evtArgs.actionButtonText = "Confirm";
        evtArgs.actionButtonIcon = "rocket";
        evtArgs.actionButtonColor = 'primary';

        evtArgs.onConfirmation = async () => {
            member.IsRowShareSupport = !member.IsRowShareSupport;
            var res = await API.BoApi.saveMember(member);
            this.reportSave(res, "changing support status");
            this.refreshData();
        }

        EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, evtArgs);
    }

    async deleteOrganization() {
        var evtArgs = new Models.ConfirmationRequiredEventParams();
        evtArgs.title = "Delete organization";
        evtArgs.description1 = "Delete organization " + this.org?.Name + " ?";
        evtArgs.cancelButtonText = "Cancel";
        evtArgs.actionButtonText = "Confirm";
        evtArgs.actionButtonIcon = "trash";
        evtArgs.actionButtonColor = 'error';
        evtArgs.onConfirmation = async () => {
            if(this.org) {
                API.BoApi.deleteOrganization(this.org)
                    .then(res => {
                        if(res) {
                            this.reportSave(res, "deleting organization");
                            this.$router.push({name:'bo'});
                        }
                    });
            }
        }
        EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, evtArgs);
    }

    async deleteMember(member: Models.Member) {
        var evtArgs = new Models.ConfirmationRequiredEventParams();
        evtArgs.title = "Remove member";
        evtArgs.description1 = "Remove member " + member.Email + " ?";
        evtArgs.cancelButtonText = "Cancel";
        evtArgs.actionButtonText = "Confirm";
        evtArgs.actionButtonIcon = "trash";
        evtArgs.actionButtonColor = 'error';

        evtArgs.onConfirmation = async () => {
            var res = await API.BoApi.deleteMember(member);
            this.reportSave(res, "deleting member");
            this.refreshData();
        }

        EventBus.$emit(Models.Event.CONFIRMATION_REQUIRED, evtArgs);
    }

    reportSave(res: boolean | null, operation: string){
        if(res) {
            EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{
                autoHide: true,
                autoHideAfterMs: 3000,
                type: Models.NotificationType.success,
                message: "Done " + operation
            });
        } else {
            EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{
                autoHide: true,
                autoHideAfterMs: 3000,
                type: Models.NotificationType.error,
                message: "Failed " + operation
            });
        }
    }

    sendInvite(member: Models.Member) {
        if(!member) {
            return;
        }
        API.Member.resendInvitation(member)
            .then(res => {
                this.reportSave(res, "sending user invite");
            });
    }
}
