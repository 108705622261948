
import { ICellEditorParams } from 'ag-grid-community';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({name: "TimeEditor"})
export default class TimeEditor extends Vue{
    params!: ICellEditorParams;
    cellValue: string = "";
    maxLength: number|null = null;

    mounted(){
        this.refreshDisplay();        
        this.$nextTick(() => {
            (<HTMLElement>this.$refs.textInput)?.focus();
        });
    }

    refreshDisplay() {
        if (this.params.eventKey === "Delete") // suppr 
            this.cellValue = '';
        else if (this.params.eventKey && this.params.eventKey.length == 1 && "0123456789:".includes(this.params.eventKey))
            this.cellValue = this.params.value + this.params.eventKey;
        else
            this.cellValue = this.params.value;
    }

    getValue() {
        if(this.cellValue == '') {
            return '';
        }
        if(this.cellValue.match(/^\d{2}:\d{2}:\d{2}$/) || this.cellValue.match(/^\d{2}:\d{2}$/))
            return this.cellValue;
        if(this.cellValue.match(/^\d{2}:\d{2}:\d{1}$/) || this.cellValue.match(/^\d{2}:\d{1}$/))
            return this.cellValue + '0';
        if(this.cellValue.match(/^\d{2}:\d{2}:$/)) 
                return this.cellValue.substring(0, 5);
        else {
            return this.params.value;
        }
    }
}
