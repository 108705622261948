
import {Prop, Vue, Component, Watch} from 'vue-property-decorator';
import CardView from '@/views/Table/CardView/CardView.vue';
import RowFormView from '@/views/Table/FormView/RowFormView.vue';
import ListVM from '@/viewModels/Table/listVM';
import ListToolbar from '@/views/Table/ListActions/ListToolbar.vue';
import RowVM from '@/viewModels/Table/rowVM';
import RowMergeDialog from '@/views/Table/ListActions/RowMergeDialog.vue';
import CommentEditorDialog from '@/views/Table/CommentEditorDialog.vue';
import RelationEditorDialog from '@/views/Table/cellsComponents/Relation/RelationEditorDialog.vue';

@Component({
    name: 'ListFormView',
    components: {CardView, RowFormView, ListToolbar, RowMergeDialog, CommentEditorDialog,
                 RelationEditorDialog}
})
export default class ListFormView extends Vue {
    @Prop() listVM!: ListVM;
    @Prop() rowId!: string;
    @Prop({default: ""}) currentRowId!: string;
    scrollToRowId: string = '';

    mounted() {
        this.init();
    }
    
    @Watch('rowId')
    onRowIdChanged() {
        this.init();
    }

    @Watch('currentRowId')
    onCurrentRowIdChanged() {
        this.init();
    }

    get showHeader(): boolean {
        return !(this.listVM?.msTeamsMode ?? false);
    }
    
    init() {
        if(this.currentRowId) {
            this.scrollToRowId = this.currentRowId;
        } else if(this.rowId) {
            this.scrollToRowId = this.rowId;
        }
    }

    get canShowCardView() {
        if(!this.$vuetify.breakpoint.mobile) {
            return true;
        } else {
            return !this.rowId || this.rowId === "";
        }
    }

    get canShowFormView() {
        if(!this.$vuetify.breakpoint.mobile) {
            return true;
        } else {
            return this.rowId && this.rowId !== "";
        }
    }

    onCardClick(row: RowVM) {
        this.$router.push(
            { 
                name: 'Form', 
                params: 
                { 
                    listId : row.rsRow.ListId, 
                    rowId : row.rsRow.Id,
                    mode: 'details'
                }
            }
        );
    }
}
