
import { EventBus } from '@/modules/EventBus';
import { IToolPanelParams } from 'ag-grid-community';
import { Component, Vue } from 'vue-property-decorator';
import * as models from '@/models/RowShare';
import { Job } from '@/api/Job';
import { AnalysisAnomaly } from '@/models/RowShare';
import {marked} from 'marked';

@Component({name: 'AnalysisToolPanel'})
export default class AnalysisToolPanel extends Vue {
    params!: IToolPanelParams;
    currentAnalysisResponse: models.AnalysisResponse | null = null;


    created() {
        EventBus.$on(models.Event.LISTANALYSIS_REFRESH, (job: models.Job) => this.refreshAnalysis(job));
    }

    beforeDestroy() {
        EventBus.$off(models.Event.LISTANALYSIS_REFRESH);
    }

    refreshAnalysis(job: models.Job) {
        this.currentAnalysisResponse = null;
        Job.loadAnalysisResponseFromJob(job.Id)
            .then(result => {
                if(result && result.IsValid) {
                    this.currentAnalysisResponse = result.Value;
                }
            });
    }

    get hasAnomalies() : boolean {
        return (this.currentAnalysisResponse?.Anomalies.length ?? 0) > 0;
    }

    get currentAnomalies() : AnalysisAnomaly[] {
        if(!this.hasAnomalies || !this.currentAnalysisResponse) {
            return [];
        }
        return this.currentAnalysisResponse.Anomalies;
    }

    get responseMessage(): string {
        if(!this.currentAnalysisResponse) {
            return "";
        }
        return this.markdownToHtml(this.currentAnalysisResponse.ResponseMessage);
    }

    onShowRowClicked(rowId: string) {
        EventBus.$emit(models.Event.GRID_SHOW_ROWS, [rowId]);
    }

    markdownToHtml(md: string) {
        let html: string = "";
        return <string>marked(md);
    }
}
