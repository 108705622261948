import { Group } from './Group';

export enum MemberOptions {
    None = 0,
    Administrator = 1,
    Disabled = 2,
    UniversalOwner = 16,
    ReadOnlyMember = 32,
    RowshareSupport = 64
}

export class Member{
    Name!: string;
    Email!: string;
    OrganizationId!: string;
    Groups!: Group[];
    Options!: MemberOptions;
    ManagerEmail!: string;
    ManagerName!: string;
    Manager!: Member;
    IsUser!: boolean;
    RecaptchaToken!: string;
    RecaptchaTokenIsV3!: boolean;
    LastSeenDate!: Date;

    public get IsDisabled(){
        return (this.Options & MemberOptions.Disabled) == MemberOptions.Disabled;
    }

    public get IsEnabled(){
        return !this.IsDisabled;
    }

    public set IsEnabled(value: boolean){
        if(value === false){
            this.Options |= MemberOptions.Disabled;
        }
        else{
            this.Options &= ~MemberOptions.Disabled;
        }
    }

    public get IsAdministrator(){
        return (this.Options & MemberOptions.Administrator) == MemberOptions.Administrator;
    }
    public set IsAdministrator(value: boolean){
        if (value === true){
            this.Options |= MemberOptions.Administrator;
        }
        else{
            this.Options &= ~MemberOptions.Administrator;
        }

    }

    public get nameOrEmail() {
        return this.Name || this.Email;
    }

    public get IsRowShareSupport(){
        return (this.Options & MemberOptions.RowshareSupport) == MemberOptions.RowshareSupport;
    }
    public set IsRowShareSupport(value: boolean){
        if (value === true){
            this.Options |= MemberOptions.RowshareSupport;
        }
        else{
            this.Options &= ~MemberOptions.RowshareSupport;
        }

    }

    public get IsUniversalOwner(){
        return (this.Options & MemberOptions.UniversalOwner) == MemberOptions.UniversalOwner;
    }
    public set IsUniversalOwner(value: boolean){
        if (value === true){
            this.Options |= MemberOptions.UniversalOwner;
        }
        else{
            this.Options &= ~MemberOptions.UniversalOwner;
        }

    }

    public get IsReadOnly(){
        return (this.Options & MemberOptions.ReadOnlyMember) == MemberOptions.ReadOnlyMember;
    }
    public set IsReadOnly(value: boolean){
        if (value === true){
            this.Options |= MemberOptions.ReadOnlyMember;
        }
        else{
            this.Options &= ~MemberOptions.ReadOnlyMember;
        }

    }
    
    public get hasRole(){
        return this.IsAdministrator || this.IsUniversalOwner || this.IsReadOnly;
    }
}
