import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as Models from "@/models/RowShare"

export class Currency {

    static loadByCulture(culture: string, options: IRequestOptions | null = null) : Promise<Models.Currency | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/currency/loadbyculture/" + ApiUtils.encodeSegment(culture), options)
            .then(response => response.object(Models.Currency));
    }
}