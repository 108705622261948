import * as RowShare from '@/models/RowShare';
import ColumnVM from '@/viewModels/Table/columnVM';
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import i18n from '@/modules/Localization';

export default class EmailColumnProxy extends BaseColumnProxy {
    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        return val;
    }

    getFormattedHtmlTooltip(rsColumn: RowShare.Column, rowVM: RowVM, val: any){
        if(val)
            return `<span>${val}<br/>${i18n.t("EmailRenderer_Tooltip").toString()}</span>`
        else
            return null;
    }

    getCellIconClasses(rsColumn: RowShare.Column, rowVM: RowVM, val: any) {
        return ['fal', 'fa-envelope'];
    }
}