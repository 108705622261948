import RowVM from '@/viewModels/Table/rowVM';
import { Column, List, Row, SecurityPrincipal } from './RowShare';
import ListVM from '@/viewModels/Table/listVM';

export enum Event {
    TITLE_CHANGED = 'TitleChanged',
    ACTION_COMMAND = 'ActionCommand',
    TOGGLE_RECENT = 'ToggleRecent',
    TOGGLE_FAVOURITE = 'ToggleFavourite',
    SEARCH_DISPLAY = 'SearchFieldDisplay',
    SHOW_DESCRIPTION_DIALOG = 'ShowDescriptionDialog',
    SEARCH_TRIGGERED = 'SearchTriggered',
    SEARCH_RESET = 'SearchReset',
    
    LOCALE_CHANGED = 'LocaleChanged',
    VALIDATE_FORM = 'ValidateForm',
    ORGANIZATION_CHANGED = 'OrganizationChanged',
    GROUP_CHANGED = 'GroupChanged',

    LISTINFO_CHANGED = "ListInfoChanged",
    LISTNAME_CHANGED = "ListNameChanged",
    LISTACCESSES_CHANGED = "ListAccessesChanged",
    LIST_COPIEDORMOVED = "ListCopiedOrMoved",
    LISTSETTINGS_CHANGED = "ListSettingsChanged",
    COLUMNSETTINGS_CHANGED = "ColumnSettingsChanged",

    ROW_ERROR = "RowError",
    // used event for local modification with server side error (e.g. when some cells are required)
    ROW_MODIFIED = "RowModified",
    // used after a succesfull API save
    ROW_SAVED = "RowSaved",
    // ROW_UPDATED is currently used by the mobile app for create, save and delete
    ROW_UPDATED = 'RowUpdated',
    ROW_DELETE = 'RowDelete',
    ROW_DELETE_DISTANT = 'RowDeleteDistant',
    ROW_RESTORE = 'RowRestore',
    ROW_ARCHIVE = 'RowArchive',
    ROW_UNARCHIVE = 'RowUnarchive',
    ROW_ADDED = 'RowAdded',
    ROW_ADDED_DISTANT = 'RowAddedDistant',
    ROW_EDIT_COMMENTS = 'RowEditComments',
    ROW_SELECTION_CHANGED = 'RowSelectionChanged',
    ROW_EDIT_RELATED_ROWS = 'RowEditRelatedRows',
    ROW_LOCKED = "RowLocked",
    ROW_UNLOCKED = "RowUnlocked",
    ROW_CELL_UPDATED = "Row_CELL_UPDATED",

    GRID_ERROR = "GRID_ERROR",
    GRID_SEARCH_CHANGED = 'GRID_SEARCH_CHANGED',
    GRID_INFOS_CHANGED = "GRID_INFOS_CHANGED",
    GRID_CLEAR_HIDDENCOLUMNS = "GRID_CLEAR_HIDDENCOLUMNS",
    GRID_CLEAR_FILTERS = "GRID_CLEAR_FILTERS",
    GRID_CLEAR_ROWGROUPS = "GRID_CLEAR_ROWGROUPS",
    GRID_ADD_ROWGROUP = "GRID_ADD_ROWGROUP",
    GRID_REMOVE_ROWGROUP = "GRID_REMOVE_ROWGROUP",
    GRID_CHOOSE_CELL_COLOR = "GRID_CHOOSE_CELL_COLOR",
    GRID_SELECTION_CHANGED = "GRID_SELECTION_CHANGED",
    GRID_SHOW_ROWS = "GRID_SHOW_ROWS",

    CONFIRMATION_REQUIRED = 'CONFIRMATION_REQUIRED',
    GLOBAL_NOTIFICATION_RAISED = 'GLOBAL_NOTIFICATION_RAISED',
    CLEAR_ALL_GLOBAL_NOTIFICATIONS = 'CLEAR_ALL_GLOBAL_NOTIFICATIONS',

    ROWMERGE_REQUEST = "ROWMERGE_REQUEST",
    ASSIGNROWS_REQUEST = "ASSIGNROWS_REQUEST",
    LISTANALYSIS_REQUEST = "LISTANALYSIS_REQUEST",
    LISTANALYSIS_SHOWRESULTS = "LISTANALYSIS_SHOWRESULTS",
    LISTANALYSIS_REFRESH = "LISTANALYSIS_REFRESH",

    RESTORE_FILTERS = "RESTORE_FILTERS",

    ADD_NEW_CHART = "ADD_NEW_CHART",
    REFRESH_CHARTS = "REFRESH_CHARTS",
    REFRESH_CHARTS_DATA = "REFRESH_CHARTS_DATA",
    EDIT_CHART = "EDIT_CHART",
    MAXIMIZE_CHART = "MAXIMIZE_CHART",

    RECAPTCHA_SUCCEEDED = "RECAPTCHA_SUCCEEDED",
    RECAPTCHA_FAILED = "RECAPTCHA_FAILED",
    RECAPTCHA_SHOW = "RECAPTCHA_SHOW",
    RECAPTCHA_SHOWV2 = "RECAPTCHA_SHOWV2",
    RECAPTCHA_CANCELEDV2 = "RECAPTCHA_CANCELV2",

    ADD_COLUMN = "ADD_COLUMN",
    COLUMN_PERMISSIONS_CHANGED = "COLUMN_PERMISSIONS_CHANGED",
    COLUMN_INFOS_CHANGED = "COLUMN_INFOS_CHANGED",
    SHOW_UPSELL_DIALOG = "SHOW_UPSELL_DIALOG",
    SHOW_SIDEPANEL_FORMVIEW = "SHOW_SIDEPANEL_FORMVIEW",
    SHOW_SIDEPANEL_SHARING = "SHOW_SIDEPANEL_SHARING",
    SHOW_SIDEPANEL_COLUMN_PERMISSIONS = "SHOW_SIDEPANEL_COLUMN_PERMISSIONS",
    SHOW_SIDEPANEL_TABLE_SETTINGS = "SHOW_SIDEPANEL_TABLE_SETTINGS",
    SHOW_SIDEPANEL_COLUMN_SETTINGS = "SHOW_SIDEPANEL_COLUMN_SETTINGS",

    UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST",

    // RTC EVENT
    COLUMN_UPDATED_RTC = "COLUMN_UPDATED_RTC",
    LIST_UPDATED_RTC = "LIST_UPDATED_RTC",
    ROW_CELL_LOCK_RTC = "ROW_CELL_LOCK_RTC"

}

export class AddColumnEventParams {
    insertBeforeColumnId: string | null = null;
}

export class ShowSidePanelFormViewEventParams {
    rowId: string | null = null;
    ListVM: ListVM | null = null;
}

export class ShowUpsellDialogEventParams {
    title: string | null = null;
    description: string | null = null;
}

export class ConfirmationRequiredEventParams{
    title: string | null = null;
    description1: string | null = null;
    description2: string | null = null;
    cancelButtonText: string | null = null;
    actionButtonText: string | null = null;
    actionButtonIcon: string | null = null;
    actionButtonColor: string | null = null;
    persistent: boolean | null = false;

    onConfirmation!: () => Promise<void>;
}

export class RowErrorEventParams {
    rowVM!: RowVM;
    title!: string;
    message!: string;
}

export class GridErrorEventParams {
    title!: string;
    message!: string;
}

export class GlobalNotificationEventParams {
    title!: string;
    message: string | null = null;
    autoHide = false;
    autoHideAfterMs = 2000;
    type : NotificationType = NotificationType.info;    
    // if provided, any previous notification with the same id will be removed to avoid duplicate
    duplicateId : string | null = null;
    showActionButton: boolean = false;
    actionButtonText: string | null = null;
    actionButtonClick!: () => void;
}

export enum NotificationType { error = 'error', warning = 'warning', success = 'success', info = 'info' }

export class GridSearchChangeEventParams {
    searchText!: string | null;
    saveValue: boolean = true;
    source: "userInput" | "external" = "userInput";
}

export class rowEditCommentsEventParams {
    row!: Row;
    column!: Column;
    list!: List;

    refreshParentRow!: () => void;
}

export class maximizeChartEventParams {
    chartId!: string;
    rowIds!: string[] | null;
}

export class rowEditRelatedRowsEventParams{
    rowVM!: RowVM;
    relatedListId!: string;
    column!: Column;
    refreshParentRow!: () => void;
}

export class inviteRequestEventParams {
    listId!: string;
    sendInvitationEmails!: boolean;
    selectedGuestsType!: string;
    selectedGuests!: (SecurityPrincipal | string)[];
}

export class showSidePanelSharingEventParams {
    listId!: string;
    addGuest!: boolean;
    inviteParams!: inviteRequestEventParams;
    advancedMode!: boolean;
}

export class listAnalysisRequestEventParams {
    listId!: string;
}

export class showSidePanelColumnPermissionsEventParams {
    columnId!: string;
}

export class updateSubscriptionRequestEventParams {
    organizationId!: string;
}

export class showSidePanelTableSettingsEventParams {
    listId!: string;
}

export class showSidePanelColumnSettingsEventParams {
    columnId!: string;
    listId!: string;
}