
import {Vue, Component, Watch} from 'vue-property-decorator';
import * as Models from '@/models/RowShare';
import { EventBus } from '@/modules/EventBus';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import * as API from '@/api/Api';
import { FormValidation } from "@/views/components/FormValidation";
import InputText from '@/views/components/FormInputs/InputText.vue';
import InputTextArea from '@/views/components/FormInputs/InputTextArea.vue';
import { UserModule } from '@/store/UserStore';
import { OnPremModule } from '@/store/OnPremStore';

@Component({name: 'SidePanelTableSettings', components: { ValidationObserver, ValidationProvider, InputText, InputTextArea}})
export default class SidePanelTableSettings extends FormValidation {
    showMe: boolean = false;
    width: string = "0px";
    listId: string = '';
    currentList: Models.List = new Models.List();
    oldList: Models.List = new Models.List();
    currentOrganization: Models.Organization | null = null;
    settingsHeight: number = 0;
    columns: Models.Column [] = [];
    listOfCultures: Models.Culture[] = [];
    categories: Models.CatalogCategory[] = [];
    iconImage: File | null = null;
    removeImageConfirmation: boolean = false;
    defaultProps = { maxHeight: 200 };
    settingsSaved: boolean = false;

    $refs!: {
        observer: InstanceType<typeof ValidationObserver>,
        sidepanelHeader: InstanceType<typeof HTMLElement>,
        inputFile: InstanceType<typeof HTMLInputElement>,
        openPickerBtn: any
    };

    created() {
        EventBus.$on(Models.Event.SHOW_SIDEPANEL_TABLE_SETTINGS, (params: Models.showSidePanelTableSettingsEventParams) => this.onShowSidePanel(params));
        window.addEventListener('resize', this.refreshSettingsHeight);
    }

    beforeDestroy() {
        EventBus.$off([Models.Event.SHOW_SIDEPANEL_TABLE_SETTINGS]);
        window.removeEventListener('resize', this.refreshSettingsHeight);
    }

    async onShowSidePanel(event: Models.showSidePanelTableSettingsEventParams) {
        this.listId = event.listId;
        this.width = "40%";
        this.loadList();
        this.refreshSettingsHeight();
        setTimeout(() => {
            this.showMe = true;
        }, 0);

        if((<any>window).Intercom) {
            (<any>window).Intercom('update', { 'hide_default_launcher': true});
        }
    }

    loadList() {
        API.List.load(this.listId,{cache: false}).then(listResult => {
            if(listResult){
                this.currentList = listResult;
                this.oldList = Object.assign({}, listResult);
                if(this.currentList){
                    API.Column.loadForList(this.listId).then(listColumns=>{
                        if(listColumns){
                            // Option by default is added and after that all the table columns
                            let firstColumn = new Models.Column();
                            firstColumn.Id = "00000000000000000000000000000000";
                            firstColumn.DisplayName = this.$t("TableSettings_MainColumn_Option").toString();
                            this.columns = listColumns.filter(c => !c.HasHiddenPermissions);
                            this.columns.unshift(firstColumn);
                        }
                    });
                    API.Culture.loadCultures().then((listOfCultures:any)=>{
                        this.listOfCultures = listOfCultures;
                    });
                    API.Category.loadAll()
                            .then((categories) => {
                                if(categories) {
                                    this.categories = categories;
                                }
                            });
                    
                }
            } 
        });
    }

    onClickOutside() {
        if(this.showMe) {
            this.onCancel();
        }
    }

    shouldClosePanel(event: any): boolean {
        if(!event?.target?.classList) {
            return false;
        }
        return event.target.classList.contains('v-overlay__scrim');
    }

    onCancel() {
        this.closePanel();
    }

    closePanel() {
        this.listId = "";
        this.showMe = false;
        this.width = "0px";
        this.currentList = new Models.List();
        this.currentOrganization = null;
        if(this.settingsSaved) {
            EventBus.$emit(Models.Event.LISTSETTINGS_CHANGED, this.currentList);
            EventBus.$emit(Models.Event.LISTINFO_CHANGED);
            this.settingsSaved = false;
        }
        if((<any>window).Intercom) {
            (<any>window).Intercom('update', { 'hide_default_launcher': false});
        }
    }

    @Watch('currentList')
    refreshSettingsHeight() {
        if(!this.$refs.sidepanelHeader) {
            return;
        }
        let maxHeight = window.innerHeight - this.$refs.sidepanelHeader.getBoundingClientRect().height;
        this.settingsHeight = maxHeight;
    }

    async updateTableSettings(){
        if(JSON.stringify(this.currentList) != JSON.stringify(this.oldList)){
            let isValid = await this.$refs.observer?.validate();
            if(isValid){
                try{
                    let result = await API.List.save(this.currentList);
                    if(result){
                        this.oldList = Object.assign({}, result);
                        this.currentList = result;
                        EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{ message: this.$i18n.t('TableSettings_SuccessMessage'), 
                                                                                                            type: Models.NotificationType.success, 
                                                                                                            duplicateId: 'ts_'+ this.listId,
                                                                                                            autoHide: true, autoHideAfterMs: 4000});
                        this.settingsSaved = true;
                    }
                }
                catch(Error) {                        
                    this.currentList = Object.assign({}, this.oldList); 
                    EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{ message: this.$i18n.t('TableSettings_ErrorMessage'), 
                                                                                                        type: Models.NotificationType.error, 
                                                                                                        duplicateId: 'ts_'+ this.listId,
                                                                                                        autoHide: true, autoHideAfterMs: 10000});
                }
            }
        }
    }

    getIcon(){
        if (this.currentList && this.currentList.IconPath) {
            return '/' + this.currentList.IconPath + "?_ssb_img=S;w:48;h:48";
        }
    }

    async onFileChanged(){
        if(this.iconImage && this.iconImage.size>0) {
            if(!this.iconImage?.type.startsWith('image/')) {
                this.currentList = Object.assign({}, this.oldList);
                this.iconImage = null;
                EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{ message: this.$i18n.t('Common_InvalidFileType'), 
                                                                                                    type: Models.NotificationType.error, 
                                                                                                    duplicateId: 'ts_'+ this.listId,
                                                                                                    autoHide: true, autoHideAfterMs: 5000});
            }
            else {
                try{
                    let result = await API.List.saveWithFile(this.currentList, this.iconImage, "/icon");
                    if(result){
                        this.oldList = Object.assign({}, result);
                        this.currentList = result;
                        this.iconImage = null;
                        this.settingsSaved = true;
                        EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{ message: this.$i18n.t('TableSettings_SuccessMessage'), 
                                                                                                                        type: Models.NotificationType.success, 
                                                                                                                        duplicateId: 'ts_'+ this.listId,
                                                                                                                        autoHide: true, autoHideAfterMs: 4000});
                    }
                }
                catch(Error){
                    this.currentList = Object.assign({}, this.oldList);
                    this.iconImage = null;
                    EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{ message: this.$i18n.t('TableSettings_ErrorMessage'), 
                                                                                                                    type: Models.NotificationType.error, 
                                                                                                                    duplicateId: 'ts_'+ this.listId,
                                                                                                                    autoHide: true, autoHideAfterMs: 10000});
                }
            }
        }
    }

    deleteImage(){
        if(this.currentList){
            this.currentList.IconPath = null;
            this.currentList.Icon_Remove = true;
        }
        this.updateTableSettings();
        this.removeImageConfirmation = false;
    }

    onDeleteIconClick(){
        this.removeImageConfirmation = true;
    }

    cancelled() {
        this.removeImageConfirmation = false;
    }

    getLocaleText(key: string) {
        if(this.existsLocalization(key)) {
            return this.$i18n.t(key).toString();
        }
        return '';
    }

    existsLocalization(key: string) {
        return this.$i18n.te(key);
    }

    selectFile() {
        this.focusButton();
        let imputElt = this.$refs.inputFile as HTMLInputElement;
        if(imputElt) {
            imputElt.click();
        }
    }

    onFileSelected(event: any) {
        this.iconImage = event.target.files[0];
        this.onFileChanged();
    }
    
    focusButton(){
        let element = (<any>this.$refs.openPickerBtn).$el as HTMLButtonElement;
        if(element){
            this.$nextTick(() => {
                element.focus(); 
            });
        }
    }

    get currentUser() {
        return UserModule.CurrentUser || new Models.User();
    }

    get isNotOnPrem(){
        return !OnPremModule.isOnPrem;
    }

    get canEditCatalogCategory(): boolean {
        return this.isNotOnPrem && this.currentUser.IsAdministrator;
    }

}
