import { ApiUtils, IRequestOptions } from "./ApiUtils";
import * as RowShare from '@/models/RowShare';

export class ColumnAccess {
    static async loadForColumn(columnId: string, options: IRequestOptions | null = null)
    : Promise<RowShare.ApiResult<RowShare.ColumnAccess[]> | null> {
        options = ApiUtils.createRequestOptions({ cache: true}, options);
        return ApiUtils.apiGet("/columnaccess/loadforcolumn/" + ApiUtils.encodeSegment(columnId), options)
            .then(response => response.json<RowShare.ApiResult<RowShare.ColumnAccess[]>>());
    }

    static async saveMultiple(accessesToSave: RowShare.ColumnAccessesToSave, options: IRequestOptions | null = null)
    : Promise<RowShare.ApiResult<boolean> | null> {
        return ApiUtils.apiPostJson("/columnaccess/savemultiple/", accessesToSave, ApiUtils.createRequestOptions({ clearCache: true }, options))
            .then(response => response.json<RowShare.ApiResult<boolean>>());
    }

}