import { Utils } from "@/utils/Utilities";
import { Column } from "./Column";

export class PostedFile {
    public column!: Column;
    public data!: File | Blob | null | any;
    public fileName!: string;

    constructor(column: Column, data: File | Blob | null | any) {
        this.column = column;
        this.data = data;
    }

    public appendToFormData(formData: FormData) {
        if (Utils.isNullOrUndefined(this.data)) {
            return;
        }

        if (Array.isArray(this.data) || this.data instanceof FileList) {
            for (let i = 0; i < this.data.length; i++) {
                const f = this.data[i];
                formData.append(this.column.Name, f);
            }
        } else {
            formData.append(this.column.Name, this.data, this.getFileName());
        }
    }

    public get hasData() {
        return !!this.data;
    }

    public getFileName() {
        if (this.fileName) {
            return this.fileName;
        }

        if (this.data instanceof File) {
            return this.data.name;
        }

        return "";
    }

}