
import moment from 'moment';
import { DateTime } from '@/utils/Date';
import { ICellEditorParams } from 'ag-grid-community';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ColumnVM from '@/viewModels/Table/columnVM';
import { ColumnStrongType } from '@/models/Column';

@Component({name: "DateTimeEditor"})
export default class DateTimeEditor extends Vue{
    params!: ICellEditorParams;
    selectedDateTime: Date  | null = null;
    formattedDateTime: string = "";
    timeOut!: number;

    isInitialized = false;

    mounted() {
        this.pickerValue = this.currentDate;
        
        this.$nextTick(() => {
            this.isInitialized = true;
            (<HTMLElement>this.$refs.datetimeInput)?.focus();
        })
    }

    get isDateTime():boolean {
        let rsColumn = (<ColumnVM>this.params.colDef)?.rsColumn;
        return rsColumn?.isDateTimeFormat;
    }

    get isReminder():boolean {
        let rsColumn = (<ColumnVM>this.params.colDef)?.rsColumn;
        return rsColumn.Type == ColumnStrongType.Reminder;
    }

    get selectionMode() {
        return this.isDateTime ? 'dateTime' : 'date';
    }

    get currentDate(): Date | null {
        return DateTime.parseDateTime(this.params.value, null);
    }

    get locale() {
       return window.navigator.language;
    }

    get isRequired() {
        if(this.isDateTime) {
            return true;
        }
        return false;
    }

    getValue() {
        this.updateDatePicker();
        return this.selectedDateTime;
    }

    get pickerValue(): Date | null {
        return this.selectedDateTime;
    }

    set pickerValue(value: Date | null) {

        if(value) {
            this.selectedDateTime = value;
        }
        let options: any = {dateStyle: "short", timeStyle: "short"};
        if(this.selectedDateTime) {
            this.formattedDateTime = this.isDateTime ? this.selectedDateTime.toLocaleString(navigator.language, options) : this.selectedDateTime.toLocaleDateString(navigator.language);
        }
        else
        {
            this.formattedDateTime = "";
        }        
        let picker = (<any>this.$refs.datePicker);
        if(picker && this.pickerValue){
            picker.move(this.pickerValue);
        }
    }

    pickerChanged() {
        if(this.isInitialized && !this.isDateTime){
            this.params.api?.stopEditing();
        }
    }

    isPopup() {
        return true;
    }

    updateDatePicker() {
        moment.locale(this.locale);
        let date = moment(this.formattedDateTime, ["L LT", "L LTS"]);
        if(date.isValid()){
            this.pickerValue = date.toDate();
            (<any>this.$refs.datePicker).move(this.pickerValue);
        }
        else
        {
            this.selectedDateTime = null;
            this.pickerValue = null;
        }
    }

    get nowLabel() {
        if(this.isDateTime) {
            return this.$i18n.t('Action_Now').toString();
        }
        else {
            return this.$i18n.t('Action_Today').toString();
        }
    }

    closeEditor(cancel: boolean) {
        this.params.api?.stopEditing(cancel);
    }

    chooseNow() {
        this.pickerValue = moment().toDate();
        this.pickerChanged();
    }

    get is24hrTimeFormat(): boolean {
        moment.locale(this.locale);
        // Try to parse a time in 24hr format using browser locale.
        // if date is invalid, browser is in 12hr time format
        let date = moment("14:00", "LT", true);
        return date.isValid();
    }
}
