
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import * as Models from '@/models/RowShare';
    import { UserNotificationUpdate } from "@/models/UserNotificationLink";
    import { UserListLinkTypes } from "@/models/UserListLink";
    import * as API from '@/api/Api';

    @Component({
        name: "MyAccountNotificationList"
    })
    export default class MyAccountNotificationList extends Vue {
        private allNotifications: UserListLinkTypes;
        private ownedNotifications: UserListLinkTypes;
        private noNotifications: UserListLinkTypes;

        @Prop() tableNotif!: Models.UserNotificationLink;

        constructor() {
            super();
            this.allNotifications = UserListLinkTypes.AllRowsEmailNotifications;
            this.ownedNotifications = UserListLinkTypes.OwnedRowsEmailNotifications;
            this.noNotifications = UserListLinkTypes.Unspecified;
        }

        async setListNotification(
            listLink: Models.UserNotificationLink, 
            newType: UserListLinkTypes) : Promise<void> {
                let newNotificationConfig = new UserNotificationUpdate();
                newNotificationConfig.ListId = listLink.ListId;
                newNotificationConfig.Types = newType;
                
                let updatedNotif: Models.UserNotificationLink | null = 
                        new Models.UserNotificationLink();
                    updatedNotif = await this.setListNotifOnServer(newNotificationConfig);
                this.tableNotif.Types = updatedNotif ? updatedNotif.Types : listLink.Types;
        }

        async setListNotifOnServer(notification: UserNotificationUpdate) 
        : Promise<Models.UserNotificationLink> {
            let userNewNotificationList: Models.UserNotificationLink | null = 
                    new Models.UserNotificationLink();
            await API.UserNotificationLink
                .updateUserTableNotification(notification)
                .then(userListLink => {
                    userNewNotificationList = userListLink;
            });
            return userNewNotificationList;
        }

        get selectedNotification() {
            switch(this.tableNotif.Types) {
                case this.allNotifications:
                    return 0;
                case this.ownedNotifications:
                    return 1;
                case this.noNotifications:
                default:
                    return 2;
            }
        }
    }
