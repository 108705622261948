import { AccessOptions } from "./Folder";
import { Utils } from '@/utils/Utilities';
import { OwnerType } from "./User";
import i18n from '@/modules/Localization';

export class SecurityPrincipal {
    UserEmail!: string;
    GroupId!: string;
    GroupName!: string;
    GroupMemberCount!: number;
    MemberEmail!: string;
    MemberOrganizationId!: string;
    MemberName!: string;
    Options!: AccessOptions;
    BackgroundColorIndex!: number | null;
    Bigram!: string | null;
    IsMyOrganization!: boolean;
    IconPath!: string;

    public get type() : SecurityPrincipalType {
        if (!Utils.isNullOrUndefined(this.UserEmail)) {
            return SecurityPrincipalType.User;
        }

        if (!Utils.isNullOrUndefined(this.GroupId) && !Utils.isNullOrUndefined(this.GroupName)) {
            return SecurityPrincipalType.Group;
        }

        if (!Utils.isNullOrUndefined(this.MemberEmail) && !Utils.isNullOrUndefined(this.MemberOrganizationId)) {
            if (this.Options & AccessOptions.ManagedOfMember) {
                if (this.Options & AccessOptions.Recursive) {
                    return SecurityPrincipalType.AllReports;
                } else {
                    return SecurityPrincipalType.DirectReports;
                }
            }
            return SecurityPrincipalType.Member;
        }

        return SecurityPrincipalType.Unknown;
    }

    public get icon() {
        switch(this.type) {
            case SecurityPrincipalType.AllReports:
            case SecurityPrincipalType.DirectReports:
                return "fa-sitemap";
            case SecurityPrincipalType.Group:
                return this.IsMyOrganization ? "" : "fa-users";
            default:
                return null;
        }
    }

    public get ownerType() {
        switch (this.type) {
            case SecurityPrincipalType.Unknown:
                return OwnerType.UserUnknown;
            case SecurityPrincipalType.User:
                return OwnerType.User;
            case SecurityPrincipalType.Member:
                return OwnerType.Member;
            case SecurityPrincipalType.Group:
                if (this.IsMyOrganization)
                    return OwnerType.MyOrganization;
                else
                    return OwnerType.Group;
            case SecurityPrincipalType.DirectReports:
            case SecurityPrincipalType.AllReports:
                return OwnerType.Team;
        }
    }

    public get displayName() {
        const memberDisplayName = Utils.isNullOrWhiteSpace(this.MemberName) ? this.MemberEmail : this.MemberName;
        switch (this.type) {
            case SecurityPrincipalType.User:
                return this.UserEmail!;
            case SecurityPrincipalType.Member:
                return i18n.t('ListAccess_MemberNameFormat', [memberDisplayName]).toString();
            case SecurityPrincipalType.Group:
                {
                    if(this.ownerType == OwnerType.MyOrganization) {
                        return i18n.t('ListAccess_MyOrganization').toString();
                    }
                    return i18n.t('ListAccess_GroupNameFormat', [this.GroupName]).toString();
                }
            case SecurityPrincipalType.AllReports:
                return i18n.t('ListAccess_AllReportsTeamNameFormat', [memberDisplayName]).toString();
            case SecurityPrincipalType.DirectReports:
                return i18n.t('ListAccess_DirectReportsTeamNameFormat', [memberDisplayName]).toString();
            default:
                return "";
        }
    }

    public get id() {
        switch (this.type) {
            case SecurityPrincipalType.User:
                return "U:" + this.UserEmail;
            case SecurityPrincipalType.Member:
                return "M:" + this.MemberEmail + ":" + this.MemberOrganizationId;
            case SecurityPrincipalType.Group:
                return "G:" + this.GroupId;
            case SecurityPrincipalType.AllReports:
            case SecurityPrincipalType.DirectReports:
                return "T:" + this.MemberEmail + ":" + this.MemberOrganizationId + ":" + this.Options;
            default:
                return "";
        }
    }

    public get group() {
        switch (this.type) {
            case SecurityPrincipalType.Member:
                return i18n.t("ListAccess_MemberCategory").toString();

            case SecurityPrincipalType.Group:
                return i18n.t("ListAccess_GroupCategory").toString();

            case SecurityPrincipalType.AllReports:
            case SecurityPrincipalType.DirectReports:
                return i18n.t("ListAccess_TeamCategory").toString();

            default:
                return "";
        }
    }
}

export const enum SecurityPrincipalType {
    Unknown,
    User,
    Member,
    Group,
    DirectReports,
    AllReports,
}