import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import RowVM from "@/viewModels/Table/rowVM";
import ColumnVM from "@/viewModels/Table/columnVM";
import { upperFirst } from "lodash";

/**
 * Generic renderer that uses the methods provided by the ColumnProxy from ColumnVM
 */
export default class ProxyBasedRenderer implements ICellRendererComp {
    cellContainer!: HTMLElement;
    cellValue!: HTMLElement;
    errorIcon!: HTMLElement;
    savingIcon!: HTMLElement;
    cellIcon!: HTMLElement;

    init(params: ICellRendererParams){
        const rowVM = <RowVM>params.data;
        const colVM = <ColumnVM>params.colDef;

        if(!colVM?.columnProxy) {
            return;
        }

        this.cellContainer = document.createElement("div");
        this.cellContainer.classList.add("cell-container");
        let wrapperClass = colVM.columnProxy.getRendererWrapperClass(colVM.rsColumn, rowVM, params.value);
        if(wrapperClass)
            this.cellContainer.classList.add(wrapperClass);

        this.errorIcon = document.createElement("i");
        this.errorIcon.classList.add("v-icon", "notranslate","mr-1", "cell-container-status-icon", "cell-container-error-icon", "far", "fa-exclamation-circle",
                                     "theme--light","error--text");
        
        this.errorIcon.title = rowVM?.getCellError((<ColumnVM>params.colDef).rsColumn) || "";
        this.cellContainer.appendChild(this.errorIcon);
        this.savingIcon = document.createElement("i");
        this.savingIcon.classList.add("mr-1","cell-container-status-icon", "cell-container-saving-icon", "far", "fa-spinner-third", "fa-spin", "primary--text");
        this.cellContainer.appendChild(this.savingIcon);

        let val = this.getVal(params.value, colVM, rowVM);

        // optional cell icon
        var cellIconClasses = colVM.columnProxy.getCellIconClasses(colVM.rsColumn, rowVM, params.value ?? "");
        if(cellIconClasses){
            this.cellIcon = document.createElement("i");
            this.cellIcon.classList.add("v-icon", "notranslate", "theme--light", "grid-cell-icon", "mr-2");
            for(let c of cellIconClasses)
                this.cellIcon.classList.add(c);
            
            if(!val) {
                this.cellIcon.style.display = 'none';
            }

            this.cellContainer.appendChild(this.cellIcon);
        }

        this.cellValue = document.createElement("span");
        this.cellValue.classList.add("content");
        this.cellValue.innerHTML = val;
        this.cellContainer.appendChild(this.cellValue);
    }

    getGui(): HTMLElement {
        return this.cellContainer;
    }
    
    private getVal(paramsVal : any, colVM: ColumnVM, rowVM: RowVM){
        if(paramsVal === RowVM.cellNotAccessible) {
            return paramsVal;
        }
        let formulaError = colVM.columnProxy.getFormulaError(colVM.rsColumn, rowVM);
        if(formulaError) {
            return formulaError;  
        } else {            
            return colVM.columnProxy.getFormattedHtml(colVM.rsColumn, rowVM, paramsVal ?? "") ?? "";
        }
    }

    refresh(params: ICellRendererParams): boolean {
        const rowVM = <RowVM>params.data;
        const colVM = <ColumnVM>params.colDef;

        if(!colVM?.columnProxy) {
            return true;
        }

        let val = this.getVal(params.value, colVM, rowVM);
        this.cellValue.innerHTML = val;
        this.errorIcon.title = rowVM?.getCellError((<ColumnVM>params.colDef).rsColumn) || "";
        if(this.cellIcon) {
            this.cellIcon.style.display = (params.value && !this.errorIcon.title) ? 'inherit' : 'none';
        }
        return true;
    }
}