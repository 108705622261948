import * as API from '@/api/Api';
import * as RowShare from "@/models/RowShare";
import * as agGrid from 'ag-grid-community';
import { ListConfiguration, ListSettings } from '@/models/RowShare';
import ColumnVM from './columnVM';
import i18n from '@/modules/Localization';
import ListDisplayModeVM from '../../viewModels/Table/listDisplayModeVM';
import ListVMListener from './listVMListener';
import ListVM from './listVM';
import RowVM from './rowVM';

export default class ViewManager implements ListVMListener {
    public isLoadingAllViews = false;
    public allViewsLoaded = false;

    displayModeVM: ListDisplayModeVM;
    currentView: RowShare.View | null = null;
    sharedViews = new Array<RowShare.View>()
    personalViews = new Array<RowShare.View>()

    constructor(displayModeVM: ListDisplayModeVM){
        this.displayModeVM = displayModeVM;
        
        this.displayModeVM.listVM.registerListener(this);
    }
    
    onListUpdated(listVM : ListVM) : void {
        if(listVM.list)
            this.loadAllView()
    }

    onColumnsUpdated(listVM : ListVM) : void {}
    onRowVMsUpdated(listVM : ListVM) : void {}
    onBeforeRowVMsUdated(listVM: ListVM): void {}
    onRowVMUpdated(rowVM : RowVM) : void {}
    refreshCell(rowVM : RowVM) : void {}

    public async loadAllView(forceReload: boolean = false, idToSelect: string | null = null) {
        if(this.isLoadingAllViews || (this.allViewsLoaded && !forceReload) || !this.displayModeVM.listVM.list)
            return;

        this.isLoadingAllViews = true;
        this.sharedViews = new Array<RowShare.View>()
        this.personalViews = new Array<RowShare.View>()

        let views = await API.View.loadForParent(this.displayModeVM.listVM.list.Id);
        if(!views)
            return;

        const lastUsedId = this.getLastUsedViewId();
        for (let i = 0; i < views.length; ++i) {
            const view = views[i];
            if (view.Shared)
                this.sharedViews.push(view);
            else
                this.personalViews.push(view);

            if((idToSelect && idToSelect == view.Id) || (!idToSelect && lastUsedId == view.Id))
                this.currentView = view;
        }

        this.allViewsLoaded = true;
        this.isLoadingAllViews = false;
    }

    public isNotDefault = false;

    public onViewDataChanged(){
        //this.displayModeVM.execOnceColAndGridApiAreReady((gridApi, columnApi) => {
            let newState = false;

            // is current grid filtered ?
            let filters = this.displayModeVM.getCurrentFilters();// gridApi.getFilterModel();
            if(Object.keys(filters).length > 0)
                newState = true;

            // is current grid sorted or does it have hidden columns ?
            var currentSort = this.displayModeVM.getCurrentSort();
            if(currentSort && currentSort.length > 0)
                newState = true;
            
            var hiddenCols = this.displayModeVM.getHiddenColumnsIds();
            if(hiddenCols && hiddenCols.length > 0)
                newState = true;
            
            // are rows grouped ?
            var rowGroupCols = this.displayModeVM.getRowGroupColumnsIds();
            if(rowGroupCols && rowGroupCols.length > 0) {
                newState = true;
            }

            var valueCols = this.displayModeVM.getValueColumns();
            if(valueCols && valueCols.length > 0) {
                newState = true;
            }

            var collapsedColumnGroups = this.displayModeVM.getCollapsedColumnGroups();
            if(collapsedColumnGroups && collapsedColumnGroups.length > 0) {
                newState = true;
            }
            
            this.isNotDefault = newState; 
        //});
    }

    public onFilterChanged(event: agGrid.FilterChangedEvent){
        this.onViewDataChanged();
    }

    public onSortChange(event: agGrid.SortChangedEvent){
        this.onViewDataChanged();
    }

    public onColumnVisibleChanged(event: agGrid.ColumnVisibleEvent){
        this.onViewDataChanged();
    }

    public onColumnRowGroupChanged(event: agGrid.ColumnRowGroupChangedEvent) {
        this.onViewDataChanged();
    }

    public onColumnGroupOpenedChanged(event: agGrid.ColumnGroupOpenedEvent) {
        this.onViewDataChanged();
    }

    public applyDefaultView(){
        this.displayModeVM.clearFilters();
        this.displayModeVM.clearHiddenColumns();
        this.displayModeVM.clearSortOrder();
        this.displayModeVM.clearRowGroups();
        this.displayModeVM.clearValueColumns();
        this.displayModeVM.clearCollapsedColumnGroups();
        
        this.currentView = null;
        this.saveLastUsedViewToLocalStorage(null);
    }

    public applyView(view: RowShare.View){
        // parse view data
        const viewConfig = <ListConfiguration | null> JSON.parse(view.Data);
        if(!viewConfig) {
            this.applyDefaultView();
            return;
        }

        this.displayModeVM.applyFilters(viewConfig);
        this.displayModeVM.applySort(viewConfig);
        this.displayModeVM.applyHiddenColumns(viewConfig);
        this.displayModeVM.applyValueColumns(viewConfig);
        this.displayModeVM.applyRowGroupColumns(viewConfig);
        this.displayModeVM.applyCollapsedColumnGroups(viewConfig);

        this.currentView = view;
        this.saveLastUsedViewToLocalStorage(view.Id);
    }

    public async saveView(view: RowShare.View) : Promise<string | null> {
        try {
            const newView = await API.View.save(view);
            this.loadAllView(true, newView.Id);
            this.saveLastUsedViewToLocalStorage(newView.Id);
            return null;
        } catch(err: any) {
            return i18n.t("ExceptionCode_" + err.code).toString();
        }
    }

    public getCurrentViewConfig() : RowShare.ListConfiguration | undefined {
        return this.displayModeVM.getCurrentViewConfig();
    }

    async deleteView(view: RowShare.View){
        if(this.currentView == view)
            this.currentView = null;

        await API.View.remove(view.Id);
        
        this.loadAllView(true);
    }

    getViewSummary(view: RowShare.View){
        const viewConfig = <ListConfiguration | null> JSON.parse(view.Data);
        if(!viewConfig)
            return null;

        return this.getViewConfigSummary(viewConfig);
    }

    getViewConfigSummary(viewConfig: ListConfiguration) : string | null{
        let res = "";
        if(!viewConfig) {
            return null;
        }

        // sorts
        if(viewConfig.sortOrder) {
            const allSortStr = new Array<string>();
            for(let i = 0; i < viewConfig.sortOrder.length; ++i){
                const sort = viewConfig.sortOrder[i];
                var sortedColName = '';
                if(sort.colId == ColumnVM.rowOwnerHeaderId) {
                    sortedColName = i18n.t("ViewSummary_OwnerColumnDisplayName").toString();
                } else {
                    const rsCol = this.displayModeVM.listVM.columns?.find(c => c.Id == sort.colId);
                    sortedColName = rsCol ? rsCol.DisplayName : '';
                }

                let sortPattern = "ViewSummary_SortDesc";
                if(sort.sort == "asc")
                    sortPattern = "ViewSummary_SortAsc";
                
                if(sortedColName != '')
                    allSortStr.push("<li>" + i18n.t(sortPattern, [sortedColName]) + "</li>");
            }
            res += allSortStr.join("\n")
        }

        // hidden cols
        if(viewConfig.hiddenColumns) {
            const hiddenColumnsNames = viewConfig.hiddenColumns.map(hc => {
                if(hc == ColumnVM.rowOwnerHeaderId) {
                    return i18n.t("ViewSummary_OwnerColumnDisplayName").toString();
                } else {
                    const rsCol = this.displayModeVM.listVM.columns?.find(c => c.Id == hc);
                    return rsCol ? rsCol.DisplayName : null;
                }
            }).filter(hc => hc != null);

            const hiddenColCount = hiddenColumnsNames.length;

            const hiddenColPattern = hiddenColCount > 1 ? "ViewSummary_HiddenColumns" : "ViewSummary_HiddenColumn";
            if(hiddenColCount > 0)
                res +=  "<li>" + i18n.t(hiddenColPattern, [hiddenColCount, hiddenColumnsNames.join(", ")]) + "</li>";
        }

        // filters

        if(viewConfig.filters) {
            for (let [key, value] of Object.entries(viewConfig.filters)) {
                
                var filteredColName = '';
                if(key == ColumnVM.rowOwnerHeaderId) {
                    filteredColName = i18n.t("ViewSummary_OwnerColumnDisplayName").toString();
                } else {
                    const rsCol = this.displayModeVM.listVM.columns?.find(c => c.Id == key);
                    filteredColName = rsCol ? rsCol.DisplayName : '';
                }

                if(filteredColName != '')
                    res += "<li>" + i18n.t("ViewSummary_FilteredColumn", [filteredColName]) + "</li>";
            }
        }

        // row group columns
        if(viewConfig?.sideBar?.rowGroupColumns) {
            const rowGroupColumnsNames = viewConfig.sideBar.rowGroupColumns.map(rgc => {
                const rsCol = this.displayModeVM.listVM.columns?.find(c => c.Id == rgc);
                return rsCol ? rsCol.DisplayName : null;
            }).filter(rgc => rgc != null);

            const rowGroupColCount = rowGroupColumnsNames.length;

            if(rowGroupColCount > 0)
                res +=  "<li>" + i18n.t("ViewSummary_RowGroupColumns", [rowGroupColumnsNames.join(", ")]) + "</li>";
        }

        // Collapsed groups
        if((viewConfig.collapsedColumnGroups?.length ?? 0) > 0) {
            const collapsedGroupsPattern = viewConfig.collapsedColumnGroups.length > 1 ? "ViewSummary_CollapsedGroups" : "ViewSummary_CollapsedGroup";
            res +=  "<li>" + i18n.t(collapsedGroupsPattern, [viewConfig.collapsedColumnGroups.length, viewConfig.collapsedColumnGroups.join(", ")]) + "</li>";
        }
        
        return res;
    }

    public saveLastUsedViewToLocalStorage(viewId: string | null) {
        if(!this.displayModeVM || !this.displayModeVM.listVM.list)
            return;

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(this.displayModeVM.listVM.list.Id);
        if(!currentSettings.configuration)
            currentSettings.configuration = new ListConfiguration();
        
        currentSettings.configuration.viewId = viewId;

        ListSettings.saveListSettingsToLocalStorage(this.displayModeVM.listVM.list.Id, currentSettings);
    }

    public getLastUsedViewId() : string | null {
        if(!this.displayModeVM || !this.displayModeVM.listVM.list)
            return null;

        let currentSettings = ListSettings.loadListSettingsFromLocalStorage(this.displayModeVM.listVM.list.Id);
        if(!currentSettings.configuration)
            currentSettings.configuration = new ListConfiguration();
        
        return currentSettings.configuration.viewId;
    }
}