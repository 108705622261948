import { Dictionary } from 'vue-router/types/router';

export class Group {
    public Id!: string;
    public Name!: string;
    public Description!: string;
    public MemberCount!: number;
    public OrganizationId!: string;
    public MetaData!: string;
    public MetaDataDictionary!: Dictionary<string>;
    public Options!: GroupOptions;
    public Children!: Array<Group>;
    public IsMyOrganization!: boolean;
}

export enum GroupOptions {
    None = 0,
    MyOrganization = 1,
    Undeletable = 2,
    Disabled = 4
}