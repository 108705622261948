
import { Component, Vue } from 'vue-property-decorator';
import * as agGrid from 'ag-grid-community';
import * as models from '@/models/RowShare';
import ColumnVM from '@/viewModels/Table/columnVM';
import GridViewVM from '@/viewModels/Table/gridViewVM';
import * as API from "@/api/Api";
import ColumnDescription from "@/views/Table/ColumnDescription.vue"
import { KeyCodes } from "@/utils/KeyCodes";    
import { ColumnDescriptionVM } from '@/viewModels/Table/ColumnDescriptionVM';
import { IColumnDescription } from '@/viewModels/Table/IColumnDescription';
import groupRowColumnDescriptionVM from '@/viewModels/Table/groupRowColumnDescriptionVM';

@Component({name: "columnHeader", components: { ColumnDescription }})
export default class columnHeader extends Vue {
    params!: agGrid.IHeaderParams;

    showColumnDescription: boolean = false;
    descriptionDisabled: boolean = false;
    columnFiltered: boolean = false;
    columnMenuOpened: boolean = false;
    filterMenuOpened: boolean = false;
    hiddenColumnsBeside: agGrid.Column[] = [];
    // isInRenameMode : boolean = false;
    // isRenaming : boolean = false;

    columnDescription: IColumnDescription | null = null;

    created() {
        if(this.currentColumnSettings?.colId != ColumnVM.autoGroupColumnId) {
            this.columnDescription = new ColumnDescriptionVM(this.currentColumn);
        }
        else {
            this.columnDescription = new groupRowColumnDescriptionVM(this.params.column.getColDef(), this.params.columnApi)
        }

        this.columnDescription.hiddenColumnsBesideCount = this.hiddenColumnsBesideCount;

        this.params.column.addEventListener('sortChanged', this.sortChanged);
        this.params.column.addEventListener('menuVisibleChanged', this.menuVisibleChanged);
        this.params.column.addEventListener('filterActiveChanged', this.filerActiveChanged);
        this.refreshDescriptionDisabled();
        this.sortChanged();
        this.filerActiveChanged();
    }

    destroy() {
        this.params.column.removeEventListener('sortChanged', this.sortChanged);
        this.params.column.removeEventListener('menuVisibleChanged', this.menuVisibleChanged);
        this.params.column.removeEventListener('filterActiveChanged', this.filerActiveChanged);
    }

    get isCompact(): boolean {
        return this.currentColumn?.isCompact ?? false;
    }

    get isSortable(): boolean {
        return this.params.enableSorting ?? false;
    }

    get isMenuEnabled(): boolean {
        return this.params.enableMenu;
    }

    get currentColumn():  models.Column | null {
        let col = this.currentColumnSettings;
        if(col) {
            return col.rsColumn;
        }
        return null;
    }

    get currentList(): models.List | null {
        let gridViewVM = <GridViewVM>this.params.context;
        if(!gridViewVM) {
            return null;
        }
        return gridViewVM.listVM.list;
    }

    get currentColumnSettings(): ColumnVM | null {
        return (<ColumnVM>this.params.api.getColumnDef(this.params.column));
    }
        
    get hiddenColumnsBesideCount(): number {
        let colState = this.params.columnApi.getColumnState();
        let hiddenColCount = 0;
        let index = colState.findIndex(c => c.colId == this.currentColumn?.Id) + 1;
        let hiddenCol: boolean = true;

        do {
            hiddenCol = colState[index]?.hide ?? false;
            if(hiddenCol) {
                let col = this.params.columnApi.getColumn(colState[index].colId);
                if(col) {
                    hiddenColCount++;
                }
            }
            index++;
        } while(index < colState.length && hiddenCol);

        return hiddenColCount;
    }

    toggleSort(event: MouseEvent) {
        this.params.progressSort(event.ctrlKey);   
    }

    sortChanged() {
        if(!this.columnDescription) {
            return;
        }
        let sort = this.params.column.getSort();
        this.columnDescription.columnSortedAsc = sort === 'asc';
        this.columnDescription.columnSortedDesc = sort === 'desc';
        this.columnDescription.columnSorted = this.columnDescription.columnSortedAsc || this.columnDescription.columnSortedDesc;        
    }


    filerActiveChanged() {
        this.columnFiltered = this.params.column.isFilterActive();
    }

    filterColumn() {
        this.filterMenuOpened = true;
        let parentElementPrefix = 'filterMenu-';
        this.openColumnMenu(['filterMenuTab'], parentElementPrefix);
    }

    menuVisibleChanged() {
        if(!this.params.column.isMenuVisible()) {
            this.columnMenuOpened = false;
            this.filterMenuOpened = false;
        }
        this.refreshDescriptionDisabled();
    }

    refreshDescriptionDisabled() {
        this.descriptionDisabled = this.params.column.isMenuVisible() || !this.params.displayName;
    }

    columnMenu() {
        if(!this.isMenuEnabled) {
            return;
        }
        this.columnMenuOpened = true;
        let parentElementPrefix = 'columnMenu-';
        this.openColumnMenu(['generalMenuTab'], parentElementPrefix);
    }

    openColumnMenu(tabs: agGrid.ColumnMenuTab[], parentElementPrefix: string) {
        this.showColumnDescription = false;
        let coldef = this.params.column.getColDef();
        coldef.menuTabs = tabs;
        
        let parentElement = document.getElementById(parentElementPrefix + this.params.column.getId());
        if(parentElement)
            this.params.showColumnMenu(parentElement);
    }

    getFilterIcon(hover: boolean) {
        if(!hover && !this.columnFiltered && !this.filterMenuOpened) {
            return "fal fa-fw";
        }
        if(this.columnFiltered) {
            return "fa-filter";
        }
        else {
          return "fal fa-filter";
        }
    }

    showHiddenColumns() {
        let gridContext = <GridViewVM>this.params.context;
        if(gridContext) {
            gridContext.showHiddenColumnsBeside(this.params.column);
        }
    }

    // startRenamingColumn() {
    //     if(!this.currentList?.Owned) {
    //         return;
    //     }
    //     if(!this.columnDescription?.isRowGroupColumn) {
    //         this.isInRenameMode = true;
    //         this.$nextTick(() => {(<HTMLElement>this.$refs.columnNameInput)?.focus();});            
    //     }
    // }

    // renameColumn() {
    //     if(!this.currentColumn || this.currentColumn.DisplayName == this.currentColumn.Name) {
    //         this.isInRenameMode = false;
    //         return;
    //     }

    //     this.isRenaming = true;
    //     API.Column.save(this.currentColumn).then(savedColumn => {
    //         if(savedColumn){
    //             let col = (<ColumnVM>this.params.api.getColumnDef(this.params.column));
    //             let oldName = col.rsColumn.DisplayName;
    //             let newName = savedColumn.DisplayName;
    //             col.rsColumn = savedColumn;
    //             if(this.params?.api) {
    //                 this.params.api.refreshHeader();
    //             }
    //             let gridView = <GridViewVM>this.params.context;                
    //             if(gridView) {
    //                 if (savedColumn.NeedsListReload)
    //                     gridView.listVM.refreshRowsFromServer();
    //                 if(gridView.listVM.columns) {
    //                     var idx = gridView.listVM.columns.findIndex(c => c.Id == savedColumn.Id);
    //                     gridView.listVM.columns[idx] = savedColumn;
    //                 }
    //             }
    //         }
    //         this.isInRenameMode = false;
    //     })
    //     .catch((exc: Error) =>{
    //         //this.nameUpdateErrors = this.$i18n.t('ListToolBar_ErrorSavingName', [exc.message]).toString();
    //     })
    //     .finally(() => {
    //         this.isRenaming = false;
    //     });
    // }
    
    //  onColumnNameKeyDown(event: KeyboardEvent) {
    //     if(event.code === "Enter" || event.code === "NumpadEnter") {
    //         this.renameColumn();
    //     }
    //     else if(event.keyCode === KeyCodes.DOM_VK_ESCAPE) {
    //         //this.loadListInfo();
    //         this.isInRenameMode = false;
    //         if(this.currentColumn)
    //             this.currentColumn.Name = this.currentColumn?.DisplayName;
    //     }
    //     event.stopPropagation();
    // }
}
