
import { Component, Vue } from 'vue-property-decorator';
import * as agGrid from 'ag-grid-community';
import * as models from '@/models/RowShare';
import GridViewVM from '@/viewModels/Table/gridViewVM';
import * as API from "@/api/Api";
import { EventBus } from '@/modules/EventBus';
import ListDisplayModeVM from '@/viewModels/Table/listDisplayModeVM';
import _ from 'lodash';
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

@Component({name: "columnGroupHeader"})
export default class columnGroupHeader extends Vue {
    params!: agGrid.IHeaderGroupParams;

    relationColumn: models.Column | null = null;
    menuOpened: boolean = false;

    async created() {
        if(this.relationColumnId) {
            this.relationColumn = await API.Column.load(this.relationColumnId);
        }
    }

    get relationColumnId(): string {        
        return (<any>this.params)?.relationColumnId ?? "";
    }

    get isMenuEnabled(): boolean {
        let gridContext = <GridViewVM>this.params.context;
        return (gridContext.listVM.list?.Owned ?? false) && (this.relationColumnId !== "");
    }

    get currentRoute() {
        return this.$router.currentRoute.fullPath;
    }

    get listId(): string {
        return this.relationColumn?.ListId ?? "";
    }

    removeRelationColumn() {
        var evtArgs = new models.ConfirmationRequiredEventParams();        
        evtArgs.title = this.$i18n.t("ColumnGroupMenu_DeleteRelationColumn_Title", [this.relationColumn?.DisplayName, this.params.columnGroup.getChildren()?.length ?? ""]).toString();
        evtArgs.description1 = this.$i18n.t("ColumnGroupMenu_DeleteRelationColumn_Description", [this.relationColumn?.DisplayName]).toString();
        evtArgs.cancelButtonText = this.$i18n.t("ColumnGroupMenu_DeleteRelationColumn_CancelButton").toString();
        evtArgs.actionButtonText = this.$i18n.t("ColumnGroupMenu_DeleteRelationColumn_ConfirmButton").toString();
        evtArgs.actionButtonIcon = "trash-alt";
        evtArgs.actionButtonColor = "error";

        evtArgs.onConfirmation = async () => {
            if(!this.relationColumn)
                return;

            this.relationColumn.RtcConnectionId = RealTimeCollaborationModule.connection.connectionId;
            API.Column.remove(this.relationColumn).then((apiRes: boolean | null) => {
                let gridViewVM = <GridViewVM>this.params.context;
                if(apiRes && this.params?.api && gridViewVM.listVM.columns) {

                    _.remove(gridViewVM.listVM.columns, c => c.RelationColumnId === this.relationColumnId);
                    let colDefs = this.params.api.getColumnDefs();
                    if(!colDefs)
                        return;

                    let deletedIndex = colDefs.findIndex(def => (<agGrid.ColGroupDef>def).headerGroupComponentParams?.relationColumnId === this.relationColumnId);
                    if(deletedIndex > -1) {                        
                        colDefs.splice(deletedIndex, 1);
                    }

                    this.params.api.setColumnDefs(colDefs);
                    this.params.api.redrawRows();
                    EventBus.$emit(models.Event.GRID_INFOS_CHANGED, <ListDisplayModeVM>this.params.context);
                    
                } else {
                    EventBus.$emit(models.Event.GRID_ERROR, { message: this.$i18n.t("ColumnGroupMenu_DeleteRelationColumn_ErrorTemplate", [this.relationColumn?.DisplayName]).toString() });
                }
            }).catch((err: any) =>  {
                console.log(err);
                EventBus.$emit(models.Event.GRID_ERROR, { message: err._message });
            });
        }

        EventBus.$emit(models.Event.CONFIRMATION_REQUIRED, evtArgs);

    }

    showColumnSettings() {
        EventBus.$emit(models.Event.SHOW_SIDEPANEL_COLUMN_SETTINGS, 
            <models.showSidePanelColumnSettingsEventParams>{columnId: this.relationColumnId, listId: this.listId});
    }
    toggleExpanded() {
        this.params.setExpanded(!this.params.columnGroup.isExpanded());
    }
}
