
    import {Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import * as RowShare from '@/models/RowShare';
    import RsColumnManager from '@/columnProxies/RsColumnManager';
    import RowVM from '@/viewModels/Table/rowVM';
    import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';

    @Component({ name: 'ProxyBasedCardRenderer' })
    export default class ProxyBasedCardRenderer extends Vue {
        @Prop() column!: RowShare.Column;
        @Prop() rowVM!: RowVM;

        get proxy(){
            return RsColumnManager.getProxy(this.column.Type);
        }

        get val(){
            return this.proxy ? this.rowVM.getValue(this.column, this.proxy) : undefined;
        }

        get innerHtml(){
            const text = this.proxy.getFormattedHtml(this.column, this.rowVM, this.val); 
            if(text && text != '')
                return text;
            else
                return '-';
        }
        
        get wrapperClass(){
            return this.proxy.getRendererWrapperClass(this.column, this.rowVM, this.val);
        }
        
        get cellIconClasses(){
            return this.proxy.getCellIconClasses(this.column, this.rowVM, this.val);
        }
    }
