
import { RowNode } from 'ag-grid-community';
import {Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Row } from '@/api/Api';
import { Member, List, Event, GlobalNotificationEventParams, NotificationType } from '@/models/RowShare';
import { EventBus } from '@/modules/EventBus';
import ListDisplayModeVM from '@/viewModels/Table/listDisplayModeVM';
import RowVM from '@/viewModels/Table/rowVM';

@Component({name: 'RemindUsersDialog'})
export default class RemindUsersDialog extends Vue {
    @Prop() displayModeVM!: ListDisplayModeVM 
    @Prop() currentList!: List
    @Prop() rows!: RowVM[];
    @Prop() showDialog!: boolean;

    dialogOpened: boolean = false;
    message: string = "";
    sendingReminders: boolean = false;
    membersToNotify: Member[] | null = null;
    notNotifiedMembers: Member[] | null = null;    
    loadingRecipients: boolean = false;

    get sendingRemindersTo(): string {
        if(!this.membersToNotify || this.membersToNotify.length == 0) {
            return "";
        }
        let membersList = this.formatMembersList(this.membersToNotify, 3);
        return this.$i18n.t('RemindUsersDialog_SendingTo', {membersList: membersList}).toString();
        
    }

    get didntSentTo(): string {
        if(!this.notNotifiedMembers || this.notNotifiedMembers.length == 0) {
            return "";
        }
        let memberList = this.formatMembersList(this.notNotifiedMembers);
        return this.$i18n.t('RemindUsersDialog_NotSentTo', {membersList: memberList}).toString();
    }

    get selectedRowsIds(): string[] | null {
        if(this.rows && this.rows.length > 0) {
            return this.rows.map(rvm => <string>rvm.rsRow.Id);
        }
        return null;
    }

    get canSendReminders() : boolean {
        if(!this.membersToNotify || this.membersToNotify.length == 0) {
            return false;
        }
        return true;
    }

    get noOneToSend() : boolean {
        return (this.membersToNotify?.length ?? 0) == 0 && !this.loadingRecipients;
    }


    @Watch('showDialog')
    initDialog() {
        this.dialogOpened = this.showDialog;
        this.message = "";
        this.sendingReminders = false;
        this.loadingRecipients = true;
        this.membersToNotify = null;
        this.notNotifiedMembers = null;
        if(this.showDialog && this.selectedRowsIds && this.selectedRowsIds.length > 0) {
            this.loadingRecipients = true;
            Row.getRowOwners(this.selectedRowsIds)
               .then(members => {
                    if(members){
                        this.membersToNotify = members.filter(m => m.IsUser);
                        this.notNotifiedMembers = members.filter(m => !m.IsUser);
                    }
                    this.loadingRecipients = false;
               });
        }
    }
    
    onCancel() {
        this.dialogOpened = false;
        this.$emit('cancel');
    }

    sendReminders() {
        this.sendingReminders = true;
        if(this.selectedRowsIds && this.selectedRowsIds.length > 0) {
            Row.SendOwnerReminders(this.selectedRowsIds, this.message)
                .then(() => {
                    this.displayModeVM.deselectAllRows();
                    EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: this.$i18n.t('RemindUsersDialog_RemindersSent').toString(),
                                                                                                      autoHide: true,
                                                                                                      autoHideAfterMs: 5000,
                                                                                                      type: NotificationType.success});
                    this.onCancel();
                    this.sendingReminders = false;
                });
        }
    }

    formatMembersList(list: Array<Member> | null, maxMembers: number = 0) {
        let membersList: string = "";
        if(list && (list.length ?? 0) > 0) {
            let sep: string = "";
            if(maxMembers === 0) {
                maxMembers = list.length;
            }
            if(maxMembers > list.length) {
                maxMembers = list.length;
            }
            for(let i = 0; i < maxMembers; i++) {
                membersList += sep + list[i].Name;
                sep = ", ";  
            }
            if(maxMembers < list.length) {
                membersList += this.$i18n.t('RemindUsersDialog_MoreMembers', {memberCount: list.length - maxMembers}).toString();
            }
        }
        return membersList;
    }
}
