
    import { Component, Model, Prop, Vue } from 'vue-property-decorator';
    import * as API from '@/api/Api';
    import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
    import * as RowShare from "@/models/RowShare";
    import { EventBus } from "@/modules/EventBus";
    import { Event, GlobalNotificationEventParams } from "@/models/RowShare";

    @Component({ name: 'EditInvitationDialog', components: { PageLoadSpinner } })
    export default class EditInvitationDialog extends Vue{
        @Prop() organizationId!: string; 
     
        isEditingInvitationMessage: boolean = false;
        invitationMessage: string | null = null;
        savingInvitationMessage: boolean = false;
        isMessageUpToDate: boolean = false;

        mounted(){
            this.refresh();
        }

        refresh(){
            API.Organization.GetInvitationMessage(this.organizationId).then( msg => {
                this.invitationMessage = msg;
                this.isMessageUpToDate = true;
            });
        }

        get hasCustomMessage(): boolean {
            return !!this.invitationMessage;
        }

        openEditInvitationMessageDialog(){
            this.isEditingInvitationMessage = true;
        }

        canSaveInvitationMessage(){
            return true;
        }

        saveInvitationMessage(){
            this.savingInvitationMessage = true;
            API.Organization.SetInvitationMessage(this.organizationId, this.invitationMessage).then(res => {
                if(res == true)
                    this.showSuccessNotif();
                else
                    this.showErroNotif();
            }).catch(e => {
                this.showErroNotif();
            }).finally(() => {
                this.isEditingInvitationMessage = false;
                this.savingInvitationMessage = false;
                this.refresh();
            });
        }

        showErroNotif(){
            EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, {
                message: (this.$i18n.t('EditMemberInvitationDialog_SaveError').toString()),
                type: 'error',
                autoHide: true,
                autoHideAfterMs: 2000
            });
        }

        showSuccessNotif(){
            EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, {
                message: (this.$i18n.t('EditMemberInvitationDialog_SaveSuccess').toString()),
                type: 'success',
                autoHide: true,
                autoHideAfterMs: 2000
            });
        }
    }
