import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import * as RowShare from "@/models/RowShare";
import {computeConcernedEventsForUer, RealTimeCollaborationEventType} from "@/models/RowShare";
import store from '@/modules/Store';
import * as signalR from "@microsoft/signalr";
import {UserModule} from "@/store/UserStore";
import {ListTreeModule} from "@/store/ListTreeStore";
import {EventBus} from "@/modules/EventBus";

@Module({dynamic: true, store: store, name: 'RealTimeCollaborationStore'})
export class RealTimeCollaborationStore extends VuexModule {
    private _connection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_SIGNALR_URL}`) // for beta
        // .withUrl('https://www.rowsharedev.com/signalr/EventsHub') // for local
        .configureLogging(signalR.LogLevel.Warning)
        .build();
    public connectedUsers: RowShare.RealTimeCollaborationEntity[] = [];
    public inEditionCells: RowShare.EditingCell[] = [];
    public createdRows: string[] = [];
    public history: RowShare.RtcHistoryItem[] = [];

    /** Getters and Setters **/
    get currentList() : RowShare.List | null {
        return ListTreeModule.currentList;
    }

    get hasAudit(): boolean {
        return ListTreeModule.currentOrganization?.HasAudit ?? false;
    }

    get hasRtcHistory(): boolean {
        // return true;
        return ListTreeModule.currentOrganization?.HasRtcHistory ?? false;
    }

    get isAdmin(): boolean {
        return this.currentList?.Owned ?? false;
    }

    get currentUser() : RowShare.User | null {
        return UserModule.CurrentUser;
    }

    get connection(): signalR.HubConnection {
        return this._connection;
    }

    set connection(value: signalR.HubConnection) {
        this._connection = value;
    }

    get ConnectedUsers() : RowShare.RealTimeCollaborationEntity[] {
        return this.connectedUsers;
    }

    get getHistory(): RowShare.RtcHistoryItem[] {
        return this.history;
    }

    /** Mutations **/

    // @Mutation
    // addConnectedUser(incomingUsers: RowShare.User[]): void {
    //     this.connectedUsers = incomingUsers;
    // }
    //
    // @Mutation
    // removeConnectedUser(outgoingUsers: RowShare.User[]): void {
    //     this.connectedUsers = outgoingUsers;
    // }

    @Mutation
    updateHistory(h: RowShare.RtcHistoryItem): void {
        this.history.push(h);
    }

    @Mutation
    updateConnectedUsers(incomingUsers: RowShare.RealTimeCollaborationEntity[]): void {
        this.connectedUsers = incomingUsers;
    }

    @Mutation
    addEditingCell(entity: RowShare.RealTimeCollaborationEntity): void {
        const rowId = entity.data["rowId"];
        const columnId = entity.data["columnId"];
        const editingCell = new RowShare.EditingCell();
        editingCell.connectionId = entity.connectionId;
        editingCell.rowId = rowId;
        editingCell.columnId = columnId;
        editingCell.backgroundColorIndex = entity.data["backgroundColorIndex"];
        this.inEditionCells.push(editingCell);
        EventBus.$emit(entity.eventType, rowId, columnId);
    }

    @Mutation
    addNewRow(entity: RowShare.RealTimeCollaborationEntity): void {
        const rowId: string = entity.data["rowId"];
        this.createdRows = [rowId];
        EventBus.$emit(entity.eventType, entity);
    }

    @Mutation
    removeEditingCell(entity: RowShare.RealTimeCollaborationEntity): void {
        const rowId: string = entity.data["rowId"];
        const columnId: string = entity.data["columnId"];
        this.inEditionCells = this.inEditionCells.filter(x =>
            x.connectionId != entity.connectionId
            && x.rowId !== rowId
            && x.columnId !== columnId
        );
        EventBus.$emit(entity.eventType, rowId, columnId);
    }

    @Mutation
    updateEditingCellsForSubscribeEvent(eCells : RowShare.EditingCell[]) {
        this.inEditionCells = eCells;
        for (const ec of eCells) {
            EventBus.$emit(RowShare.RealTimeCollaborationEventType.RowCellLock, ec.rowId, ec.columnId);
        }
    }

    @Mutation
    updateEditingCellsForUnSubscribeEvent(eCells : RowShare.EditingCell[]) {
        for (const ec of eCells) {
            this.inEditionCells = this.inEditionCells.filter(x =>
                x.connectionId !== ec.connectionId
                && x.rowId !== ec.rowId
                && x.columnId !== ec.columnId
            )
            EventBus.$emit(RowShare.RealTimeCollaborationEventType.RowCellUnlock, ec.rowId, ec.columnId);
        }
    }

    /** Actions **/

    @Action({rawError: true})
    async processHistory(entity: RowShare.RealTimeCollaborationEntity) : Promise<any> {
        if(!RowShare.RtcHistoryItem.isSupportedEvent(entity)) {
            return
        }
        if (!this.hasAudit) {
            return
        }
        const h = new RowShare.RtcHistoryItem(entity)
        this.context.commit('updateHistory', h);
    }

    @Action({rawError: true})
    async processBroadcastEvent(message: string) : Promise<any> {
        const entity = JSON.parse(message) as RowShare.RealTimeCollaborationEntity;

        await this.processHistory(entity);

        if (this.connection.connectionId === entity.connectionId) {
            return;
        }

        switch (entity.eventType) {
            case RowShare.RealTimeCollaborationEventType.RowCellLock:
                this.context.commit('addEditingCell', entity);
                return;
            case RowShare.RealTimeCollaborationEventType.RowCellUnlock:
                this.context.commit('removeEditingCell', entity);
                return;
            case RowShare.RealTimeCollaborationEventType.RowCreate:
                this.context.commit('addNewRow', entity);
                return;
        }

        EventBus.$emit(entity.eventType, entity);
    }

    @Action({rawError: true})
    async processSubscribeEvent(message: string): Promise<any> {
        const coWorkers = JSON.parse(message) as RowShare.RealTimeCollaborationEntity[];
        const remainingUsers = coWorkers
            .filter(coWorker => coWorker.connectionId !== this.connection.connectionId);
        // .map(coWorker => Object.assign(new RowShare.User(), coWorker.user));

        this.context.commit('updateConnectedUsers', remainingUsers);
    }

    @Action({rawError: true})
    async processUnSubscribeEvent(message: string): Promise<any> {
        const coWorkers = JSON.parse(message) as RowShare.RealTimeCollaborationEntity[];
        const remainingUsers = coWorkers
            .filter(coWorker => coWorker.connectionId !== this.connection.connectionId);
        // .map(coWorker => Object.assign(new RowShare.User(), coWorker.user));

        this.context.commit('updateConnectedUsers', remainingUsers);
    }

    @Action({rawError: true})
    async processEditingCellsEvent(message: string) : Promise<any> {
        const payload = JSON.parse(message);
        const entity = payload.entity;
        switch (entity.eventType) {
            case RowShare.RealTimeCollaborationEventType.Subscribe:
                this.context.commit('updateEditingCellsForSubscribeEvent', payload.editingCells as RowShare.EditingCell[]);
                return;
            case RowShare.RealTimeCollaborationEventType.UnSubscribe:
                this.context.commit('updateEditingCellsForUnSubscribeEvent', payload.editingCells as RowShare.EditingCell[]);
                return;
        }
    }

    @Action({rawError: true})
    async startAndSubscribe(): Promise<any> {
        if (this.connection.state === "Connecting" || this.connection.state == "Connected") {
            //console.log("SignalR already connected")
            return;
        }

        try {
            this.connection.on("ReceiveBroadcastEvent", async(message: string) => {
                await this.processBroadcastEvent(message);
            });

            this.connection.on("ReceiveSubscribeEvent", async(message: string) => {
                await this.processSubscribeEvent(message);
            });

            this.connection.on("ReceiveUnSubscribeEvent", async (message: string) => {
                await this.processUnSubscribeEvent(message);
            });

            this.connection.on("ReceiveEditingCells", async (message: string) => {
                await this.processEditingCellsEvent(message);
            });

            // if after 30 min we receive nothing from the server, then the connection is broken (before my update, default value was 30 seconds)
            this.connection.serverTimeoutInMilliseconds = 1_800_000; // wait 30 minutes before considering connection is lost
            this.connection.keepAliveIntervalInMilliseconds = 60_0000; // ping every 1 min
            await this.connection.start();

            let nonConcernedEvents: RealTimeCollaborationEventType[] = [];
            if (!this.currentList?.Owned) {
                nonConcernedEvents.push(RealTimeCollaborationEventType.Subscribe);
                nonConcernedEvents.push(RealTimeCollaborationEventType.UnSubscribe);
            }
            const consideredEvents = computeConcernedEventsForUer(nonConcernedEvents);
            const entity: RowShare.RealTimeCollaborationEntity = {
                connectionId: this.connection.connectionId!,
                connectionDate: new Date().toISOString(),
                eventType: "Subscribe",
                methodToInvoke: "Subscribe", // not relevant in this case
                groupId: this.currentList!.Id,
                authorName: this.currentUser!.Owner!.DisplayName,
                authorEmail: this.currentUser!.Email,
                bigram: this.currentUser!.Owner!.Bigram!,
                backgroundColorIndex: this.currentUser!.Owner!.BackgroundColorIndex!,
                data: {
                    "consideredEvents": consideredEvents.join(","),
                },
            }
            await this.connection.invoke("Subscribe", JSON.stringify(entity));
            //console.log(`user ${entity.authorName} subscribed to list: ${entity.groupId}`);
        } catch (err) {
            console.error("error during start and subscribe: ", err);
        }
    }

    @Action({rawError: true})
    async stopAndUnsubscribe(): Promise<any> {
        try {
            if (!this.connection || this.connection.state !== "Connected") {
                return;
            }
            await this.connection.stop();
        } catch (err) {
            console.error("error during stop and unsubscribe: ", err);
        }
    }

    @Action({rawError: true})
    async broadcast(event: Record<string, any>): Promise<any> {
        //console.log(event)
        try {
            if (!this.connection || this.connection.state !== "Connected") {
                return;
            }
            const entity: RowShare.RealTimeCollaborationEntity = {
                connectionId: this.connection.connectionId!,
                connectionDate: new Date().toISOString(),
                eventType: event["type"] as string,
                methodToInvoke: "Broadcast",
                groupId: this.currentList!.Id,
                authorName: this.currentUser!.Owner!.DisplayName,
                authorEmail: this.currentUser!.Email,
                bigram: this.currentUser!.Owner!.Bigram!,
                backgroundColorIndex: this.currentUser!.Owner!.BackgroundColorIndex!,
                data: event["data"] as Record<string, string>,
            }
            await this.connection.invoke("Broadcast", JSON.stringify(entity));
        } catch (err) {
            console.error("error during broadcast event: ", err);
        }
    }
}

export const RealTimeCollaborationModule = getModule(RealTimeCollaborationStore);
