
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { FormEditor } from '@/views/Table/cellsComponents/FormEditor';
    import RsColumnManager from '@/columnProxies/RsColumnManager';

    @Component({
        name: 'EmailFormEditor',
        components:{
        }
    })
    export default class EmailFormEditor extends FormEditor {
        currentValue: string = '';

        init(){
            const proxy  = RsColumnManager.getProxy(this.column.Type);
            this.currentValue = this.rowVM.getValue(this.column, proxy);            
        }

        checkEmail(value: string) : boolean {
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (!reg.test(value)) 
                return false;
            return true;
        }

        getValue() {
            return this.currentValue;
        }
        
        get mailtoLink() {
            if(this.checkEmail(this.currentValue)) {
                return `mailto:${this.currentValue}`;
            }
            return "";
        }
    }
