
import { ListTreeModule } from '@/store/ListTreeStore';
import {Vue, Component, Watch, Prop} from 'vue-property-decorator';
import * as api from '@/api/Api';
import ListVM from '@/viewModels/Table/listVM';
import GridViewVM from '@/viewModels/Table/gridViewVM';
import * as models from '@/models/RowShare';
import { EventBus } from '@/modules/EventBus';
import * as RowShare from "@/models/RowShare";
@Component({name: 'ImportDialog'})
export default class ImportDialog extends Vue {
    @Prop() listVM!: ListVM;
    @Prop() gridViewVM!: GridViewVM;
    showDialog: boolean = false;
    processingFile: boolean = false;
    errorMessage: string = "";

    get currentList() {
        return ListTreeModule.currentList;
    }

    async exportToExcel() {
        let context = this.initExportContext()
        context.format = RowShare.RowShareServiceFormat.Xls;
        context.formatOptions = new RowShare.XlsFormatOptions();
        context.formatOptions.IncludeRowDetails = true;
        context.formatOptions.OpenXml = true;
        let file = await api.List.Export(this.listVM?.list?.Id ?? '', context);
        if(file) {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = file.name;
            link.click();
        }
    }

    initExportContext(): RowShare.ExportContext {
        let context = new RowShare.ExportContext();
        if(this.gridViewVM?.gridApi) {
            let tmpRowIds: string[] = [];
            if(this.gridViewVM.isFiltered) {
                this.gridViewVM.gridApi.forEachNodeAfterFilterAndSort(n => {
                    if(!n.group && n.data?.rsRow) {
                        tmpRowIds.push(n.data.rsRow.Id);
                    }
                });
                context.rowIds = tmpRowIds;
            }
        } else {
            context.rowIds = null;
        }
        return context;
    }

    @Watch('showDialog')
    initDialog() {
        this.errorMessage = "";
    }

    openDialog() {
        setTimeout(() => this.showDialog = true);
    }

    onCancel() {
        this.showDialog = false;
    }

    selectFile() {
        let input = (<HTMLInputElement>this.$refs.fileImportInput);
        if(input) {
            this.errorMessage = "";
            (<any>this.$refs.fileImportInput).value = null;
            input.click();
        }
    }

    fileSelected() {
        let files = (<HTMLInputElement>this.$refs.fileImportInput).files;
        if(files && files.length == 1 && this.currentList?.Id) {
            this.processingFile = true;
            this.errorMessage = "";
            api.Folder.importRows(this.currentList.Id, files[0])
                .then((res: models.ImportResult | null) => {
                    if(res) {
                        if(this.listVM) {
                            let gridChanged = false;
                            if(res.AddedRows.length > 0) {
                                this.gridViewVM.addRowsInUI(res.AddedRows);
                                gridChanged = true;
                            }
                            if(res.UpdatedRows.length > 0) {
                                this.gridViewVM.updateRowsInUI(res.UpdatedRows);
                                gridChanged = true;
                            }
                            if(gridChanged) {
                                this.gridViewVM.tryRefreshTotalRow();
                                EventBus.$emit(models.Event.GRID_INFOS_CHANGED, this.gridViewVM);
                            }
                            this.showDialog = false;
                        }
                        else {
                            location.reload();
                        }
                    }
                })
                .catch((error: any) => {
                    console.log('error');
                    if(error) {
                        this.errorMessage = error.message;
                    }
                })
                .finally(() => {
                    this.processingFile = false;
                });
        }
    }
}
