
import { Vue, Component, ModelSync } from 'vue-property-decorator';

@Component({name: 'ColorPicker'})
export default class ColorPicker extends Vue {
    
    @ModelSync('value', 'change') selectedColorName!: string;

    swatches: any = [];

    constructor() {
        super();
        this.swatches = [
        [this.$vuetify.theme.currentTheme.color0, this.$vuetify.theme.currentTheme.color1, this.$vuetify.theme.currentTheme.color2, this.$vuetify.theme.currentTheme.color3, 
         this.$vuetify.theme.currentTheme.color4, this.$vuetify.theme.currentTheme.color5, this.$vuetify.theme.currentTheme.color6, this.$vuetify.theme.currentTheme.color7],
        [this.$vuetify.theme.currentTheme.color8, this.$vuetify.theme.currentTheme.color9, this.$vuetify.theme.currentTheme.color10, this.$vuetify.theme.currentTheme.color11,
         this.$vuetify.theme.currentTheme.color12, this.$vuetify.theme.currentTheme.color13, this.$vuetify.theme.currentTheme.color14, this.$vuetify.theme.currentTheme.color15],
        [this.$vuetify.theme.currentTheme.color16, this.$vuetify.theme.currentTheme.color17, this.$vuetify.theme.currentTheme.color18, this.$vuetify.theme.currentTheme.color19,
         this.$vuetify.theme.currentTheme.color20, this.$vuetify.theme.currentTheme.color21, this.$vuetify.theme.currentTheme.color22, this.$vuetify.theme.currentTheme.color23],
        [this.$vuetify.theme.currentTheme.color24, this.$vuetify.theme.currentTheme.color25, this.$vuetify.theme.currentTheme.color26, this.$vuetify.theme.currentTheme.color27, 
         this.$vuetify.theme.currentTheme.color28, this.$vuetify.theme.currentTheme.color29, this.$vuetify.theme.currentTheme.color30, this.$vuetify.theme.currentTheme.color31]
        ];
    }

    get selectedValue(): string {
        if(!this.selectedColorName) {
            return '#FFFFFF';
        }
        var color = this.$vuetify.theme.currentTheme[this.selectedColorName];
        if (color) {
            return <string>color;
        } else {
            return "#FFFFFF";
        }
    }

    set selectedValue(value: string) {
        let colorClass = '';
        if(!value) {
            this.selectedColorName = '';
            return;
        }
        Object.keys(this.$vuetify.theme.currentTheme).forEach((key) => {
            if (this.$vuetify.theme.currentTheme[key] == value.toUpperCase()) {
                if(colorClass != key) {
                    colorClass = key;
                }
            }
        });
        this.selectedColorName = colorClass;
    }

    clearColor() {
        this.selectedValue = "";
   }

    colorUpdate(event: any) {
        if(this.selectedValue === event.hex) {
            return;
        }
        this.selectedValue = <string>event.hex;
        this.$emit('change');
    }
}
