export class ListAccessAudit {
    Id!: string;
    Name!: string;
    Admins!: ListAccessAuditMember[];
    UniversalOwners!: ListAccessAuditMember[];
    RootFolder!: ListAccessAuditFolder;
}

export class ListAccessAuditMember {
    Name!: string;
    Email!: string;
    IsReadonly!: boolean;
}

export class ListAccessAuditAccess {
    Member!: ListAccessAuditMember;
    IsOwner!: boolean;
    CanCreate!: boolean;    
    CanRead!: ListAccessAuditAccesLevel;
    CanUpdate!: ListAccessAuditAccesLevel;
    CanSuppress!: ListAccessAuditAccesLevel;
    CanAssign!: ListAccessAuditAccesLevel;
}

export enum ListAccessAuditAccesLevel {
    All = "All",
    Owned = "Owned",
    None = "None" 
}

export class ListAccessAuditList
{
    Id!: string;
    Name!: string;
    Url!: string;
    OneRowMaximumPerUser!: boolean;
    ExternalAccess!: boolean;
    Accesses!: ListAccessAuditAccess[];
}

export class ListAccessAuditFolder
{
    Id!: string;
    Name!: string;
    Folders!: ListAccessAuditFolder[];
    Tables!: ListAccessAuditList[];
}