import { i18n } from "@/modules/Localization";
import { EventBus } from "@/modules/EventBus";
import { RowErrorEventParams } from '@/models/RowShare';
import RowVM from "./rowVM";
import * as API from "@/api/Api";
import * as RowShare from "@/models/RowShare";
import ListDisplayModeVM from "./listDisplayModeVM";
import { ListTreeModule } from "@/store/ListTreeStore";
import {RealTimeCollaborationModule} from "@/store/RealTimeCollaborationStore";

export default class GridActions {
    static removeRow (rowVM: RowVM, displayModeVM: ListDisplayModeVM, hardDelete: boolean, removeFromUICallback: () => void) {

        if(rowVM?.rsRow?.IsParentRow) {
            EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <RowShare.GlobalNotificationEventParams>{
                message: i18n.t('ContextMenu_DeleteRow_CannotDeleteReferenced').toString(),
                autoHide: true,
                autoHideAfterMs: 10000,
                type: 'error',
                duplicateId: 'CannotDeleteRow'
            });
        }
        else {
            var evtArgs = new RowShare.ConfirmationRequiredEventParams();
            evtArgs.title = i18n.t(`ContextMenu_${hardDelete ? 'Hard' : ''}DeleteRow_Title`, [rowVM.rsRow.DescriptorFormattedValue]).toString();
            evtArgs.description1 = i18n.t(`ContextMenu_${hardDelete ? 'Hard' : ''}DeleteRow_Description`, [rowVM.rsRow.DescriptorFormattedValue]).toString();
            evtArgs.cancelButtonText = i18n.t("ContextMenu_DeleteRow_CancelButton").toString();
            evtArgs.actionButtonText = i18n.t(`ContextMenu_${hardDelete ? 'Hard' : ''}DeleteRow_ConfirmButton`).toString();
            evtArgs.actionButtonIcon = "trash-alt";
            evtArgs.actionButtonColor = 'error';

            evtArgs.onConfirmation = async () => {
                rowVM.removed = true;

                if(rowVM.isNew) {
                    removeFromUICallback();
                    if(rowVM.listVM.rowVMs) {
                        const index = rowVM.listVM.rowVMs.findIndex(rvm => rvm.rowVMId == rowVM.rowVMId);
                        if (index > -1) {
                            rowVM.listVM.rowVMs.splice(index, 1);
                        }
                    }
                    EventBus.$emit(RowShare.Event.ROW_DELETE, [rowVM]);
                    EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, displayModeVM);
                    return;
                }

                API.Row.deleteBatch([rowVM.rsRow.Id], hardDelete, RealTimeCollaborationModule.connection.connectionId).then((apiRes: boolean | null) => {
                    if(apiRes){
                        removeFromUICallback();
                        if(rowVM.listVM.rowVMs) {
                            const index = rowVM.listVM.rowVMs.findIndex(rvm => rvm.rowVMId == rowVM.rowVMId);
                            if (index > -1) {
                                rowVM.listVM.rowVMs.splice(index, 1);
                            }
                        }
                        EventBus.$emit(RowShare.Event.ROW_DELETE, [rowVM]);
                        EventBus.$emit(RowShare.Event.GRID_INFOS_CHANGED, displayModeVM);
                    }
                }).catch((err: any) =>  {
                    EventBus.$emit(RowShare.Event.ROW_ERROR, <RowErrorEventParams>{
                        rowVM: rowVM,
                        title: i18n.t("ErrorNotif_RowErrorTitleTemplate", [rowVM.rsRow.DescriptorFormattedValue]).toString(),
                        message: err._message});
                });
            }

            EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, evtArgs);
        }
    }

    static archiveRow(rowVM: RowVM, displayModeVM: ListDisplayModeVM, removeFromUICallback: () => void) {
        if(!(ListTreeModule.currentOrganization?.HasArchiveRows ?? false)) {
            EventBus.$emit(RowShare.Event.SHOW_UPSELL_DIALOG, <RowShare.ShowUpsellDialogEventParams> {
                title: i18n.t('UpsellDialog_Title').toString(),
                description: i18n.t('UpsellDialog_Description_ArchiveRows').toString()
            });            
        }
        else {
            var evtArgs = new RowShare.ConfirmationRequiredEventParams();
            evtArgs.title = i18n.t(`ArchiveConfirmation_SingleRowTitle`, [rowVM.rsRow.DescriptorFormattedValue]).toString();
            evtArgs.description1 = i18n.t(`ArchiveConfirmation_SingleRowDescription`,[rowVM.rsRow.DescriptorFormattedValue]).toString();
            evtArgs.cancelButtonText = i18n.t("Common_Cancel").toString();
            evtArgs.actionButtonText = i18n.t(`ArchiveConfirmation_ConfirmButton`).toString();
            evtArgs.actionButtonIcon = "archive";
            evtArgs.actionButtonColor = "primary";

            evtArgs.onConfirmation = async () => {
                try {
                    let apiRes: RowShare.ApiResult<boolean> | null = null;
                    apiRes = await API.Row.archive([rowVM.rsRow.Id], RealTimeCollaborationModule.connection.connectionId);
                    
                    if(apiRes) {
                        if(apiRes.IsValid && apiRes.Value) {
                            EventBus.$emit(RowShare.Event.ROW_ARCHIVE, [rowVM]);
                        }
                        else {
                            EventBus.$emit(RowShare.Event.ROW_ERROR, <RowErrorEventParams>{message:  i18n.t(apiRes.Errors[0].ExceptionCode.toString()).toString()});
                        }
                    }
                } catch(err: any) {
                    EventBus.$emit(RowShare.Event.ROW_ERROR, <RowErrorEventParams>{message: err._message});
                }
            }
            EventBus.$emit(RowShare.Event.CONFIRMATION_REQUIRED, evtArgs);
        }
    }
}