
    import { Component, Prop, Vue } from "vue-property-decorator";
    import OwnersDialog from "@/views/MyTables/FolderActions/OwnersDialog.vue";
    import * as API from "@/api/Api";
    import * as Models from "@/models/RowShare";
    import { Action, Getter, namespace } from "vuex-class";

    @Component({
        name:'ManageOwnersAction',
        components: { OwnersDialog }
    })
    export default class ManageOwnersAction extends Vue{

        @Prop({ default: false }) canManage!: boolean;
        @Prop({ default: '' }) organizationId!: string;
        @Prop({ default: '' }) folderId!: string;

        private manageOwnersModal = false;
        manageOwnersDialogName : string = 'manage-owners-modal';
        ManageOwnersServerErrorMessage: string = '';

        get dialogAlreadyExistInDOM(): boolean {
            return !!document.getElementById(this.manageOwnersDialogName);
        }

        cancelled(){
            this.manageOwnersModal = false;
        }
        
    }
