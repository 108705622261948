import * as RowShare from '@/models/RowShare';
import RowVM from '@/viewModels/Table/rowVM';
import BaseColumnProxy from '@/columnProxies/BaseColumnProxy';
import { Utils } from '@/utils/Utilities';
import { ColumnStrongType } from '@/models/Column';
import { ICellRendererParams } from 'ag-grid-community';

export default class TextColumnProxy extends BaseColumnProxy {
    getFormattedText(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        if(rsColumn.Type == ColumnStrongType.RichText) {
            var span = document.createElement('span');
            span.innerHTML = val;
            return span.textContent || span.innerText;
        } 

        return Utils.escapeHTML(val);
    }

    getFormattedHtml(rsColumn: RowShare.Column, rowVM: RowVM, val: any) : string {
        if(rsColumn.Type == ColumnStrongType.Text) {
            return "<span style='white-space: pre;'>" + Utils.escapeHTML(val) + "</span>";
        }
        else {
            return val;
        }
    }
    
    shouldSuppressKeyboardEvent(rsCol: RowShare.Column) { 
        return rsCol.Type == ColumnStrongType.RichText;
    }    

    /** Aggregation **/

    canShowTotal(rsColumn: RowShare.Column) : boolean {
        return true;
    }

    addToTotal(currentAggResult: any, rowVM: RowVM, rsColumn: RowShare.Column): any {
        if(rowVM && !Utils.isNullOrWhiteSpace(rowVM.getValue(rsColumn, this)))
            currentAggResult++;
    
        return currentAggResult;
    }

    formatTotal(aggResult: any, rowCount: number): any {
        return this.getRowCountFormat("Aggregation_TextFormat", aggResult, rowCount);
    }
    
    /** Filters **/
    
    getCustomFilterParams(rsCol: RowShare.Column) : null | any {
        var res = super.getCustomFilterParams(rsCol);

        if(rsCol.Type == ColumnStrongType.RichText) {
            if(!res)
                res = {};
            
            res.cellRenderer = (params: ICellRendererParams) => {
                if (!params.value || params.value === '(Select All)')
                    return params.value;
                
                return "<span class='richtext-wrapper'>" + params.value + "</span>";
            };

            res.valueFormatter = (params: ICellRendererParams) => {
                var span = document.createElement('span');
                span.innerHTML = params.value;
                return span.textContent || span.innerText;
            };
        } else if(rsCol.Type == ColumnStrongType.Text) {
            res.cellRenderer = (params: ICellRendererParams) => {
                if (!params.value || params.value === '(Select All)')
                    return params.value;
                
                return "<span style='white-space: pre;'>" + params.value + "</span>";
            };
        } 

        return res;
    }
}