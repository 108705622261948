
import { Prop, Component } from "vue-property-decorator";

import * as API from "@/api/Api";

import InputText from "@/views/components/FormInputs/InputText.vue";
import { UserModule } from "@/store/UserStore";
import InputPassword from "@/views/components/FormInputs/InputPassword.vue";
import { FormValidation } from "@/views/components/FormValidation";
import { ValidationObserver } from "vee-validate";
import { SignupUser } from "@/models/User";
import { RecaptchaActions } from "@/views/components/Recaptcha.vue";
import { OnPremModule } from "@/store/OnPremStore";
import Recaptcha from "@/views/components/Recaptcha.vue";
import * as RowShare from "@/models/RowShare";
import { EventBus } from "@/modules/EventBus";
import PageLoadSpinner from "@/views/layouts/LayoutParts/PageLoadSpinner.vue";
import { ApplicationModule } from "@/store/ApplicationStore";

@Component({
  name: "PersonalData",
  components: {
    InputText,
    InputPassword,
    ValidationObserver,
    Recaptcha,
    PageLoadSpinner,
  },
})
export default class PersonalData extends FormValidation {
  private modelSignUp!: SignupUser;
  private errorCreatingAccount: boolean = false;
  private working: boolean = false;
  private errorMessage!: string;

  @Prop() accountEmail!: string;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  created() {
    this.modelSignUp = new SignupUser();
    this.modelSignUp.Email = this.accountEmail;
    EventBus.$on(RowShare.Event.RECAPTCHA_SUCCEEDED, this.onRecaptchaSucceeded);
    EventBus.$on(
      [RowShare.Event.RECAPTCHA_FAILED, RowShare.Event.RECAPTCHA_CANCELEDV2],
      this.onRecaptchaFailedOrCanceled
    );
  }

  destroyed() {
    EventBus.$off(
      RowShare.Event.RECAPTCHA_SUCCEEDED,
      this.onRecaptchaSucceeded
    );
    EventBus.$off(
      [RowShare.Event.RECAPTCHA_FAILED, RowShare.Event.RECAPTCHA_CANCELEDV2],
      this.onRecaptchaFailedOrCanceled
    );
  }

  mounted() {
    this.setValidationLanguage(this.$i18n.locale);
  }

  async onSubmit() {
    this.working = true;
    this.modelSignUp.AutoLogon = true;
    if (this.mustShowRecaptcha) {
      this.triggerRecaptcha();
    } else {
      this.doSignup();
    }
  }

  private doSignup() {
    //After the user is registered, it verifies if the user is inside an organization or not
    UserModule.signUpUser(this.modelSignUp)
      .then((result: Boolean) => {
        if (result) {
          API.Organization.loadall().then((resultList) => {
            if (resultList != null && resultList.length > 0) {
              this.$emit("change-personal-data", resultList[0]);
            } else {
              this.$emit("change-personal-data", null);
            }
          });
        } else {
          this.$refs.observer.setErrors({
            confirmPassword: [this.$i18n.t("ExceptionCode_48").toString()],
          });
        }
      })
      .catch((error) => {
        if (error.code == 96 && this.mustShowRecaptcha) {
          //Invalid token
          this.retryFallbackRecaptcha();
          return;
        } else if (error.code == 27) {
          //Email invalid
          this.errorCreatingAccount = true;
          this.errorMessage = this.$i18n
            .t("ExceptionCode_" + error.code)
            .toString();
        } else {
          //User exists or email with special characters.
          this.errorCreatingAccount = true;
          this.errorMessage = this.$i18n
            .t("SignUp_ErrorCreatingAccount")
            .toString();
        }
      })
      .finally(() => {
        this.working = false;
      });
  }

  triggerRecaptcha() {
    this.modelSignUp.RecaptchaToken = "";
    this.modelSignUp.RecaptchaTokenIsV3 = true;
    EventBus.$emit(RowShare.Event.RECAPTCHA_SHOW, RecaptchaActions.Signup);
  }

  onRecaptchaSucceeded(successEvent: any) {
    this.modelSignUp.RecaptchaToken = successEvent.token;
    this.modelSignUp.RecaptchaTokenIsV3 = successEvent.isV3;
    this.doSignup();
  }

  retryFallbackRecaptcha() {
    this.modelSignUp.RecaptchaToken = "";
    EventBus.$emit(RowShare.Event.RECAPTCHA_SHOWV2);
  }

  onRecaptchaFailedOrCanceled(reason: string = "") {
    this.modelSignUp.RecaptchaToken = "";
    this.modelSignUp.RecaptchaTokenIsV3 = true;
    this.working = false;
    EventBus.$emit(RowShare.Event.GLOBAL_NOTIFICATION_RAISED, <
      RowShare.GlobalNotificationEventParams
    >{
      autoHide: true,
      autoHideAfterMs: 3000,
      type: RowShare.NotificationType.error,
      message: this.$i18n.t("Recaptcha_Invalid").toString() + reason!,
    });
  }

  get isNotOnPrem() {
    return !OnPremModule.isOnPrem;
  }

  get mustShowRecaptcha() {
    return this.isNotOnPrem && process.env.NODE_ENV != "development";
  }

  get passwordRule() {
    return ApplicationModule.passwordRegex;
  }
}
