
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { EventBus } from "@/modules/EventBus";
import { Event } from "@/models/Event";

export enum RecaptchaActions {
  Login = "Login",
  Signup = "Signup",
  Contact = "Contact",
  ResetPasswordRequest = "ResetPasswordRequest",
  ManageOrganization = "ManageOrganization",
  ShareMembers = "ShareMembers",
  ListAccess = "ListAccess",
}

@Component({
  name: "Recaptcha",
  components: {},
})
export default class Recaptcha extends Vue {
  @Prop() calledAction!: string;

  private sitekeyV3 = "6LfrDZkUAAAAABhMd0w2md2Q9XoRPNG4cnOL8eP9";
  private sitekeyV2 = "6Lci2zAiAAAAAF_cdVk7AGJDqhRiOfBv4goh21Jx";
  private isRecaptchaV3 = true;
  private isRecaptchaV2 = false;
  private isV2Ready = false;
  private isV2Valid = false;

  mounted() {
    EventBus.$on(Event.RECAPTCHA_SHOW, this.showV3);
    EventBus.$on(Event.RECAPTCHA_SHOWV2, this.showV2);
  }

  destroyed() {
    EventBus.$off(Event.RECAPTCHA_SHOW, this.showV3);
    EventBus.$off(Event.RECAPTCHA_SHOWV2, this.showV2);
    this.removeCaptcha();
  }

  showV3() {
    let token = localStorage.getItem("lastgrecaptchatoken");
    let tokenTimestamp = localStorage.getItem("lastgrecaptchatokentimestamp");
    let valid = false;
    if (tokenTimestamp && token) // one hour max
        {
          let previousTimestamp = parseInt(tokenTimestamp);
          let currentTimestamp = new Date().getTime();
          if (currentTimestamp-previousTimestamp < 1000 * 3600)
            valid=true;
        }
    if (valid) {
      if (localStorage.getItem("lastgrecaptchatokentype")!.includes("V3"))
        EventBus.$emit(Event.RECAPTCHA_SUCCEEDED, { token: token, isV3: true });
      else
        EventBus.$emit(Event.RECAPTCHA_SUCCEEDED, {
          token: token,
          isV3: false,
        });
    } 
    else {
      localStorage.removeItem("lastgrecaptchatokentimestamp");
      localStorage.removeItem("lastgrecaptchatoken");
      localStorage.removeItem("lastgrecaptchatokentype");
      EventBus.$off(Event.RECAPTCHA_SHOW, this.showV3);
      if (this.isRecaptchaV2) this.hideV2();
      this.initOrResetRecaptchaV3();
    }
  }

  initOrResetRecaptchaV3() {
    this.isRecaptchaV3 = true;
    //see https://developers.google.com/recaptcha/docs/faq#can-i-run-recaptcha-v2-and-v3-on-the-same-page
    this.$loadScript(
      "https://www.google.com/recaptcha/api.js?render=" + this.sitekeyV3
    ).then((value) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(this.sitekeyV3, { action: this.calledAction })
          .then(this.onSuccess, this.onError);
      });
    });
  }

  showV2() {
    EventBus.$off(Event.RECAPTCHA_SHOWV2, this.showV2);
    localStorage.removeItem("lastgrecaptchatokentimestamp");
    localStorage.removeItem("lastgrecaptchatoken");
    localStorage.removeItem("lastgrecaptchatokentype");

    if (this.isRecaptchaV3) this.hideV3();
    this.isRecaptchaV2 = true;
    // @ts-ignore
    window.ReCaptchaLoaded = this.onV2Loaded;
    // @ts-ignore
    window.onErrorV2 = this.onErrorV2;
    // @ts-ignore
    window.onExpiredV2 = this.onExpiredV2;
    var script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?onload=ReCaptchaLoaded&render=explicit";
    document.head.appendChild(script);
    this.isV2Ready = true;
  }

  private hideV3() {
    this.isRecaptchaV3 = false;
    this.$unloadScript(
      "https://www.google.com/recaptcha/api.js?render=" + this.sitekeyV3
    );
    this.removeCaptcha();
    EventBus.$on(Event.RECAPTCHA_SHOW, this.showV3);
  }

  private closeV2() {
    this.hideV2();
    localStorage.removeItem("lastgrecaptchatokentimestamp");
    localStorage.removeItem("lastgrecaptchatoken");
    localStorage.removeItem("lastgrecaptchatokentype");

    EventBus.$emit(Event.RECAPTCHA_CANCELEDV2);
  }

  private hideV2() {
    this.isRecaptchaV2 = false;
    this.isV2Ready = false;
    this.$unloadScript(
      "https://www.google.com/recaptcha/api.js?onload=ReCaptchaLoaded&render=explicit"
    );
    this.removeCaptcha();
    // @ts-ignore
    window.ReCaptchaLoaded = null;
    // @ts-ignore
    window.onErrorV2 = null;
    // @ts-ignore
    window.onExpiredV2 = null;
    EventBus.$on(Event.RECAPTCHA_SHOWV2, this.showV2);
  }

  private removeCaptcha() {
    window.document
      .querySelectorAll(".g-recaptcha-response")
      .forEach((el) => el.remove());
    window.document
      .querySelectorAll(".grecaptcha-badge")
      .forEach((el) => el.remove());
  }

  private hideCaptchaBadge() {
    // allowed IF captcha text disclaimer
    // css visibility = "hidden" does not work
    window.document
      .querySelectorAll(".grecaptcha-badge")
      .forEach((el) => el.setAttribute("visibility", "hidden"));
  }

  onV2Loaded() {
    //wait recaptchaV2 is loaded
    window.grecaptcha.render("recaptchaV2", {
      sitekey: this.sitekeyV2,
      badge: "inline",
      callback: this.onSuccessV2,
    });
  }

  onSuccess(token: string) {
    this.hideV3();
    let timeStamp = new Date().getTime().toString();
    localStorage.setItem("lastgrecaptchatokentimestamp",timeStamp);
    localStorage.setItem("lastgrecaptchatoken", token);
    localStorage.setItem("lastgrecaptchatokentype", "V3");
    EventBus.$emit(Event.RECAPTCHA_SUCCEEDED, { token: token, isV3: true });
  }

  onSuccessV2(token: string) {
    if (!this.isV2Ready) return;
    if (token) {
      this.isV2Valid = true;
    }
  }

  onValidTokenV2() {
    if (!this.isV2Valid) return;

    var token = window.grecaptcha.getResponse();
    this.hideV2();
    let timeStamp = new Date().getTime().toString();
    localStorage.setItem("lastgrecaptchatokentimestamp",timeStamp);
    localStorage.setItem("lastgrecaptchatoken", token);
    localStorage.setItem("lastgrecaptchatokentype", "V2");
    EventBus.$emit(Event.RECAPTCHA_SUCCEEDED, {
      token: token,
      isV3: false,
    });
  }

  onError(reason: string) {
    localStorage.removeItem("lastgrecaptchatokentimestamp");
    localStorage.removeItem("lastgrecaptchatoken");
    localStorage.removeItem("lastgrecaptchatokentype");
    if (reason.toLowerCase().includes("timeout")) this.initOrResetRecaptchaV3();
  }

  onErrorV2(reason: string) {
    if (this.isRecaptchaV2) {
      this.hideV2();
      localStorage.removeItem("lastgrecaptchatokentimestamp");
      localStorage.removeItem("lastgrecaptchatoken");
      localStorage.removeItem("lastgrecaptchatokentype");
      EventBus.$emit(Event.RECAPTCHA_FAILED, reason);
    }
  }

  onExpiredV2() {
    localStorage.removeItem("lastgrecaptchatokentimestamp");
    localStorage.removeItem("lastgrecaptchatoken");
    localStorage.removeItem("lastgrecaptchatokentype");
    this.isV2Valid = false;
  }

  get isDisabled() {
    return !this.isV2Valid;
  }
}
