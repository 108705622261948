
import {Component, Prop } from 'vue-property-decorator';
import { UserModule } from '@/store/UserStore'
import Bandeau from '@/views/layouts/LayoutParts/Bandeau.vue';
import InputText from '@/views/components/FormInputs/InputText.vue';
import InputTextArea from '@/views/components/FormInputs/InputTextArea.vue';
import InputSelect from '@/views/components/FormInputs/InputSelect.vue';
import * as API from '@/api/Api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { FormValidation } from "@/views/components/FormValidation";
import { User } from '@/models/User';
import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
import { EventBus } from '@/modules/EventBus';
import * as Models from '@/models/RowShare';
import { RecaptchaActions } from '@/views/components/Recaptcha.vue';
import { OnPremModule } from '@/store/OnPremStore';
import Recaptcha from '@/views/components/Recaptcha.vue';
import { RowShareException } from '@/api/ApiUtils';
import { Event } from '@/models/Event';
import { GlobalNotificationEventParams, NotificationType } from '@/models/RowShare';


@Component({
    name:'Contact',
    components:{ Bandeau, ValidationProvider, ValidationObserver, InputText, InputTextArea, InputSelect, PageLoadSpinner, Recaptcha},
})

export default class Contact extends FormValidation {

    @Prop() tokenError!:string;
    @Prop() selectedQuestion!: string;
    //Fields
    private listSubjects = [{text:"",value:""}];
    private user!: User;
    private disableInputsForLoggedInUser = false;
    private formulario = true;
    private titleBandeau='';
    private descriptionBandeau='';
    private messageSent = false;
    private messageError = false;
    private working = false;

    model = {
        Question:'',
        Name:'',
        Email:'',
        Message:'',
        File: null,
        RecaptchaToken:'',
        RecaptchaTokenIsV3: true

    };

    //Methods
    async created(){
        EventBus.$on(Models.Event.LOCALE_CHANGED, async() => {
            await this.onLocaleChanged();
        });
        EventBus.$on(Models.Event.RECAPTCHA_SUCCEEDED,this.onRecaptchaSucceeded);
        EventBus.$on([Models.Event.RECAPTCHA_FAILED,Models.Event.RECAPTCHA_CANCELEDV2], this.onRecaptchaFailedOrCanceled);
        
    }

    async mounted(){    
        this.user = UserModule.CurrentUser;     
        if(!this.user.IsAnonymous){
            this.disableInputsForLoggedInUser = true;
            this.model.Name = this.user.NickName;
            this.model.Email = this.user.Email;
        }  
        this.onLocaleChanged();
        this.titleBandeau = this.$i18n.t('Contact_Title').toString();
        this.descriptionBandeau = this.$i18n.t('Contact_Description').toString();
    }

    destroyed() {
        EventBus.$off(Models.Event.RECAPTCHA_SUCCEEDED,this.onRecaptchaSucceeded);
        EventBus.$off([Models.Event.RECAPTCHA_FAILED,Models.Event.RECAPTCHA_CANCELEDV2], this.onRecaptchaFailedOrCanceled);
    }

    loadSelectList(){
        this.listSubjects=[];
        this.listSubjects.push({text:this.$i18n.t("Contact_SelectFirstQuestion").toString(), value:"Contact/Help"});
        this.listSubjects.push({text:this.$i18n.t("Contact_SelectSecondQuestion").toString(), value:"Contact/Bug"});
        this.listSubjects.push({text:this.$i18n.t("Contact_SelectThirdQuestion").toString(), value:"Contact/Billing"});
        this.listSubjects.push({text:this.$i18n.t("Contact_SelectFourthQuestion").toString(), value:"Contact/Features"});
        this.listSubjects.push({text:this.$i18n.t("Contact_SelectFifthQuestion").toString(), value:"Contact/Sales"});
        if(this.selectedQuestion && !this.model?.Question) {
            this.model.Question = this.selectedQuestion;
        }
    }

    onLocaleChanged(){
        this.setValidationLanguage(this.$i18n.locale);
        this.loadSelectList();
        this.titleBandeau = this.$i18n.t('Contact_Title').toString();
        this.descriptionBandeau = this.$i18n.t('Contact_Description').toString();
    }

    //This method is for the input file
    // formatFileNames(files:File[]){
    //     if (files.length === 1) {
    //         return files[0].name
    //     } else {
    //         return `${files.length}` + " " +this.$i18n.t("Form_MultipleFilesSelected").toString();
    //     }
    // }

    async onSubmit(){
        this.working = true;
        if (this.mustShowRecaptcha) {
            this.triggerRecaptcha();
        }
        else{
            this.doSend();
        }
    }

    private doSend(){
        API.ContactMessage.sendMessage(this.model, this.model.File)
            .then(result=>{           
                if(result==true){
                    this.formulario = false;
                    this.messageSent = true;
                    this.messageError = false;
                    this.descriptionBandeau = this.$i18n.t('Contact_MessageConfirmationThanks').toString();
                }
                else{
                    this.messageError = true;
                    this.descriptionBandeau = '';
                }
            })
            .catch(exc => {
                        let rsExc = exc as RowShareException;
                        let exceptionMsg = "";
                        if(rsExc) {
                            if (rsExc.code == 96 && this.mustShowRecaptcha) {
                                // Recaptcha token not valid
                                this.retryFallbackRecaptcha();
                                return;}
                            exceptionMsg = rsExc.message;
                        }
                        else {
                            exceptionMsg = exc.toString();
                        }
                        this.messageError = true;
                        this.descriptionBandeau = '';
                        EventBus.$emit(Event.GLOBAL_NOTIFICATION_RAISED, <GlobalNotificationEventParams>{ message: exceptionMsg,
                            type: NotificationType.error,autoHide: true, autoHideAfterMs: 10000});
  
            })
            .finally(()=> {
                this.working=false;
            });
    }

    triggerRecaptcha() {
        this.model.RecaptchaToken = "";
        EventBus.$emit(Event.RECAPTCHA_SHOW, RecaptchaActions.Contact);
    }

    onRecaptchaSucceeded(successEvent: any){   
        this.model.RecaptchaToken = successEvent.token;
        this.model.RecaptchaTokenIsV3 = successEvent.isV3;
        this.doSend();
    }

    retryFallbackRecaptcha(){
        this.model.RecaptchaToken = "";
        EventBus.$emit(Event.RECAPTCHA_SHOWV2);
    }

    onRecaptchaFailedOrCanceled(reason: string = ""){   
        this.model.RecaptchaToken = "";
        this.model.RecaptchaTokenIsV3 = true;
        this.working = false;
        this.messageError = true;
        this.descriptionBandeau = this.$i18n.t('Recaptcha_Invalid').toString() + reason;
        EventBus.$emit(Models.Event.GLOBAL_NOTIFICATION_RAISED, <Models.GlobalNotificationEventParams>{
                    autoHide: true,
                    autoHideAfterMs: 3000,
                    type: Models.NotificationType.error,
                    message: (this.$i18n.t('Recaptcha_Invalid').toString()) + reason
                });
    }

    get isNotOnPrem(){
            return !OnPremModule.isOnPrem;
        }

    get mustShowRecaptcha(){
        return this.isNotOnPrem && process.env.NODE_ENV!="development";
        }
}
