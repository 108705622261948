import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"
import { UserNotificationUpdate } from '@/models/RowShare';

export class UserNotificationLink {
    static loadAll(options: IRequestOptions | null = null) {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/usernotificationlink/loadall", options)
            .then(response => response.json<Array<RowShare.UserNotificationLink>>());
    }

    static updateUserTableNotification(
        notification: UserNotificationUpdate, 
        options: IRequestOptions | null = null) {
            return ApiUtils.apiPostJson("/usernotificationlink/save", notification, options)
                .then(response => response.object(RowShare.UserNotificationLink));
    }
}