
import {Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as api from '@/api/Api';
import * as models from '@/models/RowShare';
import { ListTreeModule } from '@/store/ListTreeStore';
import { EventBus } from '@/modules/EventBus';
import { ApplicationModule } from '@/store/ApplicationStore';
import ListDisplayModeVM from '@/viewModels/Table/listDisplayModeVM';

@Component({name: 'RowMergeDialog'})
export default class RowMergeDialog extends Vue {
    @Prop() currentList!: models.List;
    @Prop() displayModeVM!: ListDisplayModeVM
    // @Prop() generationContext!: models.ReportGenerationContext; // Récupérer depuis l'event
    // @Prop() defaultReportId!: string;

    lastInitialisedListId : string | null = null;

    showDialog: boolean = false;
    loadingReports: boolean = false;
    reportList: models.Report[] = [];
    selectedReport: models.Report | null = null;
    processingReport: boolean = false;
    currentJob: models.Job | null = null;
    disableTemplateChoice: boolean = false;
    generationContext: models.ReportGenerationContext | null = null;
    defaultReportId: string | null = null;
    currentFileTypeColumns: models.Column[] | null = [];
    destinationColumnIsDeleted: boolean = false;
    destinationColumnIsReadOnly: boolean = false;
    destinationRowIsReadOnly: boolean = false;
    disableGenerateButton: boolean = false;
    rowDestinationOutputMessage: string | null = null;
    
    created() {
        EventBus.$on(models.Event.ROWMERGE_REQUEST, this.executeRowMergeRequest);
        this.initReportsList();
    }

    async loadColumns(){
        await api.Column.loadForList(this.currentList.Id).then(cols => this.currentFileTypeColumns = cols)
        if (this.currentFileTypeColumns !== null){
            this.currentFileTypeColumns = this.currentFileTypeColumns.filter((col: models.Column) => {
                return col.Type === models.ColumnStrongType.File;
            });
        }
    }

    @Watch('showDialog')
    initDialog() {
        this.currentJob = null;
        this.processingReport = false;
        this.disableTemplateChoice = false;
        this.destinationColumnIsDeleted = false;
        this.destinationColumnIsReadOnly = false;
        this.destinationRowIsReadOnly = false;
        this.disableGenerateButton = false;
        if(this.isTestMode) {
            var reportIndex = this.reportList.findIndex(rl => rl.Id === this.generationContext?.reportId);
            if(reportIndex > -1) {
                this.selectedReport = this.reportList[reportIndex];
                this.disableTemplateChoice = true;
            }
        }
        if(this.showDialog){
            this.validateSelectedReport();
        }
    }

    @Watch('currentList')
    initReportsList() {
        if(this.currentList && (this.lastInitialisedListId == null || this.lastInitialisedListId != this.currentList.Id)) {
            this.lastInitialisedListId = this.currentList.Id;
            this.loadingReports = true;
            api.Report.loadByList(this.currentList.Id, {cache: false}).then((reports: models.Report[] | null ) => {
                if(reports) {                    
                    this.reportList = reports.filter((r: models.Report) => {
                                if(this.defaultReportId) {
                                    return r.Id === this.defaultReportId
                                }
                                else {
                                return (r.Published && !r.hasConversionError)
                                }
                    });
                    this.loadingReports = false;
                    if(this.reportList.length > 0) {
                        this.selectedReport = this.reportList[0];
                    }
                    let capabilities = ListTreeModule.currentListCapabilities;
                    if(!capabilities) {
                        capabilities = new models.ListCapabilities();
                    }
                    capabilities.rowMerge = (this.hasReports || this.currentList.Owned);
                    ListTreeModule.updateCurrentListCapabilities(capabilities);
                }
            });
        }
    }

    onCancel() {
        this.showDialog = false;
    }

    @Watch('selectedReport')
    async validateSelectedReport() {
        this.destinationColumnIsDeleted = false;
        this.destinationColumnIsReadOnly = false;
        this.destinationRowIsReadOnly = false;
        this.rowDestinationOutputMessage = this.$i18n.t('RowMergeDialog_DownloadAsFileDestinationPlaceMessage').toString();

        if (this.selectedReport?.OutputDestinationType === models.OutputDestinationType.CopyToColumn) {
            //TODO: Voir comment éviter un appel API alors qu'on a les colonnes dans ListVM
            //step1: columns verification
            await this.loadColumns();
            let found = false;
            this.currentFileTypeColumns?.forEach((col: models.Column) => {
                if(col.Id === this.selectedReport?.OutputDestinationId){
                    found = true;
                    this.destinationColumnIsReadOnly = col.IsReadOnly;
                    //TODO: Ajouter un paramètre à la chaine de traduction
                    this.rowDestinationOutputMessage = this.$i18n.t('RowMergeDialog_DestinationPlaceMessage', {columnName: col.DisplayName}).toString();
                }
            });

            if(this.destinationColumnIsReadOnly){
                this.disableGenerateButton = true;
                return;
            }

            if (!found){
                this.destinationColumnIsDeleted = true;
                this.disableGenerateButton = true;
                return;
            }
            //step 2: rows verification
            if(this.generationContext?.hasReadOnlyRows) {
                this.destinationRowIsReadOnly = true;
                this.disableGenerateButton = true;
                return;
            }
        }

        this.disableGenerateButton = false;
    }

    async generate() {
        if(!this.selectedReport){
            return;
        }

        this.disableTemplateChoice = true;
        const rows = this.generationContext ? this.generationContext.rowIds : null;
        if(this.isTestMode && this.generationContext) {
            return api.Report.test(this.generationContext.reportId, this.generationContext.outputFormat)
                .then(job => this.startMonitoringJob(job));
        }
        return api.Report.createJob(this.selectedReport.Id, this.selectedReport.OutputFormat, rows)
                .then(result => {
                    if(result?.IsValid) {
                        let job = result.Value;
                        this.startMonitoringJob(job);
                    }
                });
    }

    startMonitoringJob(job: models.Job | null) {
        if(job == null) {
            return;
        }
        this.processingReport = true;
        this.currentJob = new models.Job();
        Object.assign(this.currentJob, job);
        if(this.currentJob) {
            this.currentJob.updateStatusAsync(1000, this.onJobUpdated);
        }
        this.$emit('JobStarted', job);
    }

    onJobUpdated(job: models.Job) {
        if(job?.Type === models.JobType.ListReport && this.selectedReport?.OutputDestinationType === models.OutputDestinationType.CopyToColumn &&
             job?.Status === models.JobStatus.Finished && this.displayModeVM && this.generationContext?.rowIds) {
            
            api.Row.loadMultiple(this.generationContext.rowIds)
                .then(result => {
                    if(result?.IsValid) {
                        this.displayModeVM.updateRowsInUI(result.Value);
                    }
                });
        }
    }

    executeRowMergeRequest(context: models.ReportGenerationContext) {
        if(context == undefined) {
            return;
        }
        this.generationContext = context;
        this.showDialog = true;
    }

    get isTestMode(): boolean {
        return this.generationContext?.testMode ?? false;
    }

    get hasReports() {
        return this.reportList && this.reportList.length > 0;
    }

    get generateButtonTitle() {
        if(this.isTestMode) {
            return this.$i18n.t('RowMerge_Report_Test').toString();
        }
        else {
            return this.$i18n.t('RowMergeDialog_Generate').toString();
        }
    }

    get dialogTitle() {
        if(this.isTestMode) {
            return this.$i18n.t('RowMerge_Report_TestTitle').toString();
        }
        if(this.hasReports) {
            return this.$i18n.t('RowMergeDialog_Title').toString();
        }
        else {
            return this.$i18n.t('RowMergeDialog_NoTemplateTitle').toString();
        }
    }

    get rowCountMessage(): string {
        let rowCount = this.selectedRowsCount;
        let message = "";

        if(this.isTestMode) {
            message = this.$i18n.t('RowMerge_Report_TestDescription').toString();
        }
        else if (rowCount == null) { 
            message = this.$i18n.t("RowMergeDialog_UnknownRowCount", {rowCount: rowCount}).toString();
        } else if ((this.generationContext?.rowIds?.length ?? 0) > 0) {
            if (rowCount > 1) {
                message = this.$i18n.t("RowMergeDialog_ManyRowsSelection", {rowCount: rowCount}).toString();
            } else {
                message = this.$i18n.t("RowMergeDialog_OneRowSelection", {rowCount: rowCount}).toString();
            }
        } else {
            if (rowCount > 1) {
                message = this.$i18n.t("RowMergeDialog_WholeTable", {rowCount: rowCount}).toString();
            } else if (rowCount == 1) {
                message = this.$i18n.t("RowMergeDialog_WholeTableOneRow", {rowCount: rowCount}).toString();
            } else {
                message = this.$i18n.t("RowMergeDialog_UnknownRowCount", {rowCount: rowCount}).toString();
            }
        }

        //TODO: Voir si on peut faire mieux que concaténer deux chaines
        return `${message} ${this.rowDestinationOutputMessage}`;
    }

    get selectedRowsCount(): number {
        if(this.generationContext) {
            if((this.generationContext.rowIds?.length ?? 0) > 0) {
                return this.generationContext.rowIds?.length ?? 0;
            }
            else
            {
                return this.generationContext.totalTableRowCount;
            }
        }
        return 0;
    }

    get hasTooManyRows(): boolean {
        return this.selectedRowsCount > 2000;
    }

    get tooManyRowsWarning(): string {
        if(this.hasTooManyRows) {
            return this.$i18n.t('RowMergeDialog_TooManyRows').toString();
        }
        return "";
    }

    get canManageReports(): boolean {
        return (this.currentList?.Owned ?? false) && this.defaultReportId == null && !ApplicationModule.isMsTeamsApp && !this.isTestMode;
    }

    get canShowMyDocuments(): boolean {
        return !this.isTestMode && !ApplicationModule.isMsTeamsApp;
    }

    get jobStatus(): string {
        if(!this.currentJob) {
            return "";
        }
        return models.Job.getStatusString(this.currentJob.Status);
    }

    get hasJobError(): boolean {
        if(this.currentJob?.Status) {
            return this.currentJob.Status === models.JobStatus.Error;
        }
        return false;
    }

    get showGenerateButton(): boolean {
        return !this.currentJob || !this.currentJob.hasFinished;
    }

    get documentUrl(): string {
        if(this.currentJob?.OutputFilePath) {
            return "/" + this.currentJob.OutputFilePath;
        }
        return "";
    }

    get showDownloadButton(): boolean {
        if(this.currentJob) {
            return this.currentJob.hasFinished && !this.hasJobError && this.selectedReport?.OutputDestinationType === models.OutputDestinationType.DownloadFile;
        }
        return false;
    }

    get showCopiedToColumn(): boolean {
        if(this.currentJob) {
            return this.currentJob.hasFinished && !this.hasJobError && this.selectedReport?.OutputDestinationType === models.OutputDestinationType.CopyToColumn;
        }
        return false;
    }

}
