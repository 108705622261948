import { DataStatus } from '@/models/Enums';

export class Row {
    public InsertBeforeRowId!:         string;
    public MoveLast!:                  boolean;
    public Id!:                        string;
    public ListId!:                    string;
    public Index!:                     number;
    public CanUpdate!:                 boolean;
    public CanSuppress!:               boolean;
    public CanAssign!:                 boolean;
    public CreatedDateUtc!:            string;
    public ModifiedDateUtc!:           string;
    public Owner!:                     RowOwner;
    public Version!:                   string;
    public DescriptorFormattedValue!:  string;
    public Values!:                    any;
    public Owned!:                     boolean;
    public OwnerId!:                   string;
    public OwnerIdType!:               OwnerIdType;
    public OwnerBackgroundColorIndex!: number;
    public IsAnonymousSurveyAnswer!:   boolean;
    public ModifiedByNickName!:        string;
    public ModifiedByEmail!:           string;
    public CreatedByNickName!:         string;
    public CreatedByEmail!:            string;
    public OwnerManagerEmail!:         string;
    public OwnerManagerName!:          string;
    public OwnerHasAccessToList!:      boolean;
    public ParentRows!:                RelatedRow[];
    public ChildRows!:                 RelatedRow[];
    public Status!:                    DataStatus;
    public StatusDateUtc!:             string;
    public StatusChangeByUserName!:    string;
    public IsParentRow!:               boolean;
    public RtcConnectionId!:           string | null;

    public get HasRelatedRows() :boolean {
        return (this.ParentRows?.length ?? 0) > 0;
    }
}

// export class ClonedRowResult {
//     public RowId!: string;
//     public ClonedRow!: Row;
// }

export const surveyUserEmail = "survey@rowshare.com";

export class RowOwner {
    public BackgroundColorIndex!: number;
    public Bigram!:               string;
    public Name!:                 string;
    public Type!:                 number;
    public Email!:                string;
    public DisplayName!:          string;
}

export const enum OwnerIdType {
    Unspecified = 0,
    User = 1,
    Group = 2,
    DirectTeam = 3,
    ExtendedTeam = 4
}

export class Comment {
    public CommentCount!: number;
}

export class CommentDetails {
    public Id!: string;
    public Content!: string;
    public CreatedDate!: Date;
    public Owner!: RowOwner;
    public Owned!: boolean;
    public CreatedSince!: string;
    public RtcConnectionId!: string | null;
}

export class File {
    public ContentType!:      string;
    public FileName!:         string;
    public ImageUrl!:         string;
    public AbsoluteFileUrl!:  string;
    public Size!:             number;
    public LastWriteTimeUtc!: string;
}

export class RelatedRow {
    public Id!: string;
    public ChildRowId!: string;
    public ParentRowId!: string;
    public RelationColumnId!: string;
}