import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators'
import * as API from '@/api/Api';
import * as RowShare from '@/models/RowShare';
import { ListTreeHelper } from '@/utils/ListTreeHelper';
import store from '@/modules/Store';
import { TrackingUtils } from '@/utils/Tracking';

@Module({ dynamic: true, store: store, name: 'ListTreeStore' })
export class ListTreeStore extends VuexModule {

    /** getters **/

    //organizations structure, with all folders and lists, favorites and recents
    listTree: RowShare.ListTree | null = null;

    //all user's organizations, with root folders only
    organizationsTree: RowShare.ListTree | null = null;

    currentOrganizationId: string | null = localStorage["CurrentOrganizationId"] ?? null;

    currentOrganization: RowShare.ListTreeOrganization | null = null;

    currentFolder: RowShare.ListTreeFolder | null = null;

    currentBreadcrumb: RowShare.BreadcrumbFolder[] | null = null;

    currentList: RowShare.List | null = null;

    currentListCapabilities: RowShare.ListCapabilities | null = null;

    currentListDisplayedRows: RowShare.DisplayedRows = RowShare.DisplayedRows.Active;

    currentSmallSiteLogoPath: string | null = null;

    currentFullSiteLogoPath: string | null = null;

    /** mutations **/
    @Mutation
    private loadCurrentLogosIntoStore(payload: { fullSiteLogoPath: string | null, smallSiteLogoPath: string | null }): void {
        this.currentFullSiteLogoPath = payload.fullSiteLogoPath;
        this.currentSmallSiteLogoPath = payload.smallSiteLogoPath;
    }

    @Mutation
    private loadListTreeIntoStore(listTree: RowShare.ListTree | null): void {
        if (!this.organizationsTree || this.organizationsTree.Organizations.length <= 0
            || !this.currentOrganizationId) {
            throw new Error("organizationsTree should be initialized first");
        }

        if (!listTree || listTree.Organizations.length <= 0) {
            ListTreeHelper.resetLocalStoreCurrentOrganization();
            this.listTree = null;
            this.currentOrganization = null;
            this.currentOrganizationId = null;
            this.currentFolder = null;
            this.currentBreadcrumb = null;
        } else {
            this.listTree = listTree;
            // current folder and organization should be either initialized if this is first load, or refreshed if this is a reload
            var pathElements = ListTreeHelper.getPathElementsOrDefault(listTree, this.currentOrganizationId, this.currentFolder?.Id ?? null);
            this.currentOrganization = pathElements.organization;
            this.currentOrganizationId = pathElements.organization.Id;
            this.currentFolder = pathElements.folder;
            this.currentBreadcrumb = pathElements.breadcrumb;
        }
    }

    @Mutation
    private loadOrganizationsTreeIntoStore(organizationsTree: RowShare.ListTree | null): void {
        if (!organizationsTree || organizationsTree.Organizations.length <= 0) {
            this.organizationsTree = null;
            ListTreeHelper.resetLocalStoreOrganizationsTree();

        } else {
            this.organizationsTree = organizationsTree;
            let currentOrgId = localStorage.getItem("CurrentOrganizationId");
            if (!currentOrgId) {
                let defaultOrg = ListTreeHelper.getOrganizationTreeOrDefault(organizationsTree, null, null);
                if (defaultOrg && defaultOrg.Id && defaultOrg.RootFolder && defaultOrg.RootFolder.Id) {
                    var tree: RowShare.ListTree;
                    if (this.listTree) {
                        tree = this.listTree;
                    }
                    else {
                        tree = new RowShare.ListTree();
                        tree.Organizations = new Array(1);
                        tree.Organizations[0] = defaultOrg;
                    }
                    this.listTree = tree;
                    if (!this.currentOrganizationId)
                        this.currentOrganizationId = defaultOrg.Id;

                }
            }
        }
    }

    @Mutation
    public loadCurrentOrganizationIdIntoStore(orgId: string | undefined): void {
        if (orgId) {
            if (this.currentOrganizationId != orgId)
                ListTreeHelper.resetLocalStoreCurrentOrganization();
            this.currentOrganizationId = orgId;
        }
    }

    @Mutation
    private loadCurrentListIntoStore(list: RowShare.List | null): void {
        var oldListId = this.currentList?.Id;
        this.currentList = list;

        // if the list is still the same, we don't refresh its capabilities
        // capabilities provider should listen to currentList changes and refresh capabilities if needed 
        if (oldListId !== list?.Id) {
            this.currentListCapabilities = new RowShare.ListCapabilities();
            this.currentListDisplayedRows = RowShare.DisplayedRows.Active;

        }

    }

    @Mutation
    private loadCurrentListCapabilitiesIntoStore(listCapabilities: RowShare.ListCapabilities | null): void {
        this.currentListCapabilities = listCapabilities;
    }

    @Mutation
    private loadCurrentListDisplayedRowsIntoStore(displayedRows: RowShare.DisplayedRows | null): void {
        if (!displayedRows) {
            this.currentListDisplayedRows = RowShare.DisplayedRows.Active;
        }
        else {
            this.currentListDisplayedRows = displayedRows;
        }
    }

    @Mutation
    private updateCurrentFolderIntoStore(payload: { organizationId: string, folderId: string | null }) {
        if (!this.listTree || !this.organizationsTree) {
            //throw new Error("ListTree should be initialized first");
            console.error("ListTree should be initialized first");
            return;
        }

        var pathElements = ListTreeHelper.getPathElementsOrDefault(this.listTree, payload.organizationId, payload.folderId);
        this.currentOrganization = pathElements.organization;
        this.currentOrganizationId = payload.organizationId;
        this.currentFolder = pathElements.folder;
        this.currentBreadcrumb = pathElements.breadcrumb;
        // updateCurrentFolderIntoStore should not be used when there is an active list, see updateCurrentListPathIntoStore instead
        this.currentList = null;

    }

    @Mutation
    private updateCurrentListPathIntoStore(listId: string) {
        var pathElements = ListTreeHelper.getPathElementsForList(this.listTree, listId);

        // this is not an error, list with external access may be seen by people who won't find them in there listtree
        if (!pathElements)
            return;

        this.currentOrganization = pathElements.organization;
        this.currentOrganizationId = pathElements.organization.Id;
        this.currentFolder = pathElements.folder;
        this.currentBreadcrumb = pathElements.breadcrumb;
    }

    /** actions **/

    @Action({ rawError: true })
    async refreshListTreeIfMissingOrOutdated(): Promise<any> {
        return this.refreshListTreeIfMissing(false);
    }

    @Action({ rawError: true })
    async refreshListTreeIfMissing(enforce: boolean | false): Promise<any> {
        await this.refreshOrganizationsTreeIfMissing(false);
        if (!enforce && ListTreeHelper.isLocalStoredOrganizationFresh(this.currentOrganizationId!)) {
            this.context.commit('loadListTreeIntoStore', ListTreeHelper.getLocalStoreListTree());
            return;
        }
        else {
            await this.refreshListTree();
            return;
        }
    }

    @Action({ rawError: true })
    async refreshOrganizationsTreeIfMissingOrOutdated(): Promise<any> {
        return this.refreshOrganizationsTreeIfMissing(false);
    }
    @Action({ rawError: true })
    async refreshOrganizationsTreeIfMissing(enforce: boolean | false): Promise<any> {
        if (!enforce && ListTreeHelper.isLocalStoredAllOrganizationsFresh()) {
            this.context.commit('loadOrganizationsTreeIntoStore', ListTreeHelper.getLocalStoreOrganizationsTree());
            return;
        }
        else {
            await this.refreshOrganizationsTree();
            return;
        }
    }


    @Action({ rawError: true })
    async refreshListTree(): Promise<any> {
        var promise = API.ListTree.load(this.currentOrganizationId).then(data => {
            ListTreeHelper.setLocalStoreCurrentOrganization(data);
            this.context.commit('loadListTreeIntoStore', data);
        }).catch(err => {
            console.error('Failed refreshing current org tree');
            throw err;
        });
        return promise;
    }

    @Action({ rawError: true })
    async refreshOrganizationsTree(): Promise<any> {

        var promise = API.ListTree.load().then(data => {
            ListTreeHelper.setLocalStoreOrganizationsTree(data);
            this.context.commit('loadOrganizationsTreeIntoStore', data);
        }).catch(err => {
            console.error('Failed refreshing all user orgs tree');
            throw err;
        });
        return promise;
    }

    @Action({ rawError: true })
    async updateCurrentFolder(payload: { organizationId: string | null, folderId: string | null }) {
        this.context.commit('updateCurrentFolderIntoStore', payload);
        TrackingUtils.UpdatePathContext();
    }

    @Action({ rawError: true })
    async updateCurrentList(payload: { listId: string, allowSurvey: boolean }) {
        var promise = payload.allowSurvey
            ? API.List.loadForSurvey(payload.listId)
            : API.List.load(payload.listId);
        return promise;

    }

    @Action({ rawError: true })
    async updateCurrentListCapabilities(listCapabilities: RowShare.ListCapabilities | null) {
        this.context.commit('loadCurrentListCapabilitiesIntoStore', listCapabilities);
    }

    @Action({ rawError: true })
    async updateCurrentListDisplayedRows(displayedRows: RowShare.DisplayedRows | null) {
        this.context.commit('loadCurrentListDisplayedRowsIntoStore', displayedRows);
    }

    @Action({ rawError: true })
    async updateCurrentSiteLogos(orgId: string | null) {
        this.context.commit('loadCurrentLogosIntoStore', { fullSiteLogoPath: null, smallSiteLogoPath: null });
        if (orgId) {
            var org = await API.Organization.load(orgId);
            if (org) {
                this.context.commit('loadCurrentLogosIntoStore', { fullSiteLogoPath: org.FullSiteLogoPath, smallSiteLogoPath: org.SmallSiteLogoPath });
            }
        }
    }

    @Action({ rawError: true })
    async refreshOrganizationListTree(organizationId: string): Promise<any> {
        var enforce: boolean | null = false;
        if (organizationId !== this.currentOrganizationId) {
            this.loadCurrentOrganizationIdIntoStore(organizationId); // in case of cross org action like clone list
        }

        return this.refreshListTreeIfMissing(true); // current organisation full tree refresh
    }

    // reload the list tree for a given org if it has not already been reloaded in the last 30 seconds
    @Action({ rawError: true })
    async refreshOrganizationListTreeIfMissingOrOutdated(organizationId: string): Promise<any> {
        var enforce: boolean | null = false;
        if (organizationId !== this.currentOrganizationId) {
            this.loadCurrentOrganizationIdIntoStore(organizationId); // in case of cross org action like clone list
            enforce = true;
        }
        return this.refreshListTreeIfMissing(enforce);

    }

    @Action({ rawError: true })
    async createFolder(folderAndOrg: any): Promise<any> {
        let createdFolder = await API.Folder.create(folderAndOrg.orgId, folderAndOrg.folderName, folderAndOrg.folderId);

        if (createdFolder)
            this.refreshListTree();
    }

    @Action({ rawError: true })
    async renameFolder(folderAndOrg: any): Promise<any> {
        await API.Folder.rename(folderAndOrg.folderName, folderAndOrg.folderId, folderAndOrg.parentFolderId);
        this.refreshListTree();
    }

    @Action({ rawError: true })
    async removeFolder(deleteInfo: any): Promise<any> {
        await API.Folder.moveToRecycleBin(deleteInfo.folderId);
        if (deleteInfo.recycleBin !== null)
            this.refreshListTree();
    }

    @Action({ rawError: true })
    async setInheritAccess(inheritConfig: any): Promise<any> {
        let modifiedFolder: RowShare.ListTreeFolder | null =
            await API.Folder.setInheritAccessMode(inheritConfig);
        if (modifiedFolder)
            this.refreshListTree();
    }

    @Action({ rawError: true })
    async renameList(renameInfo: any): Promise<any> {
        await API.List.save(renameInfo.renamedList);
        this.refreshListTree();
    }

    @Action({ rawError: true })
    async moveList(moveInfo: any): Promise<any> {
        let result = await API.List.move(moveInfo.listToMove);
        if (result != null) {
            this.refreshOrganizationListTree(moveInfo.organizationId); //may be cross org
            return result;
        }
    }

    @Action({ rawError: true })
    async moveToRecycleBin(moveToRecycleBinInfo: any): Promise<any> {
        let result = await API.List.moveToRecycleBin(moveToRecycleBinInfo.listId);
        if (result)
            this.refreshListTree();
    }

    @Action({ rawError: true })
    async emptyRecycleBin(infoToEmptyRecycleBin: any): Promise<any> {
        await API.Folder.emptyRecycleBin(infoToEmptyRecycleBin.recycleBinId);
        this.refreshListTree();
    }

    @Action({ rawError: true })
    async cloneList(payload: { copyInfo: RowShare.CopyListInfo, organizationId: string }) {
        let result = await API.List.clone(payload.copyInfo);
        if (result != null) {
            this.refreshOrganizationListTree(payload.organizationId); //may be not in same organization
            return result;
        }
    }

    @Action({ rawError: true })
    async deleteList(payload: { listId: string, organizationId: string }) {
        let result = await API.List.delete(payload.listId);
        if (result)
            this.refreshListTree();
    }

    @Action({ rawError: true })
    refreshList(list: RowShare.List | null) {
        if (!list) {
            return;
        }
        let isPublicList = list.AllowPublic;
        let previousOrgId = localStorage.getItem("LastOrganizationId");
        let This = this;

        if (isPublicList && !this.organizationsTree?.Organizations.find(o => o.Id === list.OrganizationId)) {
            //in case of public list , user can not have access to the list organisation and this is not an error
            //but we can not load  organization tree related to the list organization
            //we refresh with previous one
            if (previousOrgId) {
                this.loadCurrentOrganizationIdIntoStore(previousOrgId);
                this.refreshOrganizationListTreeIfMissingOrOutdated(previousOrgId)
                    .then(() => {
                        This.refreshListPathAndStore(list);
                    })
                    .catch(() => {
                        //even older organization is not accessible any more
                        //choosing another one
                        ListTreeHelper.resetLocalStoreCurrentOrganization();
                        let otherorg: RowShare.ListTreeOrganization | undefined;
                        if (This.organizationsTree &&
                            This.organizationsTree.Organizations.length > 0) {
                            otherorg = This.organizationsTree.Organizations.find(o => o.Id !== previousOrgId);
                        }
                        if (otherorg) {
                            This.loadCurrentOrganizationIdIntoStore(otherorg.Id);
                            This.refreshOrganizationListTreeIfMissingOrOutdated(otherorg.Id)
                                .then(() => {
                                    This.refreshListPathAndStore(list);
                                });
                        }
                        else //none
                        {
                            This.refreshListPathAndStore(list);
                        }
                    }
                    );
            }
            else {
                //user has no organization at all but can see public list
                this.refreshListPathAndStore(list);
            }
            return;
        }

        //user may have several organizations and list to display not from default or previous displayed organization

        if (list.OrganizationId !== previousOrgId || !this.listTree) {
            this.loadCurrentOrganizationIdIntoStore(list.OrganizationId);
        }

        this.refreshOrganizationListTreeIfMissingOrOutdated(list.OrganizationId)
            .then(() => {
                let existent = ListTreeHelper.IsKnownList(this.listTree, list.Id);
                this.refreshListTreeIfMissing(!existent).then(
                    () => {
                        this.refreshListPathAndStore(list);
                    }
                );
            });
    }

    @Action({ rawError: true })
    refreshListPathAndStore(list: RowShare.List) {
        this.context.commit('updateCurrentListPathIntoStore', list.Id);
        this.context.commit('loadCurrentListIntoStore', list);
        TrackingUtils.UpdatePathContext();
    }
}

export const ListTreeModule = getModule(ListTreeStore)


