import TimeSpan from "./TimeSpan"
import { ApplicationModule } from "@/store/ApplicationStore";
export class Utils {

    static nameof<T>(key: keyof T, instance?: T): keyof T {
        return key;
    }

    static isUndefined(obj: any): obj is undefined {
        return typeof (obj) === "undefined";
    }

    static isNullOrUndefined(obj: any): obj is undefined | null {
        return Utils.isUndefined(obj) || obj === null;
    }

    static isFunction(obj: any): obj is Function {
        return typeof (obj) === "function";
    }

    static isObject(obj: any): obj is object {
        return obj !== null && typeof (obj) === "object";
    }

    static isNumber(obj: any): obj is number {
        return typeof (obj) === "number" && !isNaN(obj);
    }

    static isString(obj: any): obj is string {
        return typeof (obj) === "string";
    }

    static isBoolean(obj: any): obj is boolean {
        return typeof (obj) === "boolean";
    }

    static isDate(obj: any): obj is Date {
        return obj instanceof Date;
    }

    static isHtmlString(htmlString: string) : boolean {
        if(htmlString == '' || htmlString == null){
            return false;
        }
        return /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(htmlString);
    }

    static isGuid(obj: any): obj is string {
        if (!Utils.isString(obj)) {
            return false;
        }

        // the following pattern does not comply with the RFC4122 but it validates the Guids generated by the newGuid() method below
        const guidPattern = /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{12}$/i
        // the correct pattern should be
        // /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-5][0-9a-f]{3}-?[089ab][0-9a-f]{3}-?[0-9a-f]{12}$/i
        return guidPattern.test(obj);
    }

    private static readonly emptyGuid = "00000000000000000000000000000000";

    static isEmptyGuid(guid: string | null | undefined): guid is (null | undefined | "" | "00000000000000000000000000000000") {
        return Utils.isNullOrEmpty(guid) || guid === Utils.emptyGuid;
    }

    static isArray(obj: any): obj is Array<any> {
        return Array.isArray(obj);
    }

    static isJSON(obj: any): obj is string {
        try {
            JSON.parse(obj);
            return true;
        }
        catch{
            return false;
        }
    }

    static isPromiseLike(obj: any): obj is PromiseLike<any> {
        return Utils.isObject(obj) && Utils.isFunction((<any>obj).then);
    }

    static isArrayLike<T>(obj: any): obj is ArrayLike<T> {
        return Utils.isObject(obj) && ("length" in obj);
    }

    static equalsIgnoreCase(str1: string, str2: string) {
        if (Utils.isNullOrUndefined(str1) && Utils.isNullOrUndefined(str2)) {
            return true;
        }

        if (Utils.isNullOrUndefined(str1) || Utils.isNullOrUndefined(str2)) {
            return false;
        }

        return str1.toLowerCase() === str2.toLowerCase();
    }

    static endsWith(str: string, suffix: string): boolean {
        return str.indexOf(suffix, str.length - suffix.length) !== -1;
    }

    static nullify(value: string | null, trim: boolean): string | null {
        if (Utils.isNullOrEmpty(value)) {
            return null;
        }

        if (trim) {
            value = value.trim();
        }

        if (Utils.isNullOrEmpty(value)) {
            return null;
        }

        return value;
    }

    static isNullOrWhiteSpace(value: string | null | undefined) {
        if (this.isNullOrUndefined(value))
            return true;

        value = value.trim();
        return value === "";
    }

    static isNullOrEmpty(value: string | null | undefined): value is null | undefined | "" {
        if (this.isNullOrUndefined(value))
            return true;

        return value === "";
    }

    //static removeDiacritics(value: string) {
    //    return value.replace(/[^A-Za-z0-9]/g, function (x) { return (<any>NormalizationFormKDMap)[x] || x; })
    //}

    static htmlEncode(value: string | null | undefined) {
        if (Utils.isNullOrUndefined(value)) {
            return "";
        }

        return String(value)
            .replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }

    static parseString(obj: any): string | null {
        if (Utils.isNullOrUndefined(obj)) {
            return null;
        }

        if (Utils.isString(obj)) {
            return obj;
        }

        return String(obj);
    }

    static parseNumber<T>(obj: any, defaultValue: T): number | T {
        if (Utils.isNullOrUndefined(obj)) {
            return defaultValue;
        }

        if (Utils.isNumber(obj)) {
            return obj;
        }

        if (Utils.isString(obj)) {
            const f = parseFloat(obj);
            if (!isNaN(f)) {
                return f;
            }

            const i = parseInt(obj, 10);
            if (!isNaN(i)) {
                return i;
            }
        }

        return defaultValue;
    }

    static parseBoolean<T>(obj: any, defaultValue: T): boolean | T {
        if (Utils.isBoolean(obj)) {
            return obj;
        }

        if (Utils.isNumber(obj)) {
            return obj !== 0;
        }

        if (Utils.isString(obj)) {
            let upper = obj.toUpperCase();
            switch (upper) {
                case "TRUE":
                case "1":
                    return true;

                case "FALSE":
                case "0":
                    return false;
            }
        }

        return defaultValue;
    }

    static parseInteger<T>(str: string, defaultValue: T): number | T {
        let parsedValue = Utils.parseNumber(str, NaN);
        if (isNaN(parsedValue)) {
            return defaultValue;
        }

        if (Utils.isNumber(parsedValue)) {
            return Math.trunc(parsedValue);
        }

        return defaultValue;
    }

    static parseDateTime<T>(value: any, defaultValue: T): Date | T {
        if (Utils.isDate(value)) {
            return value;
        }

        if (Utils.isString(value)) {
            // Format /Date(<ticks>+<offset>)/
            // ticks  = ticks Unix Epoch => milliseconds since 1st january 1970
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
            let a = /\/Date\(((?:-|\+)?\d*)((?:-|\+)\d*)?\)\//.exec(value);
            if (a) {
                var date = new Date(+a[1]);
                // if our value didn't include an offset then we bascially want to display the same date for every user whatever his / her timezone is.
                // so with the following line we want to "cancel" the local timezone
                // new Date(ticks) = new Date(Ticks + getTimeZone) => We need to remove the timezone
                date = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
                if (a[2]) {
                    // if serveur has provided an offset, then we want to take this offset in account
                    // this is the case for exemple for row creation date for row last modification date, for reminders, etc.
                    var tz = +a[2]; // +0200, -0130
                    var offset = (tz / 100 * 60) + (tz % 100); // minutes
                    return new Date(date.getTime() + offset * 60000);
                }
                return date;
            }

            // Format 0000-00-00T00:00:00
            // Format 0000-00-00 00:00:00
            // Format 0000/00/00 00:00:00
            let b = /(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})((T| )(\d{1,2}):(\d{1,2})(:(\d{1,2})))?/.exec(value);
            if (b) {
                // ["2012-01-01T01:02:03", "2012", "-", "01", "-", "01", "T01:02:03", "T", "01", "02", ":03", "03"]
                let year = Utils.parseInteger(b[1], 0);
                let month = Utils.parseInteger(b[3], 1) - 1; // 0 based
                let day = Utils.parseInteger(b[5], 0);
                let hours = Utils.parseInteger(b[8], 0);
                let minutes = Utils.parseInteger(b[9], 0);
                let seconds = Utils.parseInteger(b[11], 0);

                return new Date(year, month, day, hours, minutes, seconds, 0);
            }
        }

        return defaultValue;
    }

    static parseTime<T>(value: any, defaultValue: T): TimeSpan | T {
        if (value instanceof TimeSpan) {
            return value;
        }

        if (Utils.isString(value)) {
            const a = /(\d+):(\d+)(?::(\d+))?/.exec(value);
            if (a) {
                const hours = parseInt(a[1], 10);
                const minutes = parseInt(a[2], 10);
                const seconds = parseInt(a[3], 10);
                return TimeSpan.fromTime(hours, minutes, seconds);
            }
        }

        if (typeof value === "number" && isFinite(value)) {
            return TimeSpan.fromTicks(value);
        }

        const ticks = parseInt(value, 10);
        if (isFinite(ticks)) {
            return Utils.parseTime(ticks, defaultValue);
        }

        return defaultValue;
    }

    static limitValue<T>(value: T, min: T, max: T): T {
        if (value < min) {
            return min;
        }

        if (value > max) {
            return max;
        }

        return value;
    }

    static groupBy<T, K extends keyof T>(items: T[], propertyName: K): Map<T[K], T[]> {
        let result = new Map<T[K], T[]>();
        for (const item of items) {
            const key = item[propertyName];

            let values = result.get(key);
            if (!values) {
                values = [];
                result.set(key, values);
            }

            values.push(item);
        }

        return result;
    }

    static delay(timeout: number) {
        return new Promise((resolve, reject) => {
            setTimeout(function () {
                resolve(null);
            }, timeout);
        });
    }

    /*static throttle(wait: number, func: (...args: any[]) => any, immediate: boolean = false) {
        let context: any;
        let args: IArguments;
        let timeout: number | null = null;
        let result: any;
        var previous: any = 0;
        var later = () => {
            previous = new Date;
            timeout = null;
            result = func.apply(context, args);
        };
        return function (this: any, ...args: any[]) {
            var now = new Date();
            if (!previous && immediate === false) {
                previous = now;
            }

            var remaining = wait - (now.getTime() - previous);
            context = this;
            if (remaining <= 0) {
                if (timeout !== null) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
            } else if (!timeout) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };*/

    static debounce(wait: number, func: (...args: any[]) => any, immediate: boolean = false): (...args: any[]) => any {
        let timeout: number | null = null;
        let result: any;
        return function (this: any, ...args: any[]) {
            var context = this;
            var later = () => {
                timeout = null;
                if (!immediate) {
                    result = func.apply(this, args);
                }
            };
            var callNow = immediate && !timeout;
            if (timeout !== null) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(later, wait);
            if (callNow) {
                result = func.apply(context, args);
            }
            return result;
        };
    };

    static chainPromises<T>(promises: (() => Promise<T>)[]): Promise<T> | null {
        return promises.reduce((prev, current) => prev ? prev.then(() => current()) : current(), <Promise<T> | null>null);
    }

    static newGuid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }

    static newId() {
        return "id" + Utils.newGuid();
    }

    static loadScript(url: string) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = url;
            script.addEventListener("load", e => { resolve(e); });
            script.addEventListener("error", e => { reject(e); });

            const head = document.head;
            if(head)
                head.insertBefore(script, head.firstChild);
        });
    }

    static isImageMimeType(mimeType: string) {
        return /^image\//i.test(mimeType);
    }

    static camelize(value: string) {
        if (Utils.isNullOrUndefined(value)) {
            return value;
        }

        if (value.length === 0) {
            return value;
        }

        return value[0].toUpperCase() + value.substring(1);
    }

    static getCurrentCulture() {
        let d = document.documentElement;
        if (d && d.lang) {
            return d.lang;
        }

        return "en";
    }

    static async findAsync<T>(array : Array<T>, asyncCallback: any) : Promise<T>{
        const promises = array.map(asyncCallback);
        const results = await Promise.all(promises);
        const index = results.findIndex(result => result);
        return array[index];
    }

    static humanFileSize(bytes: number, frLocale: boolean = false, precision: number = 1) {
        const thresh = 1024;
        const unitsEN = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const unitsFR = [" Ko", " Mo", " Go", " To", " Po", " Eo", " Zo", " Yo"];

        let units = frLocale ? unitsFR : unitsEN;

        if (Math.abs(bytes) < thresh) {
            return bytes + (frLocale ? " o" : "B");
        }

        let unitIndex = -1;
        do {
            bytes = bytes / thresh;
            unitIndex += 1;
        } while (Math.abs(bytes) >= thresh && unitIndex < units.length - 1);

        return bytes.toFixed(precision) + units[unitIndex];
    }

    static validateEmail(email: string) {
        //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // Prevent non ASCII emails (cf. bug #10821)
        var re = /^[a-zA-Z0-9.!$&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return re.test(String(email).toLowerCase());
    }

    static validateLoginPassword(pwd: string) {
       var re = new RegExp(ApplicationModule.loginPasswordRegex!);
       return re.test(String(pwd));
    }

    

    static getCookie(cname: string) {
        try {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
        }
        catch { };
        return "";
    }

    static normalizeString(value: string | null | undefined): string | null | undefined {
        if(!value) {
            return value;
        }
        return value.replace(/\s/g, '')
                    .replace(/[àáâãäå]/g, 'a')
                    .replace(/æ/g, 'ae')
                    .replace(/ç/g, 'c')
                    .replace(/[èéêë]/g, 'e')
                    .replace(/[ìíîï]/g, 'i')
                    .replace(/ñ/g, 'n')
                    .replace(/[òóôõö]/g, 'o')
                    .replace(/œ/g, 'oe')
                    .replace(/[ùúûü]/g, 'u')
                    .replace(/[ýÿ]/g, 'y')
                    .replace(/\W/g, '')
                    .toLowerCase();
    }

    public static escapeHTML(value: string) {
        if(!value) {
            return value;
        }
        return value.replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#039;");
    }
}
