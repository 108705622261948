
import { Component, Vue } from 'vue-property-decorator';
import chartRenderer from '@/views/charts/chartRenderer.vue';
import { EventBus } from '@/modules/EventBus';
import { Chart, Event, maximizeChartEventParams, List } from '@/models/RowShare';
import * as API from '@/api/Api';
import { KeyCodes } from '@/utils/KeyCodes';

@Component({name: 'chartDialog', components: { chartRenderer }})
export default class chartDialog extends Vue {
    showMe: boolean = false;
    chartId: string = "";
    chart: Chart | null = null;
    menuState: boolean = false;
    confirmDeletion: boolean = false;
    list: List | null = null;
    updatingName: boolean = false;
    nameUpdateErrors: string = "";
    isEditingName: boolean = false;
    rowIds: string[] | null = null;

    created() {
        EventBus.$on(Event.MAXIMIZE_CHART, this.init);
    }

    destroy() {
        EventBus.$off(Event.MAXIMIZE_CHART, this.init);
    }

    onCancel() {
        this.chartId = "";
        this.showMe = false;
    }

    editChart() {
        if(this.chart) {
            EventBus.$emit(Event.EDIT_CHART, this.chart);
            this.onCancel();
        }
    }

    toggleShared() {
        if(this.chart) {
            this.chart.Shared = !this.chart.Shared;
            API.Chart.save(this.chart).then((chart) =>
             { 
                 EventBus.$emit(Event.REFRESH_CHARTS);
             });
        }
    }

    deleteChart() {
        if(this.chart) {
            API.Chart.remove(this.chart.Id)
                     .then((deleted) => {
                         if(deleted) {
                             EventBus.$emit(Event.REFRESH_CHARTS);
                             this.menuState = false;
                             this.showMe = false;
                         }
                     });
        }
    }

    downloadImage() {
        if(this.imageLink && this.fileName) {
            let a = document.createElement('a');
            a.href = this.imageLink;
            a.download = this.fileName;
            a.click();
        }
        this.menuState = false;
    }

    initMenu() {
        this.confirmDeletion =false;
    }

    saveChartName() {
        if(!this.chart) {
            return;
        }
        this.nameUpdateErrors = "";
        this.updatingName = true;
        API.Chart.save(this.chart).then(chart => {
            if(chart){
                this.chart = chart;
                EventBus.$emit(Event.REFRESH_CHARTS);
            }
            this.isEditingName = false;
        })
        .catch((exc: Error) =>{
            this.nameUpdateErrors = this.$i18n.t('Charts_ErrorSavingName', [exc.message]).toString();
        })
        .finally(() => {
            this.updatingName = false;
        });
    }

    editChartName() {
        if(!this.chart?.Owned) {
            return;
        }
        this.isEditingName = true;
        this.$nextTick(() => {
            let input = <HTMLElement>this.$refs.nameInput;
            if(input) {
                input.focus();
            }
        });
        this.nameUpdateErrors = "";
    }

    onChartNameKeyDown(event: KeyboardEvent) {
        if(event.keyCode === KeyCodes.DOM_VK_RETURN) {
            this.saveChartName();
        }
        else if(event.keyCode === KeyCodes.DOM_VK_ESCAPE) {
            this.isEditingName = false;
            this.nameUpdateErrors = "";
            this.loadChartInfo(true)
            event.stopPropagation();
        }
    }

    async loadChartInfo(useCache: boolean = false) {
        if(!this.chartId) {
            return;
        }
        this.chart = await API.Chart.load(this.chartId, {cache: useCache});
            if(this.chart) {
                this.list = await API.List.load(this.chart.ListId, {cache: useCache});
            }
    }

    async init(event: maximizeChartEventParams) {
        if(!event?.chartId) {
            return;
        }
        this.isEditingName = false;
        this.chartId = event.chartId;
        this.rowIds = event.rowIds;
        this.loadChartInfo();
        this.menuState = false;
        this.confirmDeletion = false;
        this.showMe = true;
    }

    public get title(): string {
        if(this.chart) {
            return this.chart.Name;
        }
        return "";
    }

    public set title(value) {
        if(this.chart) {
            this.chart.Name = value;
        }        
    }

    public get chartShared(): boolean {
        if(this.chart) {
            return this.chart.Shared;
        }
        return false;
    }

    public get imageLink() {
        let renderer = this.$refs.renderer as chartRenderer;
        if(renderer) {
            return renderer.getChartImage();
        }        
    }

    public get fileName() {
        if(this.chart) {
            return this.chart.Name + ".png";
        }
        return "";
    }

    public get canShare(): boolean {
        return this.list?.Owned ?? false;
    }

    public get canRemove(): boolean {
        return this.chart?.Owned ?? false;
    }

    public get canEdit(): boolean {
        return this.chart?.Owned ?? false;
    }

    get hasNotPublicColumn(): boolean {
        return this.chart?.HasNotPublicColumn ?? false;
    }
}
