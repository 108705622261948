
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { ICellRendererParams } from 'ag-grid-community';

    @Component({name: 'memberEmailRenderer'})
    export default class memberEmailRenderer extends Vue{
        params!: ICellRendererParams;
        get addingNew(){
            return (this.params.data.IsAdding);
        }
        get tooltip(){
            if(this.params.data.IsUser)
                return this.params.value;
            else
                return this.params.value + '\n' + this.$i18n.t('MemberGrid_PendingInvitationTooltip').toString()
        }
    }
