var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',{staticClass:"signup-container"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"steps"},[_vm._v(_vm._s(_vm.$t("SignUp_StepTwo")))]),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.$t("SignUp_Title")))]),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('SignUp_PersonalData'))}}),_vm._v(" "),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ passes, invalid }){return [_c('form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('input-text',{attrs:{"inputTextId":'input-1',"inputTextName":_vm.$t('Form_Name'),"placeholder":_vm.$t('Form_Name') + ' *',"inputTextValidationRules":{ required: true },"focusInput":true},model:{value:(_vm.modelSignUp.FirstName),callback:function ($$v) {_vm.$set(_vm.modelSignUp, "FirstName", $$v)},expression:"modelSignUp.FirstName"}}),_vm._v(" "),_c('input-text',{attrs:{"inputTextId":'input-2',"inputTextName":_vm.$t('Form_Surname'),"placeholder":_vm.$t('Form_Surname') + ' *',"inputTextValidationRules":{ required: true }},model:{value:(_vm.modelSignUp.LastName),callback:function ($$v) {_vm.$set(_vm.modelSignUp, "LastName", $$v)},expression:"modelSignUp.LastName"}}),_vm._v(" "),_c('input-password',{attrs:{"inputPasswordId":'input-3',"inputPasswordName":_vm.$t('Form_Password'),"placeholder":_vm.$t('Form_Password') + ' *',"inputPasswordValidationRules":{
                required: true,
                min: 8,
                max: 64, //in addition to regex rule
                regex: _vm.passwordRule
              },"showTooltip":_vm.isNotOnPrem,"tooltipText":_vm.$t('Common_PasswordRules')},model:{value:(_vm.modelSignUp.Password),callback:function ($$v) {_vm.$set(_vm.modelSignUp, "Password", $$v)},expression:"modelSignUp.Password"}}),_vm._v(" "),_c('input-password',{attrs:{"inputPasswordId":'input-4',"inputPasswordName":_vm.$t('Form_ConfirmPassword'),"placeholder":_vm.$t('Form_ConfirmPassword') + ' *',"inputPasswordValidationRules":{
                required: true,
                min: 8,
                max: 64, //in addition to regex rule
                regex: _vm.passwordRule,
                confirmed: _vm.$t('Form_Password')
              },"inputPasswordVid":"confirmPassword"},model:{value:(_vm.modelSignUp.ConfirmPassword),callback:function ($$v) {_vm.$set(_vm.modelSignUp, "ConfirmPassword", $$v)},expression:"modelSignUp.ConfirmPassword"}}),_vm._v(" "),(_vm.errorCreatingAccount)?_c('span',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"required-fields-text",domProps:{"innerHTML":_vm._s(_vm.$t('Form_RequiredFields'))}}),_vm._v(" "),_c('v-btn',{attrs:{"disabled":invalid || _vm.working,"loading":_vm.working,"type":"submit","color":"primary"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fa-chevron-circle-right")]),_vm._v("\n              "+_vm._s(_vm.$t("SignUp_PersonalData_Button"))+"\n            ")],1)],1)]}}])})],1)],1),_vm._v(" "),(_vm.mustShowRecaptcha)?_c('recaptcha'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }