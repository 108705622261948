
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import * as API from '@/api/Api';
    import PageLoadSpinner from '@/views/layouts/LayoutParts/PageLoadSpinner.vue';
    import { Organization } from '@/models/RowShare';
    import { OnPremModule } from '@/store/OnPremStore';
    import { EventBus } from '@/modules/EventBus';
    import * as RowShare from "@/models/RowShare";
    import { Location as RouterLocation } from "vue-router";
    import { ReturnUrlHandler } from '@/views/ReturnUrl';
    import { RouterUtils } from '@/modules/Router';

    @Component({
        name: 'OrganizationInfos',
        components: {PageLoadSpinner}
    })
    export default class OrganizationInfos extends ReturnUrlHandler{
        @Prop() organizationId!: string;
        @Prop() isManageOrganization!:boolean;
        private organization: Organization;
        private showImage:boolean = false;
        private subscriptionTermEndDateUTC:string ="";
        private cbInstance: any;
        private cbPortal: any;

        constructor(){
            super();
            this.organization = new Organization();
            EventBus.$on(RowShare.Event.ORGANIZATION_CHANGED, async() => {
                this.updateOrganization();
            });
        }

        async created(){
            if (this.isNotOnPrem){
                await this.initChargebeeInstance();
            }
            API.Organization.load(this.organizationId).then(res=>{
                if(res){
                    this.organization = res;
                    this.subscriptionTermEndDateUTC = new Date(this.organization.SubscriptionCurrentTermEndDateUtc).toLocaleDateString(navigator.language, {day: 'numeric', month: 'short', year: 'numeric'});
                    if(res.IconPath!=null){
                        this.showImage = true;
                    }
                }
            });
        }

        mounted(){
            if (this.isNotOnPrem){
                this.createChargebeePortal();
            }
        }

        updateOrganization(){
            API.Organization.load(this.organizationId,{cache:false}).then(res=>{
                this.organization = Object.assign({}, res);;
                if(res && res.IconPath!=null){
                    this.showImage = true;
                    let elem = document.getElementById('imgOrganization');
                    if(elem){
                        elem.setAttribute("src",this.getIconPath);
                    }
                }
                else{
                    this.showImage = false;
                }                   
            })
        }

        get isNotOnPrem(){
            return !OnPremModule.isOnPrem;
        }

        get getIconPath():string{
             if (this.organization && this.organization.IconPath) {
                return (
                    "/" + this.organization.IconPath + "?_ssb_img=S;w:64;h:64"+ Math.random()
                );
            }
            return ""
        }

        get getShowImage():boolean{
            return this.showImage;
        }

        showPaymentSources(){
            if (!this.isNotOnPrem)
                return;
            this.openBillingPortal(RowShare.PortalSections.PAYMENT_SOURCES)
        }

        showSubscriptionDetails(){
            if (!this.isNotOnPrem)
                return;
            this.openBillingPortal(RowShare.PortalSections.SUBSCRIPTION_DETAILS);
        }

        async initChargebeeInstance(){
            if (!this.isNotOnPrem)
                return;

            if(!this.cbInstance)
            {
                let scriptPromise = this.$loadScript('https://js.chargebee.com/v2/chargebee.js');
                let siteNamePromise = API.Configuration.getChargebeeSiteName();
                
                let res = await Promise.all([scriptPromise, siteNamePromise]);
                let cbSiteName = res[1];
    
                this.cbInstance = (<any>window).Chargebee.init({ site: cbSiteName, enableGATracking: true, enableGTMTracking: true });
            }
        }

        async createChargebeePortal(){
            if (!this.isNotOnPrem)
                return;

            if(!this.cbInstance){
                await this.initChargebeeInstance();
            }
            this.cbPortal = this.cbInstance.createChargebeePortal();

            let component = this;
            this.cbInstance.setPortalSession(function () {
                return API.Organization.loadPortalConfig(component.organizationId);
            });
        }

        async openBillingHistory() {
            if (!this.isNotOnPrem)
                return;

            this.cbPortal.openSection({
                sectionType: (<any>window).Chargebee.getPortalSections().BILLING_HISTORY
            });        
        }

        async openBillingPortal(section: RowShare.PortalSections | null){
            if (!this.isNotOnPrem)
                return;
                
            let component = this;
            let currentSubscription = await API.Organization.getCurrentSubscriptionId(this.organizationId);
            if(currentSubscription){
                var callbacks = {
                    close: function () {
                        component.updateOrganization();
                    }};
                var sectionInfo = {
                    sectionType: section,
                    params: {subscriptionId: currentSubscription.replaceAll('"', '')}
                    }
                this.cbPortal.open(callbacks, sectionInfo);
            }
        }

        onUpdateUserCountClick() {
            EventBus.$emit(RowShare.Event.UPDATE_SUBSCRIPTION_REQUEST, <RowShare.updateSubscriptionRequestEventParams> { organizationId: this.organizationId});            
        }

        get checkOutButtonLabel():string{
            if(this.organization.InTrial || this.organization.SubscriptionIsFree){
                return this.$t("ManageOrganization_UpgradeToPaidPlan").toString();
            }
            else return this.$t("ManageOrganization_UpgradeDowngrade").toString();
        }

        get checkoutLink(){
            const queryStringReturnUrl = RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl");

            return <RouterLocation>{
                name: "SubscriptionCheckOut",
                params: { organizationId: this.organizationId },
                query: {
                    ReturnUrl: queryStringReturnUrl ? queryStringReturnUrl : this.$route.fullPath
                }
            };
        }

        get contactLink(){
            return <RouterLocation>{
                name: "Contact",
                query: {
                    ReturnUrl: RouterUtils.getCaseInsentiveQuery(this.$route, "ReturnUrl"),
                }
            };
        }

    }
