import { ApiUtils, IRequestOptions } from "./ApiUtils"
import * as RowShare from "@/models/RowShare"
import { AddOrganization } from '@/models/RowShare';

export class Organization {
    static load(id: string, options: IRequestOptions | null = null) 
    : Promise<RowShare.Organization | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/organization/load/" + ApiUtils.encodeSegment(id), options)
            .then(response => response.nullableObject(RowShare.Organization));
    }

    static loadall(options: IRequestOptions | null = null) 
    : Promise<Array<RowShare.Organization> | null> {
        options = ApiUtils.createRequestOptions({ cache: true }, options);
        return ApiUtils.apiGet("/organization/loadall", options)
            .then(response => response.objects(RowShare.Organization));
    }

    static loadGroups(organizationId: string, options: IRequestOptions | null = null) 
    : Promise<Array<RowShare.Group> | null> {
        options = ApiUtils.createRequestOptions({ cache: true}, options);
        return ApiUtils.apiPostJson("/group/loadforparent/" + organizationId, options)
            .then(response=> response.objects(RowShare.Group))
    }

    static loadSecurityPrincipal(organizationId: string, options: IRequestOptions | null = null) 
    : Promise<Array<RowShare.FolderAccess> | null> {
        options = ApiUtils.createRequestOptions({ cache: true}, options);
        return ApiUtils.apiPostJson("/securityprincipal/loadforparent/" + organizationId, options)
            .then(response=> response.objects(RowShare.FolderAccess))
    }

    static trialSetup(organization: AddOrganization, options: IRequestOptions | null = null) 
    : Promise<RowShare.Organization | null> {
        options = ApiUtils.createRequestOptions({ cache: true}, options);
        return ApiUtils.apiPostJson("/organization/trial", organization, options)
            .then(response=> response.object(RowShare.Organization))
    }

    static getLimits(organizationId: string, options: IRequestOptions | null = null) 
    : Promise<Array<RowShare.SubscriptionLimit> | null> {
        options = ApiUtils.createRequestOptions({ cache: false}, options);
        return ApiUtils.apiGet("/organization/GetLimits/" + ApiUtils.encodeSegment(organizationId), options)
            .then(response=> response.objects(RowShare.SubscriptionLimit))
    }

    static loadPortalConfig(id: string, options: IRequestOptions | null = null)
    : Promise<RowShare.PortalConfig | null> {
        var url = "/organization/LoadPortalConfig/" + id;
        return ApiUtils.apiGet(url, options).then(response => response.object(RowShare.PortalConfig));
    }
    
    static getMonthlyQuotation(id: string, usercount: number, currency: string, planId: string | null = null, options: IRequestOptions | null = null)
    : Promise<RowShare.Quotation | null> {
        var data = {organizationId: id, userCount: usercount, currency: currency, planId: planId};
        var url = "/organization/GetMonthlyQuotation";
        return ApiUtils.apiPostJson(url, data, options).then(response => response.object(RowShare.Quotation));
    }

    static getAnnualQuotation(id: string, usercount: number, currency: string, planId: string | null = null, options: IRequestOptions | null = null)
    : Promise<RowShare.Quotation | null> {
        var data = {organizationId: id, userCount: usercount, currency: currency, planId: planId};
        var url = "/organization/GetAnnualQuotation";
        return ApiUtils.apiPostJson(url, data, options).then(response => response.object(RowShare.Quotation));
    }

    static loadCheckoutFromQuotation(quotation: RowShare.Quotation, options: IRequestOptions | null = null) {
        var url = "/organization/LoadCheckoutFromQuotation";
        return ApiUtils.apiPostJson(url, quotation, options).then(response => response.object(RowShare.HostedPage));
    }

    static cancelSubscription(orgId: string, options: IRequestOptions | null = null)
    :Promise<boolean | null> {
        var url = "/organization/CancelSubscription/" + orgId;
        return ApiUtils.apiGet(url, options).then(response => response.json<boolean>());
    }

    static save(organization: Organization, options: IRequestOptions | null = null) 
    : Promise<RowShare.Organization | null> {
        options = ApiUtils.createRequestOptions({ cache: true}, options);
        return ApiUtils.apiPostJson("/organization/save", organization, options)
            .then(response=> response.object(RowShare.Organization))
    }

    static saveWithFile(organization: RowShare.Organization, file: Object | FileList | File | Blob | null, options: IRequestOptions | null = null) 
    : Promise<RowShare.Organization | null> {
        options = ApiUtils.createRequestOptions({ cache: true}, options);
        return ApiUtils.apiPostJsonWithFiles(`/organization/save/${organization.Id}`, organization, file, options)
            .then(response=> response.object(RowShare.Organization))
    }

    static getCurrentSubscriptionId(orgId: string, options: IRequestOptions | null = null)
    :Promise<string | null> {
        options = ApiUtils.createRequestOptions({cache: true}, options);
        let url = '/organization/CurrentSubscriptionId/' + orgId;
        return ApiUtils.apiGet(url, options).then(response => response.json<string>() ?? null);
    }

    static getCurrentPlanId(orgId: string, options: IRequestOptions | null = null)
    :Promise<string | null> {
        options = ApiUtils.createRequestOptions({cache: true}, options);
        let url = '/organization/CurrentPlanId/' + orgId;
        return ApiUtils.apiGet(url, options).then(response => response.json<string>() ?? null);
    }

    static deleteSubscription(orgId: string, nameConfirmation: string, options: IRequestOptions | null = null)
    :Promise<boolean> {
        options = ApiUtils.createRequestOptions({ cache: true}, options);
        return ApiUtils.apiPostJson("/organization/delete", {OrgId: orgId, NameConfirmation: nameConfirmation}, options)
            .then(response=> response.json<boolean>() ?? false)
    }

    static getConfiguratorPlans(options: IRequestOptions | null = null)
    :Promise<Array<RowShare.Plan> | null> {
        options = ApiUtils.createRequestOptions({ cache: true}, options);
        return ApiUtils.apiGet("/organization/getConfiguratorPlans", options)
            .then(response => response.objects(RowShare.Plan));
    }

    static GetInvitationMessage(id: string, options: IRequestOptions | null = null) : Promise<string | null> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/organization/GetInvitationMessage/" + ApiUtils.encodeSegment(id), options)
            .then(response => response.json<string>() ?? null);
    }


    static SetInvitationMessage(orgId: string, message: string | null, options: IRequestOptions | null = null) : Promise<boolean> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiPostJson("/organization/SetInvitationMessage", {OrgId: orgId, Message: message}, options)
            .then(response=> response.json<boolean>() ?? false)
    }

    static refreshBillingInfos(orgId: string, options: IRequestOptions | null = null)
    :Promise<boolean> {
        options = ApiUtils.createRequestOptions({ cache: false }, options);
        return ApiUtils.apiGet("/organization/refreshbillinginfos/" + ApiUtils.encodeSegment(orgId), options)
                       .then(response => response.json<boolean>() ?? false);
    }

    static estimateSubscriptionUpdate(ordId: string, userCount: number, options: IRequestOptions | null = null)
    :Promise<RowShare.ApiResult<RowShare.SubscriptionEstimate> | null> {
        options = ApiUtils.createRequestOptions({cache: false}, options);
        var data = { customerId: ordId, userQuantity: userCount};
        return ApiUtils.apiPostJson("/organization/UpdateSubscriptionEstimate", data, options)
                       .then(response => response.json<RowShare.ApiResult<RowShare.SubscriptionEstimate>>());
    }

    static updateSubscription(orgid: string, userCount: number, options: IRequestOptions | null = null)
    :Promise<RowShare.ApiResult<boolean> | null> {
        options = ApiUtils.createRequestOptions({cache: false, clearCache: true}, options);
        var data = {customerId: orgid, userQuantity: userCount};
        return ApiUtils.apiPostJson("/organization/UpdateSubscription", data, options)
                       .then(response => response.json<RowShare.ApiResult<boolean>>());
    }

}