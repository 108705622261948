
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ConfirmationDialog from '@/views/components/ConfirmationDialog.vue';
import { Location as RouterLocation } from "vue-router";
import { ListTreeModule } from '@/store/ListTreeStore';
import { ListTreeHelper } from '@/utils/ListTreeHelper';

@Component({
    name: 'DeleteFolderAction',
    components: { ConfirmationDialog }
})
export default class DeleteFolderAction extends Vue {

    @Prop({ default: false }) canDelete!: boolean;
    @Prop({ default: '' }) organizationId!: string;
    @Prop({ default: '' }) folderId!: string;
    @Prop({ default: '' }) parentFolderId!: string;
    @Prop({ default: '' }) newName!: string;

    isDeleting: boolean = false;

    private recycleBinModal = false;
    deleteFolderDialogName: string = 'delete-folder-modal';
    deleteFolderServerErrorMessage: string = '';

    @Watch('recycleBinModal')
    resetErrorMessage() {
        this.deleteFolderServerErrorMessage = '';
    }

    get parentTableLocation(): RouterLocation {
        return <RouterLocation>{
            name: "MyTables",
            params: {
                organizationId: this.organizationId,
                folderId: this.parentFolderId ? this.parentFolderId : null
            }
        };
    }

    get dialogAlreadyExistInDOM(): boolean {
        return !!document.getElementById(this.deleteFolderDialogName);
    }
    get getDescription() {
        return this.$i18n.t('MyTables_DeleteFolderModal_Description', [this.newName]).toString();
    }
    async moveFolderToRecycleBin(): Promise<void> {
        this.isDeleting = true;
        this.deleteFolderServerErrorMessage = "";
        try {
            if (this.folderId && ListTreeModule.listTree ) {
                let recycleBin = ListTreeHelper.getRecycleBin(ListTreeModule.listTree, this.organizationId)
                let deleteInfo = {
                    folderId: this.folderId,
                    organizationId: this.organizationId,
                    parentFolderId: this.parentFolderId,
                    recycleBin: recycleBin ? recycleBin.Id : null
                };
                await ListTreeModule.removeFolder(deleteInfo).then((res: any) => {
                    this.recycleBinModal = false;
                    this.isDeleting = false;
                    //the route to redirect has to be that one before updating the recycle bin because when doing that, 
                    //the parent folder changes and the redirection is not well done. That's why deleteInfo.parentFolderId is used.
                    let newRoute = <RouterLocation>{
                        name: "MyTables",
                        params: {
                            organizationId: this.organizationId,
                            folderId: deleteInfo.parentFolderId
                        }
                    }
                    this.$router.push(newRoute);
                }).catch((error: any) => {
                    this.isDeleting = false;
                    let errorMessage: string;
                    if (error.Exception != undefined) {
                        errorMessage = error.Exception.Message;
                    }
                    else {
                        errorMessage = error.message ? error.message : error.Message;
                    }
                    this.deleteFolderServerErrorMessage = errorMessage;
                })
            }
            else {
                throw new Error("Failed to delete folder");
            }
        }
        catch (error: any) {
            this.isDeleting = false;
            this.deleteFolderServerErrorMessage = error;
        }
    }

    cancelled() {
        this.recycleBinModal = false;
    }

}
