import { Utils } from "./Utilities";

export const enum SettingNames {
    configuration = "configuration",
    information = "information",
    columnsConfiguration = "columnsConfiguration"
}

export class Settings {
    static get(ns: string, name: SettingNames) {
        const finalName = ns + "." + name;
        try {
            return localStorage.getItem(finalName);
        }
        catch (e) {
            return null;
        }
    }

    static set(ns: string, name: SettingNames, value: boolean | number | string | null): boolean | null {
        const finalName = ns + "." + name;
        try {
            if (value === null) {
                localStorage.removeItem(finalName);
                return false;
            } else {
                localStorage.setItem(finalName, String(value));
                return true;
            }
        }
        catch (e) {
            return null;
        }
    }

    static getJson(ns: string, name: SettingNames) {
        const value = Settings.get(ns, name);
        if (Utils.isNullOrUndefined(value)) {
            return null;
        }

        try {
            return JSON.parse(value);
        } catch (ex) {
            return null;
        }
    }

    static setJson(ns: string, name: SettingNames, value: any | null): boolean | null {
        return Settings.set(ns, name, JSON.stringify(value));
    }


    static getColumnProperty<T>(listId: string, columnId: string, prop: string): T | null {
        let columnsConfiguration = Settings.getJson(listId, SettingNames.columnsConfiguration);
        if (columnsConfiguration) {
            const columnConfiguration = columnsConfiguration[columnId];
            if (columnConfiguration) {
                return columnConfiguration[prop];
            }
        }

        return null;
    }

    static setColumnProperty<T>(listId: string, columnId: string, prop: string, value: T) {
        let columnsConfiguration = Settings.getJson(listId, SettingNames.columnsConfiguration);
        if (!columnsConfiguration)
            columnsConfiguration = {};

        let columnConfiguration = columnsConfiguration[columnId];
        if (!columnConfiguration) {
            columnConfiguration = {};
            columnsConfiguration[columnId] = columnConfiguration;
        }

        columnConfiguration[prop] = value;
        Settings.setJson(listId, SettingNames.columnsConfiguration, columnsConfiguration);
    }
}

