
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import * as RowShare from '@/models/RowShare';
    import * as API from '@/api/Api';
    import { ICellEditorParams } from 'ag-grid-community';

    @Component({name: 'deleteRowRender'})
    export default class deleteRowRenderer extends Vue{
        beforeMount(){

        }
    }
