
import { Hyperlink } from '@/models/RowShare';
import { ICellEditorParams } from 'node_modules/ag-grid-community/main';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({name: "HyperlinkEditor"})
export default class HyperlinkEditor extends Vue {
    params!: ICellEditorParams;
    link: Hyperlink | null = null;

    isPopup(): boolean {
        return this.canEditValue;
    }

    mounted() {
        this.refreshDisplay();
        this.$nextTick(() => {
            (<HTMLElement>this.$refs.urlField)?.focus();
            if (this.params.eventKey && this.params.eventKey.length == 1 && this.link?.displayName) {
                (<HTMLElement>this.$refs.displayNameField)?.focus();
            }
        });
    }

    refreshDisplay() {
        let val = '';
        
        if (this.params.eventKey === "Delete") // suppr 
            val = '';
        else if (this.params.eventKey && this.params.eventKey.length == 1)
            val = this.params.value + this.params.eventKey;
        else
            val = this.params.value ?? '';

        this.link = new Hyperlink(val);
    }

    onUrlTabPress(event: KeyboardEvent) {
        if(!event.shiftKey) {
            (<HTMLElement>(<Vue>this.$refs.displayNameField).$el).focus();
            event.stopPropagation();
        }
    }

    onNameTabPress(event: KeyboardEvent) {
        if(event.shiftKey) {
            (<HTMLElement>(<Vue>this.$refs.urlField).$el).focus();
            event.stopPropagation();
        }
    }

    get urlLabel(): string {
        return this.$i18n.t("HyperlinkEditor_urlLabel").toString();
    }

    get nameLabel(): string {
        return this.$i18n.t("HyperlinkEditor_nameLabel").toString();
    }

    get linkUrl(): string {
        if(!this.link) {
            return "";
        }
        return this.link.url ?? "";
    }

    set linkUrl(value: string) {
        if(this.link) {
            this.link.url = value;
        }
    }

    get formattedLinkUrl(): string {
        if(this.link != null) {
            if(this.link.url == "") {
                return "";
            }
            return this.link.url.startsWith('http') ? this.link.url : 'http://' + this.link.url;
        }
        return "";
    }

    get linkName(): string {
        if(!this.link) {
            return "";
        }
        return this.link.displayName ?? "";
    }

    set linkName(value: string) {
        if(this.link) {
            this.link.displayName = value;
        }
    }

    get urlPlaceholder() {
        return this.$i18n.t("HyperlinkEditor_urlPlaceholder").toString();
    }

    get namePlaceholder() {
        return this.$i18n.t("HyperlinkEditor_namePlaceholder").toString();
    }

    get disableLink(): boolean {
        return this.linkUrl == "";
    }

    getValue() {
        if(this.link != null) {
            let cellValue = this.link.url;
            if(this.link.displayName) {
                cellValue += "|" + this.link.displayName;
            }
            return cellValue;
        }
        return "";
    }

    get canEditValue(): boolean {
        return this?.params?.data?.rsRow?.CanUpdate && !this.params?.data.isLocked;
    }
}
