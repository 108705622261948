
import { ChartAggregationFunction, chartAggregationFunctionItem, ChartDateGroupingUnit, chartDateGroupingUnitItem, ChartParameters, ChartStackedType, ChartType } from '@/models/Chart';
import * as API from '@/api/Api';
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { Chart, Column, Event, maximizeChartEventParams } from '@/models/RowShare';
import ListVM from '@/viewModels/Table/listVM';
import { EventBus } from '@/modules/EventBus';
import { Utils } from '@/utils/Utilities';
import * as agGrid from 'ag-grid-community';
import GridViewVM from '@/viewModels/Table/gridViewVM';

@Component({name: 'chartConfigurationDialog'})
export default class chartConfigurationDialog extends Vue {
    chartId!: string;
    showMe: boolean = false;
    @Prop() listVM!: ListVM;
    @Prop() gridViewVM!: GridViewVM | undefined | null;

    chartParams: ChartParameters = new ChartParameters();
    chart: Chart = new Chart();
    currentStep: number = 1;
    steps: number = 2;
    selectedAggregationFunction: chartAggregationFunctionItem | null = null;
    selectedXColumn: Column | null = null;
    selectedYColumn: Column | null = null;
    selectedSplitColumn: string | null = null;
    selectedDateGroupingUnit: chartDateGroupingUnitItem | null = null;
    selectedStackOption: number | null = null;

    yColumnSelection: Column[] = [];
    selectSplitColumn: boolean = false;
    savingChart: boolean = false;
    editMode: boolean = false;

    created() {
        EventBus.$on(Event.ADD_NEW_CHART, this.init)
        EventBus.$on(Event.EDIT_CHART, this.init);
    }

    destroy() {
        EventBus.$off([Event.ADD_NEW_CHART, Event.EDIT_CHART]);
    }

    async init(chartToEdit: Chart | null = null) {
        if(chartToEdit) {
            this.editMode = true;
            this.chart = chartToEdit;
            Object.assign(this.chartParams, <ChartParameters>JSON.parse(chartToEdit.Data));
            this.chartId = chartToEdit.Id;
            this.currentStep = 2;
            this.selectedAggregationFunction = this.aggregationFunctions.find((af) => af.aggregationFunction == this.chartParams?.YAggregationFunction) ?? null;
            this.selectedXColumn = this.columns.find(c => c.Id == this.chartParams.XColumnId) ?? null;
            this.selectedYColumn = this.columns.find(c => c.Id == this.chartParams.YColumnId) ?? null;
            this.selectedSplitColumn = this.chartParams.SplitColumnId ?? null;
            if(this.chartParams.SplitColumnId??'' !== '') {
                this.selectSplitColumn = true;
            }
            this.selectedStackOption = this.chartParams.Stacked ?? null;
            this.selectedDateGroupingUnit = this.dateGroupingUnits.find(dgu => dgu.groupingUnit == this.chartParams.DateGroupingUnit) ?? null;
        }
        else {
            this.currentStep = 1;
            this.chartParams = new ChartParameters();        
            this.chart = new Chart();
            this.selectedAggregationFunction = null;
            this.selectedXColumn = null;
            this.selectedYColumn = null;
            this.selectedSplitColumn = null;
            this.selectedDateGroupingUnit = null;
            this.selectSplitColumn = false;
            this.selectedStackOption = null;
        }
        this.savingChart = false;
        this.showMe = true;
    }

    onCancel() {
        this.showMe = false;
    }

    nextStep() {
        if(this.currentStep < this.steps) {
            this.currentStep += 1;
        }
    }

    previousStep() {
        if(this.currentStep > 1) {
            this.currentStep -= 1;
        }
    }

    @Watch('selectedAggregationFunction')
    onAggregationFunctionSelected() {
        if(this.selectedAggregationFunction != null) {
            if(this.selectedAggregationFunction.onlyNumericColumns) {
                this.yColumnSelection = this.aggregableColumns;
            }
            else {
                this.yColumnSelection = this.columns;                
            }
            this.chartParams.YAggregationFunction = this.selectedAggregationFunction.aggregationFunction;
            if(!this.selectedAggregationFunction.canChooseYColumn) {
                this.selectedYColumn = null;
            }
        }
    }

    @Watch('selectedXColumn')
    onXColumnSelected() {
        this.chartParams.XColumnId = '';
        if(this.selectedXColumn != null) {
            this.chartParams.XColumnId = this.selectedXColumn.Id;
            if(!this.selectedXColumn.isDateTimeFormat && !this.selectedXColumn.isDateFormat) {
                this.selectedDateGroupingUnit = null;
                this.chartParams.DateGroupingQuantity = undefined;
            }
            else {
                this.chartParams.DateGroupingQuantity = 1;
            }
        }
    }

    @Watch('selectedYColumn')
    onYColumnSelected() {
        this.chartParams.YColumnId = '';
        if(this.selectedYColumn != null) {
            this.chartParams.YColumnId = this.selectedYColumn.Id;
        }
    }

    @Watch('selectedSplitColumn')
    onSplitColumnSelected() {
        this.chartParams.SplitColumnId = undefined;
        if(this.selectedSplitColumn != null && this.selectSplitColumn) {
            this.chartParams.SplitColumnId = this.selectedSplitColumn;
            if(!this.selectedStackOption) {
                this.selectedStackOption = ChartStackedType.NotStacked;
            }
        }
    }

    @Watch('selectedStackOption')
    onStackOptionSelected() {
        switch(this.selectedStackOption) {
            case 0:
                this.chartParams.Stacked = ChartStackedType.NotStacked;
                break;
            case 1:
                this.chartParams.Stacked = ChartStackedType.Normal;
                break;
            default: {
                this.chartParams.Stacked = undefined;
                break;
            }
        }
    }

    @Watch('selectedDateGroupingUnit')
    onDateGroupingUnitSelected() {
        this.chartParams.DateGroupingUnit = undefined;
        if(this.selectedDateGroupingUnit) {
            this.chartParams.DateGroupingUnit = this.selectedDateGroupingUnit.groupingUnit;
        }        
    }

    @Watch('selectSplitColumn')
    onSelectSplitColumnChanged() {
        if(!this.selectSplitColumn && this.chartParams) {
            this.chartParams.Stacked = ChartStackedType.NotStacked;
            this.selectedSplitColumn = null;
        }
    }

    @Watch('chart.Type')
    onChartTypeChanged() {
        if(this.chart.Type === ChartType.Pie) {
            this.selectedSplitColumn = null;
            this.selectSplitColumn = false;
        }
    }

    public get isScatter(): boolean {
        return (this.chart.Type === ChartType.Scatter);
    }

    createChart() {
        if(!this.listVM?.list) {
            return;
        }
        this.savingChart = true;
        this.chart.DataVersion = 1;
        this.chart.ListId = this.listVM.list.Id;
        if(Utils.isNullOrWhiteSpace(this.chart.Name)) {
            this.chart.Name = this.title;
        }
        if(this.selectSplitColumn) {
            if(this.chart.Type === ChartType.Area) {
                this.chartParams.Stacked = ChartStackedType.Normal;
            }
            else if (this.chart.Type !== ChartType.Bar) {
                this.chartParams.Stacked = ChartStackedType.NotStacked;
            }
        }
        this.chart.Data = JSON.stringify(this.chartParams);

        API.Chart.save(this.chart).then((chart) => {
            if(chart) {
                this.savingChart = false;
                EventBus.$emit(Event.REFRESH_CHARTS);
                
                if(this.gridViewVM?.gridApi) {
                    var rowIds : string[] = [];
                    this.gridViewVM.gridApi.forEachNodeAfterFilter((node: agGrid.IRowNode) => {
                        if(!node.group && node.data?.rsRow)
                            rowIds.push(node.data.rsRow.Id);
                    });

                    if(rowIds.length > 0 && (!this.listVM.rowVMs || rowIds.length < this.listVM.rowVMs.length))
                        EventBus.$emit(Event.MAXIMIZE_CHART, <maximizeChartEventParams>{chartId: chart.Id, rowIds: rowIds})
                    else
                        EventBus.$emit(Event.MAXIMIZE_CHART, <maximizeChartEventParams>{chartId: chart.Id})
                } else {
                    EventBus.$emit(Event.MAXIMIZE_CHART, <maximizeChartEventParams>{chartId: chart.Id})
                }

                this.onCancel();
            }
        });
    }

    public get saveBtnLabel(): string {
        return this.editMode ? this.$i18n.t('Charts_Save').toString() : this.$i18n.t('Charts_Create').toString();
    }


    public get title(): string {
        if(this.chart.Id) {
            return this.chart.Name;
        }
        let title = "";
        if(this.selectedYColumn) {
            title = this.selectedYColumn.DisplayName;
        }
        if(this.selectedXColumn) {
            title += ` ${this.$i18n.t('Charts_ChooseXColumn').toString().toLowerCase()} ${this.selectedXColumn.DisplayName}`;
        }
        if(title == "") {
            title = this.$i18n.t('Charts_NewChart').toString();
        }
        return title;
    }

    public get chartTypeIcon(): string {
        if(this.chart.Type != undefined) {
            switch(this.chart.Type) {
                case ChartType.Line:
                    return "fal fa-chart-line";
                case ChartType.Bar:
                    return "fal fa-chart-column";
                case ChartType.Area:
                    return "fal fa-chart-area";
                case ChartType.Scatter:
                    return "fal fa-chart-scatter";
                case ChartType.Pie:
                    return "fal fa-chart-pie";
            }            
        }
        return "";
    }

    public get listName(): string {
        return this.listVM?.list?.DisplayName ?? '';
    }

    public get columns() {
        return this.listVM?.columns || []
    }

    public get aggregableColumns() {
        return this.columns.filter(c => c.isNumber);
    }

    public get aggregationFunctions(): chartAggregationFunctionItem[] {
        let result: chartAggregationFunctionItem[] = [];

        result.push({name: this.$i18n.t('Charts_AggFunc_Count').toString(),
                     aggregationFunction: ChartAggregationFunction.Count,
                     onlyNumericColumns: false,
                     canChooseYColumn: false});
        result.push({name: this.$i18n.t('Charts_AggFunc_NotNullCount').toString(),
                    aggregationFunction: ChartAggregationFunction.NotNullCount,
                    onlyNumericColumns: false,
                    canChooseYColumn: true});
        result.push({name: this.$i18n.t('Charts_AggFunc_Sum').toString(),
                     aggregationFunction: ChartAggregationFunction.Sum,
                     onlyNumericColumns: true,
                     canChooseYColumn: true});
        result.push({name: this.$i18n.t('Charts_AggFunc_Max').toString(),
                     aggregationFunction: ChartAggregationFunction.Max,
                     onlyNumericColumns: true,
                     canChooseYColumn: true});
        result.push({name: this.$i18n.t('Charts_AggFunc_Min').toString(),
                     aggregationFunction: ChartAggregationFunction.Min,
                     onlyNumericColumns: true,
                     canChooseYColumn: true});
        result.push({name: this.$i18n.t('Charts_AggFunc_Avg').toString(),
                     aggregationFunction: ChartAggregationFunction.Avg,
                     onlyNumericColumns: true,
                     canChooseYColumn: true});

        return result;
    }

    public get dateGroupingUnits(): chartDateGroupingUnitItem[] {
        let result: chartDateGroupingUnitItem[] = [];

        result.push({ name: this.$i18n.t('Charts_DateGroupingUnit_None').toString(), groupingUnit: ChartDateGroupingUnit.None});
        result.push({ name: this.$i18n.t('Charts_DateGroupingUnit_Year').toString(), groupingUnit: ChartDateGroupingUnit.Year});
        result.push({ name: this.$i18n.t('Charts_DateGroupingUnit_Month').toString(), groupingUnit: ChartDateGroupingUnit.Month});
        result.push({ name: this.$i18n.t('Charts_DateGroupingUnit_Day').toString(), groupingUnit: ChartDateGroupingUnit.Day});
        // result.push({ name: this.$i18n.t('Charts_DateGroupingUnit_Hour').toString(), groupingUnit: ChartDateGroupingUnit.Hour});
        // result.push({ name: this.$i18n.t('Charts_DateGroupingUnit_Minute').toString(), groupingUnit: ChartDateGroupingUnit.Minute});
        // result.push({ name: this.$i18n.t('Charts_DateGroupingUnit_Second').toString(), groupingUnit: ChartDateGroupingUnit.Second});

        return result;
    }

    public get xColumnSelection(): Column[] {
        if(this.chart?.Type === ChartType.Line || this.chart?.Type === ChartType.Area) {
            return this.columns.filter(c => c.isNumber || c.isDateTimeFormat || c.isDateFormat);
        }
        return this.columns;
    }

    public get splitColumnSelection(): Column[] {
        return this.columns;
    }

    public get canCreateChart(): boolean {
        if(!this.selectedXColumn || !this.selectedAggregationFunction) {
            return false;
        }
        if(this.selectedAggregationFunction.canChooseYColumn && ! this.selectedYColumn) {
            return false;
        }
        if((this.selectedXColumn.isDateTimeFormat || this.selectedXColumn.isDateFormat) && (!this.selectedDateGroupingUnit || !this.chartParams.DateGroupingQuantity)) {
            return false;
        }
        if(this.selectSplitColumn && !this.selectedSplitColumn) {
            return false;
        }
        return true;
    }

    public get showDateGroupingSelector(): boolean {
        if(this.selectedXColumn?.isDateTimeFormat || this.selectedXColumn?.isDateFormat) {
            return true;
        }
        return false;
    }

    public get canSelectSplitColumn(): boolean {
        return this.chart?.Type !== ChartType.Pie;
    }

    public get canChooseStacked(): boolean {
        return this.chart.Type === ChartType.Bar && this.selectSplitColumn && !Utils.isNullOrWhiteSpace(this.selectedSplitColumn);
    }

    public get canChooseYColumn(): boolean {
        if(this.selectedAggregationFunction) {
            return this.selectedAggregationFunction.canChooseYColumn;
        }
        return true;
    }
}
