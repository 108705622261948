import { Utils } from '@/utils/Utilities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from "vue-router";

@Component({})
export class ReturnUrlHandler extends Vue{

    @Prop() returnUrl!: string;

    redirectToReturnUrlOrDefault(defaultRedirect: RawLocation) {
        if(this.returnUrl){
            this.pushOrReloadRoute(this.returnUrl);
        }
        else
            this.pushOrReloadRoute(defaultRedirect);
    }

    pushOrReloadRoute(pathOrUrl: RawLocation){
        if(this.isV3Path(pathOrUrl)) {
            this.$router.push(this.$router.resolve(pathOrUrl).location);// pathOrUrl);
        }
        else{
            window.location.href = <string>pathOrUrl;
        }
    }

    isV3Path(pathOrUrl: RawLocation): boolean {
        // if(pathOrUrl.toString().toLowerCase().startsWith("/t/") && Utils.getCookie("rsVersion") != "3") {
        //     return false;
        // }

        let internalRoute = this.$router.resolve(pathOrUrl);
        return internalRoute && internalRoute.resolved.matched.length > 0 && internalRoute.resolved.matched.findIndex(r => r.name != 'PageNotFound') > -1;
    }


}